import { useGetOrgGraphQuery, useGetDomainGraphQuery } from '../../api';
import { SET_GRAPH_DATA, SET_LOADING_STATE } from '../state/actions';
import { ForceGraphContext } from 'features/graph/state/GraphContextProvider';
import { useEffect, useContext, useRef } from 'react';

type GraphDataQueryResult =
	| ReturnType<typeof useGetOrgGraphQuery>
	| ReturnType<typeof useGetDomainGraphQuery>;

const useGraphDataLoader = (d: GraphDataQueryResult) => {
	const graphDispatch = useContext(ForceGraphContext)[1];

	const { data, isSuccess, isLoading, isFetching } = d;

	const timerRef = useRef<ReturnType<typeof setTimeout>>();

	useEffect(() => {
		if (isSuccess && data) {
			// short delay here prevents a bug with graph not positioning
			// itself correctly when there is already data in the network cache,
			// presumably related to a DOM ref not being set in time.
			timerRef.current = setTimeout(
				() => graphDispatch({ type: SET_GRAPH_DATA, payload: data }),
				100
			);
			graphDispatch({ type: SET_LOADING_STATE, payload: false });
		}

		if (isLoading || isFetching) {
			graphDispatch({ type: SET_LOADING_STATE, payload: true });
		}

		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, [isSuccess, data, graphDispatch, isLoading, isFetching]);
};

export default useGraphDataLoader;
