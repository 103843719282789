import React, {FunctionComponent, useCallback, useEffect,} from "react";
import {FormResults} from "../Form";
import {useUpdateDomainMutation} from "../../features/api";
import {SubmitHandler, useForm} from "react-hook-form";
import AppModal from "./AppModal";
import useActiveDomainData
    from "../../features/ontology/hooks/useActiveDomainData";
import {BaseDomain} from "../../features/ontology/types/domainTypes";
import useModalType from "../../features/HUD/hooks/useModalType";
import LabelInput from "../inputs/LabelInput";
import Heading from "../text/Heading";
import SubtleTextArea from "../inputs/SubtleTextArea";
import {harvestErrors} from "../inputs/SubtleTextBox";
import NameInput from "../inputs/NameInput";

interface UpdateDomainFormValues extends BaseDomain {
    label: string;
    name: string;
    objective: string | null;
}

const DomainSettingsModal: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
    const {objectName} = modalProps;

    const {activeItem: activeDomain} = useActiveDomainData(objectName);

    const canEdit = activeDomain && activeDomain.userContext.permissions.edit
        || activeDomain && activeDomain.userContext.permissions.admin;

    const {register, formState, resetField, handleSubmit, reset} =
        useForm<UpdateDomainFormValues>({
            defaultValues: !!activeDomain && activeDomain ? activeDomain : {},
        });

    // this is needed to update react-hook-form's default values with new
    // values from a successful PUT request.
    useEffect(() => {
        if (!!activeDomain) {
            reset(activeDomain);
        }
    }, [activeDomain, reset]);

    const validatingRegister = useCallback(
        (inputName: keyof UpdateDomainFormValues, options: any) =>
            register(inputName, options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const [updateDomain, mutationResults] = useUpdateDomainMutation();

    const onSubmit: SubmitHandler<UpdateDomainFormValues> = (formVals) => {
        if (!!activeDomain) {
            updateDomain({
                domainId: activeDomain._id,
                body: Object.assign({}, activeDomain, formVals),
            });
        } else {
            return null;
        }
    };

    if (canEdit && activeDomain) {
        return (
            <AppModal
                label="Edit domain"
                isOpen={true}
                isDirty={formState.isDirty}
                onClose={closeModal}
                onSubmit={handleSubmit(onSubmit)}
                canEdit={canEdit}>
                <Heading style={{padding: '0.25 .5rem', margin: 0}}
                         component="h3">
                    Identity
                </Heading>
                <LabelInput
                    defaultValue={activeDomain.label}
                    resetField={resetField}
                    formState={formState}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                />
                <NameInput
                    defaultValue={activeDomain.name}
                    resetField={resetField}
                    formState={formState}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                />
                <Heading style={{padding: '0.25 .5rem', margin: 0}}
                         component="h3">
                    About
                </Heading>
                <SubtleTextArea
                    {...validatingRegister('objective', {})}
                    label="Objective"
                    defaultValue={'Describe the attribute...'}
                    isDirty={Boolean(formState.dirtyFields.objective)}
                    onReset={() => resetField('objective')}
                    error={harvestErrors(
                        'objective',
                        formState,
                        mutationResults
                    )}
                />
                <FormResults
                    {...mutationResults}
                    validationErrors={formState.errors}
                    onSuccessDelay={2000}
                />
            </AppModal>
        );
    } else {
        return null
    }
};

export default DomainSettingsModal;
