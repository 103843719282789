import React, {
    FunctionComponent,
    useEffect,
    useState,
    useCallback
} from "react";
import {Motion, spring} from 'react-motion';
import OrgMenu from "./menus/OrgMenu";
import useActiveOrgData from "../features/ontology/hooks/useActiveOrgData";
import useCurrentUser from "../features/authentication/hooks/useUser";
import UserMenu from "./menus/SelfMenu";
import MenuPanel from "./menus/MenuPanel";
import {CreateMenu} from "./buttons/CreateButton";
import {
    ACCOUNT_STATUS, ACTIVITY_LOG, ARCHIVE,
    AUDIO_CALL, BLOCK_USER, PROFILE_SETTINGS,
    SEE_FRIENDSHIP, SUPPORT_REPORT,
    VIDEO_CALL, VIEW_AS
} from "../features/graph/CONSTANTS";
import SettingsButton from "./buttons/SettingsButton";
import AddMemberButton from "./buttons/AddMemberButton";
import DestroyButton from "./buttons/DestroyButton";
import UserSettingsModal from "./modals/UserSettingsModal";
import useActiveLandingData
    from "../features/ontology/hooks/useActiveLandingData";

const CustomMenu = () => (
  <ul className="menu">
    <li>Login</li>
    <li>Register</li>
    <li>Open Profile</li>
  </ul>
);


const useContextMenu = () => {
    const [xPos, setXPos] = useState("0px");
    const [yPos, setYPos] = useState("0px");
    const [showMenu, setShowMenu] = useState(false);

    const handleContextMenu = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();

            setXPos(`${e.pageX}px`);
            setYPos(`${e.pageY}px`);
            setShowMenu(true);
        },
        [setXPos, setYPos]
    );

    const handleClick = useCallback(() => {
        showMenu && setShowMenu(false);
    }, [showMenu]);

    useEffect(() => {
        document.addEventListener("click", handleClick);
        document.addEventListener("contextmenu", handleContextMenu);
        return () => {
            document.addEventListener("click", handleClick);
            document.removeEventListener("contextmenu", handleContextMenu);
        };
    });

    return {xPos, yPos, showMenu};
};

const userActions = [
    {
        actionTitle: AUDIO_CALL,
        modalType: 'audioCall',
        divider: false,
        kind: "modal",
        form: SettingsButton,
    },
    {
        actionTitle: VIDEO_CALL,
        modalType: 'videoCall',
        divider: true,
        kind: "modal",
        form: AddMemberButton,
    },
    {
        actionTitle: SEE_FRIENDSHIP,
        modalType: 'friendship',
        divider: true,
        kind: "modal",
        form: AddMemberButton,
    },
    {
        actionTitle: SUPPORT_REPORT,
        modalType: 'support',
        kind: "modal",
        form: DestroyButton,
    },
    {
        actionTitle: BLOCK_USER,
        modalType: 'block',
        kind: "modal",
        form: DestroyButton,
    },
]

const selfActions = [
    {
        actionTitle: VIEW_AS,
        modalType: 'viewAs',
        divider: false,
        kind: "modal",
        form: UserSettingsModal,
    },
    {
        actionTitle: ACCOUNT_STATUS,
        modalType: 'accountStatus',
        divider: true,
        kind: "modal",
        form: AddMemberButton,
    },
    {
        actionTitle: ARCHIVE,
        modalType: 'archive',
        divider: true,
        kind: "modal",
        form: AddMemberButton,
    },
    {
        actionTitle: ACTIVITY_LOG,
        modalType: 'activity',
        kind: "modal",
        form: DestroyButton,
    },
    {
        actionTitle: PROFILE_SETTINGS,
        modalType: 'userSettings',
        kind: "modal",
        form: UserSettingsModal,
    },
]

const ContextMenu: FunctionComponent = () => {
    const {xPos, yPos, showMenu} = useContextMenu();
    const currentUser = useCurrentUser()
    const activeResource = useActiveLandingData()

        return (
            <Motion
                defaultStyle={{opacity: 0}}
                style={{opacity: !showMenu ? spring(0) : spring(1)}}
            >
                {(interpolatedStyle) => (
                    <>
                        {showMenu ? (
                            <div
                                className="menu-container"
                                style={{
                                    top: yPos,
                                    left: xPos,
                                    opacity: interpolatedStyle.opacity,
                                    position: 'absolute'
                                }}
                            >
                                <CreateMenu />
                                {/*<MenuPanel*/}
                                {/*    // openModal={openModal}*/}
                                {/*    resource={currentUser}*/}
                                {/*    canEdit={true}*/}
                                {/*    actions={selfActions}*/}
                                {/*/>*/}
                                {/*<OrgMenu org={activeOrg} canEdit={true}/>*/}
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </Motion>
    );
};
export default ContextMenu;
