import React, {FunctionComponent} from "react";
import SubtleSelect from "./SubtleSelect";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {harvestErrors} from "./SubtleTextBox";
import {BaseOrg} from "../../features/ontology/types/orgTypes";


interface DefaultOrgSelectParams extends ActionFieldProps {
    orgsData: BaseOrg[];
}


const DefaultOrgSelect: FunctionComponent<DefaultOrgSelectParams> = (
    {
        orgsData,
        formState,
        resetField,
        validatingRegister
    }
) => {
    // What type of metric is this?
    return <SubtleSelect
        label="Default organization"
        onReset={() => resetField('defaultOrg')}
        isDirty={Boolean(formState.dirtyFields.defaultOrg)}
        {...validatingRegister('defaultOrg')}
    >
        {orgsData && orgsData.map((acct) => (
            <option key={acct._id} value={acct.name}>
                {acct.label}
            </option>
        ))}
    </SubtleSelect>;
}


export default DefaultOrgSelect;
