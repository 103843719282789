import Button from '../../../common/buttons/Button';
import FlexContainer from '../../../common/FlexContainer';
import { FormResults } from '../../../common/Form';
import Heading from '../../../common/text/Heading';
import Typography from '../../../common/text/Typography';
import { useDeleteResourceMutation } from '../../api';
import { ForceGraphContext } from '../../graph/state/GraphContextProvider';
import { SET_POPUP_ELEMENT } from '../../graph/state/actions';
import {
	StyledFlexContainer,
	StyledPaper,
	StyledDeleteButton,
} from '../common/styledComponents';
import {
	Dispatch,
	FunctionComponent,
	MouseEventHandler,
	SetStateAction,
	useContext,
} from 'react';


interface DeleteResourceFormProps {
	objectId: number;
	objectType: string;
	label: string;

	setOpenAction: Dispatch<SetStateAction<string | null>>;
	// openActionSubmenu: string | null;
}


const DeleteResourceForm: FunctionComponent<DeleteResourceFormProps> = ({
	objectId,
	objectType,
	label,
	setOpenAction,
}) => {
	const [_, graphDispatch] = useContext(ForceGraphContext);

	const [deleteResource, deletionResult] = useDeleteResourceMutation();

	const onClick: MouseEventHandler = () => {
		deleteResource({ objectId, objectType });
	};

	const onSuccessfulDelete = () =>
		graphDispatch({ type: SET_POPUP_ELEMENT, payload: null });

	return (
		<StyledPaper>
			<StyledFlexContainer flexDirection="column" alignItems="center">
				<Heading component="h5">
					<Typography>
						Are you sure you want to delete {label}?
					</Typography>
				</Heading>
				<FlexContainer
					style={{ width: '50%' }}
					justifyContent="space-between"
				>
					<Button onClick={() => setOpenAction(null)}>
						Go Back
					</Button>
					<StyledDeleteButton onClick={onClick}>
						Delete
					</StyledDeleteButton>
				</FlexContainer>
				<FormResults
					{...deletionResult}
					validationErrors={{}}
					onSuccess={onSuccessfulDelete}
				/>
			</StyledFlexContainer>
		</StyledPaper>
	);
};

export default DeleteResourceForm;
