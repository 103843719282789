import FlexContainer from '../FlexContainer';
import { FunctionComponent } from 'react';
import styled, { keyframes } from 'styled-components';

// adapted from https://codepen.io/supah/pen/BjYLdW

const dash = keyframes`
	 0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const rotate = keyframes`
 100% {
    transform: rotate(360deg);
  }
`;

const StyledSVG = styled.svg`
	animation: ${rotate} 2s linear infinite;
	width: 24px;
	height: 24px;
`;

const StyledCircle = styled.circle`
	stroke: ${(p) => p.theme.palette.primary.main};
	stroke-linecap: round;
	animation: ${dash} 1.5s ease-in-out infinite;
	stroke-width: 5;
`;

export const LOAD_SPINNER_TEST_ID = 'loading-spinner';

const Spinner: FunctionComponent = () => {
	return (
		<FlexContainer
			style={{
				width: '100%',
				height: '100%',
				position: 'absolute',
				top: 0,
				left: 0,
				pointerEvents: 'none'
			}}
			alignItems='center'
			justifyContent="center">
			<StyledSVG
				data-testid={LOAD_SPINNER_TEST_ID}
				viewBox="0 0 50 50">
				<StyledCircle
					cx="25"
					cy="25"
					r="20"
					fill="none"
					stroke-width="5"
				/>
			</StyledSVG>
		</FlexContainer>
	);
};

export default Spinner;
