import React from 'react';
import {List} from '../List';
import MenuAction from './MenuAction';
import {FunctionComponent, ReactNode, useState} from 'react';
import styled from "styled-components";
import {BaseEntity} from "../../features/ontology/types/entityTypes";
import {resolveAttrType} from "../../features/ontology/typeGuards/attributeGuards";
import {sortByString} from "../utils/functionUtils";
import {AttrResolvedTypeMap} from "../../features/ontology/helpers/attributeHelpers";
import TextButton from "../buttons/TextButton";
import SubMenuButton from "../buttons/SubMenuButton";


const StyledDivider = styled.hr`
    margin: 0;
    border-width: 0;
    background-image: linear-gradient(${(p) => p.theme.palette.divider}, ${(p) => p.theme.palette.divider});
    color:${(p) => p.theme.palette.divider};
    height: 0.125rem;
    margin: 0.125rem 0;
`


const groupActions = (actions: any) => {
    const grouped = actions.reduce((acc: any, action: any) => {
        const type = !!action.subMenu ? action.subMenu : 'base';

        const existing = acc[type];

        if (existing) {
            acc[type] = [...existing, action];
            return acc;
        }

        acc[type] = [action];
        return acc;
    }, {});

    //  put each array of attributes in alphabetical order based on their 'singular' field
    // const sorted = Object.entries(grouped).reduce((acc: any, [key, vals]) => {
    // 	acc[key] = (vals as any).slice().sort((a: { position: number }, b: { position: number }) => {
    // 				const first = a.position || 1;
    // 				const second = b.position || 1;
    //
    // 				if (first < second) {
    // 					return -1;
    // 				}
    //
    // 				if (first > second) {
    // 					return 1;
    // 				}
    //
    // 				return 0;
    // 			})
    // 	}, {});
    return grouped;
};

export type ActionConfig = {
    actionTitle: string;
    kind?: 'subMenu' | 'modal' | 'link' | 'action';
    items?: ActionConfig[];
    modalType?: string;
    divider?: boolean;
    requiresEdit?: boolean;
    callback?: string;
    location?: string;
    restrictionKind?: string;
    args: any;
}

interface ActionListProps {
    resource: any;
    actions: any;
    entity?: BaseEntity;
    canEdit?: boolean;
    // openModal?: (modalType: any) => void;
    // closeModal?: () => void;
    children?: ReactNode;
}


const MenuPanel: FunctionComponent<ActionListProps> = (
    {
        resource,
        actions,
        entity,
        canEdit = false,
        children
    }
) => {
    // keep track of which action list item has a sub-menu open.  Should
    // only have one open at a time.
    // const [actionWithActiveSubmenu, setActionWithActiveSubmenu] =
    // 	useState<string | null>(null);

    return (
        <List style={{width: '12rem'}}>
            {actions.map((action: ActionConfig, index: number) => {
                if (action.kind === 'subMenu') {
                    if ((action.requiresEdit && canEdit) || !action.requiresEdit) {
                        return <React.Fragment
                            key={`${action.actionTitle}-${index}`}>
                            <SubMenuButton label={action.actionTitle}>
                                {(action.items as any).map((action: ActionConfig, index: number) => {
                                    if (!action.requiresEdit) {
                                        return <React.Fragment
                                            key={`${action.actionTitle}-${index}`}>
                                            <MenuAction
                                                action={{
                                                    ...action,
                                                    args: {
                                                        canEdit: canEdit,
                                                        objectType: resource._object,
                                                        objectId: resource._id,
                                                        objectName: resource.name,
                                                        restrictionKind: action?.restrictionKind,
                                                        resource: resource,
                                                        entity: entity
                                                    }
                                                }}
                                            />
                                            {action.divider ?
                                                <StyledDivider/> : null}
                                        </React.Fragment>
                                    } else if (action.requiresEdit && canEdit) {
                                        return <React.Fragment
                                            key={`${action.actionTitle}-${index}`}>
                                            <MenuAction
                                                action={{
                                                    ...action,
                                                    args: {
                                                        canEdit: canEdit,
                                                        objectType: resource._object,
                                                        objectId: resource._id,
                                                        objectName: resource.name,
                                                        restrictionKind: action?.restrictionKind,
                                                        resource: resource,
                                                        entity: entity
                                                    }
                                                }}
                                            />
                                            {action.divider ?
                                                <StyledDivider/> : null}
                                        </React.Fragment>
                                    } else {
                                        return null;
                                    }
                                })}
                            </SubMenuButton>
                            {action.divider ? <StyledDivider/> : null}
                        </React.Fragment>
                    } else {
                        return null;
                    }
                } else if (!action.requiresEdit || (action.requiresEdit && canEdit)) {
                    return <React.Fragment
                        key={`${action.actionTitle}-${index}`}>
                        <MenuAction
                            action={{
                                ...action,
                                args: {
                                    canEdit: canEdit,
                                    objectType: resource._object,
                                    objectId: resource._id,
                                    objectName: resource.name,
                                    restrictionKind: action?.restrictionKind,
                                    resource: resource,
                                    entity: entity
                                }
                            }}
                        />
                        {action.divider ? <StyledDivider/> : null}
                    </React.Fragment>
                } else {
                    return null;
                }
            })}
            {children}
        </List>
    );
};

export default MenuPanel;
