import {BasePaper} from 'common/Paper';
import theme from 'common/theme/theme';
import Fade from 'common/transitions/Fade';
import { forkRef } from 'common/utils/reactUtils';
import {
	FunctionComponent,
	useState,
	useRef,
	useEffect,
	ReactNode,
	CSSProperties,
} from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { usePopper, Modifier } from 'react-popper';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

const StyledArrow = styled.div`
	width: 0.5rem;
	height: 0.5rem;
	&::before {
		content: '';
		background: ${(p) => p.theme.palette.background.paper};
		width: 0.5rem;
		height: 0.5rem;
		transform: translate(-50%, -50%) rotate(45deg);
		position: absolute;
		top: 0;
		left: 0;
	}
`;

const StyledPaper = styled(BasePaper)`
	padding: ${(p) => p.theme.spacing(0.5)};
	margin-right: ${(p) => p.theme.spacing(1)};
`;

const generateModifiers = (
	offset: number | undefined,
	arrowElement?: HTMLElement | null
) => {
	const realOffset = offset || 10;

	const modifiers: Modifier<any>[] = [
		{
			name: 'offset',
			options: {
				offset: [0, realOffset],
			},
		},
	];

	if (arrowElement) {
		modifiers.push({ name: 'arrow', options: { element: arrowElement } });
	}

	return modifiers;
};

export type Placement =
	| 'auto'
	| 'auto-start'
	| 'auto-end'
	| 'top'
	| 'top-start'
	| 'top-end'
	| 'bottom'
	| 'bottom-start'
	| 'bottom-end'
	| 'right'
	| 'right-start'
	| 'right-end'
	| 'left'
	| 'left-start'
	| 'left-end';

export interface PopoverBaseProps {
	anchorEl: null | Element;
	open: boolean;
	id?: string;
	arrow?: boolean;
	offset?: number;
	placement?: Placement;
	forceUpdateKey?: string | null;
	handleClickAway?: () => void;
	children?: ReactNode;
	overrides?: {
		paper?: CSSProperties;
	};
}

const PopoverBase: FunctionComponent<PopoverBaseProps> = ({
	handleClickAway,
	children,
	anchorEl,
	open,
	offset,
	arrow,
	placement,
	overrides,
	forceUpdateKey,
}) => {
	// Need this ref to stop react-transition-group from trying to
	// use findDOMNode, which is deprecated and profoundly irritating.
	const transitionRef = useRef();

	const [popperElement, setPopperElement] = useState<HTMLElement | null>(
		null
	);

	// Both PopperJS and react-transition-group need a ref to the first
	// child of CSSTransition.
	const combinedRefs = forkRef(transitionRef, setPopperElement);

	const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

	const { styles, attributes, update } = usePopper(anchorEl, popperElement, {
		modifiers: generateModifiers(offset, arrow ? arrowElement : null),
		placement: placement ? placement : 'auto',
	});

	// parent component can optionally pass a key string to popover; whenever that key
	// changes, the popper will update its position.  Useful for popover bodies that may change
	// size significantly.
	useEffect(() => {
		if (update && forceUpdateKey) {
			update();
		}
	}, [forceUpdateKey, update]);

	return (
		<CSSTransition
			classNames="fade-transition"
			in={open}
			timeout={theme.transitions.duration.shortest}
			unmountOnExit
			nodeRef={transitionRef}
		>
			<Fade
				style={{ ...styles.popper }}
				{...attributes.popper}
				ref={combinedRefs as any}
				$duration={theme.transitions.duration.shortest}
			>
				{arrow && (
					<StyledArrow
						ref={setArrowElement as any}
						style={styles.arrow}
					/>
				)}
				<ClickAwayListener
					onClickAway={
						handleClickAway ? handleClickAway : () => undefined
					}
				>
					<StyledPaper style={{ ...overrides?.paper }}>
						{children}
					</StyledPaper>
				</ClickAwayListener>
			</Fade>
		</CSSTransition>
	);
};

export default PopoverBase;
