import React, {FunctionComponent, useCallback, useEffect,} from "react";
import useActiveOrgData from "../../features/ontology/hooks/useActiveOrgData";
import ReactModal from "react-modal";
import {BasePaper} from '../Paper';
import styled from 'styled-components';
import {FormResults} from "../Form";
import {useUpdateDatasetMutation} from "../../features/api";
import {SubmitHandler, useForm} from "react-hook-form";
import Heading from "../text/Heading";
import AppModal from "./AppModal";
import useModalType from "../../features/HUD/hooks/useModalType";
import URLInput from "../inputs/WebURLInput";
import EncodingTypeSelect from "../inputs/EncodingTypeSelect";
import LineBreakTypeSelect from "../inputs/LinebreakSelect";
import DelimiterTypeSelect from "../inputs/DelimiterTypeSelect";
import ContentTypeSelect from "../inputs/ContentTypeSelect";
import PackagingTypeSelect from "../inputs/PackagingTypeSelect";
import CompressionTypeSelect from "../inputs/CompressionTypeSelect";
import WebURLInput from "../inputs/WebURLInput";
import EncryptionTypeSelect from "../inputs/EncryptionTypeSelect";
import LicenseTypeSelect from "../inputs/LicenseTypeSelect";
import {
    CachedDataSet,
    DataSetPublisherMeta
} from "../../features/browser/types/dataTypes";

export const SourceBody = styled(BasePaper)`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: ${(p) => p.theme.palette.background.paper};
`

interface HUDProps {
    translucent?: boolean;
    borderRadius?: string;
    width?: string | number;
    height?: string | number;
}

// box-shadow: ${(p) => p.theme.shadows[4]};

export const HUDModalBody = styled.div<HUDProps>`
	background: ${(p) =>
    p.theme.palette.background[
        p.translucent ? 'transparentDark' : 'paper'
        ]};
		
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	color: ${(p) => p.theme.palette.lightBaby};
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
	${(p) => p.theme.translucent}
`;

interface StyledModalHeaderProps {
    justifyContent?: string;
    borderBottom?: string;
    padding?: string;
}

// padding: ${(p) => p.padding ? p.padding : p.theme.spacing(1, 2)};
export const ModalHeader = styled.header<StyledModalHeaderProps>`
	padding: 1rem;
    border-bottom: ${(p) => p.borderBottom ? p.borderBottom : `2px solid ${p.theme.palette.divider}`};
	display: flex;
	background: ${(p) => p.theme.palette.background['paper']};
	justify-content: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	justify-items: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	align-items: flex-start;
	align-content: flex-start;
	height: 4rem;
	z-index: 10000;
`;

// font-size: 1rem;
export const HUDModalHeaderText = styled.h1`
	display: flex;
	align-content: center;
	align-items: center;
	gap: 1rem;
	justify-content: flex-start;
	justify-items: flex-start;
`;


interface ModalHeaderProps extends ReactModal.Props {
    handleClose: () => void;
    headingText: string;
}


interface UpdateDatasetFormValues {

    	// publisher: DataSetPublisherMeta;
	// sourceId: number;
	// sql: string;
	// uid: string;
	// type: string;
	// sourceName: string;
	// catalogName: string;
	// datasetName: string;
	label: string;
	definition: string;
	contentType: string;
	compressionType: string;
	packagingType: string;
	updateFrequency: string;
	deliveryFrequency: string;
	// isReferencedBy: CachedDataSet[];
	// reportingLag: string;
	// createdAt: string;
	// modifiedAt: string;
	// metadata: {};
	// domain: string;
	// keywords: string[];
	license: string;
}

const DatasetSettingsModal: FunctionComponent = () => {

    const {modalType, modalProps, closeModal} = useModalType();
    const {resource} = modalProps;

    const {activeItem: activeOrg} = useActiveOrgData();

    const canEdit = activeOrg && activeOrg.userContext.permissions.edit
        || activeOrg && activeOrg.userContext.permissions.admin;

    const {register, formState, resetField, handleSubmit, reset} =
        useForm<UpdateDatasetFormValues>({
            defaultValues: Object.assign({}, resource),
        });

    // this is needed to update react-hook-form's default values with new
    // values from a successful PUT request.
    useEffect(() => {
        if (!!resource && formState.isDirty) {
            reset(resource);
            closeModal();
        }
    }, [activeOrg, reset]);

    // @ts-ignore
    const validatingRegister = useCallback(
        (inputName: keyof UpdateDatasetFormValues, options?: any) =>
            register(inputName, options ? options : {required: `${inputName} is required`,}),
        [register]
    );

    const [updateDataset, mutationResults] = useUpdateDatasetMutation();

    const onSubmit: SubmitHandler<UpdateDatasetFormValues> = (formVals) => {
        const {_id} = resource;

        // if (!_id || !!social || !!locale || !!orgProps) {
        // 	return null;
        // }

        updateDataset({
            datasetId: _id,
            body: Object.assign({}, resource, formVals),
        });
    };

    if (resource) {
        return (
            <AppModal
                label={"Edit metadata"}
                isOpen={modalType === 'datasetSettings'}
                isDirty={formState.isDirty}
                onClose={() => closeModal()}
                onSubmit={handleSubmit(onSubmit)}
                canEdit={canEdit}>
                {/*<Heading style={{padding: 0, margin: 0}} component="h3">*/}
                {/*    Profile Picture*/}
                {/*</Heading>*/}
                {/*<FlexContainer*/}
                {/*    flexDirection={'column'}*/}
                {/*    gap={'1rem'}*/}
                {/*    alignItems='center'>*/}
                {/*    {!!activeOrg.avatar?.medium || !!activeOrg.avatarUrl*/}
                {/*        ? <ProfileAvatar*/}
                {/*            initial={!!activeOrg ? activeOrg.name[0] : ''}*/}
                {/*            profileImageURL={activeOrg.avatar?.medium*/}
                {/*                ? activeOrg.avatar?.medium*/}
                {/*                : activeOrg.avatarUrl}*/}
                {/*            isProfile={true}*/}
                {/*            onClick={() => {*/}
                {/*            }}*/}
                {/*            isOnline={false}*/}
                {/*            styles={{*/}
                {/*                alignSelf: 'center',*/}
                {/*                justifySelf: 'center'*/}
                {/*            }}*/}
                {/*            height={'10rem'}*/}
                {/*            width={'10rem'}*/}
                {/*        />*/}
                {/*        : <StyledGraphicContainer*/}
                {/*            height={'10rem'}*/}
                {/*            width={'10rem'}*/}
                {/*            style={{*/}
                {/*                alignSelf: 'center',*/}
                {/*                justifySelf: 'center'*/}
                {/*            }}>*/}
                {/*            <StyledGraphicCircle/>*/}
                {/*        </StyledGraphicContainer>}*/}
                {/*    <URLInput*/}
                {/*        name={'avatarUrl'}*/}
                {/*        label="Avatar URL"*/}
                {/*        tooltip="Avatar URL"*/}
                {/*        defaultValue={activeOrg.avatar.medium}*/}
                {/*        validatingRegister={validatingRegister}*/}
                {/*        mutationResults={mutationResults}*/}
                {/*        formState={formState}*/}
                {/*        resetField={resetField}*/}
                {/*    />*/}
                {/*</FlexContainer>*/}
                {/*<Heading style={{padding: 0, margin: 0}} component="h3">*/}
                {/*    Intro*/}
                {/*</Heading>*/}
                <EncodingTypeSelect
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <LineBreakTypeSelect
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <DelimiterTypeSelect
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <ContentTypeSelect
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <PackagingTypeSelect
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <CompressionTypeSelect
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <EncryptionTypeSelect
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <LicenseTypeSelect
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />

                <WebURLInput
                    name={'accessUrl'}
                    label={'Access URL'}
                    tooltip={'Access URL'}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <WebURLInput
                    name={'downloadUrl'}
                    label={'Download URL'}
                    tooltip={'Download URL'}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                {/*<Heading style={{padding: 0, margin: 0}} component="h3">*/}
                {/*    Website*/}
                {/*</Heading>*/}
                {/*<URLInput*/}
                {/*    name={'domainName'}*/}
                {/*    label={"Web Domain"}*/}
                {/*    tooltip={"Web Domain"}*/}
                {/*    defaultValue={activeOrg.social.linkedin}*/}
                {/*    validatingRegister={validatingRegister}*/}
                {/*    mutationResults={mutationResults}*/}
                {/*    formState={formState}*/}
                {/*    resetField={resetField}*/}
                {/*/>*/}
                <FormResults
                    {...mutationResults}
                    validationErrors={formState.errors}
                    onSuccessDelay={2000}
                />
            </AppModal>
        );
    } else {
        return null
    }
};

export default DatasetSettingsModal;
