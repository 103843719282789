import {
	OrgAuthorityLevel,
	DomainAuthorityLevel,
} from '../../features/governance/types/GovernanceTypes';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'common/buttons/IconButton';
import Spinner from 'common/loading/Spinner';
import { useRemoveUserRoleMutation } from 'features/api';
import { parseQueryError } from 'features/api/helpers';
import React, { FunctionComponent, useEffect } from 'react';
import TextButton from "./TextButton";
import Typography from "../text/Typography";

interface RevokeRoleButtonProps {
	role: OrgAuthorityLevel | DomainAuthorityLevel;
	objectId: number;
	objectType: 'domain' | 'username' | 'entity';
	userId: number;
	setMemberErr: (errMsg: string, id: number) => void;
}

const RevokeRoleButton: FunctionComponent<RevokeRoleButtonProps> = ({
	objectId,
	objectType,
	userId,
	role,
	setMemberErr,
}) => {
	const [remove, removalResult] = useRemoveUserRoleMutation();

	const { error, isLoading } = removalResult;

	const renderButtonContent = () => {
		if (isLoading) {
			return <Spinner />;
		}

		return <Typography variant={'h5'} color={'oldManGray'}>
			Remove {role}
		</Typography>;
	};

	useEffect(() => {
		if (error) {
			setMemberErr(parseQueryError(error).message, userId);
		}
	}, [error, setMemberErr, userId]);

	return (
		<TextButton
			text=''
			color='error'
			alignContent={'flex-start'}
			fillColor={'transparent'}
			// icon={faClose}
			// text={'Remove Member'}
			// size="xs"
			// variant={'transparent'}
			disabled={isLoading || !!error}
			onClick={() =>
				remove({
					parentObjectId: objectId,
					parentObjectType: objectType,
					userId,
					role,
				} as any)
			}
			tooltip="demote user"
		>
			{renderButtonContent()}
		</TextButton>
	);
};

export default RevokeRoleButton;
