import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";

const DefinitionInput: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
    ) => {
    //Define this entity
    return <SubtleTextbox
        {...validatingRegister('definition', {})}
        showLabel
        alignItems={'center'}
        justifyContent={'space-between'}
        inline={true}
        label="Definition"
        isDirty={Boolean(formState.dirtyFields.definition)}
        onReset={() => resetField('definition')}
        error={harvestErrors('definition', formState, mutationResults)}
    />;
}

export default DefinitionInput;
