import React, {
    FunctionComponent,
    useCallback,
    useEffect
} from "react";
import ReactModal from "react-modal";
import StyledSquare from "../StyledSquare";
import Typography from "../text/Typography";
import IconButton, {IconButtonBase} from "../buttons/IconButton";
import {faInfo, faInfoCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import {BasePaper} from '../Paper';
import styled from 'styled-components';
import {
    harvestErrors,
    NonInputLine,
    SubtleTextbox
} from "../inputs/SubtleTextBox";
import {StyledGraphicContainer} from "../StyledGraphicContainer";
import {StyledGraphicCircle} from "../StyledCircle";
import {FormResults} from "../Form";
import useCurrentUser from "../../features/authentication/hooks/useUser";
import {useGetOrgsQuery, useUpdateUserMutation} from "../../features/api";
import {SubmitHandler, useForm} from "react-hook-form";
import Button from "../buttons/Button";
import FlexContainer from "../FlexContainer";
import Heading from "../text/Heading";
import ProfileAvatar
    from "../../features/navigation/components/Navbar/ProfileAvatar";
import AppModal from "./AppModal";
import useModalType from "../../features/HUD/hooks/useModalType";
import LocationSelect from "../inputs/LocationSelect";
import LinkedInInput from "../inputs/LinkedInInput";
import FacebookInput from "../inputs/FacebookInput";
import InstagramInput from "../inputs/InstagramInput";
import GithubInput from "../inputs/GithubInput";
import DefaultOrgSelect from "../inputs/DefaultOrgSelect";
import PhoneNumberInput from "../inputs/PhoneNumberInput";
import EmailInput from "../inputs/EmailInput";
import LastNameInput from "../inputs/LastNameInput";
import FirstNameInput from "../inputs/FirstNameInput";
import UserNameInput from "../inputs/UsernameInput";
import AboutInput from "../inputs/AboutInput";

export const SourceBody = styled(BasePaper)`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: ${(p) => p.theme.palette.background.paper};
`

interface HUDProps {
    translucent?: boolean;
    borderRadius?: string;
    width?: string | number;
    height?: string | number;
}


// box-shadow: ${(p) => p.theme.shadows[4]};
export const HUDModalBody = styled.div<HUDProps>`
	background: ${(p) =>
    p.theme.palette.background[
        p.translucent ? 'transparentDark' : 'paper'
        ]};
		
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	color: ${(p) => p.theme.palette.lightBaby};
	width: 100%;
	height: calc(100vh - ${(p) => p.theme.navHeight * 4 + p.theme.panelGutter * 8}px);
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter * 4}px;
	position: absolute;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
	${(p) => p.theme.translucent}
`;

interface StyledModalHeaderProps {
    justifyContent?: string;
    borderBottom?: string;
    padding?: string;
}

// padding: ${(p) => p.padding ? p.padding : p.theme.spacing(1, 2)};
export const ModalHeader = styled.header<StyledModalHeaderProps>`
	padding: 1rem;
    border-bottom: ${(p) => p.borderBottom ? p.borderBottom : `2px solid ${p.theme.palette.divider}`};
	display: flex;
	background: ${(p) => p.theme.palette.background['paper']};
	justify-content: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	justify-items: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	align-items: flex-start;
	align-content: flex-start;
	height: ${(p) => p.theme.navHeight};
	z-index: 10000;
`;

// font-size: 1rem;
export const HUDModalHeaderText = styled.h1`
	display: flex;
	align-content: center;
	align-items: center;
	gap: 1rem;
	justify-content: flex-start;
	justify-items: flex-start;
`;

interface UpdateUserFormValues {
    firstName: string;
    lastName: string;
    username: string;
    defaultOrg: string;
    github?: string;
    instagram?: string;
    facebook?: string;
    linkedin?: string;
    location: string | null;
    avatarUrl?: string;
    email?: string;
    phoneNumber?: string;
    department: string | null;
    jobTitle: string | null;
    definition: string | null;
    // phoneNumber: string;
}

const UserSettingsModal: FunctionComponent = () => {

    const {modalType, modalProps, closeModal} = useModalType();
    const {resource} = modalProps;

    const user = useCurrentUser();

    const {data: orgsData} = useGetOrgsQuery({userId: user._id});

    const {register, formState, resetField, handleSubmit, reset} =
        useForm<UpdateUserFormValues>({
            defaultValues: user,
        });

    // this is needed to update react-hook-form's default values with new
    // values from a successful PUT request.
    useEffect(() => {
        if (!!user && formState.isDirty) {
            reset(user);
            closeModal();
        }
    }, [user, reset]);

    const validatingRegister = useCallback(
        (inputName: keyof UpdateUserFormValues) =>
            register(inputName, {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const [updateUser, mutationResults] = useUpdateUserMutation();

    const onSubmit: SubmitHandler<UpdateUserFormValues> = (formVals) => {
        const {__tag, ...userProps} = user;

        updateUser({
            userId: userProps._id,
            body: Object.assign(userProps, formVals),
        });
    };

    if (user) {
        return (
            <AppModal
                label={"Edit profile"}
                isOpen={true}
                isDirty={formState.isDirty}
                onClose={closeModal}
                onSubmit={handleSubmit(onSubmit)}
                canEdit={true}>

                <Heading style={{padding: 0, margin: 0}} component="h3">
                    Profile Picture
                </Heading>
                <FlexContainer
                    flexDirection={'column'}
                    gap={'1rem'}
                    alignItems='center'>
                    {!!user.avatar?.medium.url || !!user.avatarUrl
                        ? <ProfileAvatar
                            initial={!!user ? user.firstName : ''}
                            profileImageURL={
                                user.avatar?.medium.url
                                    ? user.avatar?.medium.url
                                    : user.avatarUrl
                            }
                            isProfile={true}
                            onClick={() => {}}
                            isOnline={false}
                            styles={{alignSelf: 'center', justifySelf: 'center'}}
                            height={'10rem'}
                            width={'10rem'}
                        />
                        : <StyledGraphicContainer
                            height={'10rem'}
							width={'10rem'}
                            style={{
                                alignSelf: 'center',
                                justifySelf: 'center'}}>
                            <StyledGraphicCircle/>
                        </StyledGraphicContainer>}
                    <a style={{alignSelf: 'center', verticalAlign: 'middle'}}
                       href='https://en.gravatar.com/' target='_blank'>
                        <Typography>Manage Gravatar</Typography>
                        <IconButton
                            icon={faInfoCircle as any}
                            tooltip=""
                            size="xs"
                            iconSize='sm'
                            style={{marginLeft: '4px'}}
                            fillColor={'primary'}
                            onClick={() => {
                            }}
                            showTooltip={true}
                        />
                    </a>
                </FlexContainer>
                <Heading style={{padding: 0, margin: 0}} component="h3">
                    Bio
                </Heading>
                <AboutInput
                    defaultValue={user.definition !== undefined ? user.definition : null}
                    validatingRegister={validatingRegister}
                    formState={formState}
                    resetField={resetField}
                    mutationResults={mutationResults}
                />
                <Heading style={{padding: 0, margin: 0}} component="h3">
                    Intro
                </Heading>
                <UserNameInput
                    validatingRegister={validatingRegister}
                    formState={formState}
                    resetField={resetField}
                    mutationResults={mutationResults}
                />
                <FirstNameInput
                    validatingRegister={validatingRegister}
                    formState={formState}
                    resetField={resetField}
                    mutationResults={mutationResults}
                />
                <LastNameInput
                    validatingRegister={validatingRegister}
                    formState={formState}
                    resetField={resetField}
                    mutationResults={mutationResults}
                />
                <EmailInput
                    defaultValue={user.email}
                    validatingRegister={validatingRegister}
                    formState={formState}
                    resetField={resetField}
                    mutationResults={mutationResults}
                />
                <PhoneNumberInput
                    defaultValue={user.phoneNumber}
                    validatingRegister={validatingRegister}
                    formState={formState}
                    resetField={resetField}
                    mutationResults={mutationResults}
                />
                <DefaultOrgSelect
                    orgsData={!!orgsData ? orgsData : []}
                    validatingRegister={validatingRegister}
                    formState={formState}
                    resetField={resetField}
                />
                <SubtleTextbox
                    {...register('department')}
                    label="Department"
                    defaultValue={user.department}
                    isDirty={Boolean(formState.dirtyFields.department)}
                    onReset={() => resetField('department')}
                    error={harvestErrors(
                        'department',
                        formState,
                        mutationResults
                    )}
                    showLabel
                />
                <SubtleTextbox
                    {...register('jobTitle')}
                    label="Job Title"
                    defaultValue={user.jobTitle}
                    isDirty={Boolean(formState.dirtyFields.jobTitle)}
                    onReset={() => resetField('jobTitle')}
                    error={harvestErrors(
                        'jobTitle',
                        formState,
                        mutationResults
                    )}
                    showLabel
                />
                <Heading style={{padding: 0, margin: 0}} component="h3">
                    Locale
                </Heading>
                <LocationSelect
                    defaultValue={user.location}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <Heading style={{padding: 0, margin: 0}} component="h3">
                    Social
                </Heading>
                <LinkedInInput
                    defaultValue={user.social.linkedin.username ?? null}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <FacebookInput
                    defaultValue={user.social.facebook.username ?? null}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <InstagramInput
                    defaultValue={user.social.instagram.username ?? null}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <GithubInput
                    defaultValue={user.social.github.username ?? null}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <FormResults
                    {...mutationResults}
                    validationErrors={formState.errors}
                    onSuccessDelay={2000}
                />
            </AppModal>
        );
    } else {
        return null
    }
};

export default UserSettingsModal;
