import { CommentCreationId, CommentSubject } from '../types/commentTypes';
import { createContext } from 'react';

interface CommentContextValue {
	objectType: CommentSubject;
	canEdit: boolean;
	objectIdentifier: CommentCreationId | null;
}

const CommentContext = createContext<CommentContextValue>({
	objectIdentifier: {
		kind: 'other',
		objectId: 0,
	},
	objectType: '' as CommentSubject,
	canEdit: false,
});

export default CommentContext;
