import Typography from "./Typography";
import {FunctionComponent} from "react";
import {ListItemContent} from "../List";

const Username: FunctionComponent<{user: any}> = ({user}) => {
    return <ListItemContent>
            <Typography variant="caption1">
            {user.username}
        </Typography>
    </ListItemContent>;
}

export default Username;
