import React, {FunctionComponent} from "react";
import FlexContainer from "../FlexContainer";
import Typography from "../text/Typography";
import Spinner from "./Spinner";

const Loading: FunctionComponent = () => {
    return (
            <FlexContainer justifyContent="center">
                <Typography paragraph variant='h5'>
                    Loading attributes...
                </Typography>
                <Spinner/>
            </FlexContainer>
        );
}

export default Loading;
