import styled from "styled-components";
import {StyledGraphicSquare} from "./StyledSquare";

export const StyledGraphicCircle2 = styled(StyledGraphicSquare)`
	background: ${(p) => p.theme.palette.primary.main};
	width: 10rem;
    height: 10rem;
    border-radius: 50%;
`;

export const StyledGraphicCircle = styled(StyledGraphicSquare)`
	background: ${(p) => p.theme.palette.primary.main};
	width: 6rem;
    height: 6rem;
    border-radius: 50%;
    position: absolute;
    left: 0;
`;

export default StyledGraphicCircle;
