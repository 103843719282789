import {skipToken} from '@reduxjs/toolkit/dist/query';
import theme from 'common/theme/theme';
import {useGetDomainGraphQuery} from 'features/api';
import GraphPopover from 'common/viz/ForceGraph/GraphPopover';
import {
    StyledGraphContainer,
    StyledHeading,
} from 'common/viz/ForceGraph/styledComponents';
import {drawNode, drawLink} from 'features/graph/helpers';
import useGraphDataLoader from 'features/graph/hooks/useGraphDataLoader';
import useGraphHandlers from 'features/graph/hooks/useGraphHandlers';
import GraphContextProvider from 'features/graph/state/GraphContextProvider';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import React, {FunctionComponent} from 'react';
import {default as ForceGraph2D} from 'react-force-graph-2d';
import ContextMenu from "../../../../common/ContextMenu";
import useActiveDomainGraph from "../../../ontology/hooks/useActiveDomainGraph";
import {useParams} from "react-router-dom";

const DomainGraphBody: FunctionComponent = () => {

    const {activeItem: activeDomain} = useActiveDomainData();
    const canEdit = activeDomain && activeDomain.userContext.permissions.edit
        || activeDomain && activeDomain.userContext.permissions.admin;

    const queryRes = useGetDomainGraphQuery(
        activeDomain ? {domainId: activeDomain._id} : skipToken,
        );
    // const queryRes = useActiveDomainGraph(params.domain);

    useGraphDataLoader(queryRes);

    const {selectNode, width, height, setFgm, graphState} =
        useGraphHandlers();

    return (
        <>
            <StyledGraphContainer className={'unselectable'}>
                <div>
                    {queryRes.isLoading || graphState.isLoadingData && (
                        <StyledHeading
                            className='unselectable'
                            component="h2"
                            color={theme.palette.darkBaby}>
                            Preparing your data...
                        </StyledHeading>
                    )}
                    <ForceGraph2D
                        ref={setFgm as any}
                        backgroundColor={theme.palette.background.default}
                        width={width}
                        height={height}
                        graphData={graphState.graphData}
                        onNodeClick={selectNode as any}
                        linkCanvasObject={drawLink}
                        // linkLabel={(link: any) => link.plural}
                        nodeId="_id"
                        nodeLabel={() => ''}
                        linkCanvasObjectMode={() => 'replace'}
                        nodeCanvasObject={drawNode()}
                    />
                </div>
                <GraphPopover/>
            </StyledGraphContainer>
            {canEdit ?  <ContextMenu /> : null}
        </>
    );
};

const DomainGraph: FunctionComponent = () => {
    return (
        <GraphContextProvider>
            <DomainGraphBody/>
        </GraphContextProvider>
    );
};

export default DomainGraph;
