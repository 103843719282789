import {FunctionComponent} from "react";
import UserPopover from "./UserPopover";
import OrgPopover from "./OrgPopover";
import DomainPopover from "./DomainPopover";
import EntityPopover from "./EntityPopover";
import {BaseUser} from "../../features/authentication/types/userTypes";
import {BaseOrg} from "../../features/ontology/types/orgTypes";
import {BaseDomain} from "../../features/ontology/types/domainTypes";
import {BaseEntity} from "../../features/ontology/types/entityTypes";

interface ResourcePopoverProps {
    resource:
        | BaseUser
        | BaseOrg
        | BaseDomain
        | BaseEntity;
}

const ResourcePopover: FunctionComponent<ResourcePopoverProps> = ({resource, }) => {
	const resourceType = resource._object;

    switch (resourceType) {
        case 'User':
            return <UserPopover user={resource} />;
        case 'Organization':
            return <OrgPopover org={resource} />;
        case 'Domain':
            return <DomainPopover domain={resource} />;
        case 'Entity':
            return <EntityPopover entity={resource} />;
        default:
            return null;
    }
};

export default ResourcePopover;
