import {
	useGetOrgAdminsQuery,
	useGetOrgMembersQuery,
	useGetOrgGovernorsQuery,
	useGetOrgEngineersQuery,
	useGetOrgPublishersQuery,
	useGetOrgContactsQuery,
	useGetOrgMaintainersQuery,
	useGetOrgFundersQuery,
	useGetOrgRightsHoldersQuery,
	useGetOrgReportersQuery
} from '../../api';
import { BaseUser } from '../../authentication/types/userTypes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';

const emptyAdmins: BaseUser[] = [];
const emptyGovs: BaseUser[] = [];
const emptyMembers: BaseUser[] = [];
const emptyEngineers: BaseUser[] = [];
const emptyPublishers: BaseUser[] = [];
const emptyMaintainers: BaseUser[] = [];
const emptyRightsHolders: BaseUser[] = [];
const emptyFunders: BaseUser[] = [];
const emptyContacts: BaseUser[] = [];
const emptyReporters: BaseUser[] = [];

const useCombinedOrgMembers = (orgId: number | null | undefined) => {
	//  selectorize these to prevent a ton of unneeded re-renders as query loads/re-loads
	const { admins } = useGetOrgAdminsQuery(
		orgId ? { orgId } : skipToken,
		{ selectFromResult: ({ data }) => ({ admins: data ?? emptyAdmins }) }
	);
	const { governors } = useGetOrgGovernorsQuery(
		orgId ? { orgId } : skipToken,
		{ selectFromResult: ({ data }) => ({ governors: data ?? emptyGovs }) }
	);
	const { members } = useGetOrgMembersQuery(
		orgId ? { orgId } : skipToken,
		{ selectFromResult: ({ data }) => ({ members: data ?? emptyMembers }) }
	);
	const { engineers } = useGetOrgEngineersQuery(
		orgId ? { orgId } : skipToken,
		{ selectFromResult: ({ data }) => ({ engineers: data ?? emptyEngineers }) }
	);
	const { publishers } = useGetOrgPublishersQuery(
		orgId ? { orgId } : skipToken,
		{ selectFromResult: ({ data }) => ({ publishers: data ?? emptyPublishers }) }
	);
	const { contacts } = useGetOrgContactsQuery(
		orgId ? { orgId } : skipToken,
		{ selectFromResult: ({ data }) => ({ contacts: data ?? emptyContacts }) }
	);
	const { maintainers } = useGetOrgMaintainersQuery(
		orgId ? { orgId } : skipToken,
		{ selectFromResult: ({ data }) => ({ maintainers: data ?? emptyMaintainers }) }
	);
	const { funders } = useGetOrgFundersQuery(
		orgId ? { orgId } : skipToken,
		{ selectFromResult: ({ data }) => ({ funders: data ?? emptyFunders }) }
	);
	const { rightsHolders } = useGetOrgRightsHoldersQuery(
		orgId ? { orgId } : skipToken,
		{ selectFromResult: ({ data }) => ({ rightsHolders: data ?? emptyRightsHolders }) }
	);
	const { reporters } = useGetOrgReportersQuery(
		orgId ? { orgId } : skipToken,
		{ selectFromResult: ({ data }) => ({ reporters: data ?? emptyReporters }) }
	);

	return useMemo(
		() => ({
			admins,
			governors,
			members,
			engineers,
			publishers,
			contacts,
			maintainers,
			funders,
			rightsHolders,
			reporters,
		}),
		[
			admins,
			governors,
			members,
			engineers,
			publishers,
			contacts,
			maintainers,
			funders,
			rightsHolders,
			reporters
		]
	);
};

export default useCombinedOrgMembers;
