import Typography from "./Typography";
import {FunctionComponent} from "react";
import {ListItemContent} from "../List";

const LastActive: FunctionComponent<{user: any, position?: string}> = ({user, position}) => {
    return user.lastActive ?
        <ListItemContent style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: position,
            justifyItems: position,
        }}>
            <Typography variant="caption1">
                {user.lastActive === 'online'
                    ? 'is online'
                    : `active ${user.lastActive}`}
            </Typography>
        </ListItemContent>
        : null;
}

export default LastActive;
