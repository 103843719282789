import {mapDataCatalogsToNavList, mapDataSetsToNavList} from '../../helpers';
import {
	GetLiveDataCatalogsResponse,
	GetLiveDataSetsResponse, LiveDataSets
} from '../../types/dataTypes';
import {SelectedSourceData} from '../../types/uiTypes';
import { SourceNavItemList, SourceSetter } from '../../types/uiTypes';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import {ListItemContent, ListItemControls} from "../../../../common/List";
import Typography from "../../../../common/text/Typography";
import {SourceListItem} from "../styledComponents";
import {useNavigate} from "react-router";
import useActiveOrgData from "../../../ontology/hooks/useActiveOrgData";
import {useParams} from "react-router-dom";

	// height: 100%;
const StyledListContainer = styled.section`
	border-right: 2px solid ${(p) => p.theme.palette.divider};
	overflow-y: auto;
	position: relative;
	height: calc(100vh - ${(p) => p.theme.navHeight * 2 + p.theme.panelGutter * 4}px);
	padding: ${(p) => p.theme.spacing(1)}};
`;
	// /* min-width: 300px; */
	// /* max-width: 450px; */

interface CatalogNavProps {
	datasets: GetLiveDataSetsResponse;
	// setter: SourceSetter;
	// currentlySelected: SelectedSourceData;
	// setCardData: (cardData: LiveDataSets) => void;
	// sourceId: number | symbol;
}

const CatalogNav: FunctionComponent<CatalogNavProps> = ({
	datasets
}) => {
	const {username} = useParams();
	const navigator = useNavigate();
	return (
		<StyledListContainer>
			<ul>
				{!!datasets && datasets.map((dataset) => {
					return <SourceListItem
						button
						key={dataset.name}
						// className={clsx(active && 'browser-item-is-open')}
						onClick={() => navigator(
							`/data/${username}/${dataset.sourceName}/${dataset.catalogName}/${dataset.datasetName}`,
							{ replace: false }
							)}
					>
						<ListItemContent>
							<Typography color={'oldManGray'} variant='h5'>
								{dataset.datasetName}
							</Typography>
							{/*{mapDataSetsToNavList(*/}
							{/*	datasets as LiveDataSets,*/}
							{/*	setter,*/}
							{/*	// setCardData as DatasetCardSetter,*/}
							{/*	currentlySelected,*/}
							{/*)}*/}
						</ListItemContent>
					</SourceListItem>
				})}
			</ul>
		</StyledListContainer>
	);
};

export default CatalogNav;
