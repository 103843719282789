import timezones from '../../../assets/tzs.json';
import FlexContainer from '../../../common/FlexContainer';
import {FormResults,} from '../../../common/Form';
import Spinner from '../../../common/loading/Spinner';
import Typography from '../../../common/text/Typography';
import {useCreateEventMutation, useGetAttributesQuery} from '../../api';
import {extractQueryErrMessage} from '../../api/helpers';
import {GetEntityAttrsResponse} from '../../ontology/types/attributeTypes';
import {EntityActionFormProps} from '../common/commonTypes';
import {renderDerivationSubfields} from '../common/jsxHelpers';
import {
    createEventFormDefaults,
    createEventFormToPayload,
    validateTimeResolutionSubmission,
} from './createEventHelpers';
import {CreateEventFormValues, timeResolutions} from './createEventTypes';
import React, {FunctionComponent, useCallback} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import styled from 'styled-components';
import AppModal from "../../../common/modals/AppModal";
import TimezoneSelect from "../../../common/inputs/TimezoneSelect";
import TemporalResolutionSelect
    from "../../../common/inputs/TemporalResolutionSelect";
import IsRequiredInput from "../../../common/inputs/IsRequiredInput";
import useModalType from "../../HUD/hooks/useModalType";
import {PrependAttrFormValues} from "../prependAttribute/prependAttributeTypes";

const StyledFieldContainer = styled(FlexContainer)`
	width: 100%;
	padding: 0 ${(p) => p.theme.spacing(1)};
`;

const CreateEventForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
    const {objectId: _id, canEdit} = modalProps;

    const formDefaults = createEventFormDefaults();
    const {handleSubmit, register, resetField, reset, formState, setError} =
        useForm<CreateEventFormValues>({
            defaultValues: formDefaults,
        });

    const [createEvent, mutationResults] = useCreateEventMutation();

    const validatingRegister = useCallback(
        (inputName: keyof CreateEventFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const onSubmit: SubmitHandler<CreateEventFormValues> = (vals) => {
        const canSubmit = validateTimeResolutionSubmission(setError, vals);

        if (canSubmit) {
            return createEvent({
                entityId: _id,
                body: createEventFormToPayload(vals),
            });
        }

        return;
    };

    const {
        isLoading,
        isError,
        error,
        data: attrData,
        isUninitialized,
    } = useGetAttributesQuery({entityId: _id});

    if (isLoading || isUninitialized) {
        return (
            <FlexContainer justifyContent="center">
                <Typography paragraph variant='h5'>Loading
                    attributes...</Typography>
                <Spinner/>
            </FlexContainer>
        );
    }

    if (isError) {
        return (
            <FlexContainer justifyContent="center">
                <Typography color="error" paragraph>
                    {extractQueryErrMessage(error)}
                </Typography>
            </FlexContainer>
        );
    }

    return (
        <AppModal
            label={"Create Event"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit}>
            {renderDerivationSubfields({
                isDerivation: true,
                formState,
                resetField,
                validatingRegister,
                mutationResults
            })}
            <TemporalResolutionSelect
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            <TimezoneSelect
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            <FormResults
                isError={mutationResults.isError}
                error={mutationResults.error}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default CreateEventForm;
