import Button from 'common/buttons/Button';
import { FormBody, FormHeading, FormResults } from 'common/Form';
import Textbox from 'common/inputs/TextBox';
import { useCompleteRegistrationMutation } from 'features/api';
import React, {FunctionComponent, useCallback} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import styled from "styled-components";
import FlexContainer from "../../../common/FlexContainer";
import useCurrentUser from "../hooks/useUser";
import theme from "../../../common/theme/theme";
import useAuthStatus from "../hooks/useAuthStatus";
import Typography from "../../../common/text/Typography";
import SelectMonth from "../../../common/inputs/SelectMonthOfYear";
import SelectDayOfMonth from "../../../common/inputs/SelectDayOfMonth";

interface RegisterFormValues {
	firstName: string;
	lastName: string;
	username: string;
	month: string;
	day: string;
	year: string;
	password: string;
}

const StyledLogo = styled.img`
	width: 100%;
	height: auto;
	max-height: 30vh;
	margin-top: ${(p) => p.theme.spacing(1)};
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		display: none;
	}
`;

const StyledMain = styled.div`
	background-color: ${(p) => p.theme.palette.darkBaby};
	width: 100%;
	height: 100vh;
	color: ${(p) => p.theme.palette.lightBaby};
`;

const StyledContainer = styled(FlexContainer)`
	margin: auto;
	justify-content: center;
	align-items: center;
	width: 40%;
	height: 100%;
	gap: ${(p) => p.theme.spacing(4)};

	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		width: 80%;
		flex-direction: column;
	}
`;


// TODO: in the near future we will likely want to push user to /feed
// or something like that to prevent excessive re-routing/rendering
// by pushing to root then hitting a bunch of re-routes based on the
// fact that user authenticated after completing this form.
const RegisterPage: FunctionComponent = () => {
	const navigate = useNavigate();
	const currentUser = useCurrentUser();
	const isAuthenticatedUser = useAuthStatus();
	// const searchParams = useSearchParams()[0];
	const params = useParams();

	const { handleSubmit, register, resetField, formState } = useForm<RegisterFormValues>({
		defaultValues: {
			firstName: '',
			lastName: '',
			username: '',
			password: '',
			month: '01',
			day: '1',
		},
	});

	const validatingRegister = useCallback(
		(inputName: keyof RegisterFormValues) =>
			register(inputName, {
				required: `${inputName} is required`,
			}),
		[register]
	);

	const [registerUser, registrationResult] =
		useCompleteRegistrationMutation();


	if (!!currentUser && isAuthenticatedUser	) {
		return <Navigate to="/" />;
	} else if (!params.token) {
		return <Navigate to="/" />;
	}

	const onSubmit: SubmitHandler<RegisterFormValues> = (vals) => {
		registerUser({
			registrationToken: params.token as string,
			...vals,
		});
	};

	const onSuccess = () => navigate('/');

	return (
		<StyledMain>
			<StyledContainer>
				<FormBody

					onSubmit={handleSubmit(onSubmit)}
					aria-labelledby="complete-registration-heading"
				>
					<FlexContainer
						flexDirection='column'
						gap='.5rem'
						justifyContent='center'
						alignItems='center'>
						<div style={{ padding: '1rem', width: '100%', borderBottom: `2px solid ${theme.palette.slickRick}` }}>

						<Typography
							style={{marginBottom: '1rem', alignSelf: 'start',}}
							variant="h1"
							id="complete-registration-heading">
							Sign Up
						</Typography>

						</div>

						<FlexContainer
						flexDirection='column'
						gap='.5rem'
						 style={{ padding: '1rem', width: '100%'}}
						justifyContent='center'
						alignItems='center'>

						<FlexContainer
						gap='.5rem'
						style={{minWidth: '350px', maxWidth: '550px', width: '100%'}}
						justifyContent='center'
						alignItems='center'>

					<Textbox
						style={{
							height: '42px',
						}}
						labelText="First name"
						error={formState.errors.firstName}
						{...register('firstName', {
							required: 'Please enter your first name',
						})}
					/>
					<Textbox
						style={{
							height: '42px',
							width: '100%'
						}}
						labelText="Last name"
						error={formState.errors.lastName}
						{...register('lastName', {
							required: 'Please enter your last name',
						})}
					/>
						</FlexContainer>
					<Textbox
						style={{
							height: '42px',
						}}
						labelText="Username"
						autoComplete="username"
						error={formState.errors.username}
						{...register('username', {
							required: 'Please enter a username',
						})}
					/>
					<Textbox
						style={{
							height: '42px',
						}}
						type="password"
						labelText="New password"
						autoComplete="new-password"
						error={formState.errors.password}
						{...register('password', {
							required: 'Please enter a password',
						})}
					/>
					{/*Providing your birthday helps make sure you get the right Facebook experience for your age. If you want to change who sees this, go to the About section of your profile. For more details, please visit our Privacy Policy.*/}
					{/*<FlexContainer*/}
					{/*	gap='.5rem'*/}
					{/*	style={{minWidth: '350px', maxWidth: '550px', width: '100%'}}*/}
					{/*	justifyContent='center'*/}
					{/*	alignItems='center'>*/}
					{/*	<SelectMonth*/}
					{/*		mutationResults={registrationResult}*/}
					{/*		formState={formState}*/}
					{/*		validatingRegister={validatingRegister}*/}
					{/*		resetField={resetField}*/}
					{/*	/>*/}
					{/*	<SelectDayOfMonth*/}
					{/*		mutationResults={registrationResult}*/}
					{/*		formState={formState}*/}
					{/*		validatingRegister={validatingRegister}*/}
					{/*		resetField={resetField}*/}
					{/*	/>*/}
					{/*</FlexContainer>*/}
					{/*You can change who sees your gender on your profile later. Select Custom to choose another gender, or if you'd rather not say.*/}
						<Typography style={{padding: `0 ${theme.spacing(.5)}`}} variant={'caption1'}>
							People who use our service may have uploaded your
							contact information to Futuremodel. <a>Learn more.</a>
						</Typography>
<Typography style={{padding: `0 ${theme.spacing(.5)}`}} variant={'caption1'}>
							By clicking Sign Up, you agree to our <a>Terms</a>,{' '}
							<a>Privacy Policy</a>, and <a>Cookies Policy</a>.
							{/*You may receive*/}
							{/*SMS Notifications from us and can opt out any time.*/}
						</Typography>
					<Button
						style={{
							color: theme.palette.primary.main,
							height: '42px',
							width: '100%',
						}}
						type="submit">
						<Typography variant={'h5'}>
							Sign Up
						</Typography>
					</Button>
					<FormResults
						{...registrationResult}
						validationErrors={{}}
						onSuccess={onSuccess}
						onSuccessDelay={1000}
					/>
						</FlexContainer>
					</FlexContainer>
				</FormBody>
				<StyledLogo
					style={{position: 'relative'}}
					src='https://storage.googleapis.com/davis-associates.appspot.com/fm-hero-figure.svg'
					role="presentation"
				/>
			</StyledContainer>
		</StyledMain>
	);
};

export default RegisterPage;
