import React, {FunctionComponent} from "react";
import {ActionFieldProps,} from "../../features/actions/common/commonTypes";
import SubtleSelect from "./SubtleSelect";
import {harvestErrors} from "./SubtleTextBox";

interface AttributeSubtypeSelectParams extends ActionFieldProps {
    opts: any[];
}

const AttributeSubtypeSelect: FunctionComponent<AttributeSubtypeSelectParams> = ((
    {
        inline=true,
        opts,
        formState,
        resetField,
        validatingRegister
    }
    ) => {
    // What is the subtype of this attribute?
    return <SubtleSelect
        inline={inline}
        label={`Other Type`}
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('otherType')}
        isDirty={Boolean(formState.dirtyFields.otherType)}
        {...validatingRegister('otherType', {})}
    >
        {opts.map(({value, displayValue}) => (
            <option value={value} key={value}>
                {displayValue}
            </option>
        ))}
    </SubtleSelect>;
})

export default AttributeSubtypeSelect;
