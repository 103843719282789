import useAuthStatus from '../authentication/hooks/useAuthStatus';
import FeedPage from '../feed/components';
import TeaserPage from './TeaserPage';
import { FunctionComponent } from 'react';
import NavPanel from "../navigation/components/Navbar";
import HUD from "../HUD";
import useCurrentUser from "../authentication/hooks/useUser";
import {useNavigate} from "react-router";
import { Navigate } from 'react-router-dom';

const LandingPage: FunctionComponent = () => {
	const isAuthenticatedUser = useAuthStatus();
	const currentUser = useCurrentUser();
	if (isAuthenticatedUser) {
		if (!currentUser.tosAccepted) {
			return <Navigate to='/terms-of-service' />;
		} else if (!currentUser.isOnboarded) {
			return <Navigate to='/onboarding' />;
		} else {
			return  <>
				<NavPanel />
				<HUD/>
				<FeedPage />
				</>;
		}
	} else {
		return <TeaserPage />;
	}
};

export default LandingPage;
