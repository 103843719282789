import FlexContainer from '../../../common/FlexContainer';
import {FormResults} from '../../../common/Form';
import Spinner from '../../../common/loading/Spinner';
import Typography from '../../../common/text/Typography';
import {useOperateAttrMutation} from '../../api';
import {mergeErrorStates, extractQueryErrMessage} from '../../api/helpers';
import {BaseAttribute} from '../../ontology/types/attributeTypes';
import {
    renderReferenceField,
    renderDerivationSubfields,
} from '../common/jsxHelpers';
import useAttrNeighbors from '../hooks/useAttrNeighbors';
import {
    subtractAttrFormDefaults,
    subtractAttrFormToPayload,
} from './subtractAttributeHelpers';
import {SubtractAttrFormValues} from './subtractAttributeTypes';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import MissingValuesInput from "../../../common/inputs/MissingValuesInput";
import ValueTypeSelect from "../../../common/inputs/ValueTypeSelect";
import IsDerivationInput from "../../../common/inputs/IsDerivationInput";
import useModalType from "../../HUD/hooks/useModalType";
import {PrependAttrFormValues} from "../prependAttribute/prependAttributeTypes";
import Loading from "../../../common/loading/Loading";

const SubtractAttributeForm: FunctionComponent = () => {
    const {modalProps, closeModal} = useModalType();
	const {objectId: _id, canEdit} = modalProps;
    const formDefaults = subtractAttrFormDefaults();

    const {handleSubmit, register, resetField, reset, formState, watch} =
        useForm<SubtractAttrFormValues>({
            defaultValues: formDefaults,
        });

    const [watchedDerivationFlag, watchedUsingReferenceValue] = watch([
        'isDerivation',
        'usingStaticReference',
    ]);

    const validatingRegister = useCallback(
        (inputName: keyof SubtractAttrFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    // prevent filtering from running every render
    const filter = useCallback(
        (attr: BaseAttribute) => attr.type === 'quantity',
        []
    );

    const queryRes = useAttrNeighbors(_id, filter);

    // useEffect(() => {
    //     if (queryRes.isSuccess) {
    //         closeModal();
    //     }
    // }, [queryRes.isSuccess, closeModal]);

    const [subtractAttr, mutationResults] = useOperateAttrMutation();

    const mergedErrs = mergeErrorStates(queryRes, mutationResults);

    const onSubmit: SubmitHandler<SubtractAttrFormValues> = (vals, e) => {
        e?.preventDefault();
        subtractAttr({
            attrId: _id,
            body: subtractAttrFormToPayload(vals),
        });
    };

    if (queryRes.isError) {
        return (
            <FlexContainer justifyContent="center">
                <Typography color="error" paragraph variant='h5'>
                    {extractQueryErrMessage(queryRes.error)}
                </Typography>
            </FlexContainer>
        );
    }

    return (
        <AppModal
            label={"Subtract Attribute"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit}>
            <IsDerivationInput
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            {renderDerivationSubfields({
                isDerivation: watchedDerivationFlag === 'true',
                formState,
                validatingRegister,
                resetField,
                mutationResults
            })}
            <ValueTypeSelect
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            {queryRes.isLoading || queryRes.isUninitialized
                ? <Loading />
                : renderReferenceField(
                'otherId',
                'otherValue',
                watchedUsingReferenceValue === 'true',
                validatingRegister,
                queryRes.attrNeighbors,
                formState
            )}
            <MissingValuesInput
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            <FormResults
                isError={!!mergedErrs}
                error={mergedErrs}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default SubtractAttributeForm;
