import { FunctionComponent } from 'react';
import useActiveDomainData from "../../../features/ontology/hooks/useActiveDomainData";
import {ContributorsStat, FollowersStat, ViewsStat} from "../BaseStats";
import {DataCatalogIcon, DataSetIcon, DataSourceIcon, DataColumnIcon} from "common/icons";
import useActiveOrgData from "../../../features/ontology/hooks/useActiveOrgData";
import Typography, {ColorProp} from "../../text/Typography";
import FlexContainer from "../../FlexContainer";
import {
	FontAwesomeIcon,
	FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import IconButton from "../../buttons/IconButton";


const DataStats: FunctionComponent<{color?: ColorProp}> = ({color}) => {
	const { activeItem } = useActiveOrgData();

	const shouldDisplay = true

	if (shouldDisplay) {
		return (
			<FlexContainer className='unselectable' gap={'1rem'} justifyContent={'center'}>
				<FlexContainer className='unselectable' gap={'.5rem'} alignItems={'center'}>
					<Typography color={color ? color : 'oldManGray'} variant={'caption2'}>
						{`${activeItem?.stats.data.sources ?? 0}`}
					</Typography>
					<IconButton
						size={'xs'}
						iconSize={'lg'}
						icon={DataSourceIcon}
						color={'oldManGray'} />
				</FlexContainer>
				<FlexContainer className='unselectable' gap={'.5rem'} alignItems={'center'}>
					<Typography color={color ? color : 'oldManGray'} variant={'caption2'}>
						{`${activeItem?.stats.data.catalogs ?? 0}`}
					</Typography>
					<IconButton
						size={'xs'}
						iconSize={'lg'}
						icon={DataCatalogIcon}
						color={'oldManGray'} />
				</FlexContainer>
				<FlexContainer className='unselectable' gap={'.5rem'} alignItems={'center'}>
					<Typography color={color ? color : 'oldManGray'} variant={'caption2'}>
						{`${activeItem?.stats.data.datasets ?? 0}`}
					</Typography>
					<IconButton
						size={'xs'}
						iconSize={'lg'}
						icon={DataSetIcon}
						color={'oldManGray'} />
				</FlexContainer>
				<FlexContainer className='unselectable' gap={'.5rem'} alignItems={'center'}>
					<Typography color={color ? color : 'oldManGray'} variant={'caption2'}>
						{`${activeItem?.stats.data.columns ?? 0}`}
					</Typography>
					<IconButton
						size={'xs'}
						iconSize={'lg'}
						icon={DataColumnIcon}
						color={'oldManGray'} />
				</FlexContainer>
			</FlexContainer>
		);
	}

	return null;
};

export default DataStats;
