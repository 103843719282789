import React, {FunctionComponent} from "react";
import Typography from "../text/Typography";
import {formFieldHasErrors, genErrorIdFromLabel} from "../Form";
import SubtleSelect from "./SubtleSelect";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {BaseAttribute} from "../../features/ontology/types/attributeTypes";
import {harvestErrors} from "./SubtleTextBox";

interface RestrictionAttributeSelectProps extends ActionFieldProps {
    attributes: BaseAttribute[];
}

const RestrictionAttributeSelect: FunctionComponent<RestrictionAttributeSelectProps> = (
    {
        attributes,
        formState,
        resetField,
        validatingRegister
    }
    ) => {
    //Select an attribute to{' '}
    //                 {restrictionKind === 'specialization'
    //                     ? 'specialize'
    //                     : 'restrict'}{' '}
    //                 {singular} on:

    //            aria-errormessage={genErrorIdFromLabel('restrictedId')}
    //             aria-invalid={formFieldHasErrors(
    //                 'restrictedId',
    //                 formState
    //             )}
    return <SubtleSelect
        inline={true}
        label={`Type`}
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('restrictedId')}
        isDirty={Boolean(formState.dirtyFields.restrictedId)}
        {...validatingRegister('restrictedId', {
                required: 'An attribute to restrict on must be selected',
                valueAsNumber: true,
                validate: (v: number) => v !== 0
                    ? true
                    : 'An attribute to restrict on must be selected',
            })}
    >
        <option value={0}>none</option>
        {attributes && attributes.map((attr) => (
            <option key={attr._id} value={attr._id}>
                {attr.plural}
            </option>
        ))}
    </SubtleSelect>;
}

export default RestrictionAttributeSelect;
