import OrgConsole from './OrgConsole';
import DomainConsole from './DomainConsole';
import Heading from 'common/text/Heading';
import { ContextParams } from 'features/navigation/types/navigationContextTypes';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

const getContextLevel = (username?: string, domain?: string) =>
	domain ? 'domain' : username ? 'username' : null;

const GovernancePanel: FunctionComponent<{container?: any;}> = ({container='100%'}) => {
	const { username, domain } = useParams<ContextParams>();

	const navContextLevel = getContextLevel(username, domain);

	if (navContextLevel === 'domain') {
		return <DomainConsole container={container} />;
	}

	if (navContextLevel === 'username') {
		return <OrgConsole container={container} />;
	}

	return <Heading component="h5">
		This space available
	</Heading>;
};

export default GovernancePanel;
