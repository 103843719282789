import {
	mapSourceSummariesToNavList,
	mapDataSetsToNavList,
	mapDataCatalogsToNavList,
	mapSourceDataToNav
} from '../../helpers';
import {
	GetLiveDataCatalogsResponse,
	GetLiveDataSetsResponse,
	GetSourceSummariesResponse, LiveDataCatalogs, LiveDataSets
} from '../../types/dataTypes';
import { SelectedSourceData } from '../../types/uiTypes';
import { SourceNavItemList, SourceSetter} from '../../types/uiTypes';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import {StyledList, StyledSourceNavButton} from "../styledComponents";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Typography from "../../../../common/text/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

	// height: 100%;
const StyledListContainer = styled.section`
	border-right: 2px solid ${(p) => p.theme.palette.divider};
	overflow-y: auto;
	position: relative;
	max-height: calc(100vh - ${(p) => p.theme.navHeight * 2 - p.theme.panelGutter * 4}px);
	padding: ${(p) => p.theme.spacing(1)};
`;
	// /* min-width: 300px; */
	// /* max-width: 450px; */

interface SourceNavProps {
	catalogs: GetLiveDataCatalogsResponse | LiveDataSets;
	sourceId: number | symbol;
	setter: SourceSetter;
	currentlySelected: SelectedSourceData;
}

interface CatalogNavProps {
	datasets: GetLiveDataSetsResponse;
	setter: SourceSetter;
	currentlySelected: SelectedSourceData;
	sourceId: number | symbol;
}

interface BrowserNavProps {
	sources: GetSourceSummariesResponse;
	setter: SourceSetter;
	currentlySelected: SelectedSourceData;
}

const StyledHeader = styled.span`
	color: ${(p) => p.theme.palette.text.paper};
`;


const StyledIcon = styled(FontAwesomeIcon)`
	padding-right: ${(p) => p.theme.spacing(1)};
`;

export const BrowserNav: FunctionComponent<BrowserNavProps> = ({
	sources,
	setter,
	currentlySelected
}) => {


	return (
		<StyledListContainer>
			{/*<span style={{*/}
			{/*	display: 'inline-flex',*/}
			{/*	alignItems: 'center'*/}
			{/*}}>*/}
			{/*</span>*/}
			<ul>
				{mapSourceSummariesToNavList(
					sources,
					setter,
					// setCardData,
					currentlySelected
				)}
			</ul>
		</StyledListContainer>
	);
};

export const SourceNav: FunctionComponent<SourceNavProps> = ({
	catalogs,
	setter,
	currentlySelected,
	// setCardData,
	sourceId,
}) => {
	return (
		<StyledListContainer>
			<ul>
				{mapDataCatalogsToNavList(
					catalogs,
					setter,
					currentlySelected,
					// setCardData as CatalogCardSetter,
					sourceId,
				)}
			</ul>
		</StyledListContainer>
	);
};

export const CatalogNav: FunctionComponent<CatalogNavProps> = ({
	datasets,
	setter,
	currentlySelected,
	// setCardData,
	sourceId
}) => {
	console.log(datasets)
	return (
		<StyledListContainer>
			<ul>
			{datasets ? mapDataSetsToNavList(
				datasets,
				setter,
				// setCardData,
				currentlySelected
			) : null}
		</ul>
			{/*<ul>*/}
			{/*	*/}
			{/*	{mapDataSetsToNavList(*/}
			{/*		datasets,*/}
			{/*		// setter,*/}
			{/*		setCardData as DatasetCardSetter,*/}
			{/*		// currentlySelected*/}
			{/*	)}*/}
			{/*</ul>*/}
		</StyledListContainer>
	);
};

export default SourceNav;
