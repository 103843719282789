import SearchBar from '../../../../common/inputs/IndividualSearchBar';
import Breadcrumbs from '../Breadcrumbs';
import ViewToggle, {DataViewToggle} from '../../../../common/inputs/ViewToggle';
import NavLogo from './NavLogo';
import ProfileMenu from './ProfileMenu';
import FlexContainer from 'common/FlexContainer';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Notifications from "./Notifications";
import Typography from "../../../../common/text/Typography";
import theme from "../../../../common/theme/theme";
import {faPlus, faXmark} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../common/buttons/IconButton";
import DataStats from "../../../../common/stats/DataStats";
import DataCrumbs from "../DataCrumbs";
import useViewModeParams from "../../hooks/useViewModeParams";
import useActiveLandingData from "../../../ontology/hooks/useActiveLandingData";
// import useAuthStatus from 'features/authentication/hooks/useAuthStatus';
// import { useNavigate, useLocation } from 'react-router-dom';

const StyledHeader = styled.header<{width?: number, useTranslucency: boolean;}>`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: ${(p) => p.width ? p.width : 100}vw;
	height: ${(p) => `${p.theme.navHeight + p.theme.panelGutter}px`};
	background: transparent;
	top: 0;
	left: 0;
	margin: 0;
	z-index: ${(p) => p.theme.zIndex.navPanel};
	color: ${(p) => p.theme.palette.darkBaby};
	${(props) => props.useTranslucency ? props.theme.translucent : ''}
`;

const StyledSubHeader = styled.header<{width?: number}>`
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: flex-end;
	position: absolute;
	width: calc(${(p) => p.theme.sideControllerWidth + p.theme.panelGutter * 2}px);
	height: ${(p) => `${p.theme.navHeight}px`};
	background: transparent;
	margin: 0;
	padding: 0;
	z-index: ${(p) => p.theme.zIndex.navPanel};
	color: ${(p) => p.theme.palette.darkBaby};
`;
	// top: ${(p) => `${p.theme.navHeight + p.theme.panelGutter}px`};

					// <ConnectSourceButton />
					// {mode === 'data' ? <ViewToggle /> : null}
const StyledDataSubHeader = styled.header<{width?: number}>`
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	border-top: 1px solid ${(p) => p.theme.palette.divider};
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	width: 100%;
	position: fixed;
	height: ${(p) => `${p.theme.navHeight}px`};
	background: transparent;
	
	bottom: 0;
	margin: 0;
	padding: 0;
	z-index: ${(p) => p.theme.zIndex.navPanel};
	color: ${(p) => p.theme.palette.darkBaby};
`;
	// top: ${(p) => `${p.theme.navHeight + p.theme.panelGutter}px`};
	// pointer-events: none;

	// padding: ${(p) => p.theme.panelGutter}px;
const StyledNav = styled.nav<{flexDirection?: string; gap?: number;}>`
	width: calc(100vw - ${(p) => `${p.theme.spacing(2)}`});
	height: calc(${(p) => `${p.theme.navHeight}px - .5rem`});
	background: transparent;
	gap: ${(p) => p.theme.panelGutter}px;

	display: flex;
	justify-content: space-between;
	align-content: center;
	${(p) => p.flexDirection 
	? `${p.flexDirection === 'column' 
		? 'row-' 
		: 'column-'}gap: ${p.gap}px;` 
	: null}
`;
	// ${(props) => props.useTranslucency ? props.theme.translucent : ''}

const StyledNavTools = styled(FlexContainer)`
	//  32px is the height of IconButton @ size "sm"
	/* height: '32px; */
	display: flex;
	flex-direction: row;
	justify-items: center;
	justify-content: center;
	align-content: center;
	gap: ${(p) => p.theme.panelGutter}px;
`;

const NavPanel: FunctionComponent = () => {

	const { activeItem: activeResource } = useActiveLandingData();
	const {getViewMode} = useViewModeParams();
	const mode = getViewMode();

	return (
		<>
			<StyledHeader useTranslucency={true}>
				<StyledNav>
					<FlexContainer
						alignItems="center"
						alignContent="center"
						justifyItems="flex-start"
						justifyContent="flex-start"
						gap="4px"
					>
						<NavLogo />
						{activeResource._object === 'Organization' ?
							mode === 'data'
								? <DataCrumbs />
								: <Breadcrumbs/>
							: null }
					</FlexContainer>
						{activeResource._object === 'Organization'
							? mode === 'feed' || mode === 'data'
								? null : <ViewToggle /> : null}
					<StyledNavTools
						flexDirection='row'
						alignItems="center"
						alignContent="center"
						justifyItems="center"
						justifyContent="center"
						gap="4px"
					>
						<SearchBar />
						{/*<Notifications />*/}
						<ProfileMenu />
					</StyledNavTools>
				</StyledNav>
			</StyledHeader>
			{mode === 'data'
				? <StyledDataSubHeader>
					<StyledNavTools
						flexDirection='row'
						alignItems="flex-end"
						alignContent="flex-end"
						justifyItems="space-between"
						justifyContent="space-between"
						gap="1rem"
					>
						<DataStats />
					</StyledNavTools>
				</StyledDataSubHeader>
				: null}
				</>
	)
};

//<StyledDataSubHeader>
// 					<StyledNavTools
// 						flexDirection='row'
// 						alignItems="flex-end"
// 						alignContent="flex-end"
// 						justifyItems="space-between"
// 						justifyContent="space-between"
// 						gap="1rem"
// 					>
// 						<NavStats/>
// 					</StyledNavTools>
// 				</StyledDataSubHeader>

export const DataNav: FunctionComponent = () => {
	return (
		<StyledHeader useTranslucency={true} style={{borderBottom: `1px solid ${theme.palette.divider}`}}>
			<StyledNav>
				<FlexContainer
					alignItems="center"
					alignContent="center"
					justifyItems="flex-start"
					justifyContent="flex-start"
					gap="8px"
				>
					<NavLogo />
					<DataCrumbs />
				</FlexContainer>
				<FlexContainer
					alignItems="center"
					alignContent="center"
					justifyItems="flex-start"
					justifyContent="flex-start"
					gap="2rem"
				>
					<DataStats color='oldManGray' />
				</FlexContainer>
				<StyledNavTools
					flexDirection='row'
					alignItems="center"
					alignContent="center"
					justifyItems="center"
					justifyContent="center"
					gap="8px"
				>
					<SearchBar />
					<ViewToggle />
					<ProfileMenu />
				</StyledNavTools>
			</StyledNav>
		</StyledHeader>
	)
};

export const DataModalNav: FunctionComponent<{width?: number, handleClose: any, handleDisplay?: any}> = ({width, handleClose, handleDisplay}) => {
	return (
		<StyledHeader style={{paddingLeft: '.5rem'}} width={width} useTranslucency={true}>
			<StyledNav>
				<FlexContainer
					alignItems="center"
					alignContent="center"
					justifyItems="flex-start"
					justifyContent="flex-start"
					gap="8px"
				>
					<NavLogo />
					<Typography color={'oldManGray'} variant={'h5'}>
						Source Browser
					</Typography>
					<DataCrumbs />
				</FlexContainer>
				<StyledNavTools
					flexDirection='row'
					alignItems="center"
					alignContent="center"
					justifyItems="center"
					justifyContent="center"
					gap=".5rem"
				>
					<DataStats color='oldManGray'/>
				</StyledNavTools>
					{handleClose ?
					<IconButton
						variant='transparent'
						fillColor='oldManGray'
						onClick={handleClose}
						icon={faXmark}
						aria-label="close source browser"
					/> : null}
			</StyledNav>
		</StyledHeader>
	)
};

export default NavPanel;
