import {skipToken} from '@reduxjs/toolkit/dist/query';
import theme from 'common/theme/theme';
import {useGetEntityGraphQuery} from 'features/api';
import GraphPopover from 'common/viz/ForceGraph/GraphPopover';
import {
    StyledGraphContainer,
    StyledHeading,
} from 'common/viz/ForceGraph/styledComponents';
import {drawNode, drawLink} from 'features/graph/helpers';
import useGraphDataLoader from 'features/graph/hooks/useGraphDataLoader';
import useGraphHandlers from 'features/graph/hooks/useGraphHandlers';
import GraphContextProvider from 'features/graph/state/GraphContextProvider';
import useActiveEntityData from 'features/ontology/hooks/useActiveEntityData';
import React, {FunctionComponent, useMemo} from 'react';
import {default as ForceGraph2D, GraphData} from 'react-force-graph-2d';
import ContextMenu from "../../../../common/ContextMenu";

const EntityGraphBody: FunctionComponent = () => {
    const {activeItem: activeEntity} = useActiveEntityData();

    const canEdit = activeEntity && activeEntity.userContext.permissions.edit
        || activeEntity && activeEntity.userContext.permissions.admin;

    const queryRes = useGetEntityGraphQuery(
        activeEntity ? {entityId: activeEntity._id} : skipToken
    );

    useGraphDataLoader(queryRes);

    const {
        selectLink,
        selectNode,
        width,
        height,
        setFgm,
        graphState: {isLoadingData, graphData},
    } = useGraphHandlers();

    const neighbors: GraphData = useMemo(() => {
        if (activeEntity) {
            const entityId = activeEntity._id;
            const touchedNodes = new Set<number>();
            touchedNodes.add(entityId);
            const {links, nodes} = graphData;

            const neighborLinks = links.filter(({source, target}) => {
                // if (source === entityId || target === entityId) {
                // 	//  Set prevents duplicates
                // 	touchedNodes.add(source as number);
                // 	touchedNodes.add(target as number);
                // 	return true;
                // }
                touchedNodes.add(source as number);
                touchedNodes.add(target as number);
                return true;

                // return false;
            });

            const neighborNodes = (nodes as { _id: number }[]).filter(
                ({_id}) => touchedNodes.has(_id)
            );

            return {
                links: neighborLinks,
                nodes: neighborNodes,
            };
        }

        return {
            links: [],
            nodes: [],
        };
    }, [activeEntity, graphData]);

    return (
        <>
            <StyledGraphContainer>
                <div>
                    {isLoadingData && (
                        <StyledHeading className='unselectable' component="h2"
                                       color={theme.palette.darkBaby}>
                            Preparing your data...
                        </StyledHeading>
                    )}
                    <ForceGraph2D
                        ref={setFgm as any}
                        backgroundColor={theme.palette.background.default}
                        width={width}
                        height={height}
                        graphData={neighbors}
                        onNodeClick={selectNode as any}
                        linkCanvasObject={drawLink}
                        onLinkClick={selectLink as any}
                        nodeId="_id"
                        nodeLabel={() => ''}
                        linkCanvasObjectMode={() => 'replace'}
                        nodeCanvasObject={drawNode()}
                    />
                </div>
                <GraphPopover popperOffset={[0, 0]}/>
            </StyledGraphContainer>
            {canEdit ? <ContextMenu/> : null}
        </>
    );
};

const EntityGraph: FunctionComponent = () => {
    return (
        <GraphContextProvider>
            <EntityGraphBody/>
        </GraphContextProvider>
    );
};

export default EntityGraph;
