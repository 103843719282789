import { ActionDescriptor } from '../common/commonTypes';
import SplitAttributeForm from './SplitAttributeForm';

const splitAttribute: ActionDescriptor = {
	form: SplitAttributeForm,
	actionTitle: 'Split',
	kind: 'modal',
	requiresEdit: true,
	divider: false,
	modalType: 'splitAttribute'
};

export default splitAttribute;
