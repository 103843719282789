import ToggleLabel from './ToggleLabel';
import {
	StyledFieldset,
	StyledLabelsContainer,
	StyledSlider,
	StyledHiddenInput,
} from './styledComponents';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { nanoid } from '@reduxjs/toolkit';
import VisuallyHidden from 'common/VisuallyHidden';
import { pipe } from 'common/utils/functionUtils';
import { AppError } from 'features/errorHandling/types/errorTypes';
import {
	FunctionComponent,
	useMemo,
	useState,
	Fragment,
	useLayoutEffect,
} from 'react';

export const genValueMismatchErr = (legend: string) =>
	new AppError(
		`An instance of SlideToggle with legend ${legend} received an active value that is not associated with any checkbox value`
	);

export interface SlideToggleCheckDescriptor<T> {
	label: string;
	icon: IconProp;
	value: T;
	tooltip: string;
}

// Type generic can be used to get type-checking of active and checkbox values
export interface SlideToggleProps<T extends string | number | boolean = any> {
	onActiveValueMismatch: (e: AppError) => void;
	activeValue: T | null | undefined;
	legend: string;
	onCheck: (value: T) => void;
	checkboxes: SlideToggleCheckDescriptor<T>[];
}

const OnOffToggle: FunctionComponent<SlideToggleProps> = ({
	activeValue,
	legend,
	checkboxes,
	onCheck,
	onActiveValueMismatch,
}) => {
	//  each instance of this component should have unique id prefix to keep relationship
	// between checkboxes and their label 1 to 1.
	const componentId = useMemo(() => nanoid(), []);

	const [activeIdx, setActiveIdx] = useState<number | null>(0);

	useLayoutEffect(() => {
		if (activeValue !== null && activeValue !== undefined) {
			const idx = checkboxes.findIndex((cb) => cb.value === activeValue);

			if (idx === -1) {
				pipe(legend, genValueMismatchErr, onActiveValueMismatch);
				setActiveIdx(null);
				return;
			}

			setActiveIdx(idx);
			return;
		}

		setActiveIdx(null);
	}, [activeValue, legend, onActiveValueMismatch, checkboxes]);

	const [labelEl, setLabel] = useState<HTMLLabelElement | null>(null);

	const labelWidth = useMemo(
		() => (labelEl ? labelEl.getBoundingClientRect().width : 0),
		[labelEl]
	);

	const leftDisplacement = activeIdx ? labelWidth * activeIdx : 0;

	const isTouchingSelected = (i: number) =>
		activeIdx ? i === activeIdx || i === activeIdx - 1 || i === activeIdx + 1 : false;

	if (checkboxes.length === 0) {
		return null;
	}

	return (
		<StyledFieldset>
			<VisuallyHidden>
				<legend>{legend}</legend>
			</VisuallyHidden>

			<StyledLabelsContainer>
				<StyledSlider
					leftDisplacement={leftDisplacement}
					labelWidth={labelWidth}
				>
					{checkboxes.map(({ icon, label, value, tooltip }, i) => {
						const uniqueId = `${componentId}-${label}`;
						const isCurrent = activeIdx === i;
						return (
							<Fragment key={uniqueId}>
								<StyledHiddenInput
									type="checkbox"
									id={uniqueId}
									value={value}
									checked={isCurrent}
									onChange={(e) => {
										if (e.target.checked) {
											setActiveIdx(i);
											onCheck(value);
										}
									}}
									aria-current={isCurrent}
								/>
								<ToggleLabel
									uniqueId={uniqueId}
									icon={icon}
									label={label}
									// isFirst={i === 0}
									// isFinal={i === checkboxes.length - 1}
									isCurrentlySelected={isCurrent}
									isTouchingSlider={isTouchingSelected(i)}
									ref={(e) => {
										if (i === 0) {
											setLabel(e);
										}
									}}
									tooltip={tooltip}
								/>
							</Fragment>
						);
					})}
				</StyledSlider>
			</StyledLabelsContainer>
		</StyledFieldset>
	);
};

export default OnOffToggle;
