import Typography from 'common/text/Typography';
import { isAppError } from 'common/utils/typeGuards';
import useDispatchableError from 'features/errorHandling/hooks/useDispatchableErr';
import { AppError } from 'features/errorHandling/types/errorTypes';
import {
	contextNameFromContextIdx,
	URLParamsToContextArray,
} from 'features/navigation/helpers';
import { ContextLadder } from 'features/navigation/types/navigationContextTypes';
import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
	OrgQuestionsList,
	DomainQuestionsList,
	EntityQuestionsList,
	IndividualQuestionsList
} from './QuestionsList'

const errUI = (message: string) => {
	return (
		<>
			<div>
				<Typography color="error">
					Unable to determine ontology depth from url. Parsing failed
					with the following error:
				</Typography>
			</div>
			<div>
				<Typography color="error">
					{message}
				</Typography>
			</div>
		</>
	);
};

const getUI = (contextLadder: AppError | ContextLadder) => {
	if (isAppError(contextLadder)) {
		return errUI(contextLadder.message);
	}

	const ctx = contextNameFromContextIdx(contextLadder.length - 1);

	switch (ctx) {
		case 'username':
			return <OrgQuestionsList />;
		case 'domain':
			return <DomainQuestionsList />;
		case 'entity':
			return <EntityQuestionsList />;
		case 'individual':
			return <IndividualQuestionsList />;
		default:
			return <OrgQuestionsList/>;
	}
};

const QuestionsTab: FunctionComponent = () => {
	const dispatchErr = useDispatchableError();

	const params = useParams();

	const contextLadder = URLParamsToContextArray(params);

	useEffect(() => {
		if (isAppError(contextLadder)) {
			dispatchErr(contextLadder);
		}
	}, [contextLadder, dispatchErr]);

	return getUI(contextLadder);
};

export default QuestionsTab;
