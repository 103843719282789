import { useGetLiveDataCatalogsQuery } from '../../api';
import useUserID from '../../authentication/hooks/useUserID';
import { ContextParams } from '../../navigation/types/navigationContextTypes';
import {
	DataSourceSummary,
	DataCatalog,
	DataSet,
	DataColumn,
	LiveDataCatalog
} from '../../browser/types/dataTypes';
import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isEmpty } from 'common/utils/typeGuards';
import { useParams } from 'react-router-dom';
import useOrgID from "../../authentication/hooks/useOrgID";
import useActiveSourceData from "./useActiveSourceData";

// TODO: figure out why this doesn't type correctly in selector fn below
type QueryResponse = Omit<ReturnType<typeof useGetLiveDataCatalogsQuery>, 'refetch'>;

type ActiveCatalogName = string | undefined;

const selectActiveCatalog = createSelector(
	(res: QueryResponse) => res.data,
	(res: QueryResponse, activeCatalogName: ActiveCatalogName) =>
		activeCatalogName,
	(data: QueryResponse['data'], activeCatalogName: ActiveCatalogName) =>
		data?.find((catalog: LiveDataCatalog) => catalog.name === activeCatalogName) ??
		null
);
/**
 * @returns RTK Query files related to fetching all available orgs for a user, as well
 * as an activeSource property that corresponds to the files for the user specified
 * in the application URL.
 */
const useActiveCatalogData = () => {
	const params = useParams();

	const {activeItem: activeSource} = useActiveSourceData();

	// won't run as long as skipToken is its argument
	const fetchData = useGetLiveDataCatalogsQuery(
		!activeSource || isEmpty(activeSource._id) ? skipToken : { sourceId: activeSource?._id },
		{
			selectFromResult: (res) => ({
				...res,
				// important to use a memoizing selector here: selectFromResult
				// uses shallow-equal comparison to determine whether or not a re-render
				// should occur. The only way to get a stable reference for the value of
				// activeItem is to memoize based on inputs.  RTK query takes care of
				// memoizing the value of res.data for us.
				activeItem: selectActiveCatalog(
					res,
					params?.catalog
				) as null | LiveDataCatalog,
			}),
		}
	);

	return fetchData;
};

export default useActiveCatalogData;
