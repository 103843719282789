import {UseFormRegisterReturn} from 'react-hook-form';
import styled from "styled-components";
import Typography from "../../text/Typography";
import {formFieldHasErrors, genErrorIdFromLabel} from "../../Form";
import React, {
    PropsWithChildren,
    forwardRef,
    useState,
    FunctionComponent, useRef, MouseEventHandler
} from "react";
import useDispatchableErr
    from 'features/errorHandling/hooks/useDispatchableErr';
import {ActionFormProps} from "../../../features/actions/common/commonTypes";
import {
    StyledInputContainer,
    StyledLabel
} from "../SubtleTextBox/styledComponents";
import FlexContainer from "../../FlexContainer";
import {forkRef} from "../../utils/reactUtils";

// export const StyledToggle = styled.input<{ $error?: boolean }>`
// 	border: 1px solid transparent;
// 	color: ${(p) => p.theme.palette.common.white}BF;
// 	border-radius: ${(p) => p.theme.spacing(1)};
// 	font-size: ${(p) => p.theme.typography.input.fontSize};
// 	line-height: ${(p) => p.theme.typography.input.lineHeight};
// 	letter-spacing: ${(p) => p.theme.typography.input.letterSpacing};
// 	font-weight: ${(p) => p.theme.typography.input.fontWeight};
// 	font-family: ${(p) => p.theme.typography.input.fontFamily};
// 	padding: ${(p) => p.theme.spacing(.5)} ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(.5)};
// 	width: 100%;
// 	background-color: ${(p) => p.theme.palette.common.white}0A;
// 	&:-webkit-autofill {
// 		background-color: ${(p) => p.theme.palette.oldManGray};
// 		background-color: ${(p) => p.theme.palette.darkerBaby};
// 	}
// 	&::autofill {
// 		background-color: ${(p) => p.theme.palette.oldManGray};
// 	}
// 	&:focus {
// 		border: 1px solid ${(p) =>
// 			p.$error ? p.theme.palette.error.main : p.theme.palette.darkerBaby}AA;
// 		outline: none;
// 	}
// 		&:active {
// 		outline: none;
// 	}
//
// 	&::placeholder {
// 		color: ${(p) => p.theme.palette.cyan};
// 		font-size: ${(p) => p.theme.typography.overline.fontSize};
// 		line-height: ${(p) => p.theme.typography.overline.lineHeight};
// 		letter-spacing: ${(p) => p.theme.typography.overline.letterSpacing};
// 		font-weight: ${(p) => p.theme.typography.overline.fontWeight};
// 		font-family: ${(p) => p.theme.typography.overline.fontFamily};
// 	}
// 	caret-color:  ${(p) => p.theme.palette.primary.main};
// `;

// background-color: ${(p) => p.theme.palette.common.white}0A;
// const StyledSelect = styled.select<{ $error?: boolean }>`
// 	background-color: ${(p) => p.theme.palette.darkerBaby};
// 	color: ${(p) => p.theme.palette.common.white}BF;
// 	background: transparent;
// 	border: 1px solid transparent;
// 	border-radius: ${(p) => p.theme.spacing(1)};
// 	font-size: ${(p) => p.theme.typography.input.fontSize};
// 	line-height: ${(p) => p.theme.typography.input.lineHeight};
// 	letter-spacing: ${(p) => p.theme.typography.input.letterSpacing};
// 	font-weight: ${(p) => p.theme.typography.input.fontWeight};
// 	font-family: ${(p) => p.theme.typography.input.fontFamily};
// 	padding: ${(p) => p.theme.spacing(0.5)} ${(p) => p.theme.spacing(1)};
// 	width: 100%;
// 	&:-webkit-autofill {
// 		background-color: ${(p) => p.theme.palette.darkerBaby};
// 		background-color: ${(p) => p.theme.palette.darkerBaby};
// 	}
// 	&::autofill {
// 		background-color: ${(p) => p.theme.palette.darkerBaby};
// 	}
// 	&:focus {
// 		border: 1px solid ${(p) =>
// 			p.$error ? p.theme.palette.error.main : p.theme.palette.darkerBaby}AA;
// 		outline: none;
// 	}
// 		&:active {
// 		outline: none;
// 	}
//
// 	&::placeholder {
// 		color: ${(p) => p.theme.palette.cyan};
// 		font-size: ${(p) => p.theme.typography.overline.fontSize};
// 		line-height: ${(p) => p.theme.typography.overline.lineHeight};
// 		letter-spacing: ${(p) => p.theme.typography.overline.letterSpacing};
// 		font-weight: ${(p) => p.theme.typography.overline.fontWeight};
// 		font-family: ${(p) => p.theme.typography.overline.fontFamily};
// 	}
// 	caret-color:  ${(p) => p.theme.palette.primary.main};
// `;

// padding-right: ${(p) => p.theme.spacing(1)};
// font-family: ${(p) => p.theme.typography.body1.fontFamily};
// font-size: ${(p) => p.theme.typography.body1.fontSize};
// font-weight: ${(p) => p.theme.typography.fontWeightBold};
// letter-spacing: ${(p) => p.theme.typography.body1.letterSpacing};
// line-height: ${(p) => p.theme.typography.body1.lineHeight};
// color: ${(p) => p.theme.palette.cyan};
// export const RoundSliderContainer = styled.label`
// `;

// padding-right: ${(p) => p.theme.spacing(1)};
// font-family: ${(p) => p.theme.typography.body1.fontFamily};
// font-size: ${(p) => p.theme.typography.body1.fontSize};
// font-weight: ${(p) => p.theme.typography.fontWeightBold};
// letter-spacing: ${(p) => p.theme.typography.body1.letterSpacing};
// line-height: ${(p) => p.theme.typography.body1.lineHeight};
// color: ${(p) => p.theme.palette.cyan};
// export const RoundSlider = styled.div`
//
// 	&:checked {
// 		transform: translateX(32px);
// 	}
// ;

type BaseRadioOption = {
    name: string;
    label: string;
};

export interface SubtleRadioOptionProps extends BaseRadioOption {
    fieldset: string;
    validatingRegister: any;
}

export interface SubtleRadioProps extends ActionFormProps {
    isDirty: boolean;
    label: string;
    onReset?: () => void;
    error?: string;
    defaultValue?: string;
    name: string;
    options: BaseRadioOption[];
    validatingRegister?: any;
    justifyContent?: string;
    showLabel: boolean;
    alignItems?: string;
    inline?: boolean;
}


const SubtleRadioOption: FunctionComponent<SubtleRadioOptionProps> = (
    {name, fieldset, label, validatingRegister}
) => {
    return <div>

        <input
            id={`${fieldset}-${name}`}
            type="radio"
            value={name}
            {...validatingRegister(name, {
                required: {
                    value: true,
                    message:
                        'A value for replaceMissing is required',
                },
            })}
        />
        <label htmlFor={`${fieldset}-${name}`}>
            <Typography>
                {label}
            </Typography>
        </label>
    </div>;
};


const SubtleRadio = forwardRef<HTMLInputElement, PropsWithChildren<SubtleRadioProps>>((
    {
        options,
        name,
        isDirty,
        // formState,
        defaultValue,
        children,
        onReset,
        justifyContent,
        alignItems,
        validatingRegister,
        inline,
        showLabel = false,
        label,
        error,
        ...rest
    },
    forwardedRef) => {

    const dispatchError = useDispatchableErr();

    // const [toggleOn, setToggleOn] = useState(false);

    const resetterRef = useRef<HTMLInputElement>();

    const setRefs = forkRef(forwardedRef, resetterRef);

    const onContainerClick: MouseEventHandler<HTMLDivElement> = () => {
        if (resetterRef.current) {
            resetterRef.current.focus();
        }
    };

    return <div>
        <StyledInputContainer
            justifyContent={justifyContent ? justifyContent : "space-between"}
            alignItems={alignItems ? alignItems : "flex-start"}
            flexDirection={!inline ? 'column' : 'row'}
            onClick={onContainerClick}
            data-testid="subtle-input-container"
            // style={{ ...overrides?.container, position: 'relative' }}
            style={{position: 'relative'}}
        >
            {/*// <Typography id={name}>*/}
            {/*//         Replace missing values with 0 (does not affect NULLS)?*/}
            {/*//     </Typography>*/}
            {showLabel && (
                <StyledLabel
                    htmlFor={`${label}-subtle-input`}
                    style={{marginBottom: '.5rem'}}>
                    {/*// style={{marginBottom: '.5rem', ...overrides?.label}}*/}
                    {label}
                </StyledLabel>
            )}
            <FlexContainer
                style={{width: '100%'}}
                alignItems='center'
                justifyContent='flex-start'
            >
                <div
                    role="radiogroup"
                    aria-labelledby={`${name}-subtle-label`}
                    // aria-errormessage={genErrorIdFromLabel(name)}
                    // aria-invalid={formFieldHasErrors(name, formState)}
                >
                    <FlexContainer gap={'1rem'}>
                    {options.map((option) => {
                        return <div key={`dv-${name}-${String(option.name)}`}>
                            <input
                                type="radio"
                                id={`dv-${name}-${String(option.name)}`}
                                ref={setRefs}
                                value={option.name}
                                {...validatingRegister(name, {
                                    required: {
                                        value: true,
                                        message:
                                            'A value for replaceMissing is required',
                                    },
                                })}
                            />
                            <label htmlFor={`dv-${name}-${String(option.name)}`}>
                                <Typography>
                                    {option.label}
                                </Typography>
                            </label>
                        </div>
                    })}
                                        </FlexContainer>
                </div>
            </FlexContainer>
            <div>
                {error && (
                    <Typography color="error" id={`${label}-error-message`}>
                        {error}
                    </Typography>
                )}
            </div>
        </StyledInputContainer>
    </div>;


    // 	<StyledInputContainer
    // 		justifyContent="space-between"
    // 		alignItems="center"
    //
    // 		onClick={onContainerClick}
    // 		data-testid="subtle-input-container"
    // 	>
    // 		<div>
    // 			<RoundSliderContainer htmlFor={`${label}-subtle-checkbox`}>
    // 				{/*{label}*/}
    // 				<div>On</div>
    // 				<div>Off</div>
    // 				<RoundSlider/>
    // 			</RoundSliderContainer>
    // 			<StyledToggle
    // 				type='checkbox'
    // 				id={`${label}-subtle-checkbox`}
    // 				defaultValue={defaultValue}
    // 				className={clsx(isDirty && 'is-dirty')}
    // 				aria-invalid={Boolean(error)}
    // 				aria-errormessage={`${label}-error-message`}
    // 				// @ts-ignore
    // 				ref={setRefs}
    // 				{...rest}
    // 			/>
    // 		</div>
    // 		{isDirty && (
    // 			<IconButton
    // 				size="xs"
    // 				icon={faTimes}
    // 				aria-label={`reset ${label} field`}
    // 				tooltipPlacement="top"
    // 				tooltip={`reset ${label} field`}
    // 				onClick={(e) => {
    // 					e.preventDefault();
    // 					onReset && onReset();
    // 				}}
    // 			/>
    // 		)}

    // 	</StyledInputContainer>
    // </div>
});

export default SubtleRadio;
