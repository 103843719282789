import FlexContainer from '../../../common/FlexContainer';
import Heading from '../../../common/text/Heading';
import { ListItem } from 'common/List';
import styled from 'styled-components';

export const StyledHeadingContainer = styled(FlexContainer)`
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	padding: ${(p) => p.theme.spacing(0, 1)};
`;
export const StyledMemberMgmtContainer = styled.div`
	overflow: auto;
	padding: ${(p) => p.theme.spacing(1)};
`;

export const StyledMemberMgmtHeading = styled(Heading)`
	text-transform: capitalize;
	padding-left: 8px;
`;

export const StyledConsoleContainer = styled.div`
	background-color: ${(p) => p.theme.palette.background.default};
	height: 100%;
	overflow-y: auto;
`;

export const StyledMemberDataListItem = styled(ListItem)`
	padding: ${(p) => p.theme.spacing(1)};
	margin-top: 1rem;
`;
