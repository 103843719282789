import {
    LiveDataColumns,
    GetLiveDataSetPreviewResponse,
    LiveDataSet, DataColumn, LiveDataColumn, DataSet
} from '../../types/dataTypes';
import {isLiveDataSet} from '../../types/uiTypes';
import {faUndo} from '@fortawesome/free-solid-svg-icons';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import FlexContainer from 'common/FlexContainer';
import theme from 'common/theme/theme';
import IconButton from 'common/buttons/IconButton';
import Typography from 'common/text/Typography';
import {flow} from 'common/utils/functionUtils';
import {
    useGetDataSetPreviewQuery,
    useGetSourceSummariesQuery,
} from 'features/api';
import useActiveOrgData from 'features/ontology/hooks/useActiveOrgData';
import React, {
    CSSProperties,
    FunctionComponent,
    useEffect,
    useRef,
    useState,
    Fragment
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
    VariableSizeList as List,
    ListProps,
    ListChildComponentProps,
} from 'react-window';
import styled from 'styled-components';
import ImportButton from "../../../../common/buttons/ImportButton";
import RecacheButton from "../../../../common/buttons/RecacheButton";
import DropButton from "../../../../common/buttons/DropButton";
import MoveButton from "../../../../common/buttons/MoveButton";
import DescribeButton from "../../../../common/buttons/DescribeButton";
import DataStats from "../../../../common/stats/DataStats";
import MenuButton from "../../../../common/buttons/MenuButton";
import DatasetMenu from "../../../../common/menus/DatasetMenu";
import RouterLink from "../../../../common/RouterLink";
import Heading from "../../../../common/text/Heading";

const StyledTableToggle = styled(IconButton)`
	box-shadow: none;
	border: none;

	&:hover {
		background: #ffffff20;
	}
`;

const StyledGridItem = styled.div<{ $itemCount: number }>`
	background-color: transparent;
	display: grid;
	grid-template-columns: repeat(${(p) => p.$itemCount}, 1fr);
	grid-gap: ${(p) => p.theme.spacing(1)};
	align-items: center;
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	color: ${(p) => p.theme.palette.oldManGray};
	font-size:  ${(p) => p.theme.typography.h6.fontSize};
	font-weight:  ${(p) => p.theme.typography.h6.fontWeight};
	font-family:  ${(p) => p.theme.typography.h6.fontFamily};
	line-height:  ${(p) => p.theme.typography.h6.lineHeight};
	letter-spacing:  ${(p) => p.theme.typography.h6.letterSpacing};
	padding: .25rem;
	overflow: auto;
	height: 100%;
	cursor: pointer;
    white-space: wrap;

	&:hover {
		background-color: ${(p) => p.theme.palette.darkerBaby};
	}
`;

const StyledMetaItem = styled.div<{ $itemCount: number }>`
	background-color: transparent;
	display: grid;
	grid-template-columns: repeat(${(p) => p.$itemCount}, 1fr);
	grid-gap: ${(p) => p.theme.spacing(1)};
	align-items: center;
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	color: ${(p) => p.theme.palette.oldManGray};
	font-size:  ${(p) => p.theme.typography.h6.fontSize};
	font-weight:  ${(p) => p.theme.typography.h6.fontWeight};
	font-family:  ${(p) => p.theme.typography.h6.fontFamily};
	line-height:  ${(p) => p.theme.typography.h6.lineHeight};
	letter-spacing:  ${(p) => p.theme.typography.h6.letterSpacing};
	padding: .25rem;
	overflow: auto;
	height: 50px;
	cursor: pointer;
    white-space: wrap;

	&:hover {
		background-color: ${(p) => p.theme.palette.darkerBaby};
	}
`;


interface RowProps {
    items: any[];
    type?: string;
}

const Row: FunctionComponent<RowProps> = ({items, type}) => {
    return (
        <>
            {items.map((item, i) => {
                if (!!type && type === 'meta') {
                    return <div className='invisible-scroll'
                                style={{width: '100px', height: '50px'}}
                                key={`${item}--${i}`}>
                        {String(item)}
                    </div>
                } else {
                    return <div className='invisible-scroll' style={{
                        padding: '0 .5rem',
                        width: '200px',
                        height: '100%',
                        overflowY: 'scroll',
                        borderRight: `1px solid ${theme.palette.divider}`
                    }} key={`${item}--${i}`}>
                        {String(item)}
                    </div>
                }
            })}
        </>
    );
};

interface PreviewListItemProps<T> extends ListChildComponentProps<T> {
}

const PreviewListItem: FunctionComponent<PreviewListItemProps<LiveDataColumns | GetLiveDataSetPreviewResponse>> = (
    {
        data,
        style,
        index
    }
) => {

    if (data.length > 0 && data[0]._object === 'DataColumn') {
        const itemCount = Object.keys(data[0]).length;
        const row = data[index];
        return (
            <StyledMetaItem className='invisible-scroll' style={style}
                            $itemCount={itemCount}>
                <Row items={[
                    index === 0 ? '' : index,
                    row.name,
                    row.type,
                    row.definition,
                    row.isNullable,
                    row.isRepeated,
                    // row.fields,
                    // row.policyTags,
                    // ...Object.values(row.stats),
                    // row.stats?.sizeMegaBytes,
                    // row.stats?.distinctValues,
                    // row.stats?.missingValues,
                    // row.stats?.canonicalValues,
                    // row.stats?.invalidValues,
                ]} type='meta'/>
                {/*{Object.values(data[index]).map((p) => {*/}
                {/*	return <FlexContainer alignItems={'center'}>*/}
                {/*		<div style={{width: '100px', overflow: 'hidden'}}>*/}
                {/*			{p ? p : 'null'}*/}
                {/*		</div>*/}
                {/*		/!*<MenuButton />*!/*/}
                {/*	</FlexContainer>;*/}
                {/*})}*/}
            </StyledMetaItem>
        );
    } else {
        const itemCount = Object.keys(data[0]).length;
        const row = data[index];
        return (
            <StyledGridItem className='invisible-scroll'
                            style={{padding: '1rem .5rem', ...style}}
                            $itemCount={itemCount}>
                <Row items={Object.values(row)}/>
                {/*{Object.values(data[index]).map((p) => {*/}
                {/*	return <FlexContainer alignItems={'center'}>*/}
                {/*		<div style={{width: '100px', overflow: 'hidden'}}>*/}
                {/*			{p ? p : 'null'}*/}
                {/*		</div>*/}
                {/*		/!*<MenuButton />*!/*/}
                {/*	</FlexContainer>;*/}
                {/*})}*/}
            </StyledGridItem>
        );
    }
};

interface DataPreviewProps {
    dataset: LiveDataSet;
}

type DataPreviewListProps<T> = Pick<ListProps<T>,
    'width' | 'height' | 'itemData'> & { itemData: T };

const getMaxItemLength = (rowData: Record<any, any>) => {

    const value = Object.keys(rowData.item).reduce((acc, key) => {
        const underTest = rowData.item[key];

        if (typeof underTest !== 'string') {
            return acc;
        }

        return underTest.length > acc ? underTest.length : acc;
    }, 0);
    return {value, type: rowData.type}
}

const calculateHeightFromStringLength = (result: any) => {
    if (result.type === 'meta') {
        return 100;
    } else {
        return 150;
    }

    // let size = 200;
    //
    // if (result.value === 0) return size;
    //
    // const multiplier = Math.round(result.value / 15);
    //
    // const newSize = size + multiplier * 10;
    //
    // return newSize;
};

const calcItemSize = flow(getMaxItemLength, calculateHeightFromStringLength);

const DataPreviewList: FunctionComponent<DataPreviewListProps<LiveDataSet | GetLiveDataSetPreviewResponse>> = (
    {
        width,
        height,
        itemData
    }
) => {

    const listRef = useRef<List | null>(null);

    useEffect(() => {
        listRef.current?.resetAfterIndex(0);
    });

    if (isLiveDataSet(itemData)) {
        const header = [{
            _object: 'DataColumn',
            name: 'Name',
            type: 'Type',
            definition: 'Definition',
            isNullable: 'Is Nullable',
            isRepeated: 'Is Repeated'
        }];
        const data = header.concat(itemData.tableSchema.columns as any)

        if (data.length === 0) {
            return <Typography variant={'h5'}>
                Data unavailable
            </Typography>;
        }
        const getItemSize = (idx: number) => calcItemSize({
            item: data[idx],
            type: 'meta'
        });
        return (
            <List
                style={{display: 'flex'}}
                itemData={data}
                itemCount={data.length}
                itemSize={getItemSize}
                height={height}
                width={width}
                ref={listRef}
            >
                {PreviewListItem}
            </List>
        );
    } else {
        const header = [Object.keys(itemData[0])];
        const data = header.concat(itemData as any[])
        if (data.length === 0) {
            return <Typography variant={'h5'}>
                Data unavailable
            </Typography>;
        }
        const getItemSize = (idx: number) => calcItemSize({
            item: data[idx],
            type: 'raw'
        });
        return (
            <List
                style={{display: 'flex'}}
                itemData={data}
                itemCount={data.length}
                itemSize={getItemSize}
                height={height}
                width={width}
                ref={listRef}
            >
                {PreviewListItem}
            </List>
        );
    }
};

const StyledSection = styled.div`
	width: 100%;
	height: 100%;
`;

const StyledGraphicContainer = styled.div`
	position: relative;
	width: 64px;
	height: 64px;
	float: left;
`
const StyledGraphicSquare = styled.div`
	background: ${(p) => p.theme.palette.primary.main};
	width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
`;


const StyledContextualDisplay = styled.section`
	padding: ${(p) => p.theme.spacing(1)};
	position: relative;
`;

const StyledListContainer = styled.section`
	overflow-y: auto;
	position: relative;
	overflow: auto;
	/* min-width: 300px; */
	/* max-width: 450px; */
`;

export const ListItemControls = styled.div<{
    justifyContent?: CSSProperties['justifyContent'];
}>`
	display: flex;
	flex-basis: 5%;
	gap: .5rem;
	align-items: center;
	align-content: center;
	justify-content: ${(p) => p.justifyContent ?? 'flex-end'};
`;


const DataPreview: FunctionComponent<DataPreviewProps> = ({dataset}) => {
    const [previewMode, setPreviewMode] = useState<'overview' | 'sample'>(
        'overview'
    );

    const {activeItem} = useActiveOrgData();

    // get all the user sources for filtering by name.  Query for
    // preview files needs a source _id to ask for correct preview files.
    const {data: sourcesData, isSuccess: isSourcesSuccess} =
        useGetSourceSummariesQuery(
            activeItem ? {orgId: activeItem._id} : skipToken
        );

    const [sourceId, setSourceId] = useState<number | null>(null);

    // set a source id if we can match exactly one to the sourceName
    // field of the dataset in question.
    useEffect(() => {
        if (isSourcesSuccess && sourcesData) {
            const source = sourcesData.filter(
                (src) => src.name === dataset.sourceName
            );

            // TODO: fix this
            if (source.length !== 1) {
                throw new Error(
                    'DataPreview was unable to match selected Dataset to exactly one source id'
                );
            }

            setSourceId(source[0]._id);
        }
    }, [dataset, isSourcesSuccess, sourcesData]);

    const [activeData, setActiveData] = useState<LiveDataSet | GetLiveDataSetPreviewResponse>(dataset);

    const {data: previewData} = useGetDataSetPreviewQuery(
        sourceId
            ? {
                catalogName: dataset.catalogName,
                sourceId: sourceId,
                datasetName: dataset.name,
            }
            : skipToken
    );

    // The type of activeData determines what gets rendered.
    useEffect(() => {
        if (previewMode === 'overview') {
            return setActiveData({...dataset});
        }

        if (previewData) {
            return setActiveData([...previewData]);
        }
    }, [previewMode, previewData, dataset]);

    const togglePreviewMode = () =>
        setPreviewMode((prev) => (prev === 'sample' ? 'overview' : 'sample'));

    return activeData &&
        <FlexContainer flexDirection="column" style={{height: '100%'}}>
          <FlexContainer alignItems='center' justifyContent={'space-between'}
                         style={{padding: '1rem'}}>
            <FlexContainer gap={'3rem'}>
              <StyledSection
                  style={{width: '32px', height: '32px'}}>
                <StyledGraphicContainer>
                  <StyledGraphicSquare/>
                </StyledGraphicContainer>
              </StyledSection>
              <FlexContainer flexDirection={'column'}>
                <RouterLink
                    to={dataset.catalogName}
                    preserveSearchParams={false}>
                  <Typography
                      // style={{gridArea: 'overline',}}
                      variant="caption1">
                      {dataset.catalogName}
                  </Typography>
                </RouterLink>
                <Heading
                    // style={{gridArea: 'title'}}
                    component="h1">
                    {dataset.name}
                </Heading>
              </FlexContainer>
            </FlexContainer>
              {/*<DataStats/>*/}
            <FlexContainer gap={'1rem'} alignItems={'center'}>
              <div>
                <Typography variant='h5'>
                    {dataset.stats?.sizeMegaBytes} bytes
                </Typography>
              </div>
              <div>
                <Typography variant='h5'>
                    {dataset.stats?.rowCount} rows
                </Typography>
              </div>
              <div>
                <Typography variant='h5'>
                    {dataset.tableSchema?.columns.length} columns
                </Typography>
              </div>
            </FlexContainer>
            <FlexContainer>
              <StyledTableToggle
                  icon={faUndo}
                  size="sm"
                  variant="transparent"
                  shape="round"
                  aria-label="toggle dataset view"
                  onClick={togglePreviewMode}
              />
              <DatasetMenu dataset={dataset} canEdit={true}/>
            </FlexContainer>
          </FlexContainer>
          <div style={{
              flexGrow: 1,
              padding: '1rem',
              border: `1px solid ${theme.palette.divider}`
          }}>
              {/*defaultHeight={200} defaultWidth={200*/}
            <AutoSizer>
                {({width, height}) => {
                    return (
                        <DataPreviewList
                            itemData={activeData}
                            height={height}
                            width={width}
                        />
                    );
                }}
            </AutoSizer>
          </div>
        </FlexContainer>;
};

export default DataPreview;
