import { ListItem } from '../../../common/List';
import styled from 'styled-components';

export const StyledList = styled.ul`
	padding-left: ${(p) => p.theme.spacing(1)};
`;
export const StyledListItem = styled.li`
	padding-left: ${(p) => p.theme.spacing(1)};
	list-style: none;
`;

export const SourceListItem = styled(ListItem)`
    marginLeft: 2%;
	&.browser-item-is-open {
		font-weight: bold;
	}
`;
export const StyledSourceNavButton = styled.button`
	font-size: ${(p) => p.theme.typography.body1.fontSize};
	line-height: ${(p) => p.theme.typography.body1.lineHeight}rem;
	letter-spacing: ${(p) => p.theme.typography.body1.letterSpacing}rem;
	font-family: ${(p) => p.theme.typography.body1.fontFamily};
	cursor: pointer;
	padding: ${(p) => p.theme.spacing(1)};
	background: transparent;
	border: none;
	color: inherit;
	min-width: 100%;
	text-align: left;
	display: block;
	&:hover {
		background: #ffffff20;
	}
	&.browser-item-is-open {
		font-weight: ${(p) => p.theme.typography.fontWeightBold};
	}
`;

export const StyledSourceFormLabel = styled.label`
	color: ${(p) => p.theme.palette.common.white};
	font-size: ${(p) => p.theme.typography.body1.fontSize};
	font-family: ${(p) => p.theme.typography.body1.fontFamily};
`;
