// Need this in its own file for helper functions that use JSX,
// since JSX syntax clashes with generic type declarations in arrow function,
// and I can't stand the look of any of the workarounds...
import { formFieldHasErrors, genErrorIdFromLabel } from '../../../common/Form';
import Typography from '../../../common/text/Typography';
import { BaseAttribute } from '../../ontology/types/attributeTypes';
import { BaseEntity } from '../../ontology/types/entityTypes';
import { createBasicValidation } from './helpers';
import { StyledTextArea } from './styledComponents';
import { FormState, UseFormRegister } from 'react-hook-form';
import {
	harvestErrors,
	SubtleTextbox
} from "../../../common/inputs/SubtleTextBox";
import React, {FunctionComponent} from "react";
import Heading from "../../../common/text/Heading";
import SubtleTextArea from "../../../common/inputs/SubtleTextArea";
import AppModal from "../../../common/modals/AppModal";
import StaticValueInput from "../../../common/inputs/StaticValueInput";
import OtherIdSelect from "../../../common/inputs/OtherIdSelect";

/**
 * Render sub-fields needed if attribute operation is a derivation--
 * these sub-fields provide identifiers for the newly-derived attribute.
 */
export const renderDerivationSubfields: FunctionComponent<{
	isDerivation: boolean;
	formState: FormState<any>;
	// TODO: figure out this type.
	validatingRegister: Function;
	resetField: any;
	mutationResults: any;
}> = ({
		isDerivation,
		formState,
		validatingRegister,
		resetField,
		mutationResults
	}) => {
	if (isDerivation) {
		return (
			<>
				<SubtleTextbox
					{...validatingRegister('singular', {
						required: {
							value: true,
							message: `Singular is a required field`,
						},
						maxLength: {
							value: 200,
							message: `A maximum of 200 characters is allowed for singular`,
						},
					})}
					label="Singular"
					isDirty={Boolean(formState.dirtyFields.singular)}
					onReset={() => resetField('singular')}
					error={harvestErrors(
						'singular',
						formState,
						mutationResults
					)}
					// aria-errormessage={genErrorIdFromLabel('singular')}
					// aria-invalid={formFieldHasErrors('singular', formState)}
					showLabel
				/>
				<SubtleTextbox
					{...validatingRegister('plural', {
						required: {
							value: true,
							message: `Plural is a required field`,
						},
						maxLength: {
							value: 200,
							message: `A maximum of 200 characters is allowed for plural`,
						},
					})}
					label="Plural"
					isDirty={Boolean(formState.dirtyFields.plural)}
					// aria-errormessage={genErrorIdFromLabel('plural')}
					// aria-invalid={formFieldHasErrors('plural', formState)}
					onReset={() => resetField('plural')}
					error={harvestErrors(
						'plural', formState, mutationResults)}
					showLabel
				/>

			</>
		);
	}

	return null;
};


/**
 * Render reference entity <select> menu
 */
export const renderOtherEntitySelect = (
	fieldName: string,
	register: UseFormRegister<any>,
	formState: FormState<any>,
	selectableEntities: BaseEntity[] | null
) => {
	if (selectableEntities === null || selectableEntities.length === 0) {
		return (
			<Typography paragraph color="warn">
				No eligible entities for this operation
			</Typography>
		);
	}
	return (
		<>
			<label htmlFor={fieldName}>
				<Typography>Select a reference entity</Typography>
			</label>
			<select
				{...register(fieldName, {
					valueAsNumber: true,
					validate: (v: string | number) => {
						if (typeof v === 'string') {
							const maybeInt = parseInt(v, 10);

							return isNaN(maybeInt)
								? 'Invalid value passed to otherId'
								: maybeInt > 0
								? true
								: 'A reference entity must be selected if you are not using a data value';
						}

						return v > 0
							? true
							: 'A reference entity must be selected if you are not using a data value';
					},
				})}
				id={fieldName}
				aria-errormessage={genErrorIdFromLabel(fieldName)}
				aria-invalid={formFieldHasErrors(fieldName, formState)}
			>
				{selectableEntities.map((entity) => (
					<option value={entity._id} key={entity._id}>
						{`${entity.singular}`}
					</option>
				))}
			</select>
		</>
	);
};


/**
 * Render sub-fields needed if user opts to use a data value
 */
export const renderReferenceField = (
	attrSelectFieldName: string,
	staticValueFieldName: string,
	needsDataValue: boolean,
	validatingRegister: any,
	selectableAttrs: BaseAttribute[] | null,
	formState: FormState<any>
) => {
	if (needsDataValue) {
		return <StaticValueInput
			name={staticValueFieldName}
			validatingRegister={validatingRegister}
			formState={formState}
		/>
	}

	return (
		<OtherIdSelect
			fieldName={attrSelectFieldName}
			validatingRegister={validatingRegister}
			formState={formState}
			selectableAttrs={selectableAttrs}
		/>
	)
};
