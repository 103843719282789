import Button from '../../../common/buttons/Button';
import Container from '../../../common/Container';
import {FormBody, FormHeading, FormResults} from '../../../common/Form';
import Heading from '../../../common/text/Heading';
import Textbox from '../../../common/inputs/TextBox';
import Typography from '../../../common/text/Typography';
import {useResetPasswordMutation} from '../../api';
import {isQueryHTTPRejection} from '../../api/helpers';
import {QueryErrResult} from '../../api/types';
import React, {FunctionComponent, useEffect} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {Navigate, Link, useSearchParams, useLocation} from 'react-router-dom';
import FlexContainer from "../../../common/FlexContainer";
import StyledLogo from "../../../common/StyledLogo";
import StyledCitizen from "../../../common/StyledCitizen";
import StyledCopyright from "../../../common/StyledCopyright";
import styled from "styled-components";
import LogInLinkButton from "../../../common/buttons/LogInLinkButton";

const is403 = (err: QueryErrResult) => {
    if (isQueryHTTPRejection(err)) {
        return err.status === 403;
    }

    return false;
};

interface ResetFormValues {
    newPassword: string;
    passwordConf: string;
}

const StyledLanding = styled.div`
	height: 100vh; 
	width: 100vw;
    background-color: ${(p) => p.theme.palette.darkBaby};
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		overflow-y: auto;	
		overflow-x: hidden;	
	}
`

const StyledFormBody = styled.form`
	border-radius: ${(p) => p.theme.spacing(1)};
	background: ${(p) => p.theme.palette.darkerBaby};
	width: 350px;
	margin: 0;
`;

const StyledMain = styled.div`
	display: flex;
	flex-direction: column;
	place-content: space-evenly;
	place-items: center;
	min-height: 600px;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	padding: 0;	
	margin: 0;	
	background-color: ${(p) => p.theme.palette.darkBaby};
`;

const StyledSigninNav = styled.nav<{ useTranslucency: boolean; }>`
	background: transparent;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-between;
	flex: 1;
	justify-content: flex-end;
	align-content: flex-end;
	z-index: ${(p) => p.theme.zIndex.navPanel};
	background-color: ${(p) => p.theme.palette.darkBaby};
`;


const SigninButtons: FunctionComponent = () => {
    const {pathname} = useLocation();
    const atLanding = pathname.match('/') || pathname.match('/login');
    if (atLanding) {
        return <LogInLinkButton
            display={false}
            color={'darkBaby'}
        />
    }
    return null;
};


const ResetPage: FunctionComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const {handleSubmit, register, formState, watch} =
        useForm<ResetFormValues>({
            defaultValues: {
                newPassword: '',
                passwordConf: '',
            },
        });

    const confValue = watch('passwordConf');

    const [reset, resetResults] = useResetPasswordMutation();

    const onSubmit: SubmitHandler<ResetFormValues> = (vals) => {
        reset({
            password: vals.newPassword,
            resetToken: searchParams.get('resetToken') as string,
        });
    };

    useEffect(() => {
        if (is403(resetResults.error)) {
            setSearchParams({expired: 'true'});
        }
    }, [resetResults.error, setSearchParams]);

    if (searchParams.get('expired')) {
        return (
            <FlexContainer style={{textAlign: 'center'}}>
                <Heading component="h1" style={{textAlign: 'center'}}>
                    Token expired
                </Heading>

                <Typography paragraph>
                    Your password reset link has expired. Please{' '}
                    {
                        <Link to="/reset-password-request">
                            request a new one.
                        </Link>
                    }
                </Typography>
            </FlexContainer>
        );
    }

    if (!searchParams.get('resetToken')) {
        return <Navigate to="/"/>;
    }

    return (
        <StyledLanding>
            <StyledSigninNav useTranslucency={false}>
                <SigninButtons/>
            </StyledSigninNav>
            <StyledMain>
                <StyledLogo
                    width={'450px'}
                    height={'53px'}
                    src="https://storage.googleapis.com/davis-associates.appspot.com/futuremodel-teaser.svg"
                    alt="Futuremodel Inc."
                />
                <FlexContainer
                    style={{height: '315px', width: '100%'}}
                    gap={'.5rem'}
                    alignItems='center'
                    justifyContent='center'>
                    <StyledCitizen
                        width={'275px'}
                        height={'315px'}
                        src="https://storage.googleapis.com/davis-associates.appspot.com/fm-hero-figure.svg"
                        role="presentation"
                    />
                    <StyledFormBody
                        onSubmit={handleSubmit(onSubmit)}
                        spellCheck={false}
                        aria-label="log in"
                    >
                        <FormHeading component="h1" id="password-reset-request-heading">
                            Reset Password
                        </FormHeading>
                        <Textbox
                            type="password"
                            labelText="Enter New Password"
                            autoComplete="new-password"
                            error={formState.errors.newPassword}
                            {...register('newPassword', {
                                required: 'Please input a new password',
                                validate: (v) =>
                                    v === confValue
                                        ? true
                                        : 'Password and confirmation must match',
                            })}
                        />
                        <Textbox
                            type="password"
                            labelText="Confirm New Password"
                            autoComplete="new-password"
                            error={formState.errors.passwordConf}
                            {...register('passwordConf', {
                                required: 'Please confirm new password',
                            })}
                        />
                        <Button type="submit">Submit</Button>
                        <FormResults {...resetResults} validationErrors={{}}/>
                        {resetResults.isSuccess && (
                            <Typography>
                                Your submission has been accepted. Please{' '}
                                {<Link to="/login">login</Link>} to continue.
                            </Typography>
                        )}
                    </StyledFormBody>
                </FlexContainer>
                <StyledCopyright/>
            </StyledMain>
        </StyledLanding>
    );
    // return (
    // 	<FlexContainer>
    // 		<FormBody
    // 			onSubmit={handleSubmit(onSubmit)}
    // 			aria-labelledby="password-reset-request-heading"
    // 		>
    //
    // 		</FormBody>
    // 	</FlexContainer>
    // );
};

export default ResetPage;
