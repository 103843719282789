import FlexContainer from '../../../common/FlexContainer';
import {FormResults} from '../../../common/Form';
import Spinner from '../../../common/loading/Spinner';
import Typography from '../../../common/text/Typography';
import {
    useMoveAttributeMutation,
    useGetAttributesQuery
} from '../../api';
import {mergeErrorStates, extractQueryErrMessage} from '../../api/helpers';
import {isRelation} from '../../ontology/typeGuards/attributeGuards';
import {GetEntityAttrsResponse} from '../../ontology/types/attributeTypes';
import {
    moveAttrFormDefaults,
    moveAttrFormToPayload,
} from './moveAttributeHelpers';
import {MoveAttrFormValues} from './moveAttributeTypes';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import React, {FunctionComponent, useCallback} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import useActiveEntityData from "../../ontology/hooks/useActiveEntityData";
import AppModal from "../../../common/modals/AppModal";
import useModalType from "../../HUD/hooks/useModalType";
import {PrependAttrFormValues} from "../prependAttribute/prependAttributeTypes";
import OtherIdSelect from "../../../common/inputs/OtherIdSelect";
import Uninitialized from "../../../common/loading/Uninitialized";
import Loading from "../../../common/loading/Loading";

const MoveAttributeForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
	const {objectId: _id, resource, canEdit} = modalProps;

    const objectEntityId = isRelation(resource) ? resource.objectEntityId : null;
    const {activeItem: activeEntity} = useActiveEntityData();
    const formDefaults = moveAttrFormDefaults(0);
    // const formDefaults = loadAttrFormDefaults();

    const {handleSubmit, register, formState} = useForm<MoveAttrFormValues>({
        defaultValues: formDefaults,
    });

    const queryRes = useGetAttributesQuery(
        !!objectEntityId && typeof objectEntityId !== 'number'
            ? {entityId: objectEntityId._id} : skipToken
    );
    // const queryRes = useGetEntitiesQuery(
    //     activeEntity ? {domainId: activeEntity.domain} : skipToken
    // );

    const validatingRegister = useCallback(
        (inputName: keyof MoveAttrFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    // useEffect(() => {
    //     if (queryRes.isSuccess && closeModal) {
    //         closeModal();
    //     }
    // }, [queryRes.isSuccess, closeModal]);

    const [moveAttr, mutationResults] = useMoveAttributeMutation();

    const mergedErrs = mergeErrorStates(queryRes, mutationResults);

    const onSubmit: SubmitHandler<MoveAttrFormValues> = (vals, e) => {
        e?.preventDefault();
        moveAttr({
            attrId: _id,
            body: moveAttrFormToPayload(vals),
        });
    };

    // if (queryRes.isUninitialized) {
    //     return (
    //         <FlexContainer justifyContent="center">
    //             <Typography color="warn" paragraph>
    //                 Waiting to receive target entity Id... If this message does
    //                 not clear, it is likely that no entity id is available for
    //                 the target entity of this relation.
    //             </Typography>
    //             <Spinner/>
    //         </FlexContainer>
    //     );
    // }
    //
    // if (queryRes.isLoading) {
    //     return (
    //         <FlexContainer justifyContent="center">
    //             <Typography paragraph>Loading entities...</Typography>
    //             <Spinner/>
    //         </FlexContainer>
    //     );
    // }
    //
    // if (queryRes.isError) {
    //     return (
    //         <FlexContainer justifyContent="center">
    //             <Typography color="error" paragraph>
    //                 {extractQueryErrMessage(queryRes.error)}
    //             </Typography>
    //         </FlexContainer>
    //     );
    // }

    return (
        <AppModal
            label={"Move Attribute"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit ? canEdit : true}>

            {queryRes.isUninitialized ? (
                <FlexContainer justifyContent="center">
                    <Typography color="warn" paragraph>
                        Waiting to receive target entity Id... If this message
                        does
                        not clear, it is likely that no entity id is available
                        for
                        the target entity of this relation.
                    </Typography>
                    <Spinner/>
                </FlexContainer>
            ) : null}

            {queryRes.isLoading ? (
                <FlexContainer justifyContent="center">
                    <Typography paragraph variant='h5'>Loading
                        attributes...</Typography>
                    <Spinner/>
                </FlexContainer>
            ) : null}

            {queryRes.isError ? (
                <FlexContainer justifyContent="center">
                    <Typography color="error" paragraph>
                        {extractQueryErrMessage(queryRes.error)}
                    </Typography>
                </FlexContainer>
            ) : null}

            {/*{renderOtherEntitySelect(*/}
            {/*    'entityId',*/}
            {/*    register,*/}
            {/*    formState,*/}
            {/*    //   Ok to cast--if it's not loading and not errored, data is present*/}
            {/*    queryRes.data as GetEntitiesResponse*/}
            {/*)}*/}
            {!queryRes.isUninitialized && !queryRes.isLoading && !queryRes.isError ?
                <OtherIdSelect
                    fieldName={'attributeId'}
                    validatingRegister={validatingRegister}
                    formState={formState}
                    selectableAttrs={ queryRes.data as GetEntityAttrsResponse}
                /> : queryRes.isUninitialized ? <Uninitialized /> : <Loading />
                //   Ok to cast--if it's not loading and not errored, files is present
            }
            <FormResults
                isError={!!mergedErrs}
                error={mergedErrs}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default MoveAttributeForm;
