import { isNewIndividualIdentifier } from '../helpers';
import QuestionContext from '../state/QuestionContext';
import { QuestionSubject } from '../types/questionTypes';
import { CreationId } from '../helpers';
import CreateQuestionForm from './CreateQuestionForm';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { List } from 'common/List';
import { useGetQuestionsQuery } from 'features/api';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import { FunctionComponent, useState } from 'react';
import {Accordion,} from 'react-accessible-accordion';
import { ID } from 'react-accessible-accordion/dist/types/components/ItemContext';
import { CSSProperties } from 'styled-components';
import Question from "./Question";

interface Overrides {
	list?: CSSProperties;
}

interface QuestionListProps {
	objectType: QuestionSubject;
	parentObjectIdentifier: CreationId;
	canEdit: boolean;
	overrides?: Overrides;
}

const QuestionList: FunctionComponent<QuestionListProps> = ({
	overrides,
	canEdit,
	parentObjectIdentifier,
	objectType,
}) => {
	const [expanded, setExpanded] = useState<ID[]>([]);

	const { data, ...questionLoadState } = useGetQuestionsQuery(
		isNewIndividualIdentifier(parentObjectIdentifier)
			? skipToken
			: {
					objectType,
					objectId: parentObjectIdentifier.objectId,
			  }
	);

	return (
		<QuestionContext.Provider
			value={{
				parentObjectId: parentObjectIdentifier,
				parentObjectType: objectType,
				canEdit,
			}}
		>
			<Accordion
				allowZeroExpanded={true}
				onChange={(ids) => setExpanded(ids)}
			>
				<List
					style={{
						maxHeight: '40vh',
						overflowY: 'auto',
						maxWidth: '400px',
						...overrides?.list,
					}}
				>
					<CreateQuestionForm />
					<DisplayOnLoad
						{...questionLoadState}
						toggleOnRefetch={true}
						toggleOnUninitialized={false}
					>
						{data &&
							data.map((question) => (
								<Question
									key={question._id}
									question={question}
									expanded={expanded}
								/>
							))}
					</DisplayOnLoad>
				</List>
			</Accordion>
		</QuestionContext.Provider>
	);
};

export default QuestionList;
