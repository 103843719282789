import { range } from 'd3-array';
import { customAlphabet } from 'nanoid';
import { lowercase } from 'nanoid-dictionary';

export const asClass = (s: string) => `.${s}`;

export const nanoClassId = customAlphabet(lowercase, 10);

export const prefixClassName = (componentId: string) => (suffix: string) =>
	`${componentId}-${suffix}`;

export const getExactThresholds = (
	binCount: number,
	min: number,
	max: number
) => range(binCount).map((t) => min + (t / binCount) * (max - min));
