import {
	CreateQuestionFormValues,
	CreateQuestionPayload,
	GeneralQuestionCreationId,
	GetQuestionsParams,
	QuestionSubject,
} from './types/questionTypes';

export interface GeneralCreationId {
	kind: 'other';
	objectId: number;
}

export interface NewIndividualCreationId {
	kind: 'newIndividual';
	attributeId: number;
	primaryAttributeValue: string | number;
}

export type CreationId =
	| NewIndividualCreationId
	| GeneralCreationId;


export const isNewIndividualIdentifier = (
	v: CreationId
): v is NewIndividualCreationId => {
	return v.kind === 'newIndividual';
};

interface CreateGeneralQuestionFormParams {
	formValues: CreateQuestionFormValues;
	objectIdentifier: GeneralQuestionCreationId;
	objectType: Exclude<QuestionSubject, 'Individual'>;
}

interface CreateNewQuestionFormParams {
	formValues: CreateQuestionFormValues;
	objectIdentifier: CreationId;
	objectType: 'Individual';
}

type CreateQuestionFormParams =
	| CreateNewQuestionFormParams
	| CreateGeneralQuestionFormParams;

export const createQuestionFormToPayload = ({
	formValues,
	objectIdentifier,
	objectType,
}: CreateQuestionFormParams): CreateQuestionPayload => {
	return {
		question: formValues.question,
		objectIdentifier: objectIdentifier,
		objectType,
	};
};

export const getURLStumpFromObjectType = (objectType: QuestionSubject) => {
	switch (objectType) {
		case 'Organization':
			return 'orgs';
		case 'User':
			return 'users';
		case 'DataSource':
			return 'sources';
		case 'DataCatalog':
			return 'catalogs';
		case 'Dataset':
			return 'datasets';
		case 'DataColumn':
			return 'columns';
		case 'DataRow':
			return 'rows';
		case 'DataValue':
			return 'values';
		case 'DataType':
			return 'types';
		case 'Metric':
			return 'metrics';
		case 'Fact':
			return 'facts';
		case 'Insight':
			return 'insights';
		case 'Domain':
			return 'domains';
		case 'Entity':
			return 'entities';
		case 'Attribute':
			return 'attributes';
		case 'Individual':
			return 'individuals';
	}
};

export const buildQuestionsQueryURL = ({
	objectId,
	objectType,
}: GetQuestionsParams) =>
	`/${getURLStumpFromObjectType(objectType)}/${objectId}/questions`;

export const buildParametrizedQuestionsQueryURL = (
	objectType: QuestionSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/questions`;
