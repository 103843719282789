import useHookId from './useHookId';
import { axisLeft } from 'd3-axis';
import { ScaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { useLayoutEffect } from 'react';

const classBase = 'linear-y-axis';

const useLinearYAxis = (yScale: ScaleLinear<any, any>, leftMargin: number, tickCount?: number) => {
	const className = useHookId(classBase);

	useLayoutEffect(() => {
		const selector = `.${className}`;

		const axisGroup = select<SVGGElement, unknown>(selector);

		if (axisGroup) {
			axisGroup
				.attr('transform', `translate(${leftMargin}, 0)`)
				.call(axisLeft(yScale).tickSize(0).tickPadding(5).ticks(tickCount));
		}
	}, [yScale, leftMargin, className]);

	return className;
};

export default useLinearYAxis;
