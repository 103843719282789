import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import { DefaultTheme } from 'styled-components';
import theme from '../theme/theme';
import TooltipBody from "../Tooltip";
import { usePopperTooltip } from 'react-popper-tooltip';
import {Placement} from "@popperjs/core";
import {forkRef} from "../utils/reactUtils";

const selectThemeColor = (color: ColorProp, theme: DefaultTheme) => {
	switch (color) {
		case 'error':
			return theme.palette.error.main
		case 'warn':
			return theme.palette.warning.main
		case 'primary':
			return theme.palette.primary.main
		case 'darkBaby':
			return theme.palette.darkBaby
		case 'lightBaby':
			return theme.palette.lightBaby
		case 'darkerBaby':
			return theme.palette.darkerBaby
		case 'oldManGray':
			return theme.palette.oldManGray
		case 'cyan':
			return theme.palette.cyan
		default:
			return 'inherit'
	}
};

export type ColorProp =
	| 'transparent'
	| 'default'
	| 'error'
	| 'warn'
	| 'primary'
	| 'lightBaby'
	| 'darkBaby'
	| 'darkerBaby'
	| 'oldManGray'
	| 'cyan';

export type TypographyVariant =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'body1'
	| 'button'
	| 'subtitle'
	| 'body2'
	| 'caption1'
	| 'overline'
	| 'caption2'
	| 'input';

interface TypographyProps extends HTMLAttributes<any> {
	color?: ColorProp;
	paragraph?: boolean;
	variant?: TypographyVariant;
	children?: ReactNode;
	style?: any;
	tooltip?: string;
	htmlFor?: string;
	onMouseEnter?: () => void;
	tooltipPlacement?: Placement;
	showTooltip?: boolean;
}

// TODO: using 'style' prop directly overrides composition with styled-components.
// Ideally we would preserve that ability.
const Typography: FunctionComponent<TypographyProps> = (props) => {
	const {
		tooltip,
		tooltipPlacement,
		showTooltip,
		onMouseEnter,
		htmlFor,
		paragraph = false,
		color = 'default',
		variant = 'body1',
		style,
		...rest
	} = props;

	const showingTooltip = !!(showTooltip ?? tooltip);

	const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
		usePopperTooltip({ placement: tooltipPlacement ?? 'auto' });

	const combinedRefs = setTriggerRef;

	const Component = htmlFor ? 'label' : paragraph ? 'p' : 'span';

	return (
		<>
		<Component
			onMouseEnter={onMouseEnter}
			ref={combinedRefs as any}
			style={{
				textAlign: 'inherit',
				color: color === 'transparent'
					? selectThemeColor('oldManGray', theme)
					: selectThemeColor(color, theme),
				...{
					lineHeight: `${theme.typography[variant]?.lineHeight}`,
					letterSpacing: `${theme.typography[variant]?.letterSpacing}`,
					fontSize: `${theme.typography[variant]?.fontSize}`,
					fontWeight: `${theme.typography[variant]?.fontWeight}`,
					fontFamily: `${theme.typography[variant]?.fontFamily}`,
				},
				...style,
			}}
			{...rest}
		>
			{props.children}
		</Component>
			{tooltip && visible && showingTooltip && (
					<TooltipBody{...getTooltipProps()} ref={setTooltipRef as any}>
						<Typography
							color={'oldManGray'}
							variant='caption2'
							style={{ whiteSpace: 'nowrap' }}>
							{tooltip}
						</Typography>
					</TooltipBody>
				)}
		</>
	);
};

export default Typography;
