import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleSelect from "./SubtleSelect";

const lineBreakTypes = {
    CRLF: '\r\n',
    LF: '\n',
    CR: '\r'
}

const LineBreakTypeSelect: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleSelect
        inline={true}
        label={`Linebreak`}
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('type')}
        isDirty={Boolean(formState.dirtyFields.type)}
        {...validatingRegister('type')}
    >
        {Object.entries(lineBreakTypes).map(([name, code]) =>{
            return <option key={name} value={name}>
                {name} {code}
            </option>
        })}
    </SubtleSelect>;
};

export default LineBreakTypeSelect;
