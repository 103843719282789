import { StyledProfileGridLayout } from 'features/profile/common/styledComponents';
import { FunctionComponent } from 'react';

const SearchPage: FunctionComponent = () => {

	return (
		<StyledProfileGridLayout>
			{/*<AttributeSelect />*/}
			{/*{renderViz()}*/}
		</StyledProfileGridLayout>
	);
};

export default SearchPage;
