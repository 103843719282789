import FlexContainer from '../../../common/FlexContainer';
import Typography from '../../../common/text/Typography';
import { renderCards } from '../helpers';
import { SourceSetter, SourceNavItemList } from '../types/uiTypes';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledFlexContainer = styled(FlexContainer)`
	gap: ${(p) => p.theme.spacing(3)};
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: ${(p) => p.theme.spacing(1)};
`;

interface CardDisplayProps {
	setter: SourceSetter;
	cardData: SourceNavItemList | null;
}

const CardDisplay: FunctionComponent<CardDisplayProps> = ({
	setter,
	cardData
}) => {
		return cardData === null ? <Typography variant='subtitle'>
			No data to display.
		</Typography> : <StyledFlexContainer>
			{renderCards(cardData, setter)}
		</StyledFlexContainer>;
};

export default CardDisplay;
