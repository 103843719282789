import styled from 'styled-components';

export const StyledFieldset = styled.fieldset`
	border: none;
	background: ${(p) => p.theme.palette.darkBaby}00;
	border-radius: ${(p) => p.theme.spacing(0.5)};
	&:hover {
		background: ${(p) => p.theme.palette.darkBaby};
		color: ${(p) => p.theme.palette.lightBaby};
	}
	opacity: 95%;
`;

export const StyledHiddenInput = styled.input`
	position: absolute;
	top: -500vh;
`;

export const StyledLabelsContainer = styled.div`
	display: flex;
	/* create container block for absolutely-positioned elements */
	position: relative;
	height: 100%;
	opacity: 95%;

	& div:not(:last-of-type) {
		border-right: 1px solid ${(p) => p.theme.palette.oldManGray};
	}
`;

export const StyledIconContainer = styled.div`
	margin:  0;
	display: flex;
	justify-content: center;
	width: 100%;
		opacity: 95%;

	&.show-divider {
		border-right: 1px solid ${(p) => p.theme.palette.divider};
	}

	&.hide-divider {
		border-right: 1px solid transparent;
	}
`;

	// top: 0;
export const StyledLabel = styled.label<{isFirst?: boolean, isFinal?: boolean;}>`
	position: relative;
	/* we want the label UI to appear ABOVE the slider UI */
	z-index: 2;
	height: 2rem;
	width: 4rem;
	display: grid;
	justify-items: center;
	justify-content: center;
	align-items: center;
	align-content: center;
	cursor: pointer;
	border-radius: ${(p) => p.isFirst && p.isFinal 
	? `${p.theme.spacing(0.5)}` 
	: p.isFirst ? `${p.theme.spacing(0.5)} ${p.theme.spacing(0)} ${p.theme.spacing(0)} ${p.theme.spacing(0.5)}`
		: p.isFinal ? `${p.theme.spacing(0)} ${p.theme.spacing(0.5)} ${p.theme.spacing(0.5)} ${p.theme.spacing(0)}` 
			: p.theme.spacing(0)};
	opacity: 95%;
	background-color: ${(p) => p.theme.palette.slickRick};
	color: ${(p) => p.theme.palette.lightBaby};

	&:hover,
	&.is-selected {
		background: ${(p) => p.theme.palette.darkerBaby};
		color: ${(p) => p.theme.palette.oldManGray};
	}

	&.is-selected:hover {
		background-color: ${(p) => p.theme.palette.darkerBaby} !important;
	}
`;

export const StyledSlider = styled.div<{
	leftDisplacement: number;
	labelWidth: number;
}>`
	position: absolute;
	z-index: 1;
	background: ${(p) => p.theme.palette.darkBaby};
	transform: translate(${(p) => p.leftDisplacement - 1}px);
	border-radius: ${(p) => p.theme.spacing(0.5)};
	transition: transform 0.1s ease-out;
	top: 0;
	opacity: 95%;
`;
	// width: ${(p) => p.labelWidth + 1}px;
	// height: 100%;
	// height: ${(p) => p.labelWidth + 1}px;

