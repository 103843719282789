import React, {FunctionComponent} from "react";
import Username from "common/text/Username";
import {StyledMemberDataListItem} from "../../features/governance/components/styledComponents";
import {ListItemControls} from "../List";
import Typography from "../text/Typography";
import OrgName from "../text/OrgName";
// import LastActive from "../text/LastActive";
// import RemovalButton from "../../features/governance/components/RemovalButton";
// import UserItem from "./UserItem";
import {BaseOrg} from "../../features/ontology/types/orgTypes";
import RouterLink from "../RouterLink";
import { IconButtonBase } from 'common/buttons/IconButton';
import ImageAvatar from 'common/avatars/ImageAvatar';
import { isEmpty } from 'common/utils/typeGuards';
import { Empty } from 'common/utils/typeUtils';
import {forwardRef, useState} from 'react';
import styled from 'styled-components';
import {useNavigate} from "react-router";
import ProfileAvatar
	from "../../features/navigation/components/Navbar/ProfileAvatar";


interface AvatarProps {
	onClick?: (e?: any) => void;
	onHover?: (e?: any) => void;
	onExit?: (e?: any) => void;
	initial: string;
	profileImageURL: Empty | string | "";
	isOnline?: boolean;
	isProfile?: boolean;
	resource?: any;
	children?: any
	styles?: any;
	width?: string;
	height?: string;
}


const StyledAvatar = styled.div<{width?: string, height?: string}>`
  position:relative;
  display:inline-block;
  height: ${(p) => p.height ? p.height : '32px'};  
  width: ${(p) => p.width ? p.width : '32px'};  
`

const StyledAvatarImage = styled.img`
  /*width:100px;*/
  /*height:100px;*/
  object-fit:cover;
  border-radius:100%;
`

const StyledAvatarStatus = styled.span`
  width: 10px;
  height: 10px;
  background-color: #60b3a1;
  border: 1px solid #3e4354;
  position: absolute;
  bottom: 2%;
  right: 2%;
  border-radius: 100%;
`

const StyledLargeAvatarStatus = styled.span`
  width: 22px;
  height: 22px;
  background-color: #60b3a1;
  border: 3px solid #3e4354;
  position: absolute;
  bottom: 2%;
  right: 2%;
  border-radius: 100%;
`

export const ListItemContent = styled.div<{ truncate?: boolean }>`
	text-overflow: ${(p) => (p.truncate ? 'ellipsis' : 'default')};
	white-space: ${(p) => (p.truncate ? 'nowrap' : 'default')};
	overflow: ${(p) => (p.truncate ? 'hidden' : 'default')};
	flex-grow: 1;
	text-align: center;
	width: 200px;
`;

  // border: 3px solid #3e4354;

// const ProfileAvatar = forwardRef<HTMLButtonElement, AvatarProps>(
// 	({ resource, width, height, styles, onHover, onExit, onClick, profileImageURL, initial, isProfile, isOnline = false , children}, ref) => {
//
// 		let onlineStyle = isOnline
// 			? "avatar-online"
// 			: "avatar-offline";
//
// 		const baseComponent = isEmpty(profileImageURL)
// 			? (
// 			<IconButtonBase
// 				size="sm"
// 				fillColor="default"
// 				baseOpacity="100%"
// 				className={onlineStyle}
// 				ref={ref as any}
// 				style={{
// 					...styles,
// 					objectFit: 'cover',
// 					borderRadius: '0'
// 				}}
// 				onMouseEnter={onHover}
// 				onMouseLeave={onExit}
// 				width={width}
// 				height={height}
// 				onClick={onClick}
// 				aria-label="user profile menu"
// 			>
// 				<Typography variant='h5'>
// 					{initial}
// 				</Typography>
// 			</IconButtonBase>
// 		) : <ProfileAvatar
//                     profileImageURL={resource.avatar.medium}
//                     initial={resource.name[0]}
//                     isProfile={true}
//                     onClick={() => {
//                     }}
// 					avatarScale={resource.avatarScale ?? undefined}
// 					avatarPosition={resource.avatarPosition ?? undefined}
//                     isOnline={isOnline}
//                     height={'12rem'}
//                     width={'12rem'}
//                 />;
//                 // (
// 			// <ImageAvatar
// 			// 	as="button"
// 			// 	aria-label="user profile menu"
// 			// 	src={profileImageURL as string}
// 			// 	className={onlineStyle}
// 			// 	style={{
// 			// 		...styles,
// 			// 		objectFit: 'cover',
// 			// 		borderRadius: '25px',
// 			// 		backgroundPosition: 'center'
// 			// 	}}
// 			// 	backgroundSize={
// 			// 		!!resource.avatarScale
// 			// 			? `${resource.avatarScale}%`
// 			// 			: 'cover'
// 			// 	}
// 			// 	width={width}
// 			// 	height={height}
// 			// 	ref={ref as any}
// 			// 	data-testid="image"
// 			// />);
//
// 		return <>
// 			<StyledAvatar width={width} height={height} onClick={onClick}>
// 				{baseComponent}
// 				{isOnline ? isProfile ? <StyledLargeAvatarStatus /> : <StyledAvatarStatus/> : null}
// 			</StyledAvatar>
// 			{children}
// 		</>
// 	});


const OrgCard: FunctionComponent<{org: BaseOrg, onClick?: any, controls?: any, variant?: string, children?: any}> = ({org, onClick, variant, controls, children}) => {

	const navigator = useNavigate();

    if (!!org) {

        const canEdit = org.userContext.permissions.edit
        || org.userContext.permissions.admin;

        if (variant && variant === 'slim') {
            return !!org && <StyledMemberDataListItem
            onClick={onClick}
            background="default"
            style={{flexDirection: 'column'}}
            // position={position}
            aria-labelledby={`org-${org._id}`}
        >
            <ProfileAvatar
				onClick={() => navigator(`${org.username}`, { replace: false })}
                width={'100px'}
                height={'100px'}
				resource={org}
                initial={org.label ? org.label : org.name}
                profileImageURL={org && org?.avatar?.medium}
				// isProfile={true}
				avatarScale={org.avatarScale}
				avatarPosition={org.avatarPosition}
                borderRadius={'25px'}
				isOnline={false}
				styles={{
				    borderRadius: '25px',
					alignSelf: 'center',
					justifySelf: 'center'
				}}
            />
            <ListItemContent style={{
                alignItems: 'center',
                justifyContent: 'center'
            }}>
				<RouterLink style={{maxWidth: '100px'}} to={`./${org.name}`}>
					<OrgName org={org} />
                {/*<Username user={org}/>*/}
                {/*<LastActive org={org} position='flex-start' />*/}
                {/*<Username user={user} />*/}
                {/*<Error resource={org} handleError={handleError} />*/}
				</RouterLink>
            </ListItemContent>
            {canEdit && controls && (
                <ListItemControls>
                    {controls}
                </ListItemControls>
            )}
        </StyledMemberDataListItem>

        } else {
            return !!org && <StyledMemberDataListItem
            onClick={onClick}
            background="default"
            // position={position}
            aria-labelledby={`org-${org._id}`}
        >
            <ProfileAvatar
                onClick={() => {}}
                initial={org.label ? org.label : org.name}
                profileImageURL={org && org?.avatar?.medium}
            />
            <ListItemContent style={{
                alignItems: 'center',
                justifyContent: 'center',
                margin: '2px 0 2px 2px',
                paddingLeft: '8px'
            }}>
                <OrgName org={org} styles={{ padding: '1px' }}/>
                <Username user={org}/>
                {/*<LastActive org={org} position='flex-start' />*/}
                {/*<Username user={user} />*/}
                {/*<Error resource={org} handleError={handleError} />*/}
            </ListItemContent>
            {canEdit && controls && (
                <ListItemControls>
                    {controls}
                </ListItemControls>
            )}
        </StyledMemberDataListItem>
        }


    } else {
        return null
    }
}

export default OrgCard;
