import theme from '../../common/theme/theme';
import { OrgGraphDataResponse } from '../ontology/types/orgTypes';
import { DomainGraphDataResponse } from '../ontology/types/domainTypes';
import {
	SelectedObjectWithVC,
	UIGraphNode,
	UILinkObject,
	WithViewportCoordinates,
} from './types/graphTypes';
import { GraphData, ForceGraphMethods } from 'react-force-graph-2d';

// const colors = [theme.palette.cyan, '#6C8AEC', theme.palette.primary.main];

/**
 * Objects coming from RTK Query hooks have been frozen,
 * presumably by Immer.  Force graph animation engine requires mutability, so this
 * simply unwraps all the node and edge objects and rewraps them in
 * new, unfrozen, objects that force graph can manipulate.
 */
const rewrapIncomingGraphObject =
	(kind: 'link' | 'node') =>
	<T extends {}>(obj: T) => ({
		...obj,
		kind,
		isGhost: false,
		// color: kind === 'node' ? colors[i % colors.length] : undefined,
	});

export const toUIGraph = ({
	edges,
	nodes,
}: OrgGraphDataResponse | DomainGraphDataResponse): GraphData => {
	const links = edges.map(rewrapIncomingGraphObject('link'));
	const tryingHard = [
		...nodes.map(rewrapIncomingGraphObject('node')),
		{ kind: 'node', isGhost: true },
	];
	const graphNodes = nodes.map(rewrapIncomingGraphObject('node'));
	if (nodes.length === 1) {
		return {
			links,
			nodes: tryingHard,
		};
	}
	return { links, nodes: graphNodes };
};

export const create2DNodeLabel = ({
	label,
	name,
}: {
	label?: string;
	name: string;
}) => `
<span style="color: ${
	theme.palette.primary.main
}; background-color:#ffffff75">${label || name}</span>
`;

/**
 * Manages popover location on force graph.  If called with null
 * as its only argument,
 * returns coordinates that will place the popover outside of
 * the user's viewport.
 */
const initialPopoverClientRect: DOMRect = {
	width: 0,
	height: 0,
	top: -500,
	left: -500,
	bottom: -500,
	right: -500,
	x: -500,
	y: -500,
	toJSON: () => 'toJSON called on virtual DOMRect',
};

export const generateGetBoundingClientRect = (
	graphObject: SelectedObjectWithVC | null
): (() => DOMRect) => {
	if (graphObject) {
		const { viewportX: x, viewportY: y } = graphObject;

		return () => ({
			width: 50,
			height: 50,
			top: y,
			right: x + 50,
			bottom: y + 50,
			left: x,
			x,
			y,
			toJSON: () => 'toJSON called on virtual DOMRect',
		});
	}

	return () => initialPopoverClientRect;
};

/**
 * Add viewport-based coordinates to link object in force graph
 */
export const UILinkToVCLink = (
	fgm: ForceGraphMethods,
	linkObj: UILinkObject
): WithViewportCoordinates<UILinkObject> => {
	const { graph2ScreenCoords } = fgm;

	const { source, target } = linkObj;

	const { x: source2DX, y: source2DY } = graph2ScreenCoords(
		source.x,
		source.y
	);

	const { x: target2DX, y: target2DY } = graph2ScreenCoords(
		target.x,
		target.y
	);

	//  Caculate the viewport coordinates of the middle of the line that represents this link
	const popupX =
		Math.min(source2DX, target2DX) + Math.abs(source2DX - target2DX) / 2;

	const popupY =
		Math.min(source2DY, target2DY) + Math.abs(source2DY - target2DY) / 2;

	return { ...linkObj, viewportX: popupX, viewportY: popupY };
};

/**
 * Add viewport-based coordinates to node object in force graph
 */
export const UINodeToVCNode = (
	fgm: ForceGraphMethods,
	node: UIGraphNode
): WithViewportCoordinates<UIGraphNode> => {
	const { graph2ScreenCoords } = fgm;

	const { x: screenX, y: screenY } = graph2ScreenCoords(node.x, node.y);

	return { ...node, viewportX: screenX, viewportY: screenY };
};

export const centerOnNode = (fgm: ForceGraphMethods, node: UIGraphNode) => {
	fgm.centerAt(node.x, node.y, 500);
};

// function roundRect(ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, r: number) {
//   if (w < 2 * r) r = w / 2;
//   if (h < 2 * r) r = h / 2;
//   ctx.beginPath();
//   ctx.moveTo(x+r, y);
//   ctx.arcTo(x+w, y,   x+w, y+h, r);
//   ctx.arcTo(x+w, y+h, x,   y+h, r);
//   ctx.arcTo(x,   y+h, x,   y,   r);
//   ctx.arcTo(x,   y,   x+w, y,   r);
//   ctx.closePath();
//   return ctx;
// }

export const drawDefaultNode = (
	graphNode: any,
	ctx: CanvasRenderingContext2D,
	globalScale: number
) => {
	//  type shenanigans here: type parameter 'graphNode' as 'any' so that we can
	// pass this callback to react-force-graph component without raising a type error,
	// then redeclare it with correct type in function body so that compiler can type-check
	// function body.
	const node: UIGraphNode = graphNode;

	if (node.isGhost) {
		return;
	}

	const dotRadius = 3;
	const dotBorderThickness = 0.4;
	const labelOffset = dotRadius + dotBorderThickness + 0.5;
	const fontSize = 16 / globalScale;
	const fontFamily = theme.typography.body1.fontFamily;

	const { x, y } = node;
	const color =
		node._object === 'Domain'
			? theme.palette.primary.main
			: theme.palette.secondary.main;

	ctx.fillStyle = color;

	if (node._object === 'Entity') {
		// Draw filled circle
		ctx.beginPath();
		ctx.arc(x, y, dotRadius, 0, 2 * Math.PI, false);
		ctx.fill();

		// Draw circle border
		ctx.beginPath();
		ctx.arc(x, y, dotRadius, 0, 2 * Math.PI, false);
		ctx.lineWidth = dotBorderThickness;
		ctx.strokeStyle = theme.palette.darkBaby;
		ctx.stroke();
	} else if (node._object === 'Domain') {
		// Draw filled rect
		ctx.fillRect(
			x - dotRadius,
			y - dotRadius,
			2 * dotRadius,
			2 * dotRadius
		);
		// if (w < 2 * r) r = w / 2;
		// if (h < 2 * r) r = h / 2;
		// ctx.beginPath();
		// ctx.moveTo(x+r, y);
		// ctx.arcTo(x+w, y,   x+w, y+h, r);
		// ctx.arcTo(x+w, y+h, x,   y+h, r);
		// ctx.arcTo(x,   y+h, x,   y,   r);
		// ctx.arcTo(x,   y,   x+w, y,   r);
		// ctx.closePath();

		// Draw rect border
		ctx.strokeStyle = theme.palette.darkBaby;
		ctx.lineWidth = dotBorderThickness;
		ctx.strokeRect(
			x - dotRadius,
			y - dotRadius,
			2 * dotRadius,
			2 * dotRadius
		);
	}

	// Draw the label
	const label = node._object === 'Entity' && node.plural
		? node.plural
		: node._object === 'Domain' && node.label !== undefined
			? node.label
			: node.name !== undefined
				? node.name
				: String(node._id);
	ctx.beginPath();
	ctx.font = `bold ${fontSize}px ${fontFamily}`;
	ctx.textAlign = 'center';
	ctx.textBaseline = 'middle';
	ctx.fillStyle = theme.palette.darkBaby;
	ctx.fillText(label, x, y + labelOffset);
};

export const drawLineageNode = (node: any, ctx: CanvasRenderingContext2D) => {
	const dotRadius = 3;
	const dotBorderThickness = 1;
	const labelOffset = dotRadius + dotBorderThickness + 2;
	const fontSize = 5;
	const fontFamily = theme.typography.body1.fontFamily;
	const { x, y } = node;
	ctx.fillStyle = theme.palette.primary.main;

	// Draw filled circle
	ctx.beginPath();
	ctx.arc(x, y, 3, 0, 2 * Math.PI, false);
	ctx.fill();

	// Draw circle border
	ctx.beginPath();
	ctx.arc(x, y, 3.5, 0, 2 * Math.PI, false);
	ctx.lineWidth = dotBorderThickness;
	ctx.strokeStyle = theme.palette.darkBaby;
	ctx.stroke();

	// Draw the label
	const label = node.label;
	ctx.font = `${fontSize}px ${fontFamily}`;
	ctx.textAlign = 'center';
	ctx.textBaseline = 'middle';
	ctx.fillStyle = theme.palette.lightBaby;
	ctx.fillText(label, x, y + labelOffset);
};

export const drawNode = (drawMode: 'lineage' | 'default' = 'default') =>
	drawMode === 'lineage' ? drawLineageNode : drawDefaultNode;

export const drawLink = (
	graphLink: any,
	ctx: CanvasRenderingContext2D,
	globalScale: number
) => {
	//  type shenanigans here: type parameter 'graphLink' as 'any' so that we can
	// pass this callback to react-force-graph component without raising a type error,
	// then redeclare it with correct type in function body so that compiler can type-check
	// function body.
	const link: UILinkObject = graphLink;

	const { source, target } = link;
	ctx.beginPath();
	ctx.moveTo(source.x, source.y);
	ctx.lineTo(target.x, target.y);
	ctx.lineWidth = Math.min(globalScale, 1);
	// ctx.lineWidth = Math.min(globalScale * 0.25, 0.4);
	ctx.strokeStyle = theme.palette.cyan;
	ctx.stroke();
};

/**
 *
 * keeps zoom-to-fit function from yielding absurd results when there are only a few
 * nodes on the screen.
 */
export const selectPadding = (nodeCount: number) => {
	if (nodeCount === 1) {
		return 400;
	}

	return 150;
};

export const DEFINITION_PLACEHOLDER = 'Add a definition';

export const nullifyDefinitionPlaceholder = (s: string) =>
	s === DEFINITION_PLACEHOLDER ? null : s;
