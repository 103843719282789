import styled from "styled-components";
import Typography from "./text/Typography";
import FlexContainer from "./FlexContainer";


export const StyledHeaderSection = styled.div`
	display: grid;
	grid-template:
	 [row1-start] "avatar header" 1fr [row1-end] 
	 [row2-start] ". social" auto [row2-end] 
	 / auto 1fr;
	justify-items: start;
	place-content: flex-start flex-start;
	align-items: start;
	gap: 1rem;
	width: 100%;
	height: 100%;
	text-align: left;
		
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		grid-template:
		 [row1-start] "avatar" 1fr [row1-end] 
		 [row2-start] "header" 1fr [row2-end] 
		 [row3-start] "social" auto [row3-end] 
		 / 1fr;
		 gap: 0;
		place-items: center center;
		place-content: center center;
		text-align: center;
	}
`;
// export const StyledHeaderSection = styled.div`
// 	display: grid;
// 	grid-template:
// 	 [row1-start] "avatar header" 8rem [row1-end]
// 	 [row4-start] ". social" 3rem [row4-end]
// 	 [row3-start] ". info" 3rem [row3-end]
// 	 / 6rem 1fr;
// 	justify-items: start;
// 	justify-content: start;
// 	align-content: start;
// 	align-items: start;
// 	gap: 1rem;
// 	width: 100%;
// 	height: 100%;
// `;

//    [row6-start] "location" 1fr [row6-end]
// export const StyledLabelSection = styled.div`
// 	display: grid;
// 	grid-template:
//     [row1-start] "overline" 1fr [row1-end]
//     [row2-start] "title" 1fr [row2-end]
//     [row2-start] "title" 1fr [row2-end]
//     [row3-start] "url" 1fr [row3-end]
//     / 100%;
// 	justify-items: flex-start;
// 	justify-content: flex-start;
// 	align-content: center;
// 	align-items: center;
// 	width: 100%;
// 	height: 100%;
// `;

export const StyledLabelSection = styled.div`
	display: grid;
	grid-template:
    [row1-start] "overline" auto [row1-end]
    [row2-start] "title" 1fr [row2-end]
    [row3-start] "url" 1fr [row3-end]
    / 1fr;
	justify-content: start;
	align-content: start;
	justify-items: start;
	align-items: start;
	
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		place-items: center;
		place-content: start;
		text-align: center;
	}
`;


export const StyledSection = styled.div`
	width: 100%;
	height: 100%;
`;

export const StyledOtherSection = styled.div`
	display: grid;
	grid-template:
    [row1-start] "overline" auto [row1-end]
    [row2-start] "title" 1fr [row2-end]
    [row3-start] "url" auto [row3-end]
    [row4-start] "empty" 1rem [row4-end]
    [row5-start] "social" 1fr [row5-end]
    [row6-start] "location" 1fr [row6-end]
    / 1fr;
	justify-items: start;
	justify-content: stretch;
	align-content: start;
	align-items: start;
`;

export const OptimizedContainer = styled(FlexContainer)`

    padding: 0;
    margin: 0;
    justify-content: start;
    justify-items: start;
    justify-self: start;
    align-self: start;
    align-items: center;
    align-content: center;
    width: 100%;
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		justify-items: center;
		justify-content: center;
		align-content: center;
		align-items: center;
		text-align: center;
	}
`
