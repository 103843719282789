import { nanoid } from '@reduxjs/toolkit';
import { useMemo } from 'react';

//  create a unique id that persists as long as this hook is
// mounted, and uniquely distinguishes this axis from others.
const useHookId = (classBase: string) => {
	return useMemo(() => {
		const id = nanoid();
		return `${classBase}-${id}`;
	}, [classBase]);
};

export default useHookId;
