import PopoverBase from '../popovers/Popover/PopoverBase';
import Typography from '../text/Typography';
import {nanoid} from '@reduxjs/toolkit';
import {RestrictionKind} from 'features/actions/restrictEntity/restrictEntityTypes';
import {useAppDispatch} from 'app/hooks';
import useModalType from 'features/HUD/hooks/useModalType';
import {setModalType} from 'features/HUD/state/HUDSlice';
import {
    FunctionComponent,
    MouseEventHandler,
    useState,
    useCallback,
    Dispatch,
    SetStateAction,
} from 'react';
import {ListItem, ListItemAvatar} from "../List";

import styled from "styled-components";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {
    useFollowResourceMutation,
    useGenDomainFromCatalogMutation,
    useGenDomainFromDatasetMutation,
    useImportCatalogToDomainMutation,
    useImportDatasetToDomainMutation,
    useUnfollowResourceMutation
} from "../../features/api";
import {ActionConfig} from "./MenuPanel";
import {ModalType} from "../../features/HUD/types/HUDTypes";
import {
    LiveDataCatalog,
    LiveDataSet
} from "../../features/browser/types/dataTypes";
import {BaseDomain} from "../../features/ontology/types/domainTypes";
import {warnInDev} from "../utils/reactUtils";


interface ActionListItemProps {
    // actionWithActiveSubmenu: string | null;
    // Form: any;
    // setOpenAction: Dispatch<SetStateAction<string | null>>;
    // actionTitle: string;
    // onClick?: () => void;
    // restrictionKind?: RestrictionKind;
    action: ActionConfig;
}


// export const MenuAction: FunctionComponent<{onClick: () => void, label: string}> = ({onClick, label}) => {
// 	return 	<StyledListItem button onClick={onClick} style={{alignContent: 'center'}}>
// 		<ListItemAvatar>
// 			<TinySquare />
// 		</ListItemAvatar>
// 		<Typography variant={'h6'} style={{paddingLeft: '4px'}}>
// 			{label}
// 		</Typography>
// 	</StyledListItem>
// }

type Importable = LiveDataCatalog | LiveDataSet;

const isCatalog = (i: Importable): i is LiveDataCatalog =>
	i._object === 'DataCatalog';

const generateParams = (i: Importable, domain: BaseDomain | null) => {
	console.log(i)
	console.log(domain)
	if (domain) {
		if (isCatalog(i)) {
			const params = {
				domainId: domain._id,
				sourceId: i.internalSourceId,
				catalogName: i.name,
			};

			return params;
		}

		return {
			domainId: domain._id,
			sourceId: i.internalSourceId,
			catalogName: i.catalogName,
			datasetName: i.name,
		};
	}

	if (isCatalog(i)) {
		return { sourceId: i.internalSourceId, catalogName: i.name };
	}
	return {
		sourceId: i.internalSourceId,
		catalogName: i.catalogName,
		datasetName: i.name,
	};
};

const selectHook = (i: Importable, domainIsAvailable: boolean) => {
	if (domainIsAvailable) {
		if (isCatalog(i)) {
			return useImportCatalogToDomainMutation;
		}

		return useImportDatasetToDomainMutation;
	}

	if (isCatalog(i)) {
		return useGenDomainFromCatalogMutation;
	}
	return useGenDomainFromDatasetMutation;
};

const MenuAction: FunctionComponent<ActionListItemProps> = ({action}) => {
        const appDispatch = useAppDispatch();

        // const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);
        // const [popperUpdateKey, setPopperUpdateKey] = useState<string | null>(null);
        // const updatePopperKey = useCallback(() => setPopperUpdateKey(nanoid()), []);
        // const submenuOpen = actionWithActiveSubmenu === action.modalType;

        const navigate = useNavigate();
        const params = useParams();
        const [follow, followResult] = useFollowResourceMutation();
        const [unfollow, unfollowResult] = useUnfollowResourceMutation();

        const [doImport, importResult] = selectHook(action.args.resource, false)();

        const generateOntology: MouseEventHandler = (e) => {
            e.preventDefault();

            // If true, the last mutation request is still in-process.  Do nothing.
            if (importResult.isLoading) {
                warnInDev(
                    'Source import button clicked while last load request still in-proces',
                    'warn'
                );
                return;
            }

            // from this call site, generateParams can assume that if activeDomain
            // is null, no domain will ever be available.  This assumption will need
            // to be revisited if generateParams is ever to be called elsewhere.
            const params = generateParams(action.args.resource, null);

            // TODO: these types could probably be made to align
            doImport(params as any);
        };

        // const actionMap = {
        //     unfollow: {
        //         func: unfollow,
        //         args: {
        //             objectId: action.args.resource._id,
        //             objectType: action.args.resource._object
        //         }
        //     }
        // }
        //

        if (action.kind === 'link') {
            if (!!action.location) {
                return (
                    <ListItem onClick={() => navigate(
                        action.location ? action.location
                            .replace(':org', action.args.resource.ontology.org)
                            .replace(':domain', action.args.resource.ontology.domain)
                            .replace(':entity', action.args.resource.ontology.entity)
                            .replace(':attribute', action.args.resource.ontology.attribute) : '',
                        {replace: false})}
                              button style={{margin: '0', height: '2.25rem'}}>
                        <Typography variant='subtitle'>
                            {action.actionTitle}
                        </Typography>
                    </ListItem>
                )
            } else {
                return <ListItem
                    onClick={() => {
                    }}
                    button
                    style={{margin: '0', height: '2.25rem'}}>
                    <Typography variant='subtitle'>
                        {action.actionTitle}
                    </Typography>
                </ListItem>
            }
        } else if (action.kind === 'action') {
            if (action.callback === 'follow') {
                if (!!action.args.resource.userContext) {
                    if (action.args.resource.userContext.interactions.following) {
                        return (
                            <ListItem
                                // ref={setAnchorEl as any}
                                onClick={() => unfollow({
                                    objectIdentifier: {
                                        objectId: action.args.resource._id,
                                        kind: 'other'
                                    },
                                    objectType: action.args.resource._object,
                                    body: {
                                        objectIdentifier: {
                                            objectId: action.args.resource._id,
                                            kind: 'other'
                                        },
                                        objectType: action.args.resource._object
                                    }
                                })}
                                button
                                style={{margin: '0', height: '2.25rem'}}>
                                <Typography variant='subtitle'>
                                    Unfollow {action.args.resource._object.toLowerCase()}
                                </Typography>
                            </ListItem>
                        )
                    } else {
                        return (
                            <ListItem
                                // ref={setAnchorEl as any}
                                onClick={() => follow({
                                    objectIdentifier: {
                                        objectId: action.args.resource._id,
                                        kind: 'other'
                                    },
                                    objectType: action.args.resource._object,
                                    body: {
                                        objectIdentifier: {
                                            objectId: action.args.resource._id,
                                            kind: 'other'
                                        },
                                        objectType: action.args.resource._object
                                    }
                                })}
                                button
                                style={{margin: '0', height: '2.25rem'}}>
                                <Typography variant='subtitle'>
                                    Follow {action.args.resource._object.toLowerCase()}
                                </Typography>
                            </ListItem>
                        )
                    }
                } else {
                    console.log(action.args.resource)
                    return (
                        <ListItem
                            // ref={setAnchorEl as any}
                            onClick={() => follow({
                                objectIdentifier: {
                                    objectId: action.args.resource.individual._id,
                                    kind: 'other'
                                },
                                objectType: action.args.resource.individual._object,
                                body: {
                                    objectIdentifier: {
                                        objectId: action.args.resource.individual._id,
                                        kind: 'other'
                                    },
                                    objectType: action.args.resource.individual._object
                                }
                            })}
                            button
                            style={{margin: '0', height: '2.25rem'}}>
                            <Typography variant='subtitle'>
                                Follow {action.args.resource.individual._object.toLowerCase()}
                            </Typography>
                        </ListItem>
                    )
                }
            } else if (action.callback === 'generateOntology') {
                // const resource = action.args.resource;
                // const object = resource._object;
                return (
                    <ListItem
                        // ref={setAnchorEl as any}
                        onClick={generateOntology}
                        button
                        style={{margin: '0', height: '2.25rem'}}>
                        <Typography variant='subtitle'>
                            {action.actionTitle}
                        </Typography>
                    </ListItem>
                )
            } else if (action.callback === 'copyLink') {
                const resource = action.args.resource;
                const object = resource._object;

                if (object === 'Organization') {
                    const textToCopy = `https://futuremodel.io/${resource.username}`
                    return (
                        <ListItem
                            // ref={setAnchorEl as any}
                            onClick={() => navigator.clipboard.writeText(textToCopy)}
                            button
                            style={{margin: '0', height: '2.25rem'}}>
                            <Typography variant='subtitle'>
                                {`Copy ${object} Link`}
                            </Typography>
                        </ListItem>
                    )
                } else if (object === 'Domain') {
                    const textToCopy = `https://futuremodel.io/${resource.ontology.org}/${resource.name}`
                    return (
                        <ListItem
                            // ref={setAnchorEl as any}
                            onClick={() => navigator.clipboard.writeText(textToCopy)}
                            button
                            style={{margin: '0', height: '2.25rem'}}>
                            <Typography variant='subtitle'>
                                {`Copy ${object} Link`}
                            </Typography>
                        </ListItem>
                    )
                } else if (object === 'Entity') {
                    const textToCopy = `https://futuremodel.io/${resource.ontology.org}/${resource.ontology.domain}/${resource.name}`
                    return (
                        <ListItem
                            // ref={setAnchorEl as any}
                            onClick={() => navigator.clipboard.writeText(textToCopy)}
                            button
                            style={{margin: '0', height: '2.25rem'}}>
                            <Typography variant='subtitle'>
                                {`Copy ${object} Link`}
                            </Typography>
                        </ListItem>
                    )
                } else if (object === 'Attribute') {
                    const textToCopy = `https://futuremodel.io/${resource.entity.ontology.org}/${resource.entity.ontology.domain}/${resource.entity.name}?attribute=${resource.name}`;
                    return (
                        <ListItem
                            // ref={setAnchorEl as any}
                            onClick={() => navigator.clipboard.writeText(textToCopy)}
                            button
                            style={{margin: '0', height: '2.25rem'}}>
                            <Typography variant='subtitle'>
                                {`Copy ${object} Link`}
                            </Typography>
                        </ListItem>
                    )
                    // } else if (object === 'Metric') {
                    //     const textToCopy = `https://futuremodel.io/${.ontology.org}/${action.args.resource.name}`
                    //     return (
                    //     <ListItem
                    //         // ref={setAnchorEl as any}
                    //         onClick={() => navigator.clipboard.writeText(textToCopy)}
                    //         button
                    //         style={{margin: '0', height: '2.25rem'}}>
                    //         <Typography variant='subtitle'>
                    //             {action.actionTitle}
                    //         </Typography>
                    //     </ListItem>
                    // )
                } else if (object === 'User') {
                    const textToCopy = `https://futuremodel.io/${resource.username}`
                    return (
                        <ListItem
                            // ref={setAnchorEl as any}
                            onClick={() => navigator.clipboard.writeText(textToCopy)}
                            button
                            style={{margin: '0', height: '2.25rem'}}>
                            <Typography variant='subtitle'>
                                {`Copy ${object} Link`}
                            </Typography>
                        </ListItem>
                    )
                } else if (object === 'Individual') {
                    const textToCopy = `https://futuremodel.io/${resource.ontology.org}/${resource.ontology.domain}/${resource.ontology.entity}/${resource.name}`
                    return (
                        <ListItem
                            // ref={setAnchorEl as any}
                            onClick={() => navigator.clipboard.writeText(textToCopy)}
                            button
                            style={{margin: '0', height: '2.25rem'}}>
                            <Typography variant='subtitle'>
                                {`Copy ${object} Link`}
                            </Typography>
                        </ListItem>
                    )
                } else {
                    const textToCopy = 'https://futuremodel.io'
                    return <ListItem
                        // ref={setAnchorEl as any}
                        onClick={() => navigator.clipboard.writeText(textToCopy)}
                        button
                        style={{margin: '0', height: '2.25rem'}}>
                        <Typography variant='subtitle'>
                            {action.actionTitle}
                        </Typography>
                    </ListItem>;
                }
            } else {
                return <ListItem
                    // ref={setAnchorEl as any}
                    onClick={() => {
                    }}
                    button
                    style={{margin: '0', height: '2.25rem'}}>
                    <Typography variant='subtitle'>
                        {action.actionTitle}
                    </Typography>
                </ListItem>;
            }
        } else if (action.kind === 'modal') {
            return (
                <ListItem
                    button
                    onClick={() => !!action.modalType && appDispatch(setModalType(
                        {type: action.modalType as ModalType, props: action.args}
                    ))}
                    style={{margin: '0', height: '2.25rem'}}>
                    <Typography variant='subtitle'>
                        {action.actionTitle}
                    </Typography>
                </ListItem>
            )
        } else {
            return <ListItem onClick={() => {
            }} button style={{margin: '0', height: '2.25rem'}}>
                <Typography variant='subtitle'>
                    {action.actionTitle}
                </Typography>
            </ListItem>
        }
    }
;

export default MenuAction;
// {/*{action.kind !== 'modal' ? <PopoverBase*/}
// {/*    anchorEl={anchorEl}*/}
// {/*    open={submenuOpen}*/}
// {/*    offset={0}*/}
// {/*    placement="right-start"*/}
// {/*    forceUpdateKey={popperUpdateKey}*/}
// {/*>*/}
// {/*    <action.form*/}
// {/*        {...props}*/}
// {/*        action={action}*/}
// {/*        handleClose={() => setOpenAction(null)}*/}
// {/*        updatePopper={updatePopperKey}*/}
// {/*        setOpenAction={setOpenAction}*/}
// {/*    />*/}
// {/*</PopoverBase>*/}
// {/*    : <action.form />*/}
// {/*}*/}
