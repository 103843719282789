import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";

// class DefaultValue(StrEnum):
//     Empty = 'empty'
//     NULL = 'null'
//     AutoIncrement = 'auto_increment'
//     CurrentTimestamp = 'current_timestamp'
//     Now = 'now'
//     String = 'string'
//     Expression = 'expression'

const DefaultValueInput: FunctionComponent<ActionFieldProps> = (
    {
        inline=true,
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
    ) => {
    return <SubtleTextbox
        {...validatingRegister('defaultValue', {})}
        showLabel
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        inline={inline}
        label="Default Value"
        isDirty={Boolean(formState.dirtyFields.defaultValue)}
        onReset={() => resetField('defaultValue')}
        error={harvestErrors('defaultValue', formState, mutationResults)}
    />;
}

export default DefaultValueInput;
