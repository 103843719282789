import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";
import IconButton from "../buttons/IconButton";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import FlexContainer from "../FlexContainer";

const FacebookInput: FunctionComponent<ActionFieldProps> = (
    {
        defaultValue,
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <FlexContainer alignItems={'center'}>
        <IconButton
            icon={faFacebook as any}
            tooltip=""
            size="xs"
            iconSize='lg'
            style={{marginLeft: '4px'}}
            onClick={() => {
            }}
            showTooltip={true}
        />
        <SubtleTextbox
            {...validatingRegister('facebook', {})}
            label="Facebook"
            defaultValue={defaultValue}
            isDirty={Boolean(formState.dirtyFields.facebook)}
            onReset={() => resetField('facebook')}
            error={harvestErrors(
                'facebook',
                formState,
                mutationResults
            )}
        />
    </FlexContainer>;
}

export default FacebookInput;
