import { QuestionSubject } from '../types/questionTypes';
import { CreationId } from '../helpers';
import { createContext } from 'react';

interface QuestionContextValue {
	parentObjectType: QuestionSubject;
	canEdit: boolean;
	parentObjectId: CreationId;
}

const QuestionContext = createContext<QuestionContextValue>({
	parentObjectId: {
		kind: 'other',
		objectId: 0,
	},
	parentObjectType: '' as QuestionSubject,
	canEdit: false,
});

export default QuestionContext;
