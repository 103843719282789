import FlexContainer from 'common/FlexContainer';
import FollowButton from 'common/buttons/FollowButton';
import TextButton from 'common/buttons/TextButton';
import {PopoverHead} from 'common/popovers/Popover';
import { setModalType } from 'features/HUD/state/HUDSlice';
import {
	PopoverHeading,
	PopoverSubhead,
	PopoverCardBody,
} from 'common/popovers/Popover/styledComponents';
import RouterLink from 'common/RouterLink';
import { BaseUser, UserModalTypes } from 'features/authentication/types/userTypes';
import React, {FunctionComponent, useEffect, useState} from 'react';
import useCurrentUser from "../../features/authentication/hooks/useUser";
import {SettingsButton} from "../buttons";
import theme from "../theme/theme";
import UserMenu from "../menus/UserMenu";
import {useAppDispatch} from "../../app/hooks";
import useModalType from "../../features/HUD/hooks/useModalType";

interface UserPopoverProps {
	handleClose?: () => void;
	onHold?: (p: boolean) => void;
	user: BaseUser;
}

const CreateButton: FunctionComponent<{canEdit: boolean, onClick: () => void}> = ({canEdit, onClick}) => {
	return canEdit
		? <TextButton text='Create object' color='primary' onClick={onClick} />
		: null;
}

const UserPopover: FunctionComponent<UserPopoverProps> = ({user, handleClose, onHold}) => {

	const currentUser = useCurrentUser();

	const appDispatch = useAppDispatch();
	const {modalType, closeModal} = useModalType();

	const heading = `${user.firstName} ${user.lastName}`;

	const subhead = user.username;

	const url = user.avatar?.medium.url ?? null;

	const isSelf = user.username === currentUser.username;
	const canEdit = user.userContext.permissions.admin || isSelf;
	const isFollowing = user.userContext.interactions.following;

	const [menuOpen, setMenuOpen] = useState(false);

	return (
		<PopoverCardBody onMouseEnter={() => {
				onHold && onHold(true);
			}} onMouseLeave={() => {
				onHold && onHold(false);
				handleClose && handleClose();
			}}>
			<FlexContainer justifyContent='space-between' style={{
                width: '100%',
                borderBottom: menuOpen ? `1px solid ${theme.palette.divider}` : 'none'
            }}>
			<PopoverHead imgSrc={url}>
				<PopoverSubhead variant='overline'/>
				<RouterLink
					to={`/${user.username}`}
					preserveSearchParams={false}
				>
					<PopoverHeading variant='h2'>
						{heading}
					</PopoverHeading>
				</RouterLink>
				<PopoverSubhead variant='h6'>
					{subhead}
				</PopoverSubhead>
			</PopoverHead>
				<UserMenu
					user={user}
					canEdit={canEdit}
				/>
			</FlexContainer>

			<FlexContainer
            justifyContent="space-between"
            style={{
                padding: '.5rem',
                gap: '.5rem'
            }}
        >
			{
				!isSelf
					? <FollowButton
						objectAlreadyFollowed={isFollowing}
						resource={user}
						objectIdentifier={heading}
					/>
					: <>
					<CreateButton
						canEdit={true}
						onClick={() => appDispatch(setModalType({type: 'userProfile'}))}
					/>
					<SettingsButton
						canEdit={true}
						onClick={() => appDispatch(setModalType({type: 'userProfile'}))}
					/>
					</>
			}
			</FlexContainer>
		</PopoverCardBody>
	);
};

export default UserPopover;
