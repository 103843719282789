import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch } from 'app/hooks';
import Heading from 'common/text/Heading';
import { pipe } from 'common/utils/functionUtils';
import { viewsAvailable } from 'features/HUD/state/HUDSlice';
import { useGetEntitiesQuery } from 'features/api';
import { deriveDomainViews } from 'features/compositeViews/helpers';
import DomainGraph from 'features/graph/components/DomainGraph';
import DomainProfile from 'features/profile/DomainProfile';
import DomainSpatial from 'features/spatial/components/DomainSpatial';
import useRenderView, {
	RenderViewConfig,
} from 'features/navigation/hooks/useRenderView';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import { StyledProfileGridLayout } from 'features/profile/common/styledComponents';
import React, { FunctionComponent, useEffect } from 'react';
import FlexContainer from "../../../common/FlexContainer";
import Helmet from "react-helmet";
import useActiveOrgData from "../../ontology/hooks/useActiveOrgData";
import {ViewMode} from "../types";
import DomainMeta from "../../../common/meta/DomainMeta";
import OrgMeta from "../../../common/meta/OrgMeta";

const configs: RenderViewConfig = {
	viewDefault: 'profile',
	views: [
		{
			match: 'profile',
			element: <DomainProfile/>,
		},
		{
			match: 'graph',
			element: <DomainGraph/>,
		},
		{
			match: 'spatial',
			element: <DomainSpatial/>,
		},
	],
};

const DomainViews: FunctionComponent = () => {

	const { activeItem: activeDomain } = useActiveDomainData();

	const { data: domainEntities, isSuccess } = useGetEntitiesQuery(
		activeDomain ? { domainId: activeDomain._id } : skipToken
	);

	const appDispatch = useAppDispatch();

	const render = useRenderView(configs);

	useEffect(() => {
		if (domainEntities && isSuccess) {
			const views = ['profile', 'graph', 'spatial'] as ViewMode[];
			// const views = deriveDomainViews(domainEntities);
			pipe(views, viewsAvailable, appDispatch);
		}

		return () => pipe([], viewsAvailable, appDispatch) as void;
	}, [domainEntities, isSuccess, appDispatch]);

	if (!!activeDomain) {
		return <>
			<DomainMeta />
			<FlexContainer
			alignItems='center'
			justifyContent='center'
			alignContent='center'>
			{render()}
		</FlexContainer>
		</>;
	} else {
		return null;
	}
};

export default DomainViews;
