import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useActiveIndividualData
    from 'features/ontology/hooks/useActiveIndividualData';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import ProfileHeader from "../common/ProfileHeader";
import styled from "styled-components";
import {BasePaper} from "../../../common/Paper";
import FactPanel from "../../../common/lists/FactPanel";
import {List, ListItem} from "../../../common/List";
import {
    AttributeBaseType,
    BaseAttribute, PrimaryIdentityAttribute
} from "../../ontology/types/attributeTypes";
import BreakoutText from "../../../common/text/BreakoutText";
import {filter, flow, map, pipe} from "../../../common/utils/functionUtils";
import {
    isIdentity,
    isPrimaryIdentity, isRelation
} from "../../ontology/typeGuards/attributeGuards";
import {
    displayableAttrSubtypes,
    getSelectedSubtypes,
    groupAttrs,
    sortGroupedAttrsDisplay
} from "../../ontology/helpers/attributeHelpers";
import IndividualHeading from "./ProfileHeader";
import {useGetAttributesQuery, useGetIndividualQuery} from "features/api";
import LocationSelect from "../../../common/inputs/LocationSelect";
import {BaseOrg} from "../../ontology/types/orgTypes";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import clsx from "clsx";
import Typography from "../../../common/text/Typography";
import theme from "../../../common/theme/theme";
import DomainPanel from "../../../common/lists/DomainPanel";
import CommentsTab from "../../../common/comments/components/CommentsTab";
import QuestionsTab from "../../../common/questions/components/QuestionsTab";
import GovernancePanel from "../../governance/components/GovernancePanel";
import ActivitiesTab from "../../../common/activities/components/ActivitiesTab";
import FlexContainer from "../../../common/FlexContainer";
import FollowButton from "../../../common/buttons/FollowButton";
import {
    StyledProfileGridLayout,
    OptimizedContainer,
    StyledProfileBase
} from "../common/styledComponents";
import IndividualMenu from "../../../common/menus/IndividualMenu";
import GridContainer from "../../../common/GridContainer";
import {OrgContent} from "../OrgProfile";
import {useParams} from "react-router-dom";
import useActiveEntityData from "../../ontology/hooks/useActiveEntityData";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import useActiveIndividualsMeta
    from "../../ontology/hooks/useActiveIndividualsMeta";
import {defaultConfig} from 'features/ontology/hooks/useIndividualsMeta';
import useActiveEntityAttributes
    from "../../ontology/hooks/useActiveEntityAttributes";
import {mergeQueryStates} from "../../api/helpers";
import dataPrepFactory from "../../dataPreparation/dataPrepFactory";
import AttributeMenu from "../../../common/menus/AttributeMenu";
import RouterLink from "../../../common/RouterLink";

// grid-template-columns: ${(p) => p.theme.drawerWidth}px 1fr;
export const StyledIndividualProfileLayout = styled(BasePaper)`
	display: grid;
	grid-template:
	[row1-start] "header categories events relations" auto [row1-end]
	[row2-start] "quantities categories events relations" auto [row2-end]
	[row2-start] "quantities categories location relations" auto [row2-end]
	[row2-start] "quantities categories location relations" auto [row2-end]
	[row2-start] "media media location location relations" auto [row2-end]
	/ 1fr 1fr 1fr 1fr 1fr;
	position: absolute;
    height: ${(p) =>
    `calc(100vh - ${(p.theme.navHeight + p.theme.panelGutter * 2)}px)`};
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter}px;
    left: ${(p) => p.theme.spacing(1)};
    column-gap: 8px;
	padding: 0;
    opacity: 1;
	width: calc(100vw - ${(p) => `${p.theme.sideControllerWidth + p.theme.panelGutter * 3}`}px);
	z-index: ${(p) => p.theme.zIndex.controlSurface};
`;

export const StyledProfileFlexLayout = styled(BasePaper)`
	display: flex;
	position: absolute;
    height: ${(p) =>
    `calc(100vh - ${(p.theme.navHeight * 2 + 8)}px - ${p.theme.panelGutter * 4}px)`};
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter * 2}px;
    left: ${(p) => p.theme.spacing(1)};
    
	padding: 0;
    opacity: 1;
	z-index: ${(p) => p.theme.zIndex.controlSurface};
`;
// padding: ${(p) => p.theme.spacing(1)};
// overflow: visible;
// flex-direction: column;
// display: ${(p) => !!p.props.drawerOpen ? 'flex' : 'none'};

export const StyledProfilePanelGrid = styled.div`
    padding: ${(p) => p.theme.spacing(1)};
	display: grid;
	grid-template-columns: ${(p) => p.theme.drawerWidth}px 1fr;
	
`;

export const ListItemContent = styled.div<{ truncate?: boolean }>`
	text-overflow: ${(p) => (p.truncate ? 'ellipsis' : 'default')};
	white-space: ${(p) => (p.truncate ? 'nowrap' : 'default')};
	overflow: ${(p) => (p.truncate ? 'hidden' : 'default')};
	flex-grow: 1;
	display: flex;
	justify-content: space-between;
`;


const StyledTabList = styled(TabList)`
	list-style-type: none;
	display: flex;
	width: 100%;	
	align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
    gap: .5rem;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
	    margin-top: 2rem;
		align-items: center;
        justify-content: flex-start;
	}
`;
// margin: ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(2)};
// border-bottom: 1px solid ${(p) => p.theme.palette.divider};

const StyledTab = styled(Tab)`
	padding: ${(p) => p.theme.spacing(1)};
	&:hover {
		cursor: pointer;
		background-color: ${(p) => p.theme.palette.cyan};
	}

	&.selected {
		border-bottom: 1px solid ${(p) => p.theme.palette.background.paper};
	}
`;

const StyledProfileItem = styled(GridContainer)`
	padding: ${(p) => p.theme.spacing(2)};
	margin: 0;
	width: 100%;
	align-items: center;
	justify-content: space-between;
`;

const StyledPlaceholder = styled(StyledProfileItem)`
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
`;

export const groupAttrsForIndividualCard = flow(
    filter((a) => !isPrimaryIdentity(a)),
    groupAttrs('base'),
    getSelectedSubtypes(displayableAttrSubtypes),
    sortGroupedAttrsDisplay
    // map(renderSection)
);

const StyledSectionContainer = styled.section`
	padding-left: ${(p) => p.theme.spacing(2)};
`;

interface AttrTypeSectionProps {
    subgroup: any;
    datum: Record<string, any>;
}

interface AttrTypeSection {
    type: AttributeBaseType;
    attrs: BaseAttribute[];
}

interface PopupBaseProps {
    datum: Record<string, any>;
    attributes: BaseAttribute[];
}

const getGridAreaFromType = (type: string) => {
    switch (type) {
        case 'identity':
            return 'identities';
        case 'relation':
            return 'relations';
        case 'category':
            return 'categories';
        case 'quantity':
            return 'quantities';
        case 'event':
            return 'events';
        case 'location':
            return 'locations';
        case 'media':
            return 'media';

        default:
            return 'categories'
    }
}

const formatAttrName = (s: string) => s.split('-').join(' ');

const StyledLink = styled(RouterLink)`
	&:hover {
		text-decoration: underline;
		color: ${(p) => p.theme.palette.primary.main};
	}
`;

//{valueAtPrimaryAttr: 'davisclark', individual: {…}, primaryAttrName: 'author', primaryAttrId: 9866, primaryEntityName: 'Author'}

export const RelationFactGroup: FunctionComponent<{ attribute: BaseAttribute, datum: any; }> = (
    {
        attribute,
        datum
    }
) => {

    const { data: attrs, ...attrLoadState } = useGetAttributesQuery(
		attribute.objectEntityId?._id
            ? { entityId: attribute.objectEntityId?._id }
            : skipToken
	);

	const preparedData = useMemo(() => {
		if (attrs) {
			// const filters = config.filter ? getAllFilters() : undefined;
			const preparer = dataPrepFactory(attrs, datum[attribute.name]);
			preparer.process();
			return preparer;
		}

		return null;
		// NB: this memoization is only helpful b/c useEntitySearchParams is also memoized
	}, [attrs, datum, attribute]);

    if (!!preparedData) {
            console.log(preparedData)

        const primaryId = preparedData.attributes.find(isPrimaryIdentity) as PrimaryIdentityAttribute;
            console.log(primaryId);
        return <>
            {/*<Typography variant={'h5'}>*/}
            {/*    {attribute.singular}*/}
            {/*</Typography>*/}
            <List key={attribute._id}>
            {preparedData.data.map((related: any, index: number) => {
                return <FlexContainer
                    style={{width: 'max-content'}}
                    flexDirection={'column'}
                    key={`${attribute.name}-${index}`}>
                    <div style={{
                        backgroundColor: theme.palette.darkerBaby,
                        padding: '1rem',
                        marginBottom: '1rem'
                    }}>
                        {Object.entries(related).map(([key, value]) => {

                            const attrName = preparedData?.attributes?.find((attribute) => attribute.name === key)?.singular || key;
                            const attrValue = value as string;
                            const ontology = attribute.entity.ontology;
                            const objectEntity = attribute.objectEntityId;
                            // const objectAttribute = attribute.inverseOf;
                            const linkTo = `/${ontology?.org}/${ontology?.domain}/${objectEntity?.name}/${related[primaryId.name]}`;

                            return <FlexContainer
                                key={key}
                                justifyContent={'space-between'}
                                alignItems={'center'}>
                                <ListItem
                                    style={{
                                        justifyContent: 'space-between',
                                        width: '300px',
                                        flexWrap: 'wrap',
                                        marginLeft: 0,
                                        marginRight: 0,
                                        ...{paddingLeft: 0},
                                    }}
                                >
                                    <StyledLink
                                        to={linkTo}
                                        preserveSearchParams={false}>
                                        <Typography
                                            variant={'h6'}
                                            color={'primary'}
                                            style={{
                                                margin: '8px 8px 4px 0',
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {formatAttrName(attrName)}
                                        </Typography>
                                    </StyledLink>
                                    {!!attribute && attribute.type === 'quantity'
                                        ? <Typography
                                            paragraph
                                            variant={'h6'}
                                            color={'oldManGray'}
                                            style={{
                                                padding: '8px 8px 4px 0',
                                                overflowWrap: 'break-word',
                                            }}
                                        >
                                            {attrValue}
                                        </Typography>
                                    : !!attribute && attribute.type === 'relation'
                                            ?
                                            <StyledLink
                                        to={linkTo}
                                        preserveSearchParams={false}>
                                            <Typography
                                                paragraph
                                                variant={'h6'}
                                                color={'oldManGray'}
                                                style={{
                                                    padding: '8px 8px 4px 0',
                                                    overflowWrap: 'break-word',
                                                }}
                                            >
                                                    {attrValue}
                                            </Typography>
                                            </StyledLink>
                                            : <Typography
                                                paragraph
                                                variant={'h6'}
                                                color={'oldManGray'}
                                                style={{
                                                    padding: '8px 8px 4px 0',
                                                    overflowWrap: 'break-word',
                                                }}
                                            >
                                                {attrValue}
                                            </Typography>}
                                </ListItem>
                                {!!attribute && <AttributeMenu
                                    attribute={attribute}
                                    canEdit={true}
                                />}
                            </FlexContainer>
                        })}
                    </div>
                </FlexContainer>
            })}
        </List>
            </>
    } else {
        return null;
    }
}

export const FunctionalRelationFactGroup: FunctionComponent<{ attribute: BaseAttribute, datum: any; }> = (
    {
        attribute,
        datum
    }
) => {

    const { data: attrs, ...attrLoadState } = useGetAttributesQuery(
		attribute.objectEntityId?._id ? { entityId: attribute.objectEntityId?._id } : skipToken
	);

	const preparedData = useMemo(() => {
		if (attrs) {
			// const filters = config.filter ? getAllFilters() : undefined;
			const preparer = dataPrepFactory(attrs, [datum[attribute.name]]);
			preparer.process();
			return preparer;
		}

		return null;
		// NB: this memoization is only helpful b/c useEntitySearchParams is also memoized
	}, [attrs, datum, attribute]);

	if (!!preparedData) {

	    console.log(preparedData);
	    const primaryId = preparedData.attributes.find(isPrimaryIdentity) as PrimaryIdentityAttribute;
	    console.log(primaryId);

	    const related = preparedData.data[0];

	    return <>
            {/*<Typography variant={'h5'}>*/}
            {/*    {attribute.singular}*/}
            {/*</Typography>*/}
        <List key={attribute._id}>
            <FlexContainer flexDirection={'column'}>
                {Object.entries(related).map(([key, value]) => {

                    const attrName = preparedData?.attributes?.find((attribute) => attribute.name === key)?.singular || key;
                    const attrValue = value as string;
                    const ontology = attribute.entity.ontology;
                    const objectEntity = attribute.objectEntityId;
                    // const objectAttribute = attribute.inverseOf;
                    const linkTo = `/${ontology?.org}/${ontology?.domain}/${objectEntity?.name}/${related[primaryId.name]}`;

                    // return <BreakoutText
                    //     attribute={attribute}
                    //     attrName={key}
                    //     attrValue={value as string}
                    //     key={key}
                    //     overrides={{root: {paddingLeft: 0}}}
                    // />
                    return <FlexContainer
                        key={key}
                        justifyContent={'space-between'}
                        alignItems={'center'}>
                        <ListItem
                            style={{
                                justifyContent: 'space-between',
                                width: '300px',
                                flexWrap: 'wrap',
                                marginLeft: 0,
                                marginRight: 0,
                                ...{paddingLeft: 0},
                            }}
                        >
                                    <StyledLink
                                        to={linkTo}
                                        preserveSearchParams={false}>
                                        <Typography
                                            variant={'h6'}
                                            color={'primary'}
                                            style={{
                                                margin: '8px 8px 4px 0',
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {formatAttrName(attrName)}
                                        </Typography>
                                    </StyledLink>
                                    {!!attribute && attribute.type === 'quantity'
                                        ? <Typography
                                            paragraph
                                            variant={'h6'}
                                            color={'oldManGray'}
                                            style={{
                                                padding: '8px 8px 4px 0',
                                                overflowWrap: 'break-word',
                                            }}
                                        >
                                            {attrValue}
                                        </Typography>
                                    : !!attribute && attribute.type === 'relation'
                                            ?
                                            <StyledLink
                                        to={linkTo}
                                        preserveSearchParams={false}>
                                            <Typography
                                                paragraph
                                                variant={'h6'}
                                                color={'oldManGray'}
                                                style={{
                                                    padding: '8px 8px 4px 0',
                                                    overflowWrap: 'break-word',
                                                }}
                                            >
                                                    {attrValue}
                                            </Typography>
                                            </StyledLink>
                                            : <Typography
                                                paragraph
                                                variant={'h6'}
                                                color={'oldManGray'}
                                                style={{
                                                    padding: '8px 8px 4px 0',
                                                    overflowWrap: 'break-word',
                                                }}
                                            >
                                                {attrValue}
                                            </Typography>}
                                </ListItem>
                                {!!attribute && <AttributeMenu
                                    attribute={attribute}
                                    canEdit={true}
                                />}
                            </FlexContainer>
                        })}
            </FlexContainer>
        </List>
        </>
    } else {
	    return null
    }
}

export const AttributeFactGroup: FunctionComponent<AttrTypeSectionProps> = (
    {
        subgroup,
        datum
    }
) => {

    return <StyledSectionContainer
        style={{gridArea: getGridAreaFromType(subgroup.type)}}>
        {/*<hr*/}
        {/*    style={{*/}
        {/*        margin: '1rem 0 .5rem',*/}
        {/*        borderWidth: 0,*/}
        {/*        backgroundImage: `linear-gradient(${theme.palette.divider}, ${theme.palette.divider})`,*/}
        {/*        color: theme.palette.divider,*/}
        {/*        height: '1px',*/}
        {/*    }}*/}
        {/*/>*/}
        <List>
            {subgroup.attrs.map((attribute: BaseAttribute) => {
                if (attribute.type === 'relation' && !attribute.isFunctional) {
                    return <RelationFactGroup
                        key={attribute._id}
                        attribute={attribute}
                        datum={datum}
                    />
                } else if (attribute.type === 'relation') {
                    console.log(datum)
                    return <FunctionalRelationFactGroup
                        key={attribute._id}
                        attribute={attribute}
                        datum={datum}
                    />
                } else {
                    return <BreakoutText
                        attribute={attribute}
                        attrName={attribute.name}
                        attrValue={String(datum[attribute.name])}
                        key={attribute._id}
                        overrides={{root: {paddingLeft: 0}}}
                    />
                }
            })}
        </List>
    </StyledSectionContainer>;
}

export const IndividualProfileControls: FunctionComponent<{ individual: any; }> = ({individual}) => {

    if (!!individual.individual.userContext) {
        const isFollowing = individual.individual.userContext.interactions.following;
        return <FlexContainer
            justifyContent={'center'}
            alignItems={'flex-end'}>
            <FollowButton
                objectAlreadyFollowed={isFollowing}
                resource={individual}
            />
        </FlexContainer>;
    } else {
        return <FlexContainer
            justifyContent={'center'}
            alignItems={'flex-end'}>
            <FollowButton
                objectAlreadyFollowed={false}
                resource={individual}
            />
        </FlexContainer>;
    }
};

export const IndividualHeader: FunctionComponent<{ individual: any; onRefChange: any; attributes: BaseAttribute[] | undefined; canEdit: boolean; }> = (
    {individual, onRefChange, attributes, canEdit}
) => {

    return <FlexContainer
        ref={onRefChange}
        style={{gridArea: 'head'}}
        justifyContent={'flex-end'}
        alignItems={'flex-start'}>
        <FlexContainer style={{
            display: 'flex',
            placeContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            // gap: '1rem'
        }}>
            <OptimizedContainer>
                {!!individual &&
                <IndividualHeading
                    datum={individual.individual}
                    attributes={!!attributes ? attributes : []}
                />}
                <IndividualProfileControls individual={individual}/>
            </OptimizedContainer>
        </FlexContainer>
        <IndividualMenu
            individual={individual}
            canEdit={canEdit}
        />
    </FlexContainer>;
};


export const IndividualContent: FunctionComponent<{ individual: any; attributes: BaseAttribute[] | undefined, canEdit: boolean; containerHeight: number; }> = (
    {individual, attributes, containerHeight, canEdit}
) => {

    const [selectedIdx, setSelectedIdx] = useState<number>(0);

    const subgroups = !!attributes && pipe(attributes, groupAttrsForIndividualCard);
    console.log(subgroups)
    // const secondaryIdentities = attributes.filter((a) => isIdentity(a) && !isPrimaryIdentity(a)) ?? null;
    const relations = !!attributes && attributes.filter((a) => isRelation(a) ?? null);

    const renderSection = (s: AttrTypeSection) => (
        <AttributeFactGroup
            key={s.type}
            subgroup={s}
            datum={individual.individual}
        />);

    return <Tabs
        style={{width: '100%', height: '100%', gridArea: 'main'}}
        selectedIndex={selectedIdx}
        onSelect={(idx) => setSelectedIdx(idx)}>
        <StyledTabList style={{overflowX: 'auto',}}>
            {!!relations && [...tabLabels, ...relations.map((relation) => relation.plural)].map((label, i) => (
                <StyledTab
                    className={clsx(i === selectedIdx && 'selected')}
                    key={label}>
                    <Typography variant='h6' color='oldManGray'>
                        {label}
                    </Typography>
                </StyledTab>
            ))}
        </StyledTabList>
        <div style={{
            maxHeight: `calc(100vh - ${containerHeight + theme.navHeight * 3}px)`,
            width: '100%',
            overflowY: 'auto'
        }}>
            {!!subgroups && !!relations ? [
                {
                    Panel: null,
                    args: {individual: individual.individual, canEdit: canEdit}
                },
                {
                    Panel: () => {
                        return renderSection(subgroups.find((f: any) => f.type === 'identity') || {
                            type: 'identity',
                            attrs: []
                        })
                    },
                    args: {individual: individual.individual, canEdit: canEdit}
                },
                {
                    Panel: () => {
                        return renderSection(subgroups.find((f: any) => f.type === 'quantity') || {
                            type: 'quantity',
                            attrs: []
                        })
                    },
                    args: {individual: individual.individual, canEdit: canEdit}
                },
                {
                    Panel: () => renderSection(subgroups.find((f) => f.type === 'category') || {
                        type: 'category',
                        attrs: []
                    }),
                    // Panel: DomainPanel,
                    args: {individual: individual.individual, canEdit: canEdit}
                },
                {
                    // Panel: CommentsTab,
                    Panel: () => renderSection(subgroups.find((f) => f.type === 'event') || {
                        type: 'event',
                        attrs: []
                    }),
                    args: {container: '100%'}
                },
                {
                    Panel: () => renderSection(subgroups.find((f) => f.type === 'location') || {
                        type: 'location',
                        attrs: []
                    }),
                    args: {}
                },
                {
                    Panel: () => renderSection(subgroups.find((f) => f.type === 'media') || {
                        type: 'media',
                        attrs: []
                    }),
                    args: {}
                },
                ...relations.map((relation) => {
                    return {
                        Panel: () => renderSection(
                            {
                                type: relation.plural as AttributeBaseType,
                                attrs: [relation]
                            }
                        ),
                        args: {}
                    }
                }),
            ].map((Panel, i) => {
                const Rendering = Panel.Panel === null
                    ? pipe(subgroups.filter((group) => group.type !== 'relation'), map(renderSection))
                    : Panel.Panel();
                return i === selectedIdx
                    ? <TabPanel key={i}>
                        {Rendering}
                    </TabPanel>
                    : <TabPanel key={i}/>;
            }) : null}
        </div>
    </Tabs>;

}


const tabLabels = [
    // 'Insights',
    'About',
    'Identities',
    'Quantities',
    'Categories',
    'Events',
    'Locations',
    'Documents',
];

const IndividualProfile: FunctionComponent = () => {

    const [containerHeight, setContainerHeight] = useState<number>(300);

    const params = useParams();

    const {
        activeItem: activeEntity,
        ...entityLoadState
    } = useActiveEntityData();

    const {
        activeItem: activeIndividual,
        activeAttributes: attributes,
        ...individualLoadState
    } = useActiveIndividualData(params.individual);

    const onRefChange = useCallback((node: any) => {
        if (node) {
            const bounding = node.getBoundingClientRect();
            console.log(bounding);
            setContainerHeight(bounding.height);
        }
    }, []);

        console.log(activeIndividual)
        return <StyledProfileBase>
            <StyledProfileGridLayout>
                <DisplayOnLoad {...individualLoadState}>
                    <IndividualHeader
                        attributes={attributes}
                        onRefChange={onRefChange}
                        individual={activeIndividual}
                        canEdit={true}
                    />
                    <IndividualContent
                        canEdit={true}
                        individual={activeIndividual}
                        attributes={attributes}
                        containerHeight={containerHeight}
                    />
                </DisplayOnLoad>
            </StyledProfileGridLayout>
        </StyledProfileBase>;
};

export default IndividualProfile;
