import React, {FunctionComponent, ReactNode, useState} from "react";
import {List} from "../List";
import IconButton from "../buttons/IconButton";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import Popover, {Placement} from "../popovers/Popover/PopoverBase";
import ClickAwayListener from "react-click-away-listener";


interface ActionListProps {
    // _id: number,
    // _object: string,
    children?: ReactNode,
	style?: any;
	placement?: Placement;
    // isOpen: boolean
}


const MenuButton: FunctionComponent<ActionListProps> = ({placement='right', children, style}) => {

	const [menuOpen, setMenuOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const closeMenu = () => setMenuOpen(false);

	return <>
			<IconButton
				overrides={{root: style}}
				ref={setAnchorEl}
				icon={faEllipsisV}
				baseOpacity='40%'
				onClick={() => setMenuOpen((p) => !p)}
				// aria-label="create attribute"
				// key="creation-form"
				size="lg"
				iconSize="lg"
                fillColor={'oldManGray'}

				// tooltip={`create attribute`}
				// showTooltip={!menuOpen}
				// tooltipPlacement="bottom"
			/>
			<Popover
				anchorEl={anchorEl}
				open={menuOpen}
				placement={placement}
				handleClickAway={closeMenu}
			>
				<ClickAwayListener onClickAway={closeMenu}>
                    <List style={{
                    	display: 'flex',
						// textAlign: 'left',
						justifyContent: 'flex-start',
						// alignItems: 'flex-start',
						flexDirection: 'column'}}>
                        {children}
                    </List>
                </ClickAwayListener>
			</Popover>
		</>;
};

export default MenuButton;
