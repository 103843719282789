import React, {FunctionComponent, useEffect} from "react";
import {StyledMemberDataListItem} from "../../features/governance/components/styledComponents";
import ProfileAvatar
    from "../../features/navigation/components/Navbar/ProfileAvatar";
import {ListItemContent, ListItemControls} from "./index";
import theme from "../theme/theme";
import FullName from "../text/FullName";
import LastActive from "../text/LastActive";
import Error from "../text/Error";
import ResourceLabel from "../text/ResourceLabel";
import Typography from "../text/Typography";
import FlexContainer from "../FlexContainer";
import FollowButton from "../buttons/FollowButton";
import {parseQueryError} from "../../features/api/helpers";
import {
    useFollowUserMutation,
    useUnfollowUserMutation
} from "../../features/api";
import useCurrentUser from "../../features/authentication/hooks/useUser";
import TextButton from "../buttons/TextButton";

export const ResourceItem: FunctionComponent<{resource: any, isWaiting?: boolean; canEdit: boolean, onHold?: boolean, onWithdraw?: any, onResend?: any, onGrant?: any, onClick: any, onExit?: (onHold: boolean) => void, handleError?: any, position?: string, viewerControls?: any, controls?: any, children?: any}> = ({resource, isWaiting, onExit, onHold, canEdit, onResend, onGrant, onWithdraw, onClick, handleError, position, controls, viewerControls, children}) => {

    const resourceType = resource._object;

    const currentUser = useCurrentUser();
    const [followUser, followResult] = useFollowUserMutation();
	const [unfollowUser, unfollowResult] = useUnfollowUserMutation();

	 if (resourceType === 'Error') {
	     return null;
     }
	 console.log(resource)

    if (resourceType === 'User') {
        const isSelf = currentUser.username === resource.username;
        const hasAccepted = resource.emailConfirmed;
        return <StyledMemberDataListItem
            // onClick={onClick}
            background="default"
            position={position}
            key={resource._id}
            aria-labelledby={`member-${resource._id}`}
        >
            <ProfileAvatar
                onHover={onClick}
                onExit={onExit}
                onHold={onHold}
                onClick={() => {}}
                initial={!!resource.firstName || !!resource.lastName ? !!resource.firstName ? resource.firstName.slice(0) : ''
                    + !!resource.lastName !== null ? resource.lastName.slice(0) : ''
                    : !!resource.username ? resource.username.slice(0) : resource.email.slice(0)}
                profileImageURL={resource.avatar?.thumb.url as string}
                isOnline={resource.lastActive === 'online'}
            />
            <ListItemContent style={{
                alignItems: 'center',
                justifyContent: 'center',
                margin: '2px 0 2px 2px',
                paddingLeft: '8px'
            }}>
                 <FullName
                     onHold={onHold}
                     onHover={onClick}
                     onExit={onExit}
                     user={resource}
                     styles={{ cursor: 'pointer', padding: '1px' }}
                 />
                 <LastActive user={resource} position='flex-start' />
                 {/*<Username user={user} />*/}
                 {/*<Error resource={resource} handleError={handleError} />*/}
            </ListItemContent>
            <ListItemControls justifyContent={'flex-end'}>
                {viewerControls}
                {
                    !isSelf && hasAccepted ? <FollowButton
                        objectAlreadyFollowed={resource.userContext.interactions.following}
                        resource={resource}
                    /> : isWaiting
                        ? <FlexContainer>
                            {onGrant ? <TextButton
                                variant='transparent'
                                fillColor='transparent'
                                color='primary'
                                text={'Grant Access'}
                                onClick={onGrant}
                            /> : null}
                        </FlexContainer>
                        : <FlexContainer>
                        {onWithdraw ? <TextButton
                            variant='transparent'
                            fillColor='transparent'
                            color='primary'
                            text={'Withdraw'}
                            onClick={onWithdraw}
                        /> : null}
                        {onResend ? <TextButton
                            variant='transparent'
                            fillColor='transparent'
                            color='primary'
                            text={'Resend'}
                            onClick={onResend}
                        /> : null}
                    </FlexContainer>
                }
            </ListItemControls>
        </StyledMemberDataListItem>
    } else {
        return <StyledMemberDataListItem
            // onClick={onClick}
            background="default"
            position={position}
            key={resource._id}
            aria-labelledby={`member-${resource._id}`}
        >
            {resource.author && <ProfileAvatar
                onClick={() => {}}
                onHover={onClick}
                onExit={onExit}
                onHold={onHold}
                initial={resource.author.firstName !== undefined && resource.author.lastName !== undefined
                    ? resource.author.firstName[0] + resource.author.lastName[0]
                    : resource.author.username[0]}
                profileImageURL={resource.author.avatar?.thumb.url as string}
                isOnline={resource.author.lastActive === 'online'}
            />}
            <ListItemContent style={{
                alignItems: 'center',
                justifyContent: 'center',
                margin: '2px 0 2px 2px',
                paddingLeft: '8px'
            }}>
                <ResourceLabel
                    onHover={onClick}
                    onExit={onExit}
                    onHold={onHold}
                    resource={resource} styles={{ padding: '1px' }}/>
                {/*<Error resource={resource} handleError={handleError} />*/}
            </ListItemContent>
            <ListItemControls justifyContent={'flex-end'}>
                <FollowButton
                    objectAlreadyFollowed={resource.userContext.interactions.following}
                    resource={resource}
                />
                {viewerControls}
            </ListItemControls>
            {canEdit && (
                <ListItemControls>
                    {controls}
                </ListItemControls>
            )}
        </StyledMemberDataListItem>
    }
}

export default ResourceItem;
