import FlexContainer from '../FlexContainer';
import IconButton from '../buttons/IconButton';
import {
    PopoverDrawer,
    PopoverSubhead,
    PopoverCardBody,
    PopoverHead,
    PopoverMain,
    PopoverHeading,
    DropdownIcon,
    PopoverControlsGrid,
} from './Popover';
import SubtleTextArea from '../inputs/SubtleTextArea';
import {SubtleTextbox} from '../inputs/SubtleTextBox';
import {warnInDev} from '../utils/reactUtils';
import {useDescribeDomainMutation} from '../../features/api';
import DeleteDomainButton from '../buttons/DeleteDomainButton';
import {faCheck, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {isNonEmptyString} from 'common/utils/typeGuards';
import {canEdit} from 'features/authentication/helpers';
import {
    DEFINITION_PLACEHOLDER,
    nullifyDefinitionPlaceholder,
} from 'features/graph/helpers';
import useActiveOrgData from 'features/ontology/hooks/useActiveOrgData';
import {BaseDomain, DomainModalTypes} from 'features/ontology/types/domainTypes';
import React, {
    FormEventHandler,
    FunctionComponent,
    MouseEventHandler,
    useEffect,
    useState,
} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import theme from "../theme/theme";
import RouterLink from "../RouterLink";
import MenuButton from "../buttons/MenuButton";
import PaperButton from "../buttons/PaperButton";
import Typography from "../text/Typography";
import {UserContext} from '../../features/authentication/types/userTypes';
import {FollowButton} from "../buttons";
import QuestionButton from "../buttons/QuestionButton";
import CommentButton from "../buttons/CommentButton";
import {
    useFollowResourceMutation,
    useUnfollowResourceMutation
} from "../../features/api";
import {mergeQueryStates} from "../../features/api/helpers";
import {List, ListItem} from 'common/List';
import {ListItemContent} from 'common/List';
import {TinySquare} from '../viz/ForceGraph/styledComponents';
import Spinner from 'common/loading/Spinner';
import {useGetEntitiesQuery} from 'features/api';
import {parseQueryError} from 'features/api/helpers';
import {
    BaseEntity,
    EntityModalTypes
} from 'features/ontology/types/entityTypes';
import styled from 'styled-components';
import clsx from "clsx";
import DomainMenu from "../menus/DomainMenu";
import ExpandingListItem from "../List/ExpandingListItem";
import {
    CREATE_ACTIVITY,
    CREATE_ATTRIBUTE,
    CREATE_CONDITION,
    CREATE_EVENT,
    CREATE_LOCATION, CREATE_RESTRICTION,
    CREATE_SPECIALIZATION,
    DELETE_DOMAIN,
    DESCRIBE_DOMAIN,
    DESCRIBE_ENTITY
} from "../../features/graph/CONSTANTS";
import UpdateDomainForm from "../modals/DomainCreationForm";
import DeleteDomainForm
    from "../../features/actions/deleteDomain/DeleteDomainForm";
import UpdateEntityForm
    from "../../features/actions/updateEntity/UpdateEntityForm";
import CreateAttributeForm
    from "../../features/actions/createAttribute/CreateAttributeForm";
import CreateEventForm
    from "../../features/actions/createEvent/CreateEventForm";
import CreateActivityForm
    from "../../features/actions/createActivity/CreateActivityForm";
import CreateLocationModal
    from "../../features/actions/createLocation/CreateLocationModal";
import CreateConditionalForm
    from "../../features/actions/createConditional/CreateConditionalForm";
import RestrictEntityForm
    from "../../features/actions/restrictEntity/RestrictEntityForm";
import {useAppDispatch} from "../../app/hooks";
import useModalType from "../../features/HUD/hooks/useModalType";
import {setModalType} from "../../features/HUD/state/HUDSlice";

interface DomainDropdownProps {
    domainId: number;
    domainLabel: string;
    actionSubmenu: any;
    setActionSubmenu: any;
    canEdit: boolean;
}

export const ListItemAvatar = styled.div`
	flex: 0 0 5%;
`;

const DomainPopoverDropdown: FunctionComponent<DomainDropdownProps> = (
    {domainId, canEdit,}
    ) => {
    const {
        isLoading,
        isError,
        error,
        data: entities,
    } = useGetEntitiesQuery({domainId});

    const [actionSubmenu, setActionSubmenu] = useState<number | null>(null);

    if (isLoading) {
        return (
            <FlexContainer justifyContent="center">
                <Spinner/>
            </FlexContainer>
        );
    }

    if (isError && error) {
        return (
            <Typography variant='h5' color="error">
                {parseQueryError(error).message}
            </Typography>
        );
    }

    // cast type of 'entities' since compiler doesn't know about mutually-exclusive
    // query states.
    return (
        <div style={{
            overflow: 'auto',
            maxHeight: '300px',
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`
        }}>
            <List style={{
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center'
            }}>
                {(entities as BaseEntity[]).map((entity) => (
                    <ExpandingListItem
                        height='48px'
                        key={entity._id}
                        canEdit={canEdit}
                        resource={entity}
                        // resourceWithActiveSubmenu={actionSubmenu}
                        // setOpen={setActionSubmenu}
                    />
                    // <ListItem  >
                    //     <ListItemAvatar>
                    //         <TinySquare/>
                    //     </ListItemAvatar>
                    //     <ListItemContent>
                    //         <Typography variant="h5">
                    //             {e.plural}
                    //         </Typography>
                    //     </ListItemContent>
                    // </ListItem>
                ))}
            </List>
        </div>
    );
};

export interface DomainPopoverBodyProps {
    domain: BaseDomain;
}

interface DescribeFormValues {
    definition: string;
    label: string;
}

export const DomainPopover: FunctionComponent<DomainPopoverBodyProps> = (props) => {

    const {domain} = props;
    const {_id, name, label, userContext, definition} = domain;

    const {register, handleSubmit, formState, resetField, reset} = useForm<{
        label: string;
        definition: string;
    }>({
        defaultValues: {
            label,
            definition: definition ?? DEFINITION_PLACEHOLDER,
        },
    });

    const {isDirty: formIsDirty, dirtyFields} = formState;

    const {label: labelIsDirty, definition: definitionIsDirty} = dirtyFields;

    const [describe, describeResult] = useDescribeDomainMutation();

    useEffect(() => {
        if (describeResult.error) {
            warnInDev(JSON.stringify(describeResult.error), 'error');
        }
    }, [describeResult.error]);

    const onSubmit: SubmitHandler<DescribeFormValues> = (vals) => {
        describe({
            domainId: _id,
            body: {
                ...vals,
                definition: nullifyDefinitionPlaceholder(vals.definition),
            },
        });
    };

    const conditionalSubmit: FormEventHandler = (e) => {
        e.preventDefault();

        if (formIsDirty) {
            handleSubmit(onSubmit)();
        }

        return null;
    };

    const [menuOpen, setMenuOpen] = useState(false);

    // Handle open popover menu state here in parent to prevent multiple
    // submenus from being open at once
    const [actionSubmenu, setActionSubmenu] = useState<string | null>(null);

    const {activeItem: activeOrg} = useActiveOrgData();

    const canEditOrg = activeOrg ? canEdit(activeOrg) : false;

    const canEditDomain = canEdit({userContext});

    useEffect(() => {
        if (domain) {
            const {definition, label} = domain;
            reset(
                {label, definition: definition ?? DEFINITION_PLACEHOLDER},
                {keepDefaultValues: false}
            );
        }
    }, [reset, domain]);

    const toggleDropdown: MouseEventHandler = () => {
        setMenuOpen((p) => !p);
    };

    const pathToProfile = `../${name}`

    return (
        <PopoverCardBody>
                {/*borderBottom: menuOpen ? `1px solid ${theme.palette.divider}` : 'none'*/}
            <FlexContainer justifyContent='space-between' style={{
                width: '100%',
            }}>
                <PopoverHead imgSrc=' '>
                    <PopoverSubhead variant='overline'>
                        <RouterLink
                            to={domain && domain.ontology ? `/${domain.ontology.org}` : '/'}>
                            {canEditOrg && domain.ontology ? domain.ontology.org : ''}
                        </RouterLink>
                    </PopoverSubhead>
                    <PopoverHeading variant='h2'>
                        <RouterLink
                            to={domain && domain.ontology ? `/${domain.ontology.org}/${domain.ontology.domain}` : '/'}>
                            {domain.label}
                        </RouterLink>
                    </PopoverHeading>
                    <PopoverMain>
						<FlexContainer justifyContent='space-between' alignItems='center'>
                            {/*<form style={{*/}
                            {/*    display: 'flex',*/}
                            {/*    flexDirection: 'column',*/}
                            {/*    width: '25%',*/}
                            {/*    padding: 0,*/}
                            {/*    margin: 0,*/}
                            {/*    minWidth: '300px',*/}
                            {/*    maxWidth: `calc(${theme.spacing(4)} + 250px)`*/}
                            {/*}} onSubmit={conditionalSubmit}>*/}
                                {/*<SubtleTextbox*/}
                                {/*    {...register('label', {*/}
                                {/*        required: "A value for 'label' is required",*/}
                                {/*    })}*/}
                                {/*    isDirty={Boolean(labelIsDirty)}*/}
                                {/*    label="label"*/}
                                {/*    key={`domain-label-${domain._id}`}*/}
                                {/*    onReset={() => resetField('label')}*/}
                                {/*    overrides={{*/}
                                {/*        root: {*/}
                                {/*            fontSize: '1.2rem',*/}
                                {/*            letterSpacing: '0.1em',*/}
                                {/*            fontWeight: '600',*/}
                                {/*            display: 'block',*/}
                                {/*            margin: 0,*/}
                                {/*            textOverflow: 'ellipsis',*/}
                                {/*            whiteSpace: 'nowrap',*/}
                                {/*            overflow: 'hidden',*/}
								{/*			width: '100%',*/}
								{/*			maxWidth: '250px'*/}
                                {/*        },*/}
                                {/*    }}*/}
                                {/*    disabled={!canEditDomain}*/}
                                {/*/>*/}
                                {/*<PopoverSubhead>*/}
                                {/*    {(canEditDomain || isNonEmptyString(definition)) && (*/}
                                {/*        canEditDomain*/}
                                {/*            ? (*/}
                                {/*                <SubtleTextArea*/}
                                {/*                    {...register('definition')}*/}
                                {/*                    isDirty={Boolean(definitionIsDirty)}*/}
                                {/*                    label="definition"*/}
                                {/*                    onReset={() => resetField('definition')}*/}
                                {/*                    rows={2}*/}
                                {/*                    overrides={{*/}
                                {/*                        root: {*/}
                                {/*                            resize: 'vertical',*/}
                                {/*                            width: '100%',*/}
                				{/*							maxWidth: '250px'*/}
                                {/*                        },*/}
                                {/*                    }}*/}
                                {/*                    disabled={!canEditDomain}*/}
                                {/*                />*/}
                                {/*            ) : <p>{definition}</p>)}*/}
                                {/*</PopoverSubhead>*/}
                            {/*</form>*/}
                            {/*{canEditOrg && (*/}
                            {/*    <IconButton*/}
                            {/*        icon={faCheck}*/}
                            {/*        fillColor="primary"*/}
                            {/*        size="xs"*/}
                            {/*        iconSize={'lg'}*/}
                            {/*        variant={'transparent'}*/}
                            {/*        baseOpacity="80%"*/}
                            {/*        tooltip={`update ${domain.label}`}*/}
                            {/*        tooltipPlacement="bottom"*/}
                            {/*        disabled={!formState.isDirty}*/}
                            {/*        showTooltip={formState.isDirty}*/}
                            {/*        onClick={(e) => conditionalSubmit(e)}*/}
                            {/*        aria-label="submit changes"*/}
                            {/*    />)}*/}
                        </FlexContainer>
                    </PopoverMain>
                </PopoverHead>
                <DomainMenu
                    domain={domain}
                    canEdit={canEditDomain}
                />
            </FlexContainer>
            <PopoverDrawer open={menuOpen}>
                <DomainPopoverDropdown
                    domainId={_id}
                    canEdit={canEditDomain}
                    domainLabel={label}
                    actionSubmenu={actionSubmenu}
                    setActionSubmenu={setActionSubmenu}
                />
            </PopoverDrawer>
            {/*<DomainPopoverControls*/}
            {/*    userContext={userContext}*/}
            {/*    domainLabel={label}*/}
            {/*    domainId={_id}*/}
            {/*    toggleDropdown={toggleDropdown}*/}
            {/*    menuOpen={menuOpen}*/}
            {/*    pathToProfile={pathToProfile}*/}
            {/*>*/}
            {/*   */}
            <FlexContainer
                justifyContent="space-around"
                style={{
                    padding: '.5rem',
                    gap: '.5rem'
                }}
            >
                <FollowButton
                    resource={domain}
                    objectAlreadyFollowed={domain && domain.userContext
                        ? domain.userContext.interactions.following
                        : false}
                />
                <QuestionButton
                    objectId={domain._id}
                    objectType="Domain"
                    canEdit={canEditDomain}
                />
                <CommentButton
                    objectId={domain._id}
                    objectType="Domain"
                    canEdit={canEditDomain}
                />
                <PaperButton width='38px' height='32px' onClick={toggleDropdown} tooltip="toggle drawer">
                    <DropdownIcon
                        className={clsx(menuOpen && 'popover-menu-open')}
                        icon={faChevronDown}
                    />
                </PaperButton>
            </FlexContainer>
            {/*<FlexContainer*/}
            {/*    justifyContent="space-around"*/}
            {/*    style={{*/}
            {/*        padding: '.5rem',*/}
            {/*        gap: '.5rem'*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <RouterLink to={pathToProfile}>*/}
            {/*        <PaperButton style={{width: '300px'}}>*/}
            {/*            <Typography variant='button'>*/}
            {/*                Explore Domain*/}
            {/*            </Typography>*/}
            {/*        </PaperButton>*/}
            {/*    </RouterLink>*/}
            {/*</FlexContainer>*/}
            {/*<PaperButton onClick={toggleDropdown}>*/}
            {/*	<DropdownIcon*/}
            {/*		className={clsx(menuOpen && 'popover-menu-open')}*/}
            {/*		icon={faEllipsisH}*/}
            {/*	/>*/}
            {/*</PaperButton>*/}
        </PopoverCardBody>
    );
};

export default DomainPopover;
