import styled from 'styled-components';
import {BasePaper} from "../../../common/Paper";
import FlexContainer from "../../../common/FlexContainer";

	// grid-template-columns: 250px auto;
	// grid-template-rows: 1fr;
	// / 1fr 6fr 1fr;
export const StyledProfileBase = styled(BasePaper)`
	height: 100vh;
	width: 100vw;
	opacity: 1;
`

export const StyledProfileGridLayout = styled.div`
	display: grid;
	grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas: 
		"head head head head head"
		"main main main main main";
	border: 1px solid ${(p) => p.theme.palette.divider};
	position: absolute;
    height: ${(p) => `calc(100vh - ${p.theme.navHeight + p.theme.panelGutter * 2}px)`};
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter}px;
    left: ${(p) => p.theme.spacing(1)};
	padding: 1rem;
	margin: 0;
	justify-content: stretch;
	justify-items: stretch;
	align-items: stretch;
	align-content: stretch;
	width: calc(100vw - ${(p) => `${p.theme.sideControllerWidth + p.theme.panelGutter * 3}`}px);
	z-index: ${(p) => p.theme.zIndex.controlSurface};
	
		/* Very crude responsiveness */
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.xl}px) {
		grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
		grid-template-rows: auto 1fr;
		grid-template-areas: 
		"head head head head head"
		"main main main main main";
		justify-content: stretch;
        justify-items: stretch;
        align-items: stretch;
        align-content: stretch;
	}
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
		grid-template-rows: auto 1fr;
		grid-template-areas: 
		"head head head head head"
		"main main main main main";
		justify-content: stretch;
        justify-items: stretch;
        align-items: stretch;
        align-content: stretch;
	}

	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		width: 100%;
	}
`;

export const StyledProfileBodyLayout = styled.div`
	display: grid;
	grid-template: 
	[row1-start] "info main panel" auto [row1-end] 
	[row2-start] "info main panel" auto [row2-end] 
	[row3-start] "info main panel" auto [row3-end] 
	[row4-start] "info main panel" auto [row4-end] 
	/ 1fr 6fr 1fr;
	justify-items: stretch;
	justify-content: stretch;
	align-content: start;
	align-items: start;
	border: 1px solid ${(p) => p.theme.palette.divider};
	margin-top: 1rem;
	margin-left: 1rem;
	gap: 1rem;
	grid-area: header;
`;



export const StyledProfileHeaderLayout = styled(BasePaper)`
	display: grid;
	grid-template-columns: ${(p) => p.theme.drawerWidth}px 1fr;
	position: absolute;
    height: ${(p) => `calc(100vh - ${p.theme.navHeight + p.theme.panelGutter * 2}px)`};
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter}px;
    left: ${(p) => p.theme.spacing(1)};
    border: 1px solid ${(p) => p.theme.palette.divider};
    column-gap: 8px;
	padding: 0;
    opacity: 1;
	width: calc(100vw - ${(p) => `${p.theme.sideControllerWidth + p.theme.panelGutter * 3}`}px);
	z-index: ${(p) => p.theme.zIndex.controlSurface};
`;



export const StyledProfileFlexLayout = styled(BasePaper)`
	display: flex;
	position: absolute;
    height: ${(p) => `calc(100vh - ${(p.theme.navHeight * 2 + 8)}px - ${p.theme.panelGutter * 4}px)`};
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter * 2}px;
    left: ${(p) => p.theme.spacing(1)};
    
	padding: 0;
    opacity: 1;
	z-index: ${(p) => p.theme.zIndex.controlSurface};
`;
    // padding: ${(p) => p.theme.spacing(1)};
	// overflow: visible;
	// flex-direction: column;
	// display: ${(p) => !!p.props.drawerOpen ? 'flex' : 'none'};

export const StyledProfilePanelGrid = styled.div`
    padding: ${(p) => p.theme.spacing(1)};
	display: grid;
	grid-template-columns: ${(p) => p.theme.drawerWidth}px 1fr;
	border: 1px solid ${(p) => p.theme.palette.divider};
`;

export const OptimizedContainer = styled(FlexContainer)`

    padding: 0;
    margin: 0;
    justify-content: space-between;
    justify-items: space-between;
    align-items: baseline;
    align-content: baseline;
    width: 100%;
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
    	flex-direction: column;
		justify-items: center;
		justify-content: center;
		align-content: center;
		align-items: center;
		text-align: center;
	}
`


