import { ListItem } from '../../List';
import {BasePaper} from '../../Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Typography from "../../text/Typography";
import FlexContainer from "../../FlexContainer";

/** Contains all contents of popover, including slide-down */
export const PopoverCardBody = styled(BasePaper)`
	flex-direction: column;
	max-width: 450px;
	width: 100%;
	padding: ${(p) => p.theme.spacing(2)};
	${(p) => p.theme.translucent}
`;

export const PopoverContentColumns = styled.div`
	display: grid;
	/* MAGIC NUMBERS: px should add up to 400 - 16 to leave room for padding */
	grid-template-columns: 50px 368px;
	min-width: 400px;
	min-height: 150px;
	align-items: start;
	justify-items: start;
`;

export const PopoverImage = styled.div<{ src?: string | null }>`
	width: 85%;
	height: 0;
	padding-bottom: 85%;
	border-radius: 50%;
	background-color: #60b3a1;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: ${(p) => (p.src ? `url(${p.src})` : 'none')};
`;

export const PopoverHeader = styled(FlexContainer)`
	padding-left: ${(p) => p.theme.spacing(1)};
	flex-direction: column;
`;

export const PopoverSubhead = styled(Typography)`
	color: ${(p) => p.theme.palette.oldManGray};
`;

export const PopoverContentListItem = styled(ListItem)`
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
	text-transform: capitalize;
`;

export const DropdownIcon = styled(FontAwesomeIcon)`
	transition: transform 100ms;
	transform: rotate(0deg);

	&.popover-menu-open {
		transform: rotate(180deg);
	}
`;

export const PopoverControlsGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding: ${(p) => p.theme.spacing(.5)};
	justify-content: center;
	justify-items: center;
	align-content: center;
	align-items: center;
`;
	// grid-gap: ${(p) => p.theme.spacing(0.5)};

export const PopoverHeading = styled(Typography)`
	max-width: 250px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	color: ${(p) => p.theme.palette.oldManGray};
`;
