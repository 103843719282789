import React, {FunctionComponent} from "react";
import AttributeSelect from "common/inputs/SelectAttribute";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {harvestErrors} from "./SubtleTextBox";

interface StartEventSelect extends ActionFieldProps {
    attrData: any;
}

const StartEventSelect: FunctionComponent<StartEventSelect> = (
    {
        attrData,
        mutationResults,
        formState,
        resetField,
        validatingRegister
    }
    ) => {
    return <AttributeSelect selectId='startId' props={{
        validatingRegister,
        formState,
        resetField,
        mutationResults,
        attrData,
    }} />
}

export default StartEventSelect;
