import React, {FunctionComponent} from "react";
import {BaseComment} from "../comments/types/commentTypes";
import Button from "./Button";
import Typography from "../text/Typography";
import {setModalType} from "../../features/HUD/state/HUDSlice";
import {useAppDispatch} from "../../app/hooks";

const ReactionsButton: FunctionComponent<{resource: any}> = ({resource}) => {
    const appDispatch = useAppDispatch();
    if (resource && resource.reactions && resource.reactions?.likesSummary.totalLikes > 0) {
        return <Button
            style={{padding: 0, margin: 0, textAlign: 'left'}}
            onClick={() => appDispatch(setModalType(
                {
                    type: 'reactions',
                    props: {
                        objectType: resource._object,
                        objectId: resource._id,
                        object: resource
                }}))}>
            <Typography variant='button'>
                {resource.reactions?.likesSummary.totalLikes} Likes
            </Typography>
        </Button>
    } else {
        return null;
    }
};

export default ReactionsButton;
