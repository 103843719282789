import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";
import IconButton from "../buttons/IconButton";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";
import FlexContainer from "../FlexContainer";

const InstagramInput: FunctionComponent<ActionFieldProps> = (
    {
        defaultValue,
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <FlexContainer alignItems={'center'}>
        <IconButton
            icon={faInstagram as any}
            tooltip=""
            size="xs"
            iconSize='lg'
            style={{marginLeft: '4px'}}
            onClick={() => {
            }}
            showTooltip={true}
        />
        <SubtleTextbox
            {...validatingRegister('instagram', {})}
            label="Instagram"
            defaultValue={defaultValue}
            isDirty={Boolean(formState.dirtyFields.instagram)}
            onReset={() => resetField('instagram')}
            error={harvestErrors(
                'instagram',
                formState,
                mutationResults
            )}
        />
    </FlexContainer>;
}

export default InstagramInput;
