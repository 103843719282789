import Button from '../../../common/buttons/Button';
import FlexContainer from '../../../common/FlexContainer';
import { FormResults } from '../../../common/Form';
import Heading from '../../../common/text/Heading';
import Typography from '../../../common/text/Typography';
import { useDeleteEntityMutation } from '../../api';
import { ForceGraphContext } from '../../graph/state/GraphContextProvider';
import { SET_POPUP_ELEMENT } from '../../graph/state/actions';
import {
	StyledFlexContainer,
	StyledPaper,
	StyledDeleteButton,
} from '../common/styledComponents';
import { EntityActionFormProps } from '../common/commonTypes';
import useEntityIsImported from 'features/graph/hooks/useEntityIsImport';
import {
	FunctionComponent,
	useState,
	MouseEventHandler,
	useContext,
	useLayoutEffect,
} from 'react';
import useModalType from "../../HUD/hooks/useModalType";
import AppModal from "../../../common/modals/AppModal";
import {useParams} from "react-router-dom";

const DeleteEntityForm: FunctionComponent = () => {

	const {modalProps, closeModal} = useModalType();
	const {objectId: _id, resource, canEdit} = modalProps;

	const [unknownStateError, setUnknownStateError] = useState<string | null>(
		null
	);

	// const [_, graphDispatch] = useContext(ForceGraphContext);

	const [deleteEntity, mutationResults] = useDeleteEntityMutation();

	const importStatus = {
		isImport: resource.isImport,
		importingDomain: resource.importingDomain
	};
	// const importStatus = useEntityIsImported(_id);

	// if we can't determine whether or not entity is imported for some reason,
	// the system has reached an illogical state, and we should NOT allow user to take
	// a destructive action.
	const canDelete = resource.isImport !== undefined && resource.isImport !== null;
	// const canDelete = !(importStatus === 'unknown');

	//  run synchronously so we can use presence/absence of err message in tests
	useLayoutEffect(() => {
		if (canDelete ===  null || canDelete === undefined) {
			return setUnknownStateError(
				'Unable to determine if entity is imported. DELETE forbidden.'
			);
		}

		return setUnknownStateError(null);
	}, [canDelete]);

	const onClick: MouseEventHandler = () => {
		if (canDelete) {
			const { importingDomain, isImport } = importStatus;

			if (isImport) {
				return deleteEntity({
					entityId: _id,
					isImport: true,
					domainId: importingDomain,
				});
			}

			return deleteEntity({
				entityId: _id,
				isImport: false,
			});
		}

		return null;
	};

	const onSuccessfulDelete = () => closeModal();

	return (
		<AppModal
			isForm={false}
			label={resource.isImport ? 'Remove Import' : 'Delete Entity'}
			isOpen={true}
			isDirty={false}
			onClose={closeModal}
			onSubmit={() => {}}
			canEdit={true}>
			<StyledPaper>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					<Heading component="h2">
						<Typography>
							{
								resource.isImport ?
									`Are you sure you want to remove ${resource.plural} from the domain?`
									: `Are you sure you want to delete ${resource.plural} from the domain?`
							}
						</Typography>
					</Heading>
					<FlexContainer
						style={{ width: '50%' }}
						justifyContent="space-between"
					>
						<Button onClick={closeModal}>
							Go Back
						</Button>
						<StyledDeleteButton onClick={onClick}>
							{resource.isImport ? "Remove Import" : "Delete Entity"}
						</StyledDeleteButton>
						{unknownStateError && (
							<Typography paragraph color="error">
								{unknownStateError}
							</Typography>
						)}
					</FlexContainer>
					<FormResults
						{...mutationResults}
						validationErrors={{}}
						onSuccess={onSuccessfulDelete}
					/>
				</StyledFlexContainer>
			</StyledPaper>
		</AppModal>
	);
};

export default DeleteEntityForm;
