import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch } from 'app/hooks';
import Heading from 'common/text/Heading';
import { pipe } from 'common/utils/functionUtils';
import {viewsAvailable} from 'features/HUD/state/HUDSlice';
import {useGetEntitiesQuery, useGetLiveDataCatalogsQuery} from 'features/api';
import {
	deriveDataViews,
	deriveDomainViews
} from 'features/compositeViews/helpers';
import useRenderView, {
	RenderViewConfig,
} from 'features/navigation/hooks/useRenderView';
import { StyledProfileGridLayout } from 'features/profile/common/styledComponents';
import { FunctionComponent, useEffect } from 'react';
import FlexContainer from "../../../common/FlexContainer";
import SourceBrowser from "../../browser/components/SourceBrowser/SourceBrowser";
import useActiveOrgData from "../../ontology/hooks/useActiveOrgData";
import useActiveSourceData from "../../ontology/hooks/useActiveSourceData";
import useActiveDomainData from "../../ontology/hooks/useActiveDomainData";

const Stub: FunctionComponent<{ placeholder: string }> = ({ placeholder }) => {
	return (
		<StyledProfileGridLayout>
			<div/>
			<Heading style={{color: '#29323c'}} component="h5">
				{placeholder}
			</Heading>
		</StyledProfileGridLayout>
	);
};


const configs: RenderViewConfig = {
	viewDefault: 'data',
	views: [
		{ match: 'data', element: <SourceBrowser /> },
		{ match: 'quality', element: <SourceBrowser /> },
		{ match: 'lineage', element: <SourceBrowser /> },
		{ match: 'usage', element: <SourceBrowser /> },
		{ match: 'relations', element: <SourceBrowser /> },
		{ match: 'form', element: <SourceBrowser /> },
	],
};


const SourceViews: FunctionComponent = () => {
	const { activeItem: activeSource } = useActiveSourceData();

	const {data: sourceCatalogs, isSuccess,} = useGetLiveDataCatalogsQuery(
		activeSource ? { sourceId: activeSource._id } : skipToken
	);

	const appDispatch = useAppDispatch();
	const render = useRenderView(configs);

	useEffect(() => {
		if (sourceCatalogs && isSuccess) {
			const views = deriveDataViews();
			pipe(views, viewsAvailable, appDispatch);
		}
		return () => pipe([], viewsAvailable, appDispatch) as void;
	}, [sourceCatalogs, isSuccess, appDispatch]);

	return render();
};

export default SourceViews;
