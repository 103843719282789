import React, {FunctionComponent} from "react";
import FlexContainer from "../FlexContainer";
import Typography from "../text/Typography";
import Spinner from "./Spinner";

const Uninitialized: FunctionComponent = () => {
    return (
            <FlexContainer justifyContent="center">
                <Typography color="warn" paragraph>
                    Waiting to receive target entity Id...
                </Typography>
                <Spinner/>
            </FlexContainer>
        );
}

export default Uninitialized;
