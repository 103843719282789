import {FunctionComponent, useCallback, useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {useLocation} from "react-router-dom";
import {
    initQueryStateTracker,
    mergeQueryStates,
    QueryStateTracker
} from "../../api/helpers";
import useLinkedInCodeRequest
    from "../../../common/sharing/useLinkedInCodeRequest";
import {useCreateLinkedInShareMutation} from "../../api";
import useOAuthTokenIsValid
    from "../../authentication/hooks/useOAuthTokenIsValid";
import Typography from "../../../common/text/Typography";
import IconButton from "../../../common/buttons/IconButton";
import {faX} from "@fortawesome/free-solid-svg-icons";
import Textbox from "../../../common/inputs/TextBox";
import FlexContainer from "../../../common/FlexContainer";
import Button from "../../../common/buttons/Button";
import {FormResults} from "../../../common/Form";
import html2canvas from "html2canvas";
import styled from "styled-components";
import {StyledSubmoduleHeader} from "../../profile/EntityProfile/components/styledComponents";

const capture = (elementId: string) => {

	const node = document.getElementById(elementId);

	if (!node) {
		return Promise.reject('Unable to find element requested for share');
	}

	return html2canvas(node).then((c) => {
		return new Promise<Blob>((res, rej) => {
			c.toBlob(
				(maybeBlob) => {
					if (maybeBlob === null) {
						return rej('Unable to convert canvas to blob');
					}
					return res(maybeBlob);
				}, 'image/jpg', 1
			);
		});
	});
};

const StyledForm = styled.form`
	padding: ${(p) => p.theme.spacing(1)};
`;

const StyledFormContainer = styled.div`
	min-width: 240px;
`;

const StyledFormHeader = styled(StyledSubmoduleHeader)`
	display: flex;
	justify-content: space-between;
`;


interface ShareFormValues {
	comment?: string;
	description?: string;
}

interface ShareFormProps {
	elementId: string;
	handleClose: () => void;
}

const ShareForm: FunctionComponent<ShareFormProps> = ({
	elementId,
	handleClose,
}) => {


	const { register, handleSubmit, watch } = useForm<ShareFormValues>({
		defaultValues: {
			comment: '',
			description: '',
		},
	});

	const [wComment, wDescription] = watch(['comment', 'description']);

	const location = useLocation();

	const [localFormState, setLocalFormState] = useState<QueryStateTracker>(
		initQueryStateTracker()
	);

	const { login, ...loginStatus } = useLinkedInCodeRequest();

	const [launchShare, shareStatus] = useCreateLinkedInShareMutation();

	const linkedInTokenValid = useOAuthTokenIsValid('linkedin');

	const doShare = useCallback(
		async (
			{comment, description, code,}: ShareFormValues & { code?: string }
			) => {
			try {
				const blob = await capture(elementId);
				const fd = new FormData();
				fd.append('imageBlob', blob);
				fd.append('link', location.pathname + location.search);
				if (comment && comment.length > 1) fd.append('comment', comment);
				if (description && description.length > 1) fd.append('description', description);
				if (code) fd.append('code', code);
				launchShare({ body: fd });
			} catch (e) {
				return setLocalFormState((p) => ({
					...p,
					isError: true,
					isLoading: false,
					error: {
						status: 400,
						data: { message: e as string },
					},
				}));
			}
		},
		[elementId, location.pathname, location.search, launchShare]
	);

	const onSubmit: SubmitHandler<ShareFormValues> = async (vals) => {
		setLocalFormState((p) => ({
			...p, isLoading: true, isUninitialized: false,
		}));

		if (linkedInTokenValid) return doShare(vals);

		return login();
	};

	useEffect(() => {
		if (!linkedInTokenValid && loginStatus.code && loginStatus.isSuccess) {
			// if token was expired, the only way loginStatus could be in success state is
			// if user initiated 'share' submission and completed the login flow.
			// If that's the case, submit the share to api server with code.
			doShare({
				comment: wComment,
				description: wDescription,
				code: loginStatus.code,
			});
		}
		//  NB: we do NOT want this to re-run on form field changes.  wComment and wDescription
		// are intentionally omitted from dependency array.
	}, [linkedInTokenValid, loginStatus.code, loginStatus.isSuccess, doShare]);

	useEffect(() => {
		if (shareStatus.isSuccess) {
			// finally, if the 'share' request to api server is successful,
			// all loading is done and form should be in a success state.
			setLocalFormState((p) => ({
				...p,
				isSuccess: true,
				isError: false,
				isLoading: false,
				error: undefined,
			}));
		}
	}, [shareStatus.isSuccess]);

	const mergedQueryStates = mergeQueryStates(shareStatus, localFormState);

	return (
		<StyledFormContainer>
			<StyledFormHeader>
				<Typography variant='h5'>
					Share
				</Typography>
				<IconButton
					icon={faX}
					onClick={() => handleClose()}
					aria-label="close form"
					size="xs"
				/>
			</StyledFormHeader>
			<StyledForm onSubmit={handleSubmit(onSubmit)}>
				<Textbox {...register('comment')} labelText="post comment" />
				<Textbox
					{...register('description')}
					labelText="post description"
				/>
				<FlexContainer justifyContent="center">
					<Button
						disabled={
							mergedQueryStates.isLoading ||
							mergedQueryStates.isError ||
							mergedQueryStates.isSuccess
						}
					>
						<Typography variant='button'>
							Submit
						</Typography>
					</Button>
				</FlexContainer>
				<FormResults
					{...mergedQueryStates}
					onSuccess={() => handleClose()}
					onSuccessDelay={1500}
				/>
			</StyledForm>
		</StyledFormContainer>
	);
};

export default ShareForm;
