import React, {
	FunctionComponent, useCallback, useEffect, useState,
} from "react";
import ReactModal from "react-modal";
import StyledSquare from "../StyledSquare";
import Typography from "../text/Typography";
import IconButton from "../buttons/IconButton";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {BasePaper} from '../Paper';
import styled from 'styled-components';
import useCurrentUser from "../../features/authentication/hooks/useUser";
import {useGetFollowingQuery} from "../../features/api";
import {BaseUser, FollowersSubject} from "../../features/authentication/types/userTypes";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import ResourceItem from "../List/ResourceItem";
import AppModal from "./AppModal";
import {pipe, sortByString} from "../utils/functionUtils";
import {List} from "../List";
import {parseQueryError} from "../../features/api/helpers";
import {PopoverBase} from "../popovers/Popover";
import UserPopover from "../popovers/UserPopover";
import ResourcePopover from "../popovers/ResourcePopover";
import useModalType from "../../features/HUD/hooks/useModalType";

export const SourceBody = styled(BasePaper)`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: ${(p) => p.theme.palette.background.paper};
`

interface HUDProps {
	translucent?: boolean;
	borderRadius?: string;
	width?: string | number;
	height?: string | number;
}


	// box-shadow: ${(p) => p.theme.shadows[4]};
export const HUDModalBody = styled.div<HUDProps>`
	background: ${(p) =>
		p.theme.palette.background[
			p.translucent ? 'transparentDark' : 'paper'
		]};
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	color: ${(p) => p.theme.palette.lightBaby};
	width: ${(p) => p.width ? p.width : '50'}vw;
	height: ${(p) => p.height ? p.height : '50'}vh;
	display: flex;
	flex-direction: column;

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
	${(p) => p.theme.translucent}
`;

interface StyledModalHeaderProps {
	justifyContent?: string;
	borderBottom?: string;
	padding?: string;
}

export const ModalHeader = styled.header<StyledModalHeaderProps>`
    border-bottom: ${(p) => p.borderBottom ? p.borderBottom : `2px solid ${p.theme.palette.divider}`};
	padding: ${(p) => p.padding ? p.padding : p.theme.spacing(1, 2)};
	display: flex;
	justify-content: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
`;

export const HUDModalHeaderText = styled.h1`
	font-size: 1rem;
	display: flex;
	align-items: center;
`;


interface ModalHeaderProps extends ReactModal.Props {
	handleClose: () => void;
	headingText: string;
}

const HUDModalHeader: FunctionComponent<ModalHeaderProps> = ({
	headingText,
	handleClose
}) => {
	return (
		<ModalHeader>
			<HUDModalHeaderText>
				<StyledSquare/>
				<span style={{paddingLeft: '.75rem'}}>
					<Typography color={'oldManGray'} variant={'h4'}>
						{headingText}
					</Typography>
				</span>
			</HUDModalHeaderText>
			<IconButton
				onClick={handleClose}
				icon={faTimes}
				aria-label="close source browser"
			/>
		</ModalHeader>
	);
};

type FollowingPanelProps = {
	objectId?: number;
	isOpen: boolean;
	onClose: () => void;
};

export const getURLStumpFromObjectType = (objectType: FollowersSubject) => {
	switch (objectType) {
		case 'Organization':
			return 'orgs';
		case 'User':
			return 'users';
		case 'DataSource':
			return 'sources';
		case 'DataCatalog':
			return 'catalogs';
		case 'Dataset':
			return 'datasets';
		case 'DataColumn':
			return 'columns';
		case 'DataRow':
			return 'rows';
		case 'DataValue':
			return 'values';
		case 'DataType':
			return 'types';
		case 'Metric':
			return 'metrics';
		case 'Fact':
			return 'facts';
		case 'Insight':
			return 'insights';
		case 'Domain':
			return 'domains';
		case 'Entity':
			return 'entities';
		case 'Attribute':
			return 'attributes';
		case 'Individual':
			return 'individuals';
	}
};

// export const groupResources = (resources) => {
//
//
// 		//  put each array of attributes in alphabetical order based on their 'singular' field
// 		// grouped.forEach((vals: any, key: any, map: any) =>
// 		// 	map.set(key, sortByString(vals, (a: any) => a.name))
// 		// );
//
// 		return grouped;
// 	};

// export const sortGroupedResources = (grouped) => {
//
// 	return Array.from(grouped.entries())
// 		.map(([key, val]) => ({
// 			type: key,
// 			attrs: val,
// 		}))
// 		.sort((a, b) => {
// 			const nameA = attributeTypePriorityMap[
// 				resolvedTypeToType(a.type)
// 				] as number;
//
// 			const nameB = attributeTypePriorityMap[
// 				resolvedTypeToType(b.type)
// 				] as number;
//
// 			if (nameA < nameB) {
// 				return -1;
// 			}
// 			if (nameA > nameB) {
// 				return 1;
// 			}
//
// 			// names must be equal
// 			return 0;
// 		});
// }

const objectTypeLabels: Record<string, string> = {
	User: 'Users',
	Organization: 'Organizations',
	Domain: 'Domains',
	Entity: 'Entities',
	Metric: 'Metrics',
	Individual: 'Individuals',
}

// @ts-ignore
const FollowingModal: FunctionComponent = () => {

	const {modalProps, closeModal} = useModalType();
	const {objectId} = modalProps;
	const user = useCurrentUser();
	const {
		data: following,
		isLoading,
		isError,
		isUninitialized,
		error,
	} = useGetFollowingQuery(
		objectId ? { objectId, objectType: 'users'} : skipToken
	);

	const [popoverResource, setPopoverResource] = useState<any | null>(null);
	const [anchor, setAnchor] = useState<HTMLElement | null>(null);
	const [grouped, setGrouped] = useState<object | null>(null);
	const [resourceErr, setResourceErr] = useState<Record<string, string>>({});
	const [onHold, setOnHold] = useState<boolean>(false);

	const updateResourceErr = useCallback(
		(msg: string, id: number) => setResourceErr((p) => ({ ...p, [id]: msg })),
		[]
	);

	// useEffect(() => {
	// 	if (error) {
	// 		updateResourceErr(parseQueryError(error).message, objectId);
	// 	}
	// }, [error, setResourceErr, objectId]);

	useEffect(() => {
		if (following && following.items) {
			setGrouped(
				pipe(following.items, (data: any) => {
					return data.reduce((acc: any, next: any) => {
						const resource = next.resource;
						const type = objectTypeLabels[resource._object as string];

						const existing = acc[type];

						if (existing) {
							acc[type] = [...existing, next];
							return acc;
						}

						acc[type] = [next];
						return acc;
						}, {}) as any;
				})
			)
		}
	}, [following, isLoading])

	if (user) {

		return (
			<AppModal
				label={"Following"}
				isForm={false}
				isOpen={true}
				isDirty={false}
				onClose={closeModal}
				onSubmit={() => {}}
				canEdit={false}>
				<PopoverBase
					anchorEl={anchor}
					// handleClickAway={() => setPopoverResource(null)}
					open={!!popoverResource}>
					{popoverResource && <ResourcePopover key={popoverResource ? popoverResource.name : ''} resource={popoverResource} />}
				</PopoverBase>
				{following && following.items && grouped && Object.entries(grouped).map(([key, value], index) => {
					console.log(key)
					console.log(value)
					return (
						<div key={key}>
							{key}
							{/*<StyledHeading component="h6" style={{*/}
							{/*	margin: '10px 10px 10px 5px',*/}
							{/*	color: theme.palette.primary.main,*/}
							{/*}}>*/}
							{/*</StyledHeading>*/}
							<List>{value.map((resource: any) => {
								return <ResourceItem
									key={resource._id}
									resource={resource.resource}
									// controls={controls}
									canEdit={false}
									handleError={resourceErr}
									onHold={onHold}
									onExit={(onHold: boolean) => {
										if (!onHold) {
											setAnchor(null);
											setPopoverResource(null);
										}
									}}
									onClick={(e: any) => {
										e.preventDefault();
										setAnchor(e.currentTarget);
										setPopoverResource(resource.resource);
									}}/>
							})}</List>
						</div>
					);
				})}
			</AppModal>
		);
	} else {
		return null
	}
};

export default FollowingModal;
