import { useGetAttrNeighborsQuery } from '../../api';
import { BaseAttribute } from '../../ontology/types/attributeTypes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';
import useActiveEntityAttributes
	from "../../ontology/hooks/useActiveEntityAttributes";
import useActiveDomainData from "../../ontology/hooks/useActiveDomainData";

/**
 * Note that filter function will run every render if it is not memoized.
 */
const useAttrNeighbors = (
	attrId: number | null,
	filter: (ns: BaseAttribute) => boolean
) => {
	// useActiveDomainData()
	const queryRes = useGetAttrNeighborsQuery(attrId ? { attrId } : skipToken);

	const neighbors = useMemo(() => {
		if (queryRes.isSuccess) {
			return queryRes.data.filter(filter);
		}

		return null;
	}, [queryRes.isSuccess, queryRes.data, filter]);

	return {
		...queryRes,
		attrNeighbors: neighbors,
	};
};

export default useAttrNeighbors;
