import { ActionDescriptor } from '../common/commonTypes';
import TranslateValueForm from './TranslateValueForm';

const translateValue: ActionDescriptor = {
	form: TranslateValueForm,
	actionTitle: 'Translate',
	divider: false,
	requiresEdit: true,
	kind: 'modal',
	modalType: 'translateValue'
};

export default translateValue;
