import IconButton from './IconButton';
import PopoverBase from '../popovers/Popover/PopoverBase';
import DeleteResourceForm from '../../features/actions/deleteResource/DeleteResourceForm';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
	FunctionComponent,
	Dispatch,
	SetStateAction,
	MouseEventHandler,
	useState,
} from 'react';
import TextButton from "./TextButton";

interface DeleteOrgListItemProps {
	objectId: number;
	objectType: string;
	label: string;

	setOpenActionSubmenu: Dispatch<SetStateAction<string | null>>;
	openActionSubmenu: string | null;
}

const DestroyButton: FunctionComponent<DeleteOrgListItemProps> = ({
	objectId,
	objectType,
	label,
	setOpenActionSubmenu,
	openActionSubmenu,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const onItemClick: MouseEventHandler = (e) => {
		e.stopPropagation();
		setOpenActionSubmenu((p) => {
			if (p === 'delete') {
				return null;
			}

			return 'delete';
		});
	};

	const deleteFormOpen = openActionSubmenu === 'delete';

	return (
		<>
			<TextButton
				// icon={faTrash}
				// size="xs"
				typography={'subtitle'}
				alignContent={'flex-start'}
				text={`Destroy ${objectType}`}
				fillColor={'transparent'}
				color="error"
				baseOpacity="80%"
				tooltip="Destroy Organization"
				aria-label="destroy org"
				onClick={onItemClick}
				ref={setAnchorEl as any}
				// showTooltip={!deleteFormOpen}
				tooltipPlacement="top"
				type="button"
			/>

			<PopoverBase
				anchorEl={anchorEl}
				open={deleteFormOpen}
				offset={0}
				placement="right"
			>
				<DeleteResourceForm
					setOpenAction={setOpenActionSubmenu}
					objectId={objectId}
					objectType={objectType}
					label={label}
				/>
			</PopoverBase>
		</>
	);
};

export default DestroyButton;
