import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleSelect from "./SubtleSelect";
import {
    DataFileCSVIcon,
    DataFileImageIcon,
    DataFileVideo,
    DataFileAudioIcon,
    DataFilePDFIcon,
    DataFileTextIcon,
    DataFileCodeIcon,
    DataFileZippedIcon,
    DataFileUnknownIcon,
} from "../icons";

// file-excel
const deriveDataProps = (data: any) => {
    // () => (setter: SourceSetter) =>
    // (data: LiveDataSet): CardProps => {
    switch (data.contentType) {
        case 'text/csv':
            return {
                key: data.name,
                icon: DataFileCSVIcon,
                title: data.name,
                // onClick: () => setter(data),
            }

        case 'application/image':
            return {
                key: data.name,
                icon: DataFileImageIcon,
                title: data.name,
                // onClick: () => setter(data),
            }
        case 'application/video':
            return {
                key: data.name,
                icon: DataFileVideo,
                title: data.name,
                // onClick: () => setter(data),
            }
        case 'application/audio':
            return {
                key: data.name,
                icon: DataFileAudioIcon,
                title: data.name,
                // onClick: () => setter(data),
            }
        case 'application/pdf':
            return {
                key: data.name,
                icon: DataFilePDFIcon,
                title: data.name,
                // onClick: () => setter(data),
            }
        case 'text/plain':
            return {
                key: data.name,
                icon: DataFileTextIcon,
                title: data.name,
                // onClick: () => setter(data),
            }
        case 'text/html':
            return {
                key: data.name,
                icon: DataFileCodeIcon,
                title: data.name,
                // onClick: () => setter(data),
            }
        case 'application/gzip':
            return {
                key: data.name,
                icon: DataFileZippedIcon,
                title: data.name,
                // onClick: () => setter(data),
            }
    }
    return {
        key: data.name,
        icon: DataFileUnknownIcon,
        title: data.name,
        // onClick: () => setter(data),
    }
}


const contentTypes = [
    {
        name: 'TXT',
        label: 'text/plain'
    },
    {
        name: 'JS',
        label: 'application/javascript'
    },
    {
        name: 'MD',
        label: 'text/markdown'
    },
    {
        name: 'CSV',
        label: 'text/csv'
    },
    {
        name: 'TSV',
        label: 'text/tsv'
    },
    {
        name: 'PSV',
        label: 'text/psv'
    },
    {
        name: 'HTML',
        label: 'text/html'
    },
    {
        name: 'JPG',
        label: 'image/jpeg'
    },
    {
        name: 'PNG',
        label: 'image/png'
    },
    {
        name: 'GIF',
        label: 'image/gif'
    },
    {
        name: 'TIFF',
        label: 'image/tiff'
    },
    {
        name: 'MP4',
        label: 'video/mp4'
    },
    {
        name: 'MPEG',
        label: 'audio/mpeg'
    },
    {
        name: 'MP4A',
        label: 'audio/mp4a-latm'
    },
    {
        name: 'JSON',
        label: 'application/json'
    },
    {
        name: 'JSON2',
        label: 'application/json2'
    },
    {
        name: 'XML',
        label: 'application/xml'
    },
    {
        name: 'PDF',
        label: 'application/pdf'
    }
]
//DIRECTORY_MAP = {
//     '.md': 'text',
//     '.jpg': 'image',
//     '.png': 'image',
//     '.gif': 'image',
//     '.jpeg': 'image',
//     '.html': 'html',
//     '.txt': 'text',
//     '.lkml': 'lkml',
//     '.csv': 'csv',
//     '.tsv': 'tsv',
//     '.psv': 'psv',
//     '.json2': 'json2',
//     '.pdf': 'pdf',
//     '.mp4': 'video',
//     '.mp3': 'audio',
//     '.m4a': 'audio',
//     '.xml': 'other'
// }
//
//
// FILE_FORMATS = dict(
//     Images={
//         '.jpg',
//         '.jpeg',
//         '.png',
//         '.gif',
//         '.gif',
//         '.webp',
//         '.jpe',
//         '.svg',
//         '.tif',
//         '.tiff',
//         '.jif',
//     },
//     Videos={
//         '.mpeg',
//         '.avchd',
//         '.webm',
//         '.mpv',
//         '.swf',
//         '.avi',
//         '.m4p',
//         '.wmv',
//         '.mp2',
//         '.m4v',
//         '.qt',
//         '.mpe',
//         '.mp4',
//         '.flv',
//         '.mov',
//         '.mpg',
//         '.ogg'
//     },
//     Audio={
//         '.mp3',
//         '.flac',
//         '.wav',
//         '.m4a'
//     }
// )

//
// class MimeType(StrEnum):
//
//     JPG = '.jpg'
//     PNG = '.png'
//     GIF = '.gif'
//     JPEG = '.jpeg'
//     HTML = '.html'
//     TXT = '.txt'
//     LKML = '.lkml'
//     CSV = '.csv'
//     TSV = '.tsv'
//     JSON = '.json2'
//     PDF = '.pdf'
//     MP4 = '.mp4'
//     MP3 = '.mp3'
//     M4A = '.m4a'
//     XML = '.xml'


const RelationTypeSelect: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleSelect
        inline={true}
        label={`Content Type`}
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('type')}
        isDirty={Boolean(formState.dirtyFields.type)}
        {...validatingRegister('type')}
    >
        {contentTypes.map((type: { name: string, label: string }) => {
            return <option key={type.name} value={type.name}>
                {type.label}
            </option>
        })}
    </SubtleSelect>;
};

export default RelationTypeSelect;
