import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import IconButton from "../buttons/IconButton";
import FlexContainer from "../FlexContainer";
import AppModal from "../modals/AppModal";

const LinkedInInput: FunctionComponent<ActionFieldProps> = (
    {
        defaultValue,
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <FlexContainer alignItems={'center'}>
        <IconButton
            icon={faLinkedin as any}
            tooltip=""
            size="xs"
            iconSize='lg'
            style={{marginLeft: '4px'}}
            onClick={() => {
            }}
            showTooltip={true}
        />
        <SubtleTextbox
            {...validatingRegister('linkedin', {})}
            label="LinkedIn"
            defaultValue={defaultValue}
            isDirty={Boolean(formState.dirtyFields.linkedin)}
            onReset={() => resetField('linkedin')}
            error={harvestErrors(
                'linkedin',
                formState,
                mutationResults
            )}
        />
    </FlexContainer>;
}

export default LinkedInInput;
