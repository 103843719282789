import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useAppDispatch} from 'app/hooks';
// import Heading from 'common/Heading';
import {pipe} from 'common/utils/functionUtils';
import {viewsAvailable} from 'features/HUD/state/HUDSlice';
import {useGetDomainsQuery, useGetSourceSummariesQuery} from 'features/api';
import {deriveOrgViews} from 'features/compositeViews/helpers';
import OrgGraph from 'features/graph/components/OrgGraph';
import {BaseDomain} from 'features/ontology/types/domainTypes';
import OrgSpatial from 'features/spatial/components/OrgSpatial';
import OrgProfile from 'features/profile/OrgProfile';
import useRenderView, {RenderViewConfig,} from 'features/navigation/hooks/useRenderView';
import useActiveOrgData from 'features/ontology/hooks/useActiveOrgData';
// import { StyledProfileGridLayout } from 'features/profile/common/styledComponents';
import React, {FunctionComponent, useEffect} from 'react';
import FlexContainer from "../../../common/FlexContainer";
import {EntityViewFlag} from "../../ontology/types/entityTypes";
import {DataSourceSummary} from "../../browser/types/dataTypes";
import SourceBrowser
    from "../../browser/components/SourceBrowser/SourceBrowser";
import {useLocation} from "react-router-dom";
import {BaseOrg} from "../../ontology/types/orgTypes";
import OrgData from "../../browser/components/OrgData";
import Helmet from "react-helmet";
import OrgMeta from "../../../common/meta/OrgMeta";

// const Stub: FunctionComponent<{ placeholder: string }> = ({ placeholder }) => {
// 	return (
// 		<StyledProfileGridLayout>
// 			<div/>
// 			<Heading style={{color: '#29323c'}} component="h5">
// 				{placeholder}
// 			</Heading>
// 		</StyledProfileGridLayout>
// 	);
// };

const configs: RenderViewConfig = {
    views: [
        {
            match: 'profile',
            element: <OrgProfile/>,
        },
        {
            match: 'graph',
            element: <OrgGraph/>,
        },
        {
            match: 'spatial',
            element: <OrgSpatial/>,
        },
        {
            match: 'data',
            element: <OrgData/>,
        },
    ],
    viewDefault: 'profile',
};

const OrgViews: FunctionComponent = () => {

    const appDispatch = useAppDispatch();
    const {isLoading, isFetching, activeItem: activeOrg} = useActiveOrgData();

    const render = useRenderView(configs);

    // const { data: orgDomains, isSuccess: isDomainSuccess } = useGetDomainsQuery(
    // 	activeOrg && activeOrg._id ? {orgId: activeOrg._id} : skipToken
    // );

    useEffect(() => {
        // if (orgDomains && isDomainSuccess) {
        const views = deriveOrgViews();
        pipe(views, viewsAvailable, appDispatch);
        // }

        return () => pipe([], viewsAvailable, appDispatch) as void;
    }, [appDispatch]);
    // [orgDomains, isDomainSuccess

    return <>
        {!!activeOrg && <OrgMeta org={activeOrg}/>}
        {render()}
    </>;
    // <FlexContainer
    // 	alignItems='center'
    // 	justifyContent='center'
    // 	alignContent='center'>
    // 	{}
    // </FlexContainer>;
};

export default OrgViews;
