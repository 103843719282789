import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleSelect from "./SubtleSelect";

const compressionTypes = {
    zip: 'compressed/zip',
    bz2: 'compressed/bz2',
    gzip: 'compressed/gz',
}

const CompressionTypeSelect: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleSelect
        inline={true}
        label={`Compression Format`}
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('compressionType')}
        isDirty={Boolean(formState.dirtyFields.compressionType)}
        {...validatingRegister('compressionType')}
    >
        {Object.entries(compressionTypes).map(([name, format]) => {
            return <option key={name} value={name}>
                {format}
            </option>
        })}
    </SubtleSelect>;
};

export default CompressionTypeSelect;
