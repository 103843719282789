import {Link} from "react-router-dom";
import Button from "./Button";
import theme from "../theme/theme";
import Typography from "../text/Typography";
import FlexContainer from "../FlexContainer";
import React from "react";

const RequestAccessLink = () => {

    return <Link
        to="/"
        style={{
            textDecoration: 'none',
            color: 'inherit',
            position: 'relative',
        }}
    >
        <Button
            variant='text'
            style={{
                borderColor: theme.palette.drapeGray,
                height: '40px',
                width: '150px',
                borderRadius: theme.spacing(1),
            }}
            data-testid="request-access"
        >
            <Typography variant={'button'} color={'primary'}>
                Request invite
            </Typography>
        </Button>
    </Link>
};

export default RequestAccessLink;
