import { truncateDataLinkURL } from '../helpers';
import { DataContextLadder, DataContextParams } from '../types/navigationContextTypes';
import { useParams } from 'react-router-dom';

const useDataParams = (targetCtxLevel: DataContextLadder[number]) => {
	const params = useParams<DataContextParams>();
	const linkBuilder = truncateDataLinkURL(params, targetCtxLevel);
	return { ...params, linkBuilder };
};

export default useDataParams;
