import { ActionDescriptor } from '../common/commonTypes';
import LoadAttributeForm from './LoadAttributeForm';

const loadAttribute: ActionDescriptor = {
	form: LoadAttributeForm,
	requiresEdit: true,
	actionTitle: 'Load',
	kind: 'modal',
	divider: false,
	modalType: 'loadAttribute'
};

export default loadAttribute;
