import {useLocation,} from 'react-router-dom';
import {useNavigate} from 'react-router';
import { ViewMode } from 'features/compositeViews/types';
import {DataViewMode} from "../../browser/types/uiTypes";

function isOfTypeViewMode (keyInput: string): keyInput is ViewMode {
  return ['profile', 'graph', 'spatial', 'data', 'lineage', 'quality', 'usage', 'form', 'relations'].includes(keyInput);
}

function isOfTypeDataViewMode (keyInput: string): keyInput is DataViewMode {
  return ['lineage', 'quality', 'usage', 'form', 'relations', 'data'].includes(keyInput);
}

const useViewModeParams = () => {

	const {pathname} = useLocation();
	const getViewMode = () => {
		const pathElements = pathname.split('/');

		if (pathElements.length === 2 && pathElements[0] === '' && pathElements[1] === '') {
			return 'feed'
		}
		if (pathElements.length > 0) {
			const view = pathElements.slice(-1)[0];
			if (pathElements.slice(1)[0] === 'data') {
				return 'data'
			} else if (isOfTypeViewMode(view)) {
				return view
			} else {
				return 'profile'
			}
		}
	}

	const navigate = useNavigate();
	const setViewMode = (mode: ViewMode | string) => {
		const pathElements = pathname.split('/');
		pathElements.reverse().pop();
		pathElements.reverse();
		const leading = pathElements.slice(0)[0];
		const trailing = pathElements.slice(-1)[0];
		console.log(pathElements)
		if (isOfTypeViewMode(trailing)) {
			pathElements.pop();
		}
		console.log(pathElements)
		if (isOfTypeViewMode(mode)) {
			if (leading === 'data' && mode !== 'data') {
				pathElements.reverse().pop();
				pathElements.reverse();
				pathElements.push(mode);
				console.log(pathElements)
			} else if (leading !== 'data' && mode === 'data') {
				pathElements.reverse().push(mode);
				pathElements.reverse();
				console.log(pathElements)
			} else {
				pathElements.push(mode);
				console.log(pathElements)
			}
			console.log(pathElements)
			navigate('../' + pathElements.join('/'))
		} else if (mode !== null) {
			if (isOfTypeViewMode(trailing)) {
				pathElements.push(mode);
				pathElements.push(trailing);
				console.log(pathElements)
				navigate('/' + pathElements.join('/'))
			} else {
				pathElements.push(mode);
				console.log(pathElements)
				navigate('/' + pathElements.join('/'))
			}
		}
	};

	return { getViewMode, setViewMode };
};

export default useViewModeParams;
