import FlexContainer from '../../FlexContainer';
import styled from 'styled-components';

export const StyledInputContainer = styled(FlexContainer)`
	padding: ${(p) => p.theme.spacing(0.25)};
	border: 1px solid transparent;
	overflow: auto;
	flex-wrap: no-wrap;
`;
	// &:hover {
	// 	cursor: pointer;
	// }
	// &:focus-within {
	// 	border: 1px solid ${(p) => p.theme.palette.divider};
	// }
		// border: 1px solid ${(p) => p.theme.palette.divider};

export const StyledInput = styled.input`
	position: relative;
	background-color: transparent;
	font-family: ${(p) => p.theme.typography.body1.fontFamily};
	font-size: ${(p) => p.theme.typography.body1.fontSize};
	font-weight: ${(p) => p.theme.typography.fontWeightRegular};
	color: inherit;
	outline: none;
	border: none;
	padding: ${(p) => p.theme.spacing(0.5)};
	flex-grow: 1;

	&:hover {
		cursor: pointer;
	}

	&:focus,
	&.is-dirty {
		cursor: text;
		/* background-color: ${(p) => p.theme.palette.background.paper};
		color: ${(p) => p.theme.palette.common.white}; */
	}
`;

	// box-shadow: ${(p) =>
	// 	p.$error ? genShadow(p.theme.palette.error.main) : 'none'};
export const StyledTextbox = styled.input<{ $error?: boolean }>`
	border: 2px solid ${(p) => p.theme.palette.drapeGray};
	color: ${(p) => p.theme.palette.common.white}BF;
	border-radius: ${(p) => p.theme.spacing(1)};
	font-size: ${(p) => p.theme.typography.input.fontSize};
	line-height: ${(p) => p.theme.typography.input.lineHeight};
	letter-spacing: ${(p) => p.theme.typography.input.letterSpacing};
	font-weight: ${(p) => p.theme.typography.input.fontWeight};
	font-family: ${(p) => p.theme.typography.input.fontFamily};
	padding: ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(1)};
	width: 100%;
	background-color: ${(p) => p.theme.palette.common.white}0A;
	&:-webkit-autofill {
		background-color: ${(p) => p.theme.palette.oldManGray};
		background-color: ${(p) => p.theme.palette.darkerBaby};
	}
	&::autofill {
		background-color: ${(p) => p.theme.palette.oldManGray};
	}
	&:focus {
		border: 1px solid ${(p) =>
			p.$error ? p.theme.palette.error.main : p.theme.palette.darkerBaby}AA;
		outline: none;
	}
		&:active {
		outline: none;
	}

	&::placeholder {
		color: ${(p) => p.theme.palette.cyan};
		font-size: ${(p) => p.theme.typography.overline.fontSize};
		line-height: ${(p) => p.theme.typography.overline.lineHeight};
		letter-spacing: ${(p) => p.theme.typography.overline.letterSpacing};
		font-weight: ${(p) => p.theme.typography.overline.fontWeight};
		font-family: ${(p) => p.theme.typography.overline.fontFamily};
	}
	caret-color:  ${(p) => p.theme.palette.primary.main};
`;

export const StyledLabel = styled.label`
	padding-right: ${(p) => p.theme.spacing(1)};
	font-family: ${(p) => p.theme.typography.body1.fontFamily};
	font-size: ${(p) => p.theme.typography.body1.fontSize};
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
	letter-spacing: ${(p) => p.theme.typography.body1.letterSpacing};
	line-height: ${(p) => p.theme.typography.body1.lineHeight};
	color: ${(p) => p.theme.palette.cyan};
`;
