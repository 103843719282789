import React, {FunctionComponent} from "react";
import {getRestrictionOperators} from "../../features/actions/common/helpers";
import SubtleSelect from "./SubtleSelect";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {BaseAttribute} from "../../features/ontology/types/attributeTypes";
import {harvestErrors} from "./SubtleTextBox";

export const entityTypes: readonly {name: string, label: string}[] =
	[
		{
			name: 'object',
			label: 'Object',
		},
		{
			name: 'collection',
			label: 'Collection',
		},
		{
			name: 'event',
			label: 'Event',
		},
		{
			name: 'activity',
			label: 'Activity',
		},
			{
			name: 'agent',
			label: 'Agent',
		},
		{
			name: 'person',
			label: 'Person',
		},
		{
			name: 'organization',
			label: 'Organization',
		},
		{
			name: 'softwareAgent',
			label: 'Bot',
		},
		{
			name: 'location',
			label: 'Location',
		},
			{
			name: 'area',
			label: 'Area',
		},
		{
			name: 'plan',
			label: 'Plan',
		},

	] as const;

const EntityTypeSelect: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
    ) => {

    return <SubtleSelect
        inline={true}
        label={`Entity Type`}
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('entityType')}
        isDirty={Boolean(formState.dirtyFields.entityType)}
        {...validatingRegister('entityType')}
    >
        {entityTypes.map(({name, label}) => (
            <option value={name} key={name}>
                {label}
            </option>
        ))}
    </SubtleSelect>;
}

export default EntityTypeSelect;
