import { OrgGraphDataResponse } from '../../ontology/types/orgTypes';
import { DomainGraphDataResponse } from '../../ontology/types/domainTypes';
import {
	SelectedGraphObject,
	SelectedObjectWithVC,
	UIGraphNode,
} from '../types/graphTypes';
import { ForceGraphMethods, GraphData } from 'react-force-graph-2d';

export interface TwoDGraphState {
	previousPopupElementIdentity: null | SelectedGraphObject;
	creationFormOpen: boolean;
	graphData: GraphData;
	popupElement: null | SelectedObjectWithVC;
	forceGraphMethods: null | ForceGraphMethods;
	isLoadingData: boolean;
}

export const OPEN_CREATION_FORM = 'graphContext/openCreationForm';

interface OpenCreationForm {
	type: typeof OPEN_CREATION_FORM;
}

export const CLOSE_CREATION_FORM = 'graphContext/closeCreationForm';

interface CloseCreationForm {
	type: typeof CLOSE_CREATION_FORM;
}

export const SET_FG_METHODS = 'graphContext/setFGMethods';

// note there's no 'close' action--if this needs updating, pass a new FG payload.
interface SetFGMethods {
	type: typeof SET_FG_METHODS;
	payload: ForceGraphMethods;
}

export const SET_GRAPH_DATA = 'graphContext/setGraphData';

interface SetGraphData {
	type: typeof SET_GRAPH_DATA;
	payload: OrgGraphDataResponse | DomainGraphDataResponse;
}

export const SET_POPUP_ELEMENT = 'graphContext/setPopupElement';

interface SetPopupElement {
	type: typeof SET_POPUP_ELEMENT;
	payload: SelectedGraphObject;
}

export const ZOOM_TO_NODE = 'graphContext/zoomToNode';

export interface ZoomToNode {
	type: typeof ZOOM_TO_NODE;
	payload: UIGraphNode;
}

export const RECENTER_GRAPH = 'graphContext/recenterGraph';

export interface RecenterGraph {
	type: typeof RECENTER_GRAPH;
}

export const PAUSE_ANIMATION = 'graphContext/pauseAnimation';

export interface PauseAnimation {
	type: typeof PAUSE_ANIMATION;
}

export const SET_LOADING_STATE = 'graphContext/setLoadingState';

export interface SetLoadingState {
	type: typeof SET_LOADING_STATE;
	payload: boolean;
}

export type PureGraphAction =
	| OpenCreationForm
	| CloseCreationForm
	| SetFGMethods
	| SetGraphData
	| SetPopupElement
	| SetLoadingState;

export type MutatingGraphAction = ZoomToNode | RecenterGraph | PauseAnimation;

export type GraphAction = PureGraphAction | MutatingGraphAction;
