import { FunctionComponent } from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import AppBase from 'app/AppBase';
import NotFound from './NotFound';
import RequireAuth from './RequireAuth';
import HUD from 'features/HUD';
import RegisterPage from 'features/authentication/components/RegisterPage';
import LandingPage from "../../pages";
import LegalPage from "../../pages/LegalPage";
import AboutPage from "../../pages/AboutPage";
import CareersPage from "../../pages/CareersPage";
import DevelopersPage from "../../pages/DevelopersPage";
import HelpPage from "../../pages/HelpPage";
import DocsPage from "../../pages/DocsPage";
import ContactPage from "../../pages/ContactPage";
import BusinessPage from "../../pages/BusinessPage";
import TOSPage from 'features/authentication/components/TOSPage';
import {
	PrivacyPolicy,
	PrivacyCenter,
	PolicyBasics,
	AdPolicy,
	CookiesPolicy,
	DataPolicy,
	CommunityPolicy
} from "../../pages/privacy";
// import SupportPage from "../../pages/SupportPage";
import Onboarding from 'features/authentication/components/Onboarding';
import CredentialsPage from 'features/authentication/components/CredentialsPage';
import RequestAccessPage from 'features/authentication/components/RequestAccessPage';
import RequestPWResetPage from 'features/authentication/components/RequestPWResetPage';
import ResetPWPage from 'features/authentication/components/ResetPWPage';
import NavPanel, {DataNav} from "../../navigation/components/Navbar";
import AgentViews from "../../compositeViews/AgentViews";
import DomainViews from 'features/compositeViews/DomainViews';
import EntityViews from "../../compositeViews/EntityViews";
import IndividualProfile from 'features/profile/IndividualProfile';
import IndividualSpatial from 'features/spatial/components/IndividualSpatial';
import Callback from 'common/sharing/Callback';
import DatasetViews from "../../compositeViews/DatasetViews";
import CatalogViews from "../../compositeViews/CatalogViews";
import SourceViews from "../../compositeViews/SourceViews";
import ColumnViews from "../../compositeViews/ColumnViews";
import OrgViews from "../../compositeViews/OrgViews";
import SearchPage from "../../../common/search/components";

const requireAuth = (element: any, tosRequired: boolean = false) => {
	return <RequireAuth to="/login" TOSRequired={tosRequired}>
		{element}
	</RequireAuth>;
};


const PageRouter: FunctionComponent = () => {
	return (
		<Routes>
			<Route path="/" element={<AppBase />}>

				<Route index element={<LandingPage />} />
				<Route path="request-access" element={<RequestAccessPage />} />
				<Route path="complete-registration" element={<RegisterPage />}/>
				<Route path="complete-registration/:token" element={<RegisterPage />}/>
				<Route path="login" element={<CredentialsPage />} />
				<Route path="reset-password-request" element={<RequestPWResetPage />}/>
				<Route path="reset-password" element={<ResetPWPage />} />

				<Route path="terms-of-service" element={requireAuth(<TOSPage />, false)}/>
				<Route path="onboarding" element={requireAuth(<Onboarding />, true)}/>
				<Route path="notifications" element={requireAuth(<><NavPanel /><HUD/></>, true)} />
				<Route path="followers" element={requireAuth(<><NavPanel /><HUD/></>, true)}>
					<Route index element={<LandingPage />} />
					<Route path="requests" element={<AppBase />} />
					{/*<Route path="suggestions" element={<AppBase />} />*/}
					{/*<Route path="list" element={<AppBase />} />*/}
					{/*<Route path="birthdays" element={<AppBase />} />*/}
					{/*<Route path="custom-lists" element={<AppBase />} />*/}
				</Route>

				<Route path="search" element={requireAuth(<SearchPage />, true)}/>

				<Route path=":username" element={requireAuth(<><NavPanel /><HUD/></>, true)}>
					<Route index element={requireAuth(<AgentViews />, true)} />
					<Route path=":domain">
						<Route index element={requireAuth(<DomainViews />, true)} />
						<Route path=":entity">
							<Route index element={requireAuth(<EntityViews />, true)} />
							<Route path=":individual">
								<Route index element={requireAuth(<IndividualProfile />, true)}/>
							</Route>
							<Route path=":individual/spatial" element={requireAuth(<IndividualSpatial />, true)}/>
							<Route path=":individual/profile" element={requireAuth(<IndividualProfile />, true)}/>
							<Route path=":individual/about" element={requireAuth(<IndividualProfile />, true)} />
							<Route path=":individual/search" element={requireAuth(<IndividualProfile />, true)}/>
							<Route path=":individual/activity" element={requireAuth(<IndividualProfile />, true)}/>
						</Route>
						<Route path=":entity/graph" element={requireAuth(<EntityViews />, true)} />
						<Route path=":entity/spatial" element={requireAuth(<EntityViews />, true)} />
						<Route path=":entity/profile" element={requireAuth(<EntityViews />, true)} />
						<Route path=":entity/search" element={requireAuth(<EntityViews />, true)}/>
						<Route path=":entity/about" element={requireAuth(<EntityViews />, true)} />
						<Route path=":entity/activity" element={requireAuth(<EntityViews />, true)}/>
						{/*<Route path="activity" element={requireAuth(<DomainActivity />, true)}/>*/}
					</Route>
					<Route path=":domain/graph" element={requireAuth(<DomainViews />, true)} />
					<Route path=":domain/profile" element={requireAuth(<DomainViews />, true)} />
					<Route path=":domain/spatial" element={requireAuth(<DomainViews />, true)} />
					<Route path=":domain/search" element={requireAuth(<DomainViews />, true)}/>
					<Route path=":domain/about" element={requireAuth(<DomainViews />, true)} />
					<Route path=":domain/activity" element={requireAuth(<DomainViews />, true)}/>
					<Route path="profile" element={requireAuth(<AgentViews />, true)} />
					<Route path="graph" element={requireAuth(<AgentViews />, true) } />
					<Route path="spatial" element={requireAuth(<AgentViews />, true)} />
					<Route path="search" element={requireAuth(<AgentViews />, true)}/>
					<Route path="about" element={requireAuth(<AgentViews />, true)} />
					<Route path="activity" element={requireAuth(<AgentViews />, true)}/>
					<Route path="search" element={requireAuth(<SearchPage />, true)}/>
					{/*<Route path="photos" element={requireAuth(<ActivityLog />, true)}/>*/}
					{/*<Route path="videos" element={requireAuth(<ActivityLog />, true)}/>*/}
					{/*<Route path="events" element={requireAuth(<ActivityLog />, true)}/>*/}
					{/*<Route path="community" element={requireAuth(<ActivityLog />, true)}/>*/}
				</Route>

				<Route path="data/:username" element={requireAuth(<><NavPanel /><HUD/></>, true)}>
					<Route index element={requireAuth(<AgentViews />, true)} />
					<Route path=":source">
						<Route index element={requireAuth(<SourceViews />, true)} />
						<Route path=":catalog">
							<Route index element={requireAuth(<CatalogViews />, true)} />
							<Route path=":dataset">
								<Route index element={requireAuth(<DatasetViews />, true)} />
							</Route>
						</Route>
					</Route>
				</Route>
					{/*<Route path=":column"  element={requireAuth(<ColumnViews />, true)}  />*/}
					{/*				<Route path="lineage" element={requireAuth(<DatasetViews />, true)}/>*/}
					{/*				<Route path="quality" element={requireAuth(<DatasetViews />, true)}/>*/}
					{/*				<Route path="usage" element={requireAuth(<DatasetViews />, true)}/>*/}
					{/*				<Route path="relations" element={requireAuth(<DatasetViews />, true)}/>*/}
					{/*			</Route>*/}
					{/*			<Route path="lineage" element={requireAuth(<CatalogViews />, true)}/>*/}
					{/*			<Route path="quality" element={requireAuth(<CatalogViews />, true)}/>*/}
					{/*			<Route path="usage" element={requireAuth(<CatalogViews />, true)}/>*/}
					{/*			<Route path="relations" element={requireAuth(<CatalogViews />, true)}/>*/}
					{/*		</Route>*/}
					{/*		<Route path="lineage" element={requireAuth(<SourceViews />, true)}/>*/}
					{/*		<Route path="quality" element={requireAuth(<SourceViews />, true)}/>*/}
					{/*		<Route path="usage" element={requireAuth(<SourceViews />, true)}/>*/}
					{/*		<Route path="relations" element={requireAuth(<SourceViews />, true)}/>*/}
					{/*	</Route>*/}
					{/*</Route>*/}

				<Route path="settings" element={<AppBase />}>
					<Route path="account" element={<AppBase />} />
					<Route path="security" element={<AppBase />} />
					<Route path="personal" element={<AppBase />} />
					<Route path="privacy" element={<AppBase />} />
					<Route path="timeline" element={<AppBase />} />
					<Route path="followers" element={<AppBase />} />
					<Route path="blocking" element={<AppBase />} />
					<Route path="location" element={<AppBase />} />
					<Route path="language" element={<AppBase />} />
					<Route path="journalism" element={<AppBase />} />
					<Route path="reactions" element={<AppBase />} />
					<Route path="notifications" element={<AppBase />} />
					<Route path="mobile" element={<AppBase />} />
					<Route path="business" element={<AppBase />} />
				</Route>
			</Route>
			<Route element={<AppBase />}>
			{/*<Route element={<PageLayout />}>*/}
				<Route path="about" element={<AboutPage />} />
				<Route path="careers" element={<CareersPage />} />
				<Route path="developers" element={<DevelopersPage />} />
				<Route path="help" element={<HelpPage />} />
				<Route path="support" element={<HelpPage />} />
				<Route path="legal" element={<LegalPage />} />
				<Route path="docs" element={<DocsPage />} />
				<Route path="contact" element={<ContactPage />} />
				<Route path="business" element={<BusinessPage />} />
				<Route path="tos" element={<TOSPage />}>
					<Route path="plain-text" element={<TOSPage />} />
				</Route>
				<Route path="privacy">
				{/*<Route path="privacy" element={<Privacy />}>*/}
					<Route index element={<PrivacyCenter />} />
					<Route path="checkup" element={<PolicyBasics />} />
					<Route path="basics" element={<PolicyBasics />} />
					<Route path="policy" element={<PrivacyPolicy />} />
					<Route path="cookies" element={<CookiesPolicy />} />
					<Route path="data" element={<DataPolicy />} />
					<Route path="ads" element={<AdPolicy />} />
					<Route path="community" element={<CommunityPolicy />} />
				</Route>
			</Route>
			{/*<Route path="contact-us" element={<Contact />} />*/}

			<Route path="/callback/:oauthProvider" element={<Callback />} />
			<Route path="/404" element={<NotFound />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};

export default PageRouter;

				{/*Journalist Resources
Registration
Benefits
Once we have confirmed your information, you may be eligible to receive:
Safety and Protection measures
Stronger security measures that protect your Facebook and Instagram profiles against harassment and potential hacking threats.
Blue Badge Verification
Let people know your Facebook account has been verified.
CrowdTangle Search
Get greater insights into public content across social media.
Updates on special features
Be notified about new features for journalists as they are being released.

// */}
{/*Data Disclaimer*/}
{/*We use the information below to assess whether you are eligible to register as a journalist, to better understand the type of journalists who are interested in this program, and in accordance with the Meta Privacy Policy.*/}
				{/*Role
News Industry Role
Select all the roles that apply.

Reporter

Writer

Editor

Columnist

Commentator

Producer

Radio/TV Announcer

Photographer

Cameraperson

Podcaster

Film/Video Editor

Blogger

Audio/Video Technician

Sound Engineer

Other

Organization
Professional Email
If applicable, enter the email associated with the news organization you work with. If you are a freelancer, enter your professional email address.
Enter email address
Submit
Credentials
Choose one of the methods below to verify your credentials. Learn more.
*/}
