import { getBreadcrumbKinds } from '../../helpers';
import { ContextParams } from '../../types/navigationContextTypes';
import Breadcrumb from './Breadcrumb';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const BreadcrumbsContainer = styled.nav`
	display: flex;
	gap: 0px;
	flex-direction: row;
	justify-items: center;
	justify-content: center;
	align-items: center;
	align-content: center;
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		display: none;
	}
`;

const Breadcrumbs: FunctionComponent = () => {
	const params = useParams<ContextParams>();

	const toRender = getBreadcrumbKinds(params);

	return (
		<BreadcrumbsContainer className={'unselectable'}>
			{toRender.map((kind, i) => {
				return <Breadcrumb
					key={kind}
					kind={kind}
					isTip={i === toRender.length - 1}
				/>
			})}
		</BreadcrumbsContainer>
	);
};

export default Breadcrumbs;
