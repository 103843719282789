import { useGetLiveDataSetsQuery } from '../../api';
// import useUserID from '../../authentication/hooks/useUserID';
import { DataContextParams } from '../../navigation/types/navigationContextTypes';
import { DataSet } from '../../browser/types/dataTypes';
import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isEmpty } from 'common/utils/typeGuards';
import { useParams } from 'react-router-dom';
import useOrgID from "../../authentication/hooks/useOrgID";
import useActiveSourceData from "./useActiveSourceData";

// TODO: figure out why this doesn't type correctly in selector fn below
type QueryResponse = Omit<ReturnType<typeof useGetLiveDataSetsQuery>, 'refetch'>;

type ActiveDatasetName = string | undefined;

const selectActiveDataset = createSelector(
	(res: QueryResponse) => res.data,
	(res: QueryResponse, activeSourceName: ActiveDatasetName) =>
		activeSourceName,
	(data: QueryResponse['data'], activeDatasetName: ActiveDatasetName) =>
		data?.find((source: DataSet) => source.name === activeDatasetName) ??
		null
);
/**
 * @returns RTK Query files related to fetching all available orgs for a user, as well
 * as an activeSource property that corresponds to the files for the user specified
 * in the application URL.
 */
const useActiveDatasetData = () => {
	const params = useParams() as DataContextParams;
	const {activeItem: activeSource} = useActiveSourceData();

	// won't run as long as skipToken is its argument
	const fetchData = useGetLiveDataSetsQuery(
		!!activeSource && params.catalog ? {
			sourceId: activeSource._id,
			catalogName: params.catalog
		} : skipToken,
		{
			selectFromResult: (res) => ({
				...res,
				// important to use a memoizing selector here: selectFromResult
				// uses shallow-equal comparison to determine whether or not a re-render
				// should occur. The only way to get a stable reference for the value of
				// activeItem is to memoize based on inputs.  RTK query takes care of
				// memoizing the value of res.data for us.
				activeItem: selectActiveDataset(
					res,
					params.dataset
				) as null | DataSet,
			}),
		}
	);

	return fetchData;
};

export default useActiveDatasetData;
