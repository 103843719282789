import styled from 'styled-components';

const SlideDown = styled.div`
	&.slide-down-transition-enter {
		max-height: 0;
	}

	&.slide-down-transition-enter-active {
		max-height: 500px;
		transition: all 200ms;
	}

	&.slide-down-transition-exit {
		max-height: 500px;
	}

	&.slide-down-transition-exit-active {
		max-height: 0;
		transition: all 100ms;
	}
`;

export default SlideDown;
