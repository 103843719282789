import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useState
} from "react";
import {useAppDispatch} from "../../app/hooks";
import useActiveOrgData from "../../features/ontology/hooks/useActiveOrgData";
import theme from "../theme/theme";
import {SourceBrowserModal} from "../../features/browser/components/SourceBrowser/SourceBrowser";
import Modal from "./Modal";
import ReactModal from "react-modal";
import StyledSquare from "../StyledSquare";
import Typography from "../text/Typography";
import IconButton from "../buttons/IconButton";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {BasePaper} from '../Paper';
import styled from 'styled-components';
import {
	harvestErrors,
	NonInputLine,
	SubtleTextbox
} from "../inputs/SubtleTextBox";
import SubtleSelect from "../inputs/SubtleSelect";
import {FormResults} from "../Form";
import useCurrentUser from "../../features/authentication/hooks/useUser";
import {useGetOrgsQuery, useUpdateUserMutation} from "../../features/api";
import {SubmitHandler, useForm} from "react-hook-form";
import Button from "../buttons/Button";
import AppModal from "./AppModal";
import useModalType from "../../features/HUD/hooks/useModalType";

export const SourceBody = styled(BasePaper)`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: ${(p) => p.theme.palette.background.paper};
`

interface HUDProps {
	translucent?: boolean;
	borderRadius?: string;
	width?: string | number;
	height?: string | number;
}


export const HUDModalBody = styled.div<HUDProps>`
	background: ${(p) =>
		p.theme.palette.background[
			p.translucent ? 'transparentDark' : 'paper'
		]};
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	box-shadow: ${(p) => p.theme.shadows[4]};
	color: ${(p) => p.theme.palette.lightBaby};
	width: ${(p) => p.width ? p.width : '50'}vw;
	height: ${(p) => p.height ? p.height : '50'}vh;
	display: flex;
	flex-direction: column;

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
	${(p) => p.theme.translucent}
`;

interface StyledModalHeaderProps {
	justifyContent?: string;
	borderBottom?: string;
	padding?: string;
}

export const ModalHeader = styled.header<StyledModalHeaderProps>`
    border-bottom: ${(p) => p.borderBottom ? p.borderBottom : `2px solid ${p.theme.palette.divider}`};
	padding: ${(p) => p.padding ? p.padding : p.theme.spacing(1, 2)};
	display: flex;
	justify-content: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
`;

export const HUDModalHeaderText = styled.h1`
	font-size: 1rem;
	display: flex;
	align-items: center;
`;


interface ModalHeaderProps extends ReactModal.Props {
	handleClose: () => void;
	headingText: string;
}


interface UpdateUserFormValues {
	firstName: string;
	lastName: string;
	username: string;
	defaultOrg: string;
}


const HUDModalHeader: FunctionComponent<ModalHeaderProps> = ({
	headingText,
	handleClose
}) => {
	return (
		<ModalHeader>
			<HUDModalHeaderText>
				<StyledSquare/>
				<span style={{paddingLeft: '.75rem'}}>
					<Typography color={'oldManGray'} variant={'h4'}>
						{headingText}
					</Typography>
				</span>
			</HUDModalHeaderText>
			<IconButton
				onClick={handleClose}
				icon={faTimes}
				aria-label="close source browser"
			/>
		</ModalHeader>
	);
};

const StyledButton = styled(Button)`
	margin-top: ${(p) => p.theme.spacing(1)};
`;



const MetricSettingsModal: FunctionComponent = () => {

	const {modalProps, closeModal} = useModalType();
	const {canEdit} = modalProps;
	const user = useCurrentUser();

	const { data: orgsData } = useGetOrgsQuery({ userId: user._id });

	const realName = `${user.firstName} ${user.lastName}`;

	const { register, formState, resetField, handleSubmit, reset } =
		useForm<UpdateUserFormValues>({
			defaultValues: user,
		});

	// this is needed to update react-hook-form's default values with new
	// values from a successful PUT request.
	useEffect(() => {
		reset(user);
	}, [user, reset]);

	const validatingRegister = useCallback(
		(inputName: keyof UpdateUserFormValues) =>
			register(inputName, {
				required: `${inputName} is required`,
			}),
		[register]
	);

	const [updateUser, mutationResults] = useUpdateUserMutation();

	const onSubmit: SubmitHandler<UpdateUserFormValues> = (formVals) => {
		const { __tag, ...userProps } = user;

		updateUser({
			userId: userProps._id,
			body: Object.assign(userProps, formVals),
		});
	};

	if (canEdit && user) {
		return (
			<AppModal
				label={"Edit metric profile"}
				isOpen={true}
				isDirty={formState.isDirty}
				onClose={closeModal}
				onSubmit={handleSubmit(onSubmit)}
				canEdit={canEdit}>
				<SubtleTextbox
					{...validatingRegister('firstName')}
					label="First Name"
					isDirty={Boolean(formState.dirtyFields.firstName)}
					onReset={() => resetField('firstName')}
					error={harvestErrors(
						'firstName',
						formState,
						mutationResults
					)}
					showLabel
				/>
				<SubtleTextbox
					{...validatingRegister('lastName')}
					label="Last Name"
					isDirty={Boolean(formState.dirtyFields.lastName)}
					onReset={() => resetField('lastName')}
					error={harvestErrors(
						'lastName',
						formState,
						mutationResults
					)}
					showLabel
				/>
				<SubtleTextbox
					{...validatingRegister('username')}
					showLabel
					label="Username"
					isDirty={Boolean(formState.dirtyFields.username)}
					onReset={() => resetField('username')}
					error={harvestErrors(
						'username',
						formState,
						mutationResults
					)}
				/>
				<SubtleSelect
					label="Default Org"
					onReset={() => resetField('defaultOrg')}
					isDirty={Boolean(formState.dirtyFields.defaultOrg)}
					{...validatingRegister('defaultOrg')}
				>
					{orgsData &&
					orgsData.map((acct) => (
						<option key={acct._id} value={acct.name}>
							{acct.label}
						</option>
					))}
				</SubtleSelect>
				{/* {user.jobTitle && (
						<SubtleTextbox
							{...validatingRegister('jobTitle')}
							label="Last Name"
							isDirty={Boolean(formState.dirtyFields.jobTitle)}
							onReset={() => resetField('jobTitle')}
							error={harvestErrors(
								'jobTitle',
								formState,
								mutationResults
							)}
							showLabel
						/>
					)}
					{user.department && (
						<SubtleTextbox
							{...validatingRegister('department')}
							label="Deparment"
							isDirty={Boolean(formState.dirtyFields.department)}
							onReset={() => resetField('department')}
							error={harvestErrors(
								'department',
								formState,
								mutationResults
							)}
							showLabel
						/>
					)}
					<NonInputLine
						label="Do Not Mail"
						value={String(user.doNotMail)}
					/> */}
					<NonInputLine
						label="Joined"
						value={user.memberSince ? user.memberSince : 'unknown'} />
					<FormResults
					{...mutationResults}
					validationErrors={formState.errors}
					onSuccessDelay={2000}
				/>
			</AppModal>
		);
	} else {
		return null
	}
};

export default MetricSettingsModal;
