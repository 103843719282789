import { useAppDispatch } from '../../app/hooks';
import Button from '../buttons/Button';
import {
	FormHeading,
	ModalFormBody,
	FormResults,
} from '../Form';
import IconButton from '../buttons/IconButton';
import Textbox from '../inputs/TextBox';
import {harvestErrors, SubtleTextbox} from "../inputs/SubtleTextBox";
import useModalType from '../../features/HUD/hooks/useModalType';
import { setModalType } from '../../features/HUD/state/HUDSlice';
import {
	useCreateEntityMutation,
	useUpdateUserMutation
} from '../../features/api';
import useActiveDomainData from '../../features/ontology/hooks/useActiveDomainData';
import React, {FunctionComponent, useCallback, useEffect, useRef} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import AppModal from "./AppModal";
import {BaseEntity} from "../../features/ontology/types/entityTypes";

const StyledIconButton = styled(IconButton)`
	position: absolute;
	top: ${(p) => p.theme.spacing(2)};
	right: ${(p) => p.theme.spacing(2)};
`;

interface EntityCreationFormProps {
	domainId: number;
	isOpen: boolean;
	onClose: () => void;
	canEdit: boolean;
}

interface CreateEntityFormValues extends BaseEntity {}

// Accept domainId as a prop to avoid needing complicated route matching in tests for this form
export const EntityCreationFormBase: FunctionComponent = () => {

	const {modalProps, closeModal} = useModalType();
	const {domainId, canEdit} = modalProps;

	const { activeItem } = useActiveDomainData();


	const {register, formState, resetField, handleSubmit, reset} = useForm<CreateEntityFormValues>();

	// const [createDomain, mutationResult] = useCreateEntityMutation({
	// 	selectFromResult: (res) => ({
	// 		isLoading: res.isLoading,
	// 		isSuccess: res.isSuccess,
	// 		error: res.error,
	// 		isError: res.isError,
	// 	}),
	// });

	// const syncMutationResult = useRef<any>(null);
	// const appDispatch = useAppDispatch();
	// const modalType = useModalType();

	// const creationFormOpen = modalType === 'entityCreationForm';


	useEffect(() => {
		if (formState.isDirty) {
			reset();
		}
	}, [formState, reset]);

	const validatingRegister = useCallback(
		(inputName: keyof CreateEntityFormValues) =>
			register(inputName, {
				required: `${inputName} is required`,
			}),
		[register]
	);


	// const handleClose = () => {
	// 	if (syncMutationResult.current) {
	// 		syncMutationResult.current.unsubscribe();
	// 		syncMutationResult.current = null;
	// 	}
	// 	appDispatch(setModalType(null));
	// };

		const [createEntity, mutationResults] = useCreateEntityMutation();

	const onSubmit: SubmitHandler<CreateEntityFormValues> = (formVals) => {

		createEntity({
			domainId: domainId,
			...formVals
		});
	};

	// const onSubmit: SubmitHandler<CreateEntityFormValues> = (
	// 	{ plural, singular },
	// 	e
	// ) => {
	// 	e?.preventDefault();
	//
	// 	if (domainId === null) {
	// 		console.error(
	// 			'Attempted to submit entity creation form when no active user was matched in URL!'
	// 		);
	// 		return;
	// 	}
	//
	// 	syncMutationResult.current = createDomain({
	// 		singular, plural, domainId,
	// 	});
	// };

	return (
		<AppModal
			label={"Create Entity"}
			isOpen={true}
			isDirty={formState.isDirty}
			onClose={closeModal}
			onSubmit={handleSubmit(onSubmit)}
			canEdit={canEdit}>
			<SubtleTextbox
				{...validatingRegister('singular')}
				isDirty={Boolean(formState.dirtyFields.singular)}
				onReset={() => resetField('singular')}
								showLabel
				error={harvestErrors('singular', formState, mutationResults)}
				label="Singular"
			/>
			<SubtleTextbox
				{...validatingRegister('plural')}
				isDirty={Boolean(formState.dirtyFields.plural)}
				onReset={() => resetField('plural')}
				showLabel
				error={harvestErrors('plural', formState, mutationResults)}
				label="Plural"
			/>
			<FormResults
				{...mutationResults}
				validationErrors={formState.errors}
				onSuccess={closeModal}
				onSuccessDelay={2000}
			/>
		</AppModal>
	);
};

const EntityCreationForm: FunctionComponent = () => {

	return <EntityCreationFormBase />;
};

export default EntityCreationForm;
