import Heading from 'common/text/Heading';
import styled from 'styled-components';

export const StyledGraphContainer = styled.div`
	overflow: hidden;
`;

// export const StyledHeading = styled(Heading)`
// 	padding-top: ${(p) => p.theme.navHeight}px;
// 	color: ${(p) => p.theme.palette.darkerBaby};
// 	text-align: center;
// 	position: fixed;
// 	top: 50%;
// `;

export const StyledHeading = styled(Heading)`
	position: absolute;
	z-index: ${(p) => p.theme.zIndex.graphScreen};
	color: ${(p) => p.color ? p.color : p.theme.palette.text.primary};
	top: 50%;
	left: 50%;
	transform: translateX(-50%);
`;


export const StyledSquare = styled.div`
	width: ${(p) => p.theme.spacing(3)};
	height: ${(p) => p.theme.spacing(3)};
	background-color: ${(p) => p.theme.palette.primary.main};
`;


export const TinySquare = styled.div`
	width: ${(p) => p.theme.spacing(1)};
	height: ${(p) => p.theme.spacing(1)};
	background-color: ${(p) => p.theme.palette.primary.main};
`;
