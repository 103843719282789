import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleRadio from "./SubtleRadio";
import Toggle from 'react-toggle'
import {harvestErrors} from "./SubtleTextBox";
import Typography from "../text/Typography";

const IsRequiredInput: FunctionComponent<ActionFieldProps> = (
    {
        defaultValue,
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {

    console.log(formState)
    return <>
        <Typography color='oldManGray' variant='input' htmlFor={'isRequired'}>
            Is this attribute required?
        </Typography>
        <Toggle
            id={'isRequired'}
            defaultChecked={defaultValue}
            name='isRequired'
            value={'true'}
        />
    </>;
    // <SubtleRadio
    //     {/*{...validatingRegister('defaultValue')}*/}
    //     {...validatingRegister('isRequired')}
    //     showLabel
    //     name='isRequired'
    //     options={[
    //         {name: true, label: 'Yes'},
    //         {name: false, label: 'No'},
    //     ]}
    //     validatingRegister={validatingRegister}
    //     alignItems={'center'}
    //     justifyContent={'space-between'}
    //     inline={true}
    //     label="Required"
    //     isDirty={Boolean(formState.dirtyFields.isRequired)}
    //     onReset={() => resetField('isRequired')}
    //     error={harvestErrors('isRequired', formState, mutationResults)}
    // />;
}

export default IsRequiredInput;
