import { joinOnSeparator, joinOnSlash, dataJoinOnSeparator, dataJoinOnSlash } from '../api/urlBuilder';
import { AppError } from '../errorHandling/types/errorTypes';
import {
	ContextParams,
	ContextLadder,
	contextLadder,
	ContextLadderMap,
	ContextParamLadder,
	ContextLevels,
	DataContextParams,
	DataContextLadder,
	dataContextLadder,
	DataContextLadderMap,
	DataContextParamLadder,
	DataContextLevels,
} from './types/navigationContextTypes';
import { isAppError } from 'common/utils/typeGuards';
import { hasOwnProperty } from 'common/utils/typeUtils';
import { ViewMode } from 'features/compositeViews/types';

export const corruptURLError = (params: ContextParams) =>
	new AppError(
		'App navigation has reached a corrupt state--a nested context level ' +
			'has been specified when its ancestor properties have not been matched. ' +
			'Here are the route params at the time of the error: \n' +
			`${JSON.stringify(params)}`
	);

/** Validates that the current app URL is well-constructed, and determines
 * the depth inside the Futuremodel ontology tree that is represented
 * by the current URL.
 */

export const URLParamsToContextArray = (
	params: ContextParams
): ContextParamLadder | AppError => {
	let hasMisfired = false;
	let error = null;

	const ctx = contextLadder.reduce((acc, contextLevel) => {
		if (!hasOwnProperty(params, contextLevel)) {
			hasMisfired = true;
			return acc;
		}

		// If one ontology parameter has been matched in the URL, we require that
		// every parameter HIGHER in the ontology tree also have an active match.  Otherwise,
		// the current URL is semantically invalid.
		if (hasMisfired) {
			error = corruptURLError(params);
			return acc;
		}

		// TODO: try to fix this type problem if you ever really really hate yourself
		// @ts-ignore
		acc.push(params[contextLevel]);
		return acc;
	}, []);

	return error ? error : ctx;
};

export const contextIdxFromContextName = (ctxName: ContextLadder[number]) =>
	ContextLadderMap[ctxName];

export const contextNameFromContextIdx = (ctxIdx: number) =>
	contextLadder[ctxIdx];

export const isViewMode = (urlElement: string): urlElement is ViewMode =>
	urlElement === 'profile'
	|| urlElement === 'graph'
	||  urlElement === 'spatial'
	||  urlElement === 'data'
	||  urlElement === 'lineage'
	||  urlElement === 'quality';


export const truncateDataLinkURL = (
	contextParams: DataContextParams,
	targetContextLevel: DataContextLadder[number]
) => {
	const dataContextParamLadder = URLParamsToDataArray(contextParams);
	console.log(dataContextParamLadder)
	if (isAppError(dataContextParamLadder)) {
		return dataContextParamLadder;
	}

	const outsideOfBrowser = dataContextParamLadder.length === 0;

	if (outsideOfBrowser) {
		if (targetContextLevel !== 'username') {
			return new AppError(
				'truncateLinkURL was asked to replace a non-user URL parameter when no ontology parameters were active in the URL'
			);
		}

		return (name: string) => joinOnSeparator('/')(name);
	}

	const maxCtxIdx = dataContextIdxFromContextName(targetContextLevel);

	const reused = dataContextParamLadder.slice(0, maxCtxIdx);

	// return (name: string) =>
	// 	joinOnSeparator('/')('org', ...reused, name, currentViewMode);

	// if (targetContextLevel === 'individual') {
	// 	return (name: string) =>
	// 		joinOnSlash('/', ...reused, 'individual', name);
	// }

	//  note the LEADING slash we pass to joinOnSeparator--we want absolute paths.
	return (name: string) => joinOnSlash('/data/', ...reused, name);
	// if (currentViewMode) {
	// remove the viewMode portion of current URL
	// 	const previousViewMode = splitIdentifiers.pop() as string;

	// 	if (!isViewMode(previousViewMode)) {
	// 		return new AppError(
	// 			"truncateLinkURL expects all routes inside of an ontology tree to end in either 'profile' or 'graph'"
	// 		);
	// 	}
	// }

	// TODO: temporary fix to deal with misrouting when on user profile page
	// if (splitIdentifiers[splitIdentifiers.length - 1] === 'profile') {
	// 	splitIdentifiers.pop();
	// }

	// let replaceIdx = 0;

	// for (let i = 0; i <= maxCtxIdx; i++) {
	// 	while (
	// 		splitIdentifiers[replaceIdx] !== contextParams[contextLadder[i]] &&
	// 		replaceIdx < splitIdentifiers.length
	// 	) {
	// 		++replaceIdx;
	// 	}

	// 	if (i < maxCtxIdx) {
	// 		++replaceIdx;
	// 	}
	// }

	// if (replaceIdx >= splitIdentifiers.length) {
	// 	return corruptURLError(contextParams);
	// }

	// return (nextName: string) => {
	// 	splitIdentifiers.splice(replaceIdx, Number.POSITIVE_INFINITY, nextName);

	// 	if (currentViewMode) {
	// 		splitIdentifiers.push(currentViewMode);
	// 	}
	// 	return splitIdentifiers.join('/');
	// };
};

/**
 * Generate a new URL from the existing URL that is truncated at the appropriate
 * ontology tree depth, with the identifier at the specified depth replaced.
 * See tests for examples.
 */
export const truncateLinkURL = (
	contextParams: ContextParams,
	targetContextLevel: ContextLadder[number]
) => {
	const contextParamLadder = URLParamsToContextArray(contextParams);

	if (isAppError(contextParamLadder)) {
		return contextParamLadder;
	}

	const outsideOfOntology = contextParamLadder.length === 0;

	if (outsideOfOntology) {
		if (targetContextLevel !== 'username') {
			return new AppError(
				'truncateLinkURL was asked to replace a non-user URL parameter when no ontology parameters were active in the URL'
			);
		}

		return (name: string) => joinOnSeparator('/')(name);
	}

	const maxCtxIdx = contextIdxFromContextName(targetContextLevel);

	const reused = contextParamLadder.slice(0, maxCtxIdx);

	// return (name: string) =>
	// 	joinOnSeparator('/')('org', ...reused, name, currentViewMode);

	if (targetContextLevel === 'individual') {
		return (name: string) =>
			joinOnSlash('/', ...reused, name);
	}

	//  note the LEADING slash we pass to joinOnSeparator--we want absolute paths.
	return (name: string) => joinOnSlash('/', ...reused, name);
	// if (currentViewMode) {
	// remove the viewMode portion of current URL
	// 	const previousViewMode = splitIdentifiers.pop() as string;

	// 	if (!isViewMode(previousViewMode)) {
	// 		return new AppError(
	// 			"truncateLinkURL expects all routes inside of an ontology tree to end in either 'profile' or 'graph'"
	// 		);
	// 	}
	// }

	// TODO: temporary fix to deal with misrouting when on user profile page
	// if (splitIdentifiers[splitIdentifiers.length - 1] === 'profile') {
	// 	splitIdentifiers.pop();
	// }

	// let replaceIdx = 0;

	// for (let i = 0; i <= maxCtxIdx; i++) {
	// 	while (
	// 		splitIdentifiers[replaceIdx] !== contextParams[contextLadder[i]] &&
	// 		replaceIdx < splitIdentifiers.length
	// 	) {
	// 		++replaceIdx;
	// 	}

	// 	if (i < maxCtxIdx) {
	// 		++replaceIdx;
	// 	}
	// }

	// if (replaceIdx >= splitIdentifiers.length) {
	// 	return corruptURLError(contextParams);
	// }

	// return (nextName: string) => {
	// 	splitIdentifiers.splice(replaceIdx, Number.POSITIVE_INFINITY, nextName);

	// 	if (currentViewMode) {
	// 		splitIdentifiers.push(currentViewMode);
	// 	}
	// 	return splitIdentifiers.join('/');
	// };
};

export const getPluralKind = (kind: ContextLevels) =>
	kind === 'username'
		? 'orgs'
		: kind === 'domain'
		? 'domains'
		: kind === 'entity'
		? 'entities'
		: 'attributes';


export type AllKinds = ContextLevels | DataContextLevels;

export const getPluralLabel = (kind: AllKinds) =>
	kind === 'username'
		? 'organizations'
		: kind === 'domain'
		? 'domains'
		: kind === 'entity'
		? 'entities'
		: kind === 'attribute'
		? 'attributes'
		: kind === 'individual'
		? 'individuals'
					: kind === 'source'
						? 'sources'
						: kind === 'catalog'
							? 'catalogs'
							: kind === 'dataset'
								? 'datasets'
								: 'columns';
;


export const getBreadcrumbKinds = (params: ContextParams): ContextLevels[] => {
	if (params.username === undefined) {
		return ['username'];
	}

	// Individual breadcrumbs will handle the error case for this function.
	const paramArray = URLParamsToContextArray(params) as ContextParamLadder;

	// Never want to go deeper than 'entity'...
	const paramSlice = paramArray.slice(0, 3);

	const kindArray = paramSlice.map((_, i) => contextLadder[i]);

	// if we have an active entity (or more), we don't want to render a lookahead crumb,
	// so just return.
	if (typeof params.entity === 'string') {
		return kindArray;
	}

	// otherwise, continue one breadcrumb deeper than current depth
	kindArray.push(contextLadder[paramArray.length]);

	return kindArray;
};


export const dataContextIdxFromContextName = (ctxName: DataContextLadder[number]) =>
	DataContextLadderMap[ctxName];

export const dataContextNameFromContextIdx = (ctxIdx: number) =>
	dataContextLadder[ctxIdx];


export const URLParamsToDataArray = (
	params: DataContextParams
): DataContextParamLadder | AppError => {
	let hasMisfired = false;
	let error = null;

	const ctx = dataContextLadder.reduce((acc, dataContextLevel) => {
		if (!hasOwnProperty(params, dataContextLevel)) {
			hasMisfired = true;
			return acc;
		}

		// If one ontology parameter has been matched in the URL, we require that
		// every parameter HIGHER in the ontology tree also have an active match.  Otherwise,
		// the current URL is semantically invalid.
		if (hasMisfired) {
			error = corruptURLError(params);
			return acc;
		}

		// TODO: try to fix this type problem if you ever really really hate yourself
		// @ts-ignore
		acc.push(params[dataContextLevel]);
		return acc;
	}, []);

	return error ? error : ctx;
};


export const getDataCrumbKinds = (params: DataContextParams): DataContextLevels[] => {
	if (params.username === undefined) {
		return ['username'];
	}

	// Individual breadcrumbs will handle the error case for this function.
	const paramArray = URLParamsToDataArray(params) as DataContextParamLadder;
	console.log(paramArray)
	// Never want to go deeper than 'entity'...
	const paramSlice = paramArray.slice(0, 4);
	console.log(paramSlice)

	const kindArray = paramSlice.map((_, i) => dataContextLadder[i]);

	// if we have an active entity (or more), we don't want to render a lookahead crumb,
	// so just return.
	if (typeof params.dataset === 'string') {
		return kindArray;
	}

	// otherwise, continue one breadcrumb deeper than current depth
	kindArray.push(dataContextLadder[paramSlice.length]);

	return kindArray;
};
