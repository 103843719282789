import { List } from '../../List';
import { BaseAnswer } from '../types/questionTypes';
import AnswerItem from '../../List/AnswerItem';
import CreateAnswerItem from './CreateAnswerItem';
import { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';

const StyledIndentedList = styled(List)`
	padding-left: ${(p) => p.theme.spacing(1)};
`;

interface AnswerListProps {
	answers: BaseAnswer[];
	questionId: number;
}

// Acceptable to pass a prop whose value is a non-primitive here since
// RTK Query handles memoization for us.  I.e. if we ever wanted to memoize this
// component, it would work b/c the same array identity would get passed
// until the query cache was invalidated.
const AnswerList: FunctionComponent<AnswerListProps> = ({
	answers,
	questionId,
}) => {
	//  if any answers are accepted, display them at the top of the list.
	const sorted = useMemo(
		() =>
			answers.slice().sort((a, b) => {
				if (b.isAccepted && !a.isAccepted) {
					return 1;
				}

				if (!b.isAccepted && a.isAccepted) {
					return -1;
				}

				return 0;
			}),
		[answers]
	);

	const answerExists = answers.some((a) => a.isAccepted);

	return (
		<StyledIndentedList>
			{!answerExists && <CreateAnswerItem questionId={questionId} />}
			{sorted.map((a) => (
				<AnswerItem {...a} key={a._id} answerExists={answerExists} />
			))}
		</StyledIndentedList>
	);
};

export default AnswerList;
