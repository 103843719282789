import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleRadio from "./SubtleRadio";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Toggle from 'react-toggle'
import {harvestErrors} from "./SubtleTextBox";
import Typography from "../text/Typography";
import FlexContainer from "../FlexContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const IsDisplayInput: FunctionComponent<ActionFieldProps> =(
    {
        defaultValue,
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
    ) => {
    return <FlexContainer>
        <Typography color='oldManGray' variant='input' htmlFor={'isDisplay'}>
             Use attribute as display?
        </Typography>
        <Toggle
            id={'isDisplay'}
            icons={{checked: <FontAwesomeIcon icon={faEye} />, unchecked: <FontAwesomeIcon icon={faEyeSlash} />}}
            defaultChecked={defaultValue}
            name='isDisplay'
            value={'true'}
        />
    </FlexContainer>;
}

export default IsDisplayInput;
