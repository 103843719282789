import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";

const LastNameInput: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleTextbox
        {...validatingRegister('lastName')}
        label="Last name"
        isDirty={Boolean(formState.dirtyFields.lastName)}
        onReset={() => resetField('lastName')}
        error={harvestErrors(
            'lastName',
            formState,
            mutationResults
        )}
        showLabel
    />;
}

export default LastNameInput;
