import {
	excludeFrom,
	dedupeOnId,
	pipe,
} from '../../common/utils/functionUtils';
import { BaseUser } from '../authentication/types/userTypes';
import {
	OrgAuthorityLevel,
	CombinedOrgMembers,
	CombinedDomainMembers,
	DomainAuthorityLevel,
} from './types/GovernanceTypes';

export const composeName = (member: BaseUser) => {

	if (member.firstName && member.lastName) {
		return 	`${member.firstName} ${member.lastName}`;
	}

	if (member.username) {
		return member.username;
	}

	if (member.email) {
		return member.email;
	}

	return '';
}

export const getAcctUsersNotInDomain = (
	acctUsers: CombinedOrgMembers,
	domainUsers: CombinedDomainMembers
) => {
	//  explicitly declare these array types so that the compiler will catch it
	// if type of Combined{Org,Domain}Members ever changes.
	const acctKeys: Array<keyof CombinedOrgMembers> = [
		'admins',
		'governors',
		'members',
		'publishers',
		'rightsHolders',
		'engineers',
		'reporters',
		'funders',
		'maintainers',
		'contacts',
	];

	const domainKeys: Array<keyof CombinedDomainMembers> = [
		'stewards',
		'members',
	];

	const allAcctUsers = acctKeys.reduce(
		(acc, next) => acc.concat(acctUsers[next]),
		[] as BaseUser[]
	);

	const allDomainUsers = domainKeys.reduce(
		(acc, next) => acc.concat(domainUsers[next]),
		[] as BaseUser[]
	);

	return excludeFrom(allAcctUsers, allDomainUsers);
};

export const filterDomainMembers = (
	authorityLevel: DomainAuthorityLevel,
	combinedDomainMembers: CombinedDomainMembers,
	combinedOrgMembers: CombinedOrgMembers
) => {
	const { members: domainMembers, stewards: domainStewards } =
		combinedDomainMembers;

	if (authorityLevel === 'steward') {
		return excludeFrom(domainMembers, domainStewards);
	}

	// We can add a user to domain members if they are part of the org, but
	// not currently included at any authority level in the domain.
	return pipe(
		getAcctUsersNotInDomain(combinedOrgMembers, combinedDomainMembers),
		dedupeOnId
	);
};

export const filterOrgMembers = (
	authorityLevel: OrgAuthorityLevel,
	combinedMembers: CombinedOrgMembers
) => {
	const { governors, members, admins, contacts, engineers, funders, maintainers, publishers, reporters, rightsHolders } = combinedMembers;

	if (authorityLevel === 'admin') {
		return pipe(
			excludeFrom([...governors, ...members], admins),
			dedupeOnId
		);
	}

	if (authorityLevel === 'governor') {
		return excludeFrom(members, governors);
	}

	if (authorityLevel === 'contact') {
		return excludeFrom(members, contacts);
	}

	if (authorityLevel === 'engineer') {
		return excludeFrom(members, engineers);
	}

		if (authorityLevel === 'funder') {
		return excludeFrom(members, funders);
	}
		if (authorityLevel === 'maintainer') {
		return excludeFrom(members, maintainers);
	}

		if (authorityLevel === 'publisher') {
		return excludeFrom(members, publishers);
	}

		if (authorityLevel === 'reporter') {
		return excludeFrom(members, reporters);
	}

		if (authorityLevel === 'rightsHolder') {
		return excludeFrom(members, rightsHolders);
	}



	return [];
};
