import { isNumber } from 'common/utils/typeGuards';
import { hasOwnProperty } from 'common/utils/typeUtils';
import {UserContext} from "../../authentication/types/userTypes";
import {BaseEntity, EntityViewFlags} from "./entityTypes";

// export type Individual = Record<string, any>;

export interface Individual extends EntityViewFlags, Record<string, any> {
	hasIdentities?: boolean;
	hasRelations?: boolean;
	hasQuantities?: boolean;
	hasCategories?: boolean;
	hasLocations?: boolean;
	hasEvents?: boolean;
	hasImages?: boolean;
	hasVideos?: boolean;
	hasStart?: boolean;
	hasEnd?: boolean;
	userContext?: UserContext;
	entityId?: number
}

export interface LongLatIndividual extends Individual {
	lat: number;
	long: number;
}

export interface KnownIndividual extends Individual {
	_id: number;
}

export const isLongLatIndividual = (v: Individual): v is LongLatIndividual =>
	hasOwnProperty(v, 'lat') && hasOwnProperty(v, 'long');

export const isKnownIndividual = (ind: Individual): ind is KnownIndividual =>
	hasOwnProperty(ind, '_id') && isNumber(ind._id);

export interface GetIndividualsParams {
	entityId: number;
	identifier?: string;
}

export type GetIndividualsResponse = Individual[];

export interface GetIndividualParams {
	entityId: number;
	identifier: string;
}

export interface GetIndividualResponse {
	meta: {
		status: number;
	};
	response: Individual;
}

export interface CreateIndividualParams {
	singular: string;
	plural: string;
	entityId: number;
}

export interface CreateIndividualResponse extends KnownIndividual {}

export interface FollowIndividualParams {
	individualId: number;
}

export interface FollowIndividualResponse {}

export interface UnfollowIndividualParams {
	individualId: number;
}

export interface UnfollowIndividualResponse {}


export interface UpdateIndividualParams {
	individualId: number;
	body: {
		singular: string;
		plural: string;
		definition: string | null;
	};
}

export interface UpdateIndividualResponse {}

// export interface DeleteImportedIndividualParams {
// 	entityId: number;
// 	domainId: number;
// 	isImport: true;
// }

export interface DeleteIndividualParams {
	individualId: number;
	// isImport: false;
}
//
// export type DeleteEntityParams =
// 	| DeleteImportedEntityParams
// 	| DeleteNormalEntityParams;

// export const isDeleteImportParams = (
// 	p: DeleteIndividualParams
// ): p is DeleteImportedIndividualParams => p.isImport;

export interface DeleteIndividualResponse {}
