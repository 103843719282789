import Typography from "./Typography";
import {FunctionComponent} from "react";

const OrgName: FunctionComponent<{org: any, styles?: any}> = ({org, styles}) => {
    return <Typography
        variant='h6'
        id={`org-${org._id}`}
        style={styles}>
        {org.label ? org.label : org.name}
    </Typography>;
}

export default OrgName;
