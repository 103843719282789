import React, {FunctionComponent} from "react";
import {temporalUnits} from "../../features/actions/createActivity/createActivityHelpers";
import SubtleSelect from "./SubtleSelect";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {harvestErrors} from "./SubtleTextBox";

const TemporalUnitSelect: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister
    }
    ) => {
    //     Select a temporal unit to use in the creation of
    //                 this attribute:

    //            aria-errormessage={genErrorIdFromLabel('temporalUnit')}
    //             aria-invalid={formFieldHasErrors(
    //                 'temporalUnit',
    //                 formState
    //             )}
    return <SubtleSelect
        inline={true}
        label='Temporal unit'
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('temporalUnit')}
        isDirty={Boolean(formState.dirtyFields.temporalUnit)}
        {...validatingRegister('temporalUnit')}
    >
        {temporalUnits.map((unit) => (
            <option value={unit} style={{textTransform: 'capitalize'}} key={unit}>
                {unit}
            </option>
        ))}
    </SubtleSelect>;
}

export default TemporalUnitSelect;
