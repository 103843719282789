import { filterAttrsForYMenu } from '../../LineChart/LineChartModule/helpers';
import {
	StyledAttributeList,
	StyledVizPaper,
	StyledPoint,
	StyledVizWithControls,
	StyledSubmoduleHeader, StyledSubmoduleContent,
} from '../../../../features/profile/EntityProfile/components/styledComponents';
import {faCheck, faGear} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'app/hooks';
import FlexContainer from 'common/FlexContainer';
import Heading from 'common/text/Heading';
import { List, ListItemContent, ListItem, ListItemAvatar } from 'common/List';
import Typography from 'common/text/Typography';
import theme from 'common/theme/theme';
import { hasOwnProperty } from 'common/utils/typeUtils';
import { setDrawerIndividual } from 'features/HUD/state/HUDSlice';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import CategoryMeta from 'features/dataPreparation/CategoryMeta';
import { cardinalityLessThan } from 'features/ontology/helpers/attributeHelpers';
import useActiveIndividualsMeta from 'features/ontology/hooks/useActiveIndividualsMeta';
import { isCategory } from 'features/ontology/typeGuards/attributeGuards';
import LinkedInShareButton from 'common/buttons/LinkedInShareButton';
import Scatterplot from '../ScatterplotCore';
import { distributeByCategory } from 'common/viz/Scatterplot/ScatterplotCore/helpers';
import { PointDrawFn } from 'common/viz/types';
import {MouseEventHandler, useMemo, useLayoutEffect, useState} from 'react';
import styled from 'styled-components';
import useElementSize from "../../../hooks/useSize";
import {BaseAttribute} from "../../../../features/ontology/types/attributeTypes";
import IconButton from "../../../buttons/IconButton";

const StyledChartGrid = styled.div<{ rows: number; cols: number }>`
	overflow: hidden;
	display: grid;
	grid-template-columns: repeat(${(p) => p.cols}, 1fr);
	grid-template-rows: repeat(${(p) => p.rows}, minmax(0, 1fr));
	width: 100%;
	height: 100%;
	flex-grow: 1;
	grid-gap: ${(p) => p.theme.spacing(1)};
	padding: ${(p) => p.theme.spacing(0.5)};
`;

export const SP_POINT_TEST_ID = 'scatterplot-module-test-id';

export const SP_POPOVER_TEST_ID = 'attr-scatter-popover-test-id';

const svgId = 'scatterplot-for-capture';

const colors = [
	theme.palette.primary.main,
	theme.palette.secondary.main,
	theme.palette.error.main,
	theme.palette.info.main,
];

const ScatterplotModule = () => {
	const appDispatch = useAppDispatch();
	const [size, setSizeTarget] = useElementSize();
	const [settingsOpen, setSettingsOpen] = useState(false);

	const {
		getAllScatterplotY,
		appendScatterplotY,
		setScatterplotY,
		removeScatterplotY,
		getActiveAttributeName,
		getAllScatterplotCat,
		appendScatterplotCat,
		removeScatterplotCat,
	} = useEntitySearchParams();

	const yAttrNames = getAllScatterplotY();

	const categoryAttrNames = getAllScatterplotCat();

	const colorMap = [...yAttrNames, ...categoryAttrNames].reduce(
		(acc, name, i) => {
			acc[name] = colors[i % colors.length];
			return acc;
		},
		{} as Record<string, string>
	);

	const xAttrName = getActiveAttributeName();

	const makeMenuHandler =
		(
			attrName: string,
			existingNames: string[],
			append: (x: string) => void,
			remove: (x: string) => void
		): MouseEventHandler =>
		() => {
			if (existingNames.includes(attrName)) {
				return remove(attrName);
			}

			return append(attrName);
		};

	const { preparedData, ...individualsLoadState } =
		useActiveIndividualsMeta();

	const { charts, rows, cols, colHeadings, rowHeadings, xMin, xMax, yMax } =
		useMemo(() => {
			if (preparedData && xAttrName && yAttrNames) {
				const categoryMetas = categoryAttrNames.map(
					(name) => preparedData.attributeFields[name] as CategoryMeta
				);

				const recoveryGuide = categoryMetas.map((m) =>
					Array.from(m.uniqueValues.values()).sort()
				);

				const { charts } = distributeByCategory(
					preparedData,
					xAttrName,
					yAttrNames,
					categoryAttrNames,
					recoveryGuide
				);

				const colHeadings = recoveryGuide[1] ?? [];

				const rowHeadings = recoveryGuide[0] ?? [];

				const rows =
					recoveryGuide.length === 0 ? 1 : rowHeadings.length;

				const cols =
					recoveryGuide.length === 0 || recoveryGuide.length === 1
						? 1
						: colHeadings.length;

				// get overall min/max for all the data
				// TODO: this isn't great from a DX perspective...
				const { xMin, xMax, yMax } = charts.reduce((acc, nextChart) => {
					nextChart.forEach((subArr) =>
						subArr.forEach(({ x, y }) => {
							if (acc.xMax === undefined || acc.xMax < x) {
								acc.xMax = x;
							}

							if (acc.yMax === undefined || acc.yMax < y) {
								acc.yMax = y;
							}

							if (acc.xMin === undefined || acc.xMin > x) {
								acc.xMin = x;
							}
						})
					);

					return acc;
				}, {} as { xMin: number; yMax: number; xMax: number });
				return {
					charts,
					rows,
					cols,
					recoveryGuide,
					colHeadings,
					rowHeadings,
					xMin,
					xMax,
					yMax,
				};
			}

			return {
				charts: [],
				rows: 1,
				cols: 1,
				recoveryGuide: [],
				colHeadings: [],
				rowHeadings: [],
				xMin: 0,
				xMax: 0,
				yMax: 0,
			};
		}, [preparedData, xAttrName, categoryAttrNames, yAttrNames]);

	const yMenuAttrs =
		preparedData && xAttrName
			? filterAttrsForYMenu(xAttrName, preparedData.attributes, [
					'quantity',
			  ])
			: null;

	const defaultYAttr = yMenuAttrs ? yMenuAttrs[0] : null;

	const activeAttribute = !!preparedData && !!preparedData.attributes ?
		preparedData.attributes.filter((a) => a.name === xAttrName)[0]
	: {singular: xAttrName} as BaseAttribute;

	useLayoutEffect(() => {
		if (defaultYAttr && yAttrNames.length === 0) {
			setScatterplotY(defaultYAttr.name);
		}
	}, [defaultYAttr, yAttrNames.length, setScatterplotY]);

	const drawPoint: PointDrawFn = ({
		drawX,
		drawY,
		originalIdx,
		yAttr,
		xAttr,
	}) => (
		<StyledPoint
			hoverScaleFactor={1.5}
			cx={drawX}
			cy={drawY}
			key={`${xAttr}-${yAttr}-${originalIdx}`}
			fill={colorMap[yAttr]}
			fillOpacity={0.75}
			r={3.5}
			onClick={() => {
				if (preparedData) {
					appDispatch(
						setDrawerIndividual({
							datum: preparedData.data[originalIdx],
							attributes: preparedData.attributes,
						})
					);
				}
			}}
		/>
	);

	const getMenuText = () =>
		yAttrNames.length > 0
			? {
					color: 'cyan' as const,
					text: 'Measures',
			  }
			: {
					color: 'primary' as const,
					text: 'select Y variable',
			  };

	const menuText = getMenuText();

	return (
		<StyledVizPaper>
			<StyledSubmoduleHeader>
				<Typography variant='h6' color="cyan">
					{activeAttribute ? activeAttribute.singular: xAttrName}
				</Typography>
				<IconButton icon={faGear} onClick={() => setSettingsOpen((p) => !p)}/>
			</StyledSubmoduleHeader>
			<StyledVizWithControls settingsOpen={settingsOpen}>
				<DisplayOnLoad {...individualsLoadState}>
					<StyledSubmoduleContent ref={setSizeTarget} id={svgId}>
						<StyledChartGrid rows={rows} cols={cols}>
							{charts.map((chart, i) => {
								const colHeading = colHeadings[i];
								const rowHeading =
									i % cols === 0
										? rowHeadings[i / cols]
										: undefined;

								return (
									<Scatterplot
										topLabel={colHeading}
										leftLabel={rowHeading}
										points={chart}
										xMin={xMin}
										xMax={xMax}
										yMax={yMax}
										drawPoint={drawPoint}
										svgId={svgId}
										key={`${colHeading ?? 'placeholder'}-${
											rowHeading ?? 'placeholder'
										}-${i}`}
									/>
								);
							})}
						</StyledChartGrid>
					</StyledSubmoduleContent>
				</DisplayOnLoad>
				{
					settingsOpen ? <StyledAttributeList>
					<List
						style={{
							overflowY: 'auto',
							paddingTop: '.05rem',
							paddingBottom: '.05rem',
							paddingLeft: '1rem',
						}}
					>
						<Typography
							variant="subtitle"
							color={menuText.color}>
							Share
						</Typography>
							<LinkedInShareButton elementId={svgId} />
						{/*<ListItem>*/}
						{/*</ListItem>*/}
					</List>
					<StyledSubmoduleHeader>
						<Typography variant="caption2" color={menuText.color}>
							{menuText.text}
						</Typography>
					</StyledSubmoduleHeader>
					<List style={{
							overflowY: 'auto',
							maxHeight: '150px',
							paddingTop: '.05rem',
							paddingBottom: '.05rem',
							paddingLeft: '1rem',
						}}>
						<Typography variant="subtitle" color={'oldManGray'}>
							Quantities
						</Typography>
						{yMenuAttrs &&
							yMenuAttrs.map((a: any) => {
								const isCurrent = hasOwnProperty(colorMap, a.name);

									return (
										<ListItem
										button
										key={a.name}
										aria-current={isCurrent}
										onClick={makeMenuHandler(
											a.name,
											yAttrNames,
											appendScatterplotY,
											removeScatterplotY
										)}
										style={{
											color: isCurrent
												? colorMap[a.name]
												: theme.palette.oldManGray,
										}}
									>
										{isCurrent && (
											<ListItemAvatar>
												<FontAwesomeIcon
													icon={faCheck} size={'xs'}
												/>
											</ListItemAvatar>
										)}
										<ListItemContent>
												<Typography variant={'caption2'}>
													{a.plural}
												</Typography>
										</ListItemContent>
									</ListItem>
									);
								})}
					</List>

					<List style={{
							overflowY: 'auto',
							maxHeight: '150px',
							paddingTop: '.05rem',
							paddingBottom: '.05rem',
						paddingLeft: '1rem',
						}}>
						<Typography variant="subtitle" color={'oldManGray'}>
							Group By
						</Typography>
						{preparedData &&
							preparedData.attributes
								.filter(
									(a) =>
										isCategory(a) &&
										cardinalityLessThan(10, a)
								)
								.map((a) => {
									const isCurrent =
										categoryAttrNames.includes(a.name);

									return (
										<ListItem
											button
											key={a.name}
											aria-current={isCurrent}
											onClick={makeMenuHandler(
												a.name,
												categoryAttrNames,
												appendScatterplotCat,
												removeScatterplotCat
											)}
											style={{
												color: isCurrent
													? colorMap[a.name]
													: theme.palette.oldManGray,
											}}
										>
											{isCurrent && (
												<ListItemAvatar>
													<FontAwesomeIcon
														icon={faCheck}
														size={'xs'}
													/>
												</ListItemAvatar>
											)}
											<ListItemContent>
												<Typography variant={'caption2'}>
													{a.plural}
												</Typography>
											</ListItemContent>
										</ListItem>
									);
								})}
					</List>
				</StyledAttributeList> : null}
			</StyledVizWithControls>
		</StyledVizPaper>
	);
};

export default ScatterplotModule;
