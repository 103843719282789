import { useAppDispatch } from '../../app/hooks';
import IconButton from './IconButton';
import { pipe } from '../utils/functionUtils';
import { recenterGraph } from '../../features/HUD/state/HUDSlice';
import { ControlButtonWrapper } from '../../features/HUD/components/styledComponents';
import {faMinimize} from '@fortawesome/free-solid-svg-icons';
import { FunctionComponent } from 'react';

const RecenterButton: FunctionComponent<{display?: boolean}> = ({display = true}) => {
	const appDispatch = useAppDispatch();

	const recenter = () => pipe(recenterGraph(), appDispatch);

	return (
		<ControlButtonWrapper displaying={display}>
			<IconButton
				variant='transparent'
				fillColor={'darkBaby'}
				icon={faMinimize}
				onClick={recenter}
				aria-label={`Recenter graph`}
				key="recenter-graph"
				size="lg"
				tooltip={`Recenter Graph`}
				tooltipPlacement="left"
			/>
		</ControlButtonWrapper>
	);
};

export default RecenterButton;
