import React, {FunctionComponent} from "react";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";

const LabelInput: FunctionComponent<ActionFieldProps> = (
    {
        defaultValue,
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleTextbox
        {...validatingRegister('label')}
        label="Display name"
        defaultValue={defaultValue}
        isDirty={Boolean(formState.dirtyFields.label)}
        onReset={() => resetField('label')}
        error={harvestErrors(
            'label',
            formState,
            mutationResults
        )}
        showLabel
    />;
}

export default LabelInput;
