import {BaseUser, UserContext} from '../../authentication/types/userTypes';
import { BaseAttribute, ResolvedAttributeType } from './attributeTypes';
import {BaseDomain, DomainGraphEdge, DomainGraphNode} from './domainTypes';
import { LINEAGE_GRAPH } from 'common/modals/LineageGraph/CONSTANTS';
import { INDIVIDUALS_TABLE } from 'common/viz/CONSTANTS';
import { VizKind, VizMap } from 'common/viz/types';
import { FunctionComponent } from 'react';
import {Individual} from "./individualTypes";
import {dataObjects, DataStatisticSubject} from "../../browser/types/dataTypes";
import {ObjectType} from "./commonTypes";

const entityViewFlags = [
	'hasIdentities',
	'hasRelations',
	'hasQuantities',
	'hasCategories',
	'hasLocations',
	'hasEvents',
	'hasImages',
	'hasVideos',
	'hasStart',
	'hasEnd',
] as const;

export type EntityModalTypes =
    | 'entitySettings'
    | 'membership'
    | 'share'
    | 'destroy'
    | 'followers';

export type EntityViewFlag = typeof entityViewFlags[number];

export type EntityViewFlags = { [K in EntityViewFlag]?: boolean };


export interface BaseRestriction {
	_object: 'Restriction';
	_id: number;
	createdAt: string;
	urn: string;
	type: string;
	agent: BaseUser;
	restriction: any;
	restricted: BaseAttribute;
}

/**
 * Base type for a single entity
 */
export interface BaseEntity extends EntityViewFlags {
	_id: number;
	_object: 'Entity';
	definition: null | string;
	name: string;
	plural: string;
	singular: string;
	lastModified?: string;
	isImport?: boolean;
	importingDomain?: number | null;
	context: string;
	userContext: UserContext;
	domain: number;  	// 'domain' is _id of entity's parent domain
	ontology?: {
		org: string;
		domain: string;
		entity: string;
	};
	stats: {
		followers: number;
		attributes: number;
		relations: number;
		metrics: number;
		restrictions: number;
		contributors: {
			stewards: number;
		};
		pageViews: {
			views: number;
		};
	};
}

// types for mapping data derived from entity's attributes to particular visualizations
export type EntityVizKind =
	| VizKind
	| typeof LINEAGE_GRAPH
	| typeof INDIVIDUALS_TABLE;

export type AttrTypeCounts = Partial<Record<ResolvedAttributeType, number>>;

export interface EntityVizMapper {
	(counts: AttrTypeCounts, activeAttribute: BaseAttribute): EntityVizKind[];
}

export interface EntityVizMap extends VizMap {
	[LINEAGE_GRAPH]: FunctionComponent;
}

// network data types
export interface GetEntityParams {
	entityId: BaseEntity['_id'];
}

export interface GetEntitiesParams {
	domainId: BaseDomain['_id'];
}

/**
 * Entity node obtained by querying a domain for a graph
 * description of itself.
 */
export interface EntityGraphNode extends Individual {
	isImport: boolean;
}

/**
 * Entity edge files obtained by querying a domain for a graph
 * description of itself.
 */
export interface EntityGraphEdge {
	_id: number;
	left: boolean;
	reflexive: boolean;
	right: boolean;
	source: number;
	target: number;
}

export interface GetEntityGraphParams {
	entityId: BaseEntity['_id'];
}

export interface EntityGraphDataResponse {
	nodes: EntityGraphNode[];
	edges: EntityGraphEdge[];
}

export type GetRestrictionsResponse = BaseRestriction[];


export type EntitiesDataResponse = BaseEntity[];

export interface EntityDataResponse {
	meta: {
		status: number;
	};
	response: BaseEntity;
}

export interface CreateEntityParams {
	singular: string;
	plural: string;
	domainId: number;
}

export interface CreateEntityDataResponse extends BaseEntity {}

export interface FollowEntityParams {
	entityId: number;
}

export interface FollowEntityResponse {}

export interface UnfollowEntityParams {
	entityId: number;
}

export interface UnfollowEntityResponse {}

export interface IdentifyEntityParams {
	entityId: number;
	attributeId: number;
}

export interface IdentifyEntityResponse extends EntityDataResponse {}

export interface UpdateEntityParams {
	entityId: number;
	body: {
		singular: string;
		plural: string;
		definition: string | null;
	};
}

export interface UpdateEntityResponse {}

export interface DeleteImportedEntityParams {
	entityId: number;
	domainId: number;
	isImport: true;
}

export interface DeleteNormalEntityParams {
	entityId: number;
	isImport: false;
}

export type DeleteEntityParams =
	| DeleteImportedEntityParams
	| DeleteNormalEntityParams;

export const isDeleteImportParams = (
	p: DeleteEntityParams
): p is DeleteImportedEntityParams => p.isImport;

export interface DeleteEntityResponse {}

export interface ImportEntityParams {
	entityId: number;
	domainId: number;
}

export interface ImportEntityResponse {}

export const restrictables: Exclude<ObjectType,
	'Organization' |
	'Answer' |
	'User' |
	'DataRow' |
	'DataValue' |
	'DataType' |
	'Fact' |
	'Insight' |
	'Question' |
	'Comment' |
	'Reaction' |
	'Share' |
	'Notification'>[] = [
	// 'Definition' |
	// TODO: this api route doesn't actually exist in the backend
	'Entity',
	'Metric',
];

export type RestrictionSubject = typeof restrictables[number];

export interface GetRestrictionsParams {
	objectId: number;
	objectType: RestrictionSubject;
	perPage?: number;
	page?: number;
}
