import { selectNavItemName } from '../helpers';
import { SourceListItemProps } from '../types/uiTypes';
import {
	useState,
	useEffect,
	MouseEventHandler,
	useCallback,
	useMemo,
} from 'react';
import {useParams} from 'react-router-dom'
import useViewModeParams from "../../navigation/hooks/useViewModeParams";
import {useNavigate} from "react-router";

const useSourceListItem = ({
	onDisplay,
	kind,
	currentlySelected,
	setter,
}: SourceListItemProps) => {
	const [open, setOpen] = useState(false);

	const [active, setActive] = useState(false);

	const [queryShouldRun, setQueryShouldRun] = useState<boolean>(false);
	const params = useParams()
	const {getViewMode, setViewMode} = useViewModeParams()

	// handle opening/closing the accordion effect
	useEffect(() => {
		if (currentlySelected === onDisplay) {
			setOpen(true);
			setActive(true);
		} else {
			setOpen(false);
			setActive(false);
		}
	}, [onDisplay]);
	//currentlySelected,

	// the first time a nav item is clicked, run the query to fetch its
	// children.  Query should remain active thereafter; if we pass skipToken
	// back in when item becomes inactive, the query returns to uninitialized
	// state.
	useEffect(() => {
		if (active && !queryShouldRun) {
			setQueryShouldRun(true);
		}
	}, [queryShouldRun, active]);

	const navigation = useNavigate();

	const onClick = useCallback<MouseEventHandler>(
		(e) => {
			e.stopPropagation();
			setter(onDisplay);
			navigation( kind === 'source'
				? `/data/${params.username}/${onDisplay.name}`
				: kind === 'catalog'
					? `/data/${params.username}/${params.source}/${onDisplay.name}`
					: `/data/${params.username}/${params.source}/${params.catalog}/${onDisplay.name}`
			)
			setOpen((prev) => !prev);
			setActive((prev) => !prev);
		},
		[setter, onDisplay]
	);

	// const onClick: MouseEventHandler = (e) => {
	// 	e.stopPropagation();
	// 	setter(onDisplay);
	// 	// setOpen((prev) => !prev);
	// 	// setOpen(true);
	// };

	const itemName = useMemo(() => selectNavItemName(onDisplay), [onDisplay]);

	return {
		queryShouldRun,
		open,
		active,
		onClick,
		itemName,
	};
};

export default useSourceListItem;
