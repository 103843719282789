import { ControlButtonWrapper } from '../../features/HUD/components/styledComponents';
import { faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'common/buttons/IconButton';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import { FunctionComponent } from 'react';

const ClearFiltersButton: FunctionComponent<{display?: boolean}> = ({display = true}) => {
	const { clearAllAttributeFilters, getAllFilters } = useEntitySearchParams();

	const hasFilters = getAllFilters().length > 0;

	return (
		<ControlButtonWrapper displaying={hasFilters && display}>
			<IconButton
				icon={faFilterCircleXmark}
				onClick={() => clearAllAttributeFilters()}
				aria-label="clear active filters"
				key="clear-filters"
				size="lg"
				tooltip="clear filters"
				variant="transparent"
				fillColor={'darkBaby'}
				showTooltip={true}
				tooltipPlacement="left"
			/>
		</ControlButtonWrapper>
	);
};

export default ClearFiltersButton;
