import ResourcePopover from 'common/popovers/ResourcePopover';
import {List, ListItem,} from 'common/List';
import {PopoverBase} from 'common/popovers/Popover';
import Spinner from 'common/loading/Spinner';
import Typography from 'common/text/Typography';
import EntityItem from 'common/List/EntityItem';
import {parseQueryError} from 'features/api/helpers';
import {useGetEntitiesQuery} from 'features/api';
import {FunctionComponent, useCallback, useState} from 'react';
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {DomainGraphNode} from "../../features/ontology/types/domainTypes";
import useActiveOrgData from "../../features/ontology/hooks/useActiveOrgData";
import {EntityModalTypes} from "../../features/ontology/types/entityTypes";

type EntityPanelProps = {
    domainId?: number;
    openModal?: (type: EntityModalTypes | null) => void;
};

const EntityPanel: FunctionComponent<EntityPanelProps> = (
    {
        domainId,
        openModal
    }
) => {
    const [popoverEntity, setPopoverEntity] = useState<DomainGraphNode | null>(null);

    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const {activeItem: activeOrg} = useActiveOrgData();

    const {
        data: entities,
        isLoading,
        isError,
        isUninitialized,
        error,
    } = useGetEntitiesQuery(domainId ? {domainId} : skipToken);

    const [entityErr, setEntityErr] = useState<Record<string, string>>({});

    // const updateEntityErr = useCallback(
    // 	(msg: string, id: number) => setEntityErr(
    // 		(p) => ({ ...p, [id]: msg })
    // 	), []
    // );

    if (!!entities && entities.length > 0) {

        if (isError) {
            return (
                <Typography paragraph color="error">
                    {parseQueryError(error).message}
                </Typography>
            );
        }

        if (isLoading || isUninitialized) {
            return <Spinner/>;
        }

        return (
            <>
                <PopoverBase
                    anchorEl={anchor}
                    handleClickAway={() => setPopoverEntity(null)}
                    open={!!popoverEntity}>
                    {popoverEntity &&
                    <ResourcePopover resource={popoverEntity}/>}
                </PopoverBase>
                <List aria-labelledby='domain-entities-list'>
                    {(entities as any[]).map((entity) => {
                        return <EntityItem
                            key={`entities-${entity._id}`}
                            entity={Object.assign(
                                {}, entity,
                                {profileImageUrl: activeOrg.avatar.medium}
                            )}
                            openModal={openModal}
                            canEdit={entity.userContext.permissions.edit || entity.userContext.permissions.admin}
                            // handleError={entityErr}
                            onClick={(e: any) => {
                                setAnchor(e.currentTarget);
                                setPopoverEntity(entity);
                            }}/>
                    })}
                </List>
            </>
        );
    } else {
        return null
    }

};

export default EntityPanel;
