import {ViewMode, ViewMap} from './types';
import {EntityViewFlags} from 'features/ontology/types/entityTypes';
import {Individual} from "../ontology/types/individualTypes";
import {
    DataViewFlags,
    DataViewMap,
    DataViewMode
} from "../browser/types/uiTypes";

export const makeViewDeriver = (vm: ViewMap[]) => (e: EntityViewFlags) => {
    //  all entities will have a profile view and a graph view
    const res: ViewMode[] = ['profile', 'graph', 'spatial'];

    vm.forEach(({flagName, correspondingViewMode}) => {
        if (e[flagName] === true) {
            res.push(correspondingViewMode);
        }
    });

    return res;
};

export const makeEntityViewDeriver =
    (vm: ViewMap[]) => (e: EntityViewFlags) => {
        //  all entities will have a profile view and a graph view
        const res: ViewMode[] = ['profile', 'graph'];

        vm.forEach(({flagName, correspondingViewMode}) => {
            if (e[flagName] === true) {
                res.push(correspondingViewMode);
            }
        });

        return res;
    };

const mappedViews: ViewMap[] = [
    {flagName: 'hasLocations', correspondingViewMode: 'spatial'},
];

const mappedDataViews: DataViewMap[] = [
    {flagName: 'hasSources', correspondingViewMode: 'data'},
];

export const deriveEntityViews = makeEntityViewDeriver(mappedViews);

export const makeDomainViewDeriver =
    (vm: ViewMap[]) =>
        (e: EntityViewFlags[]): ViewMode[] => {
            // Use Set for convenient deduplication.
            const res: Set<ViewMode> = new Set();
            res.add('profile');
            res.add('graph');
            res.add('spatial');
            //
            // e.forEach((viewFlagObject) => {
            // 	vm.forEach(({ flagName, correspondingViewMode }) => {
            // 		if (viewFlagObject[flagName] === true) {
            // 			res.add(correspondingViewMode);
            // 		}
            // 	});
            // });

            return Array.from(res);
        };


export const makeDataViewDeriver =
    (vm: DataViewMap[]) => (): DataViewMode[] => {

        const res: Set<DataViewMode> = new Set();
        res.add('data');
        res.add('quality');
        res.add('lineage');
        res.add('usage');
        res.add('relations');

        // e.forEach((viewFlagObject) => {
        // 	vm.forEach(({ flagName, correspondingViewMode }) => {
        // 		if (viewFlagObject[flagName] === true) {
        // 			res.add(correspondingViewMode);
        // 		}
        // 	});
        // });

        return Array.from(res);
    };


export const deriveDomainViews = makeDomainViewDeriver(mappedViews);
export const deriveDataViews = makeDataViewDeriver(mappedDataViews);

export const makeIndividualViewDeriver =
    (vm: ViewMap[]) =>
        (e: Individual): ViewMode[] => {
            //  all domains will have a profile view and a graph view
            // const res: ViewMode[] = ['profile', 'graph'];
            // Use Set for convenient deduplication.
            const res: Set<ViewMode> = new Set();
            res.add('profile');
            res.add('graph');
            res.add('spatial');

            // e.forEach((viewFlagObject) => {
            // 	vm.forEach(({ flagName, correspondingViewMode }) => {
            // 		if (viewFlagObject[flagName] === true) {
            // 			res.add(correspondingViewMode);
            // 		}
            // 	});
            // });

            return Array.from(res);
        };


export const deriveIndividualViews = makeIndividualViewDeriver(mappedViews);

// export interface OrgViewMap {
// 	flagName: EntityViewFlag;
// 	correspondingViewMode: ViewMode;
// }

export type OrgViewMode = 'profile' | 'graph' | 'spatial' | 'data';

export const makeOrgViewDeriver = () => (): OrgViewMode[] => {
    // Use Set for convenient deduplication.
    const res: Set<OrgViewMode> = new Set();
    res.add('profile');
    res.add('graph');
    res.add('spatial');
    res.add('data');

    // e.forEach((viewFlagObject) => {
    // 	vm.forEach(({ flagName, correspondingViewMode }) => {
    // 		if (viewFlagObject[flagName] === true) {
    // 			res.add(correspondingViewMode);
    // 		}
    // 	});
    // });

    return Array.from(res);
};

export const deriveOrgViews = makeOrgViewDeriver();

export const makeUserViewDeriver =
    (vm: ViewMap[]) => (): ViewMode[] => {
        //  all domains will have a profile view and a graph view
        // const res: ViewMode[] = ['profile', 'graph'];
        // Use Set for convenient deduplication.
        const res: Set<ViewMode> = new Set();
        res.add('profile');

        // e.forEach((viewFlagObject) => {
        // 	vm.forEach(({ flagName, correspondingViewMode }) => {
        // 		if (viewFlagObject[flagName] === true) {
        // 			res.add(correspondingViewMode);
        // 		}
        // 	});
        // });

        return Array.from(res);
    };

export const deriveUserViews = makeUserViewDeriver(mappedViews);
