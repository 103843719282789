import Button from '../../buttons/Button';
import FlexContainer from '../../FlexContainer';
import {
    ListItem,
    ListItemContent,
    ListItemAvatar,
} from '../../List';
import SubtleTextArea from '../../inputs/SubtleTextArea';
import Typography from '../../text/Typography';
import theme from '../../theme/theme';
import SlideDown from '../../transitions/SlideDown';
import {useCreateCommentMutation} from '../../../features/api';
import {parseQueryError} from '../../../features/api/helpers';
import CommentContext from '../state/CommentContext';
import {CreateCommentFormValues} from '../types/commentTypes';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {FunctionComponent, useContext, useRef, useState} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {CSSTransition} from 'react-transition-group';
import ProfileAvatar
    from "../../../features/navigation/components/Navbar/ProfileAvatar";
import {PopoverBase} from "../../popovers/Popover";
import UserPopover from "../../popovers/UserPopover";
import useCurrentUser from "../../../features/authentication/hooks/useUser";
import {useNavigate} from "react-router";

const COMMENT_PLACEHOLDER = 'Write a comment';
const REPLY_PLACEHOLDER = 'Write a reply';

const CreateCommentForm: FunctionComponent<{ isOpen: boolean }> = ({isOpen}) => {
    const {objectType, objectIdentifier} = useContext(CommentContext);

    const commentInputRef = useRef<HTMLTextAreaElement>();
    const currentUser = useCurrentUser();

    const nodeRef = useRef<HTMLElement>();
    const placeholder = objectType === 'Comment' ? REPLY_PLACEHOLDER : COMMENT_PLACEHOLDER

    const {register, resetField, formState, handleSubmit, reset} =
        useForm<CreateCommentFormValues>({
            defaultValues: {
                comment: placeholder,
            },
        });

    const [createComment, createCommentResult] = useCreateCommentMutation();

    const onSubmit: SubmitHandler<CreateCommentFormValues> = (vals) => {
        if (!objectIdentifier) {
            return null;
        }
        reset();
        createComment({
            body: {
                objectIdentifier: objectIdentifier,
                objectType: objectType,
                comment: vals.comment,
            },
        });
    };

    //  If there's a network error with a message, display that.  Otherwise,
    // display any form validation error messages.
    const commentError = () => {
        if (createCommentResult.isError) {
            return parseQueryError(createCommentResult.error).message;
        }

        return formState.errors.comment?.message;
    };

    //  calling register outside the component it targets gives us access
    // to the component ref it exposes.
    const {ref: hookFormRef, ...inputProps} = register('comment', {
        required: 'Content is required to submit a comment',
        validate: (v) =>
            v === placeholder
                ? 'Content is required to submit a comment'
                : true,
    });

    const navigator = useNavigate();

    const [popoverUser, setPopoverUser] = useState<any>(null);
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const [textSize, setTextSize] = useState<number>(0);

    const handleKeyPress = (event: any) => {
        setTextSize(event.target.value.length)
    }

    return (
        <CSSTransition
            in={isOpen}
            classNames="slide-down-transition"
            timeout={theme.transitions.duration.shortest}
            unmountOnExit
            nodeRef={nodeRef}
            onEntered={() => {
                if (commentInputRef.current) {
                    commentInputRef.current.focus();
                }
            }}
        >
            <SlideDown ref={nodeRef as any}>
                <FlexContainer style={{height: '100%', width: '100%'}} gap={'1rem'}>
                    <ProfileAvatar
                        onHover={(e: any) => {
                            setAnchor(e.currentTarget);
                            setPopoverUser(currentUser);
                        }}
                        onClick={() => navigator(`/${currentUser.username}`, { replace: false })}
                        onExit={(e: any) => {
                            setAnchor(null);
                            setPopoverUser(null);
                        }}
                        initial={currentUser.firstName}
                        profileImageURL={currentUser?.avatar?.medium?.url
                            ? currentUser?.avatar?.medium?.url : ""}
                    >
                        <PopoverBase
                            anchorEl={anchor}
                            handleClickAway={() => setPopoverUser(null)}
                            open={!!popoverUser}>
                            {popoverUser && <UserPopover user={popoverUser}/>}
                        </PopoverBase>
                    </ProfileAvatar>
                    <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                        <SubtleTextArea
                            onReset={() => resetField('comment')}
                            isDirty={formState.isDirty}
                            {...inputProps}
                            ref={(el) => {
                                hookFormRef(el);
                                commentInputRef.current =
                                    el as HTMLTextAreaElement;
                            }}
                            onKeyPress={handleKeyPress}
                            style={{
                                height: '100%',
                                minHeight: `${Math.round(textSize * 16 / 200) * 8}px`,
                                display: 'flex',
                                flexGrow: 2,
                                resize: 'none',
                                borderRadius: 5,
                                padding: '20px',
                                verticalAlign: 'middle'
                            }}
                            error={commentError()}
                        />
                        <Button
                            variant="outline"
                            disabled={!formState.isDirty}
                        >
                            Submit
                        </Button>
                    </form>
                </FlexContainer>
            </SlideDown>
        </CSSTransition>
    );
};

export default CreateCommentForm;
