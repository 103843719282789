import { resolveAttrType } from 'features/ontology/typeGuards/attributeGuards';
import {
	BaseAttribute,
	ResolvedAttributeType,
} from 'features/ontology/types/attributeTypes';

export const filterAttrsForYMenu = (
	xAttr: string,
	attrs: BaseAttribute[],
	desiredTypes: ResolvedAttributeType[]
) =>
	attrs.filter(
		(a: any) => a.name !== xAttr && desiredTypes.includes(resolveAttrType(a))
	);
