import styled from 'styled-components';

const SwipeInOut = styled.div`
	display: none;

	&.swipe-transition-enter {
		display: block;
		opacity: 0;
		transform: translateX(200px);
	}

	&.swipe-transition-enter-active {
		opacity: 1;
		transform: translateX(0px);
		transition: all 300ms;
	}

	&.swipe-transition-enter-done {
		display: block;
		opacity: 1;
	}

	&.swipe-transition-exit {
		opacity: 1;
		transform: translateX(0px);
	}

	&.swipe-transition-exit-active {
		transform: translateX(-200px);
		opacity: 0;
		transition: all 300ms;
	}

	&.swipe-transition-exit-done {
		display: none;
	}
`;

export default SwipeInOut;
