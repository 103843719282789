import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetLiveDataCatalogsQuery } from 'features/api';
import { mergeQueryStates } from 'features/api/helpers';
import useActiveSourceData from 'features/ontology/hooks/useActiveSourceData';

const useActiveSourceCatalogs = () => {
	const { activeItem: activeSource, ...sourceLoadState } = useActiveSourceData();

	const { data: catalogs, ...catalogLoadState } = useGetLiveDataCatalogsQuery(
		activeSource ? { sourceId: activeSource._id } : skipToken
	);

	const combinedLoadState = mergeQueryStates(sourceLoadState, catalogLoadState);

	return {
		...combinedLoadState,
		catalogs,
	};
};

export default useActiveSourceCatalogs;
