import FlexContainer from 'common/FlexContainer';
import Spinner from 'common/loading/Spinner';
import { flatten2D, map, pipe } from 'common/utils/functionUtils';
import { isAppError } from 'common/utils/typeGuards';
import { StyledLeftDrawerContainer } from 'features/HUD/components/styledComponents';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import HUDBarChartModule from 'common/viz/BarChart/BarChartHUD';
import HUDHistogramModule from 'common/viz/Histogram/HistogramHUD';
import {
	groupAttrs,
	sortGroupedAttrs,
} from 'features/ontology/helpers/attributeHelpers';
import useActiveIndividualsMeta from 'features/ontology/hooks/useActiveIndividualsMeta';
import { resolveAttrType } from 'features/ontology/typeGuards/attributeGuards';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';
import { FunctionComponent } from 'react';
import LeftDrawer from "../../../features/HUD/components/LeftDrawer";

const isEligibleAttr = (a: BaseAttribute) => {
	const resolvedType = resolveAttrType(a);

	return resolvedType === 'quantity' || resolvedType === 'category';
};

const EntityLeftHUD: FunctionComponent = () => {
	const { preparedData, ...individualsLoadState } = useActiveIndividualsMeta({
		filter: true,
	});

	const renderViz = () => {
		if (preparedData) {
			return pipe(
				preparedData.attributes.filter(isEligibleAttr),
				groupAttrs('base'),
				sortGroupedAttrs,
				map((group) => group.attrs),
				flatten2D
			).map((a) => {
				if (resolveAttrType(a) === 'quantity') {
					const meta = preparedData.getAttributeData(a.name)!;
					const facts = preparedData.facts<number>(a.name);

					if (isAppError(facts)) {
						return (
							<HUDBarChartModule
								{...{
									...individualsLoadState,
									isError: true,
									error: facts,
								}}
								facts={[]}
								key={a._id}
								attrName={a.name}
								attrPlural={a.plural}
							/>
						);
					}

					const data = flatten2D(facts.map((f) => f.values));

					return (
						<HUDHistogramModule
							maxBinCount={meta.uniqueCount}
							data={data}
							{...individualsLoadState}
							key={a._id}
							attrName={a.name}
							attrPlural={a.plural}
						/>
					);
				}

				const facts = preparedData.facts<string>(a.name);

				if (isAppError(facts)) {
					return (
						<HUDBarChartModule
							{...{
								...individualsLoadState,
								isError: true,
								error: facts,
							}}
							facts={[]}
							key={a._id}
							attrName={a.name}
							attrPlural={a.plural}
						/>
					);
				}

				return (
					<HUDBarChartModule
						facts={facts}
						{...individualsLoadState}
						key={a._id}
						attrName={a.name}
						attrPlural={a.plural}
					/>
				);
			});
		}

		if (individualsLoadState.isLoading) {
			return (
				<FlexContainer
					justifyContent="center"
					alignItems="center"
					alignContent="center"
				>
					<Spinner />
				</FlexContainer>
			);
		}
	};

	return (
		<StyledLeftDrawerContainer className={'geospatial-viz-panel'}>
			<DisplayOnLoad {...individualsLoadState}>
				{renderViz()}
			</DisplayOnLoad>
		</StyledLeftDrawerContainer>
	);
};

export default EntityLeftHUD;
