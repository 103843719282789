import PopoverBase from '../popovers/Popover/PopoverBase';
import { QuestionSubject } from '../questions/types/questionTypes';
import QuestionList from '../questions/components/QuestionList';
import React, { FunctionComponent, useState } from 'react';
import TextButton from "./TextButton";

interface CreateQuestionButtonProps {
	objectType: QuestionSubject;
	objectId: number;
	canEdit: boolean;
}

const QuestionButton: FunctionComponent<CreateQuestionButtonProps> = ({
	canEdit,
	objectId,
	objectType,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);

	const [menuOpen, setMenuOpen] = useState<string | null>(null);

	return (
		<>
			<TextButton
				text={'Ask'}
				ref={setAnchorEl as any}
				// icon={faQuestionCircle}
				baseOpacity="80%"
				color='primary'
				// size="xs"
				tooltip="ask a question"
				tooltipPlacement="bottom"
				onClick={() => setMenuOpen((p) => (p ? null : 'open'))}
				type="button"
				showTooltip={!menuOpen}
				aria-label="view questions about this object"
			/>
			<PopoverBase
				anchorEl={anchorEl}
				open={!!menuOpen}
				offset={0}
				handleClickAway={() => setMenuOpen(null)}
			>
				<QuestionList
					//  This button is only used to create questions for non-individuals, so we can manually
					// pass object identifier for question creation on objects of other types.
					parentObjectIdentifier={{ kind: 'other', objectId }}
					objectType={objectType}
					canEdit={canEdit}
				/>
			</PopoverBase>
		</>
	);
};

export default QuestionButton;
