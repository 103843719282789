import { scaleUtc } from 'd3-scale';

const useUTCXScale = (config: {
	xMax: Date;
	xMin: Date;
	left: number;
	right: number;
	width: number;
}) => {
	const { xMax, xMin, left, right, width } = config;

	const xScale = scaleUtc()
		.domain([xMin, xMax])
		.range([left, width - right]);

	return xScale;
};

export default useUTCXScale;
