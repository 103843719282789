import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox} from "./SubtleTextBox";
import {harvestErrors} from "./SubtleTextBox";

const SingularInput: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
    ) => {
    // id="singular"
    // aria-errormessage={genErrorIdFromLabel('singular')}
    // aria-invalid={formFieldHasErrors('singular', formState)}
    return <SubtleTextbox
        {...validatingRegister('singular')}
        showLabel
        alignItems={'center'}
        justifyContent={'space-between'}
        inline={true}
        label="Singular"
        isDirty={Boolean(formState.dirtyFields.singular)}
        onReset={() => resetField('singular')}
        error={harvestErrors('singular', formState, mutationResults)}
    />;
}

export default SingularInput;
