import { defaultGraphMargins } from '../../CONSTANTS';
import ChartLeftLabel from '../../common/ChartLeftLabel';
import ChartTopLabel from '../../common/ChartTopLabel';
import {
	StyledSVGContainer,
	StyledAxisGroup,
} from '../../common/styledComponents';
import useBrushX from '../../hooks/useBrushX';
import useLinearYAxis from '../../hooks/useLinearYAxis';
import useLinearYScale from '../../hooks/useLinearYScale';
import useUTCXScale from '../../hooks/useUTCXScale';
import useXAxis from '../../hooks/useXAxis';
import { OldBadPointDrawFn } from '../../types';
import { drawDefaultCard, drawDefaultLine } from './helpers';
import { BrushExtent, DrawnEventPoint, Lines, Transformers } from './types';
import { EventLineDrawFn, EventCardArgs, EventCardDrawFn } from './types';
import useElementSize from 'common/hooks/useSize';
import { pointer } from 'd3-selection';
import { line as d3Line } from 'd3-shape';
import { FunctionComponent, useState } from 'react';
import { CSSProperties } from 'styled-components';

interface Overrides {
	svg?: CSSProperties;
	wrapper?: CSSProperties;
}

interface EventLineChartProps {
	transformers?: Transformers;
	xAxis?: boolean;
	yAxis?: boolean;
	topLabel?: string;
	leftLabel?: string;
	overrides?: Overrides;
	// xMin and xMax will be ISO 8601 compliant Date strings
	xMin: Date;
	xMax: Date;
	yMax: number;
	lines: Lines;
	drawPoint?: OldBadPointDrawFn<Date, number, any>;
	drawLine?: EventLineDrawFn;
	drawCard?: EventCardDrawFn;
	tooltip?: boolean;
	//  brushend callback will receive date strings from d3's UTCScale.invert
	onBrushEnd?: (v: [string, string]) => void;
	brushExtent?: BrushExtent;
	svgId?: string;
}

const EventLineChart: FunctionComponent<EventLineChartProps> = ({
	svgId,
	topLabel,
	leftLabel,
	xMin,
	xMax,
	yMax,
	overrides,
	lines,
	drawLine,
	drawCard,
	onBrushEnd,
	xAxis = true,
	yAxis = true,
	tooltip = true,
	brushExtent,
	...margins
}) => {
	const [size, setSizeEl] = useElementSize();

	const drawLineFn = drawLine ?? drawDefaultLine;

	const drawCardFn = drawCard ?? drawDefaultCard;

	const [cardArgs, setCardArgs] = useState<EventCardArgs>({
		x: 0,
		y: 0,
		xValue: '',
		yValue: '',
		visible: false,
		drawHeight: 0,
		drawWidth: 0,
	});

	const { top, bottom, left, right } = { ...defaultGraphMargins, ...margins };

	const xScale = useUTCXScale({
		xMax,
		xMin,
		width: size.width,
		left,
		right,
	});

	const yScale = useLinearYScale({
		top,
		bottom,
		height: size.height,
		yMax,
	});

	const invertX = xScale.invert;

	const bExtent = brushExtent
		? Array.isArray(brushExtent)
			? brushExtent
			: brushExtent({
					left,
					right,
					top,
					bottom,
					width: size.width,
					height: size.height,
			  })
		: undefined;

	const { brushClass } = useBrushX({
		width: size.width,
		height: size.height,
		extent: bExtent,
		left,
		top,
		right,
		bottom,
		invert: invertX,
		onEnd: onBrushEnd,
	});

	const xAxisClassname = useXAxis(xScale, size.height, bottom);

	const yAxisClassname = useLinearYAxis(yScale, left);

	const lineDefs = lines.map((line) => {
		const drawnLine = line.line
			.map((p) => ({
				...p,
				drawX: xScale(p.x),
				drawY: yScale(p.y),
			}))
			//    points must be sorted in chronological order for the line to make visual sense
			.sort((a, b) => {
				const v0 = a.drawX;
				const v1 = b.drawX;

				return v1 - v0;
			});

		const path = d3Line<DrawnEventPoint>(
			({ drawX }) => drawX,
			({ drawY }) => drawY
		)(drawnLine) as string;

		return {
			width: size.width,
			height: size.height,
			...line,
			path,
			line: drawnLine,
		};
	});

	const drawnLines = lineDefs.map((line) => (
		<g key={line.lineId}>{drawLineFn(line)}</g>
	));

	return (
		<StyledSVGContainer ref={setSizeEl} style={overrides?.wrapper}>
			<svg
				viewBox={`0 0 ${size.width} ${size.height}`}
				style={overrides?.svg}
				id={svgId}
			>
				{drawnLines}
				{topLabel && (
					<ChartTopLabel
						width={size.width}
						left={left}
						top={top}
						label={topLabel}
					/>
				)}
				{leftLabel && (
					<ChartLeftLabel
						height={size.height}
						bottom={bottom}
						label={leftLabel}
					/>
				)}
				{xAxis && <StyledAxisGroup className={xAxisClassname} />}
				{yAxis && <StyledAxisGroup className={yAxisClassname} />}
				{tooltip && (
					<g>
						<rect
							pointerEvents="all"
							width={size.width}
							height={size.height}
							transform={`translate(${left}, ${top})`}
							fill="none"
							onMouseOver={(e) => {
								e.stopPropagation();
								setCardArgs((p) => ({ ...p, visible: true }));
							}}
							onMouseOut={(e) => {
								e.stopPropagation();
								setCardArgs((p) => ({ ...p, visible: false }));
							}}
							onMouseMove={(e) => {
								e.stopPropagation();
								if (cardArgs.visible) {
									const [x, y] = pointer(e);
									const [xValue, yValue] = [
										xScale
											.invert(x)
											.toUTCString()
											.split(' ')
											.slice(0, 4)
											.join(' '),
										yScale.invert(y).toFixed(0).toString(),
									];
									setCardArgs((p) => ({
										...p,
										x,
										y,
										xValue,
										yValue,
										drawHeight: size.height - top - bottom,
										drawWidth: size.width - left - right,
									}));
								}

								return null;
							}}
						/>
					</g>
				)}

				{drawCardFn(cardArgs)}
				{onBrushEnd && <g className={brushClass} />}
			</svg>
		</StyledSVGContainer>
	);
};

export default EventLineChart;
