import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox} from "./SubtleTextBox";
import {harvestErrors} from "./SubtleTextBox";

const PortInput: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleTextbox
        {...validatingRegister('port', {
            value: '',
            valueAsNumber: true,
            validate: (v: any) =>
                isNaN(v)
                    ? 'invalid number provided to PORT'
                    : true,
        })}
        showLabel
        key="port"
        label="Port"
        isDirty={Boolean(formState.dirtyFields.port)}
        onReset={() => resetField('port')}
        error={harvestErrors(
            'port',
            formState,
            mutationResults
        )}
    />;
}

export default PortInput;
