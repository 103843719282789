import FlexContainer from '../../../common/FlexContainer';
import {FormResults,} from '../../../common/Form';
import Spinner from '../../../common/loading/Spinner';
import Typography from '../../../common/text/Typography';
import {useAppendAttrActionMutation} from '../../api';
import {mergeErrorStates, extractQueryErrMessage} from '../../api/helpers';
import {BaseAttribute} from '../../ontology/types/attributeTypes';
import {
    renderReferenceField,
    renderDerivationSubfields,
} from '../common/jsxHelpers';
import useAttrNeighbors from '../hooks/useAttrNeighbors';
import {
    appendAttrFormDefaults,
    appendAttrFormToPayload,
} from './appendAttributeHelpers';
import {AppendAttrFormValues} from './appendAttributeTypes';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import IsDerivationInput from "../../../common/inputs/IsDerivationInput";
import ValueTypeSelect from "../../../common/inputs/ValueTypeSelect";
import DelimiterTypeSelect from "../../../common/inputs/DelimiterTypeSelect";
import useModalType from "../../HUD/hooks/useModalType";
import Loading from "../../../common/loading/Loading";

const AppendAttributeForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
	const {objectId: _id, canEdit} = modalProps;

    const formDefaults = appendAttrFormDefaults();

    const {handleSubmit, register, resetField, reset, formState, watch} =
        useForm<AppendAttrFormValues>({
            defaultValues: formDefaults,
        });

    const [watchedDerivationFlag, watchedUsingReferenceValue] = watch([
        'isDerivation',
        'usingStaticReference',
    ]);

    const validatingRegister = useCallback(
        (inputName: keyof AppendAttrFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    // prevent filtering from running every render
    const filter = useCallback(
        (attr: BaseAttribute) => attr.type === 'category',
        []
    );

    const queryRes = useAttrNeighbors(_id, filter);

    // useEffect(() => {
    //     if (queryRes.isSuccess && closeModal) {
    //         closeModal();
    //     }
    // }, [queryRes.isSuccess, closeModal]);

    const [appendAttr, mutationResults] = useAppendAttrActionMutation();

    const mergedErrs = mergeErrorStates(queryRes, mutationResults);

    const onSubmit: SubmitHandler<AppendAttrFormValues> = (vals, e) => {
        e?.preventDefault();
        appendAttr({
            attrId: _id,
            body: appendAttrFormToPayload(vals),
        });
    };

    // if (queryRes.isLoading) {
    //     return (
    //         <FlexContainer justifyContent="center">
    //             <Typography paragraph variant='h5'>Loading
    //                 attributes...</Typography>
    //             <Spinner/>
    //         </FlexContainer>
    //     );
    // }

    if (queryRes.isError) {
        return (
            <FlexContainer justifyContent="center">
                <Typography color="error" paragraph>
                    {extractQueryErrMessage(queryRes.error)}
                </Typography>
            </FlexContainer>
        );
    }

    return (
        <AppModal
            label={"Append Attribute"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit ? canEdit : true}>
            <IsDerivationInput
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            {renderDerivationSubfields({
                isDerivation: watchedDerivationFlag === 'true',
                formState,
                validatingRegister,
                resetField,
                mutationResults
            })}
            <ValueTypeSelect
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            {!queryRes.isLoading && !queryRes.isUninitialized
                ? renderReferenceField(
                    'otherId',
                    'otherValue',
                    watchedUsingReferenceValue === 'true',
                    validatingRegister,
                    queryRes.attrNeighbors,
                    formState
                ) : <Loading />
            }
            <DelimiterTypeSelect
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            <FormResults
                isError={!!mergedErrs}
                error={mergedErrs}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default AppendAttributeForm;
