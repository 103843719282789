import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleSelect from "./SubtleSelect";
import {harvestErrors} from "./SubtleTextBox";

const DelimiterTypeSelect: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister
    }
    ) => {
    return <SubtleSelect
        inline={true}
        label='Choose a delimiter for the joined values'
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('delimiter')}
        isDirty={Boolean(formState.dirtyFields.delimiter)}
        {...validatingRegister('delimiter')}
    >
        <option value="space">Space</option>
        <option value="colon">Tab</option>
        <option value="comma">Comma</option>
        <option value="pipe">Pipe</option>
        <option value="colon">Colon</option>
    </SubtleSelect>
}

export default DelimiterTypeSelect;
