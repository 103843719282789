import React, {FunctionComponent} from "react";
import timezones from "../../assets/tzs.json";
import SubtleSelect from "./SubtleSelect";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {harvestErrors, SubtleTextbox} from "./SubtleTextBox";

const IndustrySelect: FunctionComponent<ActionFieldProps> = (
    {
        defaultValue,
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
    ) => {
    // aria-errormessage={genErrorIdFromLabel('timezone')}
    // aria-invalid={formFieldHasErrors('timezone', formState)}
    return <SubtleTextbox
        {...validatingRegister('industry', {})}
        showLabel
        defaultValue={defaultValue}
        label="Industry"
        isDirty={Boolean(formState.dirtyFields.industry)}
        onReset={() => resetField('industry')}
        error={harvestErrors(
            'industry',
            formState,
            mutationResults
        )}
    />;
}

export default IndustrySelect;
