import { useAppDispatch } from '../../app/hooks';
import Button from '../buttons/Button';
import {
	FormHeading,
	ModalFormBody,
	FormResults,
} from '../Form';
import IconButton from '../buttons/IconButton';
import Textbox from '../inputs/TextBox';
import useModalType from '../../features/HUD/hooks/useModalType';
import { setModalType } from '../../features/HUD/state/HUDSlice';
import {
	useCreateMetricMutation,
} from '../../features/api';
import useActiveEntityData from '../../features/ontology/hooks/useActiveEntityData';
import React, {FunctionComponent, useCallback, useEffect, useRef} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
	BaseMetric,
	MetricFilterParam
} from "../../features/ontology/types/metricTypes";
import AppModal from "./AppModal";
import {DomainCreationFormBase} from "./DomainCreationForm";
import {BaseDomain} from "../../features/ontology/types/domainTypes";
import {BaseEntity} from "../../features/ontology/types/entityTypes";
import {useParams} from "react-router-dom";
import {harvestErrors, SubtleTextbox} from "../inputs/SubtleTextBox";

const StyledIconButton = styled(IconButton)`
	position: absolute;
	top: ${(p) => p.theme.spacing(2)};
	right: ${(p) => p.theme.spacing(2)};
`;

interface MetricCreationFormProps {
	resource: BaseDomain | BaseEntity;
	isOpen: boolean;
	onClose: () => void;
	canEdit: boolean;
}

interface MetricCreationFormValues extends Omit<BaseMetric, '_id' | '_object'> {}


// event: st,
// Accept domainId as a prop to avoid needing complicated route matching in tests for this form
export const MetricCreationFormBase: FunctionComponent = () => {

	// const {username, domain, entity} = useParams();
	// const { activeItem: activeEntity } = useActiveEntityData();
	// const { activeItem: activeDomain } = useActiveEntityData();

	const {modalType, modalProps, closeModal} = useModalType();
	const {resource} = modalProps;

	const { register, formState, resetField, handleSubmit, reset } =
		useForm<MetricCreationFormValues>({
			defaultValues: {},
		});

	// this is needed to update react-hook-form's default values with new
	// values from a successful PUT request.
	// useEffect(() => {
	// 	reset(entity);
	// }, [entity, reset]);

	const validatingRegister = useCallback(
		(inputName: keyof MetricCreationFormValues) =>
			register(inputName, {
				required: `${inputName} is required`,
			}),
		[register]
	);

	const [createMetric, mutationResults] = useCreateMetricMutation();

	const onSubmit: SubmitHandler<MetricCreationFormValues> = (formVals) => {

		createMetric({
			objectId: resource._id,
			objectType: resource._object,
			body: formVals,
		});
	};
	// const [createMetric, mutationResult] = useCreateMetricMutation({
	// 	selectFromResult: (res) => ({
	// 		isLoading: res.isLoading,
	// 		isSuccess: res.isSuccess,
	// 		error: res.error,
	// 		isError: res.isError,
	// 	}),
	// });

	const syncMutationResult = useRef<any>(null);

	const creationFormOpen = modalType === 'metricCreationForm';



	// useEffect(() => {
	// 	if (formState.isDirty && !creationFormOpen) {
	// 		resetForm();
	// 	}
	// }, [formState, creationFormOpen, resetForm]);

	// const handleClose = () => {
	// 	if (syncMutationResult.current) {
	// 		syncMutationResult.current.unsubscribe();
	// 		syncMutationResult.current = null;
	// 	}
	// 	appDispatch(setModalType(null));
	// };

	// const onSubmit: SubmitHandler<MetricCreationFormValues> = (
	// 	{
	// 		label,
	// 		definition,
	// 		type,
	// 		timeframe,
	// 		temporalResolution,
	// 		isDistinct,
	// 		measuredId,
	// 		timestampId,
	// 		// event: string;
	// 		filters
	// 	},
	// 	e
	// ) => {
	// 	e?.preventDefault();
	//
	// 	if (entityId === null) {
	// 		console.error(
	// 			'Attempted to submit entity creation form when no active user was matched in URL!'
	// 		);
	// 		return;
	// 	}
	//
	// 	syncMutationResult.current = ({
	// 		label,
	// 		entityId,
	// 		definition,
	// 		type,
	// 		timeframe,
	// 		temporalResolution,
	// 		isDistinct,
	// 		measuredId,
	// 		timestampId,
	// 		// event: string;
	// 		filters
	// 	});
	// };


	return (
			<AppModal
				label={"Create Metric"}
				isOpen={true}
				isDirty={formState.isDirty}
				onClose={closeModal}
				onSubmit={handleSubmit(onSubmit)}
				canEdit={true}>
				<SubtleTextbox
				{...validatingRegister('label')}
				isDirty={Boolean(formState.dirtyFields.label)}
				onReset={() => resetField('label')}
				showLabel
				error={harvestErrors('label', formState, mutationResults)}
				label="Label"
			/>
			<SubtleTextbox
				{...validatingRegister('definition')}
				isDirty={Boolean(formState.dirtyFields.definition)}
				onReset={() => resetField('definition')}
				showLabel
				error={harvestErrors('definition', formState, mutationResults)}
				label="definition"
			/>
			<SubtleTextbox
				{...validatingRegister('type')}
				isDirty={Boolean(formState.dirtyFields.type)}
				onReset={() => resetField('type')}
				showLabel
				error={harvestErrors('type', formState, mutationResults)}
				label="type"
			/>
			<SubtleTextbox
				{...validatingRegister('isDistinct')}
				isDirty={Boolean(formState.dirtyFields.isDistinct)}
				onReset={() => resetField('isDistinct')}
				showLabel
				error={harvestErrors('isDistinct', formState, mutationResults)}
				label="isDistinct"
			/>
			<SubtleTextbox
				{...validatingRegister('timeframe')}
				isDirty={Boolean(formState.dirtyFields.timeframe)}
				onReset={() => resetField('timeframe')}
				showLabel
				error={harvestErrors('timeframe', formState, mutationResults)}
				label="timeframe"
			/>
			<SubtleTextbox
				{...validatingRegister('temporalResolution')}
				isDirty={Boolean(formState.dirtyFields.temporalResolution)}
				onReset={() => resetField('temporalResolution')}
				showLabel
				error={harvestErrors('temporalResolution', formState, mutationResults)}
				label="temporalResolution"
			/>
			<SubtleTextbox
				{...validatingRegister('measuredId')}
				isDirty={Boolean(formState.dirtyFields.measuredId)}
				onReset={() => resetField('measuredId')}
				showLabel
				error={harvestErrors('measuredId', formState, mutationResults)}
				label="measuredId"
			/>
			<SubtleTextbox
				{...validatingRegister('timestampId')}
				isDirty={Boolean(formState.dirtyFields.timestampId)}
				onReset={() => resetField('timestampId')}
				showLabel
				error={harvestErrors('timestampId', formState, mutationResults)}
				label="timestampId"
			/>
			<FormResults
				{...mutationResults}
				validationErrors={formState.errors}
				onSuccess={closeModal}
				onSuccessDelay={1500}
			/>
	</AppModal>
	);
};

const MetricCreationForm: FunctionComponent = () => {
	return <MetricCreationFormBase />;
};

export default MetricCreationForm;
