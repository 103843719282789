import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";
import {isValidEmail} from "../utils/typeGuards";

const EmailInput: FunctionComponent<ActionFieldProps> = (
    {
        defaultValue,
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {

    return <SubtleTextbox
        {...validatingRegister(
            'email', {
                required: 'Please enter a valid email address',
                validate: isValidEmail,
            })}
        label="Email"
        type='email'
        overrides={{root: {width: '20rem'}}}
        defaultValue={defaultValue}
        isDirty={Boolean(formState.dirtyFields.email)}
        onReset={() => resetField('email')}
        error={harvestErrors(
            'email',
            formState,
            mutationResults
        )}
        showLabel
    />;
}

export default EmailInput;
