import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleRadio from "./SubtleRadio";
import {harvestErrors} from "./SubtleTextBox";

const IsDerivationInput: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
    ) => {
    // <Typography id="isDerivation">
    //         Transformation will replace {plural}, derivation will
    //         create a new attribute
    //     {/*</Typography>*/}
    return <SubtleRadio
        {...validatingRegister('isDerivation')}
        showLabel
        name='isDerivation'
        options={[
            {name: true, label: 'Use derivation'},
            {name: false, label: 'Use transformation'},
        ]}
        validatingRegister={validatingRegister}
        defaultValue={false}
        alignItems={'center'}
        justifyContent={'space-between'}
        inline={true}
        label="Is Derivation"
        isDirty={Boolean(formState.dirtyFields.isDerivation)}
        onReset={() => resetField('isDerivation')}
        error={harvestErrors('isDerivation', formState, mutationResults)}
    />;
}

export default IsDerivationInput;
