import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleSelect from "./SubtleSelect";
import {harvestErrors} from "./SubtleTextBox";

interface AggregationTypeSelectParams extends ActionFieldProps {
    availableAggregations: any;
}

// //
// class AggregationTypes(StrEnum):
//     COUNTIF = 'countif'
//     ROW_NUMBER = 'row_number'
//     SUM = 'sum'
//     COUNT = 'count'
//     AVERAGE = 'average'
//     MEDIAN = 'median'
//     MODE = 'mode'
//     PERCENTILE = 'percentile'
//     MAX = 'max'
//     MIN = 'min'
//     LIST = 'string_agg'
// class NavigationTypes(StrEnum):
//     FIRST_VALUE = 'first_value'
//     LAST_VALUE = 'last_value'
//     NTH_VALUE = 'nth_value'
//     LEAD = 'lead'
//     LAG = 'lag'

const AggregationTypeSelect: FunctionComponent<AggregationTypeSelectParams> = ((
    {
        formState,
        resetField,
        validatingRegister,
        availableAggregations
    }
    ) => {
    // Select an aggregation to perform:

    // <select
    //             {...aggTypeRegisterProps}
    //             ref={(r) => {
    //                 setAggTypeRef(r);
    //                 registerRef(r);
    //             }}
    //             id="aggregationType"
    //             aria-errormessage={genErrorIdFromLabel(
    //                 'aggregationType'
    //             )}
    //             aria-invalid={formFieldHasErrors(
    //                 'aggregationType',
    //                 formState
    //             )}
    //         >
    return <SubtleSelect
        inline={true}
        label={`Other Type`}
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('aggregationType')}
        isDirty={Boolean(formState.dirtyFields.aggregationType)}
        {...validatingRegister('aggregationType')}
    >
        <option value="none">
            {availableAggregations.length > 0
                ? 'No aggregation selected'
                : 'No aggregations are available'}
        </option>
        {availableAggregations.map((agg: string) => (
            <option value={agg} key={agg}>
                {agg}
            </option>
        ))}
    </SubtleSelect>;
});

export default AggregationTypeSelect;
