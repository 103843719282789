import {useAppDispatch} from '../../app/hooks';
import {
    FormResults,
    formFieldHasErrors,
    genErrorIdFromLabel,
} from '../Form';
import Textbox from '../inputs/TextBox';
import useModalType from '../../features/HUD/hooks/useModalType';
import {useCreateOrgMutation} from '../../features/api';
import React, {
    FormEvent,
    FunctionComponent,
    useCallback, useEffect,
    useState
} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "./AppModal";
import {setModalType} from '../../features/HUD/state/HUDSlice';
import {BaseOrg} from "../../features/ontology/types/orgTypes";
import {harvestErrors, SubtleTextbox} from "../inputs/SubtleTextBox";
import Heading from "../text/Heading";
import FlexContainer from "../FlexContainer";
import ProfileAvatar
    from "../../features/navigation/components/Navbar/ProfileAvatar";
import SubtleTextArea from "../inputs/SubtleTextArea";
import SubtleSelect from "../inputs/SubtleSelect";
import IconButton from "../buttons/IconButton";
import {
    faFacebook, faGithub,
    faInstagram,
    faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import {StyledGraphicContainer} from "../StyledGraphicContainer";
import {StyledGraphicCircle} from "../StyledCircle";

interface OrgCreationFormProps {
    isOpen: boolean;
    onClose: () => void;
    canEdit: boolean;
}

interface CreateOrgFormValues {
    avatarUrl: string;
    label: string;
    username: string;
    legalName: string;
    domainName: string;
    definition: string;
    orgType: string;
    location: string;
    industry: string;
    language: string;
    currency: string;
    timezone: string;
    linkedin: string;
    facebook: string;
    instagram: string;
    github: string;
}

// Accept orgId as a prop to avoid needing complicated route matching in tests for this form
export const OrgCreationFormBase: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
    const {canEdit} = modalProps;

    const {register, formState, resetField, handleSubmit, reset} =
        useForm<CreateOrgFormValues>({
            defaultValues: {},
        });

    // const syncMutationResult = useRef<any>(null);

    // useEffect(() => {
    // 	if (formState.isDirty) {
    // 		reset();
    // 		closeModal();
    // 	}
    // }, [formState, reset]);

    // 	// cache this return value to reset the mutation result every time form is opened or closed.
    // 	syncMutationResult.current = createOrg({
    // 		label,
    // 		username,
    // 		legalName,
    // 		domainName,
    // 		orgType,
    // 		location,
    // 		industry,
    // 		language,
    // 		currency,
    // 		timezone,
    // 		linkedin,
    // 		facebook,
    // 		instagram,
    // 		github
    // 		});
    // };

    const validatingRegister = useCallback(
        (inputName: keyof CreateOrgFormValues) =>
            register(inputName, {required: `${inputName} is required`,}),
        [register]
    );

    const [createOrg, mutationResults] = useCreateOrgMutation();

    const onSubmit: SubmitHandler<Omit<CreateOrgFormValues, '_id' | '_object'>> = (formVals) => {
        createOrg(formVals);
    };

    const [tzs, setTzs] = useState([]);

    const [currencies, setCurrencies] = useState<any>({
        AED: "United Arab Emirates Dirham",
        AFN: "Afghan Afghani",
        ALL: "Albanian Lek",
        AMD: "Armenian Dram",
        ANG: "Netherlands Antillean Guilder",
        AOA: "Angolan Kwanza",
        ARS: "Argentine Peso",
        AUD: "Australian Dollar",
        AWG: "Aruban Florin",
        AZN: "Azerbaijani Manat",
        BAM: "Bosnia-Herzegovina Convertible Mark",
        BBD: "Barbadian Dollar",
        BDT: "Bangladeshi Taka",
        BGN: "Bulgarian Lev",
        BHD: "Bahraini Dinar",
        BIF: "Burundian Franc",
        BMD: "Bermudan Dollar",
        BND: "Brunei Dollar",
        BOB: "Bolivian Boliviano",
        BRL: "Brazilian Real",
        BSD: "Bahamian Dollar",
        BTC: "Bitcoin",
        BTN: "Bhutanese Ngultrum",
        BWP: "Botswanan Pula",
        BYN: "Belarusian Ruble",
        BYR: "Belarusian Ruble (pre-2016)",
        BZD: "Belize Dollar",
        CAD: "Canadian Dollar",
        CDF: "Congolese Franc",
        CHF: "Swiss Franc",
        CLF: "Chilean Unit of Account (UF)",
        CLP: "Chilean Peso",
        CNH: "Chinese Yuan (Offshore)",
        CNY: "Chinese Yuan",
        COP: "Colombian Peso",
        CRC: "Costa Rican Colón",
        CUC: "Cuban Convertible Peso",
        CUP: "Cuban Peso",
        CVE: "Cape Verdean Escudo",
        CZK: "Czech Republic Koruna",
        DJF: "Djiboutian Franc",
        DKK: "Danish Krone",
        DOP: "Dominican Peso",
        DZD: "Algerian Dinar",
        EEK: "Estonian Kroon",
        EGP: "Egyptian Pound",
        ERN: "Eritrean Nakfa",
        ETB: "Ethiopian Birr",
        EUR: "Euro",
        FJD: "Fijian Dollar",
        FKP: "Falkland Islands Pound",
        GBP: "British Pound Sterling",
        GEL: "Georgian Lari",
        GGP: "Guernsey Pound",
        GHS: "Ghanaian Cedi",
        GIP: "Gibraltar Pound",
        GMD: "Gambian Dalasi",
        GNF: "Guinean Franc",
        GTQ: "Guatemalan Quetzal",
        GYD: "Guyanaese Dollar",
        HKD: "Hong Kong Dollar",
        HNL: "Honduran Lempira",
        HRK: "Croatian Kuna",
        HTG: "Haitian Gourde",
        HUF: "Hungarian Forint",
        IDR: "Indonesian Rupiah",
        ILS: "Israeli New Sheqel",
        IMP: "Manx pound",
        INR: "Indian Rupee",
        IQD: "Iraqi Dinar",
        IRR: "Iranian Rial",
        ISK: "Icelandic Króna",
        JEP: "Jersey Pound",
        JMD: "Jamaican Dollar",
        JOD: "Jordanian Dinar",
        JPY: "Japanese Yen",
        KES: "Kenyan Shilling",
        KGS: "Kyrgystani Som",
        KHR: "Cambodian Riel",
        KMF: "Comorian Franc",
        KPW: "North Korean Won",
        KRW: "South Korean Won",
        KWD: "Kuwaiti Dinar",
        KYD: "Cayman Islands Dollar",
        KZT: "Kazakhstani Tenge",
        LAK: "Laotian Kip",
        LBP: "Lebanese Pound",
        LKR: "Sri Lankan Rupee",
        LRD: "Liberian Dollar",
        LSL: "Lesotho Loti",
        LYD: "Libyan Dinar",
        MAD: "Moroccan Dirham",
        MDL: "Moldovan Leu",
        MGA: "Malagasy Ariary",
        MKD: "Macedonian Denar",
        MMK: "Myanma Kyat",
        MNT: "Mongolian Tugrik",
        MOP: "Macanese Pataca",
        MRO: "Mauritanian Ouguiya (pre-2018)",
        MRU: "Mauritanian Ouguiya",
        MTL: "Maltese Lira",
        MUR: "Mauritian Rupee",
        MVR: "Maldivian Rufiyaa",
        MWK: "Malawian Kwacha",
        MXN: "Mexican Peso",
        MYR: "Malaysian Ringgit",
        MZN: "Mozambican Metical",
        NAD: "Namibian Dollar",
        NGN: "Nigerian Naira",
        NIO: "Nicaraguan Córdoba",
        NOK: "Norwegian Krone",
        NPR: "Nepalese Rupee",
        NZD: "New Zealand Dollar",
        OMR: "Omani Rial",
        PAB: "Panamanian Balboa",
        PEN: "Peruvian Nuevo Sol",
        PGK: "Papua New Guinean Kina",
        PHP: "Philippine Peso",
        PKR: "Pakistani Rupee",
        PLN: "Polish Zloty",
        PYG: "Paraguayan Guarani",
        QAR: "Qatari Rial",
        RON: "Romanian Leu",
        RSD: "Serbian Dinar",
        RUB: "Russian Ruble",
        RWF: "Rwandan Franc",
        SAR: "Saudi Riyal",
        SBD: "Solomon Islands Dollar",
        SCR: "Seychellois Rupee",
        SDG: "Sudanese Pound",
        SEK: "Swedish Krona",
        SGD: "Singapore Dollar",
        SHP: "Saint Helena Pound",
        SLL: "Sierra Leonean Leone",
        SOS: "Somali Shilling",
        SRD: "Surinamese Dollar",
        SSP: "South Sudanese Pound",
        STD: "São Tomé and Príncipe Dobra (pre-2018)",
        STN: "São Tomé and Príncipe Dobra",
        SVC: "Salvadoran Colón",
        SYP: "Syrian Pound",
        SZL: "Swazi Lilangeni",
        THB: "Thai Baht",
        TJS: "Tajikistani Somoni",
        TMT: "Turkmenistani Manat",
        TND: "Tunisian Dinar",
        TOP: "Tongan Paʻanga",
        TRY: "Turkish Lira",
        TTD: "Trinidad and Tobago Dollar",
        TWD: "New Taiwan Dollar",
        TZS: "Tanzanian Shilling",
        UAH: "Ukrainian Hryvnia",
        UGX: "Ugandan Shilling",
        USD: "United States Dollar",
        UYU: "Uruguayan Peso",
        UZS: "Uzbekistan Som",
        VEF: "Venezuelan Bolívar Fuerte",
        VND: "Vietnamese Dong",
        VUV: "Vanuatu Vatu",
        WST: "Samoan Tala",
        XAF: "CFA Franc BEAC",
        XAG: "Silver (troy ounce)",
        XAU: "Gold (troy ounce)",
        XCD: "East Caribbean Dollar",
        XDR: "Special Drawing Rights",
        XOF: "CFA Franc BCEAO",
        XPD: "Palladium Ounce",
        XPF: "CFP Franc",
        XPT: "Platinum Ounce",
        YER: "Yemeni Rial",
        ZAR: "South African Rand",
        ZMK: "Zambian Kwacha (pre-2013)",
        ZMW: "Zambian Kwacha"
    })

    // placeholder submission handlers
    // const onTZSubmit = (e: FormEvent) => {
    //     e.preventDefault();
    //     // @ts-ignore
    //     const fd = new FormData(e.target);
    //
    //     for (let [key, val] of fd) {
    //         console.log('key:', key, 'val:', val);
    //     }
    // };

    // const onCurrencySubmit = (e: FormEvent) => {
    //     e.preventDefault();
    //     // @ts-ignore
    //     const fd = new FormData(e.target);
    //
    //     for (let [key, val] of fd) {
    //         console.log('key:', key, 'val:', val);
    //     }
    // };

    // fetch data to populate timezone select
    useEffect(() => {
        fetch('https://worldtimeapi.org/api/timezone')
            .then((res) => res.json())
            .then(setTzs);
    }, []);

    // // fetch data to populate currency select
    // useEffect(() => {
    //     fetch('https://worldtimeapi.org/api/timezone')
    //         .then((res) => res.json())
    //         .then(setCurrencies);
    // }, []);

    return (
        <AppModal
            label={"Create Organization"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit}>

            <Heading style={{padding: 0, margin: 0}} component="h3">
                Profile Picture
            </Heading>
            <FlexContainer flexDirection={'column'} gap={'1rem'}
                           alignItems='center'>
                <StyledGraphicContainer
                    height={'10rem'}
                    width={'10rem'}
                    style={{alignSelf: 'center', justifySelf: 'center'}}>
                    <StyledGraphicCircle/>
                </StyledGraphicContainer>
                <SubtleTextbox
                    {...register('avatarUrl')}
                    label="Avatar URL"
                    isDirty={Boolean(formState.dirtyFields.avatarUrl)}
                    onReset={() => resetField('avatarUrl')}
                    error={harvestErrors(
                        'avatarUrl',
                        formState,
                        mutationResults
                    )}
                />
            </FlexContainer>
            <Heading style={{padding: 0, margin: 0}} component="h3">
                Intro
            </Heading>
            <SubtleTextbox
                {...validatingRegister('username')}
                showLabel
                label="Username"
                isDirty={Boolean(formState.dirtyFields.username)}
                onReset={() => resetField('username')}
                error={harvestErrors(
                    'username',
                    formState,
                    mutationResults
                )}
            />
            <SubtleTextbox
                {...register('label')}
                label="Display name"
                isDirty={Boolean(formState.dirtyFields.label)}
                onReset={() => resetField('label')}
                error={harvestErrors(
                    'label',
                    formState,
                    mutationResults
                )}
                showLabel
            />
            <SubtleTextbox
                {...register('legalName')}
                label="Legal name"
                isDirty={Boolean(formState.dirtyFields.legalName)}
                onReset={() => resetField('legalName')}
                error={harvestErrors(
                    'legalName',
                    formState,
                    mutationResults
                )}
                showLabel
            />
            <SubtleSelect
                {...validatingRegister('orgType')}
                label="Type"
                inline={true}
                defaultValue={'enterprise'}
                isDirty={Boolean(formState.dirtyFields.orgType)}
                onReset={() => resetField('orgType')}
                error={harvestErrors(
                    'orgType',
                    formState,
                    mutationResults
                )}
            >{[
                {label: 'Enterprise', name: 'enterprise'},
                {label: 'Media', name: 'media'},
                {label: 'Government', name: 'government'},
                {label: 'Non-Profit', name: 'nonProfit'}
            ].map((type) => {
                return <option key={type.name} value={type.name}>
                    {type.label}
                </option>
            })}
            </SubtleSelect>
            <SubtleTextbox
                {...register('industry')}
                showLabel
                label="Industry"
                isDirty={Boolean(formState.dirtyFields.industry)}
                onReset={() => resetField('industry')}
                error={harvestErrors(
                    'industry',
                    formState,
                    mutationResults
                )}
            />
            <Heading style={{padding: 0, margin: 0}} component="h3">
                Bio
            </Heading>
            <SubtleTextArea
                {...register('definition')}
                label="Bio"
                defaultValue={'Describe your org'}
                isDirty={Boolean(formState.dirtyFields.definition)}
                onReset={() => resetField('definition')}
                error={harvestErrors(
                    'definition',
                    formState,
                    mutationResults
                )}
            />
            <Heading style={{padding: 0, margin: 0}} component="h3">
                Locale
            </Heading>
            <SubtleTextbox
                {...register('location')}
                showLabel
                label="Location"
                isDirty={Boolean(formState.dirtyFields.location)}
                onReset={() => resetField('location')}
                error={harvestErrors(
                    'location',
                    formState,
                    mutationResults
                )}
            />
            <SubtleTextbox
                {...register('language')}
                showLabel
                label="Language"
                isDirty={Boolean(formState.dirtyFields.language)}
                onReset={() => resetField('language')}
                error={harvestErrors(
                    'language',
                    formState,
                    mutationResults
                )}
            />
            <SubtleSelect
                {...register('currency')}
                label="Currency"
                isDirty={Boolean(formState.dirtyFields.currency)}
                onReset={() => resetField('currency')}
                error={harvestErrors(
                    'currency',
                    formState,
                    mutationResults
                )}
            >{currencies && Object.keys(currencies).sort((a: string, b: string) => currencies[a].charCodeAt(0) - currencies[b].charCodeAt(0)).map((code: string) => (
                <option key={code} value={code}>{currencies[code]}</option>
            ))}</SubtleSelect>
            <SubtleSelect
                {...register('timezone')}
                label="Timezone"
                isDirty={Boolean(formState.dirtyFields.timezone)}
                onReset={() => resetField('timezone')}
                error={harvestErrors(
                    'timezone',
                    formState,
                    mutationResults
                )}
            >{tzs && tzs.map((tz) => (
                <option key={tz} value={tz}>{tz}</option>
            ))}</SubtleSelect>
            <Heading style={{padding: 0, margin: 0}} component="h3">
                Website
            </Heading>
            <SubtleTextbox
                {...register('domainName')}
                label="Web Domain"
                isDirty={Boolean(formState.dirtyFields.domainName)}
                onReset={() => resetField('domainName')}
                error={harvestErrors(
                    'domainName',
                    formState,
                    mutationResults
                )}
            />
            <Heading style={{padding: 0, margin: 0}} component="h3">
                Social
            </Heading>
            <FlexContainer alignItems={'center'}>
                <IconButton
                    icon={faLinkedin as any}
                    tooltip=""
                    size="xs"
                    iconSize='lg'
                    style={{marginLeft: '.25rem'}}
                    onClick={() => {
                    }}
                    showTooltip={true}
                />
                <SubtleTextbox
                    {...register('linkedin')}
                    label="LinkedIn"
                    isDirty={Boolean(formState.dirtyFields.linkedin)}
                    onReset={() => resetField('linkedin')}
                    error={harvestErrors(
                        'linkedin',
                        formState,
                        mutationResults
                    )}
                />
            </FlexContainer>
            <FlexContainer alignItems={'center'}>
                <IconButton
                    icon={faFacebook as any}
                    tooltip=""
                    size="xs"
                    iconSize='lg'
                    style={{marginLeft: '.25rem'}}
                    onClick={() => {
                    }}
                    showTooltip={true}
                />
                <SubtleTextbox
                    {...register('facebook')}
                    label="Facebook"
                    isDirty={Boolean(formState.dirtyFields.facebook)}
                    onReset={() => resetField('facebook')}
                    error={harvestErrors(
                        'facebook',
                        formState,
                        mutationResults
                    )}
                />
            </FlexContainer>
            <FlexContainer alignItems={'center'}>
                <IconButton
                    icon={faInstagram as any}
                    tooltip=""
                    size="xs"
                    iconSize='lg'
                    style={{marginLeft: '.25rem'}}
                    onClick={() => {
                    }}
                    showTooltip={true}
                />
                <SubtleTextbox
                    {...register('instagram')}
                    label="Instagram"
                    isDirty={Boolean(formState.dirtyFields.instagram)}
                    onReset={() => resetField('instagram')}
                    error={harvestErrors(
                        'instagram',
                        formState,
                        mutationResults
                    )}
                />
            </FlexContainer>
            <FlexContainer alignItems={'center'}>
                <IconButton
                    icon={faGithub as any}
                    tooltip=""
                    size="xs"
                    iconSize='lg'
                    style={{marginLeft: '4px'}}
                    onClick={() => {
                    }}
                    showTooltip={true}
                />
                <SubtleTextbox
                    {...register('github')}
                    label="Github"
                    isDirty={Boolean(formState.dirtyFields.github)}
                    onReset={() => resetField('github')}
                    error={harvestErrors(
                        'github',
                        formState,
                        mutationResults
                    )}
                />
            </FlexContainer>
            <FormResults
                {...mutationResults}
                onSuccess={closeModal}
                validationErrors={formState.errors}
                onSuccessDelay={500}
            />
        </AppModal>
    );
};

const OrgCreationForm: FunctionComponent = () => {
    // const { activeItem } = useActiveOrgData();

    // const orgId = activeItem ? activeItem._id : null;

    return <OrgCreationFormBase/>;
};

export default OrgCreationForm;
