import Typography, {ColorProp} from "./text/Typography";
import React, {FunctionComponent} from "react";

const StyledCopyright: FunctionComponent<{color?: ColorProp}> = (
    {color='darkerBaby'}
    ) =>
    <Typography
        style={{textAlign: 'center'}}
        variant={'overline'}
        color={color}>
        Futuremodel © 2022
    </Typography>;


export default StyledCopyright;
