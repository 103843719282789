import { isNewIndividualIdentifier } from '../helpers';
import CommentContext from '../state/CommentContext';
import {
	BaseComment,
	CommentCreationId,
	CommentSubject
} from '../types/commentTypes';
import CreateCommentForm from './CreateCommentForm';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {List, ListItem} from 'common/List';
import {useGetCommentsQuery} from 'features/api';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import React, {
    FunctionComponent,
    useState
} from 'react';
import {Accordion} from 'react-accessible-accordion';
import { ID } from 'react-accessible-accordion/dist/types/components/ItemContext';
import { CSSProperties } from 'styled-components';
import FlexContainer from "../../FlexContainer";
import ReactionsModal from "../../modals/ReactionsModal";
import Comment from "./Comment";
import {useAppDispatch} from "../../../app/hooks";
import {setModalType} from "../../../features/HUD/state/HUDSlice";

interface Overrides {
	list?: CSSProperties;
}

interface CommentListProps {
	objectType: CommentSubject;
	objectIdentifier: CommentCreationId | null;
	canEdit: boolean;
	overrides?: Overrides;
	isDisplayed?: boolean;
}

const CommentList: FunctionComponent<CommentListProps> = ({
	overrides,
	canEdit,
    isDisplayed= true,
	objectIdentifier,
	objectType
}) => {
	const appDispatch = useAppDispatch();

	const [expanded, setExpanded] = useState<ID[]>([]);
	const [reactionsComment, setReactionsComment] = useState<BaseComment | null>(null);

	const [formOpen, setFormOpen] = useState(false);

	const { data, ...commentLoadState } = useGetCommentsQuery(
		objectIdentifier && !isNewIndividualIdentifier(objectIdentifier)
			? {
					objectType,
					objectId: objectIdentifier.objectId,
			  }
			  : skipToken
	);

	return (
		<CommentContext.Provider
			value={{
				objectIdentifier,
				objectType,
				canEdit,
			}}
		>
			<FlexContainer
				style={{height: '100%'}}
				flexDirection={'column'}
				justifyContent={'flex-start'}>
                {isDisplayed
                    ? <Accordion style={{padding: '.25rem'}}
                    allowZeroExpanded={true}
                    onChange={(ids) => setExpanded(ids)}>
                    <List>
                        <DisplayOnLoad
                            {...commentLoadState}
                            toggleOnRefetch={true}
                            toggleOnUninitialized={false}
                        >
                            {data && data.items.length > 0 && data.items.map((comment) => (
                                <Comment key={comment._id} comment={comment} />
                            ))}
                        </DisplayOnLoad>
                    </List>
                </Accordion>
                    : <ListItem>
                    {data && data.items.length === 1
                        ? "View 1 more comment"
                        : data && data.items.length > 1
                            ? `View ${data.items.length} previous comments`
                            : null}
                    </ListItem>
                }
					{objectType !== 'Comment'
						?  <div style={{padding: '.25rem'}}>
							<CreateCommentForm isOpen={true} />
					</div> : null}
			</FlexContainer>
		</CommentContext.Provider>
	);
};

export default CommentList;
