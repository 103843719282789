import {List} from 'common/List';
import {PopoverBase} from 'common/popovers/Popover';
import Spinner from 'common/loading/Spinner';
import Typography from 'common/text/Typography';
import DomainItem from 'common/List/DomainItem';
import DomainMenu from 'common/menus/DomainMenu';
import {parseQueryError} from 'features/api/helpers';
import {useGetDomainsQuery} from 'features/api';
import {
    BaseDomain,
    DomainModalTypes
} from 'features/ontology/types/domainTypes';
import React, {FunctionComponent, useCallback, useState} from 'react';
import {StyledDomainListItem} from "common/List/DomainItem";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import RefreshedIcon from "../icons/RefreshedIcon";
import ViewsIcon from "../icons/ViewsIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FlexContainer from "../FlexContainer";
import ResourcePopover from "../popovers/ResourcePopover";
import OrgMenu from "../menus/OrgMenu";
import {BaseOrg, OrgModalTypes} from "../../features/ontology/types/orgTypes";


type AdminPanelProps = {
    org: BaseOrg;
    canEdit: boolean;
};

const DomainHeader: FunctionComponent = () => {
    return <FlexContainer alignItems='center' justifyContent='flex-start'>
        <StyledDomainListItem>
            <div style={{width: '50px', gridArea: 'avatar'}}/>
            <div style={{width: '100%', gridArea: 'domain'}}>
                <Typography variant='h6'>
                    TITLE
                </Typography>
            </div>
            <div style={{width: '100%', gridArea: 'entities'}}>
                <Typography variant='h6'>
                    FEATURING
                </Typography>
            </div>
            <div style={{width: '100%', gridArea: 'views', textAlign: 'right'}}>
                <Typography variant='h6'>
                    <FontAwesomeIcon icon={ViewsIcon}/>
                </Typography>
            </div>
            <div style={{
                width: '100%',
                gridArea: 'refreshed',
                textAlign: 'right'
            }}>
                <Typography variant='h6'>
                    <FontAwesomeIcon icon={RefreshedIcon}/>
                </Typography>
            </div>
        </StyledDomainListItem>
        <div style={{width: '25px'}}/>
    </FlexContainer>
}


const DomainPanel: FunctionComponent<{props: any;}> = ({props}) => {

    const {org, canEdit} = props;

    const [popoverResource, setPopoverResource] = useState<any | null>(null);

    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    const {
        data: domains,
        isLoading,
        isError,
        isUninitialized,
        error,
    } = useGetDomainsQuery({orgId: org._id});

    const [domainErr, setDomainErr] = useState<Record<string, string>>({});

    const updateDomainErr = useCallback(
        (msg: string, id: number) => setDomainErr(
            (p) => ({...p, [id]: msg})
        ), []
    );

    if (!!domains && domains.length > 0) {

        if (isError) {
            return (
                <Typography paragraph color="error">
                    {parseQueryError(error).message}
                </Typography>
            );
        }

        if (isLoading || isUninitialized) {
            return <Spinner/>;
        }

        return (
            <>
                {domains.length > 0
                    ? <>
                        <List aria-labelledby='org-domains-list'>
                            <DomainHeader/>
                            {(domains as BaseDomain[]).map((domain) => {
                                return <DomainItem
                                    key={`domains-${domain._id}`}
                                    domain={domain}
                                    canEdit={canEdit}
                                    handleError={domainErr}
                                    setPopover={(e: any, resource: any) => {
                                        setAnchor(e.currentTarget);
                                        setPopoverResource(resource);
                                    }}
                                    onClick={(e: any) => {
                                        setAnchor(e.currentTarget);
                                        setPopoverResource(domain);
                                    }}
                                />
                            })}
                        </List>
                        <PopoverBase
                            anchorEl={anchor}
                            handleClickAway={() => setPopoverResource(null)}
                            open={!!popoverResource}>
                            {popoverResource &&
                            <ResourcePopover resource={popoverResource}/>
                            }
                        </PopoverBase>
                    </>
                    : <FlexContainer>
                        Connect a source
                    </FlexContainer>
                }
            </>
        );
    } else {
        return null
    }

};

const Domains: FunctionComponent<{org: BaseOrg; variant: string;}> = ({variant, org}) => {
    if (variant === 'list') {
        return <DomainPanel props={{org: org, openModal: null, canEdit: true}} />
    }

    return null;
}

export default DomainPanel;
