import { useAppDispatch } from 'app/hooks';
import { pipe } from 'common/utils/functionUtils';
import useHUDIndividual from 'features/HUD/hooks/useHUDIndividual';
import { viewsAvailable } from 'features/HUD/state/HUDSlice';
import { clearDrawerIndividual } from 'features/HUD/state/HUDSlice';
import { deriveEntityViews } from 'features/compositeViews/helpers';
import EntityGraph from 'features/graph/components/EntityGraph';
import EntitySpatial from 'features/spatial/components/EntitySpatial';
import useRenderView, {
	RenderViewConfig,
} from 'features/navigation/hooks/useRenderView';
import useActiveEntityData from 'features/ontology/hooks/useActiveEntityData';
import { default as EntityProfile } from 'features/profile/EntityProfile';
import React, { FunctionComponent, useEffect } from 'react';
import useActiveOrgData from "../../ontology/hooks/useActiveOrgData";
import useActiveDomainData from "../../ontology/hooks/useActiveDomainData";
import EntityMeta from "../../../common/meta/EntityMeta";
import OrgMeta from "../../../common/meta/OrgMeta";
import DomainMeta from "../../../common/meta/DomainMeta";

const configs: RenderViewConfig = {
	viewDefault: 'profile',
	views: [
		{ match: 'profile', element: <EntityProfile /> },
		{ match: 'graph', element: <EntityGraph /> },
		{ match: 'spatial', element: <EntitySpatial /> },
	],
};

// TODO: the only way I can think of to integration test this is complicated
// to setup, fragile, and tests implementation rather than behavior.
// A functional test would be appropriate here: render the whole app,
// and test clicking through the Entity popover view buttons, then test
// that view toggle buttons render correct UI.
const EntityViews: FunctionComponent = () => {
	const { activeItem: activeOrg } = useActiveOrgData();
	const { activeItem: activeDomain } = useActiveDomainData();
	const { activeItem } = useActiveEntityData();

	const individualInRightDrawer = !!useHUDIndividual();

	const appDispatch = useAppDispatch();

	const render = useRenderView(configs);

	useEffect(() => {
		if (activeItem) {
			pipe(activeItem, deriveEntityViews, viewsAvailable, appDispatch);
		}

		return () => pipe([], viewsAvailable, appDispatch) as void;
	}, [activeItem, appDispatch]);

	// when entity views unmount, clear any individual data that may
	// be on display in right HUD drawer.
	useEffect(() => {
		return () => {
			if (individualInRightDrawer) {
				appDispatch(clearDrawerIndividual());
			}
		};
	}, [individualInRightDrawer, appDispatch]);

	if (!!activeDomain && !!activeItem) {
		return <>
			{render()}
		</>;
	} else {
		return null;
	}
};

export default EntityViews;
