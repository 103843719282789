import { useGetAttributesQuery, useGetIndividualsQuery } from '../../api';
import { mergeQueryStates } from '../../api/helpers';
import dataPrepFactory from '../../dataPreparation/dataPrepFactory';
import { UseIndividualsConfig } from '../types/hookTypes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import { useMemo } from 'react';

export const defaultConfig: UseIndividualsConfig = {
	filter: false,
};

// TODO: this can be optimized with 'selectFromResult'.
const useIndividualsMeta = (
	entityId: number | null | undefined,
	config: UseIndividualsConfig,
	identifier: string | null | undefined
) => {
	const { getAllFilters } = useEntitySearchParams();

	const { data: individuals, ...individualsLoadState } =
		useGetIndividualsQuery(
			entityId && identifier
				? { entityId, identifier }
				: entityId
				? {entityId}
				: skipToken);

	const { data: attrs, ...attrLoadState } = useGetAttributesQuery(
		entityId ? { entityId } : skipToken
	);

	const queryLoadState = mergeQueryStates(
		individualsLoadState,
		attrLoadState
	);

	const preparedData = useMemo(() => {
		if (individuals && attrs) {
			const filters = config.filter ? getAllFilters() : undefined;
			const preparer = dataPrepFactory(attrs, individuals);
			preparer.process(filters);
			return preparer;
		}

		return null;
		// NB: this memoization is only helpful b/c useEntitySearchParams is also memoized
	}, [individuals, attrs, config.filter, getAllFilters]);

	return { ...queryLoadState, preparedData };
};

export default useIndividualsMeta;
