import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";

const PluralInput: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
    ) => {
    return <SubtleTextbox
        {...validatingRegister('plural')}
        showLabel
        alignItems={'center'}
        justifyContent={'space-between'}
        inline={true}
        label="Plural"
        isDirty={Boolean(formState.dirtyFields.plural)}
        onReset={() => resetField('plural')}
        error={harvestErrors('plural', formState, mutationResults)}
    />;
}

export default PluralInput;
