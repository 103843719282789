import FlexContainer from '../../../common/FlexContainer';
import {FormResults} from '../../../common/Form';
import Spinner from '../../../common/loading/Spinner';
import Typography from '../../../common/text/Typography';
import {useOperateAttrMutation} from '../../api';
import {mergeErrorStates, extractQueryErrMessage} from '../../api/helpers';
import {BaseAttribute} from '../../ontology/types/attributeTypes';
import {
    renderReferenceField,
    renderDerivationSubfields,
} from '../common/jsxHelpers';
import useAttrNeighbors from '../hooks/useAttrNeighbors';
import {
    multiplyAttrFormDefaults,
    multiplyAttrFormToPayload,
} from './multiplyAttributeHelpers';
import {MultiplyAttrFormValues} from './multiplyAttributeTypes';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import MissingValuesInput from "../../../common/inputs/MissingValuesInput";
import ValueTypeSelect from "../../../common/inputs/ValueTypeSelect";
import IsDerivationInput from "../../../common/inputs/IsDerivationInput";
import useModalType from "../../HUD/hooks/useModalType";
import Loading from "../../../common/loading/Loading";
import Uninitialized from "../../../common/loading/Uninitialized";

const MultiplyAttributeForm: FunctionComponent = () => {
    // const {_id, plural: , updatePopper, canEdit} = props;

    const {modalProps, closeModal} = useModalType();
    const {objectId: _id, canEdit} = modalProps;

    const formDefaults = multiplyAttrFormDefaults();

    const {handleSubmit, register, resetField, formState, watch} =
        useForm<MultiplyAttrFormValues>({
            defaultValues: formDefaults,
        });

    const [watchedDerivationFlag, watchedUsingReferenceValue] = watch([
        'isDerivation',
        'usingStaticReference',
    ]);

    const validatingRegister = useCallback(
        (inputName: keyof MultiplyAttrFormValues, options?: any) =>
            register(inputName, options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    // prevent filtering from running every render
    const filter = useCallback(
        (attr: BaseAttribute) => attr.type === 'quantity',
        []
    );

    const queryRes = useAttrNeighbors(_id, filter);

    // useEffect(() => {
    //     if (queryRes.isSuccess && closeModal) {
    //         closeModal();
    //     }
    // }, [queryRes.isSuccess, closeModal]);

    const [multiplyAttr, mutationResults] = useOperateAttrMutation();

    const mergedErrs = mergeErrorStates(queryRes, mutationResults);

    const onSubmit: SubmitHandler<MultiplyAttrFormValues> = (vals, e) => {
        e?.preventDefault();
        multiplyAttr({
            attrId: _id,
            body: multiplyAttrFormToPayload(vals),
        });
    };

    if (queryRes.isError) {
        return (
            <FlexContainer justifyContent="center">
                <Typography color="error" paragraph>
                    {extractQueryErrMessage(queryRes.error)}
                </Typography>
            </FlexContainer>
        );
    }

    return (
        <AppModal
            label={"Multiply Attribute"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit ? canEdit : true}>
            <IsDerivationInput
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            {renderDerivationSubfields({
                isDerivation: watchedDerivationFlag === 'true',
                formState,
                validatingRegister,
                resetField,
                mutationResults
            })}
            <ValueTypeSelect
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            {!queryRes.isUninitialized && !queryRes.isLoading
                ? renderReferenceField(
                    'otherId',
                    'otherValue',
                    watchedUsingReferenceValue === 'true',
                    validatingRegister,
                    queryRes.attrNeighbors,
                    formState
                )
                : queryRes.isUninitialized
                    ? <Uninitialized/>
                    : <Loading/>}
            <MissingValuesInput
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            <FormResults
                isError={!!mergedErrs}
                error={mergedErrs}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default MultiplyAttributeForm;
