import DataCrumbBase from './DataCrumbBase';
import { extractQueryErrMessage } from 'features/api/helpers';
import useDataCrumbQuery from 'features/navigation/hooks/useDataCrumbQuery';
import { DataContextLevels } from 'features/navigation/types/navigationContextTypes';
import { FunctionComponent } from 'react';

interface DataCrumbProps {
	kind: DataContextLevels;
	isTip: boolean;
}

const DataCrumb: FunctionComponent<DataCrumbProps> = ({ kind, isTip }) => {
	const { links, activeName, isError, error, isLoading, isUninitialized } = useDataCrumbQuery(kind);

	const status =
		isLoading || isUninitialized
			? 'loading'
			: isError
			? 'error'
			: 'success';

	const errMessage = error ? extractQueryErrMessage(error) : null;

	return (
		<DataCrumbBase
			isTip={isTip}
			activeName={activeName}
			kind={kind}
			status={status}
			errorMessage={errMessage}
			links={links}
		/>
	);
};

export default DataCrumb;
