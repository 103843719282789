// import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import FlexContainer from 'common/FlexContainer';
import Heading from 'common/text/Heading';
import Spinner from 'common/loading/Spinner';
import useElementSize from 'common/hooks/useSize';
import theme from 'common/theme/theme';
import { forceCollide } from 'd3-force';
import { useGetAttributeLineageQuery } from 'features/api';
import { drawNode } from 'features/graph/helpers';
import useSearchAttributeData from 'features/ontology/hooks/useSearchAttributeData';
import {
	AttrLineageEdge,
	AttrLineageNode,
} from 'features/ontology/types/lineageTypes';
import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import ReactForceGraph2d from 'react-force-graph-2d';
import useModalType from "../../../features/HUD/hooks/useModalType";
import useActiveDomainData
	from "../../../features/ontology/hooks/useActiveDomainData";
import AppModal from "../AppModal";

// type QueryResponse = Omit<
// 	ReturnType<typeof useGetAttributeLineageQuery>,
// 	'refetch'
// >;

const emptyLinks: AttrLineageEdge[] = [];
const emptyNodes: AttrLineageNode[] = [];

// const selectAttrLineage = createSelector(
// 	(res: QueryResponse) => res.data,
// 	(data: QueryResponse['data']) => {
// 		if (data) {
// 			return {
// 				links: data.edges.map((edge: AttrLineageEdge) => ({
// 					...edge,
// 				})),
// 				nodes: data.nodes.map((n: AttrLineageNode) => ({ ...n })),
// 			};
// 		}

// 		return {
// 			links: emptyLinks,
// 			nodes: emptyNodes,
// 		};
// 	}
// );

const AttributeLineageGraph: FunctionComponent = () => {

	const {modalProps, closeModal} = useModalType();
	const {resource, canEdit} = modalProps;

	const { data, isLoading } = useGetAttributeLineageQuery(
		resource ? { attributeId: resource._id } : skipToken
	);

	const graphData = useMemo(() => {
		if (data) {
			return {
				links: data.edges.map((edge: AttrLineageEdge) => ({
					...edge,
				})),
				nodes: data.nodes.map((n: AttrLineageNode) => ({ ...n })),
			};
		}

		return {
			links: emptyLinks,
			nodes: emptyNodes,
		};
	}, [data]);

	//  NB: we HAVE to use a 'true' ref here, not a callback ref, b/c ForceGraph2D
	// calls the function repeatedly, causing an infinite update if callback triggers a re-render
	const fgmRef = useRef();

	const [size, setSizeTarget] = useElementSize();

	const { width, height } = size;

	useEffect(() => {
		if (fgmRef.current) {
			//    Setting a collision force helps minimize the chance of links crossing
			//    @ts-ignore
			fgmRef.current.d3Force('collide', forceCollide(20));
		}
	}, []);

	if (!resource) {
		return (
			<FlexContainer justifyContent="center">
				<Heading component="h2" color="error">
					No attribute selected.
				</Heading>
			</FlexContainer>
		);
	}

	return (
		<div style={{width: '100%', height: '100%'}} ref={setSizeTarget}>
		<AppModal
			label={'Attribute Lineage'}
			isForm={false}
			isOpen={true}
			isDirty={false}
			onClose={closeModal}
			onSubmit={() => {}}
			canEdit={true}>
			<div
				//    Create a new stacking context to 'catch' graph canvas element's position: absolute
				style={{ position: 'relative', flexGrow: 1, overflow: 'hidden' }}
			>
				{isLoading && (
					<FlexContainer
						justifyContent="center"
						style={{
							// absolute positioning here prevents a resizing loop caused by the ResizeObserver
							// on parent div.
							position: 'absolute',
							width: '100%',
							height: '50px',
						}}
					>
						<Spinner />
					</FlexContainer>
				)}

				<ReactForceGraph2d
					linkDirectionalArrowLength={5}
					linkDirectionalArrowRelPos={1}
					ref={fgmRef as any}
					backgroundColor={'transparent'}
					width={width}
					height={height}
					graphData={graphData}
					dagLevelDistance={50}
					linkWidth={2}
					linkColor={() => theme.palette.divider}
					nodeId="_id"
					nodeLabel={(n: any) => n.label}
					nodeCanvasObject={drawNode('lineage')}
					dagMode="lr"
					cooldownTime={750}
					enableNodeDrag={false}
				/>
			</div>
		</AppModal>
		</div>
	);
};

export default AttributeLineageGraph;
