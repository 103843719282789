import ClearFiltersButton from '../../../common/buttons/ClearFiltersButton';
import ToggleCreationFormButton from '../../../common/buttons/CreateButton';
import RecenterButton from '../../../common/buttons/RecenterButton';
import ToggleTabDrawerButton from '../../../common/buttons/ToggleTabDrawerButton';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import {useParams} from "react-router-dom";
import useViewModeParams from "../../navigation/hooks/useViewModeParams";
import EntityLeftHUD from "../../../common/viz/EntityLeftHUD";
import TabDrawer from "./TabDrawer";
import FlexContainer from "../../../common/FlexContainer";
import Messages from "../../navigation/components/Navbar/Messages";
import Notifications from "../../navigation/components/Navbar/Notifications";
import AvatarGroup from "../../../common/avatars/AvatarGroup";
import {StyledProfileGridLayout} from "../../profile/common/styledComponents";
import useActiveOrgData from "../../ontology/hooks/useActiveOrgData";
import useMemberQueryHook from "../../governance/hooks/useMemberQueryHook";
import { BaseUser } from '../../authentication/types/userTypes';
import {OrgAuthorityLevel} from "../../governance/types/GovernanceTypes";

const StyledControls = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	height: calc(100vh - ${(p) => p.theme.navHeight * 2 - p.theme.panelGutter * 1.5}px);
	align-items: center;
	justify-content: space-between;
	top: calc(${(p) => p.theme.navHeight + p.theme.panelGutter * 2}px);
	right: ${(p) => p.theme.panelGutter}px;
	padding: 0;
	margin: 0;
	z-index: ${(p) => p.theme.zIndex.controlSurface};
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		display: none;
	}
`
const StyledControlBar = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 0;
	margin: 0;
	/* 32px is width of icon button at size "sm" */
	z-index: ${(p) => p.theme.zIndex.controlSurface};
`
	// top: calc(${(p) => p.theme.spacing(p.theme.navHeight / 8)} + ${(p) => p.theme.spacing(p.theme.panelGutter / 8 / 2)});

const StyledControlsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${(p) => p.theme.spacing(0.5)};
	justify-content: start;
	justify-items: start;
	align-content: flex-start;
	align-item: flex-start;
`;

const ControlBar: FunctionComponent = () => {
	const { entity } = useParams();
	const { getViewMode } = useViewModeParams();
	const { activeItem: activeOrg } = useActiveOrgData();

	const mode = getViewMode()

	const {
		data: memberData,
		// isLoading,
		// isError,
		// isUninitialized,
		// error,
	} = useMemberQueryHook('username', 'member', (!!activeOrg && activeOrg._id) ?? undefined);

	console.log(memberData)
	// const renderToggleHUD = !!entity && mode !== 'profile';
	// const renderSpatialHUD = !!entity && mode === 'spatial';
	const renderGraphHUD = mode === 'graph';
	const renderFilterHUD = !!entity && mode === 'profile' || mode === 'spatial';
	return (
		<StyledControls>
			<StyledControlBar>
				<StyledControlsWrapper>
					{/*<Messages />*/}

					{/*<ToggleTabDrawerButton display={renderGraphHUD} />*/}
					<ClearFiltersButton display={renderFilterHUD}/>
				</StyledControlsWrapper>
			</StyledControlBar>
			<StyledControlBar>
				<StyledControlsWrapper>
					<RecenterButton display={renderGraphHUD} />
					{/*<ToggleTabDrawerButton display={renderGraphHUD} />*/}
					<ClearFiltersButton display={renderFilterHUD}/>
				</StyledControlsWrapper>
			</StyledControlBar>
			<StyledControlBar>
				<StyledControlsWrapper>
					{/*<ToggleCreationFormButton />*/}
					{!!memberData && <AvatarGroup items={memberData as BaseUser[]}/>}
				</StyledControlsWrapper>
			</StyledControlBar>
		</StyledControls>
	);
};

export default ControlBar;
