import Typography from 'common/text/Typography';
import { isAppError } from 'common/utils/typeGuards';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useDispatchableError from 'features/errorHandling/hooks/useDispatchableErr';
import { AppError } from 'features/errorHandling/types/errorTypes';
import {
	contextNameFromContextIdx,
	URLParamsToContextArray,
} from 'features/navigation/helpers';
import { ContextLadder } from 'features/navigation/types/navigationContextTypes';
import useActiveOrgData from 'features/ontology/hooks/useActiveOrgData';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import useActiveEntityData from 'features/ontology/hooks/useActiveEntityData';
import useActiveIndividualData from 'features/ontology/hooks/useActiveIndividualData';
import { isKnownIndividual } from 'features/ontology/types/individualTypes';
import QuestionList from 'common/questions/components/QuestionList';
import {GeneralQuestionCreationId} from 'common/questions/types/questionTypes';
import {CreationId} from 'common/questions/helpers';
import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const IndividualQuestionsList: FunctionComponent = () => {
	const { activeItem, ...individualLoadState } = useActiveIndividualData();

	const identifier: CreationId | null = activeItem
		? isKnownIndividual(activeItem.individual)
			? { kind: 'other', objectId: activeItem.individual._id }
			: {
					kind: 'newIndividual',
					primaryAttributeValue: activeItem.valueAtPrimaryAttr,
					attributeId: activeItem.primaryAttrId,
			  }
		: null;

	return (
		<DisplayOnLoad {...individualLoadState}>
			{activeItem && identifier && (
				<QuestionList
					parentObjectIdentifier={identifier}
					objectType={'Individual'}
					canEdit={true}
					overrides={{
						list: {
							maxHeight: '100%',
						},
					}}
				/>
			)}
		</DisplayOnLoad>
	);
};

export const EntityQuestionsList: FunctionComponent = () => {
	const { activeItem: activeEntity, ...entityLoadState } =
		useActiveEntityData();

	const identifier: GeneralQuestionCreationId | null = activeEntity
		? {
				kind: 'other',
				objectId: activeEntity._id,
		  }
		: null;

	const canEdit = activeEntity
		? activeEntity.userContext.permissions.edit
		: false;

	return (
		<DisplayOnLoad {...entityLoadState}>
			{activeEntity && identifier && (
				<QuestionList
					parentObjectIdentifier={identifier}
					objectType={'Entity'}
					canEdit={canEdit}
					overrides={{
						list: {
							maxHeight: 'auto',
						},
					}}
				/>
			)}
		</DisplayOnLoad>
	);
};

export const DomainQuestionsList: FunctionComponent = () => {
	const { activeItem: activeDomain, ...domainLoadState } =
		useActiveDomainData();

	const identifier: GeneralQuestionCreationId | null = activeDomain
		? {
				kind: 'other',
				objectId: activeDomain._id,
		  }
		: null;

	const canEdit = activeDomain
		? activeDomain.userContext.permissions.edit
		: false;

	return (
		<DisplayOnLoad {...domainLoadState}>
			{activeDomain && identifier && (
				<QuestionList
					parentObjectIdentifier={identifier}
					objectType={'Domain'}
					canEdit={canEdit}
					overrides={{
						list: {
							maxHeight: 'auto',
						},
					}}
				/>
			)}
		</DisplayOnLoad>
	);
};

export const OrgQuestionsList: FunctionComponent = () => {
	const { activeItem: activeOrg, ...orgLoadState } =
		useActiveOrgData();

	const identifier: GeneralQuestionCreationId | null = activeOrg
		? {
				kind: 'other',
				objectId: activeOrg._id,
		  }
		: null;

	const canEdit = activeOrg
		? activeOrg.userContext.permissions.edit
		: false;

	return (
		<DisplayOnLoad {...orgLoadState}>
			{activeOrg && identifier && (
				<QuestionList
					parentObjectIdentifier={identifier}
					objectType={'Organization'}
					canEdit={canEdit}
					overrides={{
						list: {
							maxHeight: 'auto',
						},
					}}
				/>
			)}
		</DisplayOnLoad>
	);
};
