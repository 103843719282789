import {FormResults,} from '../../../common/Form';
import {useDescribeMetricMutation} from '../../api';
import {MetricActionFormProps} from '../common/commonTypes';
import {renderDerivationSubfields} from '../common/jsxHelpers';
import {
    describeMetricFormDefaults,
    describeMetricFormToPayload,
} from './describeMetricHelpers';
import {DescribeMetricFormValues} from './describeMetricTypes';
import React, {FunctionComponent, useCallback} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import MetricTypeSelect from "../../../common/inputs/MetricTypeSelect";
import {useAppDispatch} from "../../../app/hooks";
import useModalType from "../../HUD/hooks/useModalType";
import {setModalType} from "../../HUD/state/HUDSlice";
import {BaseMetric} from "../../ontology/types/metricTypes";
import {PrependAttrFormValues} from "../prependAttribute/prependAttributeTypes";

// const quantitySubtypeOptions = [
// 	{ displayValue: 'None', value: '' },
// 	{ displayValue: 'Distance', value: 'distance' },
// 	{ displayValue: 'Duration', value: 'duration' },
// 	{ displayValue: 'Currency', value: 'currency' },
// 	{ displayValue: 'Ratio', value: 'ratio' },
// 	{ displayValue: 'Rate', value: 'rate' },
// ];
//
// const locationSubtypeOptions = [
// 	{ displayValue: 'Geography', value: 'geography' },
// 	{ displayValue: 'None', value: '' },
// ];

const DescribeMetricForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
	const {resource, canEdit} = modalProps;
    const {objectId: _id} = resource;


    const formDefaults = describeMetricFormDefaults(resource as BaseMetric);

    const {handleSubmit, register, resetField, reset, formState} =
        useForm<DescribeMetricFormValues>({
            defaultValues: formDefaults,
        });

    // watch type field to determine what to display in
    // subtype menu
    // const watchedMetricType = watch('type', formDefaults.type);

    // when type changes we reset the subtype to make sure we don't get a mismatch between
    // type and subtype values.
    // useEffect(() => {
    // 	resetField('otherType');
    // }, [watchedMetricType, resetField]);


    const [describeMetric, mutationResults] = useDescribeMetricMutation();

    const onSubmit: SubmitHandler<DescribeMetricFormValues> = (vals, e) => {
        e?.preventDefault();
        describeMetric({
            sourceMetricId: _id,
            body: describeMetricFormToPayload(vals),
        });
    };

    const validatingRegister = useCallback(
        (inputName: keyof DescribeMetricFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    // only present option to choose a subtype if main type is appropriate
    // const renderSubtypeSelect = useCallback(() => {
    // 	const shouldRender =
    // 		watchedMetricType === 'quantity' || watchedMetricType === 'location';
    //
    // 	if (!shouldRender) {
    // 		return null;
    // 	}
    //
    // 	const opts =
    // 		watchedMetricType === 'quantity'
    // 			? quantitySubtypeOptions
    // 			: locationSubtypeOptions;
    //
    // 	return (
    // 		<>
    // 			<label htmlFor="otherType">
    // 				<Typography>
    // 					What is the subtype of this metric?
    // 				</Typography>
    // 			</label>
    // 			<select {...register('otherType')}>
    // 				{opts.map(({ value, displayValue }) => (
    // 					<option value={value} key={value}>
    // 						{displayValue}
    // 					</option>
    // 				))}
    // 			</select>
    // 		</>
    // 	);
    // }, [watchedMetricType, register]);

    return (
        <AppModal
            label={"Describe Metric"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit ? canEdit : true}>
            {renderDerivationSubfields({
                isDerivation: true,
                formState,
                resetField,
                validatingRegister,
                mutationResults
            })}
            <MetricTypeSelect
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />

            {/*{renderSubtypeSelect()}*/}

            {/*<label htmlFor="default-value">*/}
            {/*	<Typography>*/}
            {/*		What is the default value for this metric?*/}
            {/*	</Typography>*/}
            {/*</label>*/}
            {/*<input*/}
            {/*	type="text"*/}
            {/*	{...register('defaultValue')}*/}
            {/*	id="default-value"*/}
            {/*/>*/}

            {/*<Typography id="isRequired">*/}
            {/*	Is this metric required?*/}
            {/*</Typography>*/}
            {/*<div*/}
            {/*	role="radiogroup"*/}
            {/*	aria-labelledby="isRequired"*/}
            {/*	aria-errormessage={genErrorIdFromLabel('isRequired')}*/}
            {/*	aria-invalid={formFieldHasErrors(*/}
            {/*		'isRequired',*/}
            {/*		formState*/}
            {/*	)}*/}
            {/*>*/}
            {/*	<div>*/}
            {/*		<input*/}
            {/*			id="isRequired-true"*/}
            {/*			type="radio"*/}
            {/*			value="true"*/}
            {/*			{...register('isRequired', {*/}
            {/*				required: {*/}
            {/*					value: true,*/}
            {/*					message:*/}
            {/*						'A value for isRequired is required',*/}
            {/*				},*/}
            {/*			})}*/}
            {/*		/>*/}
            {/*		<label htmlFor="isRequired-true">*/}
            {/*			<Typography>Yes</Typography>*/}
            {/*		</label>*/}
            {/*	</div>*/}
            {/*	<div>*/}
            {/*		<input*/}
            {/*			id="isRequired-false"*/}
            {/*			type="radio"*/}
            {/*			value="false"*/}
            {/*			{...register('isRequired', {*/}
            {/*				required: {*/}
            {/*					value: true,*/}
            {/*					message:*/}
            {/*						'A value for isRequired is required',*/}
            {/*				},*/}
            {/*			})}*/}
            {/*		/>*/}
            {/*		<label htmlFor="isRequired-false">*/}
            {/*			<Typography>No</Typography>*/}
            {/*		</label>*/}
            {/*	</div>*/}
            {/*</div>*/}


            <FormResults
                {...mutationResults}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default DescribeMetricForm;
