import React, {FunctionComponent} from "react";
import ProfileAvatar
    from "../../features/navigation/components/Navbar/ProfileAvatar";
import {ListItemControls} from "./index";
import theme from "../theme/theme";
import Error from "../text/Error";
import Typography from "../text/Typography";
import FlexContainer from "../FlexContainer";
import FollowButton from "../buttons/FollowButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCurrentUser from "../../features/authentication/hooks/useUser";
import FullName from "../text/FullName";
import {
    useFollowUserMutation,
    useUnfollowUserMutation
} from "../../features/api";
import {ReactionTypes} from "../activities/types/activityTypes";
import {useNavigate} from "react-router";


const ResourceType: FunctionComponent<{resource: any, styles?: any}> = ({resource, styles}) => {
      return <Typography id={`${resource._object}-${resource._id}`} style={styles}>
         <FontAwesomeIcon
              color={theme.palette.primary.main}
              icon={ReactionTypes.like.icon}
          />
      </Typography>
}

export const ReactionItem: FunctionComponent<{resource: any, canEdit: boolean, onClick: any,onHold?: boolean, onExit?: (onHold: boolean) => void, handleError?: any, position?: string, controls?: any, children?: any}> = ({resource, canEdit, onExit, onHold, onClick, handleError, position, controls, children}) => {

    const navigator = useNavigate();
    const currentUser = useCurrentUser();
    const isSelf = currentUser.username === resource.agent.username;

    return (
        <FlexContainer
            justifyContent={'space-between'}
            justifyItems={'space-between'}
            alignItems={'center'}
            alignContent={'flex-start'}
            background="default"
            style={{
                padding: `0 ${theme.spacing(1)}`,
                width: '100%'
            }}
            position={position}
            aria-labelledby={`reaction-${resource._id}`}
        >
            <FlexContainer
                justifyContent={'flex-start'}
                justifyItems={'flex-start'}
                alignItems={'center'}
                alignContent={'flex-start'}
                background="default"
                position={position}
                aria-labelledby={`reaction-${resource._id}`}
                gap={theme.spacing(2)}
            >
                <FlexContainer alignItems={'center'}>
                    <ProfileAvatar
                        onClick={() => navigator(`/${resource.agent.username}`, { replace: false })}
                        onHover={onClick}
                        onExit={onExit}
                        onHold={onHold}
                        initial={resource.agent && resource.agent.firstName && resource.agent.lastName
                            ? resource.agent.firstName[0] + resource.agent.lastName[0]
                            : resource.agent && resource.agent.username[0]}
                        profileImageURL={resource.agent.avatar?.thumb.url as string}
                        isOnline={resource.agent.lastActive === 'online'}
                    />
                    <ResourceType
                        resource={resource}
                        styles={{
                            padding: '1px',
                            marginBottom: '-.5rem',
                            marginLeft: '-.15rem',
                            position: 'relative'
                        }}
                    />
                </FlexContainer>
                <FlexContainer
                    justifyContent={'flex-start'}
                    justifyItems={'flex-start'}
                    alignItems={'center'}
                    alignContent={'flex-start'}
                    background="default"
                    flexDirection={'column'}
                    position={position}
                    aria-labelledby={`member-${resource._id}`}
                >
                    <FullName
                        onHold={onHold}
                        onHover={onClick}
                        onClick={() => navigator(`/${resource.agent.username}`, { replace: false })}
                        onExit={onExit}
                        user={resource.agent}
                        styles={{ padding: '1px' }}
                    />
                </FlexContainer>
                <Error resource={resource} handleError={handleError} />
                {canEdit && (
                    <ListItemControls>
                        {controls}
                    </ListItemControls>
                )}
            </FlexContainer>
                <ListItemControls justifyContent={'flex-end'}>
                    {!isSelf
                        ? <FollowButton
                            objectAlreadyFollowed={resource.agent.userContext.interactions.following}
                            resource={resource.agent}
                        /> : null}
                </ListItemControls>
        </FlexContainer>
    )
}

export default ReactionItem;
