import { ActionDescriptor } from '../common/commonTypes';
import DescribeAttributeForm from './DescribeAttributeForm';
import {faEdit} from "@fortawesome/free-solid-svg-icons";

const describeAttribute: ActionDescriptor = {
	form: DescribeAttributeForm,
	actionTitle: 'Describe',
	requiresEdit: true,
	modalType: 'describeAttribute',
	divider: false,
	actionIcon: faEdit,
	kind: 'modal',
};

export default describeAttribute;
