import GlobalStyles from '../common/theme/GlobalStyles';
import theme from '../common/theme/theme';
import HUDTracker from '../features/HUD/components/HUDTracker';
import { store } from './store';
import React, { FunctionComponent, ReactNode } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ContextMenu from "../common/ContextMenu";

interface ProvidersProps {
	children?: ReactNode;
}


const Providers: FunctionComponent<ProvidersProps> = ({ children }) => {

	document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
        const xPos = event.pageX + "px";
        const yPos = event.pageY + "px";
    });

	return (
		<ReduxProvider store={store}>
			<BrowserRouter>
				<HUDTracker>
					<ThemeProvider theme={theme}>
						<GlobalStyles />
						{children}
					</ThemeProvider>
				</HUDTracker>
			</BrowserRouter>
		</ReduxProvider>
	);
};

export default Providers;
