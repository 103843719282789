import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleRadio from "./SubtleRadio";
import {harvestErrors} from "./SubtleTextBox";

const IsDependentInput: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
    ) => {
    return <SubtleRadio
        {...validatingRegister('isDependent')}
        showLabel
        name='isDependent'
        options={[
            {name: 'true', label: 'Dependent'},
            {name: 'false', label: 'Independent'},
        ]}
        alignItems={'center'}
        justifyContent={'space-between'}
        validatingRegister={validatingRegister}
        inline={true}
        label="Is Dependent"
        isDirty={Boolean(formState.dirtyFields.isDependent)}
        onReset={() => resetField('isDependent')}
        error={harvestErrors(
            'isDependent',
            formState,
            mutationResults
        )}
    />;
}

export default IsDependentInput;
