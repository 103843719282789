import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleSelect from "./SubtleSelect";

const encodingTypes = [
    "ASCII",
    "UTF-8",
    "UTF-16 (2 variants)",
    "UTF-32 (4 variants)",
    "Big5",
    "GB2312",
    "EUC-TW",
    "HZ-GB-2312",
    "ISO-2022-CN (Traditional and Simplified Chinese)",
    "EUC-JP",
    "SHIFT_JIS",
    "CP932",
    "ISO-2022-JP (Japanese)",
    "EUC-KR",
    "ISO-2022-KR (Korean)",
    "KOI8-R",
    "MacCyrillic",
    "IBM855",
    "IBM866",
    "ISO-8859-5",
    "windows-1251 (Cyrillic)",
    "ISO-8859-5,",
    "windows-1251 (Bulgarian)",
    "ISO-8859-1,",
    "windows-1252 (Western European languages)",
    "ISO-8859-7,",
    "windows-1253 (Greek)",
    "ISO-8859-8,",
    "windows-1255 (Visual and Logical Hebrew)",
    "TIS-620 (Thai)"
]
//     """
//     # NONE = 'none'
//     # HTML = 'html'
//     # URL = 'url'
//     UTF8 = 'utf-8'
//     UTF16 = 'utf-16'
//     UTF32 = 'utf-32'
//     LATIN1 = 'latin-1'
//     # BASE64 = 'base64'
//     ASCII = 'ascii'


const EncodingTypeSelect: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleSelect
        inline={true}
        label={`Encoding`}
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('type')}
        isDirty={Boolean(formState.dirtyFields.type)}
        {...validatingRegister('type')}
    >
        {encodingTypes.map((encoding) =>{
            return <option key={encoding} value={encoding}>
                {encoding}
            </option>
        })}
    </SubtleSelect>;
};

export default EncodingTypeSelect;
