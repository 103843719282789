import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleSelect from "./SubtleSelect";
import {harvestErrors} from "./SubtleTextBox";

const AttributeTypeSelect: FunctionComponent<ActionFieldProps> = (
    {
        inline=true,
        formState,
        resetField,
        validatingRegister
    }
    ) => {
    //"What type of attribute is {label}?"
    return <SubtleSelect
        inline={inline}
        label={`Type`}
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('type')}
        isDirty={Boolean(formState.dirtyFields.type)}
        {...validatingRegister('type')}
    >
        <option value="identity">Identity</option>
        <option value="relation">Relation</option>
        <option value="category">Category</option>
        <option value="quantity">Quantity</option>
        <option value="event">Event</option>
        <option value="location">Location</option>
        <option value="media">Media</option>
    </SubtleSelect>;
}

{/*<SubtleSelect*/}
        {/*    label=*/}
        {/*    onReset={() => resetField('type')}*/}
        {/*    isDirty={Boolean(formState.dirtyFields.type)}*/}
        {/*    {...validatingRegister('type')}*/}
        {/*>*/}
        {/*    <option value="identity">Identity</option>*/}
        {/*    <option value="relation">Relation</option>*/}
        {/*    <option value="category">Category</option>*/}
        {/*    <option value="quantity">Quantity</option>*/}
        {/*    <option value="event">Event</option>*/}
        {/*    <option value="location">Location</option>*/}
        {/*    <option value="document">Document</option>*/}
        {/*    <option value="image">Image</option>*/}
        {/*    <option value="video">Video</option>*/}
        {/*</SubtleSelect>*/}

export default AttributeTypeSelect;
