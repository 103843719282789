import FlexContainer from '../../../common/FlexContainer';
import {FormResults} from '../../../common/Form';
import Spinner from '../../../common/loading/Spinner';
import Typography from '../../../common/text/Typography';
import {useOperateAttrMutation} from '../../api';
import {mergeErrorStates, extractQueryErrMessage} from '../../api/helpers';
import {AttrActionFormProps} from '../common/commonTypes';
import {
    renderReferenceField,
    renderDerivationSubfields,
} from '../common/jsxHelpers';
import useAttrNeighbors from '../hooks/useAttrNeighbors';
import {
    mergeAttrFormDefaults,
    mergeAttrFormToPayload,
} from './mergeAttributeHelpers';
import {MergeAttrFormValues} from './mergeAttributeTypes';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import IsDerivationInput from "../../../common/inputs/IsDerivationInput";
import ValueTypeSelect from "../../../common/inputs/ValueTypeSelect";
import {useAppDispatch} from "../../../app/hooks";
import useModalType from "../../HUD/hooks/useModalType";
import {setModalType} from "../../HUD/state/HUDSlice";
import Loading from "../../../common/loading/Loading";
import {PrependAttrFormValues} from "../prependAttribute/prependAttributeTypes";

const MergeAttributeForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
	const {objectId: _id, canEdit} = modalProps;

    const formDefaults = mergeAttrFormDefaults();

    const {handleSubmit, register, reset, resetField, formState, watch} =
        useForm<MergeAttrFormValues>({
            defaultValues: formDefaults,
        });

    const [watchedDerivationFlag, watchedUsingReferenceValue] = watch([
        'isDerivation',
        'usingStaticReference',
    ]);

    // prevent filtering from running every render
    // TODO: eventually we will want to narrow this--just use all neighbors for now
    const filter = useCallback(() => true, []);

    const queryRes = useAttrNeighbors(_id, filter);

    const validatingRegister = useCallback(
        (inputName: keyof MergeAttrFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    // useEffect(() => {
    //     if (queryRes.isSuccess && closeModal) {
    //         closeModal();
    //     }
    // }, [queryRes.isSuccess, closeModal]);

    const [mergeAttr, mutationResults] = useOperateAttrMutation();

    const mergedErrs = mergeErrorStates(queryRes, mutationResults);

    const onSubmit: SubmitHandler<MergeAttrFormValues> = (vals, e) => {
        e?.preventDefault();
        mergeAttr({
            attrId: _id,
            body: mergeAttrFormToPayload(vals),
        });
    };

    if (queryRes.isError) {
        return (
            <FlexContainer justifyContent="center">
                <Typography color="error" paragraph>
                    {extractQueryErrMessage(queryRes.error)}
                </Typography>
            </FlexContainer>
        );
    }

    return (
        <AppModal
            label={"Merge Attributes"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit ? canEdit : true}>
            <IsDerivationInput
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            {renderDerivationSubfields({
                isDerivation: watchedDerivationFlag === 'true',
                formState,
                resetField,
                validatingRegister,
                mutationResults
            })}
            <ValueTypeSelect
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            {!queryRes.isLoading && !queryRes.isUninitialized
                ? renderReferenceField(
                'otherId',
                'otherValue',
                watchedUsingReferenceValue === 'true',
                validatingRegister,
                queryRes.attrNeighbors,
                formState
            ) : <Loading />}
            <FormResults
                isError={!!mergedErrs}
                error={mergedErrs}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default MergeAttributeForm;
