import {BasePaper} from 'common/Paper';
import useHUDactive from '../hooks/useHUDActive';
import EntityLeftHUD from 'common/viz/EntityLeftHUD';
import { useParams } from 'react-router-dom';
import useViewModeParams from 'features/navigation/hooks/useViewModeParams';
import useViewportDimensions from '../hooks/useViewportDimensions';
import { ReactNode, useRef, FunctionComponent } from 'react';
import { TransitionStatus, Transition } from 'react-transition-group';
import styled, { DefaultTheme } from 'styled-components';
import TabDrawer from "./TabDrawer";

const short = (theme: DefaultTheme) => theme.transitions.duration.standard;

const ease = (theme: DefaultTheme) => theme.transitions.easing.easeInOut;
//
// transform: ${(p) =>
// `translateX(${
// 	p.state === 'entered' || p.state === 'entering'
// 		? '0'
// 		: -p.theme.drawerWidth
// }px)`};
// const StyledSidePanelContainer = styled(BasePaper)<{
// 	viewportHeight: number;
// 	state: TransitionStatus;
// 	props: any;
// }>`
// 	opacity: 1;
// 	transition: transform ${({ theme }) => `${short(theme)}ms ${ease(theme)}`},
// 		opacity ${({ theme }) => `${short(theme)}ms ${ease(theme)}`};
// 	transform: ${(p) => `translateX(${p.state === 'entered' || p.state === 'entering'
// 		? 8 / 16
// 		: p.theme.panelGutter * 2
// 	})`};
// 	position: absolute;
// 	width: ${(p) => `${p.theme.drawerWidth * 2}px`};
// 	height: ${(p) =>
// 		`calc(100vh - ${(p.theme.navHeight * 2 + 8)}px - ${p.theme.panelGutter * 4}px)`};
// 	top: calc(${(p) => `${p.theme.navHeight}`}px + ${(p) => p.theme.panelGutter * 2}px);
//     left: ${(p) => p.theme.spacing(1)};
// 	display: ${(p) => !!p.props.drawerOpen ? 'flex' : 'none'};
// 	flex-direction: column;
// 	overflow: visible;
// 	z-index: ${(p) => p.theme.zIndex.controlSurface};
// 	padding: 0;
// `;

const StyledSidePanelContainer = styled.div<{
	viewportHeight: number;
	state: TransitionStatus;
	// props: any;
}>`
	opacity: 1;
	transition: transform ${({ theme }) => `${short(theme)}ms ${ease(theme)}`},
		opacity ${({ theme }) => `${short(theme)}ms ${ease(theme)}`};
	transform: ${(p) =>
		`translateX(${
			p.state === 'entered' || p.state === 'entering'
				? '0'
				: -p.theme.drawerWidth
		}px)`};
	position: absolute;
	width: ${(p) => `${p.theme.drawerWidth}px`};
	top: ${(p) => `${p.theme.navHeight}px`};
	background: transparent;
	padding-left: ${(p) => p.theme.panelGutter}px;
	padding-top: ${(p) => p.theme.panelGutter}px;
	display: flex;
	flex-direction: column;
	overflow: visible;
	z-index: ${(p) => p.theme.zIndex.controlSurface};
`;
	// display: ${(p) => !!p.props.drawerOpen ? 'flex' : 'none'};

const StyledPanelContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: ${(p) => `${p.theme.panelGutter}px`};
`;
	// background: transparent;

interface LeftDrawerProps {
	props?: any;
	children?: ReactNode;
	drawerOpen?: boolean;
}

// const LeftDrawer = forwardRef<HTMLElement, LeftDrawerProps>(
// 	({ props, children }, ref: any) => {
// 		// Need this ref to stop react-transition-group from trying to
// 		// use findDOMNode, which is deprecated and profoundly irritating.
//
// 		const {drawerOpen} = props;
// 		const transitionRef = useRef<any>();
//
// 		const { viewportHeight } = useViewportDimensions();
//
// 		return (
// 			<Transition in={drawerOpen} timeout={10} nodeRef={transitionRef}>
// 				{(state) => (
// 					<StyledSidePanelContainer
// 						ref={transitionRef}
// 						viewportHeight={viewportHeight}
// 						state={state}
// 						props={props}
// 						data-testid="hud-left-drawer-container"
// 						className="side-container"
// 					>
// 						{children}
// 						{/* Need vertical scroll for content panel, and for some reason setting
// 						overflow-y on StyledSidePanelContainer hides translated control bar, so we put
// 						the scroll on an inner container instead. */}
// 					</StyledSidePanelContainer>
// 				)}
// 			</Transition>
// 		);
// });

export const LeftDrawerContainer: FunctionComponent<{children: any}> = ({ children }) => {
	// Need this ref to stop react-transition-group from trying to
	// use findDOMNode.
	const transitionRef = useRef<any>();

	const { viewportHeight } = useViewportDimensions();
	const HUDIsActive = useHUDactive();

	return (
		<Transition in={HUDIsActive} timeout={10} nodeRef={transitionRef}>
			{(state) => (
				<StyledSidePanelContainer
					ref={transitionRef}
					viewportHeight={viewportHeight}
					state={state}
					data-testid="hud-sidepanel-container"
				>
					{children}
				</StyledSidePanelContainer>
			)}
		</Transition>
	);
};

const LeftDrawer: FunctionComponent = () => {
	const { entity } = useParams();
	const { getViewMode } = useViewModeParams();

	const renderRealHUD = !!entity && getViewMode() === 'spatial';
	// const renderTabHUD = !entity && getViewMode() === 'profile';
	return (
		<LeftDrawerContainer>
			<StyledPanelContainer>
				{renderRealHUD
					? <EntityLeftHUD />
					: null}
			</StyledPanelContainer>
		</LeftDrawerContainer>
	);
};
					// renderTabHUD ?
					// 	<TabDrawer /> : null}

export default LeftDrawer;
