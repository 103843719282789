import FlexContainer from '../FlexContainer';
import {
	ListItem,
	ListItemContent,
	ListItemAvatar,
} from './index';
import Spinner from '../loading/Spinner';
import Typography from '../text/Typography';
import { useAcceptAnswerMutation } from '../../features/api';
import { isNewIndividualIdentifier } from '../questions/helpers';
import QuestionContext from '../questions/state/QuestionContext';
import { BaseAnswer } from '../questions/types/questionTypes';
import { StyledQuestionListButton } from '../questions/components/styledComponents';
import { faCheck, faReply } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, useContext } from 'react';

interface AnswerItemProps extends BaseAnswer {
	answerExists: boolean;
}

const AnswerItem: FunctionComponent<AnswerItemProps> = ({
	_id,
	isAccepted,
	content,
	answerExists,
	questionId,
}) => {
	const { parentObjectId, parentObjectType, canEdit } =
		useContext(QuestionContext);

	const [accept, acceptanceResult] = useAcceptAnswerMutation();

	const renderControl = () =>
		acceptanceResult.isLoading ? (
			<Spinner />
		) : (
			<StyledQuestionListButton
				onClick={() => {
					if (isNewIndividualIdentifier(parentObjectId)) {
						throw new Error(
							"Unable to submit an answer on a parent individual that is unknown to Futuremodel's system and has no internal _id"
						);
					}

					accept({
						parentObjectId: parentObjectId.objectId,
						parentObjectType,
						questionId,
						body: { answerId: _id },
					});
				}}
				variant="outline"
			>
				Accept
			</StyledQuestionListButton>
		);

	return (
		<div>
			<ListItem key={_id}>
				<ListItemAvatar>
					<FontAwesomeIcon
						icon={isAccepted ? faCheck : faReply}
						size="xs"
					/>
				</ListItemAvatar>
				<ListItemContent>
					<Typography variant="body2">{content}</Typography>
				</ListItemContent>
			</ListItem>
			{!answerExists && canEdit && (
				<FlexContainer justifyContent="flex-end">
					{renderControl()}
				</FlexContainer>
			)}
		</div>
	);
};

export default AnswerItem;
