import { useGetOrgsQuery } from '../../api';
import useUserID from '../../authentication/hooks/useUserID';
import {
	ContextParams,
	DataContextParams
} from '../../navigation/types/navigationContextTypes';
import { BaseOrg } from '../types/orgTypes';
import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isEmpty } from 'common/utils/typeGuards';
import { useParams } from 'react-router-dom';

// TODO: figure out why this doesn't type correctly in selector fn below
type QueryResponse = Omit<ReturnType<typeof useGetOrgsQuery>, 'refetch'>;

type ActiveOrgName = string | undefined;

const selectActiveOrg = createSelector(
	(res: QueryResponse) => res.data,
	(res: QueryResponse, activeOrgName: ActiveOrgName) =>
		activeOrgName,
	(data: QueryResponse['data'], activeOrgName: ActiveOrgName) =>
		data?.find((acct: BaseOrg) => acct.username === activeOrgName) ?? null
);
/**
 * @returns RTK Query files related to fetching all available orgs for a user, as well
 * as an activeOrg property that corresponds to the files for the user specified
 * in the application URL.
 */
const useActiveOrgData = () => {
	const params = useParams() as ContextParams | DataContextParams;

	const userId = useUserID();

	// won't run as long as skipToken is its argument
	const fetchData = useGetOrgsQuery(
		isEmpty(userId) ? skipToken : { userId },
		{
			selectFromResult: (res) => ({
				...res,
				// important to use a memoizing selector here: selectFromResult
				// uses shallow-equal comparison to determine whether or not a re-render
				// should occur. The only way to get a stable reference for the value of
				// activeItem is to memoize based on inputs.  RTK query takes care of
				// memoizing the value of res.data for us.
				activeItem: selectActiveOrg(
					res,
					params.username
				) as BaseOrg,
			}),
		}
	);

	return fetchData;
};

export default useActiveOrgData;
