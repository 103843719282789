import MemberPanel from './MemberPanel';
import Typography from 'common/text/Typography';
import { mergeQueryStates, parseQueryError } from 'features/api/helpers';
import { canEdit as canEditDomain } from 'features/authentication/helpers';
import useActiveOrgData from 'features/ontology/hooks/useActiveOrgData';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import { FunctionComponent } from 'react';

const DomainConsole: FunctionComponent<{container: any;}> = ({container}) => {
	const { activeItem: activeOrg, ...orgQueryRest } =
		useActiveOrgData();

	const { activeItem: activeDomain, ...domainQueryRest } =
		useActiveDomainData();

	const canEdit = activeDomain ? canEditDomain(activeDomain) : false;

	const { isError, error, isLoading, isUninitialized } = mergeQueryStates(
		orgQueryRest,
		domainQueryRest
	);

	if (isError && error) {
		return (
			<Typography variant='h5' color="error">
				{parseQueryError(error).message}
			</Typography>
		);
	}

	if (isLoading || isUninitialized) {
		return <Typography variant='h5'>
			Loading...
		</Typography>;
	}

	return (
		<div style={{padding: 0, margin: 0, height: container}}>
			{/* OK to cast Org: the 'if' blocks above return early if it's not available */}
			<MemberPanel
				canEdit={canEdit}
				authorityLevel="steward"
				contextLevel="domain"
				domainId={activeDomain?._id}
				orgId={activeOrg!._id}
			/>
			<MemberPanel
				canEdit={canEdit}
				authorityLevel="member"
				contextLevel="domain"
				domainId={activeDomain?._id}
				orgId={activeOrg!._id}
			/>
		</div>
	);
};

export default DomainConsole;
