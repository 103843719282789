import { filterOrgMembers } from '../helpers';
import { OrgAuthorityLevel } from '../types/GovernanceTypes';
import useCombinedOrgMembers from './useCombinedOrgMembers';
import { useMemo } from 'react';

const useFilteredOrgMembers = (
	orgId: number,
	authorityLevel: OrgAuthorityLevel
) => {
	const allOrgMembers = useCombinedOrgMembers(orgId);

	return useMemo(
		() => filterOrgMembers(authorityLevel, allOrgMembers),
		[allOrgMembers, authorityLevel]
	);
};

export default useFilteredOrgMembers;
