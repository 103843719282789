import React, {
	FunctionComponent,
	useCallback,
	useState
} from "react";
import ReactModal from "react-modal";
import StyledSquare from "../StyledSquare";
import Typography from "../text/Typography";
import IconButton from "../buttons/IconButton";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {BasePaper} from '../Paper';
import styled from 'styled-components';
import useCurrentUser from "../../features/authentication/hooks/useUser";
import {useGetReactionsQuery,} from "../../features/api";
import Button from "../buttons/Button";
import {skipToken} from "@reduxjs/toolkit/query";
import {ReactionItem} from "../List/ReactionItem";
import {ReactionSubject} from "../activities/types/activityTypes";
import AppModal from "./AppModal";
import {PopoverBase} from "../popovers/Popover";
import UserPopover from "../popovers/UserPopover";
import {BaseUser} from "../../features/authentication/types/userTypes";
import useModalType from "../../features/HUD/hooks/useModalType";

export const SourceBody = styled(BasePaper)`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: ${(p) => p.theme.palette.background.paper};
`

interface HUDProps {
	translucent?: boolean;
	borderRadius?: string;
	width?: string | number;
	height?: string | number;
}


	// box-shadow: ${(p) => p.theme.shadows[4]};
export const HUDModalBody = styled.div<HUDProps>`
	background: ${(p) =>
		p.theme.palette.background[
			p.translucent ? 'transparentDark' : 'paper'
		]};
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	color: ${(p) => p.theme.palette.lightBaby};
	width: ${(p) => p.width ? p.width : '50'}vw;
	height: ${(p) => p.height ? p.height : '50'}vh;
	display: flex;
	flex-direction: column;

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
	${(p) => p.theme.translucent}
`;

interface StyledModalHeaderProps {
	justifyContent?: string;
	borderBottom?: string;
	padding?: string;
}

export const ModalHeader = styled.header<StyledModalHeaderProps>`
    border-bottom: ${(p) => p.borderBottom ? p.borderBottom : `2px solid ${p.theme.palette.divider}`};
	padding: ${(p) => p.padding ? p.padding : p.theme.spacing(1, 2)};
	display: flex;
	justify-content: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
`;

export const HUDModalHeaderText = styled.h1`
	font-size: 1rem;
	display: flex;
	align-items: center;
`;


interface ModalHeaderProps extends ReactModal.Props {
	handleClose: () => void;
	headingText: string;
}


interface UpdateUserFormValues {
	firstName: string;
	lastName: string;
	username: string;
	defaultOrg: string;
}


const HUDModalHeader: FunctionComponent<ModalHeaderProps> = ({
	headingText,
	handleClose
}) => {
	return (
		<ModalHeader>
			<HUDModalHeaderText>
				<StyledSquare/>
				<span style={{paddingLeft: '.75rem'}}>
					<Typography color={'oldManGray'} variant={'h4'}>
						{headingText}
					</Typography>
				</span>
			</HUDModalHeaderText>
			<IconButton
				onClick={handleClose}
				icon={faTimes}
				aria-label="close source browser"
			/>
		</ModalHeader>
	);
};

const StyledButton = styled(Button)`
	margin-top: ${(p) => p.theme.spacing(1)};
`;

const RestrictionsModal: FunctionComponent = () => {

	const {modalProps, closeModal} = useModalType();
	const {resource: object, objectType, canEdit } = modalProps;

	const user = useCurrentUser();

	const {
		data: reactions,
		isLoading,
		isError,
		isUninitialized,
		error,
	} = useGetReactionsQuery(
		!!object && object._id !== null  && objectType
			? { objectId: object._id, objectType }
			: skipToken
	);

	const [popoverUser, setPopoverUser] = useState<BaseUser | null>(null);
	const [anchor, setAnchor] = useState<HTMLElement | null>(null);
	// const objectId = contextLevel === 'username' ? orgId : domainId;

	const [memberErr, setMemberErr] = useState<Record<string, string>>({});

	const updateMemberErr = useCallback(
		(msg: string, id: number) => setMemberErr((p) => ({ ...p, [id]: msg })),
		[]
	);

	if (canEdit && user) {
		return (
			<AppModal
				label={"Reactions"}
				isOpen={true}
				isDirty={false}
				isForm={false}
				onClose={closeModal}
				onSubmit={() => {}}
				canEdit={false}>
				<PopoverBase
					anchorEl={anchor}
					handleClickAway={() => setPopoverUser(null)}
					open={!!popoverUser}>
					{popoverUser && <UserPopover user={popoverUser} />}
				</PopoverBase>
				{reactions && reactions.items.map((reaction) => {
					return <ReactionItem
						key={reaction.object._id}
						resource={reaction}
						canEdit={false}
						handleError={memberErr}
						onClick={(e: any) => {
							setAnchor(e.currentTarget);
							setPopoverUser(reaction.agent);
						}}/>
					})}
			</AppModal>
		);
	} else {
		return null
	}
};

export default RestrictionsModal;
