import { defaultGraphMargins } from '../../CONSTANTS';
import {
	StyledSVGContainer,
	StyledAxisGroup,
} from '../../common/styledComponents';
import useBrushX from '../../hooks/useBrushX';
import useLinearXScale from '../../hooks/useLinearXScale';
import useLinearYAxis from '../../hooks/useLinearYAxis';
import useLinearYScale from '../../hooks/useLinearYScale';
import useXAxis from '../../hooks/useXAxis';
import { DataPoint, GraphMargins } from '../../types';
import useElementSize from 'common/hooks/useSize';
import theme from 'common/theme/theme';
import { extent as d3Extent, bin, max } from 'd3-array';
import { getExactThresholds } from 'common/viz/helpers';
import { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';

const StyledLegendText = styled.text`
	fill: ${(p) => p.theme.palette.cyan};
	font-size: 12px;
	alignment-baseline: middle;
`;

export interface BarGroup {
	categoryName: string | null;
	data: DataPoint<number, number>[];
}

export type BarGroups = BarGroup[];

interface HistogramProps extends GraphMargins {
	binCount?: number;
	data: BarGroups;
	xAxis?: boolean;
	yAxis?: boolean;
	onBrushEnd?: (v: [number, number]) => void;
	svgId?: string;
	colorMap?: Record<string, string>;
}

const mergeExtents = (e1: [number, number], e2: [number, number]) => {
	const res = [Math.min(e1[0], e2[0]), Math.max(e1[1], e2[1])] as [
		number,
		number
	];
	return res;
};

const getMinMax = (barGroups: BarGroups) => {
	return barGroups.reduce(
		(minMax, group) => {
			return mergeExtents(
				minMax,
				d3Extent(group.data, ({ y }) => y) as [number, number]
			);
		},
		[Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY] as [number, number]
	);
};

const getBins = (barGroups: BarGroups, binCount: number) => {
	const [minValue, maxValue] = getMinMax(barGroups);

	const thresholds = getExactThresholds(binCount, minValue, maxValue);

	const binGroups = barGroups.map((group) => ({
		bins: bin<DataPoint<number, number>, number>()
			.value((p) => p.y)
			.thresholds(thresholds)(group.data),
		categoryName: group.categoryName,
	}));

	const yMax = binGroups.reduce((acc, grp) => {
		const barMax = max(grp.bins, (bin) => bin.length)!;

		return Math.max(acc, barMax);
	}, 0);

	const res = {
		yMax,
		minValue,
		maxValue,
		binGroups,
		thresholds,
	};

	return res;
};

// 'get*bound' functions ensure that the bar for every
//  bin is the same width, even if x0 - x1 does not span
// the full interval.
const getLowerBound = (
	val: number,
	thresholds: number[],
	domainStart: number
) => {
	for (let i = 0; i < thresholds.length; i++) {
		if (thresholds[i] > val) {
			return thresholds[i - 1] ?? domainStart;
		}
	}

	return thresholds[thresholds.length - 1];
};

const getUpperBound = (
	val: number,
	thresholds: number[],
	domainEnd: number
) => {
	for (let i = 0; i < thresholds.length; i++) {
		if (thresholds[i] >= val) {
			return thresholds[i];
		}
	}

	return domainEnd;
};

const Histogram: FunctionComponent<HistogramProps> = ({
	binCount = 30,
	data,
	onBrushEnd,
	svgId,
	colorMap,
	xAxis = true,
	yAxis = true,
	...margins
}) => {
	const { top, bottom, left, right } = { ...defaultGraphMargins, ...margins };

	const [{ width, height }, setSizeEl] = useElementSize();

	const { binGroups, minValue, maxValue, yMax, thresholds } = getBins(
		data,
		binCount
	);

	const xScale = useLinearXScale({
		xMax: maxValue,
		xMin: minValue,
		left,
		right,
		width,
	});

	const yScale = useLinearYScale({
		yMax,
		top,
		bottom,
		height,
		yMaxRatio: 1.25,
	});

	const invertXValue = xScale.invert;

	const transformBrushMove = (selection: [number, number]) => {
		const [selectionLow, selectionHigh] = selection;

		const [domainStart, domainEnd] = xScale.domain();

		const low = getLowerBound(
			invertXValue(selectionLow),
			thresholds,
			domainStart
		);

		const high = getUpperBound(
			invertXValue(selectionHigh),
			thresholds,
			domainEnd
		);

		return [low, high].map(xScale) as [number, number];
	};

	const { brushClass } = useBrushX({
		left,
		top,
		right,
		bottom,
		onEnd: onBrushEnd,
		invert: invertXValue,
		width,
		height,
		transformBrushMove,
	});

	const yAxisClass = useLinearYAxis(yScale, left);

	const xAxisClass = useXAxis(xScale, height, bottom, left,{
		thresholds,
		tickFormat: '.0~f',
	});

	const getFillColor = (categoryName: string | null) => {
		if (categoryName && colorMap && colorMap[categoryName]) {
			return colorMap[categoryName];
		}

		return theme.palette.primary.main;
	};

	const legend = () => {
		// if a binGroup's category name is 'null', there's no category splitting going on
		// and we don't need a legend -- every bar belongs to the same, undivided attribute.
		const legendKeys = binGroups
			.map((g) => g.categoryName)
			.filter((name) => !!name);

		if (legendKeys.length === 0) {
			return null;
		}

		return (
			<g transform={`translate(${left + 16}, ${top + 8})`}>
				{legendKeys.map((key, i) => (
					<g transform={`translate(0, ${i * 12})`} key={key}>
						<circle r={4} cx={0} cy={-1} fill={getFillColor(key)} />
						<StyledLegendText x={8}>{key}</StyledLegendText>
					</g>
				))}
			</g>
		);
	};

	const drawBars = () => {
		const domain = xScale.domain();

		const bars = binGroups.reduce(
			(output, categoryBinObject, categoryIdxPosition) => {
				const { categoryName } = categoryBinObject;

				categoryBinObject.bins.forEach((bin) => {
					const { x1, x0 } = bin;

					const startVal = getLowerBound(x0!, thresholds, domain[0]);
					const endVal = getUpperBound(x1!, thresholds, domain[1]);

					const blockEnd = xScale(endVal);
					const blockStart = xScale(startVal);

					// -4 to create space for gap between bar groups
					const widthCalc = (blockEnd - blockStart - 0.5) / binGroups.length;
					const barWidth = widthCalc > 0 ? widthCalc : 0;

					const leftOffset =
						blockStart + barWidth * categoryIdxPosition;

					// number of elements in the bin provides the y-axis value
					const topOffset = yScale(bin.length);
					const heightCalc = height - yScale(bin.length) - bottom;
					const barHeight = heightCalc > 0 ? heightCalc : 0;

					// push resulting bar to single outer array
					output.push(
						<rect
							key={`${categoryBinObject.categoryName}-${x0}`}
							// +3 to create space for gap between bar groups
							transform={`translate(${
								leftOffset + 0
							}, ${topOffset})`}
							width={barWidth}
							height={barHeight}
							fill={getFillColor(categoryName)}
						/>
					);
				});

				return output;
			},
			[] as ReactElement[]
		);

		return <g>{bars}</g>;
	};

	return (
		<StyledSVGContainer ref={setSizeEl}>
			<svg
				width={width}
				height={height}
				id={svgId}
				viewBox={`0 0 ${width} ${height}`}
			>
				{legend()}
				{drawBars()}
				{yAxis && <StyledAxisGroup className={yAxisClass} />}
				{xAxis && <StyledAxisGroup className={xAxisClass} />}
				{onBrushEnd && <g className={brushClass} />}
			</svg>
		</StyledSVGContainer>
	);
};

export default Histogram;
