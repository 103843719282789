import { ActionDescriptor } from '../common/commonTypes';
import PrependAttributeForm from './PrependAttributeForm';

const prependAttribute: ActionDescriptor = {
	form: PrependAttributeForm,
	actionTitle: 'Prepend',
	kind: 'modal',
	requiresEdit: true,
	divider: false,
	modalType: 'prependAttribute'
};

export default prependAttribute;
