import { BaseUser } from '../../../features/authentication/types/userTypes';
import { ObjectType } from '../../../features/ontology/types/commonTypes';
import {CreationId} from "../../questions/helpers";
import likeIcon from "../../icons/LikeIcon";


export interface BaseTask {
	_object: 'Task ';
	_id: number;
	urn: string;
	object: any;
	agent: BaseUser;
	type: string;
	created: {
		actor: BaseUser;
		time: string;
	};
	lastModified: {
		actor: BaseUser;
		time: string;
	};
}


export interface BaseReaction {
	_object: 'Reaction';
	_id: number;
	root: string;
	urn: string;
	object: any;
	agent: BaseUser;
	type: string;
	created: {
		actor: BaseUser;
		time: string;
	};
	lastModified: {
		actor: BaseUser;
		time: string;
	};
}

export type ReactionConfig = {
	name: 'LIKE';
	icon: any;
}
export const ReactionTypes: {like: ReactionConfig} = {
	like: {
		name: 'LIKE',
		icon: likeIcon
	},
}

export interface BaseActivity {
	_id: number;
	_object: 'UserAction';
	createdAt: string;
	generated: null;
	generatedObject: {};
	modifiedAt: string;
	object: string;
	urn: string;
	agent: BaseUser;
	action: string;
	content: string;
	name: string;
	result?: {
		content: string;
		type: 'Note';
	};
}

export const actionables: Exclude<ObjectType, 'Activity'>[] = [
	// TODO: this api route doesn't actually exist in the backend
	'Organization',
	'Domain',
	'Entity',
	'Attribute',
	'Individual',
	'User',
	'DataSource',
	'DataCatalog',
	'Dataset',
	'DataColumn',
	'DataRow',
	'DataValue',
	'DataType',
	'Metric',
	'Fact',
	'Insight',
	'Comment'
];

export type TaskSubject = typeof actionables[number];
export type ActivitySubject = typeof actionables[number];
export type ReactionSubject = typeof actionables[number];
export type ViewerSubject = typeof actionables[number];

export interface CreateQuestionResponse {}
export interface CreateReactionResponse {}

export interface GetActivitiesParams {
	objectId: number;
	objectType: ActivitySubject;
	perPage?: number;
	page?: number;
}

export type GetActivitiesResponse = {
	_meta: {
		page: number;
		perPage: number;
		totalItems: number;
		totalPages: number;
	},
	items: 	BaseActivity[];
};

export interface GetViewersParams {
	objectId: number;
	objectType: ViewerSubject;
	perPage?: number;
	page?: number;
}

export type GetViewersResponse = {
	_meta: {
		page: number;
		perPage: number;
		totalItems: number;
		totalPages: number;
	},
	items: 	BaseUser[];
};

export interface GetReactionsParams {
	objectId: number;
	objectType: ViewerSubject;
	perPage?: number;
	page?: number;
}

export type GetReactionsResponse = {
	_meta: {
		page: number;
		perPage: number;
		totalItems: number;
		totalPages: number;
	},
	items: 	BaseReaction[];
};

export type GetTasksResponse = {
	_meta: {
		page: number;
		perPage: number;
		totalItems: number;
		totalPages: number;
	},
	items: 	BaseTask[];
};

// export interface GeneralReactionCreationId {
// 	kind: 'other';
// 	objectId: number;
// }
//
// export interface NewIndividualReactionCreationId {
// 	kind: 'newIndividualReaction';
// 	attributeId: number;
// 	primaryAttributeValue: string | number;
// }

//
// export type ReactionCreationId =
// 	| NewIndividualReactionCreationId
// 	| GeneralReactionCreationId;

export interface CreateReactionPayload {
	type: string;
	objectType: ReactionSubject;
	objectIdentifier: CreationId;
}

export interface CreateReactionParams {
	body: CreateReactionPayload;
}

export interface GetTasksParams {
	objectId: number;
	objectType: TaskSubject;
	perPage?: number;
	page?: number;
}


