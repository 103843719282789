import Typography from 'common/text/Typography';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';
import {
	FunctionComponent,
	MouseEventHandler,
} from 'react';
import {TinySquare} from "../viz/ForceGraph/styledComponents";
import FlexContainer from "../FlexContainer";
import { ListItem } from 'common/List/index';
import Spinner from 'common/loading/Spinner';
import { useIdentifyEntityMutation } from 'features/api';
import { parseQueryError } from 'features/api/helpers';
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import AttributeMenu from "../menus/AttributeMenu";
import EntityMenu from "../menus/EntityMenu";
import {BaseEntity} from "../../features/ontology/types/entityTypes";


interface ExpandingListItemProps {
	canEdit: boolean;
	resource: any;
	entity?: BaseEntity;
	height?: any;
	// resourceWithActiveSubmenu: number | null;
	// setOpen: Dispatch<SetStateAction<number | null>>;
	onClick?: () => void;
}

const ExpandingListItem: FunctionComponent<ExpandingListItemProps> = (
	{
		// resourceWithActiveSubmenu,
		canEdit,
		onClick,
		entity,
		resource,
		height,
	}) => {
	const {singular: label} = resource;

	const navigator = useNavigate();
	const params = useParams();

	// const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);

	// const submenuOpen = useMemo(
	// 	() => resourceWithActiveSubmenu === _id,
	// 	[resourceWithActiveSubmenu, _id]
	// );
	//
	// const onItemClick: MouseEventHandler = (e) => {
	// 	e.stopPropagation();
	// 	if (canEdit) {
	// 		setOpen((p) => {
	// 			if (p && p === _id) {
	// 				return null;
	// 			}
	// 			return _id;
	// 		});
	// 	}
	//
	// 	return;
	// };

	return (
		<>
			<ListItem
				// ref={setAnchorEl as any}
				style={{
					justifyContent: 'space-between',
					height: height ? height : '2rem',
					gap: '.5rem'
				}}
			>
				{resource._object === 'Attribute'
					? <FlexContainer
						style={{cursor: 'pointer'}}
						justifyContent={'flex-start'}
						alignItems={'center'}
						gap={'1rem'}>
						<TinySquare
							onClick={() => navigator(`/${params.username}/${params.domain}/${resource.entity.name}?attribute=${resource.name}`, { replace: false })}
							onMouseEnter={onClick}
							style={{cursor: 'pointer'}}/>
						<Typography
							onClick={() => navigator(`/${params.username}/${params.domain}/${resource.entity.name}?attribute=${resource.name}`, { replace: false })}
							onMouseEnter={onClick}
							variant={'h5'}
							style={{cursor: 'pointer'}}>
							{label}
						</Typography>
					</FlexContainer>
					: <FlexContainer
						style={{cursor: 'pointer'}}
						justifyContent={'flex-start'}
						alignItems={'center'}
						gap={'1rem'}>
						<TinySquare
							onClick={() => navigator(`/${params.username}/${resource.ontology.domain}/${resource.name}`, { replace: false })}
							onMouseEnter={onClick}
							style={{cursor: 'pointer'}}/>
						<Typography
							onClick={() => navigator(`/${params.username}/${resource.ontology.domain}/${resource.name}`, { replace: false })}
							onMouseEnter={onClick}
							variant={'h5'}
							style={{cursor: 'pointer'}}>
							{label}
						</Typography>
					</FlexContainer>
				}
				{resource._object === 'Entity'
					? <EntityMenu
						resource={resource}
						canEdit={canEdit}
					/>
				: <AttributeMenu
						entity={entity}
						attribute={resource}
						canEdit={canEdit}
					/>}
			</ListItem>
			{/*<PopoverBase*/}
			{/*	anchorEl={anchorEl}*/}
			{/*	open={submenuOpen}*/}
			{/*	offset={0}*/}
			{/*	placement="right"*/}
			{/*>*/}
			{/*	<ActionList*/}
			{/*		resource={resource}*/}
			{/*		actions={actions}*/}
			{/*	/>*/}
			{/*</PopoverBase>*/}
		</>
	);
};

interface IdentifyingListItemProps extends BaseAttribute {
	canEdit: boolean;
}

// If entity has no identifying attribute, clicking an attribute immediately launches
// the 'identify' query.
export const IdentifyingListItem: FunctionComponent<IdentifyingListItemProps> = ({
	_id,
	plural: label,
	canEdit,
	entity: { _id: entityId },
}) => {
	const [identify, identifyResults] = useIdentifyEntityMutation();

	const loading = identifyResults.isLoading;

	const onClick: MouseEventHandler = () => {
		if (canEdit) {
			return identify({ entityId, attributeId: _id });
		}
		return;
	};

	const text = identifyResults.error
		? parseQueryError(identifyResults.error).message
		: label;

	return loading ? (
		<Spinner />
	) : (
		<ListItem button onClick={onClick}>
				<Typography variant={'subtitle'}>
					{text}
				</Typography>
		</ListItem>
	);
};

export default ExpandingListItem;
