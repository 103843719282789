import Button from 'common/buttons/Button';
import FlexContainer from 'common/FlexContainer';
import {FormBody, FormHeading, FormResults} from 'common/Form';
import Textbox from 'common/inputs/TextBox';
import Typography from 'common/text/Typography';
import theme from 'common/theme/theme';
import {isValidEmail} from 'common/utils/typeGuards';
import {useRequestPWResetMutation} from 'features/api';
import React, {FunctionComponent} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import styled from 'styled-components';
import {Link, useLocation} from "react-router-dom";
import LogInLinkButton from "../../../common/buttons/LogInLinkButton";
import StyledLogo from "../../../common/StyledLogo";
import StyledCitizen from "../../../common/StyledCitizen";
import StyledCopyright from "../../../common/StyledCopyright";

interface PWResetRequestFormValues {
    email: string;
}

const StyledLanding = styled.div`
	height: 100vh; 
	width: 100vw;
    background-color: ${(p) => p.theme.palette.darkBaby};
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		overflow-y: auto;	
		overflow-x: hidden;	
	}
`

const StyledFormBody = styled.form`
	border-radius: ${(p) => p.theme.spacing(1)};
	background: ${(p) => p.theme.palette.darkerBaby};
	width: 350px;
	margin: 0;
`;

const StyledMain = styled.div`
	display: flex;
	flex-direction: column;
	place-content: space-evenly;
	place-items: center;
	min-height: 600px;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	padding: 0;	
	margin: 0;	
	background-color: ${(p) => p.theme.palette.darkBaby};
`;

const StyledSigninNav = styled.nav<{ useTranslucency: boolean; }>`
	background: transparent;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-between;
	flex: 1;
	justify-content: flex-end;
	align-content: flex-end;
	z-index: ${(p) => p.theme.zIndex.navPanel};
	background-color: ${(p) => p.theme.palette.darkBaby};
`;


const SigninButtons: FunctionComponent = () => {
    const {pathname} = useLocation();
    const atLanding = pathname.match('/') || pathname.match('/login');
    if (atLanding) {
        return <LogInLinkButton
            display={false}
            color={'darkBaby'}
        />
    }
    return null;
};


const RequestPWResetPage: FunctionComponent = () => {
    const {handleSubmit, register, formState} =
        useForm<PWResetRequestFormValues>({
            defaultValues: {
                email: '',
            },
        });

    const [requestReset, requestResetResult] = useRequestPWResetMutation();

    const onSubmit: SubmitHandler<PWResetRequestFormValues> = (vals) => {
        requestReset(vals);
    };

    return (
        <StyledLanding>
            <StyledSigninNav useTranslucency={false}>
                <SigninButtons/>
            </StyledSigninNav>
            <StyledMain>
                <StyledLogo
                    width={'450px'}
                    height={'53px'}
                    src="https://storage.googleapis.com/davis-associates.appspot.com/futuremodel-teaser.svg"
                    alt="Futuremodel Inc."
                />
                <FlexContainer
                    style={{height: '315px', width: '100%'}}
                    gap={'.5rem'}
                    alignItems='center'
                    justifyContent='center'>
                    <StyledCitizen
                        width={'275px'}
                        height={'315px'}
                        src="https://storage.googleapis.com/davis-associates.appspot.com/fm-hero-figure.svg"
                        role="presentation"
                    />
                    <StyledFormBody
                        onSubmit={handleSubmit(onSubmit)}
                        spellCheck={false}
                        aria-label="log in"
                    >
                        <FormResults
                            {...requestResetResult}
                            validationErrors={{}}
                        />
                        <FlexContainer
                            flexDirection="column"
                            style={{gap: '2px', padding: '1rem',}}>
                            <FormHeading
                                component="h2"
                                id="password-reset-request-heading"
                            >
                                Reset Password
                            </FormHeading>
                            <hr
                                style={{
                                    border: `.5px solid ${theme.palette.slickRick}`,
                                    width: '350px',
                                    marginTop: '10px',
                                    position: 'relative',
                                    marginBottom: '10px',
                                    left: '-1rem'
                                }}
                            />
                            <Typography style={{padding: '.5rem'}} color="primary" variant="caption1">
                                Send code via email
                            </Typography>
                            <Textbox
                                style={{
                                    height: '40px',
                                }}
                                labelText="Email Address"
                                error={formState.errors.email}
                                {...register('email', {
                                    required: 'A valid email address is required',
                                    validate: (v) =>
                                        isValidEmail(v)
                                            ? true
                                            : 'A valid email address is required',
                                })}
                            />
                            <hr
                                style={{
                                    border: `.5px solid ${theme.palette.slickRick}`,
                                    width: '350px',
                                    marginTop: '20px',
                                    position: 'relative',
                                    marginBottom: '10px',
                                    left: '-1rem'
                                }}
                            />
                            <Button
                                style={{
                                    color: theme.palette.oldManGray,
                                    backgroundColor: theme.palette.darkBaby,
                                    borderColor: theme.palette.drapeGray,
                                    height: '42px',
                                    width: '100%',
                                }}
                                type="submit"
                                disabled={
                                    requestResetResult.isSuccess ||
                                    requestResetResult.isLoading
                                }
                            >
                                Continue
                            </Button>
                            {requestResetResult.isSuccess && (
                                <Typography>
                                    An email containing a reset link has been
                                    sent
                                    to
                                    the email address provided
                                </Typography>
                            )}
                        </FlexContainer>
                    </StyledFormBody>
                </FlexContainer>
                <StyledCopyright/>
            </StyledMain>
        </StyledLanding>
        // <StyledMain>
        // 	<StyledContainer>
        // 		<FormBody
        // 			style={{
        // 				padding: '15px 25px',
        // 				marginRight: '-55px',
        // 			}}
        // 			onSubmit={handleSubmit(onSubmit)}
        // 			aria-labelledby="password-reset-request-heading"
        // 		>
        //
        // 		</FormBody>
        // 		<StyledLogo
        // 			style={{
        // 				position: 'relative',
        // 			}}
        // 			src="https://storage.googleapis.com/davis-associates.appspot.com/fm-hero-figure.svg"
        // 			role="presentation"
        // 		/>
        // 	</StyledContainer>
        // </StyledMain>
    );
};

export default RequestPWResetPage;
