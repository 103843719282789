import Spinner from '../../common/loading/Spinner';
import Typography from '../../common/text/Typography';
import {flow, sortByString} from '../../common/utils/functionUtils';
import {parseQueryError} from '../api/helpers';
import {QueryErrResult} from '../api/types';
import Card from './components/Card';
import CatalogSummaryListItem
    from './components/CatalogBrowser/CatalogSummaryListItem';
import DataSetListItem from './components/DatasetBrowser/DataSetListItem';
import SourceSummaryListItem
    from './components/SourceBrowser/SourceSummaryListItem';
import {StyledList,} from './components/styledComponents';
import {
    DisplaySourceType,
    CredsParser,
    SFTPFormValues,
    SFTPPayload,
    BigQueryPayload,
    BigQueryFormValues,
    GCSFormValues,
    GCSPayload,
    S3FormValues,
    S3Payload,
    MySQLPayload,
    MySQLFormValues,
    GHubPublicFormValues,
    GHubPublicPayload,
    URLFormValues,
    URLPayload,
    CredentialFormValues,
    CredsSchema,
} from './types/credentialTypes';
import {
    LiveDataCatalogs,
    LiveDataCatalog,
    DataSet,
    DataSourceSummaries,
    DataSourceSummary,
    LiveDataSet,
    LiveDataSets,
    SourceType,
    GetDataProfilesParams,
    DataProfileSubject,
    GetDataTestsParams,
    DataTestSubject,
    GetDataStatisticsParams,
    DataStatisticSubject,
    GetDataErrorsParams,
    DataErrorSubject,
    DataSubject
} from './types/dataTypes';
import {
    isLiveDataCatalogs,
    isLiveDataCatalog,
    isLiveDataSets,
    // DataSetter,
    SourceSetter,
    isSourceSummaries,
    isSourceSummary,
    SelectedSourceData,
    SourceNavItemData,
    SourceNavItemList,
    // CardSetter,
    // CatalogCardSetter,
    // DatasetCardSetter
} from './types/uiTypes';

import {
    SuccessIcon,
    FailIcon,
    LoadingIcon,
    DataFileUnknownIcon,
    DataCatalogIcon,
    DataSourceIcon,
    DataSetIcon,
    CacheDataSetIcon,
    DropDataSetIcon,
    MoveDataSetIcon,
    DescribeDataSetIcon,
    GenerateIcon,
    AnonymizeDataSetIcon,
} from 'common/icons'
import {IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {isValidURL} from 'common/utils/typeGuards';
import {FormState, RegisterOptions, UseFormRegister} from 'react-hook-form';
import styled from "styled-components";
import Heading from "../../common/text/Heading";
import {harvestErrors, SubtleTextbox} from "../../common/inputs/SubtleTextBox";
import React from "react";
import BigQueryKeyInput from "../../common/inputs/fileInputs/BigQueryKeyInput";
import GCSKeyInput from "../../common/inputs/fileInputs/GCSKeyInput";
import AccessKeyInput from "../../common/inputs/AccessKeyInput";
import AccessSecretInput from "../../common/inputs/AccessSecretInput";
import UsernameInput from "../../common/inputs/UsernameInput";
import PasswordInput from "../../common/inputs/PasswordInput";
import HostInput from "../../common/inputs/HostInput";
import PortInput from "../../common/inputs/PortInput";
import URLInput from "../../common/inputs/WebURLInput";


// faFolder,
// faRotate,
interface CardProps {
    key: string;
    icon: IconDefinition;
    title: string;
    width?: string;
    onClick: () => void;
}

//	hard-drive
//	hand-fist
//	ghost
//	gem
//	heart
//	fire
//	fire-flame-curved
//	link
//	link-slash

//	location-arrow
//	location-crosshairs
//	location-dot
//	location-pin
//	location-pin-lock

//	lock
//	lock-open

//	locust
//	network-wired
//	newspaper

//	object-group
//	object-ungroup
//	file-export
//	file-import
//	file-circle-minus
//	file-circle-plus
//	file-arrow-down
//	file-arrow-up
//	folder
//	folder-closed
//	folder-open

//  percent
//  plus
//  plus-minus
//  square-root-variable
//  superscript
//  timeline
//  toggle-off
//  toggle-on
//  q
//  quote-left
//  quote-right
//  signal

const deriveCardProps =
    (setter: SourceSetter) =>
        (data: SourceNavItemData): CardProps =>
            isLiveDataCatalog(data)
                ? {
                    key: data.name,
                    icon: DataCatalogIcon,
                    title: data.name,
                    width: '100px',
                    onClick: () => setter(data),
                }
                : isSourceSummary(data)
                ? {
                    key: String(data._id),
                    icon: DataSourceIcon,
                    title: data.name,
                    width: '100px',
                    onClick: () => setter(data),
                }
                : {
                    key: `${data.catalogName}-${data.name}-dataset`,
                    icon: DataSetIcon,
                    title: data.name,
                    width: '100px',
                    onClick: () => setter(data),
                };

const renderCard = (props: CardProps) => <Card {...props} />;

export const renderCards = (
    data: SourceNavItemList,
    setter: SourceSetter
) => {
    console.log(data)
    data.map(flow(deriveCardProps(setter), renderCard));
}

export const mapSourceSummariesToCards = (
    dataSources: DataSourceSummaries,
    setter: SourceSetter
) =>
    dataSources.map((s) => (
        <Card
            key={s._id}
            icon={DataSourceIcon}
            title={s.name}
            onClick={() => setter(s)}
        />
    ));

export const mapDataCatalogsToCards = (
    catalogs: LiveDataCatalogs,
    setter: SourceSetter
) =>
    catalogs.map((summary) => (
        <Card
            key={summary.name + summary.sourceName}
            icon={DataCatalogIcon}
            title={summary.name}
            onClick={() => setter(summary)}
        />
    ));

export const mapDataSetsToCards = (dataSets: LiveDataSets, setter: SourceSetter) =>
    dataSets.map((dataSet) => (
        <Card
            key={`${dataSet.catalogName}-${dataSet.name}-dataSet`}
            icon={DataSetIcon}
            title={dataSet.name}
            onClick={() => setter(dataSet)}
        />
    ));

export const getCardMapper = (onDisplay: SelectedSourceData) => {
    if (isSourceSummaries(onDisplay)) return mapSourceSummariesToCards;

    if (isSourceSummary(onDisplay)) return mapDataCatalogsToCards;

    if (isLiveDataCatalog(onDisplay)) return mapDataSetsToCards;
};

export const identifyNavItemData = (data: SourceNavItemData) => {
    if (isSourceSummary(data)) {
        return 'source';
    }
    if (isLiveDataCatalog(data)) {
        return 'catalog';
    }

    return 'dataset';
};

export const identifyNavListData = (data: SourceNavItemList) => {
    if (isLiveDataCatalogs(data)) return 'catalogs';

    if (isLiveDataSets(data)) return 'datasets';

    return 'sources';
};

export const selectNavItemName = (data: SourceNavItemData) => {
    const kind = identifyNavItemData(data);

    switch (kind) {
        case 'source':
            return (data as DataSourceSummary).name;

        case 'dataset':
            const {name: datasetName, label: label} = data as LiveDataSet;
            return datasetName;

        case 'catalog':
            const {
                name: catalogName,
                label: catalogLabel
            } = data as LiveDataCatalog;
            return catalogName;
    }
};

const StyledHeading = styled(Heading)`
	padding: ${(p) => p.theme.spacing(2)} 0 ${(p) => p.theme.spacing(1)} 5px;
	margin-bottom: 5px;
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
	text-transform: capitalize;
	font-size: 16px !important;
`;

export const mapSourceSummariesToNavList = (
    data: DataSourceSummaries,
    setter: SourceSetter,
    // cardSetter: CardSetter,
    currentlySelected: SelectedSourceData
) => {

    if (data) {
        const grouped = data.reduce((acc, next) => {
            const type = next.dialect;
            const existing = acc.get(type);

            if (existing) {
                acc.set(type, [...existing, next]);
                return acc;
            }

            acc.set(type, [next]);
            return acc;
        }, new Map());

        //  put each array of attributes in alphabetical order based on their 'singular' field
        data && grouped.forEach((vals, key, map) =>
            map.set(
                key,
                sortByString(vals, (a: DataSourceSummary) => a.name)
            )
        );

        const sources = Array.from(grouped.entries())
            .map(([key, val]) => ({
                type: key,
                sources: val,
            }))
            .sort((a, b) => {
                const nameA = a.type;

                const nameB = b.type;

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            });

        return (
            <>
                {sources.map(({type, sources}, index) => {
                    if (index === 0) {
                        return (
                            <div style={{marginTop: '0'}} key={type}>
                                <Typography variant='h6'>
                                    {type}
                                </Typography>
                                {sources.map((summary: any, index: number) => (
                                    <SourceSummaryListItem
                                        index={index}
                                        onDisplay={summary}
                                        setter={setter}
                                        key={summary._id}
                                        currentlySelected={currentlySelected}
                                        // cardSetter={cardSetter}
                                    />
                                ))}
                            </div>
                        )
                    } else {
                        return (
                            <div style={{marginTop: '1rem'}} key={type}>
                                <Typography variant='h5'>
                                    {type}
                                </Typography>
                                {sources.map((summary: any, index: number) => (
                                    <SourceSummaryListItem
                                        index={index}
                                        onDisplay={summary}
                                        setter={setter}
                                        key={summary._id}
                                        currentlySelected={currentlySelected}
                                        // cardSetter={cardSetter}
                                    />
                                ))}
                            </div>
                        )
                    }
                })}
            </>
        )

    } else {
        return null
    }

};

export const mapDataCatalogsToNavList = (
    catalogs: LiveDataCatalogs | LiveDataSets,
    setter: SourceSetter,
    // cardSetter: CatalogCardSetter,
    currentlySelected: SelectedSourceData,
    sourceId: number | symbol
) => {
    console.log(catalogs);
    if (catalogs) {
        return catalogs.map((catalog) => {
            console.log(catalog);
            return catalog._object === 'DataCatalog'
                ? <CatalogSummaryListItem
                    sourceId={sourceId}
                    onDisplay={catalog}
                    currentlySelected={currentlySelected}
                    setter={setter}
                    key={catalog.name + catalog.sourceName}
                    // cardSetter={cardSetter}
                    {...catalog}
                /> : <DataSetListItem
                    onDisplay={catalog}
                    currentlySelected={currentlySelected}
                    setter={setter}
                    key={`${catalog.sourceName}-${catalog.catalogName}-${catalog.name}-dataSet`}
                    {...catalog}
                />
        });
    } else {
        return null
    }
}

export const mapDataSetsToNavList = (
    datasets: LiveDataSets,
    // cardSetter: Data,
    setter: SourceSetter,
    currentlySelected: SelectedSourceData
) => {
    return datasets.map((dataSet) => (
        <DataSetListItem
            onDisplay={dataSet}
            currentlySelected={currentlySelected}
            setter={setter}
            key={`${dataSet.catalogName}-${dataSet.datasetName}-dataSet`}
            {...dataSet}
        />
    ));
}

export const mapSourceDataToNav = (
    data: SourceNavItemList,
    setter: SourceSetter,
    // cardSetter: CardSetter | CatalogCardSetter | DatasetCardSetter,
    currentlySelected: SelectedSourceData,
    sourceId: number | symbol
) => {
    const kind = identifyNavListData(data);

    switch (kind) {
        case 'catalogs':
            return mapDataCatalogsToNavList(
                data as LiveDataCatalogs,
                setter,
                // cardSetter as CatalogCardSetter,
                currentlySelected,
                sourceId
            );

        case 'datasets':
            return mapDataSetsToNavList(
                data as LiveDataSets,
                setter,
                // cardSetter as DatasetCardSetter,
                currentlySelected
            );

        case 'sources':
            return mapSourceSummariesToNavList(
                data as DataSourceSummaries,
                setter,
                // cardSetter as CardSetter,
                currentlySelected
            );

        default:
            return null;
    }
};

interface DropdownProps {
    isUninitialized: boolean;
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    error?: QueryErrResult;
    data?: SourceNavItemList;
    setter: SourceSetter;
    // cardSetter: CardSetter | CatalogCardSetter;
    currentlySelected: SelectedSourceData;
    sourceId: number | symbol;
}

export const renderDropdown = (
    {
        isUninitialized,
        isError,
        isFetching,
        isLoading,
        error,
        data,
        setter,
        // cardSetter,
        currentlySelected,
        sourceId,
    }: DropdownProps) => {
    if (isUninitialized) {
        return null;
    }

    if (isLoading || isFetching) {
        return <Spinner/>;
    }

    if (isError && error) {
        const {message} = parseQueryError(error);

        return <Typography>{message}</Typography>;
    }

    return (
        <StyledList>
            {mapSourceDataToNav(
                data as SourceNavItemList,
                setter,
                // cardSetter,
                currentlySelected,
                sourceId
            )}
        </StyledList>
    );
};

export const mapDisplaySourceTypeToCredentials = (
    sourceType: DisplaySourceType
): { schema: CredsSchema; sourceType: SourceType } => {
    switch (sourceType) {
        case 'bigquery':
            return {
                schema: 'service_account',
                sourceType: 'bigquery'
            };

        case 'google cloud storage':
            return {
                schema: 'service_account',
                sourceType: 'gcs',
            };

        case 'mysql':
            return {
                schema: 'basic_auth',
                sourceType: 'mysql'
            };

        case 's3':
            return {
                schema: 'access_key',
                sourceType: 's3'
            };

        case 'sftp':
            return {
                schema: 'basic_auth',
                sourceType: 'sftp'
            };

        case 'github':
            return {
                schema: null,
                sourceType: 'github'
            };

        case 'url':
            return {
                schema: null,
                sourceType: 'url'
            };
    }
};

export const renderSourceFormInputs = (
    validatingRegister: any,
    resetField: any,
    mutationResults: any,
    displaySourceType: DisplaySourceType,
    register: UseFormRegister<any>,
    formState: FormState<any>
) => {
    const registerRequired = (name: string, opts?: RegisterOptions) =>
        register(name, {
            required: {
                value: true,
                message: `A value for ${name} is required`,
            },
            ...opts,
        });

    const registerOptional = (name: string, opts?: RegisterOptions) =>
        register(name, {
            required: {
                value: false,
                message: `A value for ${name} is required`,
            },
            ...opts,
        });

    switch (displaySourceType) {
        case 'bigquery':
            return <BigQueryKeyInput registerRequired={registerRequired}/>;

        case 'google cloud storage':
            return <GCSKeyInput registerRequired={registerRequired}/>

        case 's3':
            return (
                <>
                    <AccessKeyInput
                        formState={formState}
                        resetField={resetField}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                    <AccessSecretInput
                        formState={formState}
                        resetField={resetField}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                </>
            );

        case 'sftp':
            return (
                <>
                    <UsernameInput
                        formState={formState}
                        resetField={resetField}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                    <PasswordInput
                        formState={formState}
                        resetField={resetField}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                    <HostInput
                        formState={formState}
                        resetField={resetField}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                    <PortInput
                        formState={formState}
                        resetField={resetField}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />

                </>
            );

        case 'mysql':
            return (
                <>
                    <SubtleTextbox
                        {...validatingRegister('database')}
                        showLabel
                        key="database"
                        label="Database"
                        isDirty={Boolean(formState.dirtyFields.database)}
                        onReset={() => resetField('database')}
                        error={harvestErrors(
                            'database',
                            formState,
                            mutationResults
                        )}
                    />
                    <UsernameInput
                        formState={formState}
                        resetField={resetField}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                    <PasswordInput
                        formState={formState}
                        resetField={resetField}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                    <HostInput
                        formState={formState}
                        resetField={resetField}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                    <PortInput
                        formState={formState}
                        resetField={resetField}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                </>
            );

        // case 'github (password)':
        // 	return (
        // 		<>
        // 			<Textbox
        // 				{...registerRequired('remoteURL', { value: '' })}
        // 				error={formState.errors.host}
        // 				labelText="repository URL"
        // 				key="remoteURL"
        // 			/>
        // 			<Textbox
        // 				{...registerRequired('username', { value: '' })}
        // 				error={formState.errors.username}
        // 				labelText="Username"
        // 				key="username"
        // 			/>
        // 			<Textbox
        // 				{...registerRequired('password', { value: '' })}
        // 				error={formState.errors.password}
        // 				labelText="Password"
        // 				type="password"
        // 				key="password"
        // 			/>
        // 		</>
        // 	);
        case 'github':
            return (
                <URLInput
                    name={'remoteUrl'}
                    label={"Remote URL"}
                    tooltip={"Remote repo URL"}
                    formState={formState}
                    resetField={resetField}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                />
            );
        case 'url':
            return (
                <>
                    <URLInput
                        name={'downloadUrl'}
                        label={"Download URL"}
                        tooltip={"Download repo URL"}
                        formState={formState}
                        resetField={resetField}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                    <URLInput
                        name={'accessUrl'}
                        label={"Access URL"}
                        tooltip={"Download repo URL"}
                        formState={formState}
                        resetField={resetField}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                </>
            );
    }
};

const fileDataToString = (file: File): Promise<string> =>
    new Promise((res, rej) => {
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            res(reader.result as string);
        });

        try {
            reader.readAsText(file, 'UTF-8');
        } catch (e) {
            rej(e);
        }
    });

const parseBigQuery: CredsParser<BigQueryFormValues, BigQueryPayload> = (
    formValues,
    orgId
) => {
    return fileDataToString((formValues.keyFile as FileList)[0])
        .then(JSON.parse)
        .then((serviceAccountData) => ({
            type: formValues.sourceType,
            orgId,
            credsSchema: formValues.credsSchema,
            name: formValues.name,
            creds: serviceAccountData,
        }));
};

const parseGCS: CredsParser<GCSFormValues, GCSPayload> = (
    formValues,
    orgId
) => {
    return fileDataToString((formValues.keyFile as FileList)[0])
        .then(JSON.parse)
        .then((serviceAccountData) => ({
            type: formValues.sourceType,
            orgId,
            credsSchema: formValues.credsSchema,
            name: formValues.name,
            creds: serviceAccountData,
        }));
};

const parseS3: CredsParser<S3FormValues, S3Payload> = (formValues, orgId) =>
    Promise.resolve({
        type: formValues.sourceType,
        name: formValues.name,
        orgId,
        credsSchema: formValues.credsSchema,
        creds: {
            id: formValues.id,
            secret: formValues.secret,
        },
    });

const parseSFTP: CredsParser<SFTPFormValues, SFTPPayload> = (
    creds,
    orgId
) => {
    const portNumber = parseInt(creds.port, 10);

    if (isNaN(portNumber)) {
        return Promise.reject(
            'Unable to convert the provided port value to type "number"'
        );
    }

    return Promise.resolve({
        type: creds.sourceType,
        name: creds.name,
        orgId,
        credsSchema: creds.credsSchema,
        host: creds.host,
        port: portNumber,
        creds: {
            username: creds.username,
            password: creds.password,
        },
    });
};

const parseMySQL: CredsParser<MySQLFormValues, MySQLPayload> = (
    formValues,
    orgId
) => {
    const portNumber = parseInt(formValues.port, 10);

    if (isNaN(portNumber)) {
        return Promise.reject(
            'Unable to convert the provided port value to type "number"'
        );
    }

    return Promise.resolve({
        type: formValues.sourceType,
        name: formValues.name,
        orgId,
        credsSchema: formValues.credsSchema,
        database: formValues.database,
        host: formValues.host,
        port: portNumber,
        creds: {
            username: formValues.username,
            password: formValues.password,
        },
    });
};

const parseGHubPublic: CredsParser<GHubPublicFormValues, GHubPublicPayload> = (
    formValues,
    orgId
) => {
    return Promise.resolve({
        type: formValues.sourceType,
        name: formValues.name,
        orgId,
        credsSchema: null,
        remoteUrl: formValues.remoteUrl,
        creds: null,
    });
};

const parseURL: CredsParser<URLFormValues, URLPayload> = (
    formValues,
    orgId
) => {
    return Promise.resolve({
        type: formValues.sourceType,
        name: formValues.name,
        orgId,
        credsSchema: null,
        downloadUrl: formValues.downloadUrl,
        accessUrl: formValues.accessUrl,
        creds: null,
    });
};

const parserMap: Record<DisplaySourceType, CredsParser<any, any>> = {
    bigquery: parseBigQuery,
    s3: parseS3,
    sftp: parseSFTP,
    url: parseURL,
    'google cloud storage': parseGCS,
    mysql: parseMySQL,
    github: parseGHubPublic,
};

export const parseSubmittedCredentials = (
    submittedCreds: CredentialFormValues,
    orgId: number
) => {
    return parserMap[submittedCreds.displaySourceType](
        submittedCreds,
        orgId
    );
};

/**
 * Choose icon that populates generate source buttons
 */
interface GenerationStatus {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    isUninitialized: boolean;
}

export const selectButtonIcon = (status: Partial<GenerationStatus>, type?: string) => {

    if (type) {
        switch (type) {
            case 'cache':
                return CacheDataSetIcon
            case 'drop':
                return DropDataSetIcon
            case 'move':
                return MoveDataSetIcon
            case 'describe':
                return DescribeDataSetIcon
            case 'anonymize':
                return AnonymizeDataSetIcon
            default:
                return GenerateIcon
        }
    }

    const {isLoading, isError, isSuccess} = status;

    if (isLoading) return LoadingIcon;

    if (isError) return FailIcon;

    if (isSuccess) return SuccessIcon;

    return GenerateIcon;
};

export const getURLStumpFromObjectType = (objectType: DataSubject) => {
    switch (objectType) {
        case 'DataSource':
            return 'sources';
        case 'DataCatalog':
            return 'catalogs';
        case 'Dataset':
            return 'datasets';
        case 'DataColumn':
            return 'columns';
        case 'Domain':
            return 'domains';
        case 'Entity':
            return 'entities';
        case 'Attribute':
            return 'attributes';
        case 'Individual':
            return 'individuals';
    }
};

export const buildDataProfilesQueryURL = (
    {
        objectId,
        objectType,
        perPage = 10,
        page = 1
    }: GetDataProfilesParams) =>
    `/${getURLStumpFromObjectType(objectType)}/${objectId}/profiles?page=${page}&per_page=${perPage}`;

export const buildParametrizedDataProfilesQueryURL = (
    objectType: DataProfileSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/profiles?page=:page&per_page=:perPage`;

export const buildDataTestsQueryURL = (
    {
        objectId,
        objectType,
        perPage = 10,
        page = 1
    }: GetDataTestsParams) =>
    `/${getURLStumpFromObjectType(objectType)}/${objectId}/tests?page=${page}&per_page=${perPage}`;

export const buildParametrizedDataTestsQueryURL = (
    objectType: DataTestSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/tests?page=:page&per_page=:perPage`;

export const buildDataStatisticsQueryURL = (
    {
        objectId,
        objectType,
        perPage = 10,
        page = 1
    }: GetDataStatisticsParams) =>
    `/${getURLStumpFromObjectType(objectType)}/${objectId}/statistics?page=${page}&per_page=${perPage}`;

export const buildParametrizedDataStatisticsQueryURL = (
    objectType: DataStatisticSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/statistics?page=:page&per_page=:perPage`;

export const buildDataErrorsQueryURL = (
    {
        objectId,
        objectType,
        perPage = 10,
        page = 1
    }: GetDataErrorsParams) =>
    `/${getURLStumpFromObjectType(objectType)}/${objectId}/errors?page=${page}&per_page=${perPage}`;

export const buildParametrizedDataErrorsQueryURL = (
    objectType: DataErrorSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/errors?page=:page&per_page=:perPage`;

