import {FormState} from "react-hook-form";
import {BaseAttribute} from "../../features/ontology/types/attributeTypes";
import Typography from "../text/Typography";
import {formFieldHasErrors, genErrorIdFromLabel} from "../Form";
import React, {ChangeEvent, FunctionComponent} from "react";
import SubtleSelect from "./SubtleSelect";

/**
 * Render reference attribute <select> menu
 */
export const OtherIdSelect: FunctionComponent<{fieldName: string; validatingRegister: any; formState: FormState<any>; selectableAttrs: BaseAttribute[] | null;}> = (
    {
        fieldName,
        validatingRegister,
        formState,
        selectableAttrs,
    }
) => {
    if (selectableAttrs === null || selectableAttrs.length === 0) {
        return (
            <Typography paragraph color="warn">
                No eligible attributes for this operation
            </Typography>
        );
    }
    return (
        <SubtleSelect
            name={fieldName}
            id={fieldName}
            aria-invalid={formFieldHasErrors(fieldName, formState)}
            aria-errormessage={genErrorIdFromLabel(fieldName)}
            inline={true}
            label={'Select a reference attribute'}
            alignItems={'center'}
            justifyContent={'space-between'}
            {...validatingRegister(fieldName, {
                valueAsNumber: true,
                validate: (v: string | number) => {
                    if (typeof v === 'string') {
                        const maybeInt = parseInt(v, 10);

                        return isNaN(maybeInt)
                            ? 'Invalid value passed to otherId'
                            : maybeInt > 0
                                ? true
                                : 'A reference attribute must be selected if you are not using a static files value';
                    }

                    return v > 0
                        ? true
                        : 'A reference attribute must be selected if you are not using a static files value';
                },
            })}
        >
            {selectableAttrs.map((attr) => (
                <option value={attr._id} key={attr._id}>
                    {`${attr.entity.singular}: ${attr.singular}`}
                </option>
            ))}
        </SubtleSelect>
    )
};

//	if (selectableAttrs === null || selectableAttrs.length === 0) {
// 		return (
// 			<Typography paragraph color="warn">
// 				No eligible attributes for this operation
// 			</Typography>
// 		);
// 	}
// 	return (
// 		<>
// 			<label htmlFor={fieldName}>
// 				<Typography>Select a reference attribute</Typography>
// 			</label>
// 			<select
// 				{...register(fieldName, {
// 					valueAsNumber: true,
// 					validate: (v: string | number) => {
// 						if (typeof v === 'string') {
// 							const maybeInt = parseInt(v, 10);
//
// 							return isNaN(maybeInt)
// 								? 'Invalid value passed to otherId'
// 								: maybeInt > 0
// 								? true
// 								: 'A reference attribute must be selected if you are not using a static files value';
// 						}
//
// 						return v > 0
// 							? true
// 							: 'A reference attribute must be selected if you are not using a static files value';
// 					},
// 				})}
// 				id={fieldName}
// 				aria-errormessage={genErrorIdFromLabel(fieldName)}
// 				aria-invalid={formFieldHasErrors(fieldName, formState)}
// 			>
// 				{selectableAttrs.map((attr) => (
// 					<option
// 						value={attr._id}
// 						key={attr._id}
// 					>{`${attr.entity.singular}: ${attr.singular}`}</option>
// 				))}
// 			</select>
// 		</>
// 	);


export default OtherIdSelect;
