import styled from "styled-components";

export const StyledGraphicContainer = styled.div<{width?: string, height?: string}>`
	height:100%;
	width: 100%;
	position:relative;
	display:inline-block;
	height: ${(p) => p.height ? p.height : '32px'};  
  	width: ${(p) => p.width ? p.width : '32px'};  
`

// const StyledGraphicContainer = styled.div`
// 	position: relative;
// 	height:100%;
// 	padding-right: 100%;
// 	width: 0;
// 	float: left;
// `

export default StyledGraphicContainer;
