import React, {FunctionComponent} from "react";
import {getRestrictionOperators} from "../../features/actions/common/helpers";
import SubtleSelect from "./SubtleSelect";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {BaseAttribute} from "../../features/ontology/types/attributeTypes";
import {harvestErrors} from "./SubtleTextBox";

interface RestrictionTypeSelectProps extends ActionFieldProps {
    restrictedAttribute: BaseAttribute;
}

const RestrictionTypeSelect: FunctionComponent<RestrictionTypeSelectProps> = (
    {
        restrictedAttribute,
        formState,
        resetField,
        validatingRegister
    }
    ) => {

    // What comparison should be used to
    //                 {` ${
    //                     restrictionKind === 'specialization'
    //                         ? 'specialize'
    //                         : 'restrict'
    //                 } ${singular}`}

    //          aria-errormessage={genErrorIdFromLabel(
    //                 'restrictionType'
    //             )}
    //             aria-invalid={formFieldHasErrors(
    //                 'restrictionType',
    //                 formState
    //             )}

    return <SubtleSelect
        inline={true}
        label={`Restriction Type`}
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('restrictionType')}
        isDirty={Boolean(formState.dirtyFields.restrictionType)}
        {...validatingRegister('restrictionType', {
            required: 'A comparison operator must be selected',
        })}
    >
        {restrictedAttribute
            ? getRestrictionOperators(restrictedAttribute.type)
                    .map(({operator, displayValue}) => (
                        <option value={operator} key={operator}>
                            {displayValue}
                        </option>
                    ))
            : <option value="none">
                First select attribute above
        </option>}
    </SubtleSelect>;
}

export default RestrictionTypeSelect;
