import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";
import Textbox from "./TextBox";

const AccessSecretInput: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleTextbox
        {...validatingRegister('secret')}
        showLabel
        key="secret"
        label="Access Key Secret"
        isDirty={Boolean(formState.dirtyFields.secret)}
        onReset={() => resetField('secret')}
        error={harvestErrors(
            'secret',
            formState,
            mutationResults
        )}
    />;
}

export default AccessSecretInput;
