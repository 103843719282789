import {
	QTY_RANGE_FILTER_TYPE,
	CAT_RANGE_FILTER_TYPE,
	EVENT_RANGE_FILTER_TYPE,
	IDENTITY_FILTER_TYPE,
} from './CONSTANTS';

export type QsParam = [string, string];

export type AttrFilterType =
	| typeof QTY_RANGE_FILTER_TYPE
	| typeof IDENTITY_FILTER_TYPE
	| typeof EVENT_RANGE_FILTER_TYPE
	| typeof CAT_RANGE_FILTER_TYPE;

export type AttributeFilter =
	| QtyRangeFilter
	| IdentityFilter
	| CatRangeFilter
	| EventRangeFilter;

interface BaseAttributeFilter {
	attributeName: string;
	filterType: AttrFilterType;
	searchParamKey: string;
	searchParamValue: string;
}

export interface QtyRangeFilter extends BaseAttributeFilter {
	filterType: typeof QTY_RANGE_FILTER_TYPE;
	min: number;
	max: number;
}

export const isEventRangeFilter = (f: AttributeFilter): f is EventRangeFilter =>
	f.filterType === EVENT_RANGE_FILTER_TYPE;

export const isQtyRangeFilter = (f: AttributeFilter): f is QtyRangeFilter =>
	f.filterType === QTY_RANGE_FILTER_TYPE;

export const isIdentityFilter = (f: AttributeFilter): f is IdentityFilter =>
	f.filterType === IDENTITY_FILTER_TYPE;

export const isCatRangeFilter = (f: AttributeFilter): f is CatRangeFilter =>
	f.filterType === CAT_RANGE_FILTER_TYPE;

export interface CatRangeFilter extends BaseAttributeFilter {
	filterType: typeof CAT_RANGE_FILTER_TYPE;
	includedValues: string[];
}
export interface EventRangeFilter extends BaseAttributeFilter {
	filterType: typeof EVENT_RANGE_FILTER_TYPE;
	min: number;
	max: number;
}

export interface IdentityFilter extends BaseAttributeFilter {
	filterType: typeof IDENTITY_FILTER_TYPE;
	//  primary ID could be string or number, but we'll coerce all numbers to strings
	// before checking them.
	includedIdentities: Array<string>;
}

export type FilterValidator<T> = { [K in keyof T]: (p: unknown) => boolean };

export type AttributeFilters = AttributeFilter[];
