import useHookId from './useHookId';
import { axisBottom } from 'd3-axis';
import { format } from 'd3-format';
import { ScaleLinear, ScaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { useLayoutEffect } from 'react';

const classBase = 'linear-x-axis';

const useLinearXAxis = (
	xScale: ScaleLinear<any, any> | ScaleTime<any, any>,
	height: number,
	bottomMargin: number,
	leftMargin?: number,
	config?: {
		thresholds?: any;
		tickFormat?: string;
	}
) => {
	const className = useHookId(classBase);

	useLayoutEffect(() => {
		const selector = `.${className}`;

		const axisGroup = select<SVGGElement, unknown>(selector);

		if (axisGroup) {
			const axis = axisBottom(xScale).tickSize(0).tickPadding(5).ticks(10);

			if (config?.thresholds) {
				axis.tickValues(config.thresholds);
			}

			if (config?.tickFormat) {
				axis.tickFormat(format(config.tickFormat));
			}

			axisGroup
				.attr('transform', `translate(0, ${height - bottomMargin})`)
				.call(axis);
		}
	}, [xScale, bottomMargin, height, className, config]);

	return className;
};

export default useLinearXAxis;
