import { useGetSourceSummariesQuery } from '../../api';
import useUserID from '../../authentication/hooks/useUserID';
import { DataContextParams } from '../../navigation/types/navigationContextTypes';
import { DataSourceSummary, DataCatalog, DataSet, DataColumn } from '../../browser/types/dataTypes';
import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isEmpty } from 'common/utils/typeGuards';
import { useParams } from 'react-router-dom';
import useOrgID from "../../authentication/hooks/useOrgID";
import useActiveOrgData from "./useActiveOrgData";

// TODO: figure out why this doesn't type correctly in selector fn below
type QueryResponse = Omit<ReturnType<typeof useGetSourceSummariesQuery>, 'refetch'>;

type ActiveSourceName = string | undefined;

const selectActiveSource = createSelector(
	(res: QueryResponse) => res.data,
	(res: QueryResponse, activeSourceName: ActiveSourceName) =>
		activeSourceName,
	(data: QueryResponse['data'], activeSourceName: ActiveSourceName) =>
		data?.find((source: DataSourceSummary) => source.name === activeSourceName) ??
		null
);
/**
 * @returns RTK Query files related to fetching all available orgs for a user, as well
 * as an activeSource property that corresponds to the files for the user specified
 * in the application URL.
 */
const useActiveSourceData = () => {
	const params = useParams() as DataContextParams;

	const {activeItem: activeOrg} = useActiveOrgData();

	// won't run as long as skipToken is its argument
	const fetchData = useGetSourceSummariesQuery(
		activeOrg && activeOrg._id ? { orgId: activeOrg._id }: skipToken,
		{
			selectFromResult: (res) => ({
				...res,
				// important to use a memoizing selector here: selectFromResult
				// uses shallow-equal comparison to determine whether or not a re-render
				// should occur. The only way to get a stable reference for the value of
				// activeItem is to memoize based on inputs.  RTK query takes care of
				// memoizing the value of res.data for us.
				activeItem: selectActiveSource(
					res,
					params.source
				) as null | DataSourceSummary,
			}),
		}
	);

	return fetchData;
};

export default useActiveSourceData;
