import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleSelect from "./SubtleSelect";

const relationTypes = [
    {
        name: "relationOf",
        label: " related to"
    },
    {
        name: "sameAs",
        label: "same as"
    },
    {
        name: "oppositeOf",
        label: "opposite of"
    },
    {
        name: "alternateOf",
        label: "alternate of"
    },
    {
        name: "specializationOf",
        label: "specialization of"
    },
    {
        name: "equivalentTo",
        label: "equivalent to"
    },
    {
        name: "partOf",
        label: "part of"
    },
    {
        name: "componentOf",
        label: "component of"
    },
    {
        name: "portionOf",
        label: "portion of"
    },
    {
        name: "featureOf",
        label: "feature of"
    },
    {
        name: "memberOf",
        label: "member of"
    },
    {
        name: "attachedTo",
        label: "attached to"
    },
    {
        name: "possessedBy",
        label: "possessed by"
    },
    {
        name: "influencedBy",
        label: "influenced by"
    }
];


const RelationTypeSelect: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleSelect
        inline={true}
        label={`Encryption Type`}
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('type')}
        isDirty={Boolean(formState.dirtyFields.type)}
        {...validatingRegister('type')}
    >
        {relationTypes.map((type: {name: string, label: string}) =>{
            return <option key={type.name} value={type.name}>
                {type.label}
            </option>
        })}
    </SubtleSelect>;
};

export default RelationTypeSelect;
