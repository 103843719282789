import { composeName } from '../../features/governance/helpers';
import {
	StyledMemberMgmtHeading,
	StyledHeadingContainer,
} from '../../features/governance/components/styledComponents';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { nanoid } from '@reduxjs/toolkit';
import IconButton from 'common/buttons/IconButton';
import { List, ListItemContent, ListItem } from 'common/List';
import PopoverBase from 'common/popovers/Popover/PopoverBase';
import Typography from 'common/text/Typography';
import { sortByString } from 'common/utils/functionUtils';
import { useCreateOrgMemberMutation, useGetUsersQuery } from 'features/api';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import { extractQueryErrMessage } from 'features/api/helpers';
import { BaseUser } from 'features/authentication/types/userTypes';
import useCombinedOrgMembers from 'features/governance/hooks/useCombinedOrgMembers';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import styled from "styled-components";
import Button from "./Button";
import TextButton from "./TextButton";

const getSortableIdentifier = ({ username, lastName, firstName }: BaseUser) =>
	!!lastName
		? lastName
		: !!username
		? username
		: !!firstName
		? firstName
		: null;

interface UserPromotionPanelProps {
	objectId: number;
	canEdit: boolean;
}


const BaseFollowButton = styled(Button)`
	width: 8rem;
	border: none;
	background-color: ${(p) => p.theme.palette.darkBaby};
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2rem;
	border-radius: .25rem;
	padding: ${(p) => p.theme.spacing(1)};
	&:hover {
		background-color: ${(p) => p.theme.palette.darkBaby};
	}
`;

const AddMemberButton: FunctionComponent<UserPromotionPanelProps> = ({
	objectId,
	canEdit,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const { data: users, ...usersLoadingState } = useGetUsersQuery();

	const existingMembers = useCombinedOrgMembers(objectId);

	const filteredUsers = useMemo(() => {
		if (users) {
			// note all Ids belonging to users that already have a role in the organization
			const used = Object.keys(existingMembers).reduce((acc, key) => {
				existingMembers[
					key as 'admins' | 'governors' | 'members'
				].forEach((member) => acc.add(member._id));
				return acc;
			}, new Set<number>());

			//    exclude pre-existing members from user list, and filter out users that
			// don't have any valid identifier
			return sortByString(
				users.filter(
					(usr) =>
						!used.has(usr._id) &&
						typeof getSortableIdentifier(usr) === 'string'
				),
				getSortableIdentifier as (usr: BaseUser) => string
			);
		}

		return [];
	}, [existingMembers, users]);

	const [menuOpen, setMenuOpen] = useState(false);

	const [updateKey, setUpdateKey] = useState(nanoid());

	const [promote, promotionResult] = useCreateOrgMemberMutation();

	// reset mutation state when menu is closed
	useEffect(() => {
		if (!menuOpen) {
			promotionResult.reset();
		}
	}, [menuOpen, promotionResult]);

	//  Update popover position when users list is loaded; otherwise list
	// will get drawn partially offscreen due to content size change.
	useEffect(() => {
		if (usersLoadingState.isSuccess) {
			setUpdateKey(nanoid());
		}
	}, [usersLoadingState.isSuccess]);

	const renderMemberList = () => {
		if (filteredUsers.length === 0) {
			return <ListItem>No available users</ListItem>;
		}

		return (
			<>
				{promotionResult.isLoading && (
					<ListItem>
						<ListItemContent>
							<Typography color="primary">Updating...</Typography>
						</ListItemContent>
					</ListItem>
				)}
				{promotionResult.isError && (
					<ListItem>
						<ListItemContent>
							<Typography color="error">
								{extractQueryErrMessage(promotionResult.error)}
							</Typography>
						</ListItemContent>
					</ListItem>
				)}
				{filteredUsers.map((usr) => (
					<ListItem
						button
						key={usr._id}
						onClick={() => {
							if (promotionResult.isLoading) {
								return;
							}

							promote({
								orgId: objectId,
								body: { userId: usr._id },
							});
						}}
					>
						{composeName(usr)}
						<br />
						{usr.username}
					</ListItem>
				))}
			</>
		);
	};

	if (!canEdit) {
		return null;
	}

	return (
		<>
			<TextButton
				color='primary'
				fillColor='transparent'
				ref={setAnchorEl}
				text={'Add Member'}
				onClick={() => setMenuOpen((p) => !p)}
			/>
			<PopoverBase
				open={menuOpen}
				placement={'right-start'}
				anchorEl={anchorEl}
				forceUpdateKey={updateKey}
			>
				<ClickAwayListener onClickAway={() => setMenuOpen(false)}>
					<List
						style={{ maxHeight: '300px', overflowY: 'auto' }}
						aria-labelledby="org-user-promotion"
					>
						<DisplayOnLoad {...usersLoadingState}>
							{renderMemberList()}
						</DisplayOnLoad>
					</List>
				</ClickAwayListener>
			</PopoverBase>
		</>
	);
};

//<BaseFollowButton }>
// 				<Typography color={'primary'} variant={'h6'}>
//
// 				</Typography>
// 			</BaseFollowButton>
// 			<IconButton
// 				ref={setAnchorEl}
// 				icon={faPlus}
// 				tooltip={`add user to org`}
// 				showTooltip={!menuOpen}
// 				aria-label={`add user to org`}
//
// 			/>

export default AddMemberButton;
