import {
	FlexDirectionProperties,
	FlexJustifyProperties,
	FlexAlignmentProperties,
} from './utils/typeUtils';
import styled from 'styled-components';

interface Props {
	justifyContent?: FlexJustifyProperties;
	flexDirection?: FlexDirectionProperties;
	alignItems?: FlexAlignmentProperties;
	alignContent?: FlexAlignmentProperties;
}

const FlexContainer = styled.div<Props & Record<string, any>>`
	display: flex;
	flex-direction: ${(p) => p.flexDirection ? p.flexDirection : 'row'};
	justify-content: ${(p) => p.justifyContent};
	justify-items: ${(p) => p.justifyItems};
	align-items: ${(p) => p.alignItems};
	align-content: ${(p) => p.alignContent};
	${(p) => p.flexDirection ? p.flexDirection === 'column' ? 'row' : 'column' : 'column'}-gap: 
	${(p) => p.gap};
	padding: 0;
	margin: 0;
`;

export default FlexContainer;
