import React, {FunctionComponent} from "react";
import Typography from "../text/Typography";
import FlexContainer from "../FlexContainer";
import {AccordionItemButton} from "react-accessible-accordion";

const EditsButton: FunctionComponent = () => {
    return  <FlexContainer>
        <AccordionItemButton>
            <Typography variant='button'>Edited</Typography>
        </AccordionItemButton>
    </FlexContainer>
};

export default EditsButton;
