import useElementSize from 'common/hooks/useSize';
import theme from 'common/theme/theme';
import {
	extent as d3Extent,
	bin,
	Bin,
	max as d3Max,
} from 'd3-array';
import { defaultGraphMargins } from 'common/viz/CONSTANTS';
import {
	StyledSVGContainer,
} from 'common/viz/common/styledComponents';
import { getExactThresholds } from 'common/viz/helpers';
import useBrushX from 'common/viz/hooks/useBrushX';
import useLinearXScale from 'common/viz/hooks/useLinearXScale';
import useLinearYScale from 'common/viz/hooks/useLinearYScale';
import { GraphMargins } from 'common/viz/types';
import { FunctionComponent } from 'react';

interface HUDHistogramProps extends GraphMargins {
	binCount: number;
	data: number[];
	onBrushEnd: (selection: [number, number]) => void;
}

const HUDHistogram: FunctionComponent<HUDHistogramProps> = ({
	binCount = 30,
	data,
	onBrushEnd,
	...margins
}) => {
	const { top, bottom, left, right } = { ...defaultGraphMargins, ...margins };

	const [{ width, height }, setSizeEl] = useElementSize();

	const [min, max] = d3Extent(data);

	const thresholds = getExactThresholds(binCount, min!, max!);

	const bins = bin<number, number>().thresholds(thresholds)(data);

	const yMax = d3Max(bins, (b) => b.length)!;

	const xScale = useLinearXScale({
		xMax: max!,
		xMin: min!,
		left,
		right,
		width,
	});

	const yScale = useLinearYScale({
		yMax,
		top,
		bottom,
		height,
	});

	const invertXValue = xScale.invert;

	const transformBrushMove = (selection: [number, number]) => {
		const [selectionLow, selectionHigh] = selection;

		const lowBin = bins.find((b) => b.x1! > invertXValue(selectionLow))!;

		const highBin = bins.find((b) => b.x1! > invertXValue(selectionHigh))!;

		return [lowBin.x0!, highBin.x1!].map(xScale) as [number, number];
	};

	const { brushClass } = useBrushX({
		left,
		top,
		right,
		bottom,
		onEnd: onBrushEnd,
		invert: invertXValue,
		width,
		height,
		transformBrushMove,
	});

	const drawBar = (bin: Bin<number, number>, i: number) => {
		const { x1, x0 } = bin;

		if (x1 === undefined || x0 === undefined) {
			return null;
		}

		const isSingleBin = x1 === x0;

		const widthCalc = xScale(x1) - xScale(x0);

		const barWidth =
			widthCalc > 2
				? widthCalc - 2
				: isSingleBin
				? width - left - right
				: 0;

		// number of elements in the bin provides the y-axis value
		const heightCalc = height - yScale(bin.length) - bottom;
		const barHeight = heightCalc > 0 ? heightCalc : 0;

		return (
			<rect
				key={i}
				transform={`translate(${
					isSingleBin ? left + 1 : xScale(x0) + 1
				}, ${yScale(bin.length)})`}
				width={barWidth}
				height={barHeight}
				fill={theme.palette.primary.main}
			/>
		);
	};

	return (
		<StyledSVGContainer ref={setSizeEl}>
			<svg
				width={width}
				height={height}
				viewBox={`0 0 ${width} ${height}`}
			>
				<g>{bins.map(drawBar)}</g>
				<g className={brushClass} />
			</svg>
		</StyledSVGContainer>
	);
};

export default HUDHistogram;
