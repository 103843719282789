import IconButton from './IconButton';
import PopoverBase from '../popovers/Popover/PopoverBase';
import { CommentSubject } from '../comments/types/commentTypes';
import CommentList from '../comments/components/CommentList';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import React, { FunctionComponent, useState } from 'react';
import commentIcon from "../icons/CommentIcon";
import TextButton from "./TextButton";

interface CreateCommentButtonProps {
	objectType: CommentSubject;
	objectId: number;
	canEdit: boolean;
}

const CommentButton: FunctionComponent<CreateCommentButtonProps> = ({
	canEdit,
	objectId,
	objectType,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);

	const [menuOpen, setMenuOpen] = useState<string | null>(null);

	return (
		<>
			<TextButton
				ref={setAnchorEl as any}
				baseOpacity="80%"
				color='primary'
				// size="xs"
				text="Comment"
				tooltip="post a comment"
				tooltipPlacement="bottom"
				onClick={() => setMenuOpen((p) => (p ? null : 'open'))}
				type="button"
				showTooltip={!menuOpen}
				aria-label="view comments about this object"
			/>
			<PopoverBase
				anchorEl={anchorEl}
				open={!!menuOpen}
				offset={0}
				handleClickAway={() => setMenuOpen(null)}
			>
				<CommentList
					// This button is only used to create questions for
					// non-individuals, so we can manually pass object
					// identifier for question creation on objects of other
					// types.
					objectIdentifier={{ kind: 'other', objectId }}
					objectType={objectType}
					canEdit={canEdit}
				/>
			</PopoverBase>
		</>
	);
};

export default CommentButton;
