import Button from '../../../common/buttons/Button';
import FlexContainer from '../../../common/FlexContainer';
import {
    formFieldHasErrors,
    FormResults,
    genErrorIdFromLabel,
} from '../../../common/Form';
import IconButton from '../../../common/buttons/IconButton';
import Spinner from '../../../common/loading/Spinner';
import Typography from '../../../common/text/Typography';
import {useCreateConditionalMutation, useGetAttributesQuery} from '../../api';
import {extractQueryErrMessage} from '../../api/helpers';
import {
    AttributeBaseType,
    GetEntityAttrsResponse
} from '../../ontology/types/attributeTypes';
import {ActionFieldProps, EntityActionFormProps} from '../common/commonTypes';
import {getRestrictionOperators} from '../common/helpers';
import {renderDerivationSubfields} from '../common/jsxHelpers';
import {
    conditionFormDefaults,
    createConditionalFormDefaults,
    createConditionalFormToPayload,
} from './createConditionalHelpers';
import {
    ConditionFormValues,
    CreateConditionalFormValues,
} from './createConditionalTypes';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {
    SubmitHandler,
    useForm,
    useFieldArray,
    RegisterOptions
} from 'react-hook-form';
import styled from 'styled-components';
import AppModal from "../../../common/modals/AppModal";
import SubtleSelect from "../../../common/inputs/SubtleSelect";
import Heading from "../../../common/text/Heading";
import ElseSelect from "../../../common/inputs/ElseSelect";
// import SubjectSelect from "../../../common/inputs/SubjectSelect";
// import ReferenceSelect from "../../../common/inputs/ReferenceSelect";
// import ResultSelect from "../../../common/inputs/ResultSelect";
import ComparisonTypeSelect from "../../../common/inputs/ComparisonTypeSelect";
import {useAppDispatch} from "../../../app/hooks";
import useModalType from "../../HUD/hooks/useModalType";
import {setModalType} from "../../HUD/state/HUDSlice";


interface ConditionFieldParams extends ActionFieldProps{
    formState: any;
    validatingRegister: any;
    resetField: any;
    watchedConditions: any;
    attrData: any;
    i: number;
    usingStaticResultName?: string;
    resIdName?: string;
    resValName?: string;
    usingStaticRefName?: string;
    referenceIdName?: string;
    refValueName?: string;
    subjectIdName?: string;
    setValue?: any;
}

const StyledConditionalBox = styled(FlexContainer)`
	border: 4px solid ${(p) => p.theme.palette.divider};
	margin: ${(p) => p.theme.spacing(1)};
`;

const ResultSelect: FunctionComponent<ConditionFieldParams> = (
    {
        usingStaticResultName,
        formState,
        validatingRegister,
        watchedConditions,
        resIdName,
        attrData,
        resValName,
        resetField,
        i
    }
) => {
    return <>

        <Typography id={usingStaticResultName}>
            Select an attribute or provide a static
            value that will serve as the result of this
            condition if it evaluates to 'true'
        </Typography>
        <FlexContainer
            style={{width: '100%', padding: '0 16px'}}
            justifyContent="space-between"
            role="radiogroup"
            aria-labelledby={usingStaticResultName}
            aria-errormessage={genErrorIdFromLabel(
                usingStaticResultName || ''
             )}
            aria-invalid={formFieldHasErrors(
                'conditions',
                formState
            )}
        >
            <div>
                <input
                    id={`${usingStaticResultName}-false`}
                    type="radio"
                    value="false"
                    {...validatingRegister(
                        `conditions.${i}.usingStaticResult`,
                        {
                            required: {
                                value: true,
                                message:
                                    'A value for usingStaticResult is required',
                            },
                        }
                    )}
                />
                <label
                    htmlFor={`${usingStaticResultName}-false`}
                >
                    <Typography>
                        Use attribute
                    </Typography>
                </label>
            </div>
            <div>
                <input
                    id={`${usingStaticResultName}-true`}
                    type="radio"
                    value="true"
                    {...validatingRegister(
                        `conditions.${i}.usingStaticResult`,
                        {
                            required: {
                                value: true,
                                message:
                                    'A value for usingStaticResult is required',
                            },
                        }
                    )}
                />
                <label
                    htmlFor={`${usingStaticResultName}-true`}
                >
                    <Typography>
                        Use static value
                    </Typography>
                </label>
            </div>
        </FlexContainer>
        {watchedConditions[i].usingStaticResult === 'false' ? (
            <>
                <label htmlFor={resIdName}>
                    <Typography>
                        What attribute should provide
                        the result value for this
                        condition?{' '}
                    </Typography>
                </label>
                <select
                    {...validatingRegister(
                        `conditions.${i}.resultId`,
                        {
                            valueAsNumber: true,
                            validate: (
                                v: string | number
                            ) => {
                                if (
                                    typeof v ===
                                    'string'
                                ) {
                                    const maybeInt =
                                        parseInt(v, 10);

                                    return isNaN(
                                        maybeInt
                                    )
                                        ? 'Invalid value passed to otherId'
                                        : maybeInt > 0
                                            ? true
                                            : 'A result attribute ID must be selected if you are not using a static files value';
                                }

                                return v > 0
                                    ? true
                                    : 'A result attribute ID must be selected if you are not using a static files value';
                            },
                        }
                    )}
                    id={resIdName}
                    aria-errormessage={genErrorIdFromLabel(
                        resIdName || ''
                    )}
                    aria-invalid={formFieldHasErrors(
                        'conditions',
                        formState
                    )}
                >
                    {attrData.map((attr: any) => (
                        <option
                            value={attr._id}
                            key={attr._id}
                        >{`${attr.entity.plural}: ${attr.plural}`}</option>
                    ))}
                </select>
            </>
        ) : (
            <>
                <label htmlFor={resValName}>
                    <Typography>
                        What should the resulting value
                        of this comparison be if it
                        evaluates to true?
                    </Typography>
                </label>
                <input
                    type="text"
                    {...validatingRegister(
                        `conditions.${i}.resultValue`,
                        {
                            required: {
                                value: true,
                                message: `Result value is a required field if you are not using an attribute id`,
                            },
                            maxLength: {
                                value: 200,
                                message: `A maximum of 200 characters is allowed for singular`,
                            },
                        }
                    )}
                    id={resValName}
                    aria-errormessage={genErrorIdFromLabel(
                        resValName || ''
                    )}
                    aria-invalid={formFieldHasErrors(
                        'conditions',
                        formState
                    )}
                />
            </>
        )}
    </>
}
const ReferenceSelect: FunctionComponent<ConditionFieldParams> = (
    {
        usingStaticRefName,
        formState,
        validatingRegister,
        watchedConditions,
        referenceIdName,
        attrData,
        i,
        refValueName,
        resetField
    }
    ) => {
    return <>

        <Typography id={usingStaticRefName}>
            Select an attribute or provide a static
            value to serve as the object of this
            comparison
        </Typography>
        <FlexContainer
            style={{width: '100%', padding: '0 16px'}}
            justifyContent="space-between"
            role="radiogroup"
            aria-labelledby={usingStaticRefName}
            aria-errormessage={genErrorIdFromLabel(
                usingStaticRefName || ''
            )}
            aria-invalid={formFieldHasErrors(
                'conditions',
                formState
            )}
        >
            <div>
                <input
                    id={`${usingStaticRefName}-false`}
                    type="radio"
                    value="false"
                    {...validatingRegister(
                        `conditions.${i}.usingStaticReference`,
                        {
                            required: {
                                value: true,
                                message:
                                    'A value for usingStaticReference is required',
                            },
                        }
                    )}
                />
                <label
                    htmlFor={`${usingStaticRefName}-false`}
                >
                    <Typography>
                        Use attribute
                    </Typography>
                </label>
            </div>
            <div>
                <input
                    id={`${usingStaticRefName}-true`}
                    type="radio"
                    value="true"
                    {...validatingRegister(
                        `conditions.${i}.usingStaticReference`,
                        {
                            required: {
                                value: true,
                                message:
                                    'A value for usingStaticReference is required',
                            },
                        }
                    )}
                />
                <label
                    htmlFor={`${usingStaticRefName}-true`}
                >
                    <Typography>
                        Use static value
                    </Typography>
                </label>
            </div>
        </FlexContainer>
        {watchedConditions[i].usingStaticReference ===
        'false' ? (
            <>
                <label htmlFor={referenceIdName}>
                    <Typography>
                        What attribute should be the
                        object of this condition?
                    </Typography>
                </label>
                <select
                    {...validatingRegister(
                        `conditions.${i}.referenceId`,
                        {
                            valueAsNumber: true,
                            validate: (
                                v: string | number
                            ) => {
                                if (
                                    typeof v ===
                                    'string'
                                ) {
                                    const maybeInt =
                                        parseInt(v, 10);

                                    return isNaN(
                                        maybeInt
                                    )
                                        ? 'Invalid value passed to otherId'
                                        : maybeInt > 0
                                            ? true
                                            : 'A subject attribute ID must be selected if you are not using a static files value';
                                }

                                return v > 0
                                    ? true
                                    : 'A subject attribute ID must be selected if you are not using a static files value';
                            },
                        }
                    )}
                    id={referenceIdName}
                    aria-errormessage={genErrorIdFromLabel(
                        referenceIdName || ''
                    )}
                    aria-invalid={formFieldHasErrors(
                        'conditions',
                        formState
                    )}
                >
                    {attrData.map((attr: any) => (
                        <option
                            value={attr._id}
                            key={attr._id}
                        >{`${attr.entity.plural}: ${attr.plural}`}</option>
                    ))}
                </select>
            </>
        ) : (
            <>
                <label htmlFor={refValueName}>
                    <Typography>
                        What value should be the object
                        of the comparison?
                    </Typography>
                </label>
                <input
                    type="text"
                    {...validatingRegister(
                        `conditions.${i}.referenceValue`,
                        {
                            required: {
                                value: true,
                                message: `Reference value is a required field`,
                            },
                            maxLength: {
                                value: 200,
                                message: `A maximum of 200 characters is allowed for singular`,
                            },
                        }
                    )}
                    id={refValueName}
                    aria-errormessage={genErrorIdFromLabel(
                        refValueName || ''
                    )}
                    aria-invalid={formFieldHasErrors(
                        'conditions',
                        formState
                    )}
                />
            </>
        )}
    </>
}
const SubjectSelect: FunctionComponent<ConditionFieldParams> = (
    {
        formState,
        validatingRegister,
        watchedConditions,
        subjectIdName,
        attrData,
        resetField,
        i,
        setValue
    }
    ) => {
    return <>
        <SubtleSelect
            id={subjectIdName}
            label="What attribute should be the subject of this condition?"
            defaultValue={'Select attribute'}
            onReset={() => resetField(`conditions.${i}.subjectId`)}
            isDirty={Boolean(formState.dirtyFields.conditions)}
            {...validatingRegister(`conditions.${i}.subjectId`, {
                onChange: () => {
                    setValue(
                        `conditions.${i}.comparisonType`,
                        'none'
                    );
                },
                valueAsNumber: true,
                validate: (v: string | number) => {
                    if (typeof v === 'string') {
                        const maybeInt = parseInt(
                            v,
                            10
                        );

                        return isNaN(maybeInt)
                            ? 'Invalid value passed to otherId'
                            : maybeInt > 0
                                ? true
                                : 'A subject attribute ID must be selected if you are not using a static files value';
                    }

                    return v > 0
                        ? true
                        : 'A subject attribute ID must be selected if you are not using a static files value';
                }
            })}
        >
            <option value={'default'} key={'default'}>
                Select attribute
            </option>
            {attrData.map((attr: any) => (
                <option
                    value={attr._id}
                    key={attr._id}
                >{`${attr.entity.plural}: ${attr.plural}`}</option>
            ))}
        </SubtleSelect>
    </>
}

interface ConditionInputParams extends ConditionFieldParams {
    label: string;
    subjectAttrType: AttributeBaseType |  null;
    onClick: () => void;
    compTypeName: string;
}

const ConditionInput: FunctionComponent<ConditionInputParams> = (
    {
        label,
        onClick,
        subjectAttrType,
        formState,
        resetField,
        mutationResults,
        usingStaticResultName,
        validatingRegister,
        watchedConditions,
        compTypeName,
        resIdName,
        attrData,
        resValName,
        subjectIdName,
        referenceIdName,
        refValueName,
        usingStaticRefName,
        setValue,
        i
    }
) => {
    return <StyledConditionalBox
        flexDirection="column"
        alignItems="flex-start"
        style={{gap: '16px', padding: '8px'}}
    >
        <FlexContainer
            justifyContent="space-between"
            style={{width: '100%'}}
        >
            <Heading style={{padding: 0, margin: 0}}
                     component="h3">
                {label}
            </Heading>
            <IconButton
                size="sm"
                icon={faTimes}
                onClick={onClick}
            />
        </FlexContainer>
        <SubjectSelect
            i={i}
            formState={formState}
            validatingRegister={validatingRegister}
            watchedConditions={watchedConditions}
            subjectIdName={subjectIdName}
            attrData={attrData}
            resetField={resetField}
            setValue={setValue}
        />
        {subjectAttrType
            ? <ComparisonTypeSelect
                i={i}
                compTypeName={compTypeName}
                formState={formState}
                resetField={resetField}
                validatingRegister={validatingRegister}
                attributeType={subjectAttrType}
            />
            : <Typography color="warn">
                Select a Subject Id
            </Typography>
        }
        <ReferenceSelect
            i={i}
            formState={formState}
            usingStaticRefName={usingStaticRefName}
            validatingRegister={validatingRegister}
            watchedConditions={watchedConditions}
            referenceIdName={referenceIdName}
            attrData={attrData}
            resetField={resetField}
            refValueName={refValueName}
        />
        <ResultSelect
            i={i}
            formState={formState}
            usingStaticResultName={usingStaticResultName}
            validatingRegister={validatingRegister}
            watchedConditions={watchedConditions}
            resIdName={resIdName}
            resetField={resetField}
            attrData={attrData}
            resValName={resValName}
        />
        <FormResults
            validationErrors={
                formState.errors?.conditions
                    ? formState.errors.conditions[i]
                    : undefined
            }
        />
    </StyledConditionalBox>;
}

const CreateConditionalForm: FunctionComponent = () => {
    const {modalProps, closeModal} = useModalType();
    const {objectId: _id, canEdit} = modalProps;

    const formDefaults = createConditionalFormDefaults();

    const {
        handleSubmit,
        register,
        resetField,
        reset,
        formState,
        control,
        watch,
        setValue
    } =
        useForm<CreateConditionalFormValues>({
            defaultValues: formDefaults,
        });

    const {fields, append, remove} =
        useFieldArray<CreateConditionalFormValues>({
            name: 'conditions',
            control,
        });

    const [watchedConditions, watchedStaticElse] = watch([
        'conditions',
        'usingStaticElseValue',
    ]);

    const validatingRegister = useCallback(
        (inputName: keyof CreateConditionalFormValues, options?: RegisterOptions) => {
            if (options) {
                return register(inputName, options)
            } else {
                return register(inputName, {
                    required: `${inputName} is required`,
                })
            }
        }, [register]
    );

    const addCondition = (e: any) => {
        e.preventDefault();
        append(conditionFormDefaults());
    }
    const removeCondition = (index: number) => remove(index);

    const queryRes = useGetAttributesQuery({entityId: _id});

    //  TODO: this should really be memoized into a single call whenever
    // a subjectId changes, but useForm's 'watch' doesn't play
    // nice with useMemo.
    const getAttrType = (index: number) => {
        if (!queryRes.data) {
            return null;
        }

        const maybeAttr = queryRes.data.find(
            (attr) => attr._id === watchedConditions[index].subjectId
        );

        if (maybeAttr) {
            return maybeAttr.type;
        }

        return null;
    };

    //  TODO: maybe validate on submit rather than closing the form if there are no conditions.
    // useEffect(() => {
    //     if (watchedConditions.length === 0) {
    //         setOpenAction(null);
    //     }
    // }, [watchedConditions, setOpenAction]);

    const [createConditional, mutationResults] =
        useCreateConditionalMutation();

    const onSubmit: SubmitHandler<CreateConditionalFormValues> = (vals, e) => {
        e?.preventDefault();
        createConditional({
            entityId: _id,
            body: createConditionalFormToPayload(vals),
        });
    };

    if (queryRes.isLoading) {
        return (
            <FlexContainer justifyContent="center">
                <Typography paragraph variant='h5'>Loading
                    attributes...</Typography>
                <Spinner/>
            </FlexContainer>
        );
    }

    if (queryRes.isError) {
        return (
            <FlexContainer justifyContent="center">
                <Typography color="error" paragraph>
                    {extractQueryErrMessage(queryRes.error)}
                </Typography>
            </FlexContainer>
        );
    }

    const attrData = queryRes.data as GetEntityAttrsResponse;

    return (
        <AppModal
            label={"Create Conditional"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit ? canEdit : true}>
            {renderDerivationSubfields({
                isDerivation: true,
                formState,
                resetField,
                validatingRegister,
                mutationResults
            })}
            <Heading style={{padding: 0, margin: 0}} component="h3">
                Conditions
            </Heading>
            {fields.map((fieldData, i) => {
                const baseFieldName = `conditions[${i}]`;

                const constructFieldName = (
                    tail: keyof ConditionFormValues
                ) => baseFieldName + tail;

                const subjectIdName = constructFieldName('subjectId');
                const referenceIdName = constructFieldName('referenceId');
                const refValueName = constructFieldName('referenceValue');
                const compTypeName = constructFieldName('comparisonType');
                const resIdName = constructFieldName('resultId');
                const resValName = constructFieldName('resultValue');
                const usingStaticRefName = constructFieldName(
                    'usingStaticReference'
                );
                const usingStaticResultName =
                    constructFieldName('usingStaticResult');

                const subjectAttrType = getAttrType(i);

                return <ConditionInput
                    key={fieldData.id}
                    subjectAttrType={subjectAttrType}
                    formState={formState}
                    resetField={resetField}
                    i={i}
                    mutationResults={mutationResults}
                    onClick={() => removeCondition(i)}
                    label={`Condition (${i + 1})`}
                    setValue={setValue}
                    usingStaticResultName={usingStaticResultName}
                    usingStaticRefName={usingStaticRefName}
                    validatingRegister={validatingRegister}
                    watchedConditions={watchedConditions}
                    compTypeName={compTypeName}
                    referenceIdName={referenceIdName}
                    subjectIdName={subjectIdName}
                    resIdName={resIdName}
                    attrData={attrData}
                    resValName={resValName}
                    refValueName={refValueName}
                />;
            })}
            <Button type="button" onClick={addCondition}>
                Add Condition
            </Button>
            <ElseSelect
                formState={formState}
                attrData={attrData}
                validatingRegister={validatingRegister}
                watchedStaticElse={watchedStaticElse === 'true'}
            />
            <FormResults
                {...mutationResults}
                validationErrors={{}}
            />
        </AppModal>
    );
};

export default CreateConditionalForm;
