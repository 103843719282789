import styled from "styled-components";

interface StyledSquareProps {
	width?: string;
	height?: string;
}

export const StyledSquare = styled.div<StyledSquareProps>`
	width: ${(p) => p.width ? p.width : p.theme.spacing(4)};
	height: ${(p) => p.height ? p.height : p.theme.spacing(4)};
	background-color: ${(p) => p.theme.palette.primary.main};
`;

export const StyledGraphicSquare = styled.div`
	background: ${(p) => p.theme.palette.primary.main};
	width: 100%;
    height: 100%;
`;

export default StyledSquare;
