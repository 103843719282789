import RouterLink from '../RouterLink';
import Typography from './Typography';
import {ListItem} from 'common/List';
import {ATTRIBUTE} from 'features/compositeViews/EntityViews/CONSTANTS';
import {VIEW_MODE_SEARCH_PARAM} from 'features/navigation/CONSTANTS';
import {FunctionComponent} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import styled, {CSSProperties} from 'styled-components';
import useViewModeParams
    from "../../features/navigation/hooks/useViewModeParams";
import AttributeMenu from "../menus/AttributeMenu";
import {BaseAttribute} from "../../features/ontology/types/attributeTypes";
import FlexContainer from "../FlexContainer";

const formatAttrName = (s: string) => s.split('-').join(' ');

const StyledLink = styled(RouterLink)`
	&:hover {
		text-decoration: underline;
		color: ${(p) => p.theme.palette.primary.main};
	}
`;

interface BreakoutTextProps {
    attrName: string;
    attrValue: string;
    breakWidth?: number;
    manualPreventScrollbarOverflow?: boolean;
    attribute?: BaseAttribute;
    overrides?: {
        //   'root' = ListItem
        root?: CSSProperties;
    };
}

// NB: basically tested by src/features/geospatial/__tests__/Popup.unit.test.tsx
const BreakoutText: FunctionComponent<BreakoutTextProps> = (
    {
        attribute,
        attrName,
        attrValue,
        overrides,
        manualPreventScrollbarOverflow = false,
    }
) => {
    const {username, domain, entity, individual} = useParams();
    const location = useLocation();
    const {getViewMode} = useViewModeParams();
    const mode = getViewMode();

    // TODO: weird case here where we aren't using the EntitySearchParams/ViewModeSearchParams hooks
    const linkParams = new URLSearchParams();
    linkParams.set(ATTRIBUTE, attrName);

    const linkTo = mode === 'profile'
        ? !!individual
            ? location.pathname.includes('profile')
                ? `${location.pathname.split('/').slice(0, -2).join('/')}?${linkParams.toString()}`
                : `${location.pathname.split('/').slice(0, -1).join('/')}?${linkParams.toString()}`
            : `${location.pathname}?${linkParams.toString()}`
        : location.pathname.includes('profile')
            ? `${location.pathname.split('/').slice(0, -2).join('/')}?${linkParams.toString()}`
            : `${location.pathname.split('/').slice(0, -1).join('/')}?${linkParams.toString()}`;

    return (
        <FlexContainer justifyContent={'space-between'} alignItems={'center'}>
            <ListItem
                style={{
                    justifyContent: 'space-between',
                    width: '300px',
                    flexWrap: 'wrap',
                    marginLeft: manualPreventScrollbarOverflow ? '0px' : 0,
                    marginRight: manualPreventScrollbarOverflow ? '0px' : 0,
                    ...overrides?.root,
                }}
            >
                <StyledLink to={linkTo} preserveSearchParams={false}>
                    <Typography
                        variant={'h6'}
                        color={'primary'}
                        style={{
                            margin: '8px 8px 4px 0',
                            textTransform: 'capitalize'
                        }}
                    >
                        {formatAttrName(attrName)}
                    </Typography>
                </StyledLink>
                {!!attribute && attribute.type === 'quantity'
                    ? <Typography
                        paragraph
                        variant={'h6'}
                        color={'oldManGray'}
                        style={{
                            padding: '8px 8px 4px 0',
                            overflowWrap: 'break-word',
                        }}
                    >
                        {attrValue}{!!attribute && !!attribute?.profile && !!attribute?.profile.results && attribute.type === 'quantity'
                        ? ` (${Math.round((Number(attrValue) - Number(attribute?.profile.results.mean)) / Number(attribute?.profile.results.mean) * 100)}%)` : ''}
                    </Typography>
                : !!attribute && attribute.type === 'relation'
                        ? <Typography
                            paragraph
                            variant={'h6'}
                            color={'oldManGray'}
                            style={{
                                padding: '8px 8px 4px 0',
                                overflowWrap: 'break-word',
                            }}
                        >
                            <StyledLink to={''} preserveSearchParams={false}>
                                {attrValue}
                            </StyledLink>
                        </Typography>
                        : <Typography
                            paragraph
                            variant={'h6'}
                            color={'oldManGray'}
                            style={{
                                padding: '8px 8px 4px 0',
                                overflowWrap: 'break-word',
                            }}
                        >
                            {attrValue}
                        </Typography>}
            </ListItem>
            {!!attribute && <AttributeMenu
                attribute={attribute}
                canEdit={true}
            />}
        </FlexContainer>
    );
};

export default BreakoutText;
