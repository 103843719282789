import HUDModal from './components/HUDModal';
import IndividualDrawer from './components/IndividualDrawer';
import React, { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import ControlBar from "./components/ControlBar";
import LeftDrawer from "./components/LeftDrawer";

const HUD: FunctionComponent = () => {
	return (
		<>
			<Outlet />
			<LeftDrawer/>
			{/*<SourcePanelButton />*/}
			<HUDModal />
			<ControlBar />
			<IndividualDrawer />
		</>
	);
};

export default HUD;
