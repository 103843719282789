import React, {FunctionComponent} from "react";
import MenuButton from "../buttons/MenuButton";
import MenuPanel from "./MenuPanel";
import AddMemberButton from "../buttons/AddMemberButton";
import SubMenuButton from "../buttons/SubMenuButton";
import TextButton from "../buttons/TextButton";
import DestroyButton from "../buttons/DestroyButton";
import {
    AUDIO_CALL,
    VIDEO_CALL,
    SEE_FRIENDSHIP,
    SUPPORT_REPORT,
    BLOCK_USER,
    VIEW_AS,
    ACCOUNT_STATUS,
    ARCHIVE,
    ACTIVITY_LOG,
    PROFILE_SETTINGS, FOLLOW_RESOURCE,
} from "../../features/graph/CONSTANTS";
import SettingsButton from "../buttons/SettingsButton";
import {BaseUser} from "../../features/authentication/types/userTypes";
import UserSettingsModal from "../modals/UserSettingsModal";

const userActions = [
    {
        actionTitle: FOLLOW_RESOURCE,
        requiresEdit: false,
        kind: 'action',
        callback: 'follow',
	},
    {
        actionTitle: AUDIO_CALL,
        modalType: 'audioCall',
        divider: false,
        requiresEdit: false,
        kind: "modal",
        form: SettingsButton,
    },
    {
        actionTitle: VIDEO_CALL,
        modalType: 'videoCall',
        divider: true,
        requiresEdit: false,
        kind: "modal",
        form: AddMemberButton,
    },
    {
        actionTitle: SEE_FRIENDSHIP,
        modalType: 'friendship',
        divider: true,
        requiresEdit: false,
        kind: "modal",
        form: AddMemberButton,
    },
    {
        actionTitle: SUPPORT_REPORT,
        modalType: 'support',
        kind: "modal",
        requiresEdit: false,
        form: DestroyButton,
    },
    {
        actionTitle: BLOCK_USER,
        modalType: 'block',
        kind: "modal",
        requiresEdit: false,
        form: DestroyButton,
    },
]

const selfActions = [
    {
        actionTitle: VIEW_AS,
        modalType: 'viewAs',
        divider: false,
        requiresEdit: true,
        kind: "modal",
        form: UserSettingsModal,
    },
    {
        actionTitle: ACCOUNT_STATUS,
        modalType: 'accountStatus',
        divider: true,
        kind: "modal",
        requiresEdit: true,
        form: AddMemberButton,
    },
    {
        actionTitle: ARCHIVE,
        modalType: 'archive',
        requiresEdit: true,
        divider: true,
        kind: "modal",
        form: AddMemberButton,
    },
    {
        actionTitle: ACTIVITY_LOG,
        modalType: 'activity',
        requiresEdit: true,
        kind: "modal",
        form: DestroyButton,
    },
    {
        actionTitle: PROFILE_SETTINGS,
        modalType: 'userSettings',
        requiresEdit: true,
        kind: "modal",
        form: UserSettingsModal,
    },
]

export const ShareButton: FunctionComponent = () => {
    return <SubMenuButton label={'Share'}>
            <TextButton color='primary' fillColor='transparent' text={'Copy Object Link'}/>
        </SubMenuButton>
}

export const UserMenu: FunctionComponent<{ user: BaseUser; canEdit: boolean;}> = (
    {user, canEdit}
) => {

    if (user) {
        return <MenuButton placement={'right-start'}>
            {canEdit
                ? <MenuPanel
                    resource={user}
                    canEdit={canEdit}
                    actions={selfActions}
                />
                : <MenuPanel
                    resource={user}
                    canEdit={canEdit}
                    actions={userActions}
                />}
                <ShareButton />
        </MenuButton>;
    } else {
        return null
    }

};

export default UserMenu;
