import {
	DataCatalog,
	DataSet,
	DataSourceSummaries,
	GetLiveDataCatalogsResponse,
	GetLiveDataSetsResponse, LiveDataCatalog, LiveDataCatalogs, LiveDataSet,
	LiveDataSets
} from '../../types/dataTypes';
import {SelectedSourceData, SourceNavItemList, SourceNavItemData} from '../../types/uiTypes';
import ContextualDisplay, {
	BrowserDisplay,
	DataDisplay
} from '../ContextualDisplay';
import DataSetNav from './DataSetNav';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
	useGetLiveDataSetsQuery,
	useGetSourceSummariesQuery
} from 'features/api';
import { parseQueryError } from 'features/api/helpers';
import { canEdit as canEditOrg } from 'features/authentication/helpers';
import useActiveCatalogData from 'features/ontology/hooks/useActiveCatalogData';
import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from "common/modals/Modal";
import Typography from "common/text/Typography";
import {SourceBody} from "../../../HUD/components/HUDModal/styledComponents";
import ReactModal from "react-modal";
import {ModalType} from "../../../HUD/types/HUDTypes";
import {DataNav, DataModalNav} from "../../../navigation/components/Navbar";
import HUD from "../../../HUD";
import {ViewMode} from "../../../compositeViews/types";
import SourceNav, {BrowserNav} from "../SourceBrowser/SourceNav";
import useActiveOrgData from "../../../ontology/hooks/useActiveOrgData";
import useActiveSourceData from "../../../ontology/hooks/useActiveSourceData";
import useActiveCatalogDatasets
	from "../../../ontology/hooks/useActiveCatalogDatasets";
import CatalogNav from "../CatalogBrowser/CatalogNav";
import useActiveDatasetData from "../../../ontology/hooks/useActiveDatasetData";
import {useParams} from "react-router-dom";
import CreateConnectionModal from "../../../../common/modals/CreateConnectionModal";
// import {RenderViewConfig} from "../../navigation/hooks/useRenderView";


const StyledMain = styled.div<{grid?: string;}>`
	display: grid;
	grid-template-columns: ${(p) => p.grid ? p.grid : '1fr 1fr 1fr 2fr 1fr'};
	grid-template-rows: 1fr;
	flex: 1;
	overflow: auto;
	position: absolute;
	width: calc(100vw - ${(p) => `${p.theme.sideControllerWidth + p.theme.panelGutter * 3}`}px);
	height: calc(100vh - ${(p) => p.theme.navHeight * 2 + p.theme.panelGutter * 2}px);
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter}px;
	color: ${(p) => p.theme.palette.text.paper};
`;

const StyledModal = styled.div`
	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-template-rows: 1fr;
	flex: 1;
	overflow: auto;
	background: ${(p) => p.theme.palette.background.paper};
	color: ${(p) => p.theme.palette.text.paper};
`;

const StyledPanel = styled.div`
	display: grid;
	flex: 1;
	overflow: auto;
	background: ${(p) => p.theme.palette.background.paper};
	color: ${(p) => p.theme.palette.text.paper};
`;

interface DatasetBrowserBaseProps {
	setOnDisplay: any;
	onDisplay: any;
	displayMode: any;
	setDisplayMode: any;
}

interface DatasetBrowserModalProps extends ReactModal.Props {
	handleClose: () => void;
	modalType: ModalType;
	modalWidth?: number;
	modalHeight?: number;
	top?: string;
	borderRadius?: string;
}

const StyledTypography = styled(Typography)`
	display: inline;
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
`;

// const DatasetBrowserBase: FunctionComponent<DatasetBrowserBaseProps> = ({displayMode, setDisplayMode, onDisplay, setOnDisplay}) => {
//
// 	const [cardData, setCardData] = useState<SourceNavItemList | null>(null);
//
//
//
// 	return (<>
// 			<StyledPanel>
// 				<DataSetNav
// 					sourceSummaries={data as LiveDataSets}
// 					// setter={(toSelect: SelectedSourceData) =>
// 					// 	setOnDisplay(toSelect)
// 					// }
// 					// currentlySelected={onDisplay}
// 				/>
// 			</StyledPanel>
// 			<StyledPanel>
//
// // 			</StyledPanel>
// 		</>
// 	);
// }

//DatasetBrowserProps
const DatasetBrowser: FunctionComponent = () => {
	const {activeItem: activeOrg} = useActiveOrgData();
	const {activeItem: activeSource} = useActiveSourceData();
	const {activeItem: activeCatalog } = useActiveCatalogData();

	const params = useParams();
	const {
		// isError,
		// isLoading,
		data: sources,
		// isFetching,
		// error,
		// isSuccess,
		// isUninitialized,
	} = useGetSourceSummariesQuery(
		activeOrg ? { orgId: activeOrg._id } : skipToken
	);

	const {
		catalogs,
		datasets,
		isSuccess: isCatalogSuccess,
		isLoading: isCatalogLoading,
		isFetching: isCatalogFetching,
		isUninitialized: isCatalogUninitialized,
		isError: isCatalogError,
		error: catalogError,
		// ...loadState
	} = useActiveCatalogDatasets();

	const canEdit = !!activeOrg && activeOrg.userContext.permissions.edit;

	const [cardData, setCardData] = useState<LiveDataSets>([] as LiveDataSets)
	const [sourceData, setSourceData] = useState<SourceNavItemList | null>(null)
	const [catalogData, setCatalogData] = useState<LiveDataCatalogs | null>(null)
	const [datasetData, setDatasetData] = useState<LiveDataSets | null>(null)
	const [activeDataset, setActiveDataset] = useState<LiveDataSet | null>(null)
	const [displayMode, setDisplayMode] = useState<ViewMode |'browser'>('browser')
	const [onDisplay, setOnDisplay] = useState<SelectedSourceData>([]);

		const {
		activeItem,
		isSuccess,
		isLoading,
		isFetching,
		isUninitialized,
		isError,
		error,
		...loadState
	} = useActiveDatasetData();

	useEffect(() => {
		if (isSuccess && !!activeItem) {
			// setOnDisplay(datasets);
			setCardData(datasets as LiveDataSets);
			// const dataset = datasets.find((d) => d.name === params.dataset);
			setActiveDataset(activeItem as LiveDataSet)
		}
	}, [activeItem, isSuccess]);
	// TODO: this causes flicker on cache invalidation.  Pass these props down
	// so children can render a spinner or something instead.
	if (isLoading || isFetching || isUninitialized) {
		return <SourceBody borderRadius={'0px'}>
			<Typography variant={'h2'} color={'oldManGray'}>
				Loading...
			</Typography>
		</SourceBody>;
	}

	if (isError && error) {
		const { message } = parseQueryError(error);

		return <SourceBody borderRadius={'0px'}>
			<Typography variant={'h2'} color={'oldManGray'}>
				{message}
			</Typography>
		</SourceBody>;
	}
	return <>
		<SourceBody borderRadius={'0px'}>
			<StyledMain grid='1fr 1fr 1fr 6fr'>
				<StyledPanel>
					<BrowserNav
						sources={sources as DataSourceSummaries}
						setter={(toSelect: SelectedSourceData) => setOnDisplay(toSelect)}
						currentlySelected={onDisplay}
					/>
				</StyledPanel>
				<StyledPanel>
					<SourceNav
						catalogs={catalogs as LiveDataCatalogs}
						setter={(toSelect: SelectedSourceData) => setOnDisplay(toSelect)}
						currentlySelected={onDisplay}
						sourceId={!!activeSource ? activeSource._id : skipToken}
					/>
				</StyledPanel>
				<StyledPanel>
					<CatalogNav
						datasets={datasets as LiveDataSets}
					/>
				</StyledPanel>
				<StyledPanel>
					{!!activeDataset && <DataDisplay dataset={activeDataset}/>}
							{/*<ContextualDisplay*/}
							{/*	displayMode={displayMode}*/}
							{/*	closeForm={() => setDisplayMode('data')}*/}
							{/*	activeItem={activeDataset}*/}
							{/*	setOnDisplay={setOnDisplay}*/}
							{/*	onDisplay={onDisplay}*/}
							{/*	cardData={cardData}*/}
							{/*/>*/}
				</StyledPanel>
				<StyledPanel/>
			</StyledMain>
		</SourceBody>
		</>;
}


export const DatasetBrowserModal: FunctionComponent<DatasetBrowserModalProps> = ({
		modalType,
		handleClose,
		modalWidth,
		modalHeight,
		top,
		borderRadius,
	}) => {
	const [onDisplay, setOnDisplay] = useState<SelectedSourceData>([]);
	const [displayMode, setDisplayMode] =
		useState<ViewMode>('data');
	return <Modal
			isOpen={!!modalType}
			modalType={modalType}
			shouldCloseOnOverlayClick={true}
			onRequestClose={handleClose}
			contentLabel={'Data Browser'}
			modalWidth={modalWidth}
			modalHeight={modalHeight}
			top={top}
			borderRadius={borderRadius}
		>
		<DataModalNav
			width={modalWidth}
			handleClose={handleClose}
			handleDisplay={setDisplayMode}
		/>
		<StyledModal>
			{/*<DatasetBrowserBase*/}
			{/*	onDisplay={onDisplay}*/}
			{/*	setOnDisplay={setOnDisplay}*/}
			{/*	displayMode={displayMode}*/}
			{/*	setDisplayMode={setDisplayMode}*/}
			{/*/>*/}
		</StyledModal>
		</Modal>;
}

export default DatasetBrowser;
