import Typography, {ColorProp} from "../text/Typography";
import React, {FunctionComponent} from "react";
import {useNavigate} from "react-router-dom";

const LogInLinkButton: FunctionComponent<{color?: ColorProp; display?: boolean}> = ({display=true, color}) => {
    const navigate = useNavigate();

    return <Typography
        color={color || 'primary'}
        // alignContent={'right'}
        variant={'h5'}
        style={{padding: '1rem 1.5rem'}}
        onClick={display ? () => navigate('/login') : () => {}}
    >
       <span className='unselectable' style={{cursor: display ? 'pointer' : 'default'}}>LOGIN</span>
    </Typography>
};

export default LogInLinkButton;
