import FlexContainer from '../../../common/FlexContainer';
import {FormResults} from '../../../common/Form';
import Spinner from '../../../common/loading/Spinner';
import Typography from '../../../common/text/Typography';
import { useCreateLocationMutation, useGetAttributesQuery } from '../../api';
import { extractQueryErrMessage } from '../../api/helpers';
import { GetEntityAttrsResponse } from '../../ontology/types/attributeTypes';
import { EntityActionFormProps } from '../common/commonTypes';
import {
	renderDerivationSubfields,
	renderReferenceField
} from '../common/jsxHelpers';
import {
	createLocationFormDefaults,
	createLocationFormToPayload,
} from './createLocationHelpers';
import { CreateLocationFormValues } from './createLocationTypes';
import React, {FunctionComponent, useCallback, useMemo} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import Heading from "../../../common/text/Heading";
import useModalType from "../../HUD/hooks/useModalType";
import {setModalType} from "../../HUD/state/HUDSlice";
import {useAppDispatch} from "../../../app/hooks";
import AttributeSelect from "common/inputs/SelectAttribute";
import Loading from "../../../common/loading/Loading";

const CreateMetricModal: FunctionComponent = () => {

	const {modalProps, closeModal} = useModalType();
	const {objectId: _id, canEdit} = modalProps;
	const formDefaults = createLocationFormDefaults();

	const { handleSubmit, register, resetField, reset, formState, watch } =
		useForm<CreateLocationFormValues>({
			defaultValues: formDefaults,
		});

	const watchedLatitudeId = watch('latitudeId');

	const [createLocation, mutationResults] = useCreateLocationMutation();

	const validatingRegister = useCallback(
		(inputName: keyof CreateLocationFormValues, options?: any) =>
			register(inputName, options ? options : {
				required: `${inputName} is required`,
			}),
		[register]
	);

	const onSubmit: SubmitHandler<CreateLocationFormValues> = (vals) => {
		createLocation({
			entityId: _id,
			body: createLocationFormToPayload(vals),
		});
	};

	const {
		isLoading,
		isError,
		error,
		data: attrData,
		isUninitialized,
	} = useGetAttributesQuery({ entityId: _id });

	//  lat and long can't be the same attribute
	const longitudeCandidates = useMemo(() => {
		if (attrData) {
			return attrData.filter((attr) => attr._id !== watchedLatitudeId);
		}

		return [];
	}, [watchedLatitudeId, attrData]);

	// if (isLoading || isUninitialized) {
	// 	return (
	// 		<FlexContainer justifyContent="center">
	// 			<Typography paragraph variant='h5'>Loading attributes...</Typography>
	// 			<Spinner />
	// 		</FlexContainer>
	// 	);
	// }

	if (isError) {
		return (
			<FlexContainer justifyContent="center">
				<Typography color="error" paragraph>
					{extractQueryErrMessage(error)}
				</Typography>
			</FlexContainer>
		);
	}

	return (
		<AppModal
			label={"Create Location"}
			isOpen={true}
			isDirty={formState.isDirty}
			onClose={closeModal}
			onSubmit={handleSubmit(onSubmit)}
			canEdit={canEdit}>
			{renderDerivationSubfields({
				isDerivation: true,
				formState,
				resetField,
				validatingRegister,
				mutationResults
			})}
			<Heading style={{padding: 0, margin: 0}} component="h3">
				Components
			</Heading>
			{!isLoading && !isUninitialized
                ? <>
				<AttributeSelect selectId='continentId' props={{
				validatingRegister,
				formState,
				resetField,
				mutationResults,
				attrData,
			}} />
			<AttributeSelect selectId='subcontinentId' props={{
				validatingRegister,
				formState,
				resetField,
				mutationResults,
				attrData,
			}} />
			<AttributeSelect selectId='countryId' props={{
				validatingRegister,
				formState,
				resetField,
				mutationResults,
				attrData,
			}} />
			<AttributeSelect selectId='countrySubdivision1Id' props={{
				label: 'Country Subdivision 1',
				validatingRegister,
				formState,
				resetField,
				mutationResults,
				attrData,
			}} />
			<AttributeSelect selectId='countrySubdivision2Id' props={{
				label: 'Country Subdivision 2',
				validatingRegister,
				formState,
				resetField,
				mutationResults,
				attrData,
			}} />
			<AttributeSelect selectId='designatedMarketAreaId' props={{
				label: 'Designated Market Area',
				validatingRegister,
				formState,
				resetField,
				mutationResults,
				attrData,
			}} />
			<AttributeSelect selectId='cityId' props={{
				validatingRegister,
				formState,
				resetField,
				mutationResults,
				attrData,
			}} />
			<AttributeSelect selectId='postalCodeId' props={{
				label: 'Postal Code',
				validatingRegister,
				formState,
				resetField,
				mutationResults,
				attrData,
			}} />
			<AttributeSelect selectId='addressId' props={{
				validatingRegister,
				formState,
				resetField,
				mutationResults,
				attrData,
			}} />
			<AttributeSelect selectId='latitudeId' props={{
				validatingRegister,
				formState,
				resetField,
				mutationResults,
				attrData,
			}} />
			<AttributeSelect selectId='longitudeId' props={{
				validatingRegister,
				formState,
				resetField,
				mutationResults,
				attrData,
			}} />
			</> : <Loading />
			}
			<FormResults
				isError={mutationResults.isError}
				error={mutationResults.error}
				validationErrors={formState.errors}
			/>
		</AppModal>
	);
};

export default CreateMetricModal;
