import { ListItem, ListItemContent } from 'common/List';
import SearchBar from 'common/inputs/SearchBar';
import Typography from 'common/text/Typography';
import { toStringIfNumber } from 'common/utils/typeUtils';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import useActiveIndividualsMeta from 'features/ontology/hooks/useActiveIndividualsMeta';
import {
	isIdentity,
	isPrimaryIdentity,
} from 'features/ontology/typeGuards/attributeGuards';
import {
	FunctionComponent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'throttle-debounce';

const IndividualSearchBar: FunctionComponent = () => {
	const { getViewMode, setIdentityFilter, getIdentityFilter } =
		useEntitySearchParams();

	const { entity } = useParams<{ entity: string }>();

	// const shouldDisplay =
	// 	getViewMode() === 'spatial' && entity !== undefined;
	const shouldDisplay = true;

	const [searchTerm, setSearchTerm] = useState('');
	const [inputValue, setInputValue] = useState('');

	const { preparedData } = useActiveIndividualsMeta();

	const { primaryId, results } = useMemo(() => {
		if (preparedData && searchTerm.length > 0) {
			const ids = preparedData.attributes
				.filter(isIdentity)
				.map((a) => a.name);

			const primaryId = preparedData.attributes.find(isPrimaryIdentity)!;

			const results = preparedData.data
				.filter((ind) =>
					ids.some((idName) =>
						new RegExp(searchTerm, 'i').test(
							toStringIfNumber(ind[idName])
						)
					)
				)
				.map((ind) => (
					<ListItem
						button
						onClick={() =>
							setIdentityFilter(primaryId.name, [
								toStringIfNumber(ind[primaryId.name]),
							])
						}
						key={ind[primaryId.name]}
					>
						<ListItemContent>
							<Typography>{ind[primaryId.name]}</Typography>
						</ListItemContent>
					</ListItem>
				));

			return { results, primaryId };
		}

		return { results: [], primaryId: null };
	}, [preparedData, searchTerm, setIdentityFilter]);

	const handleTermChange = useCallback(
		debounce(500, (searchTerm: string) => setSearchTerm(searchTerm)),
		[]
	);

	//  When ID filter changes, either because a new one was submitted OR it was cleared,
	// reset text input.
	const idFilterValue = primaryId
		? getIdentityFilter(primaryId.name)?.searchParamValue
		: null;

	useEffect(() => {
		setInputValue('');
	}, [idFilterValue]);

	if (shouldDisplay) {
		return (
			<SearchBar
				value={inputValue}
				label="search individuals"
				onChange={(nextVal) => {
					setInputValue(nextVal);
					handleTermChange(nextVal);
				}}
				results={results}
			/>
		);
	}

	return null;
};

export default IndividualSearchBar;
