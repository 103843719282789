import {
    OrgAuthorityLevel,
    ContextLevel,
    DomainAuthorityLevel,
} from '../types/GovernanceTypes';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {
    useGetOrgAdminsQuery,
    useGetOrgGovernorsQuery,
    useGetOrgMembersQuery,
    useGetOrgEngineersQuery,
    useGetOrgPublishersQuery,
    useGetOrgMaintainersQuery,
    useGetOrgFundersQuery,
    useGetOrgRightsHoldersQuery,
    useGetOrgReportersQuery,
    useGetOrgContactsQuery,
    useGetDomainMembersQuery,
    useGetDomainStewardsQuery,
} from 'features/api';

const selectDomainQueryHook = (authorityLevel: DomainAuthorityLevel) =>
    authorityLevel === 'steward'
        ? useGetDomainStewardsQuery
        : useGetDomainMembersQuery;

const selectOrgQueryHook = (authorityLevel: OrgAuthorityLevel) => {
    switch (authorityLevel) {
        case 'admin':
            return useGetOrgAdminsQuery;
        case 'governor':
            return useGetOrgGovernorsQuery;
        case 'member':
            return useGetOrgMembersQuery;
        case 'engineer':
            return useGetOrgEngineersQuery;
        case 'publisher':
            return useGetOrgPublishersQuery;
        case 'maintainer':
            return useGetOrgMaintainersQuery;
        case 'funder':
            return useGetOrgFundersQuery;
        case 'rightsHolder':
            return useGetOrgRightsHoldersQuery;
        case 'reporter':
            return useGetOrgReportersQuery;
        case 'contact':
            return useGetOrgContactsQuery;
    }
};

const useMemberQueryHook = (
    contextLevel: ContextLevel,
    authorityLevel: DomainAuthorityLevel | OrgAuthorityLevel,
    objectId?: number
) => {
    if (contextLevel === 'username') {
        return selectOrgQueryHook(authorityLevel as OrgAuthorityLevel)(
            objectId
                ? {orgId: objectId}
                : skipToken
        );
    }

    return selectDomainQueryHook(authorityLevel as DomainAuthorityLevel)(
        objectId
            ? {domainId: objectId,}
            : skipToken
    );
};

export default useMemberQueryHook;
