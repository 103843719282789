import FlexContainer from '../../common/FlexContainer';
import Spinner from '../../common/loading/Spinner';
import Typography from '../../common/text/Typography';
import { extractQueryErrMessage, QueryStateTracker } from './helpers';
import { FunctionComponent, ReactNode } from 'react';
import {List} from "../../common/List";
import SelectBoxItem from "../../common/inputs/SelectBox/SelectBoxItem";

export interface DisplayOnLoadProps extends QueryStateTracker {
	toggleOnRefetch?: boolean;
	toggleOnUninitialized?: boolean;
	spinnerDiameter?: number;
	children?: ReactNode;
}

export interface DisplayOnStatusProps {
	status: string;
	errorMessage?: string;
	children?: ReactNode;
}

export const DisplayOnStatus: FunctionComponent<DisplayOnStatusProps> = ({status, errorMessage, children,}) => {
	const isInLoadingState = (): boolean => {
		return status === 'loading'
	};

	if (isInLoadingState()) {
		return (
			<Spinner data-testid="breadcrumb-dropdown-loading" />
			// <FlexContainer
			// 	justifyContent="center"
			// 	style={{
			// 		height:'100%',
			// 		// height: spinnerDiameter ? `${spinnerDiameter}px` : '100%',
			// 	}}
			// >
			// 	<Spinner />
			// </FlexContainer>
		);
	}

	if (status === 'error') {
		return (
			<Typography variant='h5' paragraph color="error">
				{errorMessage || 'Something went wrong.'}
				</Typography>
				// {extractQueryErrMessage(error)}
		);
	}

	return <>{children}</>;
};

export const DisplayOnLoad: FunctionComponent<DisplayOnLoadProps> = ({
	toggleOnRefetch = false,
	toggleOnUninitialized = true,
	isLoading,
	error,
	isError,
	isUninitialized,
	isFetching,
	spinnerDiameter,
	children,
}) => {
	const isInLoadingState = () => {
		if (toggleOnRefetch && toggleOnUninitialized) {
			return !!isFetching || isUninitialized || isLoading;
		}

		if (toggleOnRefetch) {
			return !!isFetching || isLoading;
		}

		if (toggleOnUninitialized) {
			return isUninitialized || isLoading;
		}

		return isLoading;
	};

	if (isInLoadingState()) {
		return (
			<FlexContainer
				justifyContent="center"
				style={{
					height: spinnerDiameter ? `${spinnerDiameter}px` : '100%',
				}}
			>
				<Spinner />
			</FlexContainer>
		);
	}

	if (isError && error) {
		return (
			<Typography color="error">
				{extractQueryErrMessage(error)}
			</Typography>
		);
	}

	return <>{children}</>;
};

export default DisplayOnLoad;
