import { StyledSVGLabelText } from './styledComponents';
import { FunctionComponent } from 'react';

interface ChartTopLabelProps {
	width: number;
	top: number;
	label: string;
	left: number;
}

const ChartTopLabel: FunctionComponent<ChartTopLabelProps> = ({
	width,
	left,
	top,
	label,
}) => {
	return (
		<g>
			<StyledSVGLabelText x={width / 2 + left / 2} y={top}>
				{label}
			</StyledSVGLabelText>
		</g>
	);
};

export default ChartTopLabel;
