import styled from "styled-components";
import Button from "./Button";
import React, {
    ButtonHTMLAttributes,
    forwardRef,
    FunctionComponent
} from "react";
import Typography, {ColorProp, TypographyVariant} from "../text/Typography";
import {Placement} from "@popperjs/core";
import {faChevronRight, IconName} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const BaseTextButton = styled(Button)`
	width: 10rem;
	border: none;
	background-color: ${(p) => p.color && p.color === 'transparent' ? 'none' : p.theme.palette.darkBaby};
	display: flex;
	justify-content: ${(p) => p.alignContent ? p.alignContent : 'center'};
	align-items: center;
	height: 2rem;
	border-radius: .25rem;
	padding: ${(p) => p.theme.spacing(1)};
	&:hover {
		color: ${(p) => p.theme.palette.primary.main};
	}
`;

export interface TextButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
	tooltip?: string;
	tooltipPlacement?: Placement;
	typography?: TypographyVariant;
	fillColor?: ColorProp;
	color?: ColorProp;
	baseOpacity?: string;
	alignContent?: string;
	variant?: 'base' | 'transparent';
	shape?: 'base' | 'round';
	// overrides?: Overrides;
	// size?: SizeProp;
	// iconSize?: IconSizeProp;
	showTooltip?: boolean;
	content?: any;
}

const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>((props, ref) => {
    const {
			fillColor = 'default',
			baseOpacity = '100%',
			// overrides,
			// size = 'sm',
			tooltip,
			tooltipPlacement,
			children,
			variant,
			typography,
			alignContent,
			disabled,
			content,
            onClick,
			color,
            text,
			...rest
		} = props;

	return <BaseTextButton className={'unselectable'} {...rest} ref={ref} onClick={onClick} color={fillColor} alignContent={alignContent}>
        <Typography className={'unselectable'} variant={!!typography ? typography : 'h5'} color={color}>
            {text}{children}
        </Typography>
	</BaseTextButton>;
})


export default TextButton;
