// TODO: extend to include deeper levels
export type ContextLevels = 'username' | 'domain' | 'entity' | 'individual' | 'attribute';

export const contextLadder = [
	'username',
	'domain',
	'entity',
	'individual',
	'attribute',
] as const;


export type ContextParamLadder = ContextLevels[];

export type ContextLadder = typeof contextLadder[number][];

export enum ContextLadderMap {
	'username',
	'domain',
	'entity',
	'individual',
	'attribute',
}


export interface ContextParams extends Record<string, any> {
	username?: string;
	domain?: string;
	entity?: string;
	individual?: string;
	attribute?: string;
}

export interface ContextLinkBuilder {
	(targetCtxLevel: ContextLadder[number], value: string): string;
}

export type DataContextLevels = 'username' | 'source' | 'catalog' | 'dataset';

export const dataContextLadder = [
	'username',
	'source',
	'catalog',
	'dataset',
] as const;

export type DataContextParamLadder = DataContextLevels[];

export type DataContextLadder = typeof dataContextLadder[number][];

export enum DataContextLadderMap {
	'username',
	'source',
	'catalog',
	'dataset',
}

export interface DataContextParams extends Record<string, any> {
	username?: string;
	source?: string;
	catalog?: string;
	dataset?: string;
}

export interface DataLinkBuilder {
	(targetCtxLevel: DataContextLadder[number], value: string): string;
}
