import {
    DataSourceSummaries,
    GetLiveDataCatalogsResponse,
    GetLiveDataSetsResponse,
    DataCatalog,
    LiveDataCatalog, LiveDataCatalogs, LiveDataSets,
    // LiveDataCatalogs
} from '../../types/dataTypes';
import {
    SelectedSourceData,
    SourceNavItemList,
    SourceSetter,
} from '../../types/uiTypes';
import ContextualDisplay, {
    BrowserDisplay,
    DataDisplay
} from '../ContextualDisplay';
// import CatalogNav from './CatalogNav';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {
    // useGetLiveDataSetsQuery,
    useGetSourceSummariesQuery
} from 'features/api';
import {parseQueryError} from 'features/api/helpers';
// import { canEdit as canEditOrg } from 'features/authentication/helpers';
import useActiveSourceData from 'features/ontology/hooks/useActiveSourceData';
import React, {FunctionComponent, useState, useEffect} from 'react';
import styled from 'styled-components';
import Modal from "common/modals/Modal";
import Typography from "common/text/Typography";
import {SourceBody} from "../../../HUD/components/HUDModal/styledComponents";
import ReactModal from "react-modal";
import {ModalType} from "../../../HUD/types/HUDTypes";
import {DataNav, DataModalNav} from "../../../navigation/components/Navbar";
// import HUD from "../../../HUD";
// import useViewModeParams from "../../../navigation/hooks/useViewModeParams";
import {ViewMode} from "../../../compositeViews/types";
import SourceNav, {BrowserNav, CatalogNav} from "../SourceBrowser/SourceNav";
// import useActiveCatalogData from "../../../ontology/hooks/useActiveCatalogData";
import useActiveOrgData from "../../../ontology/hooks/useActiveOrgData";
import useActiveCatalogDatasets
    from "../../../ontology/hooks/useActiveCatalogDatasets";
import {SourceListItem} from "../styledComponents";
import {
    ListItem,
    ListItemContent,
    ListItemControls
} from "../../../../common/List";
import CreateConnectionModal
    from "../../../../common/modals/CreateConnectionModal";
import {useParams} from "react-router-dom";
import FlexContainer from "../../../../common/FlexContainer";
import ProfileAvatar from "../../../navigation/components/Navbar/ProfileAvatar";
import RouterLink from "../../../../common/RouterLink";
import Heading from "../../../../common/text/Heading";
import {
    FacebookButton,
    GithubButton,
    InstagramButton,
    LinkedInButton
} from "../../../../common/buttons";
import ImportButton from "../../../../common/buttons/ImportButton";
import CatalogMenu from "../../../../common/menus/CatalogMenu";
import {faUndo} from "@fortawesome/free-solid-svg-icons";
import DatasetMenu from "../../../../common/menus/DatasetMenu";
// import {RenderViewConfig} from "../../navigation/hooks/useRenderView";

const StyledMain = styled.div<{ grid?: string; }>`
	display: grid;
	grid-template-columns: ${(p) => p.grid ? p.grid : '1fr 1fr 1fr 6fr'};
	grid-template-rows: 1fr;
	flex: 1;
	overflow: auto;
	position: absolute;
	width: calc(100vw - ${(p) => `${p.theme.sideControllerWidth + p.theme.panelGutter * 3}`}px);
	height: calc(100vh - ${(p) => p.theme.navHeight * 2 + p.theme.panelGutter * 2}px);
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter}px;
	color: ${(p) => p.theme.palette.text.paper};
`;

const StyledModal = styled.div`
	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-template-rows: 1fr;
	flex: 1;
	overflow: auto;
	background: ${(p) => p.theme.palette.background.paper};
	color: ${(p) => p.theme.palette.text.paper};
`;

const StyledPanel = styled.div`
	display: grid;
	flex: 1;
	overflow: auto;
	background: ${(p) => p.theme.palette.background.paper};
	color: ${(p) => p.theme.palette.text.paper};
`;

interface CatalogBrowserBaseProps {
    setOnDisplay: any;
    onDisplay: any;
    displayMode: any;
    setDisplayMode: any;
}

interface CatalogBrowserModalProps extends ReactModal.Props {
    handleClose: () => void;
    modalType: ModalType;
    modalWidth?: number;
    modalHeight?: number;
    top?: string;
    borderRadius?: string;
}

const StyledTypography = styled(Typography)`
	display: inline;
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
`;

interface CatalogNavProps {
    datasets: GetLiveDataSetsResponse;
    setter: SourceSetter;
    currentlySelected: SelectedSourceData;
    // setCardData: (cardData: LiveDataSets) => void;
    // sourceId: number | symbol;
}

const StyledListContainer = styled.section`
	border-right: 2px solid ${(p) => p.theme.palette.divider};
	overflow-y: auto;
	position: relative;
	max-height: calc(100vh - ${(p) => p.theme.navHeight * 2 - p.theme.panelGutter * 4}px);
	padding: ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(4)};
`;
//
// const CatalogNav: FunctionComponent<CatalogNavProps> = ({
// 	datasets,
// 	// setter,
// 	// currentlySelected,
// 	setCardData,
// 	// sourceId
// }) => {
// 	return (
// 		<StyledListContainer>
// 			<ul>
// 				{!!datasets && datasets.map((dataset) => {
// 					return <SourceListItem
// 						button
// 						key={`${dataset.catalogName}-${dataset.datasetName}`}
// 						// className={clsx(active && 'browser-item-is-open')}
// 						onClick={() => setCardData([dataset])}
// 					>
// 						<ListItemContent>
// 							<Typography color={'oldManGray'} variant='h5'>
// 								{dataset.datasetName}
// 							</Typography>
// 						</ListItemContent>
// 						{/*<ListItemControls>*/}
// 						{/*	<ImportButton importable={dataset} />*/}
// 						{/*	<RecacheButton cacheable={dataset} />*/}
// 						{/*	<DropButton droppable={dataset} />*/}
// 						{/*	<MoveButton movable={dataset} />*/}
// 						{/*	<DescribeButton describable={dataset} />*/}
// 						{/*</ListItemControls>*/}
// 					</SourceListItem>
// 				})}
// 			</ul>
// 		</StyledListContainer>
// 	);
// };


// const CatalogBrowserBase: FunctionComponent<CatalogBrowserBaseProps> = ({displayMode, setDisplayMode, onDisplay, setOnDisplay}) => {
// 	const [cardData, setCardData] = useState<SourceNavItemList | null>(null);
//
//
//
// 	// TODO: this causes flicker on cache invalidation.  Pass these props down
// 	// so children can render a spinner or something instead.
// 	if (isLoading || isFetching || isUninitialized) {
// 		return <p>Loading...</p>;
// 	}
//
// 	if (isError && error) {
// 		const { message } = parseQueryError(error);
//
// 		return <p>{message}</p>;
// 	}
//
// 	return (<>
//
// 			<StyledPanel>
// 				<ContextualDisplay
// 					displayMode={displayMode}
// 					closeForm={() => setDisplayMode('data')}
// 					activeItem={activeItem}
// 					setOnDisplay={setOnDisplay}
// 					onDisplay={onDisplay}
// 					cardData={cardData}
// 				/>
// 			</StyledPanel>
// 		</>
// 	);
//
// }

const StyledHeaderSection = styled.div`
	display: grid;
	grid-template:
	 [row1-start] "avatar header" 1fr [row1-end] 
	 / 6rem 1fr;
	justify-items: stretch;
	justify-content: stretch;
	align-content: stretch;
	align-items: stretch;
	gap: 1rem;
	margin-top: 1rem;
	margin-left: 1rem;
	grid-area: header;
	width: 100%;
	height: 100%;
`;

const StyledLabelSection = styled.div`
	display: grid;
	grid-template:
    [row1-start] "overline" auto [row1-end]
    [row2-start] "title" 1fr [row2-end]
    [row3-start] "url" auto [row3-end]
    [row4-start] "empty" 1rem [row4-end]
    [row5-start] "location" 1fr [row5-end]
    [row6-start] "social" 1fr [row6-end]
    / 200px;
	justify-items: stretch;
	justify-content: stretch;
	align-content: stretch;
	align-items: stretch;
	width: 100%;
	height: 100%;
`;

const StyledSection = styled.div`
	width: 100%;
	height: 100%;
`;

const StyledOtherSection = styled.div`
	display: grid;
	grid-template:
    [row1-start] "overline" auto [row1-end]
    [row2-start] "title" 1fr [row2-end]
    [row3-start] "url" auto [row3-end]
    [row4-start] "empty" 1rem [row4-end]
    [row5-start] "location" 1fr [row5-end]
    [row6-start] "social" 1fr [row6-end]
    / 1fr;
	justify-items: start;
	justify-content: stretch;
	align-content: start;
	align-items: start;
	min-width: 275px;
`;

const StyledGraphicContainer = styled.div`
	position: relative;
	width: 64px;
	height: 64px;
	float: left;
`
const StyledGraphicSquare = styled.div`
	background: ${(p) => p.theme.palette.primary.main};
	width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
`;

const StyledListItem = styled(ListItem)`
	white-space: nowrap;
`;

type CatalogModalTypes =
    | 'settings'
    | 'drop'
    | 'rename'
    | 'import';


const CatalogBrowser: FunctionComponent = () => {

    const params = useParams();
    const {activeItem: activeOrg} = useActiveOrgData();
    const {activeItem: activeSource} = useActiveSourceData();
    const {
        // isError,
        // isLoading,
        data: sources,
        // isFetching,
        // error,
        // isSuccess,
        // isUninitialized,
    } = useGetSourceSummariesQuery(
        activeOrg ? {orgId: activeOrg._id} : skipToken
    );

    const [displayMode, setDisplayMode] = useState<null | 'form'>(null)
    const [cardData, setCardData] = useState<SourceNavItemList | LiveDataCatalogs | LiveDataSets | null>(null)

    const {
        catalogs,
        datasets,
        isSuccess,
        isLoading,
        isFetching,
        isUninitialized,
        isError,
        error,
        ...loadState
    } = useActiveCatalogDatasets();


    const [onDisplay, setOnDisplay] = useState<SelectedSourceData>([] as SelectedSourceData);
    const activeCatalog = !!catalogs && catalogs.find((catalog) => catalog.name === params.catalog)

    useEffect(() => {
        if (isSuccess && datasets) {
            // setOnDisplay(datasets as SelectedSourceData);
            setCardData(datasets);
        }
    }, [datasets, isSuccess]);
    // TODO: this causes flicker on cache invalidation.  Pass these props down
    // so children can render a spinner or something instead.
    if (isLoading || isFetching || isUninitialized) {
        return <SourceBody borderRadius={'0px'}>
            <Typography variant={'h2'} color={'oldManGray'}>
                Loading...
            </Typography>
        </SourceBody>;
    }

    if (isError && error) {
        const {message} = parseQueryError(error);

        return <SourceBody borderRadius={'0px'}>
            <Typography variant={'h2'} color={'oldManGray'}>
                {message}
            </Typography>
        </SourceBody>;
    }

    return <SourceBody borderRadius={'0px'}>
        <StyledMain>
            <StyledPanel>
                <BrowserNav
                    sources={sources as DataSourceSummaries}
                    setter={(toSelect: SelectedSourceData) => setOnDisplay(toSelect)}
                    currentlySelected={onDisplay}
                />
            </StyledPanel>
            <StyledPanel>
                <SourceNav
                    catalogs={catalogs as GetLiveDataCatalogsResponse}
                    setter={(toSelect: SelectedSourceData) => setOnDisplay(toSelect)}
                    currentlySelected={onDisplay}
                    sourceId={!!activeSource ? activeSource._id : skipToken}
                />
            </StyledPanel>
            <StyledPanel>
                <CatalogNav
                    datasets={datasets as LiveDataSets}
                    setter={(toSelect: SelectedSourceData) => setOnDisplay(toSelect)}
                    currentlySelected={onDisplay}
                    sourceId={!!activeSource ? activeSource._id : skipToken}
                />
            </StyledPanel>

            {!!activeCatalog && <StyledPanel>
              <FlexContainer flexDirection="column" style={{height: '100%'}}>
                <FlexContainer
                    alignItems='center'
                    justifyContent={'space-between'}
                    style={{padding: '1rem'}}>
                  <FlexContainer gap={'3rem'}>
                    <StyledSection
                        style={{width: '32px', height: '32px'}}>
                      <StyledGraphicContainer>
                        <StyledGraphicSquare/>
                      </StyledGraphicContainer>
                    </StyledSection>
                    <FlexContainer flexDirection={'column'}>
                      <RouterLink
                          to={activeCatalog.sourceName}
                          preserveSearchParams={false}>
                        <Typography
                            // style={{gridArea: 'overline',}}
                            variant="caption1">
                            {activeCatalog.sourceName}
                        </Typography>
                      </RouterLink>
                      <Heading
                          // style={{gridArea: 'title'}}
                          component="h1">
                          {activeCatalog.name}
                      </Heading>
                    </FlexContainer>
                  </FlexContainer>
                    {/*<DataStats/>*/}
                  <FlexContainer gap={'1rem'} alignItems={'center'}>
                    <div>
                      <Typography variant='h5'>
                          {/*{dataset.stats?.sizeMegaBytes} bytes*/}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant='h5'>
                          {/*{dataset.stats?.rowCount} rows*/}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant='h5'>
                          {/*{dataset.tableSchema?.columns.length} columns*/}
                      </Typography>
                    </div>
                  </FlexContainer>
                  <FlexContainer>
                      {/*<StyledTableToggle*/}
                      {/*    icon={faUndo}*/}
                      {/*    size="sm"*/}
                      {/*    variant="transparent"*/}
                      {/*    shape="round"*/}
                      {/*    aria-label="toggle dataset view"*/}
                      {/*    onClick={togglePreviewMode}*/}
                      {/*/>*/}
                    <CatalogMenu
                        catalog={activeCatalog}
                        canEdit={!!activeCatalog
                            ? activeCatalog.userContext.permissions.edit
                            : false}
                    />
                  </FlexContainer>
                </FlexContainer>
                  {/*<FlexContainer>*/}
                  {/*    {activeCatalog*/}
                  {/*        ? <FlexContainer*/}
                  {/*            justifyContent={'space-between'}*/}
                  {/*            alignItems='flex-start'*/}
                  {/*            flexDirection='column'>*/}
                  {/*            <FlexContainer*/}
                  {/*                alignItems={'center'}*/}
                  {/*                alignContent={'center'}*/}
                  {/*                justifyContent={'space-between'}>*/}
                  {/*                */}
                  {/*                <StyledHeaderSection>*/}

                  {/*                    <StyledLabelSection*/}
                  {/*                        style={{gridArea: 'header'}}>*/}
                  {/*                        */}
                  {/*                    </StyledLabelSection>*/}
                  {/*                </StyledHeaderSection>*/}
                  {/*                */}
                  {/*            </FlexContainer>*/}
                  {/*            <div/>*/}
                  {/*        </FlexContainer> : null}*/}

              </FlexContainer>
            </StyledPanel>}
        </StyledMain>
    </SourceBody>;
}
//
// 						{/*<StyledListContainer>*/}
// 							{/*<ListItemControls>*/}
// 							{/*	{activeCatalog &&*/}
// 							{/*	<ImportButton importable={activeCatalog as LiveDataCatalog}/>*/}
// 							{/*	}*/}
//
// 							{/*</ListItemControls>*/}

export const CatalogBrowserModal: FunctionComponent<CatalogBrowserModalProps> = (
    {
        modalType,
        handleClose,
        modalWidth,
        modalHeight,
        top,
        borderRadius,
    }
) => {
    const [onDisplay, setOnDisplay] = useState<SelectedSourceData>([] as SelectedSourceData);
    const [displayMode, setDisplayMode] =
        useState<ViewMode>('data');
    return <Modal
        isOpen={!!modalType}
        modalType={modalType}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleClose}
        contentLabel={'Data Browser'}
        modalWidth={modalWidth}
        modalHeight={modalHeight}
        top={top}
        borderRadius={borderRadius}
    >
        <DataModalNav
            width={modalWidth}
            handleClose={handleClose}
            handleDisplay={setDisplayMode}
        />
        <StyledModal>
            {/*<CatalogBrowserBase*/}
            {/*	onDisplay={onDisplay}*/}
            {/*	setOnDisplay={setOnDisplay}*/}
            {/*	displayMode={displayMode}*/}
            {/*	setDisplayMode={setDisplayMode}*/}
            {/*/>*/}
        </StyledModal>
    </Modal>;
}

export default CatalogBrowser;
