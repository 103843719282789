import SlideDown from '../../transitions/SlideDown';
import { FunctionComponent, ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

interface PopoverDrawerProps {
	open: boolean;
	children?: ReactNode;
}

const PopoverDrawer: FunctionComponent<PopoverDrawerProps> = ({
	open,
	children,
}) => {
	const transitionRef = useRef();

	return (
		<CSSTransition
			classNames="slide-down-transition"
			in={open}
			timeout={300}
			style={{ paddingTop: '8px' }}
			nodeRef={transitionRef}
			unmountOnExit
		>
			<SlideDown ref={transitionRef as any}>
				<div style={{ overflow: 'auto', maxHeight: '300px' }}>
					{children}
				</div>
			</SlideDown>
		</CSSTransition>
	);
};
export default PopoverDrawer;
