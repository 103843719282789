import FlexContainer from '../../../common/FlexContainer';
import {FormResults} from '../../../common/Form';
import Typography from '../../../common/text/Typography';
import {useGetAttributesQuery, useSplitAttributeMutation} from '../../api';
import {mergeErrorStates, extractQueryErrMessage} from '../../api/helpers';
import {isRelation} from '../../ontology/typeGuards/attributeGuards';
import {GetEntityAttrsResponse} from '../../ontology/types/attributeTypes';
import {
    splitAttrFormDefaults,
    splitAttrFormToPayload,
} from './splitAttributeHelpers';
import {SplitAttrFormValues} from './splitAttributeTypes';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import React, {FunctionComponent, useCallback} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import useModalType from "../../HUD/hooks/useModalType";
import OtherIdSelect from "../../../common/inputs/OtherIdSelect";
import Loading from "../../../common/loading/Loading";

const SplitAttributeForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
	const {objectId: sourceAttrId, resource, canEdit} = modalProps;

    const objectEntityId = isRelation(resource) ? resource.objectEntityId : null;

    const formDefaults = splitAttrFormDefaults();

    const {handleSubmit, register, formState} = useForm<SplitAttrFormValues>({
        defaultValues: formDefaults,
    });

    const queryRes = useGetAttributesQuery(
        !!objectEntityId && typeof objectEntityId !== 'number'
            ? {entityId: objectEntityId._id} : skipToken
    );

    // useEffect(() => {
    //     if (queryRes.isSuccess && closeModal) {
    //         closeModal();
    //     }
    // }, [queryRes.isSuccess, closeModal]);

    const [splitAttr, mutationResults] = useSplitAttributeMutation();

    const mergedErrs = mergeErrorStates(queryRes, mutationResults);

    const validatingRegister = useCallback(
        (inputName: keyof SplitAttrFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const onSubmit: SubmitHandler<SplitAttrFormValues> = (vals, e) => {
        e?.preventDefault();
        splitAttr({
            sourceAttrId,
            body: splitAttrFormToPayload(vals),
        });
    };

    // if (queryRes.isUninitialized) {
    //     return (
    //         <FlexContainer justifyContent="center">
    //             <Typography color="warn" paragraph>
    //                 Waiting to receive target entity Id... If this message does
    //                 not clear, it is likely that no entity id is available for
    //                 the target entity of this relation.
    //             </Typography>
    //             <Spinner/>
    //         </FlexContainer>
    //     );
    // }

    if (queryRes.isError) {
        return (
            <FlexContainer justifyContent="center">
                <Typography color="error" paragraph variant='h5'>
                    {extractQueryErrMessage(queryRes.error)}
                </Typography>
            </FlexContainer>
        );
    }

    return (
        <AppModal
            label={"Split Attribute"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit ? canEdit : true}>

            {queryRes.isLoading || queryRes.isUninitialized
                ? <Loading />
                : <OtherIdSelect
                fieldName={'attributeId'}
                validatingRegister={validatingRegister}
                formState={formState}
                selectableAttrs={queryRes.data as GetEntityAttrsResponse}
            />}
            {/*// Ok to cast--if it's not loading and not errored, data is present*/}

            <FormResults
                onSuccess={closeModal}
                onSuccessDelay={600}
                isError={!!mergedErrs}
                error={mergedErrs}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default SplitAttributeForm;
