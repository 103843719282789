import Heading from 'common/text/Heading';
import styled from 'styled-components';

export const StyledHeading = styled(Heading)`
	font-size: 12px;
	text-align: center;
`;

export const StyledContentContainer = styled.div`
	flex-grow: 1;
	max-height: calc(100% - 18px);
`;
