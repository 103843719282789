import React, {useRef, useState, useEffect, FunctionComponent} from 'react';
import {UseFormRegisterReturn} from 'react-hook-form';
import styled from "styled-components";
import mapboxgl, {Map} from 'mapbox-gl';
import {harvestErrors, SubtleTextbox} from "../SubtleTextBox";
import {ActionFieldProps} from "../../../features/actions/common/commonTypes"; // eslint-disable-line import/no-webpack-loader-syntax


export interface SubtleSelectProps extends UseFormRegisterReturn {
    isDirty: boolean;
    label: string;
    onReset?: () => void;
    error?: string;
    defaultValue?: string;
    alignItems?: string;
    justifyContent?: string;
    inline?: boolean;
    id?: any;
}

const StyledOption = styled.option`
	color: ${(p) => p.theme.palette.primary.main};
	background-color: ${(p) => p.theme.palette.primary.main};

`;

// background-color: ${(p) => p.theme.palette.common.white}0A;
const StyledSelect = styled.select<{ $error?: boolean }>`
	background-color: ${(p) => p.theme.palette.darkerBaby};
	color: ${(p) => p.theme.palette.common.white}BF;
	background: transparent;
	border: 1px solid transparent;
	border-radius: ${(p) => p.theme.spacing(1)};
	font-size: ${(p) => p.theme.typography.input.fontSize};
	line-height: ${(p) => p.theme.typography.input.lineHeight};
	letter-spacing: ${(p) => p.theme.typography.input.letterSpacing};
	font-weight: ${(p) => p.theme.typography.input.fontWeight};
	font-family: ${(p) => p.theme.typography.input.fontFamily};
	padding: ${(p) => p.theme.spacing(0.5)} ${(p) => p.theme.spacing(1)};
	width: 100%;
	&:-webkit-autofill {
		background-color: ${(p) => p.theme.palette.darkerBaby};
		background-color: ${(p) => p.theme.palette.darkerBaby};
	}
	&::autofill {
		background-color: ${(p) => p.theme.palette.darkerBaby};
	}
	&:focus {
		border: 1px solid ${(p) =>
    p.$error ? p.theme.palette.error.main : p.theme.palette.darkerBaby}AA;
		outline: none;
	}
		&:active {
		outline: none;
	}

	&::placeholder {
		color: ${(p) => p.theme.palette.cyan};
		font-size: ${(p) => p.theme.typography.overline.fontSize};
		line-height: ${(p) => p.theme.typography.overline.lineHeight};
		letter-spacing: ${(p) => p.theme.typography.overline.letterSpacing};
		font-weight: ${(p) => p.theme.typography.overline.fontWeight};
		font-family: ${(p) => p.theme.typography.overline.fontFamily};
	}
	caret-color:  ${(p) => p.theme.palette.primary.main};
`;

const LocationSelect: FunctionComponent<ActionFieldProps> = (
    {
        defaultValue,
        formState,
        mutationResults,
        validatingRegister,
        resetField
    }
    ) => {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-70.9);
    const [lat, setLat] = useState(42.35);
    const [zoom, setZoom] = useState(9);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        // @ts-ignore
        map.current = new mapboxgl.Map({
            accessToken: 'pk.eyJ1IjoiZGF2aXNjbGFyayIsImEiOiJja2NscGx0eHgwYW15MnJtOHZqeWU0NTNjIn0.sqQcAW7EGKEPo036ot6ieQ',
            container: mapContainer.current as unknown as HTMLElement,
            style: 'mapbox://styles/mapbox/light-v10',
            center: [lng, lat],
            zoom: zoom
        });
    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        // @ts-ignore
        map.current?.on('move', () => {
            // @ts-ignore
            setLng(map.current.getCenter().lng.toFixed(4));
            // @ts-ignore
            setLat(map.current.getCenter().lat.toFixed(4));
            // @ts-ignore
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

    return (
        <>
        <SubtleTextbox
            {...validatingRegister('location', {})}
            showLabel
            label="Location"
            defaultValue={defaultValue}
            isDirty={Boolean(formState.dirtyFields.location)}
            onReset={() => resetField('location')}
            error={harvestErrors(
                'location',
                formState,
                mutationResults
            )}
        />
        <div>
            <div className="sidebar">
                Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
            </div>
                <div ref={mapContainer} className="map-container" />
                {/*<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>*/}
                {/*<div style={{width: '50px', height: '50px', margin: '45px auto', left: '45%', top: '45%', backgroundColor: '#000000'}} />*/}
            {/*</div>*/}

        </div>
            </>
    );
};

export default LocationSelect;
