import Popover from '../../../../common/popovers/Popover/PopoverBase';
import theme from '../../../../common/theme/theme';
import { useGetOrgsQuery, useLogoutMutation } from '../../../api';
import useLogout from '../../../authentication/hooks/useLogout';
import useCurrentUser from '../../../authentication/hooks/useUser';
import ProfileAvatar from './ProfileAvatar';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { List, ListItem, ListItemContent } from 'common/List';
import RouterLink from 'common/RouterLink';
import Typography from 'common/text/Typography';
import { isEmpty } from 'common/utils/typeGuards';
import useProfileImageURL from 'features/authentication/hooks/useProfileImageURL';
import useUserID from 'features/authentication/hooks/useUserID';
import InviteMenu from 'common/menus/InviteMenu';
import { ContextParams } from 'features/navigation/types/navigationContextTypes';
import React, { FunctionComponent, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useParams } from 'react-router-dom';
import StyledCopyright from "../../../../common/StyledCopyright";
import UserSettingsModal from "../../../../common/modals/UserSettingsModal";
import AccessibilityModal from "../../../../common/modals/AccessibilityModal";
import FeedbackModal from "../../../../common/modals/FeedbackModal";
import SettingsModal from "../../../../common/modals/SettingsModal";
import HelpModal from "../../../../common/modals/HelpModal";
import InvitationModal from "../../../../common/modals/InvitationModal";
import {useAppDispatch} from "../../../../app/hooks";
import useModalType from "../../../HUD/hooks/useModalType";
import {setModalType} from "../../../HUD/state/HUDSlice";
import {ModalType} from "../../../HUD/types/HUDTypes";

// const RouterLink = styled(RouterLink)`
// 	padding: ${(p) => p.theme.spacing(1)};
// 	display: inline-block;
// `;

// const ProfileMenuButton = styled.button`
// 	width: 100%;
// 	background: transparent;
// 	border: none;
// 	font-size: inherit;
// 	font-family: inherit;
// 	padding: ${(p) => p.theme.spacing(1)};
// 	cursor: pointer;
// `;

const ProfileMenu: FunctionComponent = () => {
	// const { org: currentOrgName } = useParams<ContextParams>();

	const appDispatch = useAppDispatch();
	const {closeModal} = useModalType();

	const { firstName, lastName, invitesRemaining, username, isOnline } = useCurrentUser();

	const profileImageURL = useProfileImageURL();

	const initial = firstName && firstName.length > 0 ? firstName[0] : 'U';

	const [referenceEl, setReferenceEl] = useState<HTMLElement | null>(null);
	const [referenceEl2, setReferenceEl2] = useState<HTMLElement | null>(null);

	const [menuOpen, setMenuOpen] = useState(false);
	const [revokeToken] = useLogoutMutation();

	const doLogout = useLogout();

	const handleSignout = () => {
		revokeToken();
		doLogout();
	};

	const openModal = (modalType: ModalType, options?: any) => {
		appDispatch(setModalType({type: modalType, props: options}));
	};

	const userId = useUserID();

	// const { data: orgs } = useGetOrgsQuery(
	// 	isEmpty(userId) ? skipToken : { userId }
	// );

	return (
		<>
			<ProfileAvatar
				initial={initial}
				profileImageURL={profileImageURL}
				onClick={() => setMenuOpen((p) => !p)}
				ref={setReferenceEl}
				isOnline={isOnline}
			/>
			<Popover anchorEl={referenceEl} open={menuOpen} offset={0}>
				<ClickAwayListener
					onClickAway={() => {
						if (menuOpen) {
							setMenuOpen(false);
						}
					}}
				>
				<List style={{ width: '250px' }}>
					<RouterLink
						to={`/${username}`}
						preserveSearchParams={false}
					>
						<ListItem style={{
							padding: '8px 3% 8px 8px',
							borderBottom:'1px solid #6a748550'
						}}>
							<ListItemContent style={{
								display: 'flex',
								justifyContent: 'start',
								alignItems: 'center'
							}}>
								<ProfileAvatar
									initial={initial}
									onClick={() => {}}
									profileImageURL={profileImageURL}
									isOnline={isOnline}
								/>
								<ListItemContent style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'start',
									flexDirection:'column',
									marginLeft: '.5rem'
								}}>
									<Typography
										variant='h5'
									>
										{firstName} {lastName}
									</Typography>
									<Typography
										style={{margin: '2px 0 2px .05rem'}}
										variant='subtitle'
									>
										View Profile
									</Typography>
								</ListItemContent>
							</ListItemContent>
					</ListItem>
					</RouterLink>
					<RouterLink
						to={`/`}
						preserveSearchParams={false}
					>
						<ListItem style={{
							borderBottom: '1px solid #6a748550'
						}}>
							<ListItemContent style={{
								display: 'flex',
								justifyContent: 'start',
								alignItems: 'center',
								padding: '16px 0 16px 16px',
							}}>
								<Typography variant='h6' style={{margin: '0'}}>
									See all Organizations
								</Typography>
							</ListItemContent>
					</ListItem>
					</RouterLink>
					{/*{orgs &&*/}
					{/*	orgs*/}
					{/*		.filter((a) => a.name !== currentOrgName)*/}
					{/*		.map((a) => (*/}
					{/*			<RouterLink to={`/${a.name}`} key={a.name}>*/}
					{/*				<ListItem style={{padding: '4% 0 0 8%'}}>*/}
					{/*					/!*<ListItemContent>*!/*/}
					{/*						<Typography variant='caption1'>{a.label}</Typography>*/}
					{/*					/!*</ListItemContent>*!/*/}
					{/*				</ListItem>*/}
					{/*			</RouterLink>*/}
					{/*		))}	*/}
					{invitesRemaining > 0 && <ListItem
							button
							onClick={() => {
								openModal('invitations');
								// setMenuOpen(false);
							}}
							ref={setReferenceEl2}
							style={{
								borderBottom: '1px solid #6a748550',
								margin: '0',
								padding: '4% 0 4% 16px',
								flexDirection: 'column',
								justifyItems: 'start',
								alignItems: 'start'
							}}
						>
						<Typography color='primary' variant={'caption2'}>
							Invite your friends
						</Typography>
						<Typography color='primary' variant='overline'>
							{invitesRemaining} Invites remaining
						</Typography>
					</ListItem>}
					<ListItem button onClick={() => openModal('settings', {canEdit: true})} style={{padding: '4%  0 4% 16px', borderBottom: '1px solid #6a748550',}}>
						<Typography variant='caption1'>
							Settings & privacy
						</Typography>
					</ListItem>
					<ListItem button onClick={() => openModal('help')}  style={{padding: '4%  0 4% 16px', borderBottom: '1px solid #6a748550'}}>
						<Typography variant='caption1'>
							Help & support
						</Typography>
					</ListItem>
					<ListItem button onClick={() => openModal('accessibility')} style={{padding: '4%  0 4% 16px', borderBottom: '1px solid #6a748550'}}>
						<Typography variant='caption1'>
							Display & accessibility
						</Typography>
					</ListItem>
					<ListItem button onClick={() => openModal('feedback')} style={{padding: '4%  0 4% 16px', borderBottom: '1px solid #6a748550'}}>
						<Typography variant='caption1'>
							Give feedback
						</Typography>
					</ListItem>
					<ListItem
						button onClick={handleSignout}
						style={{padding: '4% 0 6% 16px', borderBottom: '1px solid #6a748550'}}
					>
						<Typography variant='caption1'>
							Logout
						</Typography>
					</ListItem>
					<ListItem style={{flexDirection: 'column', gap: '0px', lineHeight: '0'}}>
						<ListItemContent style={{display: 'flex', flexDirection:'column', textAlign: 'center', padding: '8px 4px'}}>
							<Typography variant='caption1'>
								<RouterLink to="/privacy">Privacy</RouterLink> ·&nbsp;
								{/*<RouterLink to="/terms">Terms</RouterLink> ·&nbsp;*/}
								<RouterLink to="/privacy/cookies">Cookies</RouterLink> ·&nbsp;
								<RouterLink to="/about">About</RouterLink> ·&nbsp;
								<RouterLink to="/careers">Careers</RouterLink> ·&nbsp;
								<RouterLink to="/developers">Developers</RouterLink> ·&nbsp;
								<RouterLink to="/help">Help</RouterLink> ·&nbsp;
								{/*<RouterLink to="/docs">Docs</RouterLink> ·&nbsp;*/}
								<StyledCopyright color={'oldManGray'} />
							</Typography>
						</ListItemContent>
					</ListItem>
				</List>
				</ClickAwayListener>
			</Popover>
			{/*<InvitationModal isOpen={isInviteOpen} onClose={closeInvitations} />*/}
			{/*<HelpModal isOpen={isHelpOpen} onClose={closeHelp} />*/}
			{/*<AppSettingsModal isOpen={isSettingsOpen} onClose={closeSettings} />*/}
			{/*<AccessibilityModal isOpen={isAccessibilityOpen} onClose={closeAccessibility} />*/}
			{/*<FeedbackModal isOpen={isFeedbackOpen} onClose={closeFeedback} />*/}
			{/*<SettingsModal canEdit={true} isOpen={isSettingsOpen} onClose={closeSettings} />*/}
		</>
	);
};

export default ProfileMenu;
