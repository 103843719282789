import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";
import Textbox from "./TextBox";

const AccessKeyInput: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleTextbox
        {...validatingRegister('id')}
        showLabel
        label="Access Key ID"
        key="id"
        isDirty={Boolean(formState.dirtyFields.id)}
        onReset={() => resetField('id')}
        error={harvestErrors(
            'id',
            formState,
            mutationResults
        )}
    />;
}

export default AccessKeyInput;
