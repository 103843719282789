import {
	ReserveUserParams,
	ReserveUserResponse,
} from '../authentication/components/RequestAccessPage';
import { tokenReceived } from '../authentication/state/tokenSlice';
import {
	authenticate,
	logout,
	userRegistered,
} from '../authentication/state/userSlice';
import { setLinkedInTokenExpiry } from '../authentication/state/userSlice';
import { TokenDataResponse } from '../authentication/types/tokenTypes';
import {
	AuthenticateUserParams,
	GetUserParams,
	GetLandingParams,
	GetLandingResponse,
	GetUserProfileParams,
	GetUsersResponse,
	GrantAccessParams,
	GrantAccessResponse,
	InviteUserParams,
	InviteUserResponse,
	RegisterUserParams,
	RegisterUserResponse,
	RequestPWResetParams,
	RequestPWResetResponse,
	ResetPasswordParams,
	ResetPasswordResponse,
	UpdateUserDataResponse,
	UpdateUserParams,
	UserDataResponse,
	GetFollowersResponse,
	GetFollowersParams,
	GetFollowingResponse,
	GetFollowingParams,
	FollowResourceResponse,
	FollowResourceParams,
	UnfollowResourceResponse,
	UnfollowResourceParams,
	GetUserInvitesResponse,
	GetUserInvitesParams,
	DeleteResourceResponse,
	DeleteResourceParams,
	userResponseToAuthenticatedUser,
	GetUsersWaitingResponse,
	GetUsersWaitingParams,
	WithdrawInviteResponse,
	WithdrawInviteParams,
	ResendInviteResponse,
	ResendInviteParams,
} from '../authentication/types/userTypes';
import {
	GetSourceSummariesResponse,
	GetSourceSummariesParams,
	CreateSourceDataResponse,
	CreateSourceParams,
	GetLiveDataSetPreviewParams,
	GetLiveDataSetPreviewResponse,
	GetLiveDataCatalogsResponse,
	GetLiveDataCatalogsParams,
	GetLiveDataSetsResponse,
	GetLiveDataSetsParams,
	ImportCatalogToDomainResponse,
	ImportCatalogToDomainParams,
	ImportDatasetToDomainParams,
	ImportDatasetToDomainResponse,
	GenDomainFromCatalogParams,
	GenDomainFromCatalogResponse,
	GenDomainFromDatasetResponse,
	GenDomainFromDatasetParams,
	ProfileSourceDataResponse,
	ProfileSourceParams,
	TestSourceDataResponse,
	TestSourceParams,
	CreateCatalogDataResponse,
	CreateCatalogParams,
	CacheCatalogDataResponse,
	CacheCatalogParams,
	ProfileCatalogDataResponse,
	ProfileCatalogParams,
	TestCatalogDataResponse,
	TestCatalogParams,
	DropCatalogDataResponse,
	DropCatalogParams,
	CacheDatasetDataResponse,
	CacheDatasetParams,
	// DatasetColumnDataResponse,
	// DatasetColumnParams,
	ProfileDatasetDataResponse,
	ProfileDatasetParams,
	TestDatasetDataResponse,
	TestDatasetParams,
	CreateDataLoadResponse,
	CreateDataLoadParams,
	DropDatasetDataResponse,
	DropDatasetParams,
	UpdateColumnDataResponse,
	UpdateColumnParams,
	ProfileColumnDataResponse,
	ProfileColumnParams,
	TestColumnDataResponse,
	TestColumnParams,
	DropColumnDataResponse,
	DropColumnParams,
	UpdateDatasetDataResponse,
	UpdateDatasetParams,
	DataSourceSummary,
	GetDataProfilesResponse,
	GetDataProfilesParams,
	GetDataTestsResponse,
	GetDataErrorsResponse,
	GetDataStatisticsResponse,
	GetDataLoadsResponse,
	GetDataTestsParams,
	GetDataStatisticsParams,
	GetDataErrorsParams,
	GetDataLoadsParams,
} from '../browser/types/dataTypes';
import { applicationErr } from '../errorHandling/state/errorSlice';
import { toDispatchableErr } from '../errorHandling/types/errorTypes';
import {
	AggregateAttrActionParams,
	AggregateAttrActionResponse,
} from '../actions/aggregateAttribute/aggregateAttributeTypes';
import {
	AppendAttrActionParams,
	AppendAttrActionResponse,
} from '../actions/appendAttribute/appendAttributeTypes';
import {
	CreateAttrActionParams,
	CreateAttrActionResponse,
} from '../actions/createAttribute/createAttributeTypes';
import {
	CreateConditionalActionParams,
	CreateConditionalActionResponse,
} from '../actions/createConditional/createConditionalTypes';
import {
	CreateEventActionParams,
	CreateEventActionResponse,
} from '../actions/createEvent/createEventTypes';
import {
	CreateLocationActionParams,
	CreateLocationActionResponse,
} from '../actions/createLocation/createLocationTypes';
import {
	DeleteAttrActionParams,
	DeleteAttrActionResponse,
} from '../actions/deleteAttribute/deleteAttributeTypes';
import {
	DescribeAttrActionParams,
	DescribeAttrActionResponse,
} from '../actions/describeAttribute/describeAttributeTypes';
import {
	DescribeDomainParams,
	DescribeDomainResponse,
} from '../actions/describeDomain/describeDomainTypes';
import {
	DescribeEntityParams,
	DescribeEntityResponse,
} from '../actions/describeEntity/describeEntityTypes';
import {
	DescribeMetricParams,
	DescribeMetricResponse,
} from '../actions/describeMetric/describeMetricTypes';
import {
	LoadAttrActionParams,
	LoadAttrActionResponse,
} from '../actions/loadAttribute/loadAttributeTypes';
import {
	MoveAttrActionResponse,
	MoveAttrActionParams
} from '../actions/moveAttribute/moveAttributeTypes';
import {
	PivotEntityResponse,
	PivotEntityParams
} from '../actions/pivotEntity/pivotEntityTypes';
import {
	SplitAttrActionResponse,
	SplitAttrActionParams
} from '../actions/splitAttribute/splitAttributeTypes';
import {
	TranslateValueActionResponse,
	TranslateValueActionParams
} from '../actions/translateValue/translateValueTypes';
import {
	AddComponentActionResponse,
	AddComponentActionParams
} from '../actions/addComponent/addComponentTypes';
import {
	MeltEntityParams,
	MeltEntityResponse,
} from '../actions/meltEntity/meltEntityTypes';
import {
	OperateAttrActionParams,
	OperateAttrActionResponse,
} from '../actions/operateAttribute';
import {
	PersistEntityParams,
	PersistEntityResponse,
} from '../actions/persistEntity/persistEntityTypes';
import {
	RelateAttrActionParams,
	RelateAttrActionResponse,
} from '../actions/relateAttribute/relateAttributeTypes';
import {
	RestrictEntityActionParams,
	RestrictEntityActionResponse,
} from '../actions/restrictEntity/restrictEntityTypes';
import {
	CreateOrgAdminParams,
	CreateOrgAdminResponse,
	CreateOrgGovernorParams,
	CreateOrgGovernorResponse,
	CreateOrgPublisherParams,
	CreateOrgPublisherResponse,
	CreateOrgMaintainerResponse,
	CreateOrgMaintainerParams,
	CreateOrgFunderResponse,
	CreateOrgFunderParams,
	CreateOrgReporterResponse,
	CreateOrgReporterParams,
	CreateOrgContactResponse,
	CreateOrgContactParams,
	CreateOrgRightsHolderResponse,
	CreateOrgRightsHolderParams,
	CreateOrgMemberParams,
	CreateOrgMemberResponse,
	CreateDomainMemberParams,
	CreateDomainMemberResponse,
	CreateDomainStewardParams,
	CreateDomainStewardResponse,
	FollowUserParams,
	FollowUserResponse,
	GetOrgAdminsParams,
	GetOrgAdminsResponse,
	GetOrgGovernorsParams,
	GetOrgGovernorsResponse,
	GetOrgMembersParams,
	GetOrgMembersResponse,
	GetOrgPublishersResponse,
	GetOrgPublishersParams,
	GetOrgContactsResponse,
	GetOrgContactsParams,
	GetOrgMaintainersResponse,
	GetOrgMaintainersParams,
	GetOrgFundersResponse,
	GetOrgFundersParams,
	GetOrgRightsHoldersResponse,
	GetOrgRightsHoldersParams,
	GetOrgReportersResponse,
	GetOrgReportersParams,
	CreateOrgEngineerResponse,
	CreateOrgEngineerParams,
	GetDomainMembersParams,
	GetDomainMembersResponse,
	GetDomainStewardsParams,
	GetDomainStewardsResponse,
	RemoveUserRoleParams,
	RemoveUserRoleResponse,
	UnfollowUserParams,
	UnfollowUserResponse,
} from '../governance/types/GovernanceTypes';
import {
	OrgDataResponse,
	GetOrgParams,
	GetOrgGraphParams,
	OrgGraphDataResponse,
	OrgsDataResponse,
	GetOrgsParams,
	BaseOrg,
	CreateOrgParams,
	UpdateOrgParams,
	UpdateOrgDataResponse,
	DeleteOrgParams,
	DeleteOrgResponse,
	FollowOrgParams,
	FollowOrgResponse,
	UnfollowOrgParams,
	UnfollowOrgResponse
} from '../ontology/types/orgTypes';
import {
	AttributeDataResponse,
	AttributesDataResponse,
	GetAttributeParams,
	GetAttributesParams,
	GetDomainAttrsParams,
	GetDomainAttrsResponse,
	GetAttrNeighborsParams,
	GetAttrNeighborsResponse,
	GetAttributeMembersResponse,
	GetAttributeMembersParams,
	GetAttributeConditionsResponse,
	GetAttributeConditionsParams,
	GetAttributeSourcesResponse,
	GetAttributeSourcesParams,
} from '../ontology/types/attributeTypes';
import {
	BaseDomain,
	CreateDomainParams,
	UpdateDomainParams,
	UpdateDomainDataResponse,
	DeleteDomainParams,
	DeleteDomainResponse,
	DomainGraphDataResponse,
	DomainsDataResponse,
	FollowDomainParams,
	FollowDomainResponse,
	GetDomainGraphParams,
	GetDomainParams,
	GetDomainResponse,
	GetDomainsParams,
	UnfollowDomainParams,
	UnfollowDomainResponse,
} from '../ontology/types/domainTypes';
import {
	GetIndividualsParams,
	GetIndividualsResponse,
	CreateIndividualResponse,
	CreateIndividualParams,
	GetIndividualResponse,
	GetIndividualParams,
	UpdateIndividualResponse,
	UpdateIndividualParams,
	DeleteIndividualResponse,
	DeleteIndividualParams,
	FollowIndividualParams,
	FollowIndividualResponse,
	UnfollowIndividualParams,
	UnfollowIndividualResponse,
} from '../ontology/types/individualTypes';
import {
	CreateEntityDataResponse,
	CreateEntityParams,
	EntitiesDataResponse,
	EntityDataResponse,
	FollowEntityParams,
	FollowEntityResponse,
	GetEntitiesParams,
	GetEntityParams,
	GetEntityGraphParams,
	EntityGraphDataResponse,
	IdentifyEntityParams,
	IdentifyEntityResponse,
	ImportEntityParams,
	ImportEntityResponse,
	UnfollowEntityParams,
	UnfollowEntityResponse,
	UpdateEntityParams,
	UpdateEntityResponse,
	DeleteEntityParams,
	DeleteEntityResponse,
	BaseEntity,
	GetRestrictionsResponse,
	GetRestrictionsParams,
} from '../ontology/types/entityTypes';
import {
	CreateMetricDataResponse,
	CreateMetricParams,
	GetMetricParams,
	GetMetricsParams,
	FollowMetricParams,
	FollowMetricResponse,
	UnfollowMetricParams,
	UnfollowMetricResponse,
	MetricsDataResponse,
	MetricDataResponse,
	UpdateMetricResponse,
	UpdateMetricParams,
	DeleteMetricResponse,
	DeleteMetricParams,
} from '../ontology/types/metricTypes';
import {
	CreateInsightResponse,
	CreateInsightParams,
	GetInsightResponse,
	GetInsightParams,
	GetInsightsResponse,
	GetInsightsParams,
	UpdateInsightResponse,
	UpdateInsightParams,
	DeleteInsightResponse,
	DeleteInsightParams,
	FollowInsightResponse,
	FollowInsightParams,
	UnfollowInsightResponse,
	UnfollowInsightParams,
} from '../ontology/types/insightTypes';
import {
	AcceptAnswerParams,
	AcceptAnswerResponse,
	CreateAnswerParams,
	CreateAnswerResponse,
	CreateQuestionParams,
	CreateQuestionResponse,
	GetQuestionsParams,
	GetQuestionsResponse,
} from '../../common/questions/types/questionTypes';
import {
	CreateCommentParams,
	CreateCommentResponse,
	GetCommentsParams,
	GetCommentsResponse,
} from '../../common/comments/types/commentTypes';
import {
	GetNotificationsResponse,
	GetNotificationsParams
} from '../notifications/types/notificationTypes';
import {
	GetMessagesResponse,
	GetMessagesParams
} from '../messages/types/messageTypes';
import {
	GetActivitiesResponse,
	GetActivitiesParams,
	GetReactionsResponse,
	GetReactionsParams,
	GetViewersResponse,
	GetViewersParams,
	CreateReactionResponse,
	CreateReactionParams,
	GetTasksResponse,
	GetTasksParams,
} from '../../common/activities/types/activityTypes';
import { baseQueryWithLogout } from './fetchBaseQuery';
import { memberMgmtInvalidator, parseQueryError } from './helpers';
import URLBuilders from './urlBuilder';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { pipe } from 'common/utils/functionUtils';
import { isAppError } from 'common/utils/typeGuards';
import {
	CreateLinkedInShareParams,
	CreateLinkedInShareResponse,
	CreateShareResponse,
	CreateShareParams
} from 'common/sharing/types';
import {
	GetAttributeLineageResponse,
	GetAttributeLineageParams,
} from 'features/ontology/types/lineageTypes';
import { isNewIndividualIdentifier as isNewQuestionIndividualIdentifier } from 'common/questions/helpers';
import { isNewIndividualIdentifier as isNewReactionIndividualIdentifier } from 'common/questions/helpers';
import {
	CreateFeedbackParams,
	CreateFeedbackResponse,
	UpdateAccessibilityParams,
	UpdateAccessibilityResponse,
	UpdateSettingsParams,
	UpdateSettingsResponse
} from "../navigation/types/navigationTypes";
import {
	CreateActivityActionParams,
	CreateActivityActionResponse
} from "../actions/createActivity/createActivityTypes";

export const futureModelApi = createApi({
	reducerPath: 'futureModelApi',
	tagTypes: [
		'user',
		'currentUser',
		'orgs',
		'sources',
		'catalogs',
		'datasets',
		'columns',
		'rows',
		'types',
		'insights',
		'values',
		'metrics',
		'facts',
		'ontology',
		'domains',
		'entities',
		'attributes',
		'metrics',
		'individuals',
		'questions',
		'comments',
		'reactions',
		'invitations',
		'views',
		'followers',
		'following',
		'activities',
		'orgAdmins',
		'orgGovernors',
		'orgMembers',
		'orgPublishers',
		'orgContacts',
		'orgMaintainers',
		'orgFunders',
		'orgRightsHolders',
		'orgReporters',
		'orgEngineers',
		'domainMembers',
		'domainStewards',
		'attrLineage',
		//   general tag for any query that fetches data for users other than the authenticated user.
		'otherUsers',
		'messages',
		'notifications',
	],
	baseQuery: baseQueryWithLogout,
	// Keep cached data until explicitly invalidated, for now.  This will likely
	// have to change in the future.
	keepUnusedDataFor: 60 * 60,
	// RTK Query uses shallow-equals to determine query argument equality,
	// so it is acceptable to pass objects as query args as long as they
	// are not deeply-nested.  We will still get the caching behavior
	// we expect.
	// https://redux-toolkit.js.org/rtk-query/usage/queries#query-hook-options
	endpoints: (build) => ({
		authenticateUser: build.mutation<
			TokenDataResponse,
			AuthenticateUserParams
		>({
			async queryFn(args, { dispatch }, extraOptions, baseQuery) {
				const encoded = btoa(
					`${args.credentials.username}:${args.credentials.password}`
				);
				const queryResult = await baseQuery({
					url: URLBuilders.authenticateUser.buildSubpath(),
					method: 'POST',
					headers: {
						Authorization: `Basic ${encoded}`,
					},
				});

				// automatically add token to redux state
				// if request succeeds
				if (queryResult.data) {
					const { token } = queryResult.data as TokenDataResponse;

					pipe(token, tokenReceived, dispatch);
				}

				return queryResult.error
					? { error: queryResult.error as FetchBaseQueryError }
					: { data: queryResult.data as TokenDataResponse };
			},

			invalidatesTags: ['currentUser'],
		}),

		refreshToken: build.mutation<{ token: string }, any>({
			query: () => ({
				url: URLBuilders.refreshToken.buildSubpath(),
				method: 'POST',
			}),
		}),

		getUser: build.query<UserDataResponse, GetUserParams>({
			async queryFn(args, { dispatch }, extraOptions, baseQuery) {
				const queryResult = await baseQuery(
					URLBuilders.getUser.buildSubpath(args)
				);

				// query succeeded
				if (queryResult.data) {
					const maybeUser = userResponseToAuthenticatedUser(
						queryResult.data as UserDataResponse
					);

					// invalid default user role data came in from server
					if (isAppError(maybeUser)) {
						// Add err message to Redux state
						pipe(
							maybeUser,
							toDispatchableErr,
							applicationErr,
							dispatch
						);

						// log user out, since by this point in auth flow a token will have been set,
						// but is yielding invalid data
						pipe(logout(), dispatch);

						return {
							data: queryResult.data as UserDataResponse,
						};
					}

					// dispatch successfully-formatted user to Redux store
					pipe(maybeUser, authenticate, dispatch);

					// UI components need to know that query succeeded
					return {
						data: queryResult.data as UserDataResponse,
					};
				}

				// query failed, i.e. we don't have any user data from server.
				// dispatch parsed query error to Redux store
				else {
					pipe(
						queryResult.error as
							| FetchBaseQueryError
							| SerializedError,
						parseQueryError,
						toDispatchableErr,
						applicationErr,
						dispatch
					);

					return {
						error: queryResult.error as FetchBaseQueryError,
					};
				}
			},

			providesTags: ['currentUser'],
		}),

		getUserProfile: build.query<UserDataResponse, GetUserProfileParams>({
			query: (args) => ({
				url: URLBuilders.getUserProfile.buildSubpath(args),
			}),
			providesTags: ['otherUsers'],
		}),

		updateUser: build.mutation<UpdateUserDataResponse, UpdateUserParams>({
			query: (args) => ({
				url: URLBuilders.updateUser.buildSubpath(args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['currentUser', 'otherUsers'],
		}),

		reserveUser: build.mutation<ReserveUserResponse, ReserveUserParams>({
			query: (args) => ({
				url: URLBuilders.reserveUser.buildSubpath(),
				method: 'POST',
				body: args,
			}),
		}),

		logout: build.mutation<void, void>({
			query: () => ({
				url: URLBuilders.logout.buildSubpath(),
				method: 'DELETE',
			}),
			invalidatesTags: ['currentUser'],
		}),

		getUsers: build.query<GetUsersResponse, void>({
			query: () => ({
				url: URLBuilders.getUsers.buildSubpath(),
			}),
			providesTags: ['otherUsers'],
		}),

		getLanding: build.query<GetLandingResponse, GetLandingParams>({
			query: (args) => URLBuilders.getLanding.buildSubpath(args),
			providesTags: ['otherUsers', 'orgs'],
		}),

		getOrgs: build.query<OrgsDataResponse, GetOrgsParams>({
			query: (args) => URLBuilders.getOrgs.buildSubpath(args),
			providesTags: ['ontology', 'orgs'],
		}),

		getOrgGraph: build.query<
			OrgGraphDataResponse,
			GetOrgGraphParams
		>({
			query: (args) => URLBuilders.getOrgGraph.buildSubpath(args),
			providesTags: ['ontology', 'domains'],
		}),

		createOrg: build.mutation<OrgDataResponse, CreateOrgParams>({
			query: (args) => ({
				url: URLBuilders.createOrg.buildSubpath(),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['orgs'],
		}),

		updateOrg: build.mutation<UpdateOrgDataResponse, UpdateOrgParams>({
			query: (args) => ({
				url: URLBuilders.updateOrg.buildSubpath(args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['orgs', 'domains'],
		}),

		deleteOrg: build.mutation<DeleteOrgResponse, DeleteOrgParams>({
			query: (args) => ({
				url: URLBuilders.deleteOrg.buildSubpath(args),
				method: 'DELETE',
			}),
			invalidatesTags: ['orgs'],
		}),

		deleteResource: build.mutation<DeleteResourceResponse, DeleteResourceParams>({
			query: (args) => ({
				url: URLBuilders.deleteResource.buildSubpath(args),
				method: 'DELETE',
			}),
			// invalidatesTags: ['orgs'],
			// getURLStumpFromObjectType(args.objectType) as string
			invalidatesTags: (result, err, args) => {
				// if (isNewQuestionIndividualIdentifier(objectIdentifier)) {
				// 	// if the question being created will cause an individual to transition from 'unknown' to 'known',
				// 	// we need to re-query so that UI for that individual can be updated accordingly.
				// 	return ['questions', 'individuals'];
				// }

				return ['ontology'];
			},
		}),

		followOrg: build.mutation<FollowOrgResponse, FollowOrgParams>({
			query: (args) => ({
				url: URLBuilders.followOrg.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['orgs'],
		}),

		unfollowOrg: build.mutation<
			UnfollowOrgResponse,
			UnfollowOrgParams
		>({
			query: (args) => ({
				url: URLBuilders.unfollowOrg.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['orgs'],
		}),

		getDomain: build.query<GetDomainResponse, GetDomainParams>({
			query: (args) => URLBuilders.getDomain.buildSubpath(args),
			providesTags: ['ontology', 'domains'],
		}),

		getDomains: build.query<DomainsDataResponse, GetDomainsParams>({
			query: (args) => URLBuilders.getDomains.buildSubpath(args),
			providesTags: ['ontology', 'domains'],
		}),

		createDomain: build.mutation<BaseDomain, CreateDomainParams>({
			query: (args) => ({
				url: URLBuilders.createDomain.buildSubpath(),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['domains'],
		}),

		updateDomain: build.mutation<UpdateDomainDataResponse, UpdateDomainParams>({
			query: (args) => ({
				url: URLBuilders.updateDomain.buildSubpath(args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['domains', 'entities'],
		}),

		deleteDomain: build.mutation<DeleteDomainResponse, DeleteDomainParams>({
			query: (args) => ({
				url: URLBuilders.deleteDomain.buildSubpath(args),
				method: 'DELETE',
			}),
			invalidatesTags: ['domains'],
		}),

		getEntities: build.query<EntitiesDataResponse, GetEntitiesParams>({
			query: (args) => URLBuilders.getEntities.buildSubpath(args),
			providesTags: ['ontology', 'entities'],
		}),

		getEntity: build.query<BaseEntity, GetEntityParams>({
			query: (args) => URLBuilders.getEntity.buildSubpath(args),
			transformResponse: (res: EntityDataResponse) => res.response,
			providesTags: ['ontology', 'entities'],
		}),

		createEntity: build.mutation<
			CreateEntityDataResponse,
			CreateEntityParams
		>({
			query: (args) => ({
				url: URLBuilders.createEntity.buildSubpath(),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['entities'],
		}),

		getQuestions: build.query<GetQuestionsResponse, GetQuestionsParams>({
			query: (args) => ({
				url: URLBuilders.getQuestions.buildSubpath(args),
			}),
			// providesTags: (result, err, { objectId, objectType }) => [
			// 	{ type: 'questions', id: `${objectType}-${objectId}` },
			// ],
			providesTags: ['questions'],
		}),

		createQuestion: build.mutation<
			CreateQuestionResponse,
			CreateQuestionParams
		>({
			query: (args) => ({
				url: URLBuilders.createQuestion.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			// invalidatesTags: (
			// 	result,
			// 	err,
			// 	{ body: { objectId, objectType } }
			// ) => [{ type: 'questions', id: `${objectType}-${objectId}` }],
			invalidatesTags: (result, err, { body: { objectIdentifier } }) => {
				if (isNewQuestionIndividualIdentifier(objectIdentifier)) {
					// if the question being created will cause an individual to transition from 'unknown' to 'known',
					// we need to re-query so that UI for that individual can be updated accordingly.
					return ['questions', 'individuals'];
				}

				return ['questions'];
			},
		}),

		followResource: build.mutation<
			FollowResourceResponse,
			FollowResourceParams
		>({
			query: (args) => ({
				url: URLBuilders.followResource.buildSubpath(args),
				method: 'POST',
				body: args.body
			}),
			// invalidatesTags: (
			// 	result,
			// 	err,
			// 	{ body: { objectId, objectType } }
			// ) => [{ type: 'questions', id: `${objectType}-${objectId}` }],
			// invalidatesTags: (result, err, { body: { objectIdentifier } }) => {
			// 	if (isNewQuestionIndividualIdentifier(objectIdentifier)) {
			// 		// if the question being created will cause an individual to transition from 'unknown' to 'known',
			// 		// we need to re-query so that UI for that individual can be updated accordingly.
			// 		return ['questions', 'individuals'];
			// 	}
			//
			// 	return ['questions'];
			// },
		}),

		unfollowResource: build.mutation<
			UnfollowResourceResponse,
			UnfollowResourceParams
		>({
			query: (args) => ({
				url: URLBuilders.unfollowResource.buildSubpath(args),
				method: 'POST',
				body: args.body
			}),
			// invalidatesTags: (
			// 	result,
			// 	err,
			// 	{ body: { objectId, objectType } }
			// ) => [{ type: 'questions', id: `${objectType}-${objectId}` }],
			// invalidatesTags: (result, err, { body: { objectIdentifier } }) => {
			// 	if (isNewQuestionIndividualIdentifier(objectIdentifier)) {
			// 		// if the question being created will cause an individual to transition from 'unknown' to 'known',
			// 		// we need to re-query so that UI for that individual can be updated accordingly.
			// 		return ['questions', 'individuals'];
			// 	}
			//
			// 	return ['questions'];
			// },
		}),

		createReaction: build.mutation<
			CreateReactionResponse,
			CreateReactionParams
		>({
			query: (args) => ({
				url: URLBuilders.createReaction.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			// invalidatesTags: (
			// 	result,
			// 	err,
			// 	{ body: { objectId, objectType } }
			// ) => [{ type: 'questions', id: `${objectType}-${objectId}` }],
			invalidatesTags: (result, err, { body: { objectIdentifier } }) => {
				if (isNewReactionIndividualIdentifier(objectIdentifier)) {
					// if the question being created will cause an individual to transition from 'unknown' to 'known',
					// we need to re-query so that UI for that individual can be updated accordingly.
					return ['reactions', 'individuals'];
				}

				return ['reactions'];
			},
		}),

		// createShare: build.mutation<
		// 	CreateQuestionResponse,
		// 	CreateQuestionParams
		// >({
		// 	query: (args) => ({
		// 		url: URLBuilders.createQuestion.buildSubpath(args),
		// 		method: 'POST',
		// 		body: args.body,
		// 	}),
		// 	// invalidatesTags: (
		// 	// 	result,
		// 	// 	err,
		// 	// 	{ body: { objectId, objectType } }
		// 	// ) => [{ type: 'questions', id: `${objectType}-${objectId}` }],
		// 	invalidatesTags: (result, err, { body: { objectIdentifier } }) => {
		// 		if (isNewQuestionIndividualIdentifier(objectIdentifier)) {
		// 			// if the question being created will cause an individual to transition from 'unknown' to 'known',
		// 			// we need to re-query so that UI for that individual can be updated accordingly.
		// 			return ['questions', 'individuals'];
		// 		}
		//
		// 		return ['questions'];
		// 	},
		// }),

		createFeedback: build.mutation<CreateFeedbackResponse, CreateFeedbackParams>({
			query: (args) => ({
				url: URLBuilders.createFeedback.buildSubpath(),
				method: 'POST',
				body: args.body,
			}),
		}),

		updateAccessibility: build.mutation<UpdateAccessibilityResponse, UpdateAccessibilityParams>({
			query: (args) => ({
				url: URLBuilders.updateAccessibility.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
		}),

		updateSettings: build.mutation<UpdateSettingsResponse, UpdateSettingsParams>({
			query: (args) => ({
				url: URLBuilders.updateSettings.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
		}),

		createAnswer: build.mutation<CreateAnswerResponse, CreateAnswerParams>({
			query: (args) => ({
				url: URLBuilders.createAnswer.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			//    Answers are always fetched as nested data within a question object,
			// so when we create a new answer, we trigger fetching it by invalidating the
			// query for its parent question
			// invalidatesTags: (
			// 	result,
			// 	err,
			// 	{ parentObjectId, parentObjectType }
			// ) => [
			// 	{
			// 		type: 'questions',
			// 		id: `${parentObjectType}-${parentObjectId}`,
			// 	},
			// ],
			invalidatesTags: ['questions'],
		}),

		acceptAnswer: build.mutation<AcceptAnswerResponse, AcceptAnswerParams>({
			query: (args) => ({
				url: URLBuilders.acceptAnswer.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			//    Answers are always fetched as nested data within a question object,
			// so when we create a new answer, we trigger fetching it by invalidating the
			// query for its parent question
			// invalidatesTags: (
			// 	result,
			// 	err,
			// 	{ parentObjectId, parentObjectType }
			// ) => [
			// 	{
			// 		type: 'questions',
			// 		id: `${parentObjectType}-${parentObjectId}`,
			// 	},
			// ],
			invalidatesTags: ['questions'],
		}),

		getComments: build.query<GetCommentsResponse, GetCommentsParams>({
			query: (args) => ({
				url: URLBuilders.getComments.buildSubpath(args),
			}),
			// providesTags: (result, err, { objectId, objectType }) => [
			// 	{ type: 'questions', id: `${objectType}-${objectId}` },
			// ],
			providesTags: ['comments'],
		}),

		getReactions: build.query<GetReactionsResponse, GetReactionsParams>({
			query: (args) => ({
				url: URLBuilders.getReactions.buildSubpath(args),
			}),
			// providesTags: (result, err, { objectId, objectType }) => [
			// 	{ type: 'questions', id: `${objectType}-${objectId}` },
			// ],
			providesTags: ['reactions'],
		}),

		getViewers: build.query<GetViewersResponse, GetViewersParams>({
			query: (args) => ({
				url: URLBuilders.getViewers.buildSubpath(args),
			}),
			// providesTags: (result, err, { objectId, objectType }) => [
			// 	{ type: 'questions', id: `${objectType}-${objectId}` },
			// ],
			providesTags: ['views'],
		}),

		getFollowers: build.query<GetFollowersResponse, GetFollowersParams>({
			query: (args) => ({
				url: URLBuilders.getFollowers.buildSubpath(args),
			}),
			// providesTags: (result, err, { objectId, objectType }) => [
			// 	{ type: 'questions', id: `${objectType}-${objectId}` },
			// ],
			providesTags: ['followers'],
		}),

		getFollowing: build.query<GetFollowingResponse, GetFollowingParams>({
			query: (args) => ({
				url: URLBuilders.getFollowing.buildSubpath(args),
			}),
			// providesTags: (result, err, { objectId, objectType }) => [
			// 	{ type: 'questions', id: `${objectType}-${objectId}` },
			// ],
			providesTags: ['following'],
		}),

		getMessages: build.query<GetMessagesResponse, GetMessagesParams>({
			query: (args) => ({
				url: URLBuilders.getMessages.buildSubpath(args),
			}),
			// providesTags: (result, err, { objectId, objectType }) => [
			// 	{ type: 'questions', id: `${objectType}-${objectId}` },
			// ],
			providesTags: ['messages'],
		}),

		getNotifications: build.query<GetNotificationsResponse, GetNotificationsParams>({
			query: (args) => ({
				url: URLBuilders.getNotifications.buildSubpath(args),
			}),
			// providesTags: (result, err, { objectId, objectType }) => [
			// 	{ type: 'questions', id: `${objectType}-${objectId}` },
			// ],
			providesTags: ['notifications'],
		}),

		getActivity: build.query<GetActivitiesResponse, GetActivitiesParams>({
			query: (args) => ({
				url: URLBuilders.getActivity.buildSubpath(args),
			}),
			// providesTags: (result, err, { objectId, objectType }) => [
			// 	{ type: 'questions', id: `${objectType}-${objectId}` },
			// ],
			providesTags: ['activities'],
		}),

		createComment: build.mutation<
			CreateCommentResponse,
			CreateCommentParams
		>({
			query: (args) => ({
				url: URLBuilders.createComment.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			// invalidatesTags: (
			// 	result,
			// 	err,
			// 	{ body: { objectId, objectType } }
			// ) => [{ type: 'questions', id: `${objectType}-${objectId}` }],
			// invalidatesTags: (result, err, { body: { objectIdentifier } }) => {
			// 	if (isNewCommentIndividualIdentifier(objectIdentifier)) {
			// 		// if the question being created will cause an individual to transition from 'unknown' to 'known',
			// 		// we need to re-query so that UI for that individual can be updated accordingly.
			// 		return ['comments', 'individuals'];
			// 	}
			//
			// 	return ['comments'];
			// },
		}),

		getDomainGraph: build.query<
			DomainGraphDataResponse,
			GetDomainGraphParams
		>({
			query: (args) => URLBuilders.getDomainGraph.buildSubpath(args),
			providesTags: ['ontology', 'entities'],
		}),

		getEntityGraph: build.query<
			EntityGraphDataResponse,
			GetEntityGraphParams
		>({
			query: (args) => URLBuilders.getEntityGraph.buildSubpath(args),
			providesTags: ['ontology'],
		}),


		getOrg: build.query<OrgDataResponse, GetOrgParams>({
			query: (args) => URLBuilders.getOrg.buildSubpath(args),
			providesTags: ['ontology', 'orgs'],
		}),

		getAttribute: build.query<AttributeDataResponse, GetAttributeParams>({
			query: (args) => URLBuilders.getAttribute.buildSubpath(args),
			providesTags: ['ontology', 'attributes'],
		}),

		getAttributes: build.query<AttributesDataResponse, GetAttributesParams>(
			{
				query: (args) => URLBuilders.getAttributes.buildSubpath(args),
				providesTags: ['ontology', 'attributes'],
			}
		),

		getAttributeMembers: build.query<GetAttributeMembersResponse, GetAttributeMembersParams>(
			{
				query: (args) => URLBuilders.getAttributeMembers.buildSubpath(args),
				providesTags: ['ontology', 'attributes'],
			}
		),
		getAttributeConditions: build.query<GetAttributeConditionsResponse, GetAttributeConditionsParams>(
			{
				query: (args) => URLBuilders.getAttributeConditions.buildSubpath(args),
				providesTags: ['ontology', 'attributes'],
			}
		),
		getAttributeSources: build.query<GetAttributeSourcesResponse, GetAttributeSourcesParams>(
			{
				query: (args) => URLBuilders.getAttributeSources.buildSubpath(args),
				providesTags: ['ontology', 'attributes'],
			}
		),

		getDataProfiles: build.query<GetDataProfilesResponse, GetDataProfilesParams>(
			{
				query: (args) => URLBuilders.getDataProfiles.buildSubpath(args),
				providesTags: ['ontology', 'attributes'],
			}
					// providesTags: (result, err, { objectId, objectType }) => [
			// 	{ type: 'questions', id: `${objectType}-${objectId}` },
			// ],
		),

		getDataTests: build.query<GetDataTestsResponse, GetDataTestsParams>(
			{
				query: (args) => URLBuilders.getDataTests.buildSubpath(args),
				providesTags: ['ontology', 'attributes'],
			}
					// providesTags: (result, err, { objectId, objectType }) => [
			// 	{ type: 'questions', id: `${objectType}-${objectId}` },
			// ],
		),

		getDataStatistics: build.query<GetDataStatisticsResponse, GetDataStatisticsParams>(
			{
				query: (args) => URLBuilders.getDataStatistics.buildSubpath(args),
				providesTags: ['ontology', 'attributes'],
			}
					// providesTags: (result, err, { objectId, objectType }) => [
			// 	{ type: 'questions', id: `${objectType}-${objectId}` },
			// ],
		),

		getDataErrors: build.query<GetDataErrorsResponse, GetDataErrorsParams>(
			{
				query: (args) => URLBuilders.getDataErrors.buildSubpath(args),
				providesTags: ['ontology', 'attributes'],
			}
		),

		getDataLoads: build.query<GetDataLoadsResponse, GetDataLoadsParams>(
			{
				query: (args) => URLBuilders.getDataLoads.buildSubpath(args),
				providesTags: ['ontology', 'attributes'],
			}
		),

		getTasks: build.query<GetTasksResponse, GetTasksParams>(
			{
				query: (args) => URLBuilders.getTasks.buildSubpath(args),
				providesTags: ['ontology', 'attributes'],
			}
					// providesTags: (result, err, { objectId, objectType }) => [
			// 	{ type: 'questions', id: `${objectType}-${objectId}` },
			// ],
		),

		getRestrictions: build.query<GetRestrictionsResponse, GetRestrictionsParams>(
			{
				query: (args) => URLBuilders.getRestrictions.buildSubpath(args),
				providesTags: ['ontology', 'attributes'],
			}
		),

		//   TODO: need a way to invalidate this that make sense, probably associated with parent attribute
		getIndividuals: build.query<
			GetIndividualsResponse,
			GetIndividualsParams
		>({
			query: (args) => !!args.identifier
				? URLBuilders.getIndividual.buildSubpath(args)
				: URLBuilders.getIndividuals.buildSubpath(args),
			providesTags: ['attributes', 'individuals'],
		}),

		getSourceSummaries: build.query<
			GetSourceSummariesResponse,
			GetSourceSummariesParams
		>({
			query: (args) => URLBuilders.getSourceSummaries.buildSubpath(args),
			providesTags: ['sources'],
		}),

		getLiveDataCatalogs: build.query<
			GetLiveDataCatalogsResponse,
			GetLiveDataCatalogsParams
		>({
			query: (args) => URLBuilders.getLiveDataCatalogs.buildSubpath(args),
			providesTags: ['catalogs'],
		}),

		getLiveDataSets: build.query<
			GetLiveDataSetsResponse,
			GetLiveDataSetsParams
		>({
			query: (args) => URLBuilders.getLiveDataSets.buildSubpath(args),
			providesTags: ['datasets'],
		}),

		// TODO: eventually we will want to submit any file uploads separately for both client and
		// server performance.
		createSource: build.mutation<
			CreateSourceDataResponse,
			CreateSourceParams
		>({
			query: (args) => ({
				url: URLBuilders.createSource.buildSubpath(),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['sources'],
		}),

		profileSource: build.mutation<
			ProfileSourceDataResponse,
			ProfileSourceParams
		>({
			query: (args) => ({
				url: URLBuilders.profileSource.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['sources'],
		}),

		testSource: build.mutation<
			TestSourceDataResponse,
			TestSourceParams
		>({
			query: (args) => ({
				url: URLBuilders.testSource.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['sources'],
		}),

		createCatalog: build.mutation<
			CreateCatalogDataResponse,
			CreateCatalogParams
		>({
			query: (args) => ({
				url: URLBuilders.createCatalog.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['catalogs'],
		}),

		cacheCatalog: build.mutation<
			CacheCatalogDataResponse,
			CacheCatalogParams
		>({
			query: (args) => ({
				url: URLBuilders.cacheCatalog.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['catalogs'],
		}),

		profileCatalog: build.mutation<
			ProfileCatalogDataResponse,
			ProfileCatalogParams
		>({
			query: (args) => ({
				url: URLBuilders.profileCatalog.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['catalogs'],
		}),

		testCatalog: build.mutation<
			TestCatalogDataResponse,
			TestCatalogParams
		>({
			query: (args) => ({
				url: URLBuilders.testCatalog.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['catalogs'],
		}),

		dropCatalog: build.mutation<
			DropCatalogDataResponse,
			DropCatalogParams
		>({
			query: (args) => ({
				url: URLBuilders.dropCatalog.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['catalogs'],
		}),

		cacheDataset: build.mutation<
			CacheDatasetDataResponse,
			CacheDatasetParams
		>({
			query: (args) => ({
				url: URLBuilders.cacheDataset.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['datasets'],
		}),

		updateDataset: build.mutation<
			UpdateDatasetDataResponse,
			UpdateDatasetParams
		>({
			query: (args) => ({
				url: URLBuilders.updateDataset.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['datasets'],
		}),

		profileDataset: build.mutation<
			ProfileDatasetDataResponse,
			ProfileDatasetParams
		>({
			query: (args) => ({
				url: URLBuilders.profileDataset.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['datasets'],
		}),

		testDataset: build.mutation<
			TestDatasetDataResponse,
			TestDatasetParams
		>({
			query: (args) => ({
				url: URLBuilders.testDataset.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['datasets'],
		}),

		createDataLoad: build.mutation<
			CreateDataLoadResponse,
			CreateDataLoadParams
		>({
			query: (args) => ({
				url: URLBuilders.createDataLoad.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['datasets'],
		}),


		dropDataset: build.mutation<
			DropDatasetDataResponse,
			DropDatasetParams
		>({
			query: (args) => ({
				url: URLBuilders.dropDataset.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['datasets'],
		}),

		updateColumn: build.mutation<
			UpdateColumnDataResponse,
			UpdateColumnParams
		>({
			query: (args) => ({
				url: URLBuilders.updateColumn.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['columns'],
		}),

		profileColumn: build.mutation<
			ProfileColumnDataResponse,
			ProfileColumnParams
		>({
			query: (args) => ({
				url: URLBuilders.profileColumn.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['columns'],
		}),

		testColumn: build.mutation<
			TestColumnDataResponse,
			TestColumnParams
		>({
			query: (args) => ({
				url: URLBuilders.testColumn.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['columns'],
		}),

		dropColumn: build.mutation<
			DropColumnDataResponse,
			DropColumnParams
		>({
			query: (args) => ({
				url: URLBuilders.dropColumn.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['columns'],
		}),

		// genDomainFromSource: build.mutation<
		// 	GenDomainFromSourceResponse,
		// 	GenDomainFromSourceParams
		// >({
		// 	query: (args) => ({
		// 		url: URLBuilders.genDomainFromSource.buildSubpath(args),
		// 		method: 'POST',
		// 	}),

		// 	invalidatesTags: ['domains'],
		// }),

		importCatalogToDomain: build.mutation<
			ImportCatalogToDomainResponse,
			ImportCatalogToDomainParams
		>({
			query: (args) => ({
				url: URLBuilders.importCatalogToDomain.buildSubpath(args),
				method: 'POST',
			}),

			invalidatesTags: ['domains', 'entities'],
		}),

		importDatasetToDomain: build.mutation<
			ImportDatasetToDomainResponse,
			ImportDatasetToDomainParams
		>({
			query: (args) => ({
				url: URLBuilders.importDatasetToDomain.buildSubpath(args),
				method: 'POST',
			}),

			invalidatesTags: ['domains', 'entities'],
		}),

		genDomainFromCatalog: build.mutation<
			GenDomainFromCatalogResponse,
			GenDomainFromCatalogParams
		>({
			query: (args) => ({
				url: URLBuilders.genDomainFromCatalog.buildSubpath(args),
				method: 'POST',
			}),

			invalidatesTags: ['domains', 'entities'],
		}),

		genDomainFromDataset: build.mutation<
			GenDomainFromDatasetResponse,
			GenDomainFromDatasetParams
		>({
			query: (args) => ({
				url: URLBuilders.genDomainFromDataset.buildSubpath(args),
				method: 'POST',
			}),

			invalidatesTags: ['domains', 'entities'],
		}),

		getDataSetPreview: build.query<
			GetLiveDataSetPreviewResponse,
			GetLiveDataSetPreviewParams
		>({
			query: (args) =>
				URLBuilders.getLiveDataSetPreview.buildSubpath(args),
		}),

		getDataset: build.query<
			GetLiveDataSetPreviewResponse,
			GetLiveDataSetPreviewParams
		>({
			query: (args) =>
				URLBuilders.getLiveDataSetPreview.buildSubpath(args),
		}),

		createIndividual: build.mutation<
			CreateIndividualResponse,
			CreateIndividualParams
		>({
			query: (args) => ({
				url: URLBuilders.createIndividual.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['insights'],
		}),

		getIndividual: build.query<
			GetIndividualResponse,
			GetIndividualParams
		>({
			query: (args) => ({
				url: URLBuilders.getIndividual.buildSubpath(args)
			}),
			providesTags: ['individuals'],
		}),

		updateIndividual: build.mutation<
			UpdateIndividualResponse,
			UpdateIndividualParams
		>({
			query: (args) => ({
				url: URLBuilders.updateIndividual.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['insights'],
		}),

		deleteIndividual: build.mutation<
			DeleteIndividualResponse,
			DeleteIndividualParams
		>({
			query: (args) => ({
				url: URLBuilders.deleteIndividual.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['individuals'],
		}),

		createInsight: build.mutation<
			CreateInsightResponse,
			CreateInsightParams
		>({
			query: (args) => ({
				url: URLBuilders.createInsight.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['insights'],
		}),

		getInsight: build.query<
			GetInsightResponse,
			GetInsightParams
		>({
			query: (args) =>
				URLBuilders.getInsight.buildSubpath(args),
		}),

		getInsights: build.query<
			GetInsightsResponse,
			GetInsightsParams
		>({
			query: (args) =>
				URLBuilders.getInsights.buildSubpath(args),
		}),

		updateInsight: build.mutation<UpdateInsightResponse, UpdateInsightParams>({
			query: (args) => ({
				url: URLBuilders.updateInsight.buildSubpath(args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['insights'],
		}),

		deleteInsight: build.mutation<DeleteInsightResponse, DeleteInsightParams>({
			query: (args) => ({
				url: URLBuilders.deleteInsight.buildSubpath(args),
				method: 'DELETE',
			}),
			invalidatesTags: ['insights'],
		}),

		followInsight: build.mutation<FollowInsightResponse, FollowInsightParams>({
			query: (args) => ({
				url: URLBuilders.followInsight.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['insights'],
		}),

		unfollowInsight: build.mutation<
			UnfollowInsightResponse,
			UnfollowInsightParams
		>({
			query: (args) => ({
				url: URLBuilders.unfollowInsight.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['insights'],
		}),

		createMetric: build.mutation<
			CreateMetricDataResponse,
			CreateMetricParams
		>({
			query: (args) => ({
				url: URLBuilders.createMetric.buildSubpath(args),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['metrics'],
		}),

		getMetric: build.query<
			MetricDataResponse,
			GetMetricParams
		>({
			query: (args) =>
				URLBuilders.getMetric.buildSubpath(args),
		}),

		getMetrics: build.query<
			MetricsDataResponse,
			GetMetricsParams
		>({
			query: (args) =>
				URLBuilders.getMetrics.buildSubpath(args),
		}),

		updateMetric: build.mutation<UpdateMetricResponse, UpdateMetricParams>({
			query: (args) => ({
				url: URLBuilders.updateMetric.buildSubpath(args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['metrics', 'entities'],
		}),

		deleteMetric: build.mutation<DeleteMetricResponse, DeleteMetricParams>({
			query: (args) => ({
				url: URLBuilders.deleteMetric.buildSubpath(args),
				method: 'DELETE',
			}),
			invalidatesTags: ['metrics'],
		}),

		followMetric: build.mutation<FollowMetricResponse, FollowMetricParams>({
			query: (args) => ({
				url: URLBuilders.followMetric.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['metrics'],
		}),

		unfollowMetric: build.mutation<
			UnfollowMetricResponse,
			UnfollowMetricParams
		>({
			query: (args) => ({
				url: URLBuilders.unfollowMetric.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['metrics'],
		}),

		followDomain: build.mutation<FollowDomainResponse, FollowDomainParams>({
			query: (args) => ({
				url: URLBuilders.followDomain.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['domains'],
		}),

		unfollowDomain: build.mutation<
			UnfollowDomainResponse,
			UnfollowDomainParams
		>({
			query: (args) => ({
				url: URLBuilders.unfollowDomain.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['domains'],
		}),

		followEntity: build.mutation<FollowEntityResponse, FollowEntityParams>({
			query: (args) => ({
				url: URLBuilders.followEntity.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['entities'],
		}),

		unfollowEntity: build.mutation<
			UnfollowEntityResponse,
			UnfollowEntityParams
		>({
			query: (args) => ({
				url: URLBuilders.unfollowEntity.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['entities'],
		}),

		followUser: build.mutation<FollowUserResponse, FollowUserParams>({
			query: (args) => ({
				url: URLBuilders.followUser.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['otherUsers'],
		}),

		unfollowUser: build.mutation<UnfollowUserResponse, UnfollowUserParams>({
			query: (args) => ({
				url: URLBuilders.unfollowUser.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['otherUsers'],
		}),

		getDomainAttributes: build.query<
			GetDomainAttrsResponse,
			GetDomainAttrsParams
		>({
			query: (args) => URLBuilders.getDomainAttributes.buildSubpath(args),
			providesTags: ['attributes'],
		}),

		identifyEntity: build.mutation<
			IdentifyEntityResponse,
			IdentifyEntityParams
		>({
			query: (args) => ({
				url: URLBuilders.identifyEntity.buildSubpath(args),
				method: 'POST',
				body: { attributeId: args.attributeId },
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		relateAttrAction: build.mutation<
			RelateAttrActionResponse,
			RelateAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.relateAttrAction.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		describeAttrAction: build.mutation<
			DescribeAttrActionResponse,
			DescribeAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.describeAttrAction.buildSubpath(args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		getAttrNeighbors: build.query<
			GetAttrNeighborsResponse,
			GetAttrNeighborsParams
		>({
			query: (args) => ({
				url: URLBuilders.getAttrNeighbors.buildSubpath(args),
			}),
			providesTags: ['entities', 'attributes'],
		}),

		appendAttrAction: build.mutation<
			AppendAttrActionResponse,
			AppendAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.appendAttrAction.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		operateAttr: build.mutation<
			OperateAttrActionResponse,
			OperateAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.operateAttrAction.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		createActivity: build.mutation<
			CreateActivityActionResponse,
			CreateActivityActionParams
		>({
			query: (args) => ({
				url: URLBuilders.createActivity.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		// This endpoint handles both 'restrict entity' and 'specialize entity'
		// cases.  URL builder produces correct URL based on 'restrictionKind'
		// field in RestrictEntityActionParams.
		restrictEntity: build.mutation<
			RestrictEntityActionResponse,
			RestrictEntityActionParams
		>({
			query: (args) => ({
				url: URLBuilders.restrictEntityAction.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		loadAttribute: build.mutation<
			LoadAttrActionResponse,
			LoadAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.loadAttribute.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		pivotEntity: build.mutation<
			PivotEntityResponse,
			PivotEntityParams
		>({
			query: (args) => ({
				url: URLBuilders.pivotEntity.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		splitAttribute: build.mutation<
			SplitAttrActionResponse,
			SplitAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.splitAttribute.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		translateValue: build.mutation<
			TranslateValueActionResponse,
			TranslateValueActionParams
		>({
			query: (args) => ({
				url: URLBuilders.translateValue.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		addComponent: build.mutation<
			AddComponentActionResponse,
			AddComponentActionParams
		>({
			query: (args) => ({
				url: URLBuilders.addComponent.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		moveAttribute: build.mutation<
			MoveAttrActionResponse,
			MoveAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.moveAttribute.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		aggregateAttribute: build.mutation<
			AggregateAttrActionResponse,
			AggregateAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.aggregateAttribute.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		deleteAttribute: build.mutation<
			DeleteAttrActionResponse,
			DeleteAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.deleteAttribute.buildSubpath(args),
				method: 'DELETE',
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		deleteEntity: build.mutation<DeleteEntityResponse, DeleteEntityParams>({
			query: (args) => ({
				url: URLBuilders.deleteEntity.buildSubpath(args as any),
				method: 'DELETE',
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		updateEntity: build.mutation<UpdateEntityResponse, UpdateEntityParams>({
			query: (args) => ({
				url: URLBuilders.updateEntity.buildSubpath(args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		describeDomain: build.mutation<
			DescribeDomainResponse,
			DescribeDomainParams
		>({
			query: (args) => ({
				url: URLBuilders.describeDomain.buildSubpath(args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['domains'],
		}),

		describeMetric: build.mutation<
			DescribeMetricResponse,
			DescribeMetricParams
		>({
			query: (args) => ({
				url: URLBuilders.describeMetric.buildSubpath(args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['metrics'],
		}),

		createAttribute: build.mutation<
			CreateAttrActionResponse,
			CreateAttrActionParams
		>({
			query: (args) => ({
				url: URLBuilders.createAttribute.buildSubpath(),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		meltEntity: build.mutation<MeltEntityResponse, MeltEntityParams>({
			query: (args) => ({
				url: URLBuilders.meltEntity.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		persistEntity: build.mutation<
			PersistEntityResponse,
			PersistEntityParams
		>({
			query: (args) => ({
				url: URLBuilders.persistEntity.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		importEntity: build.mutation<ImportEntityResponse, ImportEntityParams>({
			query: (args) => ({
				url: URLBuilders.importEntity.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['entities'],
		}),

		createConditional: build.mutation<
			CreateConditionalActionResponse,
			CreateConditionalActionParams
		>({
			query: (args) => ({
				url: URLBuilders.createConditional.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['entities', 'attributes'],
		}),

		requestPWReset: build.mutation<
			RequestPWResetResponse,
			RequestPWResetParams
		>({
			query: (args) => ({
				url: URLBuilders.requestPWReset.buildSubpath(),
				method: 'POST',
				body: args,
			}),
		}),

		resetPassword: build.mutation<
			ResetPasswordResponse,
			ResetPasswordParams
		>({
			query: (args) => ({
				url: URLBuilders.resetPassword.buildSubpath(),
				method: 'POST',
				body: args,
			}),
		}),

		completeRegistration: build.mutation<
			RegisterUserResponse,
			RegisterUserParams
		>({
			async queryFn(args, { dispatch }, extraOptions, baseQuery) {
				const queryResult = await baseQuery({
					url: URLBuilders.completeRegistration.buildSubpath(),
					method: 'POST',
					body: args,
				});

				// if registration succeeds, use response files to authenticate the user
				if (queryResult.data) {
					pipe(
						queryResult.data as RegisterUserResponse,
						userRegistered,
						dispatch
					);
				}

				return queryResult.error
					? { error: queryResult.error as FetchBaseQueryError }
					: { data: queryResult.data as RegisterUserResponse };
			},
		}),

		acceptTOS: build.mutation<{}, null>({
			query: () => ({
				url: URLBuilders.acceptTOS.buildSubpath(),
				method: 'POST',
			}),
			invalidatesTags: ['user'],
		}),

		completeOnboarding: build.mutation<{}, null>({
			query: () => ({
				url: URLBuilders.completeOnboarding.buildSubpath(),
				method: 'POST',
			}),
			invalidatesTags: ['user'],
		}),

		getUsersWaiting: build.query<GetUsersWaitingResponse, GetUsersWaitingParams>({
			query: (args) => ({
				url: URLBuilders.getUsersWaiting.buildSubpath(args),
			}),
			providesTags: ['invitations'],
		}),

		getUserInvites: build.query<GetUserInvitesResponse, GetUserInvitesParams>({
			query: (args) => ({
				url: URLBuilders.getUserInvites.buildSubpath(args),
			}),
			providesTags: ['invitations'],
		}),

		inviteUser: build.mutation<InviteUserResponse, InviteUserParams>({
			query: (args) => ({
				url: URLBuilders.inviteUser.buildSubpath(),
				method: 'POST',
				body: args,
			}),
			invalidatesTags: ['user'],
		}),

		withdrawInvite: build.mutation<WithdrawInviteResponse, WithdrawInviteParams>({
			query: (args) => ({
				url: URLBuilders.withdrawInvite.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['invitations'],
		}),

		resendInvite: build.mutation<ResendInviteResponse, ResendInviteParams>({
			query: (args) => ({
				url: URLBuilders.resendInvite.buildSubpath(args),
				method: 'POST',
			}),
			invalidatesTags: ['invitations'],
		}),

		grantAccess: build.mutation<GrantAccessResponse, GrantAccessParams>({
			query: (args) => ({
				url: URLBuilders.grantAccess.buildSubpath(args),
				method: 'POST',
			}),
		}),

		describeEntity: build.mutation<
			DescribeEntityResponse,
			DescribeEntityParams
		>({
			query: (args) => ({
				url: URLBuilders.describeEntity.buildSubpath(args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: ['entities'],
		}),

		getOrgAdmins: build.query<
			GetOrgAdminsResponse,
			GetOrgAdminsParams
		>({
			query: (args) => ({
				url: URLBuilders.getOrgAdmins.buildSubpath(args),
			}),
			providesTags: ['orgAdmins'],
		}),

		createOrgAdmin: build.mutation<
			CreateOrgAdminResponse,
			CreateOrgAdminParams
		>({
			query: (args) => ({
				url: URLBuilders.createOrgAdmin.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['orgAdmins'],
		}),

		getOrgGovernors: build.query<
			GetOrgGovernorsResponse,
			GetOrgGovernorsParams
		>({
			query: (args) => ({
				url: URLBuilders.getOrgGovernors.buildSubpath(args),
			}),
			providesTags: ['orgGovernors'],
		}),

		getOrgEngineers: build.query<
			GetOrgGovernorsResponse,
			GetOrgGovernorsParams
		>({
			query: (args) => ({
				url: URLBuilders.getOrgEngineers.buildSubpath(args),
			}),
			providesTags: ['orgEngineers'],
		}),

		getOrgPublishers: build.query<
			GetOrgPublishersResponse,
			GetOrgPublishersParams
		>({
			query: (args) => ({
				url: URLBuilders.getOrgPublishers.buildSubpath(args),
			}),
			providesTags: ['orgPublishers'],
		}),
		getOrgContacts: build.query<
			GetOrgContactsResponse,
			GetOrgContactsParams
		>({
			query: (args) => ({
				url: URLBuilders.getOrgContacts.buildSubpath(args),
			}),
			providesTags: ['orgContacts'],
		}),

		getOrgMaintainers: build.query<
			GetOrgMaintainersResponse,
			GetOrgMaintainersParams
		>({
			query: (args) => ({
				url: URLBuilders.getOrgMaintainers.buildSubpath(args),
			}),
			providesTags: ['orgMaintainers'],
		}),

		getOrgFunders: build.query<
			GetOrgFundersResponse,
			GetOrgFundersParams
		>({
			query: (args) => ({
				url: URLBuilders.getOrgFunders.buildSubpath(args),
			}),
			providesTags: ['orgFunders'],
		}),

		getOrgRightsHolders: build.query<
			GetOrgRightsHoldersResponse,
			GetOrgRightsHoldersParams
		>({
			query: (args) => ({
				url: URLBuilders.getOrgRightsHolders.buildSubpath(args),
			}),
			providesTags: ['orgRightsHolders'],
		}),

		getOrgReporters: build.query<
			GetOrgReportersResponse,
			GetOrgReportersParams
		>({
			query: (args) => ({
				url: URLBuilders.getOrgReporters.buildSubpath(args),
			}),
			providesTags: ['orgReporters'],
		}),

		createOrgGovernor: build.mutation<
			CreateOrgGovernorResponse,
			CreateOrgGovernorParams
		>({
			query: (args) => ({
				url: URLBuilders.createOrgGovernor.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['orgGovernors'],
		}),

		createOrgEngineer: build.mutation<
			CreateOrgEngineerResponse,
			CreateOrgEngineerParams
		>({
			query: (args) => ({
				url: URLBuilders.createOrgEngineer.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['orgEngineers'],
		}),

		createOrgMaintainer: build.mutation<
			CreateOrgMaintainerResponse,
			CreateOrgMaintainerParams
		>({
			query: (args) => ({
				url: URLBuilders.createOrgMaintainer.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['orgMaintainers'],
		}),

		createOrgFunder: build.mutation<
			CreateOrgFunderResponse,
			CreateOrgFunderParams
		>({
			query: (args) => ({
				url: URLBuilders.createOrgFunder.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['orgFunders'],
		}),

		createOrgReporter: build.mutation<
			CreateOrgReporterResponse,
			CreateOrgReporterParams
		>({
			query: (args) => ({
				url: URLBuilders.createOrgReporter.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['orgReporters'],
		}),

		createOrgContact: build.mutation<
			CreateOrgContactResponse,
			CreateOrgContactParams
		>({
			query: (args) => ({
				url: URLBuilders.createOrgContact.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['orgContacts'],
		}),

		createOrgRightsHolder: build.mutation<
			CreateOrgRightsHolderResponse,
			CreateOrgRightsHolderParams
		>({
			query: (args) => ({
				url: URLBuilders.createOrgRightsHolder.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['orgRightsHolders'],
		}),

		createOrgPublisher: build.mutation<
			CreateOrgPublisherResponse,
			CreateOrgPublisherParams
		>({
			query: (args) => ({
				url: URLBuilders.createOrgPublisher.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['orgPublishers'],
		}),

		getOrgMembers: build.query<
			GetOrgMembersResponse,
			GetOrgMembersParams
		>({
			query: (args) => ({
				url: URLBuilders.getOrgMembers.buildSubpath(args),
			}),
			providesTags: ['orgMembers'],
		}),

		createOrgMember: build.mutation<
			CreateOrgMemberResponse,
			CreateOrgMemberParams
		>({
			query: (args) => ({
				url: URLBuilders.createOrgMember.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['orgMembers'],
		}),

		getDomainMembers: build.query<
			GetDomainMembersResponse,
			GetDomainMembersParams
		>({
			query: (args) => ({
				url: URLBuilders.getDomainMembers.buildSubpath(args),
			}),
			providesTags: ['domainMembers'],
		}),

		createDomainMember: build.mutation<
			CreateDomainMemberResponse,
			CreateDomainMemberParams
		>({
			query: (args) => ({
				url: URLBuilders.createDomainMember.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['domainMembers'],
		}),

		getDomainStewards: build.query<
			GetDomainStewardsResponse,
			GetDomainStewardsParams
		>({
			query: (args) => ({
				url: URLBuilders.getDomainStewards.buildSubpath(args),
			}),
			providesTags: ['domainStewards'],
		}),

		createDomainSteward: build.mutation<
			CreateDomainStewardResponse,
			CreateDomainStewardParams
		>({
			query: (args) => ({
				url: URLBuilders.createDomainSteward.buildSubpath(args),
				body: args.body,
				method: 'POST',
			}),
			invalidatesTags: ['domainStewards'],
		}),

		removeUserRole: build.mutation<
			RemoveUserRoleResponse,
			RemoveUserRoleParams
		>({
			query: (args) => ({
				// TODO: figure out this type signature
				// @ts-ignore
				url: URLBuilders.removeUserRole.buildSubpath(args),
				method: 'DELETE',
			}),
			invalidatesTags: (res, err, { role, parentObjectType }) => [
				memberMgmtInvalidator(parentObjectType, role),
			],
		}),

		createLocation: build.mutation<
			CreateLocationActionResponse,
			CreateLocationActionParams
		>({
			query: (args) => ({
				url: URLBuilders.createLocation.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['attributes'],
		}),

		createEvent: build.mutation<
			CreateEventActionResponse,
			CreateEventActionParams
		>({
			query: (args) => ({
				url: URLBuilders.createEvent.buildSubpath(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['attributes'],
		}),

		getAttributeLineage: build.query<
			GetAttributeLineageResponse,
			GetAttributeLineageParams
		>({
			query: (args) => ({
				url: URLBuilders.getAttributeLineage.buildSubpath(args),
			}),
			providesTags: ['attrLineage'],
		}),

		createShare: build.mutation<
			CreateShareResponse,
			CreateShareParams
		>({
			query: (args) => ({
				method: 'POST',
				url: URLBuilders.createShare.buildSubpath(args),
				body: args.body,
			}),
			// async onQueryStarted(args, { queryFulfilled, dispatch }) {
			// 	try {
			// 		const {
			// 			data: { linkedInTokenExpiration },
			// 		} = await queryFulfilled;
			//
			// 		//  if this succeeds, we want to update user object without invalidating network cache,
			// 		// since that could trigger a huge amount of re-rendering.
			// 		dispatch(setLinkedInTokenExpiry(linkedInTokenExpiration));
			// 	} catch (e) {
			// 		return;
			// 	}
			// },
		}),

		createLinkedInShare: build.mutation<
			CreateLinkedInShareResponse,
			CreateLinkedInShareParams
		>({
			query: (args) => ({
				method: 'POST',
				url: URLBuilders.createLinkedInShare.buildSubpath(),
				body: args.body,
			}),
			async onQueryStarted(args, { queryFulfilled, dispatch }) {
				try {
					const {
						data: { linkedInTokenExpiration },
					} = await queryFulfilled;

					//  if this succeeds, we want to update user object without invalidating network cache,
					// since that could trigger a huge amount of re-rendering.
					dispatch(setLinkedInTokenExpiry(linkedInTokenExpiration));
				} catch (e) {
					return;
				}
			},
		}),
	}),
});

export const {
	useGetUsersQuery,
	useGetUserProfileQuery,
	useGetUserInvitesQuery,
	useGetUsersWaitingQuery,
	useWithdrawInviteMutation,
	useResendInviteMutation,
	useGrantAccessMutation,
	useGetLandingQuery,
	useGetAttributeLineageQuery,
	useCreateEventMutation,
	useCreateLocationMutation,
	useRemoveUserRoleMutation,
	useCreateOrgAdminMutation,
	useCreateOrgGovernorMutation,
	useCreateOrgEngineerMutation,
	useCreateOrgPublisherMutation,
	useCreateOrgMaintainerMutation,
	useCreateOrgReporterMutation,
	useCreateOrgContactMutation,
	useCreateOrgFunderMutation,
	useCreateOrgRightsHolderMutation,
	useCreateDomainStewardMutation,
	useCreateDomainMemberMutation,
	useGetDomainMembersQuery,
	useGetDomainStewardsQuery,
	useGetOrgMembersQuery,
	useGetOrgGovernorsQuery,
	useGetOrgAdminsQuery,
	useGetOrgEngineersQuery,
	useGetOrgPublishersQuery,
	useGetOrgContactsQuery,
	useGetOrgMaintainersQuery,
	useGetOrgFundersQuery,
	useGetOrgRightsHoldersQuery,
	useGetOrgReportersQuery,
	useDescribeEntityMutation,
	useCreateAnswerMutation,
	useInviteUserMutation,
	useAcceptTOSMutation,
	useCompleteRegistrationMutation,
	useImportEntityMutation,
	useAuthenticateUserMutation,
	useGetUserQuery,
	useReserveUserMutation,
	useUpdateUserMutation,
	useLogoutMutation,
	useGetEntitiesQuery,
	useGetEntityQuery,
	useCreateEntityMutation,
	useGetOrgsQuery,
	useGetOrgGraphQuery,
	useGetDomainsQuery,
	useGetDomainGraphQuery,
	useCreateDomainMutation,
	useGetOrgQuery,
	useGetAttributeQuery,
	useGetAttributesQuery,
	useGetSourceSummariesQuery,
	useGetLiveDataCatalogsQuery,
	useGetLiveDataSetsQuery,
	useGetDataSetPreviewQuery,
	useCreateSourceMutation,
	useProfileSourceMutation,
	useTestSourceMutation,
	useCreateCatalogMutation,
	useCacheCatalogMutation,
	useProfileCatalogMutation,
	useTestCatalogMutation,
	useDropCatalogMutation,
	useCacheDatasetMutation,
	useUpdateDatasetMutation,
	useProfileDatasetMutation,
	useTestDatasetMutation,
	useCreateDataLoadMutation,
	useDropDatasetMutation,
	useUpdateColumnMutation,
	useProfileColumnMutation,
	useTestColumnMutation,
	useDropColumnMutation,
	useImportCatalogToDomainMutation,
	useImportDatasetToDomainMutation,
	useGenDomainFromCatalogMutation,
	useGenDomainFromDatasetMutation,
	useFollowDomainMutation,
	useUnfollowDomainMutation,
	useUpdateDomainMutation,
	useCreateOrgMutation,
	useDeleteOrgMutation,
	useDeleteResourceMutation,
	useUpdateOrgMutation,
	useFollowOrgMutation,
	useUnfollowOrgMutation,
	useFollowEntityMutation,
	useUnfollowEntityMutation,
	useIdentifyEntityMutation,
	useGetDomainAttributesQuery,
	useRelateAttrActionMutation,
	useAppendAttrActionMutation,
	useDescribeAttrActionMutation,
	useGetAttrNeighborsQuery,
	useOperateAttrMutation,
	useRestrictEntityMutation,
	useGetRestrictionsQuery,
	useGetDataProfilesQuery,
	useGetDataTestsQuery,
	useGetDataErrorsQuery,
	useGetDataStatisticsQuery,
	useGetDataLoadsQuery,
	useGetTasksQuery,
	useGetAttributeMembersQuery,
	useGetAttributeConditionsQuery,
	useGetAttributeSourcesQuery,
	useLoadAttributeMutation,
	useMoveAttributeMutation,
	usePivotEntityMutation,
	useSplitAttributeMutation,
	useTranslateValueMutation,
	useAddComponentMutation,
	useAggregateAttributeMutation,
	useDeleteAttributeMutation,
	useDeleteEntityMutation,
	useUpdateEntityMutation,
	useCreateAttributeMutation,
	useMeltEntityMutation,
	usePersistEntityMutation,
	useGetDomainQuery,
	useGetEntityGraphQuery,
	useDeleteDomainMutation,
	useCreateConditionalMutation,
	useDescribeDomainMutation,
	useGetMetricQuery,
	useGetMetricsQuery,
	useUpdateMetricMutation,
	useDeleteMetricMutation,
	useFollowMetricMutation,
	useUnfollowMetricMutation,
	useCreateMetricMutation,
	useDescribeMetricMutation,
	useGetInsightQuery,
	useGetInsightsQuery,
	useUpdateInsightMutation,
	useDeleteInsightMutation,
	useFollowInsightMutation,
	useUnfollowInsightMutation,
	useCreateInsightMutation,
	useCreateIndividualMutation,
	useRequestPWResetMutation,
	useResetPasswordMutation,
	useGetIndividualsQuery,
	useCreateQuestionMutation,
	useCreateFeedbackMutation,
	useUpdateAccessibilityMutation,
	useUpdateSettingsMutation,
	useGetQuestionsQuery,
	useAcceptAnswerMutation,
	useCreateCommentMutation,
	useCreateReactionMutation,
	useGetCommentsQuery,
	useGetActivityQuery,
	useCreateOrgMemberMutation,
	useCreateActivityMutation,
	useCompleteOnboardingMutation,
	useCreateLinkedInShareMutation,
	useCreateShareMutation,
	useGetIndividualQuery,
	useFollowUserMutation,
	useUnfollowUserMutation,
	useFollowResourceMutation,
	useUnfollowResourceMutation,
	useGetMessagesQuery,
	useGetNotificationsQuery,
	useGetReactionsQuery,
	useGetViewersQuery,
	useGetFollowersQuery,
	useGetFollowingQuery,
} = futureModelApi;
