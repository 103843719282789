import useCurrentUser from '../hooks/useUser';
import Button from 'common/buttons/Button';
import FlexContainer from 'common/FlexContainer';
import Typography from 'common/text/Typography';
import theme from 'common/theme/theme';
import {useCompleteOnboardingMutation} from 'features/api';
import {extractQueryErrMessage} from 'features/api/helpers';
import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {backgroundColor} from "html2canvas/dist/types/css/property-descriptors/background-color";

const StyledSlideButton = styled(Button)<{ side: 'left' | 'right' }>`
	border-radius: 0;
	padding: ${(p) => p.theme.spacing(1)};
	min-width: ${(p) => p.theme.spacing(12)};

	&:hover {
		border: none;
		color: white;
		background-color: ${(p) => p.theme.palette.primary.main};
	}
`;

const StyledOnboardingContainer = styled.div`
	margin: auto;
	display: grid;
	max-width: 1200px;
	width: 80%;
	align-items: center;
	grid-template-rows: min-content min-content;
`;

const StyledMain = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	justify-items: space-evenly;
	align-items: center;
	align-content: center;
	min-height: 600px;
	max-height: 100%;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	padding: 0;	
	margin: 0;	
	background-color: ${(p) => p.theme.palette.darkBaby};
`;


export const OnboardingBase: FunctionComponent<{ images: string[] }> = ({
                                                                            images,
                                                                        }) => {
    const {isOnboarded} = useCurrentUser();

    const navigate = useNavigate();

    const nodeRef = useRef();

    const [currentSlide, setCurrentSlide] = useState(0);

    const isFinalSlide = currentSlide === images.length - 1;

    const [onboard, onboardResult] = useCompleteOnboardingMutation();

    // We only want to push an already-onboarded user to home IF their
    // onboarded status is a result of successfully completing onboarding for the
    // first time. Otherwise they may be here for informational purposes, and we want
    // to let them explore.
    useEffect(() => {
        if (isOnboarded && onboardResult.isSuccess) {
            navigate('/');
        }
    }, [isOnboarded, navigate, onboardResult.isSuccess]);

    const btnText = onboardResult.isSuccess
        ? 'Success!'
        : onboardResult.isLoading
            ? 'Preparing...'
            : isFinalSlide
                ? isOnboarded
                    ? 'Home'
                    : 'Get Started'
                : 'Next';

    return (
        <div style={{height: '100vh', width: '100vw'}}>
            <FlexContainer style={{height: '100%', width: '100%',}}>
                <StyledMain>
                        <FlexContainer gap='1rem' flexDirection='column'>
                            <div style={{position: 'relative'}}>
                                <img
                                    src={images[currentSlide]}
                                    ref={nodeRef as any}
                                    alt=""
                                    style={{width: '100%', height: 'auto'}}
                                    data-testid={`img-${currentSlide}`}
                                />
                            </div>
                            <FlexContainer alignItems='center' justifyContent='space-between'>
                                <StyledSlideButton
                                    side="left"
                                    style={{backgroundColor: theme.palette.darkerBaby}}
                                    onClick={() => {
                                        if (currentSlide > 0) {
                                            setCurrentSlide((p) => p - 1);
                                        }
                                    }}
                                >
                                    <Typography variant={'h5'} color={'primary'}>
                                        Previous
                                    </Typography>
                                </StyledSlideButton>
                                <FlexContainer gap={'1rem'}>
                                        <FlexContainer
                                            style={{
                                                backgroundColor: theme.palette.darkerBaby,
                                                padding: '8px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Typography variant={'h5'} color={'primary'}>
                                                {currentSlide + 1} of {images.length}
                                            </Typography>
                                        </FlexContainer>
                                        <StyledSlideButton
                                            side="right"
                                            style={{backgroundColor: theme.palette.darkerBaby,                                                 padding: '8px',}}
                                            onClick={() => {
                                                if (!isFinalSlide) {
                                                    return setCurrentSlide((p) => p + 1);
                                                }

                                                if (isOnboarded) {
                                                    return navigate('/');
                                                }

                                                onboard(null);
                                            }}
                                        >
                                            <Typography variant={'h5'}
                                                        color={'primary'}>
                                                {btnText}
                                            </Typography>
                                        </StyledSlideButton>
                                    </FlexContainer>
                            </FlexContainer>
                            {onboardResult.error && (
                                <Typography
                                    paragraph
                                    color="error"
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {extractQueryErrMessage(onboardResult.error)}
                                </Typography>
                            )}
                        </FlexContainer>
                </StyledMain>
            </FlexContainer>
        </div>
    );
};

const imgs = Array(4)
    .fill(null)
    .map(
        (_, i) =>
            `https://storage.googleapis.com/davis-associates.appspot.com/onboarding/${
                i + 1
            }.png`
    );

const Onboarding: FunctionComponent = () => <OnboardingBase images={imgs}/>;

export default Onboarding;
