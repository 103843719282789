import { MoveAttrFormValues, MoveAttrPayload } from './moveAttributeTypes';

export const moveAttrFormDefaults = (entityId: number): MoveAttrFormValues => ({
	entityId: entityId,
});

export const moveAttrFormToPayload = (
	formValues: MoveAttrFormValues
): MoveAttrPayload => {
	return formValues;
};
