import React, {FunctionComponent} from "react";
import SubtleSelect from "./SubtleSelect";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {harvestErrors} from "./SubtleTextBox";

// class MetricTypes(StrEnum):
//     Count = 'count'
//     Sum = 'sum'
//     Average = 'average'
//     Median = 'median'
//     Percentile = 'percentile'
//     Max = 'max'
//     Min = 'min'
//     List = 'string_agg'

const MetricTypeSelect: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister
    }
    ) => {
     // What type of metric is this?
    return <SubtleSelect
        inline={true}
        label={`Type`}
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('metricType')}
        isDirty={Boolean(formState.dirtyFields.metricType)}
        {...validatingRegister('metricType')}
    >
        <option value="identity">Identity</option>
        <option value="relation">Relation</option>
        <option value="category">Category</option>
        <option value="quantity">Quantity</option>
        <option value="event">Event</option>
        <option value="location">Location</option>
        <option value="media">Media</option>
    </SubtleSelect>;
}


export default MetricTypeSelect;
