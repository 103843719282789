import AttributeMeta from './AttributeMeta';

class CategoryMeta extends AttributeMeta {
	uniqueValues: Set<string> = new Set<string>();

	ingest(value: unknown, idx: number) {
		super.ingest(value, idx);

		if (value === undefined || value === null) {
			// parent class handles this case--do nothing
			// particularly important to return here, b/c
			// we do NOT want null or undefined to be considered
			// a unique value for the category.
			return;
		}

		if (typeof value === 'string') {
			this.uniqueValues.add(value);
		}
	}
}

export default CategoryMeta;
