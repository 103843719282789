import {
	AddComponentFormValues,
	AddComponentPayload,
} from './addComponentTypes';

export const addComponentFormDefaults = (): AddComponentFormValues => ({
	singular: '',
	plural: '',
	definition: '',
	latitudeId: 0,
	longitudeId: 0,
});

export const addComponentFormToPayload = (
	formValues: AddComponentFormValues
): AddComponentPayload => {
	return {
		...formValues,
		definition: formValues.definition === '' ? null : formValues.definition,
	};
};
