import {ActivitySubject, BaseActivity} from '../types/activityTypes';
import {faHeartPulse} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {List, ListItem, ListItemContent, ListItemAvatar} from 'common/List';
import Typography from 'common/text/Typography';
import {useGetActivityQuery, useGetDomainGraphQuery} from 'features/api';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import {
    FunctionComponent,
    useContext,
    useEffect,
    useRef,
    useMemo,
    useState
} from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
} from 'react-accessible-accordion';
import {ID} from 'react-accessible-accordion/dist/types/components/ItemContext';
import {CSSProperties} from 'styled-components';
import {FixedSizeList} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import {skipToken} from "@reduxjs/toolkit/query";
import {
    useLoadNextPage,
    ActivityContextProvider,
    ActivityContext
} from "../state/ActivityContext";

interface Overrides {
    list?: CSSProperties;
}

interface ActivityListProps {
    objectType: ActivitySubject;
    objectId: number;
    overrides?: Overrides;
    // hasNextPage: boolean;
    /** Are there more items to load? (This information comes from the most recent API request.) */
    // isNextPageLoading: boolean;
    /** Are we currently loading a page of items? (This may be an in-flight flag in your Redux store for example.) */
    // list: any[];
    /** List of items loaded so far */
    // loadNextPage: () => void;
    /** Callback function (eg. Redux action-creator) responsible for loading the next page of items */
}

// const ExampleWrapper: FunctionComponent<{
//     loadNextPage: () => void,
//     props?: any,
// }> = ({
//           // Are there more items to load?
//           // (This information comes from the most recent API request.
//
//           // Are we currently loading a page of items?
//           // (This may be an in-flight flag in your Redux store for example.)
//
//           // Array of items loaded so far.
//
//           // Callback function responsible for loading the next page of items.
//           loadNextPage,
//           props
//       }) => {
//     // If there are more items to be loaded then add an extra row to hold a loading indicator.
//
//     // const {
//     //     hasNextPage,
//     //     isNextPageLoading,
//     //     items
//     // } = useContext(ActivityContext)[0];
//     // const itemCount = hasNextPage ? items.length + 1 : items.length;
//
//     // Only load 1 page of items at a time.
//     // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
//     // const loadMoreItems = isNextPageLoading ? () => {
//     // } : loadNextPage;
//
//     // Every row is loaded except for our loading indicator row.
//     // const isItemLoaded = (index: number) => !hasNextPage || index < items.length;
//
//     // const infiniteLoaderRef = useRef<InfiniteLoader>(null);
//
//
//     // const {nextPage, items} = useContext(ActivityContext)[0];
//     const [currentPage, setCurrentPage] = useState(0)
//     const [items, setItems] = useState([])
//     // const [loadNextPage, setloadNextPage] = useState(false)
//
//     // const queryRes = useGetActivityQuery(
//     //     loadNextPage ?
//     //         : skipToken
//     // );
//
//     // useLoadNextPage(queryRes);
//
//     const getNextPage = (page) => {
//         return useGetActivityQuery(
//             {
//                 objectType,
//                 objectId: objectId,
//                 page: page,
//                 perPage: 10
//             }
//         )
//         // return queryRes;
//     };
//     // useEffect(() => {
//     //     setloadNextPage(false);
//     // }, [queryRes.isSuccess])
//
//     // Render an item or a loading indicator.
//     const Item: FunctionComponent<{ index: number, style: any, data: any }> = ({
//                                                                                    index,
//                                                                                    style,
//                                                                                    data
//                                                                                }) => {
//         let content;
//         const [expanded, setExpanded] = useState<ID[]>([]);
//         const item = items[index];
//         if (!isItemLoaded(index)) {
//             content = "Loading...";
//         } else {
//             content = item.action;
//         }
//         return isItemLoaded(index)
//             ? <AccordionItem key={item._id} uuid={item._id}>
//                 <AccordionItemHeading>
//                     <AccordionItemButton>
//                         <ListItem button>
//                             <ListItemAvatar>
//                                 <FontAwesomeIcon icon={faHeartPulse}/>
//                             </ListItemAvatar>
//                             <ListItemContent
//                                 truncate={expanded.every((v) => v !== item._id)}>
//                                 <Typography color={'oldManGray'} variant={'h5'}>
//                                     {content}
//                                 </Typography>
//                             </ListItemContent>
//                         </ListItem>
//                     </AccordionItemButton>
//                 </AccordionItemHeading>
//             </AccordionItem>
//             : <AccordionItem>
//                 <AccordionItemHeading>
//                     <AccordionItemButton>
//                         <ListItem button>
//                             <ListItemAvatar>
//                                 <FontAwesomeIcon icon={faHeartPulse}/>
//                             </ListItemAvatar>
//                             <ListItemContent>
//                                 <Typography color={'oldManGray'} variant={'h5'}>
//                                     {content}
//                                 </Typography>
//                             </ListItemContent>
//                         </ListItem>
//                     </AccordionItemButton>
//                 </AccordionItemHeading>
//             </AccordionItem>
//     };
//
//     return (
//         <InfiniteScroll
//             dataLength={items.length} //This is important field to render the next data
//             next={fetchData}
//             hasMore={true}
//             loader={<h4>Loading...</h4>}
//             endMessage={
//                 <p style={{textAlign: 'center'}}>
//                     <b>Yay! You have seen it all</b>
//                 </p>
//             }
//             // below props only if you need pull down functionality
//             refreshFunction={this.refresh}
//             pullDownToRefresh
//             pullDownToRefreshThreshold={50}
//             pullDownToRefreshContent={
//                 <h3 style={{textAlign: 'center'}}>&#8595; Pull down to
//                     refresh</h3>
//             }
//             releaseToRefreshContent={
//                 <h3 style={{textAlign: 'center'}}>&#8593; Release to
//                     refresh</h3>
//             }
//         >
//             {items}
//         </InfiniteScroll>
//     )
//
//     // return (
//     //   <InfiniteLoader
//     //     isItemLoaded={isItemLoaded}
//     //     itemCount={itemCount}
//     //     loadMoreItems={loadMoreItems}
//     //   ref={infiniteLoaderRef}
//     //   >
//     //     {({ onItemsRendered, ref }) => (
//     //       <FixedSizeList
//     //         itemCount={itemCount}
//     // 	  layout={'vertical'}
//     //         onItemsRendered={(props) => {
//     //           // const style =
//     //             // listRef.current &&
//     //             // @ts-ignore private method access
//     //             // listRef.current._getItemStyle(props.overscanStartIndex)
//     //           // setTop((style && style.top) || 0)
//     //
//     //           // Call the original callback
//     // 		  rest.onItemsRendered && rest.onItemsRendered(props)
//     //         }}
//     //         ref={ref}
//     //         {...props}
//     //       >
//     //         {Item}
//     //       </FixedSizeList>
//     //     )}
//     //   </InfiniteLoader>
//     // );
// }


// export default useGraphDataLoader;
// nextPage, existingItems, setItems
//, setItems: (items: BaseActivity[]) => void
// existingItems: BaseActivity[],
// nextPage: number,
const useInfiniteScroll = (
    currentPage: number,
    objectType: any,
    objectId: number,
) => {

    const pageSize = 10;
    const [totalItems, setTotalItems] = useState<number>(1);
    // const currentPage = // something calculated from ScrollPosition

    const lastResult = useGetActivityQuery(
        currentPage === 1 ? skipToken :
        {
            objectType,
            objectId: objectId,
            page: currentPage - 1,
            perPage: pageSize
        }); // don't fetch pages before 0
    const currentResult = useGetActivityQuery(
        {
            objectType,
            objectId: objectId,
            page: currentPage,
            perPage: pageSize
        });

    const nextResult =  useGetActivityQuery(
       {
            objectType,
            objectId: objectId,
            page: currentPage + 1,
            perPage: pageSize
        }
        );



    const combined = useMemo(() => {
      const arr = new Array(currentPage === 1 ? pageSize * (currentPage + 1) : pageSize * (currentPage + 2))
      for (const data of [lastResult.data, currentResult.data, nextResult.data]) {
        if (data) {
            const {page, perPage, totalItems, totalPages} = data._meta
          arr.splice(page + 1 * perPage, data.items.length, ...data.items)
        }
      }
      setTotalItems(arr.length);
      return arr
    }, [pageSize, currentPage, lastResult.data, currentResult.data, nextResult.data])

    return [
        combined,
        totalItems,
        lastResult.isLoading,
        currentResult.isLoading,
        nextResult.isLoading
    ]

      // If there are more items to be loaded then add an extra row to hold a loading indicator.
  // const itemCount = hasNextPage ? items.length + 1 : items.length;
  //
  //   // Only load 1 page of items at a time.
  //   // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  //   const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
  //
  //   const isItemLoaded = index => !hasNextPage || index < items.length;
  //
  //   // work with `combined` here
    //
    // const {data: nextBatch, isSuccess} =
    //
    // const [localItems, setLocalItems] = useState<BaseActivity[]>([]);
    //
    // useEffect(() => {
    //     if (!!nextBatch && !!nextBatch.items && isSuccess) {
    //         setLocalItems((p) => [...p].concat(nextBatch.items))
    //         setItems(localItems);
    //     }
    // }, [nextBatch, isSuccess])

    // return <InfiniteScroll
    //         dataLength={combined.length} //This is important field to render the next data
    //         next={() => nextData()}
    //         hasMore={true}
    //         loader={<h4>Loading...</h4>}
    //         endMessage={
    //             <p style={{textAlign: 'center'}}>
    //                 <b>Yay! You have seen it all</b>
    //             </p>
    //         }
    //         // below props only if you need pull down functionality
    //         // refreshFunction={this.refresh}
    //         // pullDownToRefresh
    //         // pullDownToRefreshThreshold={50}
    //         // pullDownToRefreshContent={
    //         //     <h3 style={{textAlign: 'center'}}>&#8595; Pull down to
    //         //         refresh</h3>
    //         // }
    //         // releaseToRefreshContent={
    //         //     <h3 style={{textAlign: 'center'}}>&#8593; Release to
    //         //         refresh</h3>
    //         // }
    //     >
    //         {
    //             combined.map((item, index) => {
    //                 return
    //             })
    //         }
    //     </InfiniteScroll>

}
const Activities: FunctionComponent<ActivityListProps> = (
    {
        overrides,
        objectType,
        objectId,
    }
) => {


    const [currentPage, setCurrentPage] = useState(1)
    // const [items, setItems] = useState<BaseActivity[]>([])
    // const [loadNextPage, setloadNextPage] = useState(false)

    // const queryRes = useGetActivityQuery(
    //     loadNextPage ?
    //         : skipToken
    // );

    // useLoadNextPage(queryRes);

    // const getNextPage = (page: number) => {
    //     return
    //     // return queryRes;
    // };


    // useEffect(() => {
    //     if (!!nextBatch && !!nextBatch.items && isSuccess) {
    //         setItems((p: any) => {
    //             return [...p].concat(nextBatch.items);
    //         });
    //     }
    // }, [nextBatch, isSuccess])

    const [items, totalItems, lastPageLoading, currentPageLoading, nextPageLoading] = useInfiniteScroll(currentPage, objectType, objectId);

    // Render an item or a loading indicator.
    const Item: FunctionComponent<{ index: number, style: any, data: any }> = (
        {
            index,
            style,
            data=items
        }
    ) => {
        // let content;
        // const [expanded, setExpanded] = useState<ID[]>([]);
        // const item = items[index];
        // if (!isItemLoaded(index)) {
        //     content = "Loading...";
        // } else {
        //     content = item.action;
        // }
        // return isItemLoaded(index)
        console.log(data)
        return <div key={index}>
            {!!data && !!data[index] && data[index]._id}
        </div>
        // return <AccordionItem key={item._id} uuid={item._id}>
        //         <AccordionItemHeading>
        //             <AccordionItemButton>
        //                 <ListItem button>
        //                     <ListItemAvatar>
        //                         <FontAwesomeIcon icon={faHeartPulse}/>
        //                     </ListItemAvatar>
        //                     <ListItemContent
        //                         truncate={expanded.every((v) => v !== item._id)}>
        //                         <Typography color={'oldManGray'} variant={'h5'}>
        //                             {content}
        //                         </Typography>
        //                     </ListItemContent>
        //                 </ListItem>
        //             </AccordionItemButton>
        //         </AccordionItemHeading>
        //     </AccordionItem>
            // : <AccordionItem>
            //     <AccordionItemHeading>
            //         <AccordionItemButton>
            //             <ListItem button>
            //                 <ListItemAvatar>
            //                     <FontAwesomeIcon icon={faHeartPulse}/>
            //                 </ListItemAvatar>
            //                 <ListItemContent>
            //                     <Typography color={'oldManGray'} variant={'h5'}>
            //                         {content}
            //                     </Typography>
            //                 </ListItemContent>
            //             </ListItem>
            //         </AccordionItemButton>
            //     </AccordionItemHeading>
            // </AccordionItem>
    };

    const isNextPageLoading = lastPageLoading || currentPageLoading || nextPageLoading;
    const loadMoreItems = isNextPageLoading ? () => {} : () => setCurrentPage((p) => p + 1);


    return (
        <InfiniteLoader
          isItemLoaded={(index) => index < totalItems}
          itemCount={totalItems as number + 1}
          loadMoreItems={() => loadMoreItems()}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              itemCount={totalItems as number  + 1}
              onItemsRendered={onItemsRendered}
              ref={ref}
              itemSize={20}
              height={300}
              width={300}
            >
                {Item}
            </FixedSizeList>
          )}
        </InfiniteLoader>

              // {/*{...otherListProps}*/}
    )
    // return <ExampleWrapper
    //     // hasNextPage={hasNextPage}
    //     // isNextPageLoading={isNextPageLoading}
    //     // items={items}
    //     props={{height: 1000, width: 250, itemSize: 30}}
    //     loadNextPage={() => getNextPage()}
    // />
};
// {/*</DisplayOnLoad>*/}
//
// 			{/*			<Accordion*/}
// 			{/*	allowZeroExpanded={true}*/}
// 			{/*	onChange={(ids) => setExpanded(ids)}*/}
// 			{/*>*/}
// 			{/*	<List*/}
// 			{/*		style={{*/}
// 			{/*			maxHeight: '40vh',*/}
// 			{/*			overflowY: 'auto',*/}
// 			{/*			maxWidth: '400px',*/}
// 			{/*			...overrides?.list,*/}
// 			{/*		}}*/}
// 			{/*	>*/}
// 			{/*		<DisplayOnLoad*/}
// 			{/*			{...activityLoadState}*/}
// 			{/*			toggleOnRefetch={true}*/}
// 			{/*			toggleOnUninitialized={false}*/}
// 			{/*		>*/}
// 			{/*<DisplayOnLoad isLoading={} isUninitialized={} isSuccess={} isError={}>*/}


const ActivityList: FunctionComponent<ActivityListProps> = ({
                                                                overrides,
                                                                objectType,
                                                                objectId,
                                                            }) => {

    return (
        <ActivityContextProvider>
            <div style={{height: '300px'}}>

            <Activities overrides={overrides} objectType={objectType}
                        objectId={objectId}/>
            </div>
        </ActivityContextProvider>
    )
}

export default ActivityList;
