import Button from '../../../common/buttons/Button';
import {FormResults} from '../../../common/Form';
import {useRelateAttrActionMutation} from '../../api';
import {mergeErrorStates} from '../../api/helpers';
import {BaseAttribute} from '../../ontology/types/attributeTypes';
import TargetAttrSelect from '../../../common/inputs/TargetAttrSelect';
import {AttrActionFormProps} from '../common/commonTypes';
import useAttrActionData from '../hooks/useAttrActionData';
import {
    relateAttrFormDefaults,
    relateAttrFormToPayload,
    genRelateAttrFieldLabels,
} from './relateAttributeHelpers';
import {RelateAttrFormValues} from './relateAttributeTypes';
import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import SingularInput from "../../../common/inputs/SingularInput";
import PluralInput from "../../../common/inputs/PluralInput";
import DefinitionInput from "../../../common/inputs/DefinitionInput";
import IsExclusiveInput from "../../../common/inputs/IsExclusiveInput";
import IsDependentInput from "../../../common/inputs/IsDependentInput";
import RelationTypeSelect from "../../../common/inputs/RelationTypeSelect";
import useModalType from "../../HUD/hooks/useModalType";

const RelateAttributeForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
    const {objectId: _id, entity: sourceEntity, canEdit} = modalProps;
    const {isLoading, domainAttrs, domainAttrQueryResult} = useAttrActionData();

    const {
        handleSubmit,
        register,
        formState,
        resetField
    } = useForm<RelateAttrFormValues>(
        {
            defaultValues: relateAttrFormDefaults(modalProps.resource),
        }
    );

    // User is required to select a target attribute as the first step
    const [targetAttr, setTargetAttr] = useState<BaseAttribute | 'deriveNew' | null>(null);

    const fieldLabels = genRelateAttrFieldLabels(modalProps, targetAttr);

    const validatingRegister = useCallback(
        (inputName: keyof RelateAttrFormValues, options?: any) =>
            register(inputName, options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const selectableAttrs = useMemo(() => {
        if (!domainAttrs) {
            return [];
        }

        // exclude attr if it is
        // 1) same as source attr,
        // 2) belongs to the same entity as source attr,
        // 3) is not of type 'identity'
        return domainAttrs.filter(
            (attr) => attr.type === 'identity' && attr._id !== _id
        );
    }, [domainAttrs, _id]);

    const [relateAttr, mutationResults] = useRelateAttrActionMutation();

    const mergedErrResults = mergeErrorStates(
        mutationResults,
        domainAttrQueryResult
    );

    const onSubmit: SubmitHandler<RelateAttrFormValues> = (vals, e) => {
        e?.preventDefault();
        relateAttr({
            sourceAttrId: _id,
            body: relateAttrFormToPayload(vals),
        });
    };

    return (
        <AppModal
            label={"Relate Attribute"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit ? canEdit : true}>
            <TargetAttrSelect
                setTargetAttr={setTargetAttr}
                loading={isLoading}
                formState={formState}
                resetField={resetField}
                validatingRegister={validatingRegister}
                selectableAttrs={selectableAttrs}
                labelText={fieldLabels.otherId}
            />
            {targetAttr !== null && (
                <>
                    <SingularInput
                        label={fieldLabels.singular}
                        resetField={resetField}
                        formState={formState}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                    <PluralInput
                        resetField={resetField}
                        formState={formState}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                    <DefinitionInput
                        resetField={resetField}
                        formState={formState}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                    <IsDependentInput
                        resetField={resetField}
                        formState={formState}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                    <IsExclusiveInput
                        resetField={resetField}
                        formState={formState}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                    <RelationTypeSelect
                        resetField={resetField}
                        formState={formState}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                    />
                </>
            )}
            <FormResults
                isError={mutationResults.isError}
                onSuccess={closeModal}
                error={mergedErrResults}
                validationErrors={formState.errors}
            />
        </AppModal>
    )
}

export default RelateAttributeForm;
