import {ListItemContent, ListItemControls} from '../../../../common/List';
import Typography from '../../../../common/text/Typography';
import {LiveDataSet} from '../../types/dataTypes';
import {SelectedSourceData, SourceSetter} from '../../types/uiTypes';
import {SourceListItem} from '../styledComponents';
import {FunctionComponent} from 'react';
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";

interface DataSetListItemProps extends LiveDataSet {
    onDisplay: LiveDataSet;
    setter: SourceSetter;
    currentlySelected: SelectedSourceData;
    datasetProps?: any;
}

const DataSetListItem: FunctionComponent<DataSetListItemProps> = (
    {
        onDisplay,
        setter,
        currentlySelected,
        ...datasetProps
    }
) => {
    // const { active, onClick, itemName } = useSourceListItem({
    // 	onDisplay,
    // 	kind: 'dataset',
    // 	currentlySelected,
    // 	setter,
    // });

    const {username} = useParams();
    const navigator = useNavigate();

    return (
        <SourceListItem
            button
            // className={clsx(active && 'browser-item-is-open')}
            onClick={() => navigator(
                `/data/${username}/${onDisplay.sourceName}/${onDisplay.catalogName}/${onDisplay.datasetName}`,
                {replace: false}
            )}
        >
            <ListItemContent>
                <Typography color={'oldManGray'} variant='h5'>
                    {onDisplay.datasetName}
                </Typography>
                {/*{mapDataSetsToNavList(*/}
                {/*	datasets as LiveDataSets,*/}
                {/*	setter,*/}
                {/*	// setCardData as DatasetCardSetter,*/}
                {/*	currentlySelected,*/}
                {/*)}*/}
            </ListItemContent>
        </SourceListItem>
    );
};

export default DataSetListItem;

