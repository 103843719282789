import HUDReducer from '../features/HUD/state/HUDSlice';
import { futureModelApi } from '../features/api';
import tokenReducer from '../features/authentication/state/tokenSlice';
import {currentUserReducer, currentOrgReducer} from '../features/authentication/state/userSlice';
import errorMiddleware from '../features/errorHandling/errorMiddleware';
import errorReducer from '../features/errorHandling/state/errorSlice';
import { getToken } from '../features/localStorage';
import localStorageMiddleware from '../features/localStorage/localStorageMiddleware';
import {
	configureStore,
	ThunkAction,
	Action,
	combineReducers,
} from '@reduxjs/toolkit';

const { reducerPath: apiReducerPath, reducer: apiReducer } = futureModelApi;

// Note we MUST define root reducer separate from configureStore
// in order to get middleware types to work correctly (avoids circular type reference)
const rootReducer = combineReducers({
	[apiReducerPath]: apiReducer,
	currentUser: currentUserReducer,
	currentOrg: currentOrgReducer,
	// user: userReducer,
	token: tokenReducer,
	err: errorReducer,
	HUD: HUDReducer,
});

// TODO: type of preloaded state should be tightened up
export const initStore = (customState?: any) =>
	configureStore({
		reducer: rootReducer,

		preloadedState: customState
			? customState
			: {
					// TODO: may need to submit a ticket to get the types for this working
					token: getToken() as any,
			  },

		middleware: (getDefaultMW) =>
			getDefaultMW().concat(
				futureModelApi.middleware,
				localStorageMiddleware,
				errorMiddleware
			),
	});

export const store = initStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;

/**
 * Type of redux state object w/out properties added by RTK Query.
 * For use in setting up test files.
 */
export type StateWithoutQueries = Pick<RootState, 'currentUser' | 'err' | 'token'>;
