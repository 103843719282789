import BreakoutText from 'common/text/BreakoutText';
import { List } from 'common/List';
import { filter, flow, map, pipe } from 'common/utils/functionUtils';
import {
	sortGroupedAttrsDisplay,
	groupAttrs,
	getSelectedSubtypes,
	displayableAttrSubtypes,
} from 'features/ontology/helpers/attributeHelpers';
import {
	isIdentity,
	isPrimaryIdentity,
} from 'features/ontology/typeGuards/attributeGuards';
import {
	BaseAttribute,
	AttributeBaseType,
} from 'features/ontology/types/attributeTypes';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import IndividualHeader from "../../features/profile/IndividualProfile/ProfileHeader";
import useHUDIndividual from "../../features/HUD/hooks/useHUDIndividual";

export const groupAttrsForIndividualCard = flow(
	filter((a) => !isIdentity(a)),
	groupAttrs('base'),
	getSelectedSubtypes(displayableAttrSubtypes),
	sortGroupedAttrsDisplay
	// map(renderSection)
);

const StyledSectionContainer = styled.section`
	padding-left: ${(p) => p.theme.spacing(2)};
`;

interface AttrTypeSectionProps {
	subgroup: any;
	datum: Record<string, any>;
}

interface AttrTypeSection {
	type: AttributeBaseType;
	attrs: BaseAttribute[];
}

interface PopupBaseProps {
	datum: Record<string, any>;
	attributes: BaseAttribute[];
}

export const AttributeFactGroup: FunctionComponent<AttrTypeSectionProps> = ({subgroup, datum}) => {

	return <StyledSectionContainer>
			<hr
				style={{
					margin: '1rem 0 .5rem',
					borderWidth: 0,
					backgroundImage: 'linear-gradient(#29323c, #29323c)',
					color: '#c1c9d2',
					height: '1px',
				}}
			/>
			<List>
				{subgroup.attrs.map((attribute: BaseAttribute) => (
					<BreakoutText
						attribute={attribute}
						attrName={attribute.name}
						attrValue={String(datum[attribute.name])}
						key={attribute.name}
						overrides={{ root: { paddingLeft: 0 } }}
					/>
				))}
			</List>
		</StyledSectionContainer>;
}



export const IndividualCard: FunctionComponent<PopupBaseProps> = ({
	datum,
	attributes,
}) => {

	const hudIndividual = useHUDIndividual();

	const secondaryIdentities =
		attributes.filter((a) => isIdentity(a) && !isPrimaryIdentity(a)) ??
		null;

	const renderSection = (s: AttrTypeSection) => (
		<AttributeFactGroup
			key={s.type}
			subgroup={s}
			datum={datum}
		/>
	);

	return (
		<>
			<IndividualHeader datum={datum} attributes={attributes} />
			{pipe(attributes, groupAttrsForIndividualCard, map(renderSection))}
			{secondaryIdentities && (
				<StyledSectionContainer>
					<hr
						style={{
							margin: '1rem 0 .25rem',
							borderWidth: 0,
							backgroundImage:
								'linear-gradient(#29323c, #29323c)',
							color: '#c1c9d2',
							height: '1px',
						}}
					/>
					<List>
						{secondaryIdentities.map((a) => (
							<BreakoutText
								key={a._id}
								attrName={a.name}
								attrValue={String(datum[a.name])}
								manualPreventScrollbarOverflow
							/>
						))}
					</List>
				</StyledSectionContainer>
			)}
		</>
	);
};

export default IndividualCard;
