import HUDHistogram from './HUDHistogram';
import { default as theme } from 'common/theme/theme';
import { StyledLeftDrawerPanel } from 'features/HUD/components/styledComponents';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import { QueryStateTracker } from 'features/api/helpers';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import {
	StyledContentContainer,
	StyledHeading,
} from 'features/spatial/components/styledComponents';
import { FunctionComponent } from 'react';

interface HUDHistoProps extends QueryStateTracker {
	data: number[];
	attrName: string;
	attrPlural: string;
	maxBinCount: number;
}

const HUDHistogramModule: FunctionComponent<HUDHistoProps> = ({
	maxBinCount,
	attrName,
	attrPlural,
	data,
	...queryState
}) => {
	const { setQtyRangeFilter } = useEntitySearchParams();

	//  don't need any checks on event type for this callback--it's only invoked by the Histogram
	// brush event if the needed data is available and correctly formatted.
	const onBrushEnd = (minMax: [number, number]) => {
		setQtyRangeFilter(attrName, minMax);
	};

	const binCount = Math.min(30, maxBinCount);

	return (
		<StyledLeftDrawerPanel
			style={{
				padding: 0,
				display: 'flex',
				flexDirection: 'column',
				overflow: 'hidden',
			}}
		>
			<StyledHeading
				component="h6"
				className="unselectable"
				style={{
					color: theme.palette.lightBaby + 99,
					margin: '5px',
					whiteSpace: 'nowrap',
					fontSize: '9px',
					fontWeight: '700',
					height: '10px',
				}}
			>
				{attrPlural}
			</StyledHeading>
			<DisplayOnLoad {...queryState} spinnerDiameter={50}>
				<StyledContentContainer>
					<HUDHistogram
						binCount={binCount}
						data={data}
						top={4}
						bottom={4}
						right={4}
						left={4}
						onBrushEnd={onBrushEnd}
					/>
				</StyledContentContainer>
			</DisplayOnLoad>
		</StyledLeftDrawerPanel>
	);
};

export default HUDHistogramModule;
