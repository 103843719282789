import { ActionDescriptor } from '../common/commonTypes';
import SubtractAttributeForm from './SubtractAttributeForm';
import {faSubtract} from "@fortawesome/free-solid-svg-icons";

const subtractAttribute: ActionDescriptor = {
	form: SubtractAttributeForm,
	actionTitle: 'Subtract',
	divider: false,
	kind: 'modal',
	requiresEdit: true,
	actionIcon: faSubtract,
	modalType: 'subtractAttribute'
};

export default subtractAttribute;
