import Typography from '../../text/Typography';
import { forwardRef, CSSProperties, InputHTMLAttributes } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import styled from 'styled-components';

const genShadow = (color: string) => `-1px -1px 3px 0px ${color}90`;

 // 772	input:-webkit-autofill-and-obscured {
// 773	    -webkit-text-security: disc !important;
// 774	}
// 775
// 776	input:-webkit-autofill-strong-password {
// 777	    user-select: none !important;
// 778	}
// 779
// 780	input:-webkit-autofill-strong-password, input:-webkit-autofill-strong-password-viewable {
// 781	    input-security: none !important;
// 782	    cursor: default !important;
// 783	    font-family: monospace;
// 784	}
// 785
// 786	input:-webkit-autofill, input:-webkit-autofill-strong-password, input:-webkit-autofill-strong-password-viewable, input:-webkit-autofill-and-obscured {
// 787	    background-color: #FAFFBD !important;
// 788	    background-image: none !important;
// 789	    color: #000000 !important;
// 790	}
 // 1px solid

const StyledTextbox = styled.input<{ $error?: boolean }>`
	border: 2px solid ${(p) =>
			p.$error ? p.theme.palette.error.main : p.theme.palette.darkerBaby};
	color: ${(p) => p.theme.palette.cyan};
	border-radius: ${(p) => p.theme.spacing(1)};
	font-size: ${(p) => p.theme.typography.subtitle.fontSize};
	line-height: ${(p) => p.theme.typography.subtitle.lineHeight};
	letter-spacing: ${(p) => p.theme.typography.subtitle.letterSpacing};
	font-weight: ${(p) => p.theme.typography.subtitle.fontWeight};
	font-family: ${(p) => p.theme.typography.subtitle.fontFamily};
	padding: 0 ${(p) => p.theme.spacing(1)};
	box-shadow: ${(p) =>
		p.$error ? genShadow(p.theme.palette.error.main) : 'none'};
	width: 100%;
	background-color: ${(p) => p.theme.palette.common.white}0A;
	&:-webkit-autofill {
		background-color: ${(p) => p.theme.palette.oldManGray};
		background-color: ${(p) => p.theme.palette.darkerBaby};
	}
	&::autofill {
		background-color: ${(p) => p.theme.palette.oldManGray};
	}
	&:focus {
		outline: none;
	}

	&::placeholder {
		color: ${(p) => p.theme.palette.cyan};
		font-size: ${(p) => p.theme.typography.overline.fontSize};
		line-height: ${(p) => p.theme.typography.overline.lineHeight};
		letter-spacing: ${(p) => p.theme.typography.overline.letterSpacing};
		font-weight: ${(p) => p.theme.typography.overline.fontWeight};
		font-family: ${(p) => p.theme.typography.overline.fontFamily};
	}
	caret-color:  ${(p) => p.theme.palette.primary.main};
`;

//	&::placeholder {
// 	}

// const StyledLabel = styled.label<{ $error?: boolean; $inputHeight: number }>`
// 	background: transparent;
// 	transition: all 0.2s ease;
// 	transform: ${(p) => `translateY(${p.$inputHeight / 1.25}px)`};
// 	padding-left: ${(p) => p.theme.spacing(1)};
// 	text-transform: capitalize;
// 	color: ${(p) =>
// 		p.$error ? p.theme.palette.error.main : p.theme.palette.darkBaby};
// 	cursor: text;

// 	&.textbox-has-entries,
// 	&.textbox-has-focus {
// 		cursor: default;
// 		transform: ${(p) => `translateX(-${p.$inputHeight}px)`};
// 		color: ${(p) =>
// 			p.$error
// 				? p.theme.palette.error.main
// 				: p.theme.palette.primary.main};
// 	}
// `;

interface Overrides {
	root?: CSSProperties;
	label?: CSSProperties;
}

// Prevent any conflicts between attributes passed in from {...register}
// and 'normal' attributes expected  by <input/>
type WithoutRegisterProps<T> = Omit<T, keyof UseFormRegisterReturn>;

// Omit 'ref' from props component expects to receive via {...register},
// since 'ref' will be stripped out by React.forwardRef
interface TextboxProps
	extends Omit<UseFormRegisterReturn, 'ref' | 'onBlur' | 'onChange'>,
		WithoutRegisterProps<InputHTMLAttributes<HTMLInputElement>> {
	dirty?: boolean;
	labelText?: string;
	label?: string;
	onChange?: any;
	error?: FieldError;
	overrides?: Overrides;
	type?: 'password' | 'email' | 'text' | 'url' | 'month';

	// This is needed to keep error message IDs unique in case form is dynamically-generated
	// by react-hook-form useFieldArray()
	fieldGroupIndex?: number;

	//  capture will never be used on this component since it only applies to file inputs,
	// but being explicit here defeats type-widening to string, which causes an error
	// when passing all excess props to the HTML input.
	capture?: boolean | 'user' | 'environment' | undefined;
	Control?: JSX.Element;
}

// Text fields
// Text fields let users enter and edit text.

// Options
// Character counter
// Leading icon
// Trailing icon

// Assistive text
// None
// Helper text
// Error text

// Usage
// Text fields allow users to enter text into a UI. They typically appear in forms and dialogs.

// Principles
// Discoverable
// Text fields should stand out and indicate that users can input information.

// Clear
// Text field states should be clearly differentiated from one another.

// Efficient
// Text fields should make it easy to understand the requested information and to address any errors.

// Types
//
// Text fields come in two forms: filled and outlined.
//
// Text fields come in two types:
//
// Filled text fields
// Outlined text fields
// Both types of text fields use a container to provide a clear affordance for interaction, making the fields discoverable in layouts.

// Choosing the right text field
//
// Both types of text fields provide the same functionality, so the type of text field you use can depend on style alone. Choose the type...
//
// Both types of text fields provide the same functionality, so the type of text field you use can depend on style alone.
//
// Choose the type that:
//
// Works best with your app’s visual style
// Best accommodates the goals of your UI
// Is most distinct from other components (like buttons) and surrounding content

// Both types of text fields in one UI
// If both types of text fields are used in a single UI, they should be used consistently within different sections, and not intermixed within the same region. For example, you could use outlined text fields in one section and filled text fields in another.

// Do
// When using both types of text fields in a UI, separate them by region.

// Don't
// When using a both types of text fields, don't use both next to each other, or within the same form.

// https://material.io/components/text-fields#anatomy

// 1. Container
// 2. Leading icon (optional)
// 3. Label text
// 4. Input text
// 5. Trailing icon (optional)
// 6. Activation indicator
// 7. Helper text (optional)

// Container
//
// Containers improve the discoverability of text fields by creating contrast between the text field and surrounding content. A text field container has a fill and...
//
// Containers improve the discoverability of text fields by creating contrast between the text field and surrounding content.

// Fill and stroke
// A text field container has a fill and a stroke (either around the entire container, or just the bottom edge). The color and thickness of a stroke can change to indicate when the text field is active.

// Rounded corners
// The container of an outlined text field has rounded corners, while the container of a filled text field has rounded top corners and square bottom corners.

// Label text
//
// Label text is used to inform users as to what information is requested for a text field. Every text field should have a label. Label...
//
// Label text is used to inform users as to what information is requested for a text field. Every text field should have a label.
//
// Label text should be aligned with the input line, and always visible. It can be placed in the middle of a text field, or rest near the top of the container.

// Do
// Label text should always be visible, moving from the middle of the text field to the top (if the field is selected).

// Don't
// Label text shouldn't be truncated. Keep it short, clear, and fully visible.

// Don't
// Label text shouldn’t take up multiple lines.

// Required text indicator
// To indicate that a field is required, display an asterisk (*) next to the label text and mention near the form that asterisks indicate required fields.
//
// If some fields are required, indicate all required ones
// If most fields are required, indicate optional fields by displaying the word “optional” in parentheses next to the label text
// If required text is colored, that color should also be used for the asterisk

// Input text is text the user has entered into a text field.
//
// 1. Input text
// Input text is text entered by the user.
//
// 2. Cursor
// A cursor indicates the current location of text input in a field.

// Assistive elements provide additional detail about text entered into text fields.
//
// 1. Helper text
// Helper text conveys additional guidance about the input field, such as how it will be used. It should only take up a single line, being persistently visible or visible only on focus.
//
// 2. Error message
// When text input isn't accepted, an error message can display instructions on how to fix it. Error messages are displayed below the input line, replacing helper text until fixed.
//
// 3. Icons
// Icons can be used to message alerts as well. Pair them with error messages to provide redundant alerts, which are useful when you need to design for colorblind users.
//
// 4. Character counter
// Character or word counters should be used if there is a character or word limit. They display the ratio of characters used and the total character limit.

// Error text
// For text fields that validate their content (such as passwords), replace helper text with error text when applicable. Swapping helper text with error text helps prevent new lines of text from being introduced into a layout, thus bumping content to fit it.
//
// If only one error is possible, error text describes how to avoid the error
// If multiple errors are possible, error text describes how to avoid the most likely error

// Do
// Swap helper text with error text.

// Don't
// Don't place error text under helper text, as their appearance will shift content.

// Caution
// Long errors can wrap to multiple lines if there isn't enough space to clearly describe the error. In this case, ensure padding between text fields is sufficient to prevent multi-lined errors from bumping layout content.

// Icons
//
// Icons in text fields are optional. Text field icons can describe valid input methods (such as a microphone icon), provide affordances to access additional functionality...
//
// Icons in text fields are optional. Text field icons can describe valid input methods (such as a microphone icon), provide affordances to access additional functionality (such as clearing the content of a field), and can express an error.
//
// Leading and trailing icons change their position based on LTR or RTL contexts.

// 1. Icon signifier
// Icon signifiers can describe the type of input a text field requires, and be touch targets for nested components. For example, a calendar icon may be tapped to reveal a date picker.
//
// 2. Valid or error icon
// Iconography can indicate both valid and invalid inputs, making error states clear for colorblind users.
//
// 3. Clear icon
// Clear icons let users clear an entire input field. They appear only when input text is present.
//
// 4. Voice input icon
// A microphone icon signifies that users can input characters using voice.
//
// 5. Dropdown icon
// A dropdown arrow indicates that a text field has a nested selection component.

// Behavior
// Scaling and adaptation
//
// As layouts adapt to larger screens and form factors, apply scalable container dimensions to text fields.
//
// As layouts adapt to larger screens and form factors, apply flexible container dimensions to text fields. Set minimum and maximum values for margins, padding, and container dimensions as layouts scale so that typography adjusts for better reading experiences.

// Text fields can span the full width of the display on small screens, but should be bounded by flexible margins or other containers on larger screens.
// As text fields expand within fluid layouts, avoid maintaining fixed margins and typography properties because this can lead to extra long text fields. Text fields should not, for example, span the full width of a large screen.

// Filled text field
// Usage
//
// Filled text fields have more visual emphasis than outlined text fields, making them stand out when surrounded by other content and components.
//
// Filled text fields have more visual emphasis than outlined text fields, making them stand out when surrounded by other content and components.

// Don't
// Don’t design text fields to look similar to buttons, as they could be mistaken for buttons.

// Dense text fields
//
// Dense text fields enable users to scan and take action on large amounts of information.
//
// Dense text fields enable users to scan and take action on large amounts of information.

// Text fields without labels
//
// A text field doesn’t require a label if the field’s purpose is indicated by a separate, adjacent label.
//
// A text field doesn't require a label if the field's purpose is indicated by a separate, adjacent label.

// Prefix and suffix text
// A text field with a currency symbol text prefix.
// A text field with a unit of mass suffix.

//Text field with a suffix expressing an academic grading scale.

//Text field with an email domain address suffix.

// States
//
// Filled text fields (baseline)

// Filled text fields can display the following states: inactive, activated, focused, hover, error, and disabled.
// Filled text fields without labels

// Filled text fields (without labels) can display the following states: inactive, activated, focused, hover, error, and disabled.
// Filled text fields with prefix text

// Filled text fields (with prefix text) can display the following states: inactive, activated, focused, hover, error, and disabled.
// Filled text fields with suffix text
// Filled text fields (with suffix text) can display the following states: inactive, activated, focused, hover, error, and disabled.

// Outlined text field
// Usage
//
// Outlined text fields have less visual emphasis than filled text fields. When they appear in places like forms, where many text fields are placed together,...
//
// Outlined text fields have less visual emphasis than filled text fields. When they appear in places like forms, where many text fields are placed together, their reduced emphasis helps simplify the layout.

// Dense text fields
//
// Dense text fields enable users to scan and take action on large amounts of information.
//
// Dense text fields enable users to scan and take action on large amounts of information.

// Input types
// Text fields can display user input in the following ways:
//
// Single line text fields display only one line of text
// Multi-line text fields grow to accommodate multiple lines of text
// Text areas are fixed-height fields

// Single-line fields
// In single-line fields, as the cursor reaches the right field edge, text longer than the input line automatically scrolls left.
//
// Single-line fields are not suitable for collecting long responses. For those, use a multi-line text field or text area instead.

// Multi-line fields
// Multi-line text fields show all user input at once. Overflow text causes the text field to expand (shifting screen elements downward), and text wraps onto a new line.
//
// These fields initially appear as single-line fields, which is useful for compact layouts that need to be able to accommodate large amounts of text.

//

// Text areas
// Text areas are taller than text fields and wrap overflow text onto a new line. They are a fixed height and scroll vertically when the cursor reaches the bottom of the field.
//
// The large initial size indicates that longer responses are possible and encouraged.
//
// These should be used instead of multi-line fields on the web. Ensure the height of a text area fits within mobile screen sizes.

// Read-only fields
// Read-only text fields display pre-filled text that the user cannot edit. A read-only text field is styled the same as a regular text field and is clearly labeled as read-only.


// Research
// Material Design conducted two studies with around 600 participants to understand characteristics of text field usability and user preferences for text field design. The studies measured a user’s ability to scan and find a text field as well as the ability to identify the types of text fields.
//
// Research showed the following are best practices that can be adopted to improve the usability of text fields:
//
// Use an enclosed text field with a rectangular box, rather than a single underline to indicate a text field
// Use either a semi-transparent fill with a bottom line or a fully transparent fill with an opaque stroke for the text field box
// Place label text within the boundary of a text field box
// Ensure text field outlines or strokes meet 3:1 minimum color contrast ratio to the background

const Textbox = forwardRef<HTMLInputElement, TextboxProps>(
	(
		{
			labelText,
			label,
			error,
			overrides,
			type = 'text',
			name,
			fieldGroupIndex = 0,
			...rest
		},
		ref
	) => {
		// const [dims, setDims] = useClientRect();

		// const { height } = dims;

		// const inputRef = useRef<HTMLInputElement>();

		// const combinedRef = useForkRef(setDims, ref, inputRef);

		// const [hasFocus, setHasFocus] = useState(false);

		// const [hasValues, setHasValues] = useState(false);

		// const onFocus = () => setHasFocus(true);

		// const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		// 	if (hasValues && e.target.value.length === 0) {
		// 		setHasValues(false);
		// 	}

		// 	if (!hasValues && e.target.value.length !== 0) {
		// 		setHasValues(true);
		// 	}
		// };
		// const handleBlur = () => setHasFocus(false);

		// const handleLabelClick: MouseEventHandler = () => {
		// 	if (inputRef.current && hasFocus === false) {
		// 		setHasFocus(true);
		// 		inputRef.current.focus();
		// 	}
		// };

		const errMessageId = `${name}-${fieldGroupIndex}-error-label`;
		const ariaLabelId = `${name}-${fieldGroupIndex}-label-label`;

		return (
			<div style={{ width: '100%' }}>
				<StyledTextbox
					id={ariaLabelId}
					name={name}
					$error={!!error}
					style={{ ...overrides?.root }}
					ref={ref}
					{...rest}
					type={type}
					aria-invalid={!!error}
					aria-errormessage={errMessageId}
					placeholder={labelText}
					aria-label={labelText}
					spellCheck={false}
					// onBlur={(e: FocusEvent<HTMLInputElement>) => {
					// 	handleBlur();
					// 	onBlur(e);
					// }}
					// onFocus={onFocus}
				/>

				{error && (
					<>
						<Typography color="error" paragraph id={errMessageId} variant='overline'>
							{error.message ?? `Problem submitting ${labelText}`}
						</Typography>
					</>
				)}
			</div>
		);
	}
);

export default Textbox;
