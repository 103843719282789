import Button from '../buttons/Button';
import FlexContainer from '../FlexContainer';
import { FormResults } from '../Form';
import Heading from '../text/Heading';
import Typography from '../text/Typography';
import { useDeleteOrgMutation } from '../../features/api';
import { ForceGraphContext } from '../../features/graph/state/GraphContextProvider';
import { SET_POPUP_ELEMENT } from '../../features/graph/state/actions';
import {
	StyledFlexContainer,
	StyledPaper,
	StyledDeleteButton,
} from '../../features/actions/common/styledComponents';
import {
	Dispatch,
	FunctionComponent,
	MouseEventHandler,
	SetStateAction,
	useContext,
} from 'react';
import AppModal from "./AppModal";
import {useAppDispatch} from "../../app/hooks";
import useModalType from "../../features/HUD/hooks/useModalType";
import {setModalType} from "../../features/HUD/state/HUDSlice";

interface DeleteOrgFormProps {
	orgId: number;
	orgLabel: string;
}

const DeleteOrgForm: FunctionComponent = () => {
	const [_, graphDispatch] = useContext(ForceGraphContext);

	const [deleteOrg, mutationResults] = useDeleteOrgMutation();

	const appDispatch = useAppDispatch();
	const {modalType, modalProps, closeModal} = useModalType();
	const {orgId, orgLabel} = modalProps;

	const onClick: MouseEventHandler = () => {
		deleteOrg({ orgId });
	};

	const onSuccessfulDelete = () => closeModal();
		// graphDispatch({ type: SET_POPUP_ELEMENT, payload: null });

	return (
		<AppModal isForm={false} label={'Delete Org'} isOpen={true} isDirty={false} onClose={closeModal} onSubmit={() => {}} canEdit={true} >
		<StyledPaper>
			<StyledFlexContainer flexDirection="column" alignItems="center">
				<Heading component="h5">
					<Typography>
						Are you sure you want to delete {orgLabel}?
					</Typography>
				</Heading>
				<FlexContainer
					style={{ width: '50%' }}
					justifyContent="space-between"
				>
					<Button onClick={closeModal}>
						Go Back
					</Button>
					<StyledDeleteButton onClick={onClick}>
						Delete
					</StyledDeleteButton>
				</FlexContainer>
				<FormResults
					{...mutationResults}
					validationErrors={{}}
					onSuccess={onSuccessfulDelete}
				/>
			</StyledFlexContainer>
		</StyledPaper>
		</AppModal>
	);
};

export default DeleteOrgForm;
