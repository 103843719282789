import AttributeSelect from '../../../common/inputs/AttributeSelect';
import HorizontalBarChartModule from '../../../common/viz/HorizontalBarChart/HorizontalBarChartModule';
import IndividualsModule from './components/IndividualsModule';
import ScatterplotModule from '../../../common/viz/Scatterplot/ScatterplotModule';
import { StyledVizPaper } from './components/styledComponents';
import { StyledVizContainer } from './components/styledComponents';
import FlexContainer from 'common/FlexContainer';
import {BasePaper} from 'common/Paper';
import styled from 'styled-components'
import Heading from 'common/text/Heading';
import Spinner from 'common/loading/Spinner';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import { LINEAGE_GRAPH } from 'common/modals/LineageGraph/CONSTANTS';
import { deriveEntityProfileViz } from 'features/ontology/helpers/entityHelpers';
import useActiveEntityAttributes from 'features/ontology/hooks/useActiveEntityAttributes';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';
import { EntityVizMap } from 'features/ontology/types/entityTypes';
import EventLineChartModule from 'common/viz/LineChart/LineChartModule';
import HistogramModule from 'common/viz/Histogram/HistogramModule';
import LineageModule from 'features/profile/EntityProfile/components/LineageModule';
import {
	CATEGORY_BAR_CHART,
	EVENT_LINE_CHART,
	INDIVIDUALS_TABLE,
	QTY_HISTO,
	QTY_SCATTERPLOT,
	RELATION_BAR_CHART
} from 'common/viz/CONSTANTS';
import { FunctionComponent } from 'react';
import Typography from "../../../common/text/Typography";

const entityVizMap: EntityVizMap = {
	[QTY_HISTO]: HistogramModule,
	[LINEAGE_GRAPH]: LineageModule,
	[QTY_SCATTERPLOT]: ScatterplotModule,
	[CATEGORY_BAR_CHART]: HorizontalBarChartModule,
	[RELATION_BAR_CHART]: HorizontalBarChartModule,
	[EVENT_LINE_CHART]: EventLineChartModule,
	[INDIVIDUALS_TABLE]: IndividualsModule,
};

export const StyledProfileGridLayout = styled(BasePaper)`
	display: grid;
	grid-template-columns: ${(p) => p.theme.drawerWidth}px 1fr;
	position: absolute;
	height: ${(p) => `calc(100vh - ${p.theme.navHeight + p.theme.panelGutter * 2}`}px);
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter}px;
	left: ${(p) => p.theme.spacing(1)};
	column-gap: 8px;
	padding: 0;
	opacity: 1;
	width: calc(100vw - ${(p) => `${p.theme.sideControllerWidth + p.theme.panelGutter * 3}`}px);
	z-index: ${(p) => p.theme.zIndex.controlSurface};
`;

export const StyledProfileFlexLayout = styled(BasePaper)`
	display: flex;
	position: absolute;
    height: ${(p) =>
		`calc(100vh - ${(p.theme.navHeight * 2 + 8)}px - ${p.theme.panelGutter * 4}px)`};
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter * 2}px;
    left: ${(p) => p.theme.spacing(1)};
    
	padding: 0;
    opacity: 1;
	z-index: ${(p) => p.theme.zIndex.controlSurface};
`;
    // padding: ${(p) => p.theme.spacing(1)};
	// overflow: visible;
	// flex-direction: column;
	// display: ${(p) => !!p.props.drawerOpen ? 'flex' : 'none'};

export const StyledProfilePanelGrid = styled.div`
    padding: ${(p) => p.theme.spacing(1)};
	display: grid;
	grid-template-columns: ${(p) => p.theme.drawerWidth}px 1fr;
	
`;

// TODO: Pretty janky. Always yields 4 modules, with any populated modules
// (which range in number from 0 - 2 based on current attribute) first.
// If current attribute is primary identity, only return a single (full-page)
// module.
const fillModules = (attrs: BaseAttribute[], currentAttr: string) => {
	const currentFullAttr = attrs.find(
		(a) => a.name === currentAttr
	) as BaseAttribute;

	if (currentFullAttr.isPrimary) {
		return <IndividualsModule />;
	}

	const vizModules = deriveEntityProfileViz(attrs, currentFullAttr).map(
		(kind) => {
			const Component = entityVizMap[kind];
			return <Component key={kind} />;
		}
	);

	let placeholderCount = 0;
	if (currentFullAttr.type === 'quantity') {
		placeholderCount = 4 - vizModules.length;
	}

	if (placeholderCount > 0) {
		for (let i = 1; i <= placeholderCount; i++) {
			vizModules.push(<StyledVizPaper key={`placeholder-${i}`} />);
		}
	}

	return vizModules;
};

const EntityProfile: FunctionComponent = () => {
	const { getActiveAttributeName } = useEntitySearchParams();

	const currentAttrName = getActiveAttributeName();

	const { attributes, ...loadState } = useActiveEntityAttributes();

	const activeIsPrimaryID = !!attributes?.find(
		(a) => a.name === currentAttrName
	)?.isPrimary;

	const activeType = attributes?.find(
		(a) => a.name === currentAttrName
	)?.type;

	const renderViz = () => {
		if (loadState.isSuccess && attributes && currentAttrName) {
			return (
				<StyledVizContainer fullscreen={activeIsPrimaryID || activeType !== 'quantity'}>
					{fillModules(attributes, currentAttrName)}
				</StyledVizContainer>
			);
		}

		if (loadState.isLoading) {
			return (
				<div>
					<FlexContainer
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						style={{ height: '100%' }}
					>
						<Spinner />
					</FlexContainer>
				</div>
			);
		}

		if (!currentAttrName) {
			return (
				<FlexContainer justifyContent="center">
					<div style={{position: 'fixed', top: '50%',}}>
						<Typography variant="h1">
							Select an attribute
						</Typography>
					</div>
				</FlexContainer>
			);
		}
	};

	return (
		<StyledProfileGridLayout>
			<AttributeSelect />
			{renderViz()}
		</StyledProfileGridLayout>
	);
};

export default EntityProfile;
