import React, {
	FunctionComponent, useCallback, useState,
} from "react";
import ReactModal from "react-modal";
import StyledSquare from "../StyledSquare";
import Typography from "../text/Typography";
import IconButton from "../buttons/IconButton";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {BasePaper} from '../Paper';
import styled from 'styled-components';
import useCurrentUser from "../../features/authentication/hooks/useUser";
import {
	useFollowUserMutation,
	useGetFollowersQuery,
	useUnfollowUserMutation
} from "../../features/api";
import ResourceItem from "../List/ResourceItem";
import {BaseUser, FollowersSubject} from "../../features/authentication/types/userTypes";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import UserPopover from "../popovers/UserPopover";
import {PopoverBase} from "../popovers/Popover";
import RevokeRoleButton from "../buttons/RevokeRoleButton";
import FollowButton from "../buttons/FollowButton";
import AppModal from "./AppModal";
import useModalType from "../../features/HUD/hooks/useModalType";

export const SourceBody = styled(BasePaper)`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: ${(p) => p.theme.palette.background.paper};
`

interface HUDProps {
	translucent?: boolean;
	borderRadius?: string;
	width?: string | number;
	height?: string | number;
}


	// box-shadow: ${(p) => p.theme.shadows[4]};
	// height: ${(p) => p.height ? p.height : '50'}vh;
	// width: ${(p) => p.width ? p.width : '50'}vw;
export const HUDModalBody = styled.div<HUDProps>`
	background: ${(p) =>
		p.theme.palette.background[
			p.translucent ? 'transparentDark' : 'paper'
		]};
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	color: ${(p) => p.theme.palette.lightBaby};
	display: flex;
	flex-direction: column;

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
	${(p) => p.theme.translucent}
`;

interface StyledModalHeaderProps {
	justifyContent?: string;
	borderBottom?: string;
	padding?: string;
}

export const ModalHeader = styled.header<StyledModalHeaderProps>`
    border-bottom: ${(p) => p.borderBottom ? p.borderBottom : `2px solid ${p.theme.palette.divider}`};
	padding: ${(p) => p.padding ? p.padding : p.theme.spacing(1, 2)};
	display: flex;
	justify-content: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
`;

export const HUDModalHeaderText = styled.h1`
	font-size: 1rem;
	display: flex;
	align-items: center;
`;


interface ModalHeaderProps extends ReactModal.Props {
	handleClose: () => void;
	headingText: string;
}

const HUDModalHeader: FunctionComponent<ModalHeaderProps> = ({
	headingText,
	handleClose
}) => {
	return (
		<ModalHeader>
			<HUDModalHeaderText>
				<StyledSquare/>
				<span style={{paddingLeft: '.75rem'}}>
					<Typography color={'oldManGray'} variant={'h4'}>
						{headingText}
					</Typography>
				</span>
			</HUDModalHeaderText>
			<IconButton
				onClick={handleClose}
				icon={faTimes}
				aria-label="close source browser"
			/>
		</ModalHeader>
	);
};

type FollowerPanelProps = {
	objectId?: number;
	objectType?: FollowersSubject;
	isOpen: boolean;
	onClose: () => void;
};

export const getURLStumpFromObjectType = (objectType: FollowersSubject) => {
	switch (objectType) {
		case 'Organization':
			return 'orgs';
		case 'User':
			return 'users';
		case 'DataSource':
			return 'sources';
		case 'DataCatalog':
			return 'catalogs';
		case 'Dataset':
			return 'datasets';
		case 'DataColumn':
			return 'columns';
		case 'DataRow':
			return 'rows';
		case 'DataValue':
			return 'values';
		case 'DataType':
			return 'types';
		case 'Metric':
			return 'metrics';
		case 'Fact':
			return 'facts';
		case 'Insight':
			return 'insights';
		case 'Domain':
			return 'domains';
		case 'Entity':
			return 'entities';
		case 'Attribute':
			return 'attributes';
		case 'Individual':
			return 'individuals';
	}
};

const FollowersModal: FunctionComponent = () => {

	const {modalProps, closeModal} = useModalType();
	const {objectId, objectType} = modalProps;

	const [popoverUser, setPopoverUser] = useState<BaseUser | null>(null);
	const [anchor, setAnchor] = useState<HTMLElement | null>(null);
	const [onHold, setOnHold] = useState<boolean>(false);

	const user = useCurrentUser();
	const {
		data: followers,
		isLoading,
		isError,
		isUninitialized,
		error,
	} = useGetFollowersQuery(
		!!objectId && !!objectType ? { objectId, objectType } : skipToken
	);
	// const objectId = contextLevel === 'username' ? orgId : domainId;


	if (user) {
		return (
			<AppModal
				label={"Followers"}
				isForm={false}
				isOpen={true}
				isDirty={false}
				onClose={closeModal}
				onSubmit={() => {}}
				canEdit={false}
			>
					<PopoverBase
						anchorEl={anchor}
						// handleClickAway={() => setPopoverUser(null)}
						open={!!popoverUser}>
						{popoverUser && <UserPopover onHold={setOnHold} handleClose={() => {
							setAnchor(null);
							setPopoverUser(null);
						}} user={popoverUser} />}
					</PopoverBase>
					{followers && followers.items && followers.items.map((follower) => {
						// const controls = <FollowButton
						// 	objectAlreadyFollowed={follower.follower.userContext.interactions.following}
						// 	handleFollow={() => follow({ userId: follower.follower._id })}
						// 	handleUnfollow={() => unfollow({ userId: follower.follower._id })}
						// 	loading={false} />;
						return <ResourceItem
							key={follower.follower._id}
							resource={follower.follower}
							// viewerControls={controls}
							// controls={controls}
							canEdit={false}
							onHold={onHold}
							onExit={(onHold: boolean) => {
								if (!onHold) {
									setAnchor(null);
									setPopoverUser(null);
								}
							}}
							onClick={(e: any) => {
								setOnHold(true);
								setAnchor(e.currentTarget);
								setPopoverUser(follower.follower);
							}}/>
					})}
			</AppModal>
		);
	} else {
		return null
	}
};

export default FollowersModal;
