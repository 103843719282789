import React, {FunctionComponent} from "react";
import {ReactionSubject} from "../activities/types/activityTypes";
import {useCreateReactionMutation} from "../../features/api";
import Button from "./Button";
import Typography from "../text/Typography";

const ReactButton: FunctionComponent<{ objectId: number, objectType: ReactionSubject}> = (
    {objectId, objectType}) => {
    const [reaction, reactionResult] = useCreateReactionMutation();

	const handleReact = () => reaction({ body: {
    	    type: 'LIKE',
            objectIdentifier: {
    	        objectId,
                kind: 'other'
            },
            objectType
        } });

	// const [unfollow, unfollowResult] = useUnfollowUserMutation();
	// const handleUnfollow = () => unfollow({ userId: user._id });
	// const mergedMutationState = mergeQueryStates(followResult, unfollowResult);

    return <Button style={{padding: 0, margin: 0}} onClick={handleReact}>
        <Typography variant='button'>
            Like
        </Typography>
    </Button>
};

export default ReactButton;
