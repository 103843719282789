import FlexContainer from 'common/FlexContainer';
import {FormResults} from 'common/Form';
import Spinner from 'common/loading/Spinner';
import Typography from 'common/text/Typography';
import {useGetAttributesQuery, useRestrictEntityMutation} from '../../api';
import {mergeErrorStates, extractQueryErrMessage} from '../../api/helpers';
import {EntityActionFormProps} from '../common/commonTypes';
import {getRestrictionOperators} from '../common/helpers';
import {
    renderDerivationSubfields,
    renderReferenceField,
} from '../common/jsxHelpers';
import useAttrNeighbors from '../hooks/useAttrNeighbors';
import {
    renderRelationCountSubfield,
    restrictEntityFormDefaults,
    restrictEntityFormToParams,
} from './restrictEntityHelpers';
import {
    RestrictEntityFormValues,
    RestrictionKind,
} from './restrictEntityTypes';
import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "common/modals/AppModal";
import RestrictionAttributeSelect
    from "common/inputs/RestrictionAttributeSelect";
import RestrictionTypeSelect from "common/inputs/RestrictionTypeSelect";
import ValueTypeSelect from "common/inputs/ValueTypeSelect";
import useModalType from "../../HUD/hooks/useModalType";

// pass RestrictionKind prop to determine correct endpoint
const RestrictEntityForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
	const {objectId: parentEntityId, restrictionKind, canEdit} = modalProps;

    const formDefaults = restrictEntityFormDefaults();

    const {handleSubmit, register, resetField, reset, formState, watch} =
        useForm<RestrictEntityFormValues>({
            defaultValues: formDefaults,
        });

    const [
        watchedUsingStaticReference,
        watchedRestrictionType,
        watchedRestrictedId,
    ] = watch(['usingStaticReference', 'restrictionType', 'restrictedId']);

    const relationCountNeeded = useMemo(
        () =>
            watchedRestrictionType === 'hasAtLeastNRelationsWith' ||
            watchedRestrictionType === 'hasAtMostNRelationsWith',
        [watchedRestrictionType]
    );

    const validatingRegister = useCallback(
        (inputName: keyof RestrictEntityFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    //  prevent filtering from running every render
    const filter = useMemo(() => {
        return () => true;
    }, []);

    const {data: attributes} = useGetAttributesQuery({
        entityId: parentEntityId,
    });

    const queryRes = useAttrNeighbors(
        watchedRestrictedId === 0 ? null : watchedRestrictedId,
        filter
    );

    const restrictedAttribute = useMemo(() => {
        if (attributes) {
            return (
                attributes.find((attr) => attr._id === watchedRestrictedId) ??
                null
            );
        }

        return null;
    }, [watchedRestrictedId, attributes]);

    // useEffect(() => {
    //     if (queryRes.isSuccess && closeModal) {
    //         closeModal();
    //     }
    // }, [queryRes.isSuccess, closeModal]);

    const [restrict, mutationResults] = useRestrictEntityMutation();

    const mergedErrs = mergeErrorStates(queryRes, mutationResults);

    const onSubmit: SubmitHandler<RestrictEntityFormValues> = (vals, e) => {
        e?.preventDefault();

        if (restrictedAttribute !== null) {
            return restrict(
                restrictEntityFormToParams(
                    parentEntityId,
                    restrictionKind,
                    vals
                )
            );
        }
    };

    // if (queryRes.isLoading) {
    //     return (
    //         <FlexContainer justifyContent="center">
    //             <Typography paragraph variant='h5'>
    //                 Loading attributes...
    //             </Typography>
    //             <Spinner/>
    //         </FlexContainer>
    //     );
    // }

    if (queryRes.isError) {
        return (
            <FlexContainer justifyContent="center">
                <Typography color="error" paragraph>
                    {extractQueryErrMessage(queryRes.error)}
                </Typography>
            </FlexContainer>
        );
    }

    return (
        <AppModal
            label={restrictionKind === 'specialization'
                ? "Specialize entity"
                : "Restrict entity"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit}>
            {!!attributes && <RestrictionAttributeSelect
                attributes={attributes}
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />}

            {renderDerivationSubfields({
                isDerivation: restrictionKind === 'specialization',
                formState,
                resetField,
                validatingRegister,
                mutationResults
            })}

            {!!restrictedAttribute && <RestrictionTypeSelect
                restrictedAttribute={restrictedAttribute}
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />}

            {renderRelationCountSubfield(
                relationCountNeeded,
                validatingRegister,
                formState
            )}
            <ValueTypeSelect
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />

            {renderReferenceField(
                'restrictionId',
                'restrictionValue',
                watchedUsingStaticReference === 'true',
                validatingRegister,
                queryRes.attrNeighbors,
                formState
            )}

            <FormResults
                isError={!!mergedErrs}
                error={mergedErrs}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default RestrictEntityForm;
