import { StyledSVGLabelText } from './styledComponents';
import { FunctionComponent } from 'react';

interface ChartLeftLabelProps {
	label: string;
	height: number;
	bottom: number;
}

const ChartLeftLabel: FunctionComponent<ChartLeftLabelProps> = ({
	bottom,
	height,
	label,
}) => {
	return (
		<g>
			<StyledSVGLabelText
				x={5}
				y={(height - bottom) / 2}
				writingMode="vertical-lr"
			>
				{label}
			</StyledSVGLabelText>
		</g>
	);
};

export default ChartLeftLabel;
