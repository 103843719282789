import React, {FunctionComponent, useCallback, useEffect,} from "react";
import useActiveOrgData from "../../features/ontology/hooks/useActiveOrgData";
import ReactModal from "react-modal";
import StyledSquare from "../StyledSquare";
import Typography from "../text/Typography";
import IconButton from "../buttons/IconButton";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {BasePaper} from '../Paper';
import styled from 'styled-components';
import {FormResults} from "../Form";
import {useUpdateOrgMutation} from "../../features/api";
import {SubmitHandler, useForm} from "react-hook-form";
import FlexContainer from "../FlexContainer";
import Heading from "../text/Heading";
import ProfileAvatar
    from "../../features/navigation/components/Navbar/ProfileAvatar";
import AppModal from "./AppModal";
import {StyledGraphicContainer} from "../StyledGraphicContainer";
import {StyledGraphicCircle} from "../StyledCircle";
import useModalType from "../../features/HUD/hooks/useModalType";
import GithubInput from "../inputs/GithubInput";
import InstagramInput from "../inputs/InstagramInput";
import FacebookInput from "../inputs/FacebookInput";
import LinkedInInput from "../inputs/LinkedInInput";
import TimezoneSelect from "../inputs/TimezoneSelect";
import CurrencySelect from "../inputs/CurrencySelect";
import LanguageSelect from "../inputs/LanguageSelect";
import LocationSelect from "../inputs/LocationSelect";
import AboutInput from "../inputs/AboutInput";
import IndustrySelect from "../inputs/IndustrySelect";
import OrgTypeSelect from "../inputs/OrgTypeSelect";
import LegalNameInput from "../inputs/LegalNameInput";
import LabelInput from "../inputs/LabelInput";
import UserNameInput from "../inputs/UsernameInput";
import URLInput from "../inputs/WebURLInput";
import {harvestErrors, SubtleTextbox} from "../inputs/SubtleTextBox";

interface UpdateOrgFormValues {
    username?: string;
    label?: string;
    legalName?: string;
    domainName?: string;
    orgType?: string;
    location?: string;
    industry?: string;
    language?: string;
    currency?: string;
    timezone?: string;
    linkedin: string;
    facebook: string;
    instagram: string;
    github: string;
    avatarUrl?: string;
    avatarScale?: number;
    avatarPosition?: number;
    definition?: string;
    isOnboarded?: boolean;
    isManaged?: boolean;
    isVerified?: boolean;
}

const OrgSettingsModal: FunctionComponent = () => {

    const {modalType, modalProps, closeModal} = useModalType();
    const {resource: activeOrg} = modalProps;

    const canEdit = activeOrg && activeOrg.userContext.permissions.edit
        || activeOrg && activeOrg.userContext.permissions.admin;

    const {register, formState, resetField, handleSubmit, reset, watch} =
        useForm<UpdateOrgFormValues>({
            defaultValues: Object.assign(
                {}, activeOrg, activeOrg.social, activeOrg.locale, {avatarUrl: activeOrg.avatar.medium}),
        });

    const avatarScale = watch('avatarScale');
    const avatarPosition = watch('avatarPosition');

    // this is needed to update react-hook-form's default values with new
    // values from a successful PUT request.
    useEffect(() => {
        if (!!activeOrg && formState.isDirty) {
            reset(activeOrg);
            closeModal();
        }
    }, [activeOrg, reset]);

    const validatingRegister = useCallback(
        (inputName: keyof UpdateOrgFormValues, options?: any) =>
            register(inputName, options
                ? options : {required: `${inputName} is required`,}),
        [register]
    );

    const [updateOrg, mutationResults] = useUpdateOrgMutation();

    const onSubmit: SubmitHandler<UpdateOrgFormValues> = (formVals) => {
        const {_id, social, locale, ...orgProps} = activeOrg;

        // if (!_id || !!social || !!locale || !!orgProps) {
        // 	return null;
        // }

        updateOrg({
            orgId: _id,
            body: Object.assign({}, activeOrg, activeOrg.social, activeOrg.locale, formVals),
        });
    };

    if (activeOrg) {
        return (
            <AppModal
                label={"Edit profile"}
                isOpen={modalType === 'orgSettings'}
                isDirty={formState.isDirty}
                onClose={() => closeModal()}
                onSubmit={handleSubmit(onSubmit)}
                canEdit={canEdit}>
                <Heading style={{padding: 0, margin: 0}} component="h3">
                    Profile Picture
                </Heading>
                <FlexContainer
                    flexDirection={'column'}
                    gap={'1rem'}
                    alignItems='center'>
                    {!!activeOrg.avatar?.medium || !!activeOrg.avatarUrl
                        ? <ProfileAvatar
                            initial={!!activeOrg ? activeOrg.name[0] : ''}
                            profileImageURL={activeOrg.avatar?.medium
                                ? activeOrg.avatar?.medium
                                : activeOrg.avatarUrl}
                            isProfile={true}
                            onClick={() => {
                            }}
                            avatarScale={!!avatarScale ? avatarScale : undefined}
                            avatarPosition={!!avatarPosition ? avatarPosition : undefined}
                            isOnline={false}
                            styles={{
                                alignSelf: 'center',
                                justifySelf: 'center'
                            }}
                            height={'10rem'}
                            width={'10rem'}
                        />
                        : <StyledGraphicContainer
                            height={'10rem'}
                            width={'10rem'}
                            style={{
                                alignSelf: 'center',
                                justifySelf: 'center'
                            }}>
                            <StyledGraphicCircle/>
                        </StyledGraphicContainer>}
                    <URLInput
                        name={'avatarUrl'}
                        label="Avatar URL"
                        tooltip="Avatar URL"
                        defaultValue={activeOrg.avatar.medium}
                        validatingRegister={validatingRegister}
                        mutationResults={mutationResults}
                        formState={formState}
                        resetField={resetField}
                    />
                    <SubtleTextbox
                        {...validatingRegister('avatarScale', {})}
                        showLabel
                        label="Scale"
                        isDirty={Boolean(formState.dirtyFields.avatarScale)}
                        onReset={() => resetField('avatarScale')}
                        error={harvestErrors(
                            'avatarScale',
                            formState,
                            mutationResults
                        )}
                    />
                    <SubtleTextbox
                        {...validatingRegister('avatarPosition', {})}
                        showLabel
                        label="Position"
                        isDirty={Boolean(formState.dirtyFields.avatarPosition)}
                        onReset={() => resetField('avatarPosition')}
                        error={harvestErrors(
                            'avatarPosition',
                            formState,
                            mutationResults
                        )}
                    />
                </FlexContainer>
                <Heading style={{padding: 0, margin: 0}} component="h3">
                    Intro
                </Heading>
                <UserNameInput
                    defaultValue={activeOrg.label}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <LabelInput
                    defaultValue={activeOrg.label}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <LegalNameInput
                    defaultValue={activeOrg.legalName}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <OrgTypeSelect
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <IndustrySelect
                    defaultValue={activeOrg.industry}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <Heading style={{padding: 0, margin: 0}} component="h3">
                    Bio
                </Heading>
                <AboutInput
                    defaultValue={activeOrg.definition !== undefined
                        ? activeOrg.definition : null}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <Heading style={{padding: 0, margin: 0}} component="h3">
                    Locale
                </Heading>
                <LocationSelect
                    defaultValue={activeOrg.locale.location}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <LanguageSelect
                    defaultValue={activeOrg.locale.language}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <CurrencySelect
                    defaultValue={activeOrg.locale.currency}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <TimezoneSelect
                    defaultValue={activeOrg.locale.timezone || 'Etc/UTC'}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <Heading style={{padding: 0, margin: 0}} component="h3">
                    Website
                </Heading>
                <URLInput
                    name={'domainName'}
                    label={"Web Domain"}
                    tooltip={"Web Domain"}
                    defaultValue={activeOrg.social.linkedin}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <Heading style={{padding: 0, margin: 0}} component="h3">
                    Social
                </Heading>
                <LinkedInInput
                    defaultValue={activeOrg.social.linkedin}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <FacebookInput
                    defaultValue={activeOrg.social.facebook}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <InstagramInput
                    defaultValue={activeOrg.social.instagram}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <GithubInput
                    defaultValue={activeOrg.social.github}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                    formState={formState}
                    resetField={resetField}
                />
                <FormResults
                    {...mutationResults}
                    onSuccess={closeModal}
                    validationErrors={formState.errors}
                    onSuccessDelay={500}
                />
            </AppModal>
        );
    } else {
        return null
    }
};

export default OrgSettingsModal;
