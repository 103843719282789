import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useActiveOrgData from 'features/ontology/hooks/useActiveOrgData';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import useActiveEntityData from 'features/ontology/hooks/useActiveEntityData';
import useActiveIndividualData from 'features/ontology/hooks/useActiveIndividualData';
import { isKnownIndividual } from 'features/ontology/types/individualTypes';
import CommentList from 'common/comments/components/CommentList';
import {GeneralCommentCreationId, CommentCreationId, BaseComment,} from 'common/comments/types/commentTypes';
import React, {FunctionComponent, useEffect, useState} from 'react';
// import useActiveDatasetData from "../../ontology/hooks/useActiveDatasetData";

export const IndividualCommentsList: FunctionComponent = () => {
	const { activeItem, ...individualLoadState } = useActiveIndividualData();

	const identifier: CommentCreationId | null = activeItem
		? isKnownIndividual(activeItem.individual)
			? { kind: 'other', objectId: activeItem.individual._id }
			: {
					kind: 'newIndividualComment',
					primaryAttributeValue: activeItem.valueAtPrimaryAttr,
					attributeId: activeItem.primaryAttrId,
			  }
		: null;

	const [reactionsComment, setReactionsComment] = useState<BaseComment | null>(null);
	const [isReactionsOpen, setIsReactionsOpen] = useState(false);
	const closeReactions = () => setIsReactionsOpen(false);

	return (
		<DisplayOnLoad {...individualLoadState}>
			{activeItem && identifier && (
				<CommentList
					objectIdentifier={identifier}
					objectType={'Individual'}
					canEdit={true}
					overrides={{
						list: {
							maxHeight: '100%',
						},
					}}
				/>
			)}
		</DisplayOnLoad>
	);
};

export const EntityCommentsList: FunctionComponent = () => {
	const { activeItem: activeEntity, ...entityLoadState } =
		useActiveEntityData();

	const identifier: GeneralCommentCreationId | null = activeEntity
		? {
				kind: 'other',
				objectId: activeEntity._id,
		  }
		: null;

	const canEdit = activeEntity
		? activeEntity.userContext.permissions.edit
		: false;
	const [reactionsComment, setReactionsComment] = useState<BaseComment | null>(null);
	const [isReactionsOpen, setIsReactionsOpen] = useState(false);
	const closeReactions = () => setIsReactionsOpen(false);

	return (
		<DisplayOnLoad {...entityLoadState}>
			{activeEntity && identifier && (
				<CommentList
					objectIdentifier={identifier}
					objectType={'Entity'}
					canEdit={canEdit}
					overrides={{
						list: {
							maxHeight: 'auto',
						},
					}}
				/>
			)}
		</DisplayOnLoad>
	);
};

export const DomainCommentsList: FunctionComponent = () => {
	const { activeItem: activeDomain, ...domainLoadState } =
		useActiveDomainData();

	const identifier: GeneralCommentCreationId | null = activeDomain
		? {
				kind: 'other',
				objectId: activeDomain._id,
		  }
		: null;

	const canEdit = activeDomain
		? activeDomain.userContext.permissions.edit
		: false;

	const [reactionsComment, setReactionsComment] = useState<BaseComment | null>(null);

	return (
		<DisplayOnLoad {...domainLoadState}>
			{activeDomain && identifier && (
				<CommentList
					objectIdentifier={identifier}
					objectType={'Domain'}
					canEdit={canEdit}
					overrides={{
						list: {
							maxHeight: 'auto',
						},
					}}
				/>
			)}
		</DisplayOnLoad>
	);
};

export const OrgCommentsList: FunctionComponent = () => {
	const { activeItem: activeOrg, ...orgLoadState } =
		useActiveOrgData();

	const canEdit = activeOrg
		? activeOrg.userContext.permissions.edit
		: false;

	return (
		<DisplayOnLoad {...orgLoadState}>
			{activeOrg && (
				<CommentList
					objectIdentifier={activeOrg
					? {
							kind: 'other',
							objectId: activeOrg._id,
					  }
					: null}
					objectType={'Organization'}
					canEdit={canEdit}
					isDisplayed={true}
					overrides={{list: {maxHeight: 'auto',},}}
				/>
			)}
		</DisplayOnLoad>
	);
};

// const SourceCommentsList: FunctionComponent = () => {
// 	const { activeItem: activeOrg, ...orgLoadState } =
// 		useActiveOrgData();
//
// 	const identifier: GeneralCommentCreationId | null = activeOrg
// 		? {
// 				kind: 'other',
// 				objectId: activeOrg._id,
// 		  }
// 		: null;
//
// 	const canEdit = activeOrg
// 		? activeOrg.userContext.permissions.edit
// 		: false;
//
// 	return (
// 		<DisplayOnLoad {...orgLoadState}>
// 			{activeOrg && identifier && (
// 				<CommentList
// 					parentObjectIdentifier={identifier}
// 					objectType={'Organization'}
// 					canEdit={canEdit}
// 					overrides={{
// 						list: {
// 							maxHeight: 'auto',
// 						},
// 					}}
// 				/>
// 			)}
// 		</DisplayOnLoad>
// 	);
// };
//
// const CatalogCommentsList: FunctionComponent = () => {
// 	const { activeItem: activeOrg, ...orgLoadState } =
// 		useActiveOrgData();
//
// 	const identifier: GeneralCommentCreationId | null = activeOrg
// 		? {
// 				kind: 'other',
// 				objectId: activeOrg._id,
// 		  }
// 		: null;
//
// 	const canEdit = activeOrg
// 		? activeOrg.userContext.permissions.edit
// 		: false;
//
// 	return (
// 		<DisplayOnLoad {...orgLoadState}>
// 			{activeOrg && identifier && (
// 				<CommentList
// 					parentObjectIdentifier={identifier}
// 					objectType={'Organization'}
// 					canEdit={canEdit}
// 					overrides={{
// 						list: {
// 							maxHeight: 'auto',
// 						},
// 					}}
// 				/>
// 			)}
// 		</DisplayOnLoad>
// 	);
// };

// const DataCommentsList: FunctionComponent = () => {
// 	const { activeItem: activeDataset, ...orgLoadState } =
// 		useActiveSourceData();
//
// 	const identifier: GeneralCommentCreationId | null = activeDataset
// 		? {
// 				kind: 'other',
// 				objectId: activeDataset._id,
// 		  }
// 		: null;
//
// 	const canEdit = activeDataset
// 		? activeDataset.userContext.permissions.edit
// 		: false;
//
// 	return (
// 		<DisplayOnLoad {...orgLoadState}>
// 			{activeOrg && identifier && (
// 				<CommentList
// 					parentObjectIdentifier={identifier}
// 					objectType={'Organization'}
// 					canEdit={canEdit}
// 					overrides={{
// 						list: {
// 							maxHeight: 'auto',
// 						},
// 					}}
// 				/>
// 			)}
// 		</DisplayOnLoad>
// 	);
// };

// const DataColumnCommentsList: FunctionComponent = () => {
// 	const { activeItem: activeOrg, ...orgLoadState } =
// 		useActiveOrgData();
//
// 	const identifier: GeneralCommentCreationId | null = activeOrg
// 		? {
// 				kind: 'other',
// 				objectId: activeOrg._id,
// 		  }
// 		: null;
//
// 	const canEdit = activeOrg
// 		? activeOrg.userContext.permissions.edit
// 		: false;
//
// 	return (
// 		<DisplayOnLoad {...orgLoadState}>
// 			{activeOrg && identifier && (
// 				<CommentList
// 					parentObjectIdentifier={identifier}
// 					objectType={'Organization'}
// 					canEdit={canEdit}
// 					overrides={{
// 						list: {
// 							maxHeight: 'auto',
// 						},
// 					}}
// 				/>
// 			)}
// 		</DisplayOnLoad>
// 	);
// };
