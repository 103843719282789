import { skipToken } from '@reduxjs/toolkit/dist/query';
import {useGetDomainAttributesQuery, useGetDomainsQuery} from 'features/api';
import { mergeQueryStates } from 'features/api/helpers';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import {createSelector} from "@reduxjs/toolkit";
import {BaseAttribute} from "../types/attributeTypes";

type QueryResponse = Omit<ReturnType<typeof useGetDomainsQuery>, 'refetch'>;

const selectActiveDomainAttributes = createSelector(
	(res: QueryResponse) => res.data,
	(data: QueryResponse['data']) =>
		data ?? []
);

const useActiveDomainAttributes = (domainName?: string) => {

	const { activeItem: activeDomain, ...domainLoadState } = useActiveDomainData(
		domainName ?? undefined);

	const { activeItem: activeItems, ...attributeLoadState } = useGetDomainAttributesQuery(
		activeDomain ? { domainId: activeDomain._id } : skipToken,
		{
			selectFromResult: (res) => ({
				...res,
				activeItem: selectActiveDomainAttributes(
					res,
				) as [] | BaseAttribute[],
			}),
		}
	);

	const combinedLoadState = mergeQueryStates(domainLoadState, attributeLoadState);

	return {
		...combinedLoadState,
		activeItems,
	};
};

export default useActiveDomainAttributes;
