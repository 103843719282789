import {usePivotEntityMutation} from '../../api';
import {EntityActionFormProps} from '../common/commonTypes';
import {renderDerivationSubfields} from '../common/jsxHelpers';
import {
    pivotEntityFormDefaults,
    pivotEntityFormToPayload,
    pivotEntityPayloadToParams,
} from './pivotEntityHelpers';
import {PivotEntityFormValues} from './pivotEntityTypes';
import React, {FunctionComponent, useCallback} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import {useAppDispatch} from "../../../app/hooks";
import useModalType from "../../HUD/hooks/useModalType";
import {setModalType} from "../../HUD/state/HUDSlice";
import {PrependAttrFormValues} from "../prependAttribute/prependAttributeTypes";

const PivotEntityForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
	const {resource, canEdit} = modalProps;

    const formDefaults = pivotEntityFormDefaults(modalProps);

    const {
        handleSubmit,
        register,
        reset,
        resetField,
        formState
    } = useForm<PivotEntityFormValues>(
        {
            defaultValues: formDefaults,
        }
    );

    const [pivot, mutationResults] = usePivotEntityMutation();

    const validatingRegister = useCallback(
        (inputName: keyof PivotEntityFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const onSubmit: SubmitHandler<PivotEntityFormValues> = (vals, e) => {
        e?.preventDefault();
        pivot(
            pivotEntityPayloadToParams(
                pivotEntityFormToPayload(vals),
                resource._id
            )
        );
    };

    return (
        <AppModal
            label={"Pivot Entity"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit ? canEdit : true}>
            {renderDerivationSubfields({
                isDerivation: true,
                formState,
                resetField,
                validatingRegister,
                mutationResults
            })}
        </AppModal>
    );
};

export default PivotEntityForm;
