import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";

const LegalNameInput: FunctionComponent<ActionFieldProps> = (
    {
        defaultValue,
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleTextbox
        {...validatingRegister('legalName', {})}
        label="Legal name"
        defaultValue={defaultValue}
        isDirty={Boolean(formState.dirtyFields.legalName)}
        onReset={() => resetField('legalName')}
        error={harvestErrors(
            'legalName',
            formState,
            mutationResults
        )}
        showLabel
    />;
}

export default LegalNameInput;
