import React, {FunctionComponent} from "react";
import {StyledSourceFormLabel} from "../../../features/browser/components/styledComponents";

const GCSKeyInput: FunctionComponent<{ registerRequired: any; }> = ({registerRequired}) => {
    return (
        <>
            <StyledSourceFormLabel
                htmlFor="upload-json-gcs-key"
                style={{marginTop: '16px'}}
            >
                Upload a JSON service account key file:
            </StyledSourceFormLabel>
            <input
                {...registerRequired('keyFile', {value: null})}
                type="file"
                id="upload-json-gcs-key"
                accept="application/json"
            />
        </>
    );
}

export default GCSKeyInput;
