import React, {FunctionComponent, useState} from "react";
import Button from "../../buttons/Button";
import Typography from "../../text/Typography";
import styled from "styled-components";
import {BaseComment} from "../types/commentTypes";
import useCurrentUser from "../../../features/authentication/hooks/useUser";
import {PopoverBase} from "../../popovers/Popover";
import UserPopover from "../../popovers/UserPopover";
import FlexContainer from "../../FlexContainer";
import theme from "../../theme/theme";
import ProfileAvatar
    from "../../../features/navigation/components/Navbar/ProfileAvatar";
import {AccordionItem} from "react-accessible-accordion";
import FullName from "../../text/FullName";
import SeeMoreButton from "../../buttons/SeeMoreButton";
import ReactionsButton from "../../buttons/ReactionsButton";
import CommentSummaryButton from "../../buttons/CommentSummaryButton";
import ReactButton from "../../buttons/ReactButton";
import ReplyButton from "../../buttons/ReplyButton";
import PostedAgo from "../../text/PostedAgo";
import EditsButton from "../../buttons/EditsButton";
import MenuButton from "../../buttons/MenuButton";
import DestroyButton from "../../buttons/DestroyButton";
import CommentList from "./CommentList";
import CreateCommentForm from "./CreateCommentForm";
import {setModalType} from "../../../features/HUD/state/HUDSlice";
import CommentMenu from "../../menus/CommentMenu";
import {useNavigate} from "react-router";

const ShareButton: FunctionComponent<{ onClick: () => void }> = () => {
    return <Button style={{padding: 0, margin: 0}}>
        <Typography variant='button'>
            Share
        </Typography>
    </Button>
};

export const ListItemAvatar = styled.div`
	flex: 0 0 5%;
	margin: 0 10px 0 5px;
`;


const Comment: FunctionComponent<{ comment: BaseComment }> = ({comment}) => {
    const [repliesOpen, setRepliesOpen] = useState<boolean>(false);
    const [replyFormOpen, setReplyFormOpen] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const currentUser = useCurrentUser();
    const canEdit = comment && comment.userContext && (comment.userContext.permissions.edit
        || comment.userContext.permissions.admin);

    const [actionSubmenu, setActionSubmenu] = useState<string | null>(null);

    const navigator = useNavigate();
    const [popoverUser, setPopoverUser] = useState<any>(null);
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    return (
        <div onMouseLeave={() => setPopoverUser(null)}>
            <PopoverBase
                offset={2}
                anchorEl={anchor}
                handleClickAway={() => setPopoverUser(null)}
                open={!!popoverUser}>
                {popoverUser && <UserPopover user={popoverUser}/>}
            </PopoverBase>
            <FlexContainer
                style={{
                    width: '100%',
                    padding: theme.spacing(0),
                    margin: `${theme.spacing(2)} 0`
                }}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
                gap={'.5rem'}
                background="default"
                aria-labelledby={`currentUser-${currentUser._id}`}
            >
                <FlexContainer gap={'1rem'}>
                    {/*<UserItem*/}
                    {/*	user={comment.author}*/}
                    {/*	canEdit={canEdit}*/}
                    {/*	onClick={(e: any) => {*/}
                    {/*		setAnchor(e.currentTarget);*/}
                    {/*		setPopoverUser(comment.author);*/}
                    {/*	}}*/}
                    {/*		  // controls={controls}*/}
                    {/*/>*/}
                    {/*<ListItemContent style={{*/}
                    {/*	alignItems: 'center',*/}
                    {/*	justifyContent: 'center',*/}
                    {/*	margin: '2px 0 2px 2px',*/}
                    {/*	paddingLeft: '8px'*/}
                    {/*}}>*/}
                    {/*<FullName user={user} styles={{ padding: '1px' }}/>*/}
                    {/*<LastActive user={user} position='flex-start' />*/}
                    {/*<Username user={user} />*/}
                    {/*<Error resource={user} handleError={handleError} />*/}
                    {/*</ListItemContent>*/}
                    <ProfileAvatar
                        onHover={(e: any) => {
                            setAnchor(e.currentTarget);
                            setPopoverUser(comment.author);
                        }}
                        onClick={() => navigator(`/${comment.author.username}`, { replace: false })}
                        // onExit={(e: any) => {
                        //     setAnchor(null);
                        //     setPopoverUser(null);
                        // }}
                        initial={comment.author.firstName}
                        profileImageURL={comment?.author?.avatar?.medium?.url
                            ? comment?.author?.avatar?.medium?.url : ""}
                    />
                    <FlexContainer
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                        gap={'.5rem'}
                        onClick={() => setIsExpanded(true)}
                        background="default"
                        // position={position}
                        aria-labelledby={`currentUser-${currentUser._id}`}
                        // style={{ minWidth: '400px'}}
                    >
                        <AccordionItem uuid={comment._id}>
                            <FlexContainer
                                flexDirection={'column'}
                                gap={'.25rem'}>
                                <FullName
                                    onClick={() => navigator(`/${comment.author.username}`, { replace: false })}
                                    onHover={(e: any) => {
                                        setAnchor(e.currentTarget);
                                        setPopoverUser(comment.author);
                                    }}
                                    user={comment.author}
                                />
                                {comment.message.text.length > 175 && !isExpanded
                                    ? <Typography variant='body1'>
                                        {comment.message.text.slice(0, 175)}
                                        <SeeMoreButton
                                            onClick={() => setIsExpanded(true)}/>
                                    </Typography>
                                    : <Typography variant='body1'>
                                        {comment.message.text}
                                    </Typography>
                                }
                                <FlexContainer justifyContent={'space-between'}
                                               gap={'.5rem'}>
                                    <ReactionsButton resource={comment}/>
                                    <CommentSummaryButton
                                        onClick={() => setRepliesOpen((p) => !p)}
                                        comment={comment}
                                    />
                                </FlexContainer>
                                <FlexContainer
                                    justifyContent={'space-between'}
                                    gap={'.5rem'}>
                                    <FlexContainer
                                        padding={0}
                                        margin={0}
                                        gap={'.5rem'}
                                        alignItems={'flex-start'}
                                        justifyContent={'flex-start'}>
                                        <ReactButton
                                            objectId={comment._id}
                                            objectType={'Comment'}
                                        />
                                        <ReplyButton
                                            onClick={() => setReplyFormOpen((p) => !p)}
                                        />
                                        <PostedAgo resource={comment}/>
                                    </FlexContainer>
                                    {/*<ShareButton onClick={() => {}} />*/}
                                </FlexContainer>
                            </FlexContainer>
                            {comment.hasEdits ? <EditsButton/> : null}
                            {repliesOpen
                                ? <CommentList
                                    canEdit={true}
                                    isDisplayed={repliesOpen}
                                    objectIdentifier={{
                                        objectId: comment._id,
                                        kind: 'other'
                                    }}
                                    objectType='Comment'
                                /> : null}
                            <CreateCommentForm
                                isOpen={replyFormOpen}
                            />
                        </AccordionItem>
                    </FlexContainer>
                </FlexContainer>
                <CommentMenu
                    resource={comment}
                    canEdit={true}
                />
            </FlexContainer>
        </div>
    )
}

export default Comment;
