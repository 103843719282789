import { ActionDescriptor } from '../common/commonTypes';
import MoveAttributeForm from './MoveAttributeForm';

const moveAttribute: ActionDescriptor = {
	form: MoveAttributeForm,
	requiresEdit: true,
	modalType: 'moveAttribute',
	kind: 'modal',
	divider: false,
	actionTitle: 'Move',
};

export default moveAttribute;
