import React, {FunctionComponent} from "react";
import IconButton from "./IconButton";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";

export const LinkedInButton: FunctionComponent<{resource: any}> = ({resource}) => {
	return resource && resource.social.linkedin
			? resource._object === 'Organization'
				? <a href={`https://linkedin.com/company/${resource.social.linkedin}`} target='_blank' rel="noreferrer">
					<IconButton
						size={'xs'}
						iconSize={'lg'}
						fillColor={'cyan'}
						style={{padding: 0}}
						icon={faLinkedin as any}
					/>
				</a>
				: <a href={`https://linkedin.com/in/${resource.social.linkedin.username}`} target='_blank' rel="noreferrer">
					<IconButton
						size={'xs'}
						iconSize={'lg'}
						fillColor={'cyan'}
						icon={faLinkedin as any}
					/>
				</a> : null;
}

export default LinkedInButton;
