import {
	GetNotificationsParams,
	NotificationSubject,
} from './types/notificationTypes';


export const getURLStumpFromObjectType = (objectType: NotificationSubject) => {
	switch (objectType) {
		case 'Organization':
			return 'orgs';
		case 'User':
			return 'users';
		case 'DataSource':
			return 'sources';
		case 'DataCatalog':
			return 'catalogs';
		case 'Dataset':
			return 'datasets';
		case 'DataColumn':
			return 'columns';
		case 'DataRow':
			return 'rows';
		case 'DataValue':
			return 'values';
		case 'DataType':
			return 'types';
		case 'Metric':
			return 'metrics';
		case 'Fact':
			return 'facts';
		case 'Insight':
			return 'insights';
		case 'Domain':
			return 'domains';
		case 'Entity':
			return 'entities';
		case 'Attribute':
			return 'attributes';
		case 'Individual':
			return 'individuals';
	}
};

export const buildNotificationsQueryURL = ({
	objectId,
	objectType,
	perPage = 10,
	page = 1
}: GetNotificationsParams) =>
	`/${getURLStumpFromObjectType(objectType)}/${objectId}/notifications?page=${page}&per_page=${perPage}`;

export const buildParametrizedNotificationsQueryURL = (
	objectType: NotificationSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectIdnNotifications?page=:page&per_page=:perPage`;
