import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";

const FirstNameInput: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleTextbox
        {...validatingRegister('firstName')}
        label="First name"
        isDirty={Boolean(formState.dirtyFields.firstName)}
        onReset={() => resetField('firstName')}
        error={harvestErrors(
            'firstName',
            formState,
            mutationResults
        )}
        showLabel
    />;
}

export default FirstNameInput;
