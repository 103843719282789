import { toStringIfNumber } from 'common/utils/typeUtils';
import useActiveIndividualsMeta from 'features/ontology/hooks/useActiveIndividualsMeta';
import { isPrimaryIdentity } from 'features/ontology/typeGuards/attributeGuards';
import { PrimaryIdentityAttribute } from 'features/ontology/types/attributeTypes';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { defaultConfig } from './useIndividualsMeta';

const 	useActiveIndividualData = (identifier: string | null | undefined = undefined) => {
	const { individual: routeIndividual } = useParams<{ individual: string }>();

	const { preparedData, ...individualsLoadState } =
		useActiveIndividualsMeta(defaultConfig, identifier);

	const activeIndividual = useMemo(() => {
		if (preparedData) {
			const primaryId = preparedData.attributes.find(
				isPrimaryIdentity
			) as PrimaryIdentityAttribute;

			const maybeFound = preparedData.data.find(
				(ind) =>
					toStringIfNumber(ind[primaryId.name]) === routeIndividual
			);

			return maybeFound
				? {
						valueAtPrimaryAttr: maybeFound[primaryId.name],
						individual: maybeFound,
						primaryAttrName: primaryId.name,
						primaryAttrId: primaryId._id,
						primaryEntityName: primaryId.entity.singular,
				  }
				: null;
		}

		return null;
	}, [preparedData, routeIndividual]);

	return {
		activeItem: activeIndividual,
		activeAttributes: preparedData?.attributes,
		...individualsLoadState,
	};
};

export default useActiveIndividualData;
