import React, {FunctionComponent} from "react";
import Typography from "../text/Typography";
import {createBasicValidation} from "../../features/actions/common/helpers";
import {formFieldHasErrors, genErrorIdFromLabel} from "../Form";
import {harvestErrors, SubtleTextbox} from "./SubtleTextBox";

const StaticValueInput: FunctionComponent<{ name: string; validatingRegister: any; formState: any }> = (
    {name, validatingRegister, formState}
) => {
    return <SubtleTextbox
        label=" If not using a second attribute, enter a static value to use"
        // defaultValue={defaultValue}
        // isDirty={Boolean(formState.dirtyFields[name])}
        // onReset={() => resetField(name)}
        id={name}
        {...validatingRegister(name, createBasicValidation(name))}
        aria-errormessage={genErrorIdFromLabel(name)}
        aria-invalid={formFieldHasErrors(name, formState)}
        // error={harvestErrors(
        //     name,
        //     formState,
        //     mutationResults
        // )}
        showLabel
    />;
}

export default StaticValueInput;
