import { BaseEntity } from '../../ontology/types/entityTypes';
import { emptyStringToNull } from '../common/helpers';
import {
	PivotEntityPayload,
	PivotEntityFormValues,
	PivotEntityParams,
} from './pivotEntityTypes';

export const pivotEntityFormDefaults = (
	entity: BaseEntity
): PivotEntityFormValues => {
	return {
		singular: entity.singular,
		plural: entity.plural,
		definition: entity.definition ?? '',
	};
};

export const pivotEntityFormToPayload = (
	formValues: PivotEntityFormValues
): PivotEntityPayload => {
	return {
		...formValues,
		definition: emptyStringToNull(formValues.definition),
	};
};

export const pivotEntityPayloadToParams = (
	payload: PivotEntityPayload,
	entityId: number
): PivotEntityParams => ({
	body: payload,
	entityId,
});
