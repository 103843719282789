import React, {FunctionComponent} from "react";
import {BaseAttribute} from "../../features/ontology/types/attributeTypes";
import MenuButton from "../buttons/MenuButton";
import MenuPanel from "./MenuPanel";
import SubMenuButton from "../buttons/SubMenuButton";
import TextButton from "../buttons/TextButton";
import {
    DESCRIBE_ATTRIBUTE,
    DESTROY_ATTRIBUTE,
    SHARE_RESOURCE,
    ADD_COMPONENT,
    VIEW_ATTRIBUTE,
    VIEW_ENTITY,
    VIEW_DOMAIN,
    VIEW_ORG,
    VIEW_LINEAGE,
    SHOW_CREDITS, COPY_SHARE_URL
} from "../../features/graph/CONSTANTS";
import {BaseEntity} from "../../features/ontology/types/entityTypes";
import {FuturemodelAttrActions} from "../../features/actions/common/commonTypes";
import describeAttribute from "../../features/actions/describeAttribute";
import relateAttribute from "../../features/actions/relateAttribute";
import aggregateAttribute from "../../features/actions/aggregateAttribute";
import loadAttribute from "../../features/actions/loadAttribute";
import moveAttribute from "../../features/actions/moveAttribute";
import translateValue from "../../features/actions/translateValue";
import mergeAttribute from "../../features/actions/mergeAttribute";
import appendAttribute from "../../features/actions/appendAttribute";
import prependAttribute from "../../features/actions/prependAttribute";
import splitAttribute from "../../features/actions/splitAttribute";
import addAttribute from "../../features/actions/addAttribute";
import subtractAttribute from "../../features/actions/subtractAttribute";
import multiplyAttribute from "../../features/actions/multiplyAttribute";
import divideAttribute from "../../features/actions/divideAttribute";
// import makePrimaryIdentity from "../../features/actions/makePrimaryIdentity/MakePrimaryIdentityForm";
import ShareResourceModal from "../modals/ShareResourceModal";

export const actionMap: FuturemodelAttrActions = {
    identity: [
        // makePrimaryIdentity
    ],
        // restrictEntity,
    relation: [
        aggregateAttribute,
    ],
    nonFunctionalRelation: [
        aggregateAttribute,
    ],
    functionalRelation: [
        loadAttribute,
    ],
    category: [
        translateValue,
        mergeAttribute,
        appendAttribute,
        prependAttribute,
        splitAttribute,
    ],
    quantity: [
        addAttribute,
        subtractAttribute,
        multiplyAttribute,
        divideAttribute,
        mergeAttribute,
    ],
    event: [
        mergeAttribute,
        // deriveDuration,
    ],
    location: [
        mergeAttribute,
    ],
    media: [
        mergeAttribute,
    ],
};

const viewActions = [
    {
        actionTitle: VIEW_ATTRIBUTE,
        kind: 'link',
        requiresEdit: false,
        location: '/:org/:domain/:entity?attribute=:attribute'
    },
    {
        actionTitle: VIEW_ENTITY,
        kind: 'link',
        requiresEdit: false,
        location: '/:org/:domain/:entity'
    },
    {
        actionTitle: VIEW_DOMAIN,
        kind: 'link',
        requiresEdit: false,
        location: '/:org/:domain'
    },
    {
        actionTitle: VIEW_ORG,
        kind: 'link',
        requiresEdit: false,
        location: '/:org'
    },
    {
        actionTitle: VIEW_LINEAGE,
        kind: 'modal',
        modalType: 'attributeLineage',
        requiresEdit: false,
    },
    {
        actionTitle: SHOW_CREDITS,
        modalType: 'attributeCredits',
        kind: 'modal',
        requiresEdit: false,
        divider: true
    },
]

const shareActions = [
    {
        actionTitle: 'Share',
        requiresEdit: false,
        kind: 'subMenu',
        items: [
            {
                actionTitle: SHARE_RESOURCE,
                kind: 'modal',
                requiresEdit: false,
                modalType: 'shareResource',
            },
            {
                actionTitle: COPY_SHARE_URL,
                requiresEdit: false,
                subMenu: 'share',
                kind: 'action',
                callback: 'copyLink',
                divider: false,
            },
        ]
    },
]

export const ShareButton: FunctionComponent = () => {
    return <SubMenuButton label={'Share'}>
        <TextButton
            color='primary'
            fillColor='transparent'
            text={'Copy Object Link'}
        />
    </SubMenuButton>
}

export const AttributeMenu: FunctionComponent<{ attribute: BaseAttribute; entity?: BaseEntity; canEdit: boolean; }> = (
    {attribute, canEdit, entity}
) => {

    const typeActions = actionMap[attribute.type];

    const editActions = [
        describeAttribute,
        moveAttribute,
    ];

    if (attribute.type !== 'relation') {
        editActions.push(relateAttribute);
    } else if (attribute.isFunctional) {
        editActions.push(actionMap['functionalRelation'][0]);
    } else {
        editActions.push(actionMap['nonFunctionalRelation'][0]);
    }

    if (attribute.isComposite) {
        //  ,
        // {
        //     actionTitle: 'Transform',
        //     requiresEdit: true,
        //     kind: 'subMenu',
        //     divider: true,
        //     // items: [...typeActions],
        // }
        editActions.push(
            {
                actionTitle: 'Transform',
                requiresEdit: true,
                kind: 'subMenu',
                divider: true,
                items: [
                    ...typeActions,
                    {
                        actionTitle: ADD_COMPONENT,
                        kind: 'modal',
                        requiresEdit: true,
                        divider: false,
                        modalType: 'addComponent',
                    }
                ],
            })
    } else {
        editActions.push(
            {
                actionTitle: 'Transform',
                requiresEdit: true,
                kind: 'subMenu',
                divider: true,
                items: typeActions,
            })
    }

    return <MenuButton placement={'right-start'}>
        {attribute ? <>
                <MenuPanel
                    resource={attribute}
                    entity={entity}
                    canEdit={canEdit}
                    actions={[
                        ...viewActions,
                        ...editActions,
                        ...shareActions
                    ]}
                />
            </>
            : null}
    </MenuButton>;
};

export default AttributeMenu;
