import { ActionDescriptor } from '../common/commonTypes';
import RelateAttributeForm from './RelateAttributeForm';

const relateAttribute: ActionDescriptor = {
	form: RelateAttributeForm,
	actionTitle: 'Relate',
	divider: false,
	requiresEdit: true,
	kind: 'modal',
	modalType: 'relateAttribute'
};

export default relateAttribute;
