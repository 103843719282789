import { useAppDispatch } from '../../app/hooks';
import {FormResults,} from '../Form';
import useModalType from '../../features/HUD/hooks/useModalType';
import {useCreateDomainMutation} from '../../features/api';
import useActiveOrgData from '../../features/ontology/hooks/useActiveOrgData';
import React, {FunctionComponent, useCallback, useEffect, useRef} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import AppModal from "./AppModal";
import {harvestErrors, SubtleTextbox} from "../inputs/SubtleTextBox";
import {BaseDomain} from "../../features/ontology/types/domainTypes";

interface CreateDomainFormValues extends BaseDomain {}

// Accept orgId as a prop to avoid needing complicated route matching in tests for this form
export const DomainCreationFormBase: FunctionComponent = () => {
	// const {
	// 	register,
	// 	handleSubmit,
	// 	formState,
	// 	reset: resetForm,
	// } = useForm({
	// 	defaultValues: {
	// 		label: '',
	// 	},
	// });

	const appDispatch = useAppDispatch();

	const {register, formState, resetField, handleSubmit, reset} = useForm<CreateDomainFormValues>();

	const { activeItem } = useActiveOrgData();

	const syncMutationResult = useRef<any>(null);

	const {modalProps, closeModal} = useModalType();
	// const {orgId, canEdit} = modalProps;
	// const modalType = useModalType();
	//
	// const creationFormOpen = modalType === 'domainCreationForm';

	useEffect(() => {
		if (formState.isDirty) {
			reset();
		}
	}, [formState, reset]);

	const validatingRegister = useCallback(
		(inputName: keyof CreateDomainFormValues) =>
			register(inputName, {
				required: `${inputName} is required`,
			}),
		[register]
	);


	// const handleClose = () => {
	// 	if (syncMutationResult.current) {
	// 		syncMutationResult.current.unsubscribe();
	// 		syncMutationResult.current = null;
	// 	}
	// 	appDispatch(setModalType(null));
	// };

	const [createDomain, mutationResults] = useCreateDomainMutation();

	const onSubmit: SubmitHandler<CreateDomainFormValues> = (formVals) => {

		createDomain({
			orgId: activeItem._id,
			...formVals
		});
	};


	return (
		<AppModal
			label={"Create Domain"}
			isOpen={true}
			isDirty={formState.isDirty}
			onClose={closeModal}
			onSubmit={handleSubmit(onSubmit)}
			canEdit={true}>
			<SubtleTextbox
				{...validatingRegister('label')}
				isDirty={Boolean(formState.dirtyFields.label)}
				onReset={() => resetField('label')}
				showLabel
				error={harvestErrors('label', formState, mutationResults)}
				label="Label"
			/>
			<FormResults
				{...mutationResults}
				validationErrors={formState.errors}
				onSuccess={closeModal}
				onSuccessDelay={1500}
			/>
		</AppModal>
	);
};

const DomainCreationForm: FunctionComponent = () => {

	return <DomainCreationFormBase />;
};

export default DomainCreationForm;
