import Typography from "../text/Typography";
import {Link} from "react-router-dom";
import React from "react";

const ForgotPasswordLink = () => {

    return <Link
        to="/reset-password-request"
        style={{
            textDecoration: 'none',
            textAlign: 'center',
            color: 'inherit',
            padding: '10px 0 5px',
        }}
    >
        <Typography variant={'button'} color={'cyan'}>
            Forgot password?
        </Typography>
    </Link>
};

export default ForgotPasswordLink;
