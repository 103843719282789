import React, {FunctionComponent} from "react";
import {BaseComment} from "../comments/types/commentTypes";
import Button from "./Button";
import Typography from "../text/Typography";

const CommentSummaryButton: FunctionComponent<{comment: BaseComment, onClick: () => void;}> = ({onClick, comment}) => {
	if (comment && comment.summary && comment.summary?.aggregatedTotalComments > 0) {
    	return <Button onClick={onClick} style={{padding: 0, margin: 0, textAlign: 'left', overflow: 'visible'}}>
			<Typography variant='button'>
				{comment.summary?.aggregatedTotalComments} Comments
			</Typography>
		</Button>
	} else {
		return null
	}
};

export default CommentSummaryButton;
