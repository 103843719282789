import {
	ActivitySubject,
	BaseActivity, GetActivitiesResponse,
} from '../types/activityTypes';
import {
	createContext,
	Dispatch, FunctionComponent, ReactNode,
	Reducer,
	useContext,
	useEffect, useReducer,
	useRef, useState
} from 'react';
import {
	useGetActivityQuery,
	useGetDomainGraphQuery,
	useGetOrgGraphQuery
} from "../../../features/api";
import {GraphAction, TwoDGraphState} from "../../../features/graph/state/actions";
import reducer from "../../../features/graph/state/pureReducer";
import {skipToken} from "@reduxjs/toolkit/query";


export interface ActivityState {
	items: BaseActivity[];
	isNextPageLoading: boolean;
	hasNextPage: boolean;
	nextPage: number;
}

export const initialState: ActivityState = {
	items: [],
	isNextPageLoading: false,
	hasNextPage: true,
	nextPage: 1,
};

export const SET_LOADING_STATE = 'activityContext/setLoadingState';

export interface SetLoadingState {
	type: typeof SET_LOADING_STATE;
	payload: boolean;
}

export const SET_ACTIVITY_DATA = 'activityContext/setActivityData';
export const SET_HAS_NEXT_PAGE = 'activityContext/setHasNextPage';
export const SET_NEXT_PAGE = 'activityContext/setNextPage';

interface SetActivityData {
	type: typeof SET_ACTIVITY_DATA;
	payload: BaseActivity[];
}

interface SetHasNextPage {
	type: typeof SET_HAS_NEXT_PAGE;
	payload: boolean;
}

interface SetNextPage {
	type: typeof SET_NEXT_PAGE;
	payload: number;
}


export type PureActivityAction =
	| SetHasNextPage
	| SetActivityData
	| SetNextPage
	| SetLoadingState;

export type ActivityAction = PureActivityAction;

// interface ActivityContextValue {
// 	objectType: ActivitySubject;
// 	objectId: number;
// }

export const ActivityContext = createContext<[ActivityState, Dispatch<ActivityAction>]>(
	[initialState, () => null as any]
);

export const activityContextReducer: Reducer<ActivityState, ActivityAction> = (s, a) => {
	switch (a.type) {
		case SET_ACTIVITY_DATA:
			return { ...s, items: [...s.items, ...a.payload] };

		case SET_LOADING_STATE:
			return { ...s, isNextPageLoading: a.payload };

			case SET_HAS_NEXT_PAGE:
			return { ...s, hasNextPage: a.payload };
			case SET_NEXT_PAGE:
			return { ...s, nextPage: a.payload };

		default:
			return s
	}
};

interface ActivityProviderProps {
	patchedState?: Partial<ActivityState>;
	injectedDispatch?: Dispatch<ActivityAction>;
	children?: ReactNode;
}

// Provide an inlet for manipulating this state during tests
export const ActivityContextProvider: FunctionComponent<ActivityProviderProps> = ({
	children,
	patchedState,
	injectedDispatch,
}) => {
	const reducerState = patchedState
		? { ...initialState, ...patchedState }
		: initialState;

	const [activityContext, activityDispatch] = useReducer(activityContextReducer, reducerState);

	const dispatch = injectedDispatch ? injectedDispatch : activityDispatch;

	return (
		<ActivityContext.Provider value={[activityContext, dispatch]}>
			{children}
		</ActivityContext.Provider>
	);
};

type ActivityDataQueryResult =
	| ReturnType<typeof useGetActivityQuery>;

export const useLoadNextPage = (d: ActivityDataQueryResult) => {
	const activityDispatch = useContext(ActivityContext)[1];
	const { data, isSuccess, isLoading, isFetching } = d;
	const timerRef = useRef<ReturnType<typeof setTimeout>>();

	useEffect(() => {
		// console.log("loadNextPage", ...args);
		if (isSuccess && data) {
			// short delay here prevents a bug with graph not positioning
			// itself correctly when there is already data in the network cache,
			// presumably related to a DOM ref not being set in time.

			const { items, _meta: meta } = data;
			const { page, perPage, totalItems, totalPages } = meta;

			timerRef.current = setTimeout(
				() => activityDispatch({
					type: SET_ACTIVITY_DATA,
					payload: items
				}), 2500
			);
			activityDispatch({ type: SET_HAS_NEXT_PAGE, payload: page < totalPages });
			activityDispatch({ type: SET_LOADING_STATE, payload: false });
			activityDispatch({ type: SET_NEXT_PAGE, payload: page + 1 });
		}

		if (isLoading || isFetching) {
			activityDispatch({ type: SET_LOADING_STATE, payload: true });
		}

		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, [isSuccess, data, activityDispatch, isLoading, isFetching]);
};


export default ActivityContext;
