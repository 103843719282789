import FlexContainer from '../../../common/FlexContainer';
import Typography from '../../../common/text/Typography';
import {
	FontAwesomeIcon,
	FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	/* min-width: 100px;
	max-width: 10%; */
	padding: ${(p) => p.theme.spacing(1)} 0;
	background: transparent;
	cursor: pointer;
	/* border: 1px solid ${(p) => p.theme.palette.divider}; */
	&:hover {
		background: #ffffff20;
	}
`;

interface Props {
	width?: string;
}

const StyledTextDiv = styled.div<Props>`
	max-width: ${(p) => p.width ? p.width : '200px'};
	width: ${(p) => p.width ? p.width : 'unset'};
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

interface CardProps extends FontAwesomeIconProps {
	title: string;
	onClick: () => void;
	width?: string;
}

const Card: FunctionComponent<CardProps> = ({ onClick, icon, title , width}) => {
	return (
		<StyledCard role="button" aria-label={title} onClick={onClick}>
		{/*<StyledCard role="button" aria-label={title} >*/}
			<FlexContainer justifyContent="center">
				<FontAwesomeIcon icon={icon}/>
			</FlexContainer>
			<StyledTextDiv width={width}>
				<Typography id={title} variant='caption1'>
					{title}
				</Typography>
			</StyledTextDiv>
		</StyledCard>
	);
};

export default Card;
