import {
	GridContainerProperties,
	GridJustifyItemsProperties,
	GridJustifyContentProperties,
	GridAlignItemsProperties,
	GridAlignContentProperties
} from './utils/typeUtils';
import styled from 'styled-components';

interface Props {
	grid?: GridContainerProperties;
	justifyContent?: GridJustifyContentProperties;
	justifyItems?: GridJustifyItemsProperties;
	alignItems?: GridAlignItemsProperties;
	alignContent?: GridAlignContentProperties;
}

const GridContainer = styled.div<Props & Record<string, any>>`
	display: grid;
	grid-template: ${(p) => p.grid ? p.grid : '1fr / 1fr'};
	justify-items: ${(p) => p.justifyItems};
	justify-content: ${(p) => p.justifyContent};
	align-items: ${(p) => p.alignItems};
	align-content: ${(p) => p.alignContent};
`;

export default GridContainer;
