import Button from '../../buttons/Button';
import styled from 'styled-components';

export const StyledQuestionListButton = styled(Button)`
	padding: ${(p) => p.theme.spacing(0.25, 0.5)};
	margin-right: ${(p) => p.theme.spacing(1)};
	color: inherit;
	border: 1px solid transparent;
	font-weight: ${(p) => p.theme.typography.fontWeightRegular};

	&:hover {
		border: 1px solid ${(p) => p.theme.palette.divider};
		background-color: #ffffff20;
	}
`;
