import React, {FunctionComponent} from "react";
import Helmet from "react-helmet";
import useActiveOrgData from "../../features/ontology/hooks/useActiveOrgData";
import useActiveDomainData
    from "../../features/ontology/hooks/useActiveDomainData";
import useUserProfile from "../../features/authentication/hooks/useUserProfile";
import {useGetUserProfileQuery} from "../../features/api";
import {useParams} from "react-router-dom";

const UserMeta: FunctionComponent = () => {

    const {username} = useParams();
    const {data: response} = useGetUserProfileQuery({username: username ? username : ''});
    const user = response?.response

    if (!!user) {
        return (
            <Helmet>
                <title>
                    {user.firstName} {!!user.lastName ? user.lastName : ''}
                    | Futuremodel
                </title>
                <meta
                    name="description"
                    content={`${user.firstName} ${!!user.lastName ? user.lastName : ''}`}
                />
                <link
                    rel="canonical"
                    href={`https://futuremodel.io/${user.username}`}
                />
            </Helmet>
        )
    } else {
        return null;
    }
}

export default UserMeta;
