import { flatten2D } from 'common/utils/functionUtils';
import { isoParse } from 'd3';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import useActiveIndividualsMeta from 'features/ontology/hooks/useActiveIndividualsMeta';
import {
	isISODateString,
	isPrimaryIdentity,
} from 'features/ontology/typeGuards/attributeGuards';
import EventLineChart from 'common/viz/LineChart/LineChartCore';
import 'common/viz/LineChart/LineChartCore/helpers';
import { groupDataToEventLines } from 'common/viz/LineChart/LineChartCore/helpers';
import { EventLineDrawFn } from 'common/viz/LineChart/LineChartCore/types';
import { distributeByCategory } from 'common/viz/Scatterplot/ScatterplotCore/helpers';
import { FunctionComponent, useCallback, useMemo } from 'react';
import styled from 'styled-components';

const chartMargins = {
	top: 4,
	bottom: 4,
	left: 4,
	right: 4,
};

const StyledBars = styled.g`
	fill: ${(p) => p.theme.palette.primary.main};
`;

const EventFilter: FunctionComponent = () => {
	const { setEventRangeFilter } = useEntitySearchParams();

	const { preparedData } = useActiveIndividualsMeta({ filter: true });

	const { groupedByYear, eventAttrName, xMin, xMax, yMax } = useMemo(() => {
		if (preparedData) {
			//    TODO: this will mess up if there is more than one
			//     ISODateString attribute
			const xAttr = preparedData.attributes.find(isISODateString)!;
			const xAttrName = xAttr && xAttr.name ? xAttr.name : '';

			const yAttr = preparedData.attributes.find(isPrimaryIdentity)!;
			const yAttrName = yAttr && yAttr.name ? yAttr.name : '';

			const { charts } = distributeByCategory(
				preparedData,
				xAttrName,
				[yAttrName],
				[],
				[]
			);

			// we KNOW there's only one LeafArray inside charts since we only passed-in one y-attr and no categories,
			// so go ahead and flatten the array.
			const chartPoints = flatten2D(charts);

			const groupedByYear = groupDataToEventLines(chartPoints, 'Month');

			const { xMin, xMax, yMax } = groupedByYear.reduce((acc, line) => {
				const {
					xMax: xMaxForLine,
					xMin: xMinForLine,
					yMax: yMaxForLine,
				} = line;

				if (acc.xMax === undefined || xMaxForLine > acc.xMax) {
					acc.xMax = xMaxForLine;
				}

				if (acc.xMin === undefined || xMinForLine < acc.xMin) {
					acc.xMin = xMinForLine;
				}

				if (acc.yMax === undefined || yMaxForLine > acc.yMax) {
					acc.yMax = yMaxForLine;
				}

				return acc;
			}, {} as { xMax: Date; xMin: Date; yMax: number });

			console.log({ xMin, xMax, yMax });

			return {
				groupedByYear,
				eventAttrName: xAttrName,
				xMax,
				xMin,
				yMax,
			};
		}

		const placeholderDate = new Date(Date.now());

		return {
			groupedByYear: [],
			eventAttrName: null,
			xMin: placeholderDate,
			yMax: 0,
			xMax: placeholderDate,
		};
	}, [preparedData]);

	const drawBar: EventLineDrawFn = useCallback(({ line, lineId, height }) => {
		return (
			<StyledBars key={lineId}>
				{line.map(({ drawX, drawY, pointId }) => {
					// console.log(`drawing ${yAttr} with ${y} members`);
					// If ref hasn't been updated with final size yet, don't bother drawing anything yet.
					// This prevent negative height warnings in console.
					return height > 0 ? (
						<rect
							key={pointId}
							width={4}
							height={height - drawY - chartMargins.bottom}
							transform={`translate(${drawX - 2}, ${drawY})`}
						/>
					) : null;
				})}
			</StyledBars>
		);
	}, []);

	const onBrushEnd = useCallback(
		//   callback receives ISO Date strings from d3's UTCScale.invert function.
		// They need to be converted to numbers before they go into search param filter.
		(range?: [string, string]) => {
			if (eventAttrName && range) {
				// defeat type widening here.
				const timeStamps = range
					.map(isoParse)
					.map((d) => d!.getTime()) as [number, number];

				setEventRangeFilter(eventAttrName, timeStamps);
			}
		},
		[eventAttrName, setEventRangeFilter]
	);

	return (
		<EventLineChart
			xMin={xMin}
			xMax={xMax}
			yMax={yMax}
			lines={groupedByYear}
			drawLine={drawBar}
			yAxis={false}
			xAxis={false}
			{...chartMargins}
			tooltip={false}
			onBrushEnd={onBrushEnd}
			brushExtent={({ width, height }) => [
				//  the '2' is 1/2 the width we're giving each bar--if we don't factor that
				// in the brush extent, the first and last bar will extend past the max brush extent
				// by 2px at either end.
				// The '1' is cosmetic--it keeps the white top border of brush inside dark
				// background for clarity.
				[chartMargins.left - 2, 1],
				[width - chartMargins.right + 2, height],
			]}
		/>
	);
};

export default EventFilter;
