import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleSelect from "./SubtleSelect";
import {harvestErrors} from "./SubtleTextBox";

const availableSourceTypes = [
	{
		label: 'Google BigQuery',
		name: 'bigquery'
	},
	{
		label: 'SFTP',
		name: 'sftp'
	},
	{
		label: 'Amazon S3',
		name: 's3'
	},
	{
		label: 'Web URL',
		name: 'url'
	},
	{
		label: 'Google Cloud Storage',
		name: 'google cloud storage'
	},
	{
		label: 'MySQL',
		name: 'mysql'
	},
	{
		label: 'Github',
		name: 'github'
	}
];

const SourceTypeSelect: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        mutationResults,
        validatingRegister
    }
    ) => {
    //"What type of attribute is {label}?"
    return <SubtleSelect
        id="select-display-source-type"
        label="Type"
        inline={true}
        defaultValue={'enterprise'}
        isDirty={Boolean(formState.dirtyFields.displaySourceType)}
        onReset={() => resetField('displaySourceType')}
        {...validatingRegister('displaySourceType')}
        error={harvestErrors(
            'displaySourceType',
            formState,
            mutationResults
        )}
    >
        {availableSourceTypes.map((type) => {
            return <option key={type.name} value={type.name}>
                {type.label}
            </option>
        })}
    </SubtleSelect>
}

export default SourceTypeSelect;
