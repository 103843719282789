import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import Typography from "../text/Typography";
import {formFieldHasErrors, genErrorIdFromLabel} from "../Form";
import SubtleSelect from "./SubtleSelect";
import SubtleRadio from "./SubtleRadio";
import {harvestErrors} from "./SubtleTextBox";

//					<Typography id="usingStaticReference">
// 						Use another attribute or set a static value to prepend
// 						to {label}
// 					</Typography>
// 					<div
// 						role="radiogroup"
// 						aria-errormessage={genErrorIdFromLabel(
// 							'usingStaticReference'
// 						)}
// 						aria-invalid={formFieldHasErrors(
// 							'usingStaticReference',
// 							formState
// 						)}
// 						aria-labelledby="usingStaticReference"
// 					>
// 						<div>
// 							<input
// 								id="dv-false"
// 								type="radio"
// 								value="false"
// 								{...register('usingStaticReference', {
// 									required: {
// 										value: true,
// 										message:
// 											'A value for usingStaticReference is required',
// 									},
// 								})}
// 							/>
// 							<label htmlFor="dv-false">
// 								<Typography>Select an attribute</Typography>
// 							</label>
// 						</div>
// 						<div>
// 							<input
// 								id="dv-true"
// 								type="radio"
// 								value="true"
// 								{...register('usingStaticReference', {
// 									required: {
// 										value: true,
// 										message:
// 											'A value for usingStaticReference is required',
// 									},
// 								})}
// 							/>
// 							<label htmlFor="dv-true">
// 								<Typography>Set a static value</Typography>
// 							</label>
// 						</div>
// 					</div>

//				<Typography id="usingStaticReference">
// 					Should {restrictedAttribute?.singular} be compared with
// 					a related attribute or a static value?
// 				</Typography>
// 				<div
// 					role="radiogroup"
// 					aria-errormessage={genErrorIdFromLabel(
// 						'usingStaticReference'
// 					)}
// 					aria-invalid={formFieldHasErrors(
// 						'usingStaticReference',
// 						formState
// 					)}
// 					aria-labelledby="usingStaticReference"
// 				>
// 					<div>
// 						<input
// 							id="dv-false"
// 							type="radio"
// 							value="false"
// 							{...register('usingStaticReference', {
// 								required: {
// 									value: true,
// 									message:
// 										'A value for usingStaticReference is required',
// 								},
// 							})}
// 						/>
// 						<label htmlFor="dv-false">
// 							<Typography>Related attribute</Typography>
// 						</label>
// 					</div>
// 					<div>
// 						<input
// 							id="dv-true"
// 							type="radio"
// 							value="true"
// 							{...register('usingStaticReference', {
// 								required: {
// 									value: true,
// 									message:
// 										'A value for usingStaticReference is required',
// 								},
// 							})}
// 						/>
// 						<label htmlFor="dv-true">
// 							<Typography>Static value</Typography>
// 						</label>
// 					</div>
// 				</div>

const ValueTypeSelect: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
    ) => {
    //
    //

    return <SubtleRadio
        {...validatingRegister('usingStaticReference')}
        showLabel
        name='usingStaticReference'
        options={[
            {name: 'true', label: 'Set a static value'},
            {name: 'false', label: 'Select an attribute'},
        ]}
        validatingRegister={validatingRegister}
        alignItems={'center'}
        justifyContent={'space-between'}
        inline={true}
        label={`Divide by another attribute or enter a static value?`}
        isDirty={Boolean(formState.dirtyFields.usingStaticReference)}
        onReset={() => resetField('usingStaticReference')}
        error={harvestErrors('usingStaticReference', formState, mutationResults)}
    />;
}

export default ValueTypeSelect;
