import MemberPanel from './MemberPanel';
import UserPromotionPanel from './UserPromotionPanel';
// import {StyledMemberMgmtHeading} from './styledComponents';
import Typography from 'common/text/Typography';
import { extractQueryErrMessage, mergeQueryStates } from 'features/api/helpers';
import { canEdit as canEditOrg } from 'features/authentication/helpers';
import useActiveOrgData from 'features/ontology/hooks/useActiveOrgData';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import { BaseOrg } from 'features/ontology/types/orgTypes';
import { FunctionComponent } from 'react';


const OrgConsole: FunctionComponent<{container: any;}> = ({container = {height: 0}}) => {
	const { activeItem: activeOrg, ...orgQueryRest } =
		useActiveOrgData();

	const { activeItem: activeDomain, ...domainQueryRest } =
		useActiveDomainData();

	const canEdit = activeOrg ? canEditOrg(activeOrg) : false;

	const { isError, error, isLoading, isUninitialized } = mergeQueryStates(
		orgQueryRest,
		domainQueryRest
	);

	if (isError && error) {
		return (
			<Typography variant='h5' color="error">
				{extractQueryErrMessage(error)}
			</Typography>
		);
	}

	if (isLoading || isUninitialized) {
		return <Typography variant='h5'>Loading...</Typography>;
	}

	if (activeOrg) {
		const {
			// label,
			// legalName,
			// domainName,
			// orgType,
			// created,
			// modified,
			locale,
			// stats,
			// avatar
		} = activeOrg;
		const {currency, timezone} = locale;

		return (
			<div style={{
				padding: 0,
				margin: '0',
				height: container,
				width: '100%'
			}}>
				<MemberPanel
					canEdit={canEdit}
					authorityLevel="admin"
					contextLevel="username"
					authorityLabel="Administered By"
					orgId={(activeOrg as BaseOrg)._id}
					domainId={activeDomain?._id}
				/>
				<MemberPanel
					canEdit={canEdit}
					authorityLevel="governor"
					contextLevel="username"
					authorityLabel="Governed By"
					orgId={(activeOrg as BaseOrg)._id}
					domainId={activeDomain?._id}
				/>
				<MemberPanel
					canEdit={canEdit}
					authorityLevel="engineer"
					contextLevel="username"
					authorityLabel="Engineered By"
					orgId={(activeOrg as BaseOrg)._id}
					domainId={activeDomain?._id}
				/>
				<MemberPanel
					canEdit={canEdit}
					authorityLevel="publisher"
					contextLevel="username"
					authorityLabel="Published By"
					orgId={(activeOrg as BaseOrg)._id}
					domainId={activeDomain?._id}
				/>
				<MemberPanel
					canEdit={canEdit}
					authorityLevel="maintainer"
					contextLevel="username"
					authorityLabel="Maintained By"
					orgId={(activeOrg as BaseOrg)._id}
					domainId={activeDomain?._id}
				/>
				<MemberPanel
					canEdit={canEdit}
					authorityLevel="rightsHolder"
					contextLevel="username"
					authorityLabel="Rights Held By"
					orgId={(activeOrg as BaseOrg)._id}
					domainId={activeDomain?._id}
				/>
				<MemberPanel
					canEdit={canEdit}
					authorityLevel="funder"
					contextLevel="username"
					authorityLabel="Funded By"
					orgId={(activeOrg as BaseOrg)._id}
					domainId={activeDomain?._id}
				/>
				<MemberPanel
					canEdit={canEdit}
					authorityLevel="reporter"
					contextLevel="username"
					authorityLabel="Reported By"
					orgId={(activeOrg as BaseOrg)._id}
					domainId={activeDomain?._id}
				/>
				{/*				<MemberPanel*/}
				{/*	canEdit={canEdit}*/}
				{/*	authorityLevel="member"*/}
				{/*	contextLevel="username"*/}
				{/*	authorityLabel="Members"*/}
				{/*	orgId={(activeOrg as BaseOrg)._id}*/}
				{/*	domainId={activeDomain?._id}*/}
				{/*/>*/}
				{/*<MemberPanel*/}
				{/*	canEdit={canEdit}*/}
				{/*	authorityLevel="contact"*/}
				{/*	contextLevel="username"*/}
				{/*	authorityLabel="Contacts"*/}
				{/*	orgId={(activeOrg as BaseOrg)._id}*/}
				{/*	domainId={activeDomain?._id}*/}
				{/*/>*/}
				{/*<UserPromotionPanel*/}
				{/*	orgId={(activeOrg as BaseOrg)._id}*/}
				{/*	canEdit={canEdit}*/}
				{/*/>*/}
			</div>
		);
	} else {
		return null
	}
};

export default OrgConsole;
