import {
	CreateLocationFormValues,
	CreateLocationPayload,
} from './createLocationTypes';

export const createLocationFormDefaults = (): CreateLocationFormValues => ({
	singular: '',
	plural: '',
	definition: '',
	latitudeId: 0,
	longitudeId: 0,
	continentId: 0,
	subcontinentId: 0,
	countryId: 0,
	countrySubdivision1Id: 0,
	countrySubdivision2Id: 0,
	designatedMarketAreaId: 0,
	cityId: 0,
	postalCodeId: 0,
	addressId: 0,
});

export const createLocationFormToPayload = (
	formValues: CreateLocationFormValues
): CreateLocationPayload => {
	return {
		...formValues,
		definition: formValues.definition === '' ? null : formValues.definition,
	};
};
