import Button from '../buttons/Button';
import {FormBody, FormHeading, FormResults} from '../Form';
import {warnInDev} from '../utils/reactUtils';
import {useCreateSourceMutation} from '../../features/api';
import {
    parseSubmittedCredentials,
    mapDisplaySourceTypeToCredentials,
    renderSourceFormInputs,
} from '../../features/browser/helpers';
import {
    DisplaySourceType,
    CredentialFormValues,
} from '../../features/browser/types/credentialTypes';
import {StyledSourceFormLabel} from '../../features/browser/components/styledComponents';
import Textbox from 'common/inputs/TextBox';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import styled from 'styled-components';
import AppModal from "./AppModal";
import useActiveOrgData from "../../features/ontology/hooks/useActiveOrgData";
import Heading from "../text/Heading";
import {harvestErrors} from "../inputs/SubtleTextBox";
import SubtleSelect from "../inputs/SubtleSelect";
import SourceTypeSelect from "../inputs/SourceTypeSelect";
import useModalType from "../../features/HUD/hooks/useModalType";

// Requiring either aria-labelledby or aria-label on the form component
// messes up the function signature for 'styled', but shouldn't actually
// cause a problem at runtime.
// @ts-ignore
const StyledForm = styled(FormBody)`
	background: ${(p) => p.theme.palette.background.paper};
	border: none;
`;

const CreateConnectionModal: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
    const {objectId, canEdit} = modalProps;

    const {
        register,
        formState,
        resetField,
        handleSubmit,
        reset,
        setValue,
        watch
    } =
        useForm<CredentialFormValues>({
            defaultValues: {
                sourceType: 'bigquery',
                displaySourceType: 'bigquery',
                name: '',
                credsSchema: 'service_account',
            },
            shouldUnregister: true,
        });

    const {activeItem: activeOrg} = useActiveOrgData();

    const displaySourceType = watch('displaySourceType');

    useEffect(() => {
        const {schema, sourceType} =
            mapDisplaySourceTypeToCredentials(displaySourceType);
        setValue('credsSchema', schema);
        setValue('sourceType', sourceType);
    }, [displaySourceType, setValue]);

    const [createSource, mutationResults] = useCreateSourceMutation();

    const validatingRegister = useCallback(
        (inputName: keyof CredentialFormValues) =>
            register(inputName, {required: `${inputName} is required`,}),
        [register]
    );

    const onSubmit: SubmitHandler<CredentialFormValues> = (values) => {
        if (activeOrg._id === null || typeof objectId === 'string') {
            warnInDev(
                'Attempted to submit source creation form when no user ID was available.',
                'error'
            );
            return;
        }

        // NB: this catch statement is primarily for the possbility that reading
        // a submitted file to a files URL will fail.  Failure cases for the
        // network request are handled by 'mutationResult', above.
        // TODO: check if createSource passes network failures/rejections to this catch
        // statement.  Also, display some kind of error to user on a FileReader failure.
        parseSubmittedCredentials(values, activeOrg._id)
            .then(createSource)
            .catch((e) => warnInDev('credential parsing failed', 'error', e));
    };

    return (
        <AppModal
            label={"Create connection"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            id="add-source-form"
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit}>
            <Heading style={{padding: 0, margin: 0}} component="h3">
                Source Type
            </Heading>
            <SourceTypeSelect
                formState={formState}
                validatingRegister={validatingRegister}
                resetField={resetField}
                mutationResults={mutationResults}
            />

            <Heading style={{padding: 0, margin: 0}} component="h3">
                Source Credentials
            </Heading>
            {renderSourceFormInputs(
                validatingRegister,
                resetField,
                mutationResults,
                displaySourceType,
                register as any,
                formState
            )}
            <>
                <input
                    {...register('credsSchema')}
                    type="hidden"
                    id="creds-schema"
                />
                <input
                    {...register('sourceType', {
                        required: true,
                    })}
                    type="hidden"
                    id="source-type"
                />
            </>
            <FormResults
                {...mutationResults}
                validationErrors={formState.errors}
                // TODO: figure out why onSuccess isn't firing correctly and closing the form
                onSuccess={closeModal}
            />
        </AppModal>
    );
};

export default CreateConnectionModal;
