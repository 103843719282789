import { hasOwnProperty } from '../../utils/typeUtils';
import { isNonNullObject } from 'common/utils/typeGuards';
import { FormState } from 'react-hook-form';

const hasRelevantMessage = (fieldName: string, u: unknown) =>
	isNonNullObject(u) &&
	hasOwnProperty(u, fieldName) &&
	typeof u[fieldName] === 'string';

// TODO: ideally tighten up type of mutationResults
export const harvestErrors = <T extends Record<string, any>>(
	fieldName: string,
	formState: FormState<T>,
	mutationResults: any
) => {
	const validationErr = formState.errors[fieldName]?.message;

	const queryErrMessage = mutationResults.error?.data;

	if (hasRelevantMessage(fieldName, queryErrMessage)) {
		return queryErrMessage[fieldName];
	}

	if (validationErr) {
		return validationErr;
	}

	return null;
};
