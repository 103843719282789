import LinkedInShareButton from '../../../buttons/LinkedInShareButton';
import {
    StyledAttributeList,
    StyledVizWithControls,
    StyledVizPaper,
    StyledSubmoduleHeader,
    // StyledSubmoduleFooter,
    StyledSubmoduleContent,
} from '../../../../features/profile/EntityProfile/components/styledComponents';
// import { faCheck } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlexContainer from 'common/FlexContainer';
import {List, ListItem, ListItemContent} from 'common/List';
// ListItemAvatar
import Typography from 'common/text/Typography';
import theme from 'common/theme/theme';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useEntitySearchParams
    from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import CategoryMeta from 'features/dataPreparation/CategoryMeta';
import {cardinalityLessThan} from 'features/ontology/helpers/attributeHelpers';
import useActiveIndividualsMeta
    from 'features/ontology/hooks/useActiveIndividualsMeta';
import {isCategory} from 'features/ontology/typeGuards/attributeGuards';
import Histogram from 'common/viz/Histogram/HistogramCore';
import {distributeByCategory} from 'common/viz/Scatterplot/ScatterplotCore/helpers';
import {
    ChangeEvent,
    FunctionComponent,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import styled from 'styled-components';
import {debounce} from 'throttle-debounce';
import useElementSize from "../../../hooks/useSize";
import SlideToggle from "../../../inputs/SlideToggle";
import {BaseAttribute} from "../../../../features/ontology/types/attributeTypes";
import {SettingsButton} from "../../../buttons";
import IconButton from "../../../buttons/IconButton";
import {faGear} from "@fortawesome/free-solid-svg-icons";

const StyledSlider = styled.input`
	width: 100%;
`;

const svgId = 'histo-for-capture';

const colors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.info.main,
];

const HistogramModule: FunctionComponent = () => {
    const {
        getActiveAttributeName,
        getAllHistogramCat,
        setHistogramCat,
        removeHistogramCat,
    } = useEntitySearchParams();

    const [sliderValue, setSliderValue] = useState(1);

    const [settingsOpen, setSettingsOpen] = useState(false);
    const [binCount, setBinCount] = useState(1);

    const [size, setSizeTarget] = useElementSize();

    const debounced = useMemo(
        () =>
            debounce(250, (v: number) => {
                setBinCount(v);
            }),
        []
    );

    const attributeName = getActiveAttributeName();

    const categoryAttrNames = getAllHistogramCat();

    const {preparedData, ...individualsLoadState} =
        useActiveIndividualsMeta();

    const activeAttribute = !!preparedData && !!preparedData.attributes ?
        preparedData.attributes.filter((a) => a.name === attributeName)[0]
        : {singular: attributeName} as BaseAttribute;

    const {data, maxThresholds, colorMap} = useMemo(() => {
        if (preparedData && attributeName) {
            const activeAttrMeta =
                preparedData.getAttributeData(attributeName)!;

            const categoryMetas = categoryAttrNames.map(
                (name) => preparedData.attributeFields[name] as CategoryMeta
            );

            const recoveryGuide = categoryMetas.map((m) =>
                Array.from(m.uniqueValues.values()).sort()
            );

            const {charts} = distributeByCategory<number, number>(
                preparedData,
                attributeName,
                [attributeName],
                categoryAttrNames,
                recoveryGuide
            );

            // Shenanigans here: b/c for histogram we allow selecting a maximum of 1 category, AND
            // there are no extra sets of data points due to multiple selected y-axes (histogram is a single-variable chart),
            // we know the only data in each leaf array will be at index[0], and also know that recoveryGuide
            // will contain a single array of strings.
            const data = charts.map((leafArr, i) => ({
                data: leafArr[0],
                categoryName: recoveryGuide[0] ? recoveryGuide[0][i] : null,
            }));

            const colorMap =
                categoryAttrNames.length > 0
                    ? recoveryGuide[0].reduce((acc, v, i) => {
                        acc[v] = colors[i % colors.length];
                        return acc;
                    }, {} as Record<string, string>)
                    : {};

            return {
                maxThresholds: activeAttrMeta.uniqueCount,
                data,
                colorMap,
            };
        }

        return {maxThresholds: 30, data: [], colorMap: {}};
    }, [attributeName, preparedData, categoryAttrNames]);

    const onSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newVal = parseInt(e.target.value, 10);
        setSliderValue(newVal);
        debounced(newVal);
    };

    useLayoutEffect(() => {
        // never more than 30 bins, but use the number of unique values in the data
        // if that value is less than 30 (since it never makes sense to use a larger number).
        const target = Math.min(30, maxThresholds);
        setSliderValue(target);
        setBinCount(target);
    }, [maxThresholds]);

    return (
        <StyledVizPaper>
            <StyledSubmoduleHeader>
                <Typography variant='h6' color="cyan">
                    Distribution
                    of {activeAttribute ? activeAttribute.singular : attributeName}
                </Typography>
                <IconButton icon={faGear}
                            onClick={() => setSettingsOpen((p) => !p)}/>
            </StyledSubmoduleHeader>
            <StyledVizWithControls settingsOpen={settingsOpen}>
                <DisplayOnLoad {...individualsLoadState}>
                    <StyledSubmoduleContent ref={setSizeTarget} id={svgId}>
                        <Histogram
                            binCount={binCount}
                            data={data}
                            colorMap={colorMap}
                        />
                    </StyledSubmoduleContent>
                </DisplayOnLoad>
                {
                    settingsOpen ?
                        <div
                            style={{borderLeft: `1px solid ${theme.palette.divider}`}}>
                            <List
                                style={{
                                    overflowY: 'auto',
                                    // paddingTop: '.05rem',
                                    // paddingBottom: '.05rem',
                                    paddingLeft: '1rem',
                                }}
                            >
                                <Typography
                                    variant="subtitle"
                                    color={'cyan'}>
                                    Share
                                </Typography>
                                <ListItem>
                                    <LinkedInShareButton elementId={svgId}/>
                                </ListItem>
                            </List>
                            <StyledSubmoduleHeader>
                                <Typography variant='caption2' color={'cyan'}>
                                    Group by
                                </Typography>
                            </StyledSubmoduleHeader>
                            <List
                                style={{
                                    overflowY: 'auto',
                                    maxHeight: '280px',
                                    // paddingTop: '.05rem',
                                    // paddingBottom: '.05rem',
                                }}
                            >
                                {preparedData &&
                                preparedData.attributes
                                    .filter(
                                        (a) =>
                                            isCategory(a) &&
                                            cardinalityLessThan(10, a)
                                    )
                                    .map((a) => {
                                        const isCurrent =
                                            categoryAttrNames.includes(a.name);

                                        return (
                                            <ListItem
                                                button
                                                key={a.name}
                                                aria-current={isCurrent}
                                                onClick={() =>
                                                    isCurrent
                                                        ? removeHistogramCat(a.name)
                                                        : setHistogramCat(a.name)
                                                }
                                                style={{
                                                    color: isCurrent
                                                        ? theme.palette.primary.main
                                                        : theme.palette.oldManGray,
                                                    paddingLeft: '.5rem'
                                                }}
                                            >
                                                {/*{isCurrent && (*/}
                                                {/*	<ListItemAvatar>*/}
                                                {/*		<FontAwesomeIcon*/}
                                                {/*			icon={faCheck}*/}
                                                {/*		/>*/}
                                                {/*	</ListItemAvatar>*/}
                                                {/*)}*/}
                                                <ListItemContent>
                                                    <Typography
                                                        variant={'caption2'}>
                                                        {a.plural}
                                                    </Typography>
                                                </ListItemContent>
                                            </ListItem>
                                        );
                                    })}
                            </List>
                            <StyledSubmoduleHeader>
                                <Typography variant='caption2'
                                            color='oldManGray'>
                                    Bins:
                                </Typography>
                                <Typography variant='caption2' color="primary">
                                    {sliderValue}
                                </Typography>
                            </StyledSubmoduleHeader>
                            <StyledSlider
                                onChange={onSliderChange}
                                type="range"
                                min="1"
                                max={maxThresholds}
                                value={sliderValue}
                                style={{
                                    width: '80%',
                                    margin: '0 auto 0 .5rem'
                                }}
                            />
                        </div> : null
                }
            </StyledVizWithControls>
            {/*<StyledSubmoduleFooter>*/}
            {/*</StyledSubmoduleFooter>*/}
        </StyledVizPaper>
    );
};

export default HistogramModule;
