import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleSelect from "./SubtleSelect";

// "BY",
// "BY-SA",
// "BY-NC",
// "BY-NC-SA",
// "BY-ND",
// "BY-NC-ND",
// code =
//
// const licenseElements = [
// There are 4 licence elements which are mixed to create six CC licences:
//
// attribute the author
// #  You must give appropriate credit, provide a link to the license, and indicate if changes were made. You may do so in any reasonable manner, but not in any way that suggests the licensor endorses you or your use.
//
// no commercial use
//
// no remixing
//
// {code: "Attribution",
//  definition: "copy and redistribute the material in any medium or format",
//  name: "Share"}
//
// {code: "NonCommercial",
//  definition: "copy and redistribute the material in any medium or format",
//  name: "Share"}
//
// {code: "ShareAlike",
//  definition: "copy and redistribute the material in any medium or format",
//  name: "Share"}
//
// {name: "NoDerivatives",
//  name: "remix, transform, and build upon the material for any purpose, even commercially.",
//  name: "Adapt"}

// https://creativecommons.org/share-your-work/public-domain/freeworks
const licenseTypes = [
    {
        code: 'CC0',
        label: 'Creative Commons (CC) Universal',
        docsUrl: 'https://creativecommons.org/share-your-work/public-domain/cc0',
        summaryUrl: 'https://creativecommons.org/publicdomain/zero/1.0/',
        licenseUrl: 'https://creativecommons.org/publicdomain/zero/1.0/legalcode',
        approvedFreeCulture: true
    },
    {
        code: 'CC BY 4.0',
        summaryUrl: 'https://creativecommons.org/licenses/by/4.0/',
        licenseUrl: 'https://creativecommons.org/licenses/by/4.0/legalcode',
        label: 'Creative Commons (CC) Attribution 4.0 International',
        approvedFreeCulture: true
    },
    {
        code: 'CC SA 4.0',
        summaryUrl: 'https://creativecommons.org/licenses/by-sa/4.0/',
        licenseUrl: 'https://creativecommons.org/licenses/by-sa/4.0/legalcode',
        label: 'Creative Commons (CC) Attribution-ShareAlike 4.0 International',
        approvedFreeCulture: true
    },
    {
        code: 'CC BY-NC 4.0',
        summaryUrl: 'https://creativecommons.org/licenses/by-nc/4.0/',
        licenseUrl: 'https://creativecommons.org/licenses/by-nc/4.0/legalcode',
        label: 'Creative Commons (CC) Attribution–NonCommercial 4.0 International',
        approvedFreeCulture: false
    },
    {
        code: 'CC BY-ND 4.0',
        summaryUrl: 'https://creativecommons.org/licenses/by-nd/4.0/',
        licenseUrl: 'https://creativecommons.org/licenses/by-nd/4.0/legalcode',
        label: 'Creative Commons (CC) Attribution–NoDerivatives 4.0 International',
        approvedFreeCulture: false
    },
    {
        code: 'CC BY-NC-SA 4.0',
        summaryUrl: 'https://creativecommons.org/licenses/by-nc-sa/4.0/',
        licenseUrl: 'https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode',
        label: 'Creative Commons (CC) Attribution-NonCommerical-ShareAlike 4.0 International',
        approvedFreeCulture: false
    },
    {
        code: 'CC BY-NC-ND 4.0',
        summaryUrl: 'https://creativecommons.org/licenses/by-nc-nd/4.0/',
        licenseUrl: 'https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode',
        label: 'Creative Commons (CC) Attribution-NonCommercial-NoDerivatives 4.0 International',
        approvedFreeCulture: false
    }
]

const LicenseTypeSelect: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleSelect
        inline={true}
        label={`License Type`}
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('license')}
        isDirty={Boolean(formState.dirtyFields.license)}
        {...validatingRegister('license')}
    >
        {licenseTypes.map((type: {code: string, label: string}) =>{
            return <option key={type.code} value={type.code}>
                {type.label}
            </option>
        })}
    </SubtleSelect>;
};

export default LicenseTypeSelect;
