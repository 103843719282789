import React, {FunctionComponent} from "react";
import {BaseOrg} from "../../features/ontology/types/orgTypes";
import MenuButton from "../buttons/MenuButton";
import MenuPanel from "./MenuPanel";
import SubMenuButton from "../buttons/SubMenuButton";
import TextButton from "../buttons/TextButton";
import {
    COPY_SHARE_URL,
    DESCRIBE_ORG,
    DESTROY_ORG, FOLLOW_RESOURCE,
    MANAGE_MEMBERS,
    SHARE_RESOURCE,
    SHOW_CREDITS
} from "../../features/graph/CONSTANTS";
import ShareResourceModal from "../modals/ShareResourceModal";

const individualActions = [
    // {
    //     actionTitle: DESCRIBE_ORG,
    //     modalType: 'orgSettings',
    //     kind: 'modal',
    //     requiresEdit: true,
    //     divider: true,
    // },
    {
        actionTitle: SHOW_CREDITS,
        modalType: 'individualCredits',
        kind: 'modal',
        requiresEdit: false,
        divider: true
    },
    {
        actionTitle: FOLLOW_RESOURCE,
        requiresEdit: false,
        kind: 'action',
        callback: 'follow',
	},
    {
        actionTitle: 'Share',
        requiresEdit: false,
        kind: 'subMenu',
        items: [
            {
                actionTitle: SHARE_RESOURCE,
                kind: 'modal',
                requiresEdit: false,
                modalType: 'shareResource',
                form: ShareResourceModal,
            },
            // {
            //     actionTitle: COPY_SHARE_URL,
            //     requiresEdit: false,
            //     subMenu: 'share',
            //     kind: 'action',
            //     callback: 'copyLink',
            //     divider: false,
            // },
        ]
    },
    // {
    //     actionTitle: MANAGE_MEMBERS,
    //     modalType: 'membership',
    //     divider: true,
    //     form: AddMemberButton,
    // },
    // {
    //     actionTitle: SHARE_RESOURCE,
    //     modalType: 'share',
    //     kind: 'modal',
    //     divider: true,
    //     form: AddMemberButton,
    // },
]

export const ShareButton: FunctionComponent = () => {
    return <SubMenuButton label={'Share'}>
        <TextButton
            color='primary'
            fillColor='transparent'
            text={'Copy Object Link'}
        />
    </SubMenuButton>
}

export const IndividualMenu: FunctionComponent<{ individual: any; canEdit: boolean; }> = (
    {individual, canEdit}
) => {
    return <MenuButton placement={'right-start'}>
        {individual ? <>
                <MenuPanel
                    resource={individual}
                    canEdit={canEdit}
                    actions={individualActions}
                />
            </>
            : null}
    </MenuButton>;
};

export default IndividualMenu;
