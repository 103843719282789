import Typography from "./Typography";
import {FunctionComponent} from "react";
import {composeName} from "../../features/governance/helpers";

const ResourceLabel: FunctionComponent<{resource: any, styles?: any, onHold?: boolean, onHover?: () => void, onExit?: (onHold: boolean) => void}> = ({resource, styles, onExit, onHold, onHover}) => {
    return <Typography
        variant='h6'
        paragraph
        id={`${resource._object}-${resource._id}`}
        style={styles}>
        <span onMouseEnter={onHover} onMouseLeave={() => onHold && onExit && onExit(onHold)}>
        {resource._object === 'Entity' || resource._object === 'Attribute'
            ? resource.plural
            : resource.label
                ? resource.label
                : resource.name
                    ? resource.name
                    : resource._object
        }
        </span>
    </Typography>;
}

export default ResourceLabel;
