import React, {FunctionComponent} from "react";
import Helmet from "react-helmet";
import useActiveOrgData from "../../features/ontology/hooks/useActiveOrgData";
import useActiveDomainData
    from "../../features/ontology/hooks/useActiveDomainData";

const DomainMeta: FunctionComponent = () => {

    const { activeItem: activeOrg } = useActiveOrgData();
	const { activeItem: activeDomain } = useActiveDomainData();

    return (

        <Helmet>
            <title>
                {!!activeDomain ? activeDomain.label : ''}
                {/*{' '}| {activeOrg.label} */}
                {' '}| Futuremodel</title>
            <meta name="description" content={activeOrg.label}/>
            <link
                rel="canonical"
                href={`https://futuremodel.io/${activeOrg.username}`}
            />
        </Helmet>
    )
}

export default DomainMeta;
