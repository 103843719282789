import useViewportDimensions from '../hooks/useViewportDimensions';
import {BottomPaper, TopPaper} from 'common/Paper';
import {forwardRef, ReactNode, useRef} from 'react';
import { TransitionStatus, Transition } from 'react-transition-group';
import styled, { DefaultTheme } from 'styled-components';

const short = (theme: DefaultTheme) => theme.transitions.duration.standard;

const ease = (theme: DefaultTheme) => theme.transitions.easing.easeInOut;

const StyledSidePanelContainer = styled(BottomPaper)<{
	viewportHeight: number;
	state: TransitionStatus;
	props: any;
}>`
	opacity: .95;
	transition: transform ${({ theme }) => `${short(theme)}ms ${ease(theme)}`},
	opacity ${({ theme }) => `${short(theme)}ms ${ease(theme)}`};
	transform: ${(p) => 
	`translateX(-${p.state === 'entered' || p.state === 'entering'
		? 8 / 16 
		: p.theme.panelGutter * 2
	})`};
	position: absolute;
	width: ${(p) => `${p.theme.drawerWidth * 2.13}px`};
	height: calc(100vh - ${(p) => p.theme.navHeight * 2 + p.theme.panelGutter * 3}px);
	top: ${(p) => `${p.theme.navHeight + p.theme.panelGutter * 1.5}`}px;
	right: ${(p) => p.theme.sideControllerWidth + p.theme.panelGutter * 2}px;
	display: ${(p) => !!p.props.drawerOpen ? 'flex' : 'none'};
	flex-direction: column;
	overflow: visible;
	z-index: ${(p) => p.theme.zIndex.controlSurface};
	padding: 0;
`;

interface RightDrawerProps {
	props?: any;
	children?: ReactNode;
}

const RightDrawer = forwardRef<HTMLElement, RightDrawerProps>(
	({ props, children }, ref: any) => {
		// Need this ref to stop react-transition-group from trying to
		// use findDOMNode, which is deprecated and profoundly irritating.

		const {drawerOpen} = props;
		const transitionRef = useRef<any>();

		const { viewportHeight } = useViewportDimensions();

		return (
			<Transition in={drawerOpen} timeout={10} nodeRef={transitionRef}>
				{(state) => (
					<StyledSidePanelContainer
						ref={transitionRef}
						viewportHeight={viewportHeight}
						state={state}
						props={props}
						data-testid="hud-right-drawer-container"
						className="side-container"
					>
						<BottomPaper>

							{children}
						</BottomPaper>
						{/* Need vertical scroll for content panel, and for some reason setting
						overflow-y on StyledSidePanelContainer hides translated control bar, so we put
						the scroll on an inner container instead. */}
					</StyledSidePanelContainer>
				)}
			</Transition>
		);
});

export default RightDrawer;
