import styled from 'styled-components';

	// box-shadow: ${(p) => p.theme.shadows[4]};
const TooltipBody = styled.div`
	border-radius: 2px;
	color: ${(p) => p.theme.palette.darkBaby};
	z-index: 1;
	background: transparent;
	padding: ${(p) => p.theme.spacing(0.5)} ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(0.75)} ${(p) => p.theme.spacing(1)};
	color: ${(p) => p.theme.palette.darkBaby};
	text-transform: capitalize;
`;
	// background-color: ${(p) => p.theme.palette.slickRick};

export default TooltipBody;
