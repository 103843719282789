import environment from '../environment';
import theme from '../theme/theme';
import { ModalType } from 'features/HUD/types/HUDTypes';
import { FunctionComponent, useEffect } from 'react';
import ReactModal from 'react-modal';

interface ModalProps extends ReactModal.Props {
	modalType?: ModalType;
	modalWidth?: number;
	modalHeight?: number;
	top?: string;
	borderRadius?: string;
	backgroundColor?: string;
}

const Modal: FunctionComponent<ModalProps> = ({
	children,
	modalType,
	modalWidth,
	modalHeight,
	top,
	borderRadius,
	backgroundColor,
	...rest
}) => {
	useEffect(() => ReactModal.setAppElement('#root'), []);

		return (
			<ReactModal
				{...rest}
				style={{
					overlay: {
						backgroundColor: 'rgba(0, 0, 0, 0.2)',
						zIndex: theme.zIndex.modal,
					},
					content: {
						top: `${theme.navHeight + theme.panelGutter * 2}px`,
						marginLeft: 'auto',
						marginRight: 'auto',
						backgroundColor:  backgroundColor ? backgroundColor : theme.palette.slickRick,
						display: 'flex',
						width: modalWidth ? `${modalWidth}vw` : '90vw',
						minWidth: '500px',
						maxWidth: '45rem',
						minHeight: '20rem',
						height: `calc(100vh - ${theme.navHeight * 2 + theme.panelGutter * 4}px)`,
						left: 0,
						right: 0,
						padding: 0,
						border: 'none',
						boxShadow: theme.shadows[3],
						borderRadius: borderRadius,
					},
				}}
				// this prevents complaints when Modal is rendered in tests
				ariaHideApp={environment.NODE_ENV === 'test' ? false : true}
			>
				{children}
			</ReactModal>
		)
};

export default Modal;
