import { ActionDescriptor } from '../common/commonTypes';
import MergeAttributeForm from './MergeAttributeForm';

const mergeAttribute: ActionDescriptor = {
	form: MergeAttributeForm,
	modalType: 'mergeAttribute',
	kind: 'modal',
	divider: false,
	requiresEdit: true,
	actionTitle: 'Merge',
};

export default mergeAttribute;
