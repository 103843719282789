import {faCheck, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import FlexContainer from 'common/FlexContainer';
import IconButton from 'common/buttons/IconButton';
import {
    PopoverHead,
    PopoverMain,
    PopoverHeader,
    PopoverSubhead,
    PopoverCardBody,
    PopoverDrawer,
    PopoverHeading,
    PopoverControlsGrid,
    DropdownIcon,
} from 'common/popovers/Popover';
import EntityMenu from 'common/menus/EntityMenu';
import theme from 'common/theme/theme';
import {SubtleTextbox} from 'common/inputs/SubtleTextBox';
import {warnInDev} from 'common/utils/reactUtils';
import {
    useDescribeEntityMutation,
    useFollowResourceMutation,
    useGetAttributesQuery,
    useUnfollowResourceMutation
} from 'features/api';
import {deriveEntityViews} from 'features/compositeViews/helpers';
import {nullifyDefinitionPlaceholder} from 'features/graph/helpers';
import {DEFINITION_PLACEHOLDER} from 'features/graph/helpers';
import {DescribeEntityFormValues} from 'features/actions/describeEntity/describeEntityTypes';
import {DomainGraphNode} from 'features/ontology/types/domainTypes';
import QuestionButton from 'common/buttons/QuestionButton';
import {
    FormEventHandler,
    FunctionComponent,
    MouseEventHandler,
    useEffect,
    useState,
    useCallback
} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import CommentButton from "../buttons/CommentButton";
import RouterLink from "../RouterLink";
import {BaseEntity, EntityModalTypes} from "../../features/ontology/types/entityTypes";
import MenuButton from "../buttons/MenuButton";
import PaperButton from "../buttons/PaperButton";
import clsx from "clsx";
import {FollowButton} from "../buttons";
import {mergeQueryStates} from "../../features/api/helpers";
import {
    faGlobe, faDisplay, faDatabase, faCircleNodes, faCodeBranch, faHeartPulse,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Typography from 'common/text/Typography';
import {ViewMode} from 'features/compositeViews/types';
import styled from 'styled-components';
import ExpandingListItem, {IdentifyingListItem} from '../List/ExpandingListItem';
import Heading from 'common/text/Heading';
import {List} from 'common/List';
import {pipe} from 'common/utils/functionUtils';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import {QueryStateTracker} from 'features/api/helpers';
import {
    groupAttrs,
    pluralizeType,
    sortGroupedAttrs,
} from 'features/ontology/helpers/attributeHelpers';
import {
    BaseAttribute,
    GetEntityAttrsResponse,
} from 'features/ontology/types/attributeTypes';
import {useAppDispatch} from "../../app/hooks";
import useModalType from "../../features/HUD/hooks/useModalType";
import useActiveEntityAttributes
    from "../../features/ontology/hooks/useActiveEntityAttributes";


const StyledHeading = styled(Heading)`
	padding: ${(p) => p.theme.spacing(1)};
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
	text-transform: uppercase;
`;

interface DropdownProps {
    attrs: GetEntityAttrsResponse;
    mapAttrToUI: (attrs: BaseAttribute) => JSX.Element;
}

const Dropdown: FunctionComponent<DropdownProps> = ({attrs, mapAttrToUI}) => {
    // Only attributes that DO have a source can be used as an identity; filter the rest.
    const attrsWithSource = attrs.filter((attr) => attr.hasSource);

    const groupedAndSorted = pipe(
        attrsWithSource,
        groupAttrs('base'),
        sortGroupedAttrs
    );

    return (
        <>
            {groupedAndSorted.map(({type, attrs}) => {
                return (
                    <div key={type}>
                        <StyledHeading component="h6" style={{
                            margin: '10px 10px 10px 5px',
                            color: theme.palette.primary.main,
                        }}>
                            {pluralizeType(type)}
                        </StyledHeading>
                        <List>{attrs.map(mapAttrToUI)}</List>
                    </div>
                );
            })}
        </>
    );
};

interface EntityDropdownProps extends QueryStateTracker {
    hasIdentities: boolean;
    attributes?: GetEntityAttrsResponse;
    canEdit: boolean;
    entity: BaseEntity;
}


const EntityPopoverDropdown: FunctionComponent<EntityDropdownProps> = (
    {
        hasIdentities,
        entity,
        attributes,
        canEdit,
        ...loadingState
    }
) => {
    // keep track of which attribute list item has a sub-menu open.  Should
    // only have one open at a time.
    const [attrWithActiveSubmenu, setAttrWithActiveSubmenu] = useState<number | null>(null);

    // if entity doesn't yet have an identity, clicking on a list item
    // immediately launches a request to use the clicked attribute as
    // an identity.  Otherwise, clicked list item opens a sub-menu with
    // actions that can be performed on that entity.
    const mapAttrToUI = useCallback((attr: BaseAttribute) => {
            return hasIdentities ? (
                <ExpandingListItem
                    key={attr._id}
                    entity={entity}
                    resource={attr}
                    // resourceWithActiveSubmenu={attrWithActiveSubmenu}
                    // setOpen={setAttrWithActiveSubmenu}
                    canEdit={canEdit}
                />
            ) : (
                <IdentifyingListItem
                    {...attr}
                    key={attr._id}
                    canEdit={canEdit}
                />
            )
        },
        [
            hasIdentities,
            attrWithActiveSubmenu,
            setAttrWithActiveSubmenu,
            canEdit,
        ]
    );

    return (
        <DisplayOnLoad {...loadingState}>
            <Dropdown
                mapAttrToUI={mapAttrToUI}
                attrs={attributes as GetEntityAttrsResponse}
            />
        </DisplayOnLoad>
    );
};

const StyledSingleControl = styled.div`
	display: flex;
	padding: ${(p) => p.theme.spacing(1)};
`;

export interface PopoverControlsGridProps {
    toggleDropdown: MouseEventHandler;
    entityHasIdentities: boolean;
    attributeLoadingStatus: 'success' | 'error' | 'loading';
    availableViews: ViewMode[];
    entity: any;
    canEdit: boolean;
}

const iconMap = {
    spatial: faGlobe,
    profile: faDisplay,
    graph: faCircleNodes,
    data: faDatabase,
    form: faDatabase,
    lineage: faCodeBranch,
    usage: faDatabase,
    relations: faDatabase,
    quality: faHeartPulse
};

const EntityPopoverNav: FunctionComponent<PopoverControlsGridProps> = (
    {
        availableViews,
        toggleDropdown,
        entityHasIdentities,
        entity,
        attributeLoadingStatus,
        canEdit,
    }
) => {

    if (entityHasIdentities) {
        return (
            <PopoverControlsGrid style={{width: '100%'}}>
                <FlexContainer
                    style={{width: '100%', padding: '0 .5rem'}}
                    gap='.5rem'
                    alignItems='center'
                    justifyContent='space-around'>
                    {availableViews.map((v) => (
                        <RouterLink
                            to={entity ? `/${entity.ontology.org}/${entity.ontology.domain}/${entity.ontology.entity}/${v}` : '/'}
                            key={v}
                            style={{flex: 1, padding: 0, margin: 0}}
                            preserveSearchParams={false}
                        >
                            <PaperButton
                                width='110px'
                                height='32px'
                                aria-label={`${v} view`}
                                tooltip={`view ${v}`}
                                tooltipPlacement="bottom"
                            >
                                <FontAwesomeIcon icon={iconMap[v]}/>
                            </PaperButton>
                        </RouterLink>
                    ))}
                </FlexContainer>
            </PopoverControlsGrid>
        );
    }

    if (attributeLoadingStatus === 'success') {
        return (
            <StyledSingleControl>
                <PaperButton
                    width='110px'
                    height='32px'
                    tooltipPlacement="bottom"
                    onClick={toggleDropdown}>
                    {canEdit ? 'Choose an identity' : 'View Attributes'}
                </PaperButton>
            </StyledSingleControl>
        );
    }

    if (attributeLoadingStatus === 'error') {
        return (
            <StyledSingleControl>
                <PaperButton onClick={toggleDropdown}>
                    <Typography color="error">View errors</Typography>
                </PaperButton>
            </StyledSingleControl>
        );
    }
    // don't display identity selection button until we have some attributes
    // to offer the user
    return null;
};

export interface EntityPopoverBodyProps {
    entity: BaseEntity | DomainGraphNode | any;
}

export const EntityInteractions: FunctionComponent<{ entity: BaseEntity; canEdit: boolean; menuOpen: boolean; toggleDropdown: MouseEventHandler; }> = (
    {
        entity,
        canEdit,
        menuOpen,
        toggleDropdown
    }
) => {

    return <FlexContainer
        justifyContent="space-around"
        style={{
            padding: '.5rem',
            gap: '.5rem'
        }}
    >
        {/*{ && <EntityFollowButton entity={entity} />}*/}

        <FollowButton
            resource={entity}
            objectAlreadyFollowed={entity && entity.userContext
                ? entity.userContext.interactions.following
                : false}
        />
        <QuestionButton
            objectId={entity._id}
            objectType="Entity"
            canEdit={canEdit}
        />
        <CommentButton
            objectId={entity._id}
            objectType="Entity"
            canEdit={canEdit}
        />
        <PaperButton width='38px' height='32px' onClick={toggleDropdown}
                     tooltip="toggle drawer">
            <DropdownIcon
                className={clsx(menuOpen && 'popover-menu-open')}
                icon={faChevronDown}
            />
        </PaperButton>
    </FlexContainer>
}

export const EntityPopover: FunctionComponent<EntityPopoverBodyProps> = (
    props
) => {
    const {entity} = props;

    const appDispatch = useAppDispatch();
	const {modalType, closeModal} = useModalType();

    const {register, handleSubmit, formState, resetField, reset} =
        useForm<DescribeEntityFormValues>({
            defaultValues: {
                singular: entity.singular,
                definition: entity.definition ?? DEFINITION_PLACEHOLDER,
                plural: entity.plural,
            },
        });

    const {
        singular: singularIsDirty,
        definition: definitionIsDirty,
        plural: pluralIsDirty,
    } = formState.dirtyFields;

    const [describe, describeResult] = useDescribeEntityMutation();

    useEffect(() => {
        if (entity) {
            const {definition, singular, plural} = entity;
            reset(
                {
                    singular,
                    plural,
                    definition: definition ?? DEFINITION_PLACEHOLDER
                },
                {keepDefaultValues: false}
            );
        }
    }, [reset, entity]);

    useEffect(() => {
        if (describeResult.error) {
            warnInDev(JSON.stringify(describeResult.error), 'error');
        }
    }, [describeResult.error]);

    const onSubmit: SubmitHandler<DescribeEntityFormValues> = (vals) => {
        if (vals.definition && nullifyDefinitionPlaceholder(vals.definition) === null) {
            return null;
        }
        describe({
            entityId: entity._id,
            body: vals,
        });
    };

    const conditionalSubmit: FormEventHandler = (e) => {
        e.preventDefault();

        if (formState.isDirty) {
            handleSubmit(onSubmit)();
        }

        return null;
    };

    const {attributes, ...attrQueryLoadingState} = useActiveEntityAttributes(
        entity.ontology.domain, entity.name);
        // useGetAttributesQuery({entityId: entity._id});
    // console.log(attributes)

    // const { activeItem: activeDomain } = useActiveDomainData();

    const canEdit = entity.userContext
        ? entity.userContext.permissions.edit || entity.userContext.permissions.admin
        : false;

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleDropdown: MouseEventHandler = () => {
        setMenuOpen((p) => !p);
    };

    return (
        <PopoverCardBody>
            <FlexContainer justifyContent='space-between' style={{
                width: '100%',
                borderBottom: menuOpen ? `1px solid ${theme.palette.divider}` : 'none'
            }}>
                <PopoverHead imgSrc=' '>
                    <PopoverSubhead variant='overline'>
                        <RouterLink
                            to={entity && entity.ontology ? `/${entity.ontology.org}/${entity.ontology.domain}` : '/'}>
                            {canEdit && entity.ontology ? entity.ontology.domain : ''}
                        </RouterLink>
                    </PopoverSubhead>
                    <PopoverHeading variant='h2'>
                        <RouterLink
                            to={entity && entity.ontology ? `/${entity.ontology.org}/${entity.ontology.domain}/${entity.ontology.entity}` : '/'}>
                            {entity.plural}
                        </RouterLink>
                    </PopoverHeading>
                    <PopoverMain>
                        <FlexContainer justifyContent='space-between'
                                       alignItems='center'>
                            <form style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '25%',
                                padding: 0,
                                margin: 0,
                                minWidth: '300px',
                                maxWidth: `calc(${theme.spacing(4)} + 250px)`
                            }} onSubmit={conditionalSubmit}>
                                <SubtleTextbox
                                    {...register('singular')}
                                    showLabel
                                    key={`${entity._id}-form-singular`}
                                    id={`${entity._id}-form-singular`}
                                    isDirty={Boolean(singularIsDirty)}
                                    label="Singular"
                                    onReset={() => resetField('singular')}
                                    overrides={{
                                        root: {
                                            display: 'block',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            width: '100%',
                                            maxWidth: '250px'
                                        },
                                    }}
                                    disabled={!canEdit}
                                />
                                <SubtleTextbox
                                    {...register('plural')}
                                    key={`${entity._id}-form-plural`}
                                    id={`${entity._id}-form-plural`}
                                    isDirty={Boolean(pluralIsDirty)}
                                    label="Plural"
                                    showLabel
                                    onReset={() => resetField('plural')}
                                    overrides={{
                                        root: {
                                            display: 'block',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            width: '100%',
                                            maxWidth: '250px',
                                        },
                                        label: {}
                                    }}
                                    disabled={!canEdit}
                                />
                                {/* Always show this field if user has edit permission.  Otherwise,
								only show it if there is user-created content to populate it.*/}
                                {/*{(canEditDomain || isNonEmptyString(definition)) && (*/}
                                {/*	canEditDomain ? (*/}
                                {/*		<SubtleTextArea*/}
                                {/*			{...register('definition')}*/}
                                {/*			isDirty={Boolean(definitionIsDirty)}*/}
                                {/*			label="About"*/}
                                {/*			showlabel*/}
                                {/*			onReset={() => resetField('definition')}*/}
                                {/*			rows={2}*/}
                                {/*			overrides={{*/}
                                {/*				root: {*/}
                                {/*					resize: 'vertical',*/}
                                {/*					width: '100%',*/}
                                {/*					height: '100px'*/}
                                {/*				},*/}
                                {/*				label: {}*/}
                                {/*			}}*/}
                                {/*			disabled={!canEditDomain}*/}
                                {/*		/>) : (<p>{definition}</p>)*/}
                                {/*)}*/}
                            </form>
                            {canEdit && (
                                <IconButton
                                    icon={faCheck}
                                    fillColor="primary"
                                    size="xs"
                                    iconSize={'lg'}
                                    variant={'transparent'}
                                    baseOpacity="80%"
                                    tooltip={`update ${entity.plural}`}
                                    tooltipPlacement="bottom"
                                    disabled={!formState.isDirty}
                                    showTooltip={formState.isDirty}
                                    onClick={(e) => conditionalSubmit(e)}
                                    aria-label="submit changes"
                                />)}
                        </FlexContainer>
                    </PopoverMain>
                </PopoverHead>
                <EntityMenu
                    resource={entity}
                    canEdit={canEdit}
                />
            </FlexContainer>
            <PopoverDrawer open={menuOpen}>
                {!!attributes ?
                <EntityPopoverDropdown
                    {...attrQueryLoadingState}
                    entity={entity}
                    attributes={attributes}
                    hasIdentities={!!entity?.hasIdentities ? entity.hasIdentities : false}
                    canEdit={canEdit}
                /> : null}
            </PopoverDrawer>
            <EntityPopoverNav
                attributeLoadingStatus={
                    attrQueryLoadingState.isError
                        ? 'error'
                        : attrQueryLoadingState.isLoading
                        ? 'loading'
                        : attrQueryLoadingState.isSuccess
                            ? 'success'
                            : 'loading'
                }
                canEdit={canEdit}
                availableViews={deriveEntityViews(entity)}
                entity={entity}
                toggleDropdown={toggleDropdown}
                entityHasIdentities={!!entity?.hasIdentities ? entity.hasIdentities : false}
            />
            <EntityInteractions
                entity={entity}
                canEdit={canEdit}
                toggleDropdown={toggleDropdown}
                menuOpen={menuOpen}
            />
        </PopoverCardBody>
    );
};

export default EntityPopover;
