import Button from '../../../common/buttons/Button';
import FlexContainer from '../../../common/FlexContainer';
import {FormResults} from '../../../common/Form';
import Spinner from '../../../common/loading/Spinner';
import Typography from '../../../common/text/Typography';
import {useGetEntitiesQuery, useImportEntityMutation} from '../../api';
import {extractQueryErrMessage} from '../../api/helpers';
import {ForceGraphContext} from '../../graph/state/GraphContextProvider';
import {OrgGraphNode} from '../../ontology/types/orgTypes';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import React, {
    FormEventHandler,
    FunctionComponent,
    useContext,
    useState,
} from 'react';
import AppModal from "../../../common/modals/AppModal";
import useModalType from "../../HUD/hooks/useModalType";

interface ImportEntityFormProps {
    domainId: number;
    domainLabel: string;
    setOpenAction: () => void;
}

const ImportEntityForm: FunctionComponent = () => {
    const [graphState, graphDispatch] = useContext(ForceGraphContext);

    const {modalProps, closeModal} = useModalType();
    const {objectId: domainId, resource} = modalProps;
    // const appDispatch = useAppDispatch();
    // const modalType = useModalType();
    // const closeModal = () => appDispatch(setModalType(null));
    // const isOpen = modalType === 'importEntity';

    const [selectedDomain, setSelectedDomain] = useState<number | null>(
        (graphState.graphData.nodes as OrgGraphNode[])[0]._id
    );

    const [entityToImport, setEntityToImport] = useState<number | null>(null);

    const [importEntity, importResult] = useImportEntityMutation();

    const queryRes = useGetEntitiesQuery(
        selectedDomain ? {domainId: selectedDomain} : skipToken
    );

    const handleSubmit: FormEventHandler = (e) => {
        e.preventDefault();
        //   OK to cast since submit button is disabled if this value is null
        importEntity({domainId, entityId: entityToImport as number});
    };

    const renderEntitySelect = () => {
        if (queryRes.isUninitialized) {
            return (
                <FlexContainer justifyContent="center">
                    <Typography paragraph>
                        Select a domain to continue
                    </Typography>
                </FlexContainer>
            );
        }

        if (queryRes.isLoading || queryRes.isFetching) {
            return (
                <FlexContainer justifyContent="center">
                    <Typography paragraph>Loading entities...</Typography>
                    <Spinner/>
                </FlexContainer>
            );
        }

        if (queryRes.isError) {
            return (
                <FlexContainer justifyContent="center">
                    <Typography color="error" paragraph>
                        {extractQueryErrMessage(queryRes.error)}
                    </Typography>
                </FlexContainer>
            );
        }

        if (queryRes.data.length === 0) {
            return (
                <FlexContainer justifyContent="center">
                    <Typography color="warn" paragraph>
                        No entities available for this domain
                    </Typography>
                </FlexContainer>
            );
        }

        return (
            <>
                <label htmlFor="select-import-entity">
                    <Typography>
                        Select an entity to import into {resource.label}
                    </Typography>
                </label>
                <select
                    id="select-import-entity"
                    onChange={(e) => setEntityToImport(+e.target.value)}
                >
                    {queryRes.data.map((e) => (
                        <option value={e._id} key={e._id}>
                            {e.plural}
                        </option>
                    ))}
                </select>
            </>
        );
    };

    return (
        <AppModal
            label={"Import Entity"}
            isOpen={true}
            isDirty={true}
            onClose={closeModal}
            onSubmit={handleSubmit}
            canEdit={true}>
            <label htmlFor="select-import-domain">
                <Typography>Select a domain to import from</Typography>
            </label>
            <select
                id="select-import-domain"
                onChange={(e) => setSelectedDomain(+e.target.value)}
            >
                {(graphState.graphData.nodes as OrgGraphNode[]).map(
                    (domain) => (
                        <option value={domain._id} key={domain._id}>
                            {domain.label}
                        </option>
                    )
                )}
            </select>

            {renderEntitySelect()}
            <Button disabled={entityToImport === null} type="submit">
                Submit
            </Button>
            <FormResults
                {...importResult}
                validationErrors={{}}
                onSuccess={closeModal}
                onSuccessDelay={1500}
            />
        </AppModal>
    );
};

export default ImportEntityForm;
