import { useAppDispatch } from '../../app/hooks';
import Notification from '../../common/Notification/Notification';
import { clearErr, selectErrMessage } from './state/errorSlice';
import { nanoid } from 'nanoid';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

const ErrorAlert: FunctionComponent = () => {
	const message = useSelector(selectErrMessage);

	const appDispatch = useAppDispatch();

	const closeHandler = () => appDispatch(clearErr());

	const id = nanoid();

	return null;
	// return (
	// 	<Notification
	// 		level="error"
	// 		isOpen={!!message}
	// 		closeHandler={closeHandler}
	// 		id={id}
	// 	>
	// 		{message}
	// 	</Notification>
	// );
};

export default ErrorAlert;
