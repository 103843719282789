import Button from '../../../common/buttons/Button';
import FlexContainer from '../../../common/FlexContainer';
import { FormResults } from '../../../common/Form';
import Heading from '../../../common/text/Heading';
import Typography from '../../../common/text/Typography';
import { useDeleteDomainMutation } from '../../api';
import { ForceGraphContext } from '../../graph/state/GraphContextProvider';
import { SET_POPUP_ELEMENT } from '../../graph/state/actions';
import {
	StyledFlexContainer,
	StyledPaper,
	StyledDeleteButton,
} from '../common/styledComponents';
import {
	Dispatch,
	FunctionComponent,
	MouseEventHandler,
	SetStateAction,
	useContext,
} from 'react';
import useModalType from "../../HUD/hooks/useModalType";
import AppModal from "../../../common/modals/AppModal";

interface DeleteDomainFormProps {
	domainId: number;
	domainLabel: string;
}

const DeleteDomainForm: FunctionComponent = () => {
	// const [_, graphDispatch] = useContext(ForceGraphContext);
	const {modalProps, closeModal} = useModalType();
	const {objectId: domainId, resource} = modalProps;

	const [deleteDomain, mutationResults] = useDeleteDomainMutation();

	const onClick: MouseEventHandler = () => {
		deleteDomain({ domainId });
	};

	const onSuccessfulDelete = () => closeModal();
	// graphDispatch({ type: SET_POPUP_ELEMENT, payload: null });

	return (
		<AppModal
			isForm={false}
			label={'Delete Domain'}
			isOpen={true}
			isDirty={false}
			onClose={closeModal}
			onSubmit={() => {}}
			canEdit={true} >
			<StyledPaper>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					<Heading component="h5">
						<Typography>
							Are you sure you want to delete {resource.label}?
						</Typography>
					</Heading>
					<FlexContainer
						style={{ width: '50%' }}
						justifyContent="space-between"
					>
						<Button onClick={closeModal}>
							Go Back
						</Button>
						<StyledDeleteButton onClick={onClick}>
							Delete
						</StyledDeleteButton>
					</FlexContainer>
					<FormResults
						{...mutationResults}
						validationErrors={{}}
						onSuccess={onSuccessfulDelete}
					/>
				</StyledFlexContainer>
			</StyledPaper>
		</AppModal>
	);
};

export default DeleteDomainForm;
