import LinkedInShareButton from '../../../buttons/LinkedInShareButton';
import { filterAttrsForYMenu } from './helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlexContainer from 'common/FlexContainer';
import {
	YearIcon,
	MonthIcon,
	DayIcon,
	HourIcon,
	MinuteIcon,
	SecondIcon,
	CheckIcon
} from 'common/icons';
import Heading from 'common/text/Heading';
import { List, ListItem, ListItemAvatar, ListItemContent } from 'common/List';
import SlideToggle, { SlideToggleCheckDescriptor } from 'common/inputs/SlideToggle';
import Typography from 'common/text/Typography';
import theme from 'common/theme/theme';
import { sum } from 'common/utils/functionUtils';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import CategoryMeta from 'features/dataPreparation/CategoryMeta';
import useDispatchableErr from 'features/errorHandling/hooks/useDispatchableErr';
import { cardinalityLessThan } from 'features/ontology/helpers/attributeHelpers';
import useActiveIndividualsMeta from 'features/ontology/hooks/useActiveIndividualsMeta';
import { isCategory } from 'features/ontology/typeGuards/attributeGuards';
import {
	StyledAttributeList,
	StyledVizWithControls,
	StyledSubmoduleHeader,
	StyledVizPaper,
	StyledSubmoduleContent,
	StyledChartGrid
} from 'features/profile/EntityProfile/components/styledComponents';
import EventLineChart from 'common/viz/LineChart/LineChartCore';
import { groupDataToEventLines } from 'common/viz/LineChart/LineChartCore/helpers';
import {
	EventLineDrawFn,
	TimeInterval,
	Transformers,
} from 'common/viz/LineChart/LineChartCore/types';
import { distributeByCategory } from '../../Scatterplot/ScatterplotCore/helpers';
import {
	FunctionComponent,
	MouseEventHandler,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';
import useElementSize from "../../../hooks/useSize";
import IconButton from "../../../buttons/IconButton";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import {BaseAttribute} from "../../../../features/ontology/types/attributeTypes";

const colors = [
	theme.palette.primary.main,
	theme.palette.secondary.main,
	theme.palette.error.main,
	theme.palette.info.main,
];

const svgId = 'event-line-chart-for-capture';

export const LINE_POINT_TEST_ID = 'event-line-module-test-id';

export const LINE_POPOVER_TEST_ID = 'event-line-popover-test-id';

const toggleDefs: SlideToggleCheckDescriptor<TimeInterval | 'none'>[] = [
	{
		label: 'year',
		icon: YearIcon,
		value: 'FullYear',
		tooltip: 'years',
	},
	{ label: 'month', icon: MonthIcon, value: 'Month', tooltip: 'months' },
	{ label: 'day', icon: DayIcon, value: 'Date', tooltip: 'days' },
	{ label: 'hour', icon: HourIcon, value: 'Hours', tooltip: 'hours' },
	{ label: 'minute', icon: MinuteIcon, value: 'Minutes', tooltip: 'minutes' },
	{ label: 'second', icon: SecondIcon, value: 'Seconds', tooltip: 'seconds' },
];

const EventLineChartModule: FunctionComponent = () => {
	const dispatchError = useDispatchableErr();

	const [size, setSizeTarget] = useElementSize();
	const [settingsOpen, setSettingsOpen] = useState(false);

	const [timeGrouping, setTimeGrouping] = useState<TimeInterval | 'none'>(
		'FullYear'
	);

	const {
		getActiveAttributeName,
		getAllLineChartY,
		appendLineChartY,
		removeLineChartY,
		getAllEventLineCat,
		appendEventLineCat,
		removeEventLineCat,
	} = useEntitySearchParams();

	const xAttrName = getActiveAttributeName();

	const yAttrNames = getAllLineChartY();

	const categoryAttrNames = getAllEventLineCat();

	const colorMap = [...yAttrNames, ...categoryAttrNames].reduce(
		(acc, name, i) => {
			acc[name] = colors[i % colors.length];
			return acc;
		},
		{} as Record<string, string>
	);

	const makeMenuHandler =
		(
			attrName: string,
			existingNames: string[],
			append: (x: string) => void,
			remove: (x: string) => void
		): MouseEventHandler =>
		() => {
			if (existingNames.includes(attrName)) {
				return remove(attrName);
			}

			return append(attrName);
		};

	const { preparedData, ...individualsLoadState } =
		useActiveIndividualsMeta();


	const attributeName = getActiveAttributeName();

	// const categoryAttrNames = getAllHistogramCat();

	const activeAttribute = !!preparedData && !!preparedData.attributes ?
		preparedData.attributes.filter((a) => a.name === attributeName)[0]
	: {singular: attributeName} as BaseAttribute;

	const {
		rows,
		groupedByTimeInterval,
		colHeadings,
		rowHeadings,
		cols,
		xMin,
		xMax,
		yMax,
		primaryAttribute,
	} = useMemo(() => {
		if (preparedData && xAttrName && yAttrNames) {
			const primaryAttribute = preparedData.attributes.find(
				(a) => a.isPrimary === true
			)!;

			const categoryMetas = categoryAttrNames.map(
				(name) => preparedData.attributeFields[name] as CategoryMeta
			);

			const recoveryGuide = categoryMetas.map((m) =>
				Array.from(m.uniqueValues.values()).sort()
			);

			const { charts } = distributeByCategory(
				preparedData,
				xAttrName,
				yAttrNames,
				categoryAttrNames,
				recoveryGuide
			);

			// if the attribute providing value for y-axis is the primary identity, we just
			// want to count the number of individuals that fall in a given time group.  Otherwise,
			// the attribute is a quantity, and we want to sum the individual values.
			const transformers: Transformers = yAttrNames.reduce(
				(acc, name) => {
					acc[name] =
						name === primaryAttribute.name
							? { map: () => 1, aggregate: sum }
							: { aggregate: sum };
					return acc;
				},
				{} as Transformers
			);

			const groupedByTimeInterval = charts.map((chart) =>
				groupDataToEventLines(chart, timeGrouping, transformers)
			);

			// get full extent of merged data so each chart can have the same
			// axes
			const { xMin, xMax, yMax } = groupedByTimeInterval.reduce(
				(acc, lines) => {
					lines.forEach((line) => {
						const {
							xMax: xMaxForLine,
							xMin: xMinForLine,
							yMax: yMaxForLine,
						} = line;

						if (acc.xMax === undefined || xMaxForLine > acc.xMax) {
							acc.xMax = xMaxForLine;
						}

						if (acc.xMin === undefined || xMinForLine < acc.xMin) {
							acc.xMin = xMinForLine;
						}

						if (acc.yMax === undefined || yMaxForLine > acc.yMax) {
							acc.yMax = yMaxForLine;
						}
					});

					return acc;
				},
				{} as { xMax: Date; xMin: Date; yMax: number }
			);

			const colHeadings = recoveryGuide[1] ?? [];

			const rowHeadings = recoveryGuide[0] ?? [];

			const rows = recoveryGuide.length === 0 ? 1 : rowHeadings.length;

			const cols =
				recoveryGuide.length === 0 || recoveryGuide.length === 1
					? 1
					: colHeadings.length;

			return {
				primaryAttribute,
				groupedByTimeInterval,
				rows,
				cols,
				recoveryGuide,
				colHeadings,
				rowHeadings,
				xMin,
				xMax,
				yMax,
			};
		}

		const placeholderDate = new Date(Date.now());

		return {
			primaryAttribute: null,
			xMin: placeholderDate,
			xMax: placeholderDate,
			yMax: 0,
			groupedByTimeInterval: [],
			rows: 1,
			cols: 1,
			recoveryGuide: [],
			colHeadings: [],
			rowHeadings: [],
		};
	}, [xAttrName, preparedData, yAttrNames, timeGrouping, categoryAttrNames]);

	// if no attribute for y-axis is set in URL search params,
	// set it to primary id attribute
	useLayoutEffect(() => {
		const existingLineChartY = getAllLineChartY();
		if (primaryAttribute && existingLineChartY.length === 0) {
			appendLineChartY(primaryAttribute.name);
		}

		return;
	}, [primaryAttribute, appendLineChartY, getAllLineChartY]);

	const drawLine: EventLineDrawFn = ({ path, lineId, yAttr }) => {
		return (
			<path
				d={path}
				fill="none"
				stroke={colorMap[yAttr]}
				key={lineId}
				strokeWidth={1.5}
			/>
		);
	};

	const getMenuText = () =>
		yAttrNames.length > 0
			? {
					color: 'oldManGray' as const,
					text: 'Measures',
			  }
			: {
					color: 'error' as const,
					text: 'select Y variable',
			  };

	const menuText = getMenuText();

	return (
		<StyledVizPaper>
					<StyledSubmoduleHeader>
				<Typography variant='h6' color="cyan">
					Distribution of {activeAttribute ? activeAttribute.singular: attributeName}
				</Typography>
				<IconButton icon={faGear} onClick={() => setSettingsOpen((p) => !p)}/>
			</StyledSubmoduleHeader>
			<StyledVizWithControls settingsOpen={settingsOpen}>
				<DisplayOnLoad {...individualsLoadState}>
					<StyledSubmoduleContent ref={setSizeTarget} id={svgId}>
						<StyledChartGrid rows={rows} cols={cols}>
							{groupedByTimeInterval.map((lines, i) => (
								<EventLineChart
									lines={lines}
									topLabel={colHeadings[i]}
									leftLabel={
										i % cols === 0
											? rowHeadings[i / cols]
											: undefined
									}
									xMin={xMin}
									xMax={xMax}
									yMax={yMax}
									svgId={svgId}
									drawLine={drawLine}
									key={i}
								/>
							))}
						</StyledChartGrid>
					</StyledSubmoduleContent>
				</DisplayOnLoad>
				{
					settingsOpen ? <StyledAttributeList>
					<List
						style={{
							overflowY: 'auto',
							paddingTop: '.05rem',
							paddingBottom: '.05rem',
							paddingLeft: '1rem',
						}}
					>
						<Typography
							variant="subtitle"
							color={menuText.color}>
							Share
						</Typography>
						<ListItem>
							<LinkedInShareButton elementId={svgId} />
						</ListItem>
					</List>
					<List
						style={{
							overflowY: 'auto',
							paddingTop: '.05rem',
							paddingBottom: '.05rem',
							paddingLeft: '1rem',
							marginTop: '1rem',
						}}
					>
						<Typography
							variant="subtitle"
							color={menuText.color}>
							{menuText.text}
						</Typography>
						{primaryAttribute && (
							<ListItem
								button
								aria-current={
									yAttrNames.includes(primaryAttribute.name)
								}
								onClick={makeMenuHandler(
									primaryAttribute.name,
									yAttrNames,
									appendLineChartY,
									removeLineChartY
								)}>
								{/*{yAttrNames.includes(primaryAttribute.name) && (*/}
								{/*	<ListItemAvatar>*/}
								{/*		<FontAwesomeIcon*/}
								{/*			size='xs'*/}
								{/*			icon={faCheck}*/}
								{/*			color={colorMap[primaryAttribute.name]}*/}
								{/*		/>*/}
								{/*	</ListItemAvatar>*/}
								{/*)}*/}
								<ListItemContent>
									<Typography variant={'caption2'} color={'primary'}>
										{`Number of ${primaryAttribute.entity.plural}`}
									</Typography>
								</ListItemContent>
							</ListItem>
						)}
						{preparedData &&
							xAttrName &&
							filterAttrsForYMenu(
								xAttrName,
								preparedData.attributes,
								['quantity']
							).map((a) => {
								const current = yAttrNames.includes(a.name);

								return (
									<ListItem
										button
										key={a.name}
										aria-current={!!current}
										onClick={makeMenuHandler(
											a.name,
											yAttrNames,
											appendLineChartY,
											removeLineChartY
										)}
										style={{
											color:
												colorMap[a.name] ?? 'inherit',
										}}
									>
										{/*{current && (*/}
										{/*	<ListItemAvatar>*/}
										{/*		<FontAwesomeIcon*/}
										{/*			size='xs'*/}
										{/*			icon={faCheck}*/}
										{/*		/>*/}
										{/*	</ListItemAvatar>*/}
										{/*)}*/}
										<ListItemContent>
											<Typography variant={'caption2'}>
												{a.singular}
											</Typography>
										</ListItemContent>
									</ListItem>
								);
							})}
					</List>
					<List style={{
						overflowY: 'auto',
						paddingTop: '.05rem',
						paddingBottom: '.05rem',
						paddingLeft: '1rem',
						marginTop: '1rem',
					}}>
						<Typography
							variant="subtitle"
							color={menuText.color}>
							Group By
						</Typography>
						{preparedData &&
							preparedData.attributes
								.filter(
									(a) =>
										isCategory(a) &&
										cardinalityLessThan(10, a)
								)
								.map((a) => {
									const isCurrent =
										categoryAttrNames.includes(a.name);

									return (
										<ListItem
											button
											key={a.name}
											aria-current={isCurrent}
											onClick={makeMenuHandler(
												a.name,
												categoryAttrNames,
												appendEventLineCat,
												removeEventLineCat
											)}
											style={{
												color: isCurrent
													? theme.palette.primary.main
													: 'inherit',
											}}
										>
											{isCurrent && (
												<ListItemAvatar>
													<FontAwesomeIcon
														icon={CheckIcon}
													/>
												</ListItemAvatar>
											)}
											<ListItemContent>
												<Typography variant={'caption2'}>
												{a.plural}
											</Typography>
											</ListItemContent>
										</ListItem>
									);
								})}
					</List>
					<List
						style={{
							overflowY: 'auto',
							paddingTop: '.05rem',
							paddingBottom: '.05rem',
							paddingLeft: '1rem',
							marginTop: '1rem',
						}}
					>
						<ListItem>
							<SlideToggle
								onActiveValueMismatch={dispatchError}
								activeValue={timeGrouping}
								legend="group events by time period"
								onCheck={setTimeGrouping}
								checkboxes={toggleDefs}
							/>
						</ListItem>
					</List>
				</StyledAttributeList> : null}
			</StyledVizWithControls>
		</StyledVizPaper>
	);
};

export default EventLineChartModule;
