import environment from '../../common/environment';
import { flow } from '../../common/utils/functionUtils';
import { warnInDev } from '../../common/utils/reactUtils';
import {
	GetUserParams,
	GetUserProfileParams,
	GetLandingParams,
	UpdateUserParams,
	GrantAccessParams,
	GetFollowingParams,
	GetFollowersParams,
	FollowersSubject,
	FollowResourceParams,
	UnfollowResourceParams,
	GetUserInvitesParams,
	buildInvitationsQueryURL,
	buildParametrizedInvitationsQueryURL,
	buildFollowQueryURL,
	buildParametrizedFollowQueryURL,
	buildUnfollowQueryURL,
	buildParametrizedUnfollowQueryURL,
	buildFollowingQueryURL,
	buildParametrizedFollowingQueryURL,
	buildFollowersQueryURL,
	buildParametrizedFollowersQueryURL,
	DeleteResourceParams,
	buildDeleteResourceQueryURL,
	buildParametrizedDeleteResourceQueryURL,
	GetUsersWaitingParams,
	buildUsersWaitingQueryURL,
	buildParametrizedUsersWaitingQueryURL,
	WithdrawInviteParams,
	ResendInviteParams,
} from '../authentication/types/userTypes';
import {
	GenDomainFromCatalogParams,
	GenDomainFromDatasetParams,
	GetLiveDataSetsParams,
	GetLiveDataCatalogsParams,
	GetLiveDataSetPreviewParams,
	GetSourceSummariesParams, // GenDomainFromSourceParams,
	ImportCatalogToDomainParams,
	ImportDatasetToDomainParams,
	ProfileSourceParams,
	TestSourceParams,
	UpdateSourceParams,
	DeleteSourceParams,
	CacheCatalogParams,
	CreateCatalogParams,
	ProfileCatalogParams,
	TestCatalogParams,
	DropCatalogParams,
	CreateDatasetParams,
	UpdateDatasetParams,
	ProfileDatasetParams,
	CacheDatasetParams,
	TestDatasetParams,
	DropDatasetParams,
	TestColumnParams,
	ProfileColumnParams,
	UpdateColumnParams,
	DropColumnParams,
	CreateDataLoadParams,
	GetDataProfilesParams,
	DataProfileSubject,
	GetDataTestsParams,
	DataTestSubject,
	GetDataStatisticsParams,
	DataStatisticSubject,
	GetDataErrorsParams,
	DataErrorSubject,
	GetDataLoadsParams,
} from '../browser/types/dataTypes';
import {
	buildDataProfilesQueryURL,
	buildParametrizedDataProfilesQueryURL,
	buildDataTestsQueryURL,
	buildParametrizedDataTestsQueryURL,
	buildDataStatisticsQueryURL,
	buildParametrizedDataStatisticsQueryURL,
	buildDataErrorsQueryURL,
	buildParametrizedDataErrorsQueryURL,
} from '../browser/helpers';
import { AggregateAttrActionParams } from '../actions/aggregateAttribute/aggregateAttributeTypes';
import { AppendAttrActionParams } from '../actions/appendAttribute/appendAttributeTypes';
import { CreateConditionalActionParams } from '../actions/createConditional/createConditionalTypes';
import { CreateEventActionParams } from '../actions/createEvent/createEventTypes';
import { CreateLocationActionParams } from '../actions/createLocation/createLocationTypes';
import { DeleteAttrActionParams } from '../actions/deleteAttribute/deleteAttributeTypes';
import { DescribeAttrActionParams } from '../actions/describeAttribute/describeAttributeTypes';
import { DescribeDomainParams } from '../actions/describeDomain/describeDomainTypes';
import { DescribeEntityParams } from '../actions/describeEntity/describeEntityTypes';
import { DescribeMetricParams } from '../actions/describeMetric/describeMetricTypes';
import { LoadAttrActionParams } from '../actions/loadAttribute/loadAttributeTypes';
import { MoveAttrActionParams } from '../actions/moveAttribute/moveAttributeTypes';
import { PivotEntityParams } from '../actions/pivotEntity/pivotEntityTypes';
import { SplitAttrActionParams } from '../actions/splitAttribute/splitAttributeTypes';
import { TranslateValueActionParams } from '../actions/translateValue/translateValueTypes';
import { AddComponentActionParams } from '../actions/addComponent/addComponentTypes';
import { MeltEntityParams } from '../actions/meltEntity/meltEntityTypes';
import { OperateAttrActionParams } from '../actions/operateAttribute';
import { PersistEntityParams } from '../actions/persistEntity/persistEntityTypes';
import { RelateAttrActionParams } from '../actions/relateAttribute/relateAttributeTypes';
import { RestrictEntityActionParams } from '../actions/restrictEntity/restrictEntityTypes';
import {
	CreateOrgAdminParams,
	CreateOrgGovernorParams,
	CreateOrgMemberParams,
	CreateOrgEngineerParams,
	CreateOrgPublisherParams,
	CreateOrgMaintainerParams,
	CreateOrgFunderParams,
	CreateOrgReporterParams,
	CreateOrgContactParams,
	CreateOrgRightsHolderParams,
	CreateDomainMemberParams,
	CreateDomainStewardParams,
	FollowUserParams,
	GetOrgAdminsParams,
	GetOrgGovernorsParams,
	GetOrgMembersParams,
	GetOrgEngineersParams,
	GetOrgPublishersParams,
	GetOrgContactsParams,
	GetOrgMaintainersParams,
	GetOrgFundersParams,
	GetOrgRightsHoldersParams,
	GetOrgReportersParams,
	GetDomainMembersParams,
	GetDomainStewardsParams,
	RemoveUserRoleParams,
	UnfollowUserParams,
} from '../governance/types/GovernanceTypes';
import {
	GetOrgParams,
	GetOrgsParams,
	GetOrgGraphParams,
	// CreateOrgParams,
	UpdateOrgParams,
	DeleteOrgParams,
	UnfollowOrgParams,
	FollowOrgParams
} from '../ontology/types/orgTypes';
import {
	GetAttributeParams,
	GetAttributesParams,
	GetEntityAttrsParams,
	GetDomainAttrsParams,
	GetAttrNeighborsParams,
	GetAttributeMembersParams,
	GetAttributeConditionsParams,
	GetAttributeSourcesParams,
} from '../ontology/types/attributeTypes';
import {
	DeleteDomainParams,
	UpdateDomainParams,
	// CreateDomainParams,
	GetDomainGraphParams,
	GetDomainParams,
	GetDomainsParams,
	UnfollowDomainParams,
	FollowDomainParams
} from '../ontology/types/domainTypes';
import {
	CreateInsightParams,
	GetInsightsParams,
	GetInsightParams,
	UpdateInsightParams,
	DeleteInsightParams,
	FollowInsightParams,
	UnfollowInsightParams,
} from '../ontology/types/insightTypes';
import {
	CreateMetricParams,
	GetMetricsParams,
	GetMetricParams,
	UpdateMetricParams,
	DeleteMetricParams,
	FollowMetricParams,
	UnfollowMetricParams
} from '../ontology/types/metricTypes';
import {
	GetEntitiesParams,
	GetEntityGraphParams,
	GetEntityParams,
	ImportEntityParams,
	UnfollowEntityParams,
	isDeleteImportParams,
	FollowEntityParams,
	IdentifyEntityParams,
	DeleteEntityParams,
	UpdateEntityParams,
	GetRestrictionsParams,
	RestrictionSubject,
} from '../ontology/types/entityTypes';
import {
	buildRestrictionsQueryURL,
	buildParametrizedRestrictionsQueryURL,
} from '../ontology/helpers/entityHelpers';
import {
	GetIndividualsParams,
	GetIndividualParams,
	CreateIndividualParams,
	UpdateIndividualParams,
	DeleteIndividualParams,
	FollowIndividualParams,
	UnfollowIndividualParams,
} from '../ontology/types/individualTypes';
import {
	buildParametrizedQuestionsQueryURL,
	buildQuestionsQueryURL,
} from '../../common/questions/helpers';
import {
	AcceptAnswerParams,
	CreateAnswerParams,
	CreateQuestionParams,
	GetQuestionsParams,
	QuestionSubject,
} from '../../common/questions/types/questionTypes';
import {
	buildParametrizedCommentsQueryURL,
	buildCommentsQueryURL
} from '../../common/comments/helpers';
import {
	buildMessagesQueryURL,
	buildParametrizedMessagesQueryURL,
} from '../messages/helpers';
import {
	buildNotificationsQueryURL,
	buildParametrizedNotificationsQueryURL,
} from '../notifications/helpers';
import {
	buildParametrizedActivitiesQueryURL,
	buildActivitiesQueryURL,
	buildViewersQueryURL,
	buildParametrizedViewersQueryURL,
	buildReactionsQueryURL,
	buildParametrizedReactionsQueryURL,
	buildTasksQueryURL,
	buildParametrizedTasksQueryURL,
} from '../../common/activities/helpers';
import {
	CreateCommentParams,
	GetCommentsParams,
	CommentSubject,
} from '../../common/comments/types/commentTypes';
import {
	GetMessagesParams,
	MessageSubject,
} from '../messages/types/messageTypes';
import {
	GetActivitiesParams,
	ActivitySubject,
	GetViewersParams,
	ViewerSubject,
	GetReactionsParams,
	ReactionSubject,
	CreateReactionParams,
	GetTasksParams,
	TaskSubject,
} from '../../common/activities/types/activityTypes';
import {
	GetNotificationsParams,
	NotificationSubject,
} from '../notifications/types/notificationTypes';
import { ConditionalArgs } from './types';
import { isNonNullObject } from 'common/utils/typeGuards';
import { GetAttributeLineageParams } from 'features/ontology/types/lineageTypes';
import {
	CreateFeedbackParams,
	UpdateAccessibilityParams,
	UpdateSettingsParams
} from "../navigation/types/navigationTypes";
import {CreateActivityActionParams} from "../actions/createActivity/createActivityTypes";
import {CreateShareParams} from "common/sharing/types";

const warnOnMissingSeparator = () =>
	warnInDev(
		`remove leading/trailing separator was called without a string argument.  There is likely a broken type somewhere in the application code, and a broken or inaccurate link in the application`,
		'error'
	);

const createWarn = () => {
	let hasWarned = false;

	return () => {
		if (!hasWarned) {
			warnOnMissingSeparator();
			hasWarned = true;
		}

		return;
	};
};

const warn = createWarn();

const removeTrailingSeparator = (separator: string) => (s: string) => {
	if (s === null || typeof s === 'undefined') {
		warn();
		return s;
	}

	while (s[s.length - 1] === separator) {
		s = s.slice(0, s.length - 1);
	}

	return s;
};

const removeLeadingSeparator = (separator: string) => (s: string) => {
	if (s === null || typeof s === 'undefined') {
		warn();
		return s;
	}

	while (s[0] === separator) {
		s = s.slice(1, s.length);
	}
	return s;
};

export const joinOnSeparator =
	(separator: string) =>
	(...args: string[]) => {
		return args
			.map(
				flow(
					removeLeadingSeparator(separator),
					removeTrailingSeparator(separator)
				)
			)
			.join(separator);
	};

export const dataJoinOnSeparator =
	(separator: string) =>
	(...args: string[]) => {
		const dataArgs = ['data', ...args];
		return dataArgs
			.map(
				flow(
					removeLeadingSeparator(separator),
					removeTrailingSeparator(separator)
				)
			)
			.join(separator);
	};

export const withLeadingSlash =
	(separator: string) =>
	(...args: string[]) =>
		'/' + joinOnSeparator(separator)(...args);

export const joinOnSlash = joinOnSeparator('/');
export const dataJoinOnSlash = dataJoinOnSeparator('/');

const replacer = (s: string, k?: any) =>
	s
		.split('/')
		.map((s) => {
			if (s[0] === ':') {
				const replacementVal = k ? k[s.substring(1)] : null;

				if (!replacementVal) {
					// get the stack into the console
					console.error(
						'Parameter/value mismatch in URL builder\nParams:\n',
						JSON.stringify(k)
					);
					console.error('path:\n', s);
					// break the program
					throw new Error('Parameter/value mismatch in URL builder');
				}

				return replacementVal;
			}

			return s;
		})
		.join('/');

interface BuilderConfig<T, K> {
	urlConstructor: (...params: ConditionalArgs<T>) => string;
	endpointConstructor: (...params: ConditionalArgs<K>) => string;
}

const isBuilderConfig = <T, K>(
	v: string | BuilderConfig<T, K>
): v is BuilderConfig<T, K> => isNonNullObject(v);

// TODO: this should use URL/URLParams constructor rather than this
// homeroll bullshit.
export const makeBuilder =
	(domain: string) =>
	<T = undefined, K = undefined>(
		endpointPath: string | BuilderConfig<T, K>
	) => {
		if (isBuilderConfig(endpointPath)) {
			return {
				buildURL: (...args: ConditionalArgs<T>) =>
					joinOnSlash(domain, endpointPath.urlConstructor(...args)),

				buildSubpath: (...args: ConditionalArgs<T>) =>
					joinOnSlash('/', endpointPath.urlConstructor(...args)),

				endpoint: (...args: ConditionalArgs<K>) =>
					joinOnSlash(
						domain,
						endpointPath.endpointConstructor(...args)
					),
			};
		}

		return {
			buildURL: (...args: ConditionalArgs<T>) =>
				joinOnSlash(domain, replacer(endpointPath, ...args)),

			buildSubpath: (...args: ConditionalArgs<T>) =>
				joinOnSlash('/', replacer(endpointPath, ...args)),

			endpoint: () => joinOnSlash(domain, endpointPath),
		};
	};

const { API_ROOT, USE_API_SERVICE_WORKER } = environment;

const domain = USE_API_SERVICE_WORKER === 'true' ? API_ROOT : '';

const builder = makeBuilder(domain);

const authenticateUser = builder('/tokens');

const refreshToken = builder('/tokens/refresh');

const getUser = builder<GetUserParams>('users/:userId');

const getUserProfile = builder<GetUserProfileParams>(':username');

const getLanding = builder<GetLandingParams>(':username');

const getUsers = builder('users');

const reserveUser = builder('request');

const grantAccess = builder<GrantAccessParams>('/users/:userId/grant');

const updateUser = builder<UpdateUserParams>('users/:userId');

const logout = builder('/tokens');

const getOrg = builder<GetOrgParams>('/orgs/:orgId');

const getOrgs = builder<GetOrgsParams>('/users/:userId/orgs');

const createOrg = builder('/orgs');

const updateOrg = builder<UpdateOrgParams>('/orgs/:orgId');

const deleteOrg = builder<DeleteOrgParams>('/orgs/:orgId');

const getOrgGraph = builder<GetOrgGraphParams>(
	'/orgs/:orgId/graph'
);

const followOrg = builder<FollowOrgParams>('/orgs/:orgId/follow');

const unfollowOrg = builder<UnfollowOrgParams>(
	'/orgs/:orgId/unfollow'
);

const getDomains = builder<GetDomainsParams>('/orgs/:orgId/domains');

const getDomain = builder<GetDomainParams>('/domains/:domainId');

const createDomain = builder('/domains');

const profileSource = builder<ProfileSourceParams>('/sources/:sourceId/profile');

const testSource = builder<TestSourceParams>('/sources/:sourceId/test');

const updateSource = builder<UpdateSourceParams>('/sources/:sourceId');

const deleteSource = builder<DeleteSourceParams>('/sources/:sourceId');

const cacheCatalog = builder<CacheCatalogParams>('/catalogs/:catalogId/cache');

const createCatalog = builder<CreateCatalogParams>('/catalogs');

const profileCatalog = builder<ProfileCatalogParams>('/catalogs/:catalogId/profile');

const testCatalog = builder<TestCatalogParams>('/catalogs/:catalogId/test');

const dropCatalog = builder<DropCatalogParams>('/catalogs/:catalogId/drop');

const createDataset = builder<CreateDatasetParams>('/datasets');

const createDataLoad = builder<CreateDataLoadParams>('/datasets/:datasetId/load');

const updateDataset = builder<UpdateDatasetParams>('/datasets/:datasetId');

const profileDataset = builder<ProfileDatasetParams>('/datasets/:datasetId/profile');

const cacheDataset = builder<CacheDatasetParams>('/datasets/:datasetId/cache');

const testDataset = builder<TestDatasetParams>('/datasets/:datasetId/test');

const dropDataset = builder<DropDatasetParams>('/datasets/:datasetId/drop');

const testColumn = builder<TestColumnParams>('/columns/:columnId/test');

const profileColumn = builder<ProfileColumnParams>('/columns/:columnId/profile');

const updateColumn = builder<UpdateColumnParams>('/columns/:columnId');

const dropColumn = builder<DropColumnParams>('/columns/:columnId/drop');

const updateDomain = builder<UpdateDomainParams>('/domains/:domainId');

const deleteDomain = builder<DeleteDomainParams>('/domains/:domainId');

const getDomainGraph = builder<GetDomainGraphParams>(
	'/domains/:domainId/graph'
);

const getEntityGraph = builder<GetEntityGraphParams>(
	'/entities/:entityId/graph'
);

const followDomain = builder<FollowDomainParams>('/domains/:domainId/follow');

const unfollowDomain = builder<UnfollowDomainParams>(
	'/domains/:domainId/unfollow'
);

const getEntity = builder<GetEntityParams>('/entities/:entityId');

const getEntities = builder<GetEntitiesParams>('/domains/:domainId/entities');

const createEntity = builder('/entities');

const getUsersWaiting = builder<GetUsersWaitingParams>({
	urlConstructor: buildUsersWaitingQueryURL,
	endpointConstructor: buildParametrizedUsersWaitingQueryURL,
});

const followEntity = builder<FollowEntityParams>('/entities/:entityId/follow');

const unfollowEntity = builder<UnfollowEntityParams>(
	'/entities/:entityId/unfollow'
);

const getAttribute = builder<GetAttributeParams>('/attributes/:attributeId');

const getAttributes = builder<GetAttributesParams>(
	'/entities/:entityId/attributes'
);

const getSourceSummaries = builder<GetSourceSummariesParams>(
	'/orgs/:orgId/sources'
);

const getLiveDataCatalogs = builder<GetLiveDataCatalogsParams>(
	'/sources/:sourceId/catalogs'
);

const getLiveDataSets = builder<GetLiveDataSetsParams>(
	'/sources/:sourceId/catalogs/:catalogName'
);

const createSource = builder('/sources');

const getLiveDataSetPreview = builder<GetLiveDataSetPreviewParams>(
	'/sources/:sourceId/catalogs/:catalogName/datasets/:datasetName/preview'
);

const importCatalogToDomain = builder<ImportCatalogToDomainParams>(
	'/domains/:domainId/sources/:sourceId/catalogs/:catalogName/import'
);

const importDatasetToDomain = builder<ImportDatasetToDomainParams>(
	'/domains/:domainId/sources/:sourceId/catalogs/:catalogName/datasets/:datasetName/import'
);

const genDomainFromCatalog = builder<GenDomainFromCatalogParams>(
	'/sources/:sourceId/catalogs/:catalogName/domain'
);

const genDomainFromDataset = builder<GenDomainFromDatasetParams>(
	'/sources/:sourceId/catalogs/:catalogName/datasets/:datasetName/domain'
);

const getEntityAttributes = builder<GetEntityAttrsParams>(
	'/entities/:entityId/attributes'
);

const identifyEntity = builder<IdentifyEntityParams>(
	'/entities/:entityId/identify'
);

const getDomainAttributes = builder<GetDomainAttrsParams>(
	'/domains/:domainId/attributes'
);

const relateAttrAction = builder<RelateAttrActionParams>(
	'/attributes/:sourceAttrId/relate'
);

const describeAttrAction = builder<DescribeAttrActionParams>(
	'/attributes/:sourceAttrId'
);

const appendAttrAction = builder<AppendAttrActionParams>(
	'/attributes/:attrId/operation'
);

const getAttrNeighbors = builder<GetAttrNeighborsParams>(
	'/attributes/:attrId/neighbors'
);

const getIndividuals = builder<GetIndividualsParams>(
	'/entities/:entityId/individuals'
);

const getIndividual = builder<GetIndividualParams | GetIndividualsParams>('/entities/:entityId/individuals/:identifier');

const createIndividual = builder<CreateIndividualParams>('/individuals');

const updateIndividual = builder<UpdateIndividualParams>('/individuals');

const deleteIndividual = builder<DeleteIndividualParams>('/individuals');

const followIndividual = builder<FollowIndividualParams>('/individuals/:individualId/follow');

const unfollowIndividual = builder<UnfollowIndividualParams>(
	'/individuals/:individualId/unfollow'
);

// Type parameter is a union of several types that are not mutually-compatible;
// to successfully call the builder we need to narrow the expected type to properties
// that exist on all members of the union
const operateAttrAction = builder<Pick<OperateAttrActionParams, 'attrId'>>(
	'/attributes/:attrId/operation'
);

const createActivity = builder<CreateActivityActionParams>('/entities/:entityId/activities');


const restrictEntityAction = builder<
	RestrictEntityActionParams,
	{ type: 'specialization' | 'restriction' }
>({
	urlConstructor: (params) =>
		`entities/${params.entityId}/${
			params.restrictionKind === 'specialization'
				? 'specialize'
				: 'restrict'
		}`,
	endpointConstructor: ({ type }) =>
		type === 'restriction'
			? '/entities/:entityId/restrict'
			: '/entities/:entityId/specialize',
});

const loadAttribute = builder<LoadAttrActionParams>(
	'/attributes/:sourceAttrId/load'
);

const getAttributeMembers = builder<GetAttributeMembersParams>('/attributes/:attributeId/members');

const createInsight = builder<CreateInsightParams>('/insights');
const getInsights = builder<GetInsightsParams>('/insights');
const getInsight = builder<GetInsightParams>('/insights/:insightId');
const updateInsight = builder<UpdateInsightParams>('/insights/:insightId');
const deleteInsight = builder<DeleteInsightParams>('/insights/:insightId');
const followInsight = builder<FollowInsightParams>('/insights/:insightId/follow');
const unfollowInsight = builder<UnfollowInsightParams>('/insights/:insightId/unfollow');

const getUserInvites = builder<GetUserInvitesParams>({
	urlConstructor: buildInvitationsQueryURL,
	endpointConstructor: buildParametrizedInvitationsQueryURL,
});

const getTasks = builder<GetTasksParams, TaskSubject>({
	urlConstructor: buildTasksQueryURL,
	endpointConstructor: buildParametrizedTasksQueryURL,
});

const getRestrictions = builder<GetRestrictionsParams, RestrictionSubject>({
	urlConstructor: buildRestrictionsQueryURL,
	endpointConstructor: buildParametrizedRestrictionsQueryURL,
});

const getDataProfiles = builder<GetDataProfilesParams, DataProfileSubject>({
	urlConstructor: buildDataProfilesQueryURL,
	endpointConstructor: buildParametrizedDataProfilesQueryURL,
});

const getDataTests = builder<GetDataTestsParams, DataTestSubject>({
	urlConstructor: buildDataTestsQueryURL,
	endpointConstructor: buildParametrizedDataTestsQueryURL,
});

const getDataStatistics = builder<GetDataStatisticsParams, DataStatisticSubject>({
	urlConstructor: buildDataStatisticsQueryURL,
	endpointConstructor: buildParametrizedDataStatisticsQueryURL,
});

const getDataErrors = builder<GetDataErrorsParams, DataErrorSubject>({
	urlConstructor: buildDataErrorsQueryURL,
	endpointConstructor: buildParametrizedDataErrorsQueryURL,
});

const getDataLoads = builder<GetDataLoadsParams>('/orgs/:orgId/loads');

const getAttributeConditions = builder<GetAttributeConditionsParams>('/attributes/:attributeId/conditions');
const getAttributeSources = builder<GetAttributeSourcesParams>('/attributes/:attributeId/sources');

// # Relations
// # Individuals
// # Facts
// # Imports
// # Translations
// # Columns
// # Rows
// # Values
// # Types
// # Hierarchy
// # Children
// # Siblings

const moveAttribute = builder<MoveAttrActionParams>(
	'/attributes/:sourceAttrId/move'
);

const pivotEntity = builder<PivotEntityParams>(
	'/entities/:entityId/pivot'
);

const splitAttribute = builder<SplitAttrActionParams>(
	'/attributes/:sourceAttrId/split'
);

const translateValue = builder<TranslateValueActionParams>(
	'/attributes/:sourceAttrId/values'
);

const addComponent = builder<AddComponentActionParams>(
	'/attributes/:sourceAttrId/components'
);

const aggregateAttribute = builder<AggregateAttrActionParams>(
	'/attributes/:attributeId/aggregation'
);

const createAttribute = builder('/attributes');

const createMetric = builder<CreateMetricParams>('/metrics');
const getMetrics = builder<GetMetricsParams>('/metrics');
const getMetric = builder<GetMetricParams>('/metrics/:metricId');
const updateMetric = builder<UpdateMetricParams>('/metrics/:metricId');
const deleteMetric = builder<DeleteMetricParams>('/metrics/:metricId');
const followMetric = builder<FollowMetricParams>('/metrics/:metricId/follow');
const unfollowMetric = builder<UnfollowMetricParams>('/metrics/:metricId/unfollow');

const deleteAttribute = builder<DeleteAttrActionParams>('/attributes/:attrId');

const updateEntity = builder<UpdateEntityParams>('/entities/:entityId');

const describeDomain = builder<DescribeDomainParams>('/domains/:domainId');

const describeMetric = builder<DescribeMetricParams>('/metrics/:metricId');

const importEntity = builder<ImportEntityParams>(
	'/domains/:domainId/imports/:entityId'
);

const deleteEntity = builder<DeleteEntityParams, { isImport: boolean }>({
	urlConstructor: (params) =>
		isDeleteImportParams(params)
			? `/domains/${params.domainId}/imports/${params.entityId}`
			: `/entities/${params.entityId}`,
	endpointConstructor: ({ isImport }) =>
		isImport
			? '/domains/:domainId/imports/:entityId'
			: '/entities/:entityId',
});

const meltEntity = builder<MeltEntityParams>('/entities/:entityId/melt');

const persistEntity = builder<PersistEntityParams>(
	'/entities/:entityId/persist'
);

const createConditional = builder<CreateConditionalActionParams>(
	'/entities/:entityId/condition'
);

const requestPWReset = builder('/reset-password-request');

const resetPassword = builder('/reset-password');

const completeRegistration = builder('/complete-registration');

const acceptTOS = builder('/tos');

const inviteUser = builder('/invitations');

const resendInvite = builder<ResendInviteParams>('/invitations/:inviteId/resend');

const withdrawInvite = builder<WithdrawInviteParams>('/invitations/:inviteId/withdraw');

const describeEntity = builder<DescribeEntityParams>('/entities/:entityId');

const createQuestion = builder<CreateQuestionParams>('/questions');

const createReaction = builder<CreateReactionParams>('/reactions');

const getQuestions = builder<GetQuestionsParams, QuestionSubject>({
	urlConstructor: buildQuestionsQueryURL,
	endpointConstructor: buildParametrizedQuestionsQueryURL,
});

const createAnswer = builder<CreateAnswerParams>(
	'/questions/:questionId/answers'
);

const acceptAnswer = builder<AcceptAnswerParams>(
	'/questions/:questionId/accept'
);

const createComment = builder<CreateCommentParams>('/comments');

const getComments = builder<GetCommentsParams, CommentSubject>({
	urlConstructor: buildCommentsQueryURL,
	endpointConstructor: buildParametrizedCommentsQueryURL,
});

const getViewers = builder<GetViewersParams, ViewerSubject>({
	urlConstructor: buildViewersQueryURL,
	endpointConstructor: buildParametrizedViewersQueryURL,
});

const getReactions = builder<GetReactionsParams, ReactionSubject>({
	urlConstructor: buildReactionsQueryURL,
	endpointConstructor: buildParametrizedReactionsQueryURL,
});

const getActivity = builder<GetActivitiesParams, ActivitySubject>({
	urlConstructor: buildActivitiesQueryURL,
	endpointConstructor: buildParametrizedActivitiesQueryURL,
});

const getMessages = builder<GetMessagesParams, MessageSubject>({
	urlConstructor: buildMessagesQueryURL,
	endpointConstructor: buildParametrizedMessagesQueryURL,
});

const getFollowing = builder<GetFollowingParams>({
	urlConstructor: buildFollowingQueryURL,
	endpointConstructor: buildParametrizedFollowingQueryURL,
});

const getFollowers = builder<GetFollowersParams, FollowersSubject>({
	urlConstructor: buildFollowersQueryURL,
	endpointConstructor: buildParametrizedFollowersQueryURL,
});

const followResource = builder<FollowResourceParams, FollowersSubject>({
	urlConstructor: buildFollowQueryURL,
	endpointConstructor: buildParametrizedFollowQueryURL,
});

const unfollowResource = builder<UnfollowResourceParams, FollowersSubject>({
	urlConstructor: buildUnfollowQueryURL,
	endpointConstructor: buildParametrizedUnfollowQueryURL,
});

const deleteResource = builder<DeleteResourceParams, FollowersSubject>({
	urlConstructor: buildDeleteResourceQueryURL,
	endpointConstructor: buildParametrizedDeleteResourceQueryURL,
});

const getNotifications = builder<GetNotificationsParams, NotificationSubject>({
	urlConstructor: buildNotificationsQueryURL,
	endpointConstructor: buildParametrizedNotificationsQueryURL,
});

const getOrgAdmins = builder<GetOrgAdminsParams>(
	'/orgs/:orgId/admins'
);

const createOrgAdmin = builder<CreateOrgAdminParams>(
	'/orgs/:orgId/admins'
);

const getOrgGovernors = builder<GetOrgGovernorsParams>(
	'/orgs/:orgId/governors'
);

const getOrgEngineers = builder<GetOrgEngineersParams>(
	'/orgs/:orgId/engineers'
);

const createOrgEngineer = builder<CreateOrgEngineerParams>(
	'/orgs/:orgId/engineers'
);

const createOrgPublisher = builder<CreateOrgPublisherParams>(
	'/orgs/:orgId/publishers'
);

const createOrgMaintainer = builder<CreateOrgMaintainerParams>(
	'/orgs/:orgId/maintainers'
);

const createOrgFunder = builder<CreateOrgFunderParams>(
	'/orgs/:orgId/funders'
);

const createOrgReporter = builder<CreateOrgReporterParams>(
	'/orgs/:orgId/reporters'
);

const createOrgContact = builder<CreateOrgContactParams>(
	'/orgs/:orgId/contacts'
);

const createOrgRightsHolder = builder<CreateOrgRightsHolderParams>(
	'/orgs/:orgId/rights-holders'
);

const getOrgPublishers = builder<GetOrgPublishersParams>(
	'/orgs/:orgId/publishers'
);

const getOrgContacts = builder<GetOrgContactsParams>(
	'/orgs/:orgId/contacts'
);

const getOrgMaintainers = builder<GetOrgMaintainersParams>(
	'/orgs/:orgId/maintainers'
);

const getOrgFunders = builder<GetOrgFundersParams>(
	'/orgs/:orgId/funders'
);

const getOrgRightsHolders = builder<GetOrgRightsHoldersParams>(
	'/orgs/:orgId/rights-holders'
);

const getOrgReporters = builder<GetOrgReportersParams>(
	'/orgs/:orgId/reporters'
);

const createOrgGovernor = builder<CreateOrgGovernorParams>(
	'/orgs/:orgId/governors'
);

const getOrgMembers = builder<GetOrgMembersParams>(
	'/orgs/:orgId/members'
);

const createOrgMember = builder<CreateOrgMemberParams>(
	'/orgs/:orgId/members'
);

const getDomainStewards = builder<GetDomainStewardsParams>(
	'/domains/:domainId/stewards'
);

const createDomainSteward = builder<CreateDomainStewardParams>(
	'/domains/:domainId/stewards'
);

const getDomainMembers = builder<GetDomainMembersParams>(
	'/domains/:domainId/members'
);

const createDomainMember = builder<CreateDomainMemberParams>(
	'/domains/:domainId/stewards'
);

// NB: add an 's' after 'role' parameter and 'parentObjectType' parameter
const removeUserRole = builder<
	RemoveUserRoleParams,
	{ parentObjectType: 'org' | 'domain' }
>({
	urlConstructor: ({ parentObjectId, parentObjectType, role, userId }) =>
		`${parentObjectType}s/${parentObjectId}/${role}s/${userId}`,
	endpointConstructor: ({ parentObjectType }) =>
		`/${parentObjectType}s/:objectId/:role/:userId`,
});

const createLocation = builder<CreateLocationActionParams>(
	'/entities/:entityId/locations'
);

const createEvent = builder<CreateEventActionParams>(
	'/entities/:entityId/events'
);

const getAttributeLineage = builder<GetAttributeLineageParams>(
	'/attributes/:attributeId/lineage'
);

const completeOnboarding = builder('/onboarding');

const createFeedback = builder('/feedback');

const updateAccessibility = builder<UpdateAccessibilityParams>('/users/:userId/accessibility');

const updateSettings = builder<UpdateSettingsParams>('/users/:userId/settings');

const createLinkedInShare = builder('/image-share/linkedin');

const createShare = builder<CreateShareParams>('/user/:userId/shares');

const followUser = builder<FollowUserParams>('/users/:userId/follow');

const unfollowUser = builder<UnfollowUserParams>('/users/:userId/unfollow');

const pathBuilders = {
	reserveUser,
	inviteUser,
	grantAccess,
	withdrawInvite,
	resendInvite,
	getEntityGraph,
	createFeedback,
	updateAccessibility,
	updateSettings,
	completeRegistration,
	completeOnboarding,
	acceptTOS,
	authenticateUser,
	requestPWReset,
	getUser,
	getUserProfile,
	deleteResource,
	followResource,
	unfollowResource,
	getLanding,
	getUsers,
	updateUser,
	followUser,
	unfollowUser,
	logout,
	getActivity,
	createIndividual,
	getIndividual,
	updateIndividual,
	deleteIndividual,
	createActivity,
	createDomainMember,
	createDomainSteward,
	createOrgGovernor,
	createOrgMember,
	createOrgEngineer,
	createOrgPublisher,
	createOrgMaintainer,
	createOrgFunder,
	createOrgReporter,
	createOrgContact,
	createOrgRightsHolder,
	createOrgAdmin,
	getDomainStewards,
	getDomainMembers,
	getOrgGovernors,
	getOrgMembers,
	getOrgAdmins,
	getOrgEngineers,
	getOrgPublishers,
	getOrgContacts,
	getOrgMaintainers,
	getOrgFunders,
	getOrgRightsHolders,
	getOrgReporters,
	removeUserRole,
	createLinkedInShare,
	createShare,
	getQuestions,
	createQuestion,
	createAnswer,
	acceptAnswer,
	getMessages,
	getNotifications,
	getFollowing,
	getFollowers,
	getComments,
	getReactions,
	getViewers,
	createComment,
	createReaction,
	getOrg,
	getOrgs,
	getOrgGraph,
	createOrg,
	updateOrg,
	deleteOrg,
	followOrg,
	unfollowOrg,
	getDomain,
	getDomainGraph,
	createDomain,
	updateDomain,
	deleteDomain,
	followDomain,
	unfollowDomain,
	describeEntity,
	getEntity,
	getEntities,
	createEntity,
	updateEntity,
	deleteEntity,
	followEntity,
	createEvent,
	createMetric,
	getMetrics,
	getMetric,
	updateMetric,
	followMetric,
	unfollowMetric,
	deleteMetric,
	createLocation,
	unfollowEntity,
	getDomains,
	getAttribute,
	getAttributes,
	getAttributeLineage,
	createAttribute,
	deleteAttribute,
	getSourceSummaries,
	getLiveDataCatalogs,
	getLiveDataSets,
	createSource,
	profileSource,
	testSource,
	updateSource,
	deleteSource,
	cacheCatalog,
	createCatalog,
	profileCatalog,
	testCatalog,
	dropCatalog,
	createDataset,
	updateDataset,
	profileDataset,
	cacheDataset,
	testDataset,
	dropDataset,
	testColumn,
	profileColumn,
	updateColumn,
	dropColumn,
	createDataLoad,
	getLiveDataSetPreview,
	importCatalogToDomain,
	importDatasetToDomain,
	genDomainFromCatalog,
	genDomainFromDataset,
	getEntityAttributes,
	identifyEntity,
	getDomainAttributes,
	relateAttrAction,
	appendAttrAction,
	describeAttrAction,
	getAttrNeighbors,
	operateAttrAction,
	restrictEntityAction,
	loadAttribute,
	moveAttribute,
	pivotEntity,
	splitAttribute,
	translateValue,
	addComponent,
	aggregateAttribute,
	meltEntity,
	persistEntity,
	importEntity,
	createConditional,
	describeDomain,
	describeMetric,
	resetPassword,
	refreshToken,
	getIndividuals,
	getAttributeMembers,
	createInsight,
	getInsights,
	getInsight,
	updateInsight,
	deleteInsight,
	followInsight,
	unfollowInsight,
	getUserInvites,
	getUsersWaiting,
	getTasks,
	getRestrictions,
	getDataProfiles,
	getDataTests,
	getDataStatistics,
	getDataErrors,
	getDataLoads,
	getAttributeConditions,
	getAttributeSources,
};

export default pathBuilders;
