import { emptyStringToNull } from '../common/helpers';
import {
	CreateActivityFormValues,
	CreateActivityPayload,
	TemporalUnit,
} from './createActivityTypes';

export const temporalUnits: readonly TemporalUnit[] = [
	'year',
	'month',
	'week',
	'day',
	'hour',
	'minute',
	'second',
	'millisecond',
	'microsecond',
] as const;

export const deriveDurationFormDefaults = (): CreateActivityFormValues => ({
	attrId: 0,
	operationType: 'deriveDuration',
	singular: '',
	plural: '',
	otherId: 0,
	definition: '',
	temporalUnit: 'millisecond',
});

export const deriveDurationFormToPayload = (
	formValues: CreateActivityFormValues
): CreateActivityPayload => {
	const { ...restOfForm } = formValues;

	const baseValues = {
		...restOfForm,
		definition: emptyStringToNull(formValues.definition),
	};

	return baseValues;
};
