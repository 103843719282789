import React, {FunctionComponent} from "react";
import MenuButton from "../buttons/MenuButton";
import MenuPanel from "./MenuPanel";
import DestroyButton from "../buttons/DestroyButton";
import {DELETE_COMMENT,} from "../../features/graph/CONSTANTS";
import {BaseComment} from "../comments/types/commentTypes";

const commentActions = [
    {
        actionTitle: DELETE_COMMENT,
        modalType: 'destroy',
        kind: 'modal',
        requiresEdit: true,
        form: DestroyButton,
    },
]

export const CommentMenu: FunctionComponent<{ resource: BaseComment; canEdit: boolean;}> = (
    {resource, canEdit}
) => {
    return <MenuButton placement={'right-start'}>
        {resource && canEdit ? <>
                <MenuPanel
                    resource={resource}
                    canEdit={canEdit}
                    actions={commentActions}
                />
            </>
            : null}
    </MenuButton>;
};

export default CommentMenu;
