import FlexContainer from 'common/FlexContainer';
import {FormResults} from 'common/Form';
import theme from 'common/theme/theme';
import {useAuthenticateUserMutation} from 'features/api';
import useAuthStatus from 'features/authentication/hooks/useAuthStatus';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {Location} from 'react-router-dom';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import StyledCitizen from "../../../common/StyledCitizen";
import StyledLogo from "../../../common/StyledLogo";
import StyledCopyright from "../../../common/StyledCopyright";
import PasswordInput from "../../../common/inputs/PasswordInput";
import LogInButton from "../../../common/buttons/LogInButton";
import ForgotPasswordLink
    from "../../../common/buttons/ForgotPasswordLinkButton";
import RequestAccessLink from "../../../common/buttons/RequestAccessLinkButton";
import LoginNameInput from "../../../common/inputs/LoginNameInput";
import Typography from "../../../common/text/Typography";
import LogInLinkButton from "../../../common/buttons/LogInLinkButton";

interface CredentialsFormValues {
    username: string;
    password: string;
}

const StyledLanding = styled.div`
	height: 100vh; 
	width: 100vw;
    background-color: ${(p) => p.theme.palette.darkBaby};
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		overflow-y: auto;	
		overflow-x: hidden;	
	}
`

const StyledFormBody = styled.form`
	border-radius: ${(p) => p.theme.spacing(1)};
	background: ${(p) => p.theme.palette.darkerBaby};
	width: 350px;
	margin: 0;
`;

const StyledMain = styled.div`
	display: flex;
	flex-direction: column;
	place-content: center;
	place-items: center;
	min-height: 600px;
	height: 100vh;
	width: 100vw;
			gap: 6rem;
	overflow: hidden;
	padding: 0;	
	margin: 0;	
	background-color: ${(p) => p.theme.palette.darkBaby};
`;

const StyledSigninNav = styled.nav<{ useTranslucency: boolean; }>`
	background: transparent;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-between;
	flex: 1;
	justify-content: flex-end;
	align-content: flex-end;
	z-index: ${(p) => p.theme.zIndex.navPanel};
	background-color: ${(p) => p.theme.palette.darkBaby};
`;

const SigninButtons: FunctionComponent = () => {
    const {pathname} = useLocation();
    const atLanding = pathname.match('/') || pathname.match('/login');
    if (atLanding) {
        return <LogInLinkButton
            display={false}
            color={'darkBaby'}
        />
    }
    return null;
};


const CredentialsPage: FunctionComponent = () => {
    const {handleSubmit, register, resetField, formState} = useForm({
        defaultValues: {
            username: '',
            password: '',
        },
    });
    const isAuthorized = useAuthStatus();

    const location = useLocation() as {
        state?: { returnWhenAuthenticated?: Location };
        pathname: string;
    };

    const navigate = useNavigate();
    // const [dims, setDims] = useClientRect();
    // const { height } = dims;

    const [authenticate, mutationResults] = useAuthenticateUserMutation();

    const validatingRegister = useCallback(
        (inputName: keyof CredentialsFormValues) =>
            register(inputName, {
                required: `${inputName} is required`,
            }),
        [register]
    );

    // If 'returnWhenAuthenticated' is specified, it means the user arrived
    // at login form via redirect from a protected route.  If auth succeeds,
    // send them back to wherever they were trying to go before, and remove
    // login route from history to keep browser's 'back' functionality from
    // needlessly returning them to the auth page.  Otherwise, kick them back to
    // landing once authenticated.
    useEffect(() => {
        if (isAuthorized) {
            const previousLocation =
                location.state?.returnWhenAuthenticated?.pathname || null;

            const prevSearch =
                location.state?.returnWhenAuthenticated?.search || '';

            return previousLocation
                ? navigate(previousLocation + prevSearch, {replace: true})
                : navigate('/');
        }
    }, [isAuthorized, navigate, location]);

    const onSubmit: SubmitHandler<CredentialsFormValues> = (credentials) => {
        authenticate({
            kind: location.pathname.match('login')
                ? 'login'
                : 'signup',
            credentials,
        });
    };

    return (
        <StyledLanding>
            <StyledSigninNav useTranslucency={false}>
                <SigninButtons/>
            </StyledSigninNav>
            <StyledMain>
                <StyledLogo
                    width={'400px'}
                    height={'47px'}
                    src="https://storage.googleapis.com/davis-associates.appspot.com/futuremodel-teaser.svg"
                    alt="Futuremodel Inc."
                />
                <FlexContainer
                    style={{height: '315px', width: '100%'}}
                    gap={'.5rem'}
                    alignItems='center'
                    justifyContent='center'>
                    <StyledCitizen
                        width={'275px'}
                        height={'315px'}
                        src="https://storage.googleapis.com/davis-associates.appspot.com/fm-hero-figure.svg"
                        role="presentation"
                    />
                    <StyledFormBody
                        onSubmit={handleSubmit(onSubmit)}
                        spellCheck={false}
                        aria-label="log in"
                    >
                        <FormResults
                            {...mutationResults}
                            validationErrors={{}}
                        />
                        <FlexContainer
                            flexDirection="column"
                            style={{gap: '2px', padding: '16px 16px 8px',}}>
                            <LoginNameInput
                                formState={formState}
                                resetField={resetField}
                                validatingRegister={validatingRegister}
                                mutationResults={mutationResults}
                            />
                            <PasswordInput
                                formState={formState}
                                resetField={resetField}
                                validatingRegister={validatingRegister}
                                mutationResults={mutationResults}
                            />
                            <LogInButton/>
                            <ForgotPasswordLink/>
                        </FlexContainer>
                        <FlexContainer
                            justifyContent="center"
                            style={{
                                padding: '16px',
                                borderTop: `2px solid ${theme.palette.slickRick}`,
                            }}
                        >
                            <RequestAccessLink/>
                        </FlexContainer>
                    </StyledFormBody>
                </FlexContainer>
                <StyledCopyright/>
            </StyledMain>
        </StyledLanding>
    );
};

{/*<p style={{*/}
     {/*    position: 'relative',*/}
     {/*    top: '40px',*/}
     {/*    fontSize: '10px',*/}
     {/*    color: theme.palette.oldManGray,*/}
     {/*    fontWeight: '500',*/}
     {/*    lineHeight: '1rem'*/}
     {/*}}>*/}
     {/*    Data publisher? <span style={{color: theme.palette.primary.main,}}>Create a Page</span> for your <span style={{color: theme.palette.primary.main,}}>organization</span>, <span style={{color: theme.palette.primary.main,}}>business</span>, or <span style={{color: theme.palette.primary.main,}}>brand</span>*/}
     {/*</p>*/}

export default CredentialsPage;
