import { defaultGraphMargins } from '../../CONSTANTS';
import { SVGGraphProps } from '../../types';
import theme from 'common/theme/theme';
import { group as d3Group, max as d3Max, rollup as d3Rollup } from 'd3-array';
import { axisLeft, axisBottom } from 'd3-axis';
import { scaleBand, scaleLinear } from 'd3-scale';
import { selectAll, select } from 'd3-selection';
import { Ref, FunctionComponent, useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import useElementSize from "../../../hooks/useSize";
import {forkRef} from "../../../utils/reactUtils";
import {StyledSVGContainer} from "../../common/styledComponents";

const StyledRect = styled.rect<{ active: boolean }>`
	fill: ${(p) =>
		p.active
			? p.theme.palette.secondary.main
			: p.theme.palette.primary.main};
`;

interface CategoryHistogrampProps extends SVGGraphProps {
	facts: string[];
	minBarThickness?: number;
	innerPadding?: number;
	xAxis?: boolean;
	fontSize?: number;
	yAxis?: boolean;
	showPercentage?: boolean;
	showLabels?: boolean;
	onBarClick?: (value: any) => void;
	activeCategories?: string[];
}

export const BAR_CHART_BAR_TEST_ID = 'category-horizontal-bar';

const HorizontalBarChart: FunctionComponent<CategoryHistogrampProps> = ({
	// width,
	// height,
	facts,
	minBarThickness = 40,
	innerPadding = 0.05,
	xAxis = true,
	yAxis = true,
	showPercentage,
	showLabels= true,
	fontSize = 12,
	onBarClick,
	activeCategories,
	...margins
}) => {
	const { top, bottom, left, right } = { ...defaultGraphMargins, ...margins };

	const yMargin = top + bottom;

	const xMargin = left + right;

	const [svgRef, setSVGRef] = useState<SVGSVGElement | null>(null);

	const [{ width, height }, setSizeEl] = useElementSize();

	// const combinedRefs = forkRef(setSizeEl as any, setSVGRef as any);

	const { xScale, yScale, drawHeight, counts, innerHeight } = useMemo(() => {
		if (facts.length === 0) {
			return {
				xScale: null,
				yScale: null,
				bins: [],
				drawWidth: 0,
				drawHeight: 0,
				innerHeight: 0,
				counts: null,
			};
		}
		const drawWidth = !!width ? width > 0 ? width - xMargin : 0 : 0;

		const drawHeight = !!height ? height > 0 ? height - yMargin : 0 : 0;

		const counts = d3Group(facts, (d) => d);

		const defaultPadding = innerPadding * (counts.size - 1);

		const defaultHeight = counts.size * minBarThickness;

		const innerHeight = Math.max(
			drawHeight,
			defaultHeight + defaultPadding
		);

		const max = d3Max(
			d3Rollup(
				facts,
				(v) => v.length,
				(d) => d
			).values()
		) as number;

		const xScale = scaleBand()
			.range([0, innerHeight])
			.domain(
				// results in bars sorted by length, in descending order, from top to bottom.
				// i.e. biggest bar will be at top of graph, smallest at bottom.
				Array.from(counts.entries())
					.sort((a, b) => {
						const v0 = a[1].length;
						const v1 = b[1].length;

						return v1 - v0;
					})
					.map((v) => v[0])
			)
			.paddingInner(innerPadding);

		const yScale = scaleLinear()
			.range([0, drawWidth])
			//    SHENANIGANS: artificially increase the domain by 20% to leave
			// room at right side of graph for bar text labels
			.domain([0, max * 1.2]);

		return { xScale, yScale, counts, drawHeight, drawWidth, innerHeight };
	}, [minBarThickness, facts, height, innerPadding, width, xMargin, yMargin]);

	useLayoutEffect(() => {
		if (xScale && yScale) {
			const svg = select(svgRef);

			// remove pre-existing axes, if any
			if (!svg.empty()) {
				selectAll('.bar-chart-axis').remove();
			}

			if (xAxis) {
				svg.append('g')
					.attr('transform', `translate(${left}, ${top})`)
					.classed('bar-chart-axis', true)
					.call(
						axisLeft(xScale)
							.tickValues([])
							.tickSize(0)
							.tickPadding(6)
					);
			}

			if (yAxis) {
				svg.append('g')
					.attr(
						'transform',
						`translate(${left}, ${innerHeight + top})`
					)
					.classed('bar-chart-axis', true)
					.call(axisBottom(yScale).tickSize(0).tickPadding(6));
			}
		}
	}, [
		xScale,
		xAxis,
		yAxis,
		yScale,
		svgRef,
		drawHeight,
		left,
		top,
		innerHeight,
	]);

	return (

		<StyledSVGContainer style={{ overflowY: 'auto', overflowX: 'hidden'}} ref={setSizeEl}>
		{/*<div ref={setSizeEl} , width, height }}>*/}
			<svg width={width} height={innerHeight + yMargin} ref={setSVGRef}>
				{xScale &&
					yScale &&
					Array.from(counts.entries()).map(([key, values]) => {
						const yVal = values.length;
						const pctString =
							((Math.round(yVal / facts.length * 1000) / 10)).toFixed(1);

						const valueString = String(yVal);

						const barHeight = yScale(yVal);

						const valueLabelOffset = showLabels ? valueString.length * 5 + barHeight : pctString.length * 1.5 + barHeight;

						const vertDisplacement = xScale(key);

						const barWidth = xScale.bandwidth();

						const countLabelFontSize = Math.min(barWidth - fontSize, fontSize);
						const categoryLabelFontSize = Math.min(barWidth - fontSize, fontSize);

						return (
							<g
								key={`category-horizontal-bar-${key}`}
								transform={`translate(${left}, ${
									top + (vertDisplacement as number)
								})`}
								onClick={() => {
									if (onBarClick) {
										onBarClick(key);
									}
								}}
							>
								<g>
									<StyledRect
										width={barHeight}
										height={barWidth}
										data-testid={BAR_CHART_BAR_TEST_ID}
										active={
											activeCategories
												? activeCategories.includes(key)
												: false
										}
									/>
									<text
										fontSize={`${categoryLabelFontSize}px`}
										width={barWidth}
										x={5}
										y={barWidth * 0.5}
										textAnchor="left"
										fill={theme.palette.slickRick}
										alignmentBaseline="central"
										fontWeight="700"
										className={'unselectable'}
									>
										{String(key).slice(0, 150)}
									</text>
									{
									<text
										x={showLabels ? valueLabelOffset : valueLabelOffset - (.02 * barWidth)}
										y={barWidth * 0.5}
										textAnchor="right"
										fill={theme.palette.cyan}
										alignmentBaseline="central"
										fontSize={`${countLabelFontSize}px`}
										fontWeight='600'
										className={'unselectable'}
									>
										{showPercentage ? pctString : valueString}
									</text>}

								</g>
							</g>
						);
					})}
			</svg>
		{/*</div>*/}
		</StyledSVGContainer>
	);
};

export default HorizontalBarChart;
