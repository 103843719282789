import {
	User,
	BaseUser,
	CurrentUser,
	CurrentOrg,
	AuthenticatedUser,
	UNAUTHENTICATED_USER_TAG,
	AUTHENTICATED_USER_TAG,
	ORG_TAG,
	RegisterUserResponse,
} from '../types/userTypes';
import { isAuthenticatedUser } from '../types/userTypes';
import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { EMPTY } from 'common/utils/typeUtils';
import {BaseOrg, CreateOrgResponse} from "../../ontology/types/orgTypes";

// TODO: it might be smarter just to add a 'token' field
// to the user object and do it all on the same slice.
export const userRegistered =
	createAction<RegisterUserResponse>('userRegistered');

export const orgCreated =
	createAction<CreateOrgResponse>('orgCreated');

// Cast to union type User to prevent createSlice from narrowing to
// UnauthenticatedUser based on the value provided to token in initialState.
// We want the type available in Redux state to be a union.
export const initialState = {
	__tag: UNAUTHENTICATED_USER_TAG,
} as CurrentUser;

export const initialOrgState = {} as BaseOrg;

// const initialState = {
// 	_links: {
// 		url: '/api/users/52/following',
// 		followed: '/api/users/52/following',
// 		followers: '/api/users/52/followers',
// 		self: '/api/users/52',
// 		apiPath: '/api/users/52',
// 	},
// 	tosAccepted: true,
// 	isOnboarded: true,
// 	avatar: {
// 		medium: {
// 			bounding_box: {
// 				height: 400,
// 				width: 400,
// 			},
// 			url: 'https://futuremodel.local:5000/api/avatars/medium/c63a2ab7b96fced7ed2fe4d91c5bad74',
// 		},
// 		small: {
// 			bounding_box: {
// 				height: 100,
// 				width: 100,
// 			},
// 			url: 'https://futuremodel.local:5000/api/avatars/small/c63a2ab7b96fced7ed2fe4d91c5bad74',
// 		},
// 		thumb: {
// 			bounding_box: {
// 				height: 32,
// 				width: 32,
// 			},
// 			url: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
// 		},
// 		tiny: {
// 			bounding_box: {
// 				height: 16,
// 				width: 16,
// 			},
// 			url: 'https://futuremodel.local:5000/api/avatars/tiny/c63a2ab7b96fced7ed2fe4d91c5bad74',
// 		},
// 	},
// 	department: null,
// 	defaultOrg: 'davisAssociates',
// 	doNotMail: false,
// 	firstName: 'Roger',
// 	_id: 52,
// 	isInternal: true,
// 	jobTitle: null,
// 	lastName: 'Clemens',
// 	lastSeen: '2020-12-02T22:52:47Z',
// 	locale: null,
// 	location: null,
// 	memberSince: '2020-09-08T23:46:21Z',
// 	social: {
// 		github: {
// 			username: 'some username',
// 		},
// 		facebook: {
// 			username: 'davissclark',
// 		},
// 		instagram: {
// 			username: 'davissclark',
// 		},
// 		linkedin: {
// 			username: 'some username',
// 			//  We generally want this token NOT to be expired during dev,
// 			//  so set the expiration 10 hours into the future
// 			oauthTokenExp: Date.now() / 1000 + 60 * 60 * 10,
// 		},
// 	},
// 	invitesRemaining: 5,
// 	invitedBy: null,
// 	stats: {
// 		profile: {},
// 		logged: {
// 			logins: 0,
// 			logouts: 6,
// 		},
// 		connections: {
// 			followers: 0,
// 			following: 6,
// 			invitesSent: 0
// 		},
// 		modeling: {
// 			sources: 0,
// 			catalogs: 0,
// 			datasets: 0,
// 			attributes: 5688,
// 			domains: 3,
// 			entities: 7,
// 			imports: 0,
// 			metrics: 0,
// 		},
// 		content: {
// 			tasks: 0,
// 			messagesSent: 0,
// 			questions: 0,
// 			answers: 0,
// 			comments: 0,
// 			reactions: 0,
// 			shares: 0,
// 		},
// 		mentions: {},
// 		groups: {
// 			organizations: 0,
// 			domains: 0,
// 		},
// 	},
// 	_object: 'User',
// 	userContext: {
// 		excludedPermissions: [],
// 		interactions: {
// 			following: false,
// 		},
// 		iqByAction: {
// 			editMetadata: {
// 				primary: {
// 					applicable: true,
// 					base: 2,
// 					multiplier: 1,
// 				},
// 			},
// 		},
// 		permissions: {
// 			view: true,
// 			admin: true,
// 			edit: true,
// 		},
// 		relationships: {},
// 	},
// 	username: 'davisclark',
// 	emailConfirmed: true,
// } as BaseUser;


const currentUserSlice = createSlice({
	name: 'currentUser',
	initialState,
	reducers: {
		authenticate: (state, action: PayloadAction<AuthenticatedUser>) => {
			return action.payload;
		},

		logout: () => {
			return { ...initialState };
		},

		setLinkedInTokenExpiry: (state, a: PayloadAction<number>) => {
			if (isAuthenticatedUser(state)) {
				return {
					...state,
					social: {
						...state.social,
						linkedin: {
							username: state.social.linkedin.username,
							oauthTokenExp: a.payload,
						},
					},
				};
			}

			return state;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(userRegistered, (state, a) => {
			const { token, ...userProps } = a.payload;

			return { __tag: AUTHENTICATED_USER_TAG, ...userProps };
		});
	},
});


const currentOrgSlice = createSlice({
	initialState: initialOrgState,
	name: 'currentOrg',
	reducers: {
		// setLinkedInTokenExpiry: (state, a: PayloadAction<number>) => {
		// 	return {
		// 		...state,
		// 		social: {
		// 			...state.social,
		// 			linkedin: {
		// 				username: state.social.linkedin.username,
		// 				oauthTokenExp: a.payload,
		// 			},
		// 		},
		// 	};
		// },
	},
	extraReducers: (builder) => {
		builder.addCase(orgCreated, (state, a) => {
			const { ...orgProps } = a.payload;
			return {...orgProps};
		});
	}
});

// const userSlice = createSlice({
// 	name: 'user',
// 	initialState,
// 	reducers: {},
// 	// extraReducers: (builder) => {
// 	// 	builder.addCase(userRegistered, (s, a) => {
// 	// 		const { token, ...userProps } = a.payload;
// 	//
// 	// 		return { __tag: AUTHENTICATED_USER_TAG, ...userProps };
// 	// 	});
// 	// },
// });

export const { authenticate, logout, setLinkedInTokenExpiry } =
	currentUserSlice.actions;

export const selectUserAuthStatus = (s: RootState) => isAuthenticatedUser(s.currentUser);

/**Casts result to AuthenticatedUser, since no component using this should be
mounted if user is unauthenticated.  To check User auth status, use
selectUserAuthStatus instead.
*/
export const selectCurrentUser = (s: RootState) => s.currentUser as AuthenticatedUser;

export const selectUser = (s: RootState) => s.currentUser as AuthenticatedUser;
export const selectUserProfile = (s: RootState) => s.currentUser as BaseUser;

export const selectUserProfileImageURL = ({ currentUser }: RootState) => {
	const maybeURL = currentUser && currentUser.__tag === AUTHENTICATED_USER_TAG
		? currentUser.avatar?.thumb?.url : '';
	return maybeURL ?? EMPTY;
};

export const selectUserID = ({ currentUser }: RootState) =>
	isAuthenticatedUser(currentUser) ? currentUser._id : EMPTY;

export const selectOrgID = ({currentOrg}: RootState) =>
	currentOrg ? currentOrg._id : EMPTY;

export const selectDefaultOrg = ({ currentUser }: RootState) =>
	isAuthenticatedUser(currentUser) ? currentUser.defaultOrg : EMPTY;

export const selectTOSAccepted = ({ currentUser }: RootState) =>
	isAuthenticatedUser(currentUser) ? currentUser.tosAccepted : false;

export const selectTokenExpirys = ({ currentUser }: RootState) =>
	isAuthenticatedUser(currentUser)
		? { linkedin: currentUser.social.linkedin.oauthTokenExp }
		: null;

// export const userReducer = userSlice.reducer;
export const currentUserReducer = currentUserSlice.reducer;
export const currentOrgReducer = currentOrgSlice.reducer;
