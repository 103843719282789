import React, {FunctionComponent, ReactNode, useState} from "react";
import {StyledMemberDataListItem} from "../../features/governance/components/styledComponents";
import ProfileAvatar
    from "../../features/navigation/components/Navbar/ProfileAvatar";
import {List, ListItemContent, ListItemControls} from "./index";
import {
    BaseUser
} from "../../features/authentication/types/userTypes";
import IconButton from "../buttons/IconButton";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import Popover from "../popovers/Popover/PopoverBase";
import ClickAwayListener from "react-click-away-listener";

export const UserItem: FunctionComponent<{user: BaseUser, canEdit: boolean, onClick: any, handleError?: any, position?: string, controls?: any, children?: any}> = ({user, canEdit, onClick, handleError, position, controls, children}) => {

    return <StyledMemberDataListItem
        background="default"
        position={position}
        key={user._id}
        aria-labelledby={`member-${user._id}`}
    >
        <ProfileAvatar
            onClick={onClick}
            initial={user.firstName && user.lastName
                ? user.firstName[0] + user.lastName[0]
                : user.username[0]}
            profileImageURL={user.avatar?.thumb.url as string}
            isOnline={user.lastActive === 'online'}
        />
        <ListItemContent style={{
            alignItems: 'center',
            justifyContent: 'center',
            margin: '2px 0 2px 2px',
            paddingLeft: '8px'
        }}>
			{children}
        </ListItemContent>
    </StyledMemberDataListItem>
}

export default UserItem;
