import {BottomPaper, TopPaper} from '../../../common/Paper';
import useHUDIndividual from '../hooks/useHUDIndividual';
import useViewportDimensions from '../hooks/useViewportDimensions';
import { clearDrawerIndividual } from '../state/HUDSlice';
import IndividualCard from '../../../common/cards/IndividualCard';
import RightDrawer from './RightDrawer';
import { useAppDispatch } from 'app/hooks';
import { isNonNullable } from 'common/utils/typeGuards';
import { FunctionComponent } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import styled from 'styled-components';

import {filter, flow} from "../../../common/utils/functionUtils";
import {isIdentity, isPrimaryIdentity} from "../../ontology/typeGuards/attributeGuards";
import {sortGroupedAttrs, groupAttrs, getSelectedSubtypes, displayableAttrSubtypes,
} from 'features/ontology/helpers/attributeHelpers';
import {BaseAttribute, AttributeBaseType,} from 'features/ontology/types/attributeTypes';
import GridContainer from "../../../common/GridContainer";

export const groupAttrsForIndividualCard = flow(
	filter((a) => !isIdentity(a)),
	groupAttrs('base'),
	getSelectedSubtypes(displayableAttrSubtypes),
	sortGroupedAttrs
	// map(renderSection)
);

const StyledProfileItem = styled(GridContainer)`
	padding: ${(p) => p.theme.spacing(1)};
	align-items: start;
	justify-content: space-between;
`;

const StyledPlaceholder = styled(StyledProfileItem)`
	margin: auto auto 0 auto;
	padding: auto auto 0 auto;
`;

const StyledSectionContainer = styled.section`
	padding-left: ${(p) => p.theme.spacing(2)};
`;

interface AttrTypeSection {
	type: AttributeBaseType;
	attrs: BaseAttribute[];
}

interface PopupBaseProps {
	// datum: Record<string, any>;
	attributes?: BaseAttribute[];
}

//
// const StyledIndividualContainer = styled(BasePaper)<{
// 	viewportHeight: number;
// }>`
// 	overflow: scroll;
// 	height: ${(p) => `${p.viewportHeight - p.theme.navHeight * 2 - p.theme.panelGutter * 2}px`};
// 	top: ${(p) => `${p.theme.navHeight + p.theme.panelGutter / 2}px`};
// 	right: 0;
// `

const StyledIndividualTop = styled(TopPaper)<{
	viewportHeight: number;
}>`
	height: ${(p) =>
		`${
			p.viewportHeight - p.theme.navHeight * 2 - p.theme.panelGutter * 2
		}px`};
	overflow-y: scroll;
	overflow-x: hidden;
`;

const StyledIndividualBottom = styled(BottomPaper)<{
	viewportHeight: number;
}>`
	height: ${(p) =>
		`${
			p.viewportHeight - p.theme.navHeight * 2 - p.theme.panelGutter * 2
		}px`};
	overflow-y: scroll;
	overflow-x: hidden;
`;

const IndividualDrawer: FunctionComponent = () => {
	const hudIndividual = useHUDIndividual();
	const { viewportHeight } = useViewportDimensions();
	const appDispatch = useAppDispatch();

	return (
		<RightDrawer props={{drawerOpen: !!hudIndividual?.datum}}>
			{isNonNullable(hudIndividual) ? (
				<ClickAwayListener
					onClickAway={() => {
						if (hudIndividual) {
							appDispatch(clearDrawerIndividual());
						}
					}}>
					<StyledIndividualTop translucent={false} viewportHeight={viewportHeight}>
						{/*	<StyledIndividualBottom viewportHeight={viewportHeight}>*/}
							<IndividualCard {...hudIndividual} />
						{/*</StyledIndividualBottom>*/}
					</StyledIndividualTop>
				</ClickAwayListener>
			) : null}
		</RightDrawer>
	);
};

export default IndividualDrawer;
