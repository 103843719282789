import { ActionDescriptor } from '../common/commonTypes';
import AppendAttributeForm from './AppendAttributeForm';

const appendAttribute: ActionDescriptor = {
	form: AppendAttributeForm,
	actionTitle: 'Append',
	requiresEdit: true,
	kind: 'modal',
	divider: false,
	modalType: 'appendAttribute'
};

export default appendAttribute;
