import useAuthStatus from '../../../authentication/hooks/useAuthStatus';
import { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import StyledSquare from "../../../../common/StyledSquare";
import useViewModeParams from "../../hooks/useViewModeParams";
import useActiveLandingData from "../../../ontology/hooks/useActiveLandingData";
import theme from "../../../../common/theme/theme";

const LogoLink = styled(Link)`
	display: inline-flex;
`;

	// max-width: ${(p) => p.theme.drawerWidth - 4}px;
	// padding: ${(p) => p.theme.panelGutter}px;

const StyledLogo = styled.img`
	width: auto;
	height: 32px;
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		display: none;
	}
`;

interface StyledSquareProps {
	width?: string;
	height?: string;
}

export const StyledMiniLogo = styled.div`
	display: none;
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		width: 32px;
		height: 32px;
		display: initial	;
		background-color: ${(p) => p.theme.palette.primary.main};
	}
`;


const NavLogo: FunctionComponent = () => {
	const { pathname } = useLocation();

	const { activeItem: activeResource } = useActiveLandingData();
	const isAuthenticated = useAuthStatus();
	const {getViewMode} = useViewModeParams();
	const mode = getViewMode();

	// If we're on the teaser/reserve user page, don't display link/logo
	if ((pathname === '/' ||
		pathname === '/login' ||
		pathname === '/request-access' ||
		pathname === '/reset-password-request' ||
		pathname === '/reset-password'
	) && !isAuthenticated) {
		return null;
	} else if (
		((
			pathname === '/reset-password' ||
			pathname === '/complete-registration'
		) && !isAuthenticated) || (
			pathname === '/terms-of-service' && isAuthenticated
		) || (mode === 'feed' || (mode === 'profile' && activeResource._object === 'User'))
	) {
		return (
		<LogoLink to="/">
			<StyledLogo
				src="https://storage.googleapis.com/davis-associates.appspot.com/futuremodel-teaser.svg"
				alt="Futuremodel Inc."
			/>
			<StyledMiniLogo />
		</LogoLink>
	);
	}

	return (
		<LogoLink to="/">
			<StyledSquare />
		</LogoLink>
	);
};

export default NavLogo;
