import Button from '../../../../common/buttons/Button';
import { List, ListItem, ListItemContent } from '../../../../common/List';
import Popover from '../../../../common/popovers/Popover/PopoverBase';
import ReactRouterLink from '../../../../common/RouterLink';
import { getPluralKind, getPluralLabel } from '../../helpers';
import { DataContextLevels } from '../../types/navigationContextTypes';
import {faChevronDown, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import Spinner from 'common/loading/Spinner';
import Typography from 'common/text/Typography';
import { sortByString } from 'common/utils/functionUtils';
import { FunctionComponent, useMemo, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import styled from 'styled-components';
import IconButton from "../../../../common/buttons/IconButton";

const DataCrumbChevron = styled.div`
	margin-right: 2px;
	border-radius: .25rem;
	position: relative;

	&:hover {
		left: -1px;
		margin-right: 0px;
		border: 1px solid ${(p) => p.theme.palette.darkBaby};
		color: ${(p) => p.theme.palette.darkBaby};
	}
`

const DataCrumbButton = styled(Button)`
	background: transparent;
	color: ${(p) => p.theme.palette.darkBaby};
	margin: 0;
	border-radius: .25rem;
	padding: .25rem;
	text-transform: capitalize;
	border: 1px solid transparent;

	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	
	
	&:hover {
		border: 1px solid ${(p) => p.theme.palette.darkBaby};
	}
`;
// color: ${(p) => p.theme.palette.darkBaby};

export interface DataCrumbLink {
	url: string;
	name: string;
	label: string;
	kind: DataContextLevels;
}

interface DataCrumbBaseProps {
	kind: DataContextLevels;
	links: DataCrumbLink[];
	status: 'loading' | 'success' | 'error';
	errorMessage?: string | null;
	isTip: boolean;
	activeName: string | null;
}

const DataCrumbBase: FunctionComponent<DataCrumbBaseProps> = ({
	kind,
	links,
	isTip,
	status,
	activeName,
	errorMessage,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const [initial, setInitial] = useState(true);
	const [open, setOpen] = useState(false);

	const sortedLinks = useMemo(
		() => sortByString(links, (l) => l.name),
		[links]
	);

	const handleClick = () => {
		if (initial) {
			setInitial(false);
		}
		return setOpen((p) => !p);
	};

	const onClickAway = () => {
		if (open) {
			return setOpen(false);
		}

		return null;
	};

	const activeLink = links.find((l) => l.name === activeName);

	const getTipBtnText = () => {
		switch (status) {
			case 'loading':
				return 'Loading...';

			case 'error':
				return 'Error';

			case 'success':
				return typeof activeName === 'string'
					? <Typography color='oldManGray' variant='h5'>
						{activeLink?.name}
					</Typography>
					: <Typography color='oldManGray' variant='h5'>
						{activeLink?.name !== undefined
								? activeLink?.name
								: getPluralLabel(kind)}
					</Typography>;
		}
	};

	const renderButton = () => {
		if (isTip) {
			return (
				<ClickAwayListener onClickAway={onClickAway}>
						<DataCrumbButton
							variant='text'
							onClick={handleClick}
							ref={setAnchorEl as any}
						>
							<Typography color='oldManGray' variant='h5'>
								{getTipBtnText()}
							</Typography>
						</DataCrumbButton>
				</ClickAwayListener>
			);
		}

		return (
			<>
				<ReactRouterLink
					to={activeLink?.name !== undefined
						? activeLink?.kind === 'username'
							? `../data/${activeLink?.name}`
							: `/${activeLink?.url}`
						: '/'}
					preserveSearchParams={false}
				>
					<DataCrumbButton variant='text'>
						<Typography color='oldManGray' variant='h5'>
							{activeLink?.name !== undefined
								? activeLink?.name
								: getPluralLabel(kind)}
						</Typography>
					</DataCrumbButton>
				</ReactRouterLink>
				<ClickAwayListener onClickAway={onClickAway}>
					<DataCrumbChevron data-testid="datacrumb-chevron">
						<IconButton
							size="xs"
							iconSize="lg"
							onClick={handleClick}
							ref={setAnchorEl as any}
							usingLabel={true}
							variant={'transparent'}
							overrides={{icon: {verticalAlign: 0}}}
							style={initial
								? {transition: 'transform 0.5s'}
								: open ? {transform: 'rotate(90deg)', transition: 'transform 0.3s'}
								: {transform: 'rotate(0deg)', transition: 'transform 0.3s'}}
							icon={faChevronRight}
							fillColor='cyan'
						>
							<Typography color='oldManGray' variant='h5'>
								{getTipBtnText()}
							</Typography>
						</IconButton>
					</DataCrumbChevron>
				</ClickAwayListener>
			</>
		);
	};

	const renderDropdown = () => {
		switch (status) {
			case 'loading':
				return <Spinner data-testid="datacrumb-dropdown-loading" />;
			case 'error':
				return (
					<Typography paragraph color="error">
						{errorMessage || 'Something went wrong.'}
					</Typography>
				);
			case 'success':
				return (
					<List style={{ maxHeight: '50vh', overflowY: 'auto' }}>
						{sortedLinks.map(({ url, label, name, kind }, index) => (
							<ReactRouterLink
								to={name !== undefined
										? kind === 'username'
											? `../data/${name}`
											:  `/${url}`
										: '/'}
								key={url + String(index)}
								preserveSearchParams={false}
							>
								<ListItem
									onClick={() => setAnchorEl(null)}
									style={{ cursor: 'pointer' }}
								>
									<ListItemContent>
										<Typography variant='caption2'>
											{name}
										</Typography>
									</ListItemContent>
								</ListItem>
							</ReactRouterLink>
						))}
					</List>
				);
		}
	};

	return (
		<>
			{renderButton()}
			<Popover anchorEl={anchorEl} open={open} placement="bottom">
				{renderDropdown()}
			</Popover>
		</>
	);
};

//	{
// 									(activeLink?.name !== undefined
// 										? activeLink?.kind === 'username'
// 										? `.` :  `${activeLink?.name}` : '/') + '/data'
// 								}

export default DataCrumbBase;
