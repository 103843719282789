import React, {FunctionComponent} from "react";
import MenuButton from "../buttons/MenuButton";
import MenuPanel from "./MenuPanel";
import {
    BOOKMARK_RESOURCE,
    COPY_SHARE_URL,
    CREATE_ACTIVITY,
    CREATE_ATTRIBUTE,
    CREATE_CONDITION,
    CREATE_EVENT,
    CREATE_LOCATION,
    CREATE_RESTRICTION,
    CREATE_SPECIALIZATION,
    DESCRIBE_ENTITY,
    FOLLOW_RESOURCE,
    MANAGE_MEMBERS,
    VIEW_ENTITY,
    SHARE_RESOURCE,
    SHOW_CREDITS,
    VIEW_DOMAIN,
    VIEW_ORG,
    CREATE_METRIC,
    CREATE_INDIVIDUAL,
    PERSIST_ENTITY,
    ALTERNATE_OF,
    SPECIALIZATION_OF,
    SAME_AS,
    GENERALIZATION_OF,
    ENTITY_AUDIENCE,
    SHOW_COMMENTS,
    SHOW_QUESTIONS,
    RENAME_RESOURCE,
    REFRESH_QUERY,
    COPY_EMBED_CODE,
    COPY_FUTUREMODEL_URI
} from "../../features/graph/CONSTANTS";
import {BaseEntity} from "../../features/ontology/types/entityTypes";
import UpdateEntityModal
    from "../../features/actions/updateEntity/UpdateEntityModal";


const entityActions = [
    {
        actionTitle: VIEW_ENTITY,
        kind: 'link',
        requiresEdit: false,
        location: '/:org/:domain/:entity'
    },
    {
        actionTitle: VIEW_DOMAIN,
        kind: 'link',
        requiresEdit: false,
        location: '/:org/:domain'
    },
    {
        actionTitle: VIEW_ORG,
        kind: 'link',
        requiresEdit: false,
        location: '/:org'
    },
    {
        actionTitle: SHOW_CREDITS,
        modalType: 'entityCredits',
        kind: 'modal',
        requiresEdit: false,
        divider: false
    },
    {
        actionTitle: SHOW_COMMENTS,
        modalType: 'entityComments',
        kind: 'modal',
        requiresEdit: false,
        divider: true
    },
        {
        actionTitle: SHOW_QUESTIONS,
        modalType: 'entityQuestions',
        kind: 'modal',
        requiresEdit: false,
        divider: true
    },
    {
        actionTitle: FOLLOW_RESOURCE,
        requiresEdit: false,
        kind: 'action',
        callback: 'follow',
	},
    {
        actionTitle: BOOKMARK_RESOURCE,
        kind: 'action',
        requiresEdit: false,
        divider: true,
    },
    {
        actionTitle: ENTITY_AUDIENCE,
        modalType: 'entityAudience',
        kind: 'modal',
        requiresEdit: true,
    },
    {
        actionTitle: DESCRIBE_ENTITY,
        modalType: 'entitySettings',
        kind: 'modal',
        requiresEdit: true,
    },
    {
        actionTitle: RENAME_RESOURCE,
        modalType: 'renameEntity',
        kind: 'modal',
        requiresEdit: true,
    },
        {
        actionTitle: REFRESH_QUERY,
        modalType: 'refreshQuery',
        kind: 'modal',
        requiresEdit: true,
    },
    {
        actionTitle: 'Create',
        kind: 'subMenu',
        requiresEdit: true,
        divider: true,
        items: [
            {
                actionTitle: CREATE_ATTRIBUTE,
                divider: false,
                modalType: 'createAttribute',
                requiresEdit: true,
                kind: 'modal',
            },
            {
                actionTitle: CREATE_EVENT,
                divider: false,
                modalType: 'createEvent',
                requiresEdit: true,
                kind: 'modal'
            },
            {
                actionTitle: CREATE_ACTIVITY,
                divider: false,
                modalType: 'createActivity',
                requiresEdit: true,
                kind: 'modal'
            },
            {
                actionTitle: CREATE_LOCATION,
                divider: false,
                modalType: 'createLocation',
                requiresEdit: true,
                kind: 'modal',
            },
            {
            	actionTitle: CREATE_CONDITION,
            	modalType: 'createCondition',
            	divider: true,
                requiresEdit: true,
                kind: 'modal'
            },
            {
            	actionTitle: CREATE_METRIC,
            	modalType: 'createMetric',
            	divider: true,
                requiresEdit: true,
                kind: 'modal'
            },
            {
            	actionTitle: CREATE_INDIVIDUAL,
            	modalType: 'createIndividual',
            	divider: true,
                requiresEdit: true,
                kind: 'modal'
            },
            {
                actionTitle: CREATE_SPECIALIZATION,
                kind: 'modal',
                divider: false,
                modalType: 'specializeEntity',
                requiresEdit: true,
                restrictionKind: 'specialization'
            },
            {
                actionTitle: CREATE_RESTRICTION,
                modalType: 'restrictEntity',
                kind: 'modal',
                requiresEdit: true,
                restrictionKind: 'restriction',
            },
        ]
    },
    {
        actionTitle: 'Relate',
        kind: 'subMenu',
        requiresEdit: true,
        divider: true,
        items: [
            {
                actionTitle: SAME_AS,
                divider: false,
                modalType: 'sameAs',
                requiresEdit: true,
                kind: 'link',
            },
            {
                actionTitle: ALTERNATE_OF,
                divider: false,
                modalType: 'alternateOf',
                requiresEdit: true,
                kind: 'link',
            },
                      {
                actionTitle: SPECIALIZATION_OF,
                divider: false,
                modalType: 'specializationOf',
                requiresEdit: true,
                kind: 'link',
            },
                             {
                actionTitle: GENERALIZATION_OF,
                divider: false,
                modalType: 'generalizationOf',
                requiresEdit: true,
                kind: 'link',
            },
        ],
    },
    {
        actionTitle: 'Persistence',
        kind: 'subMenu',
        requiresEdit: true,
        divider: true,
        items: [
            {
                actionTitle: PERSIST_ENTITY,
                divider: false,
                modalType: 'persistEntity',
                requiresEdit: true,
                kind: 'modal',
            },
        ]
    },
    {
        actionTitle: 'Profiling',
        kind: 'subMenu',
        requiresEdit: true,
        divider: true,
        items: [
            {
                actionTitle: PERSIST_ENTITY,
                divider: false,
                modalType: 'persistEntity',
                requiresEdit: true,
                kind: 'modal',
            },
        ]
    },
    {
        actionTitle: 'Transfer',
        kind: 'subMenu',
        requiresEdit: true,
        divider: true,
        items: [
            {
                actionTitle: PERSIST_ENTITY,
                divider: false,
                modalType: 'transferEntity',
                requiresEdit: true,
                kind: 'modal',
            },
        ]
    },
    {
        actionTitle: 'Share',
        requiresEdit: false,
        kind: 'subMenu',
        items: [
            {
                actionTitle: 'LinkedIn',
                kind: 'modal',
                requiresEdit: false,
                modalType: 'shareResource'
            },
                        {
                actionTitle: 'Futuremodel Code',
                kind: 'modal',
                requiresEdit: false,
                modalType: 'shareResource'
            },
            {
                actionTitle: SHARE_RESOURCE,
                kind: 'modal',
                requiresEdit: false,
                modalType: 'shareResource'
            },
            {
                actionTitle: COPY_SHARE_URL,
                requiresEdit: false,
                subMenu: 'share',
                kind: 'action',
                callback: 'copyLink',
                divider: false,
            },
            {
                actionTitle: COPY_EMBED_CODE,
                requiresEdit: false,
                subMenu: 'share',
                kind: 'action',
                callback: 'copyEmbed',
                divider: false,
            },
            {
                actionTitle: COPY_FUTUREMODEL_URI,
                requiresEdit: false,
                subMenu: 'share',
                kind: 'action',
                callback: 'copyUri',
                divider: false,
            },
        ]
    },
]

// {
//     actionTitle: MANAGE_MEMBERS,
//     modalType: 'membership',
//     divider: true,
//     form: AddMemberButton,
// },

// const deleteAction = {
//     actionTitle: DESTROY_ENTITY,
//     divider: false,
//     requiresEdit: true,
//     modalType: 'deleteEntity',
//     form: DestroyButton,
// };
//
// const removeAction = {
//     actionTitle: "Remove Import",
//     divider: false,
//     requiresEdit: true,
//     modalType: 'deleteEntity',
//     form: DestroyButton,
// };

export const EntityMenu: FunctionComponent<{ resource: BaseEntity; canEdit: boolean; }> = (
    {resource, canEdit}
) => {

    // let actions;
    //
    // if (resource.isImport) {
    //     actions = [...entityActions, removeAction];
    // } else {
    //     actions = [...entityActions, deleteAction];
    // }

    return <MenuButton placement={'right-start'}>
        <MenuPanel
            resource={resource}
            canEdit={canEdit}
            actions={entityActions}
        />
    </MenuButton>;
};

export default EntityMenu;
