import React, {FunctionComponent} from "react";
import {
    ActionFieldProps,
    EntityActionFormProps
} from "../../features/actions/common/commonTypes";
import SubtleSelect from "./SubtleSelect";
import {formFieldHasErrors, genErrorIdFromLabel} from "../Form";
import {getRestrictionOperators} from "../../features/actions/common/helpers";
import {harvestErrors} from "./SubtleTextBox";
import {AttributeBaseType} from "../../features/ontology/types/attributeTypes";


interface ComparisonTypeSelectParams extends ActionFieldProps {
    attributeType: AttributeBaseType;
    i: number;
    compTypeName: string;
}

//class IndividualAssertions(StrEnum):
//     """
//     OWL 2 supports a rich set of axioms for stating assertions — axioms about
//     individuals that are often also called facts.
//     """
//     IsA = 'IsA'
//     # states that an individual is an instance of a particular class
//     SameIndividual = 'SameIndividual'
//     # states that several individuals are all equal to each other
//     DifferentIndividual = 'DifferentIndividual'
//     AllDifferent = 'AllDifferent'
//     # states that several individuals are all different from each other.
//     # (More precisely, that the several different individuals in the syntax
//     # are also semantically different.)
//     HasRelation = 'HasRelation'
//     # states that an individual is related by a relation to an individual
//     DoesNotHaveRelation = 'DoesNotHaveRelation'
//     # states that an individual is not related by a relation to an individual
//     HasValue = 'HasValue'
//     # states that an individual is connected by a data attribute to a literal
//     DoesNotHaveValue = 'NegativeDataPropertyAssertion'
//     # states that individual is not connected by a data attribute to a literal.

const ComparisonTypeSelect: FunctionComponent<ComparisonTypeSelectParams> = (
    {
        attributeType,
        formState,
        resetField,
        validatingRegister,
        compTypeName,
        i
    }
) => {

    const opts = getRestrictionOperators(attributeType);
    return <SubtleSelect
        inline={true}
        id={compTypeName}
        label='Select operation'
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField(`conditions.${i}.comparisonType`)}
        isDirty={Boolean(formState.dirtyFields.conditions)}
        {...validatingRegister(`conditions.${i}.comparisonType`)}

    >
        <option value="none">Select comparison</option>
        {opts.map(
            ({operator, displayValue}) => (
                <option value={operator} key={operator}>
                    {displayValue}
                </option>
            ))}
    </SubtleSelect>;
}

// <SubtleSelect
//     label=""
//     // defaultValue={'Select attribute'}
//     onReset={() => resetField('conditions')}
//     // onReset={() => resetField(`conditions.${i}.subjectId`)}
//     isDirty={Boolean(formState.dirtyFields.conditions)}
//     // /!*{...validatingRegister(`conditions.${i}.subjectId`, {*!/
//     {...register(
//         `conditions.${i}.comparisonType`,
//         {
//             required:
//                 'A comparison operator must be selected',
//             validate: (v) =>
//                 v === 'none'
//                     ? 'A comparison operator must be selected'
//                     : true,
//
//     )}
//     id={compTypeName}
//     aria-errormessage={genErrorIdFromLabel(
//         compTypeName
//     )}
//     aria-invalid={formFieldHasErrors(
//         'conditions',
//         formState
//     )}
// >

export default ComparisonTypeSelect;
