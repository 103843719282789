import { StyledVizPaper, StyledSubmoduleHeader } from '../styledComponents';
import Typography from 'common/text/Typography';
import AttributeLineageGraph from 'common/modals/LineageGraph';
import { FunctionComponent } from 'react';

const LineageModule: FunctionComponent = () => {
	return (
		<StyledVizPaper>
			<StyledSubmoduleHeader>
				<Typography>Lineage</Typography>
			</StyledSubmoduleHeader>
			<AttributeLineageGraph />
		</StyledVizPaper>
	);
};

export default LineageModule;
