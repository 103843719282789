import Heading from '../../../common/text/Heading';
import React, {FunctionComponent, ReactNode} from 'react';
import styled from 'styled-components';
import Typography from "../../../common/text/Typography";
import RouterLink from "../../../common/RouterLink";
import {BaseUser} from '../../authentication/types/userTypes';
import ProfileAvatar from "../../navigation/components/Navbar/ProfileAvatar";
import FlexContainer from "../../../common/FlexContainer";
import LinkedInButton from "../../../common/buttons/LinkedInButton";
import FacebookButton from "../../../common/buttons/FacebookButton";
import InstagramButton from "../../../common/buttons/InstagramButton";
import GithubButton from "../../../common/buttons/GithubButton";
import FollowersButton from "../../../common/buttons/FollowersButton";


const StyledHeaderSection = styled.div`
	display: grid;
	grid-template:
	 [row1-start] "avatar header" 1fr [row1-end] 
	 [row2-start] ". social" auto [row2-end] 
	 / auto 1fr;
	justify-items: start;
	place-content: start start;
	align-items: center;
	gap: 2rem;
	width: 100%;
	height: 100%;
	text-align: left;
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		grid-template:
		 [row1-start] "avatar" 1fr [row1-end] 
		 [row2-start] "header" 1fr [row2-end] 
		 [row3-start] "social" auto [row3-end] 
		 / 1fr;
		gap: 1rem;
		place-items: center center;
		place-content: center center;
		text-align: center;
	}
`;

//    [row6-start] "location" 1fr [row6-end]
const StyledLabelSection = styled.div`
	display: grid;
	grid-template:
    [row1-start] "overline" auto [row1-end]
    [row2-start] "title" 1fr [row2-end]
    [row3-start] "url" 1fr [row3-end]
    / 1fr;
	justify-content: start;
	align-content: start;
	justify-items: start;
	align-items: start;
	gap: .5rem;
		
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		gap: .25rem;
		place-items: center;
		place-content: start;
		text-align: center;
	}
`;

const OptimizedContainer = styled(FlexContainer)`

    padding: 0;
    margin: 0;
    justify-content: start;
    justify-items: start;
    justify-self: start;
    align-self: start;
    align-items: center;
    align-content: center;
    width: 100%;
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		justify-items: center;
		justify-content: center;
		align-content: center;
		align-items: center;
		text-align: center;
	}
`

const StyledOtherSection = styled.div`
	display: grid;
	grid-template:
    [row1-start] "overline" auto [row1-end]
    [row2-start] "title" 1fr [row2-end]
    [row3-start] "url" auto [row3-end]
    [row4-start] "empty" 1rem [row4-end]
    [row5-start] "social" 1fr [row5-end]
    [row6-start] "location" 1fr [row6-end]
    / 1fr;
    
	justify-items: start;
	justify-content: stretch;
	align-content: start;
	align-items: start;
`;

const StyledGraphicContainer = styled.div`
	position: relative;
	height:100%;
	padding-right: 100%;
	width: 0;
	float: left;
`
const StyledGraphicSquare = styled.div`
	background: ${(p) => p.theme.palette.primary.main};
	width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
`;

const StyledGraphicCircle = styled(StyledGraphicSquare)`
	background: ${(p) => p.theme.palette.primary.main};
	width: 6rem;
    height: 6rem;
    border-radius: 50%;
    position: absolute;
    left: 0;
`;

const StyledSection = styled.div`
	width: 100%;
	height: 100%;
`;

// const StyledHeaderSection = styled.div`
// 	display: grid;
// 	grid-template:
// 	 [row1-start] "avatar header" 6rem [row1-end]
// 	 / 6rem 1fr;
// 	justify-items: start;
// 	justify-content: stretch;
// 	align-content: center;
// 	align-items: center;
// 	margin-top: 2rem;
// 	margin-left: 2rem;
// 	gap: 2rem;
// 	grid-area: header;
// `;
//
// const StyledLabelSection = styled.div`
// 	display: grid;
// 	grid-template:
//     [row1-start] "overline" auto [row1-end]
//     [row2-start] "title" 2fr [row2-end]
//     [row4-start] "location" 1fr [row4-end]
//     [row4-start] "empty" auto [row4-end]
//     [row6-start] "social" 1fr [row6-end]
//     / 1fr;
// 	justify-items: start;
// 	justify-content: stretch;
// 	align-content: start;
// 	align-items: start;
// 	min-width: 275px;
// `;
//
// const StyledGraphicContainer = styled.div`
// 	position: relative;
// 	height:100%;
// 	padding-right: 100%;
// 	width: 0;
// 	float: left;
// `
// const StyledGraphicSquare = styled.div`
// 	background: ${(p) => p.theme.palette.primary.main};
// 	width: 100%;
//     height: 100%;
//     position: absolute;
//     left: 0;
// `;

interface ProfileHeaderProps {
    headingText: string;
    subheadText?: string;
    avatarUrl?: string | null;
    subheadUrl?: string | null;
    webUrl?: string | null;
    location?: string | null;
    includeAvatar?: boolean;
    isOnline?: boolean;
    user: BaseUser;
    children?: ReactNode;
    // canEdit?: boolean;
    // setIsSettingsOpen?: () => void;
}

const ProfileOverline: FunctionComponent<{ text: string; url?: string; }> = (
    {
        text,
        url
    }
) => {
    return !!text && !!url
        ? <RouterLink to={url} preserveSearchParams={false}>
            <Typography style={{gridArea: 'overline',}} variant="subtitle">
                {text}
            </Typography>
        </RouterLink>
        : <Typography style={{gridArea: 'overline',}} variant="subtitle">
            {text}
        </Typography>
}

const SocialHeader: FunctionComponent<{ resource: any; }> = ({resource}) => {
    return <OptimizedContainer
        style={{gridArea: 'social', marginTop: '-3rem'}}
        gap='1rem'>
        {resource.social.linkedin.username ?
            <LinkedInButton resource={resource}/> : null}
        {resource.social.facebook.username ?
            <FacebookButton resource={resource}/> : null}
        {resource.social.instagram.username ?
            <InstagramButton resource={resource}/> : null}
        {resource.social.github.username ?
            <GithubButton resource={resource}/> : null}
    </OptimizedContainer>
}

const ProfileHeader: FunctionComponent<ProfileHeaderProps> = (
    {
        children,
        headingText,
        subheadText,
        avatarUrl,
        subheadUrl,
        includeAvatar = true,
        webUrl,
        location,
        isOnline,
        user
        // canEdit
        // setIsSettingsOpen
    }
) => {

    return (
        <StyledHeaderSection>
            {avatarUrl ? <ProfileAvatar
                initial={!!user ? user.firstName : ''}
                profileImageURL={avatarUrl}
                isProfile={true}
                onClick={() => {}}
                isOnline={isOnline}
                styles={{gridArea: 'avatar'}}
                height={'12rem'}
                width={'12rem'}
            /> : includeAvatar
                ? <StyledGraphicContainer style={{gridArea: 'avatar', height: '12rem', width: '12rem'}}>
                    <StyledGraphicCircle style={{height: '12rem', width: '12rem'}}/>
                </StyledGraphicContainer>
                : null
            }
            <StyledLabelSection style={{gridArea: 'header',}}>
                <ProfileOverline text={subheadText ?? ''} url={subheadUrl ?? ''}/>
                <Heading style={{gridArea: 'title'}} component="h1">
                    {headingText}
                </Heading>
                <FollowersButton resource={user}/>
                {/*{location && (*/}
                {/*    <Typography style={{gridArea: 'url'}} color={'oldManGray'} variant='subtitle'>*/}
                {/*        {location}*/}
                {/*    </Typography>*/}
                {/*)}*/}
            </StyledLabelSection>
            <SocialHeader resource={user}/>
        </StyledHeaderSection>
    );
};

export default ProfileHeader;
