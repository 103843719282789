import React, {FunctionComponent} from "react";
import {BaseOrg} from "../../features/ontology/types/orgTypes";
import MenuButton from "../buttons/MenuButton";
import MenuPanel from "./MenuPanel";
import SubMenuButton from "../buttons/SubMenuButton";
import TextButton from "../buttons/TextButton";
import {
    COPY_SHARE_URL,
    DESCRIBE_ORG,
    DESTROY_ORG,
    FOLLOW_RESOURCE,
    MANAGE_MEMBERS,
    SHARE_RESOURCE,
    SHOW_CREDITS
} from "../../features/graph/CONSTANTS";
import ShareResourceModal from "../modals/ShareResourceModal";

const orgActions = [
    {
        actionTitle: DESCRIBE_ORG,
        modalType: 'orgSettings',
        kind: 'modal',
        requiresEdit: true,
        divider: true,
    },
    {
        actionTitle: SHOW_CREDITS,
        modalType: 'orgCredits',
        kind: 'modal',
        requiresEdit: false,
        divider: true
    },
    {
        actionTitle: FOLLOW_RESOURCE,
        requiresEdit: false,
        kind: 'action',
        callback: 'follow',
	},
    {
        actionTitle: 'Share',
        requiresEdit: false,
        kind: 'subMenu',
        items: [
            {
                actionTitle: SHARE_RESOURCE,
                kind: 'modal',
                requiresEdit: false,
                modalType: 'shareResource'
            },
            {
                actionTitle: COPY_SHARE_URL,
                requiresEdit: false,
                subMenu: 'share',
                kind: 'action',
                callback: 'copyLink',
                divider: false,
            },
        ]
    },
    {
        actionTitle: MANAGE_MEMBERS,
        kind: 'modal',
        modalType: 'membership',
        requiresEdit: true,
        divider: false,
    }
]

export const ShareButton: FunctionComponent = () => {
    return <SubMenuButton label={'Share'}>
        <TextButton
            color='primary'
            fillColor='transparent'
            text={'Copy Object Link'}
        />
    </SubMenuButton>
}

export const OrgMenu: FunctionComponent<{ org: BaseOrg; canEdit: boolean; }> = (
    {org, canEdit}
) => {
    return <MenuButton placement={'right-start'}>
        {org ? <>
                <MenuPanel
                    resource={org}
                    canEdit={canEdit}
                    actions={orgActions}
                />
            </>
            : null}
    </MenuButton>;
};

export default OrgMenu;
