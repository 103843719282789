import { faShare, faX } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'common/buttons/IconButton';
import { List, ListItem, ListItemContent } from 'common/List';
import { PopoverBase } from 'common/popovers/Popover';
import Typography from 'common/text/Typography';
import { FunctionComponent, useState } from 'react';
import ShareForm from "../../features/actions/shareResource/ShareResourceForm";
import FlexContainer from "../FlexContainer";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";

interface ShareButtonProps {
	elementId: string;
}

const LinkedInShareButton: FunctionComponent<ShareButtonProps> = ({ elementId }) => {
	const [anchor, setAnchor] = useState<HTMLElement | null>(null);
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const [mode, setMode] = useState<'menu' | 'form'>('menu');

	const handleClose = () => {
		setMode('menu');
		setMenuOpen(false);
	};

	const renderContent = () =>
		mode === 'menu' ? (
			<List>
				<ListItem button onClick={() => setMode('form')}>
					<ListItemContent>
						<Typography>Linked In</Typography>
					</ListItemContent>
				</ListItem>
			</List>
		) : (
			<ShareForm elementId={elementId} handleClose={handleClose} />
		);

	return (
		<>
			<IconButton
				icon={faShare}
				tooltip="share"
				size="xs"
				ref={setAnchor}
				onClick={() => setMenuOpen(true)}
				showTooltip={!menuOpen}
			/>
			<PopoverBase anchorEl={anchor} open={menuOpen}>
				{renderContent()}
			</PopoverBase>
		</>
	);
};


interface ShareButtonProps {
	elementId: string;
}

const LinkedInShareButton2: FunctionComponent<ShareButtonProps> = ({ elementId }) => {
	const [anchor, setAnchor] = useState<HTMLElement | null>(null);
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const [mode, setMode] = useState<'menu' | 'form'>('menu');

	const handleClose = () => {
		setMode('menu');
		setMenuOpen(false);
	};

	const renderContent = () =>
		mode === 'menu' ? null
		// mode === 'menu' ? (
		// 	<List>
		// 		<ListItem button onClick={() => setMode('form')}>
		// 			<ListItemContent>
		// 				<IconButton
		// 					icon={faLinkedin as any}
		// 					tooltip="LinkedIn"
		// 					size="xs"
		// 					// ref={setAnchor}
		// 					onClick={() => setMenuOpen(true)}
		// 					showTooltip={!menuOpen}
		// 				/>
		// 				<IconButton
		// 					icon={faFacebook as any}
		// 					tooltip="Facebook"
		// 					size="xs"
		// 					// ref={setAnchor}
		// 					onClick={() => setMenuOpen(true)}
		// 					showTooltip={!menuOpen}
		// 				/>
		// 				<IconButton
		// 					icon={faInstagram as any}
		// 					tooltip="Instagram"
		// 					size="xs"
		// 					// ref={setAnchor}
		// 					onClick={() => setMenuOpen(true)}
		// 					showTooltip={!menuOpen}
		// 				/>
		// 			</ListItemContent>
		// 		</ListItem>
		// 	</List>
		// ) : (
		: (
			<ShareForm elementId={elementId} handleClose={handleClose} />
		);

	return (
		<FlexContainer
			alignContent="center"
			alignItems="center"
			justifyContent="space-between"
			justifyItems="space-between"
			// style={{width: '100%'}}
		>
			<ListItem button onClick={() => setMode('form')}>
				<IconButton
					icon={faLinkedin as any}
					tooltip="share"
					size="xs"
					ref={setAnchor}
					onClick={() => {
						if (menuOpen) {
							setMenuOpen(false);
							setMode('menu');
						} else {
							setMenuOpen(true);
							setMode('form');
						}
					}}
					showTooltip={!menuOpen}
				/>
				<PopoverBase
					anchorEl={anchor}
					open={menuOpen}
					offset={0}
					placement={'left-end'}>
					{renderContent()}
				</PopoverBase>
			</ListItem>
		</FlexContainer>
	);
};

export default LinkedInShareButton;
