import {FormResults} from '../../../common/Form';
import {useDescribeAttrActionMutation} from '../../api';
import {AttrActionFormProps} from '../common/commonTypes';
import {renderDerivationSubfields} from '../common/jsxHelpers';
import {
    describeAttrFormDefaults,
    describeAttrFormToPayload,
} from './describeAttributeHelpers';
import {DescribeAttrFormValues} from './describeAttributeTypes';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import DefaultValueInput from "../../../common/inputs/DefaultValueInput";
import IsRequiredInput from "../../../common/inputs/IsRequiredInput";
import IsDisplayInput from "../../../common/inputs/IsDisplayInput";
import AttributeTypeSelect from "../../../common/inputs/AttributeTypeSelect";
import AttributeSubtypeSelect
    from "../../../common/inputs/AttributeSubtypeSelect";
import useModalType from "../../HUD/hooks/useModalType";
import Heading from "../../../common/text/Heading";
import SubtleTextArea from "../../../common/inputs/SubtleTextArea";
import {harvestErrors} from "../../../common/inputs/SubtleTextBox";
import {PrependAttrFormValues} from "../prependAttribute/prependAttributeTypes";


const identitySubtypeOptions = [
    {displayValue: 'None', value: ''},
    {displayValue: 'Surrogate Key', value: 'surrogate'},
    {displayValue: 'Natural Key', value: 'natural'},
];

const relationSubtypeOptions = [
    {
        displayValue: "Related to",
        value: "relationOf",
    },
    {
        displayValue: "Same as",
        value: "sameAs",
    },
    {
        displayValue: "Opposite of",
        value: "oppositeOf",
    },
    {
        displayValue: "Alternate of",
        value: "alternateOf",
    },
    {
        displayValue: "Specialization of",
        value: "specializationOf",
    },
    {
        displayValue: "Equivalent to",
        value: "equivalentTo",
    },
    {
        displayValue: "Part of",
        value: "partOf",
    },
    {
        displayValue: "Component of",
        value: "componentOf",
    },
    {
        displayValue: "Portion of",
        value: "portionOf",
    },
    {
        displayValue: "Feature of",
        value: "featureOf",
    },
    {
        displayValue: "Member of",
        value: "memberOf",
    },
    {
        displayValue: "Attached to",
        value: "attachedTo",
    },
    {
        displayValue: "Possessed by",
        value: "possessedBy",
    },
    {
        displayValue: "Influenced by",
        value: "influencedBy",
    }
];

// const categorySubtypeOptions = [
//     {displayValue: 'None', value: ''},
//     {displayValue: 'Surrogate Key', value: 'surrogate'},
//     {displayValue: 'Natural Key', value: 'natural'},
// ];

// Quantity
//Quantity Definition: Total number of items purchased. For example, if users
// purchase 2 frisbees and 5 tennis balls, this will be 7.

const quantitySubtypeOptions = [
    {displayValue: 'None', value: ''},
    {displayValue: 'Distance', value: 'distance'},
    {displayValue: 'Duration', value: 'duration'},
    {displayValue: 'Currency', value: 'currency'},
    {displayValue: 'Ratio', value: 'ratio'},
    {displayValue: 'Rate', value: 'rate'},
    {displayValue: 'Rank', value: 'rank'},
    {displayValue: 'Total', value: 'total'},
];

const locationSubtypeOptions = [
    {displayValue: 'Geography', value: 'geography'},
    {displayValue: 'None', value: ''},
];

const mediaSubtypeOptions = [
    {displayValue: 'None', value: ''},
    {displayValue: 'Document', value: 'document'},
    {displayValue: 'Image', value: 'image'},
    {displayValue: 'Video', value: 'video'},
    {displayValue: 'URL', value: 'url'},
];


const DescribeAttributeForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
    const {objectId: _id, resource, canEdit} = modalProps;

    const formDefaults = describeAttrFormDefaults(resource);

    const {handleSubmit, register, formState, watch, resetField} =
        useForm<DescribeAttrFormValues>({
            defaultValues: formDefaults,
        });

    // watch type field to determine what to display in
    // subtype menu
    const watchedAttrType = watch('type', formDefaults.type);

    // when type changes we reset the subtype to make sure we don't get a mismatch between
    // type and subtype values.
    useEffect(() => {
        resetField('otherType');
    }, [watchedAttrType, resetField]);

    const validatingRegister = useCallback(
        (inputName: keyof DescribeAttrFormValues, options?: any) =>
            register(inputName, options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const [describeAttr, mutationResults] = useDescribeAttrActionMutation();

    const onSubmit: SubmitHandler<DescribeAttrFormValues> = (vals, e) => {
        e?.preventDefault();
        const payload = describeAttrFormToPayload(vals);
        console.log(payload)
        describeAttr({
            sourceAttrId: _id,
            body: payload,
        });
    };

    // only present option to choose a subtype if main type is appropriate
    const renderSubtypeSelect = useCallback(() => {
        const shouldRender =
            watchedAttrType === 'quantity'
            || watchedAttrType === 'identity'
            || watchedAttrType === 'relation'
            || watchedAttrType === 'media'
            || watchedAttrType === 'location';

        if (!shouldRender) {
            return null;
        }

        const opts =
            watchedAttrType === 'quantity'
                ? quantitySubtypeOptions
                : watchedAttrType === 'identity'
                ? identitySubtypeOptions
                : watchedAttrType === 'relation'
                    ? relationSubtypeOptions
                    : watchedAttrType === 'location'
                        ? locationSubtypeOptions
                        : mediaSubtypeOptions;

        return <AttributeSubtypeSelect
            inline={false}
            opts={opts}
            resetField={resetField}
            formState={formState}
            validatingRegister={validatingRegister}
            mutationResults={mutationResults}
        />

    }, [watchedAttrType, register]);

    return (
        <AppModal
            label={"Describe Attribute"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit}>
            <Heading style={{padding: 0, margin: 0}} component="h3">
                Intro
            </Heading>
            {renderDerivationSubfields({
                isDerivation: true,
                formState,
                resetField,
                validatingRegister,
                mutationResults
            })}
            <AttributeTypeSelect
                resetField={resetField}
                inline={false}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            {renderSubtypeSelect()}
            <Heading style={{padding: 0, margin: 0}} component="h3">
                Bio
            </Heading>
            <SubtleTextArea
                {...validatingRegister('definition', {})}
                label="Bio"
                defaultValue={'Describe the attribute...'}
                isDirty={Boolean(formState.dirtyFields.definition)}
                onReset={() => resetField('definition')}
                // aria-errormessage={genErrorIdFromLabel('definition')}
                // aria-invalid={formFieldHasErrors('definition', formState)}
                error={harvestErrors(
                    'definition',
                    formState,
                    mutationResults
                )}
            />
            <DefaultValueInput
                inline={false}
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            <IsRequiredInput
                defaultValue={resource.isRequired}
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            <IsDisplayInput
                defaultValue={resource.isRequired}
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            <FormResults
                onSuccess={closeModal}
                {...mutationResults}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default DescribeAttributeForm;
