import { isAppError, isNonEmptyString } from 'common/utils/typeGuards';
import {
	SCATTERPLOT_Y,
	ATTRIBUTE,
	EVENT_LINE_Y,
	SCATTERPLOT_CATEGORY,
	EVENT_LINE_CAT,
	HISTOGRAM_CAT,
} from 'features/compositeViews/EntityViews/CONSTANTS';
import {
	createIdentityFilter,
	createEventRangeFilter,
	catRangeKey,
	createCatRangeFilter,
	createQtyRangeFilter,
	eventRangeKey,
	parseCatRangeFilter,
	parseParam,
	parseQtyRangeFilter,
	parserMap,
	qtyRangeKey,
	identityKey,
	parseIdentityFilter,
} from 'features/compositeViews/EntityViews/helpers';
import {
	CatRangeFilter,
	IdentityFilter,
	QtyRangeFilter,
} from 'features/compositeViews/EntityViews/types';
import { ViewMode } from 'features/compositeViews/types';
import useDispatchableErr from 'features/errorHandling/hooks/useDispatchableErr';
import { VIEW_MODE_SEARCH_PARAM } from 'features/navigation/CONSTANTS';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

// NB: identity of these helper functions can trigger expensive calculations--important to memoize all of them
// TODO: if memoization logic gets complicated, it might be better to turn all these memoized
// functions into pure functions and let the caller pass in searchParams and setter function from hook
// at call site.
const useEntitySearchParams = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const dispatchErr = useDispatchableErr();

	return useMemo(() => {
		const getViewMode = () =>
			searchParams.get(VIEW_MODE_SEARCH_PARAM) as ViewMode | null;

		// Histogram category
		// Special behavior here: limit active categories to two,
		// since there's no way (for now) to represent more than that on a 2-axis grid.
		const appendHistogramCat = (attrName: string) => {
			const current = searchParams.getAll(HISTOGRAM_CAT);

			if (current.length < 2) {
				searchParams.append(HISTOGRAM_CAT, attrName);
				return setSearchParams(searchParams);
			}

			searchParams.delete(HISTOGRAM_CAT);

			[(current[0], attrName)].forEach((n) =>
				searchParams.append(HISTOGRAM_CAT, n)
			);

			return setSearchParams(searchParams);
		};

		const setHistogramCat = (attrName: string) => {
			searchParams.set(HISTOGRAM_CAT, attrName);
			setSearchParams(searchParams);
		};

		const removeHistogramCat = (toRemove: string) => {
			const remainingVals = searchParams
				.getAll(HISTOGRAM_CAT)
				.filter((v) => v !== toRemove);

			searchParams.delete(HISTOGRAM_CAT);

			remainingVals.forEach((v) => searchParams.append(HISTOGRAM_CAT, v));

			setSearchParams(searchParams);
		};

		const getAllHistogramCat = () => searchParams.getAll(HISTOGRAM_CAT);
		// Event line-category
		// Special behavior here: limit active categories to two,
		// since there's no way (for now) to represent more than that on a 2-axis grid.
		const appendEventLineCat = (attrName: string) => {
			const current = searchParams.getAll(EVENT_LINE_CAT);

			if (current.length < 2) {
				searchParams.append(EVENT_LINE_CAT, attrName);
				return setSearchParams(searchParams);
			}

			searchParams.delete(EVENT_LINE_CAT);

			[(current[0], attrName)].forEach((n) =>
				searchParams.append(EVENT_LINE_CAT, n)
			);

			return setSearchParams(searchParams);
		};

		const setEventLineCat = (attrName: string) => {
			searchParams.set(EVENT_LINE_CAT, attrName);
			setSearchParams(searchParams);
		};

		const removeEventLineCat = (toRemove: string) => {
			const remainingVals = searchParams
				.getAll(EVENT_LINE_CAT)
				.filter((v) => v !== toRemove);

			searchParams.delete(EVENT_LINE_CAT);

			remainingVals.forEach((v) =>
				searchParams.append(EVENT_LINE_CAT, v)
			);

			setSearchParams(searchParams);
		};

		const getAllEventLineCat = () => searchParams.getAll(EVENT_LINE_CAT);

		// Scatterplot-category
		// Special behavior here: limit active categories to two,
		// since there's no way (for now) to represent more than that on a 2-axis grid.
		const appendScatterplotCat = (attrName: string) => {
			const current = searchParams.getAll(SCATTERPLOT_CATEGORY);

			if (current.length < 2) {
				searchParams.append(SCATTERPLOT_CATEGORY, attrName);
				return setSearchParams(searchParams);
			}

			searchParams.delete(SCATTERPLOT_CATEGORY);

			[(current[0], attrName)].forEach((n) =>
				searchParams.append(SCATTERPLOT_CATEGORY, n)
			);

			return setSearchParams(searchParams);
		};

		const setScatterplotCat = (attrName: string) => {
			searchParams.set(SCATTERPLOT_CATEGORY, attrName);
			setSearchParams(searchParams);
		};

		const removeScatterplotCat = (toRemove: string) => {
			const remainingVals = searchParams
				.getAll(SCATTERPLOT_CATEGORY)
				.filter((v) => v !== toRemove);

			searchParams.delete(SCATTERPLOT_CATEGORY);

			remainingVals.forEach((v) =>
				searchParams.append(SCATTERPLOT_CATEGORY, v)
			);

			setSearchParams(searchParams);
		};

		const getAllScatterplotCat = () =>
			searchParams.getAll(SCATTERPLOT_CATEGORY);

		// Scatterplot-y
		const appendScatterplotY = (attrName: string) => {
			searchParams.append(SCATTERPLOT_Y, attrName);
			setSearchParams(searchParams);
		};

		const setScatterplotY = (attrName: string) => {
			searchParams.set(SCATTERPLOT_Y, attrName);
			setSearchParams(searchParams);
		};

		const removeScatterplotY = (toRemove: string) => {
			const remainingVals = searchParams
				.getAll(SCATTERPLOT_Y)
				.filter((v) => v !== toRemove);

			searchParams.delete(SCATTERPLOT_Y);

			remainingVals.forEach((v) => searchParams.append(SCATTERPLOT_Y, v));

			setSearchParams(searchParams);
		};

		const getAllScatterplotY = () => searchParams.getAll(SCATTERPLOT_Y);

		// Event line chart
		const appendLineChartY = (attrName: string) => {
			searchParams.append(EVENT_LINE_Y, attrName);
			setSearchParams(searchParams);
		};

		const removeLineChartY = (toRemove: string) => {
			const remainingVals = searchParams
				.getAll(EVENT_LINE_Y)
				.filter((v) => v !== toRemove);

			searchParams.delete(EVENT_LINE_Y);

			remainingVals.forEach((v) => searchParams.append(EVENT_LINE_Y, v));

			setSearchParams(searchParams);
		};

		const getAllLineChartY = () => searchParams.getAll(EVENT_LINE_Y);

		// general
		const setActiveAttribute = (
			attrName: string,
			clearChartParams: boolean = false
		) => {
			searchParams.set(ATTRIBUTE, attrName);

			if (clearChartParams) {
				searchParams.delete(SCATTERPLOT_Y);
			}

			setSearchParams(searchParams);
		};

		const getActiveAttributeName = () => searchParams.get(ATTRIBUTE);

		// filters
		const setIdentityFilter = (
			attrName: string,
			includedIdentities: string[]
		) => {
			const filter = createIdentityFilter(attrName)(includedIdentities);

			if (isAppError(filter)) {
				dispatchErr(filter);
				return null;
			}

			searchParams.set(filter.searchParamKey, filter.searchParamValue);

			setSearchParams(searchParams);
		};

		const getIdentityFilter = (attributeName: string) => {
			const searchParamKey = identityKey(attributeName);

			const filterValue = searchParams.get(searchParamKey);

			if (!filterValue) {
				return null;
			}

			const filter = parseIdentityFilter([searchParamKey, filterValue]);

			if (isAppError(filter)) {
				dispatchErr(filter);
				return null;
			}

			return filter;
		};

		const setEventRangeFilter = (
			attributeName: string,
			minMax: [number, number]
		) => {
			const filter = createEventRangeFilter(attributeName)(minMax);

			//   if creation fails for some reason, dispatch to central err handler and return.
			if (isAppError(filter)) {
				dispatchErr(filter);
				return null;
			}

			searchParams.set(filter.searchParamKey, filter.searchParamValue);

			setSearchParams(searchParams);
		};

		const setQtyRangeFilter = (
			attributeName: string,
			minMax: [number, number]
		) => {
			const filter = createQtyRangeFilter(attributeName)(minMax);

			//   if creation fails for some reason, dispatch to central err handler and return.
			if (isAppError(filter)) {
				dispatchErr(filter);
				return null;
			}

			searchParams.set(filter.searchParamKey, filter.searchParamValue);

			setSearchParams(searchParams);
		};

		const setCatRangeFilter = (
			attributeName: string,
			includedValues: string[]
		) => {
			const filter = createCatRangeFilter(attributeName)(includedValues);

			//   if creation fails for some reason, dispatch to central err handler and return.
			if (isAppError(filter)) {
				dispatchErr(filter);
				return null;
			}

			searchParams.set(filter.searchParamKey, filter.searchParamValue);

			setSearchParams(searchParams);
		};

		const getCatRangeFilter = (attributeName: string) => {
			const searchParamKey = catRangeKey(attributeName);

			const filterValue = searchParams.get(searchParamKey);

			if (!filterValue) {
				return null;
			}

			const filter = parseCatRangeFilter([searchParamKey, filterValue]);

			if (isAppError(filter)) {
				dispatchErr(filter);
				return null;
			}

			return filter;
		};

		const getQtyRangeFilter = (attributeName: string) => {
			const searchParamKey = qtyRangeKey(attributeName);

			const filterValue = searchParams.get(searchParamKey);

			if (!filterValue) {
				return null;
			}

			const filter = parseQtyRangeFilter([searchParamKey, filterValue]);

			if (isAppError(filter)) {
				dispatchErr(filter);
				return null;
			}

			return filter;
		};

		const clearAttributeFilters = (
			attributeName: string | null | undefined
		) => {
			if (attributeName === null || attributeName === undefined) {
				return;
			}

			const catFilterName = catRangeKey(attributeName);
			const qtyFilterName = qtyRangeKey(attributeName);
			const eventFilterName = eventRangeKey(attributeName);
			searchParams.delete(catFilterName);
			searchParams.delete(qtyFilterName);
			searchParams.delete(eventFilterName);
			setSearchParams(searchParams);
		};

		const clearAllAttributeFilters = () => {
			const filtered = Array.from(searchParams.entries()).filter((kv) => {
				const [key] = kv;

				const maybeFilterType = key.split(':')[1];

				if (isNonEmptyString(maybeFilterType)) {
					return false;
				}

				return true;
			});

			setSearchParams(filtered);
		};

		const attributeHasFilters = (
			attributeName: string | null | undefined
		) => {
			if (attributeName === null || attributeName === undefined) {
				return false;
			}

			const catFilterName = catRangeKey(attributeName);
			const qtyFilterName = qtyRangeKey(attributeName);
			const eventFilterName = eventRangeKey(attributeName);

			return (
				!!searchParams.get(catFilterName) ||
				!!searchParams.get(qtyFilterName) ||
				!!searchParams.get(eventFilterName)
			);
		};

		const getAllFilters = () =>
			Array.from(searchParams.entries())
				.map((param) => {
					const { filterType } = parseParam(param);

					return parserMap[filterType]
						? parserMap[filterType](param)
						: null;
				})
				.filter(
					(maybeFilter) => !!maybeFilter && !isAppError(maybeFilter)
				) as Array<CatRangeFilter | QtyRangeFilter | IdentityFilter>;

		return {
			appendHistogramCat,
			setHistogramCat,
			removeHistogramCat,
			getAllHistogramCat,
			appendEventLineCat,
			setEventLineCat,
			removeEventLineCat,
			getAllEventLineCat,
			appendScatterplotCat,
			setScatterplotCat,
			removeScatterplotCat,
			getAllScatterplotCat,
			getIdentityFilter,
			setEventRangeFilter,
			clearAllAttributeFilters,
			appendLineChartY,
			removeLineChartY,
			attributeHasFilters,
			clearAttributeFilters,
			getAllFilters,
			getQtyRangeFilter,
			getCatRangeFilter,
			setCatRangeFilter,
			setQtyRangeFilter,
			getAllScatterplotY,
			getActiveAttributeName,
			appendScatterplotY,
			removeScatterplotY,
			setActiveAttribute,
			getAllLineChartY,
			setScatterplotY,
			setIdentityFilter,
			getViewMode,
		};
	}, [searchParams, setSearchParams, dispatchErr]);
};

export default useEntitySearchParams;
