import { hasOwnProperty } from '../../../common/utils/typeUtils';
import {
	LiveDataSet,
	DataSourceSummary,
	LiveDataCatalog,
	DataSourceSummaries,
	LiveDataCatalogs,
	LiveDataSets, DataSet,
} from './dataTypes';


const dataViewFlags = [
	'hasSources',
	'hasCatalogs',
	'hasDatasets',
] as const;

export type DataViewFlag = typeof dataViewFlags[number];

export type DataViewFlags = { [K in DataViewFlag]?: boolean };

export type DataViewMode =
	| 'form'
	| 'data'
	| 'quality'
	| 'lineage'
	| 'usage'
	| 'relations';


export interface DataViewMap {
	flagName: DataViewFlag;
	correspondingViewMode: DataViewMode;
}

/**
 * Union of every type the SourceBrowser can have set as its 'active'
 * source data
 */
export type SelectedSourceData =
	| DataSourceSummaries
	| DataSourceSummary
	| LiveDataCatalog
	| LiveDataSet;

/**
 * Union of every type for which the SourceBrowser renders a line item
 * in the browser panel (left-hand side)
 */
export type SourceNavItemData =
	| DataSourceSummary
	| LiveDataCatalog
	| LiveDataSet;

/**
 * Union of the array types from which we render file browser sub-items
 */
export type SourceNavItemList =
	| DataSourceSummaries
	| LiveDataCatalogs
	| LiveDataSets;

export type DataSetter = (data: LiveDataSets) => void;

export type SourceSetter = (data: SelectedSourceData) => void;

// We put these typeguards with UI types b/c the UI depends on them,
// even though they pertain to dataTypes
export const isSourceSummaries = (
	u: SelectedSourceData
): u is DataSourceSummaries =>
	Array.isArray(u) && u.length > 0 && isSourceSummary(u[0]);

export const isSourceSummary = (
	u: SelectedSourceData
): u is DataSourceSummary => !Array.isArray(u) && u._object === 'DataSource';

export const isLiveDataCatalog = (
	u: SelectedSourceData
): u is LiveDataCatalog => !Array.isArray(u) && u._object === 'DataCatalog';

export const isLiveDataCatalogs = (
	u: SourceNavItemList
): u is LiveDataCatalogs =>
	Array.isArray(u) && u.length > 0 && u[0]._object === 'DataCatalog';

export const isLiveDataSet = (u: {}): u is LiveDataSet =>
	!Array.isArray(u) &&
	hasOwnProperty(u, '_object') &&
	u._object === 'DataSet';

export const isLiveDataSets = (u: SourceNavItemList): u is LiveDataSets =>
	Array.isArray(u) && u.length > 0 && u[0]._object === 'DataSet';

export interface SourceListItemProps {
	onDisplay: SourceNavItemData;
	kind?: string;
	setter: SourceSetter;
	currentlySelected: SelectedSourceData;
}
