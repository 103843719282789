import { isEmpty } from '../../../common/utils/typeGuards';
import { useGetUserQuery } from '../../api';
import useAuthToken from '../hooks/useAuthToken';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import jwtDecode from 'jwt-decode';
import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

const AuthManager: FunctionComponent = () => {
	const maybeToken = useAuthToken();
	const tokenPresent = !isEmpty(maybeToken);

	//  TODO: the below checks were originally put in place to prevent an
	// unnecessary query for user profile data after a new user successfully completes
	// registration. Because the registration response contains profile data and a token,
	// we can use that to populate Redux store and skip the query below.  However, these
	// checks ALSO prevent getUser from re-running when its cache is invalidated, b/c
	// isLoadable will evaluate to false. B/c registration is a one-time event for every user,
	// and updating profile info may occur often, we prefer one unneeded query for now to avoid
	// manually updating the user profile cache.

	// const userDataUnloaded = !useAuthStatus();
	// const tosUnaccepted = !useTOSAccepted();
	// const isLoadable = tokenPresent && userDataUnloaded;
	// const isPendingTOSAcceptance = tokenPresent && tosUnaccepted;
	// const queryShouldRun = isLoadable || isPendingTOSAcceptance;

	useGetUserQuery(
		tokenPresent
			? { userId: jwtDecode<{ id: number }>(maybeToken).id }
			: skipToken
	);

	return <Outlet />;
};

export default AuthManager;
