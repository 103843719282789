import { ListItemContent, List } from '../../../../common/List';
import Typography from '../../../../common/text/Typography';
import { useGetLiveDataCatalogsQuery } from '../../../api';
import { renderDropdown } from '../../helpers';
import useSourceListItem from '../../hooks/useSourceListItem';
import { DataSourceSummary } from '../../types/dataTypes';
import {
	SourceListItemProps,
	SourceSetter
} from '../../types/uiTypes';
import { SourceListItem } from '../styledComponents';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import clsx from 'clsx';
import { FunctionComponent, useEffect } from 'react';

interface SourceSummaryListItemProps extends SourceListItemProps {
	onDisplay: DataSourceSummary;
	index: number;
}

const SourceSummaryListItem: FunctionComponent<SourceSummaryListItemProps> = ({
	onDisplay,
	currentlySelected,
	setter,
	// cardSetter,
	index
}) => {
	const { queryShouldRun, open, active, onClick, itemName } =
		useSourceListItem({
			onDisplay, kind: 'source', currentlySelected, setter
		});

	const queryResults = useGetLiveDataCatalogsQuery(
		queryShouldRun ? { sourceId: onDisplay._id } : skipToken
	);

	// pass list back up to the card display component
	// useEffect(() => {
	// 	const { isSuccess, data } = queryResults;
	// 	if (isSuccess && data && active) {
	// 		setter(data);
	// 	}
	// }, [queryResults, setter, active]);

	return (
		<List style={{
			marginTop: index === 0 ? '.5rem' : '0rem',
			marginLeft: '.5rem'
		}} onClick={onClick}>
			<SourceListItem button className={clsx(active )}>
				<ListItemContent>
					<Typography variant='subtitle'>
						{itemName}
					</Typography>
				</ListItemContent>
			</SourceListItem>
			{open &&
				renderDropdown({
					...queryResults,
					setter,
					// cardSetter,
					currentlySelected,
					sourceId: onDisplay._id,
				})}
		</List>
	);
};

export default SourceSummaryListItem;
