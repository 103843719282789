import { ActionDescriptor } from '../common/commonTypes';
import DivideAttributeForm from './DivideAttributeForm';
import {faDivide} from "@fortawesome/free-solid-svg-icons";

const divideAttribute: ActionDescriptor = {
	form: DivideAttributeForm,
	actionTitle: 'Divide',
	actionIcon: faDivide,
	requiresEdit: true,
	kind: 'modal',
	divider: false,
	modalType: 'divideAttribute'
};

export default divideAttribute;
