import {BasePaper} from 'common/Paper';
import styled from 'styled-components';

export const StyledVizPaper = styled(BasePaper)`
	display: flex;
	flex-direction: column;
	margin: 0;
	passing: 0;
	flex: 1 1 40%;
	height: 100%;
	width: 100%;
	align-self: flex-start;
	overflow: hidden;
`;

export const StyledSubmoduleHeader = styled.div`
	padding: ${(p) => p.theme.spacing(1)};
	display: flex;
	flex-direction: row;
	align-self: flex-end;
	justify-content: space-between;
	gap: 1rem;
`;

export const StyledSubmoduleFooter = styled.div`
	padding: ${(p) => p.theme.spacing(1)};
	border-top: 1px solid ${(p) => p.theme.palette.divider};
`;

export const StyledSubmoduleContent = styled.div`
	flex-grow: 1;
	overflow: hidden;
	background-color: ${(p) => p.theme.palette.background.paper};
`;

// IMPORTANT: If overflow is NOT hidden on these flex containers,
// an infinite loop is created by ResizeObserver callback.
// TODO: understand this better, and maybe submit at bug report somewhere(?)
export const StyledVizWithControls = styled.div<{settingsOpen?: boolean;}>`
	flex-grow: 1;
	display: grid;
	grid-template-columns: ${(p) => p.settingsOpen ? "4fr 1fr" : "4fr"};
	overflow: hidden;
`;

export const StyledChartGrid = styled.div<{ rows: number; cols: number }>`
	overflow: hidden;
	display: grid;
	grid-template-columns: repeat(${(p) => p.cols}, 1fr);
	grid-template-rows: repeat(${(p) => p.rows}, minmax(0, 1fr));
	width: 100%;
	height: 100%;
	flex-grow: 1;
	grid-gap: ${(p) => p.theme.spacing(1)};
	padding: ${(p) => p.theme.spacing(0.5)};
`;


export const StyledAttributeList = styled.div`
	border-left: 1px solid ${(p) => p.theme.palette.divider};
	border-top: 1px solid ${(p) => p.theme.palette.divider};
`;

export const StyledPoint = styled.circle<{ hoverScaleFactor: number }>`
	&:hover {
		cursor: pointer;
		transform: scale(
			${(p) => `${p.hoverScaleFactor}, ${p.hoverScaleFactor}`}
		);
		/* this keeps the scale from applying to circle's cx/cy */
		transform-box: fill-box;
		transform-origin: center;
	}
`;

export const StyledVizContainer = styled.div<{ fullscreen: boolean }>`
	display: grid;
	grid-template-columns: ${(p) => (p.fullscreen ? '1fr' : 'repeat(2, 1fr)')};
	grid-template-rows: ${(p) => (p.fullscreen ? '1fr' : 'repeat(2, 1fr)')};
	gap: ${(p) => p.theme.spacing(1)};
	height: calc(100vh - ${(p) => p.theme.navHeight + p.theme.panelGutter * 2}px);
	overflow: hidden;
`;
