import { scaleLinear } from 'd3-scale';

const useLinearYScale = (config: {
	yMaxRatio?: number;
	yMax: number;
	top: number;
	bottom: number;
	height: number;
}) => {
	const { yMax, yMaxRatio = 1, height, bottom, top } = config;

	const yScale = scaleLinear()
		.domain([0, yMax * yMaxRatio])
		.range([height - bottom, top]);

	return yScale;
};

export default useLinearYScale;
