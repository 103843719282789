import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import theme from "../theme/theme";
import Modal from "./Modal";
import ReactModal from "react-modal";
import StyledSquare from "../StyledSquare";
import Typography from "../text/Typography";
import IconButton, {IconButtonBase} from "../buttons/IconButton";
import {
	faBorderNone,
	faInfo,
	faInfoCircle,
	faTimes, faToggleOff,
	faToggleOn
} from "@fortawesome/free-solid-svg-icons";
import {BasePaper} from '../Paper';
import styled from 'styled-components';
import {harvestErrors, NonInputLine, SubtleTextbox} from "../inputs/SubtleTextBox";
import SubtleSelect from "../inputs/SubtleSelect";
import {FormResults} from "../Form";
import useCurrentUser from "../../features/authentication/hooks/useUser";
import {useUpdateSettingsMutation} from "../../features/api";
import {SubmitHandler, useForm} from "react-hook-form";
import Button from "../buttons/Button";
import FlexContainer from "../FlexContainer";
import {StyledInputContainer} from "../inputs/SubtleTextBox/styledComponents";
import Heading from "../text/Heading";
import SubtleTextArea from "../inputs/SubtleTextArea";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebook,
	faGithub, faInstagram,
	faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import ProfileAvatar from "../../features/navigation/components/Navbar/ProfileAvatar";
import SlideToggle, {SlideToggleCheckDescriptor} from "../inputs/SlideToggle";
import AppModal from "./AppModal";
import useModalType from "../../features/HUD/hooks/useModalType";

export const SourceBody = styled(BasePaper)`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: ${(p) => p.theme.palette.background.paper};
`

interface HUDProps {
	translucent?: boolean;
	borderRadius?: string;
	width?: string | number;
	height?: string | number;
}


	// box-shadow: ${(p) => p.theme.shadows[4]};
export const HUDModalBody = styled.div<HUDProps>`
	background: ${(p) =>
		p.theme.palette.background[
			p.translucent ? 'transparentDark' : 'paper'
		]};
		
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	color: ${(p) => p.theme.palette.lightBaby};
	width: 100%;
	height: calc(100vh - ${(p) => p.theme.navHeight * 4 + p.theme.panelGutter * 8}px);
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter * 4}px;
	position: absolute;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
	${(p) => p.theme.translucent}
`;

interface StyledModalHeaderProps {
	justifyContent?: string;
	borderBottom?: string;
	padding?: string;
}

	// padding: ${(p) => p.padding ? p.padding : p.theme.spacing(1, 2)};
export const ModalHeader = styled.header<StyledModalHeaderProps>`
	padding: 1rem;
    border-bottom: ${(p) => p.borderBottom ? p.borderBottom : `2px solid ${p.theme.palette.divider}`};
	display: flex;
	background: ${(p) => p.theme.palette.background['paper']};
	justify-content: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	justify-items: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	align-items: flex-start;
	align-content: flex-start;
	height: ${(p) => p.theme.navHeight};
	z-index: 10000;
`;

const StyledGraphicContainer = styled.div`
	position: relative;
	height:100%;
	padding-right: 100%;
	width: 0;
	float: left;
`
const StyledGraphicSquare = styled.div`
	background: ${(p) => p.theme.palette.primary.main};
	width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
`;

const StyledGraphicCircle = styled(StyledGraphicSquare)`
	background: ${(p) => p.theme.palette.primary.main};
	width: 6rem;
    height: 6rem;
    border-radius: 50%;
    position: absolute;
    left: 0;
`;


	// font-size: 1rem;
export const HUDModalHeaderText = styled.h1`
	display: flex;
	align-content: center;
	align-items: center;
	gap: 1rem;
	justify-content: flex-start;
	justify-items: flex-start;
`;


interface ModalHeaderProps extends ReactModal.Props {
	handleClose: () => void;
	headingText: string;
}


interface UpdateAppSettingsFormValues {
	doNotMail: boolean;
	doNotTrack: boolean;
}


const HUDModalHeader: FunctionComponent<ModalHeaderProps> = ({
	headingText,
	handleClose
}) => {
	return (
		<ModalHeader>
			<HUDModalHeaderText>
				<StyledSquare width={'2rem'} height={'2rem'}/>
				<Typography color={'oldManGray'} variant={'h1'}>
					{headingText}
				</Typography>
			</HUDModalHeaderText>
			<IconButton
				onClick={handleClose}
				icon={faTimes}
				aria-label="close source browser"
			/>
		</ModalHeader>
	);
};

const StyledButton = styled(Button)`
	margin-top: ${(p) => p.theme.spacing(1)};
`;


const toggleDefs: SlideToggleCheckDescriptor<string>[]  = [
	{label: 'on', icon: faToggleOn, value: 'true', tooltip: 'On'},
	{label: 'off', icon: faToggleOff, value: 'false', tooltip: 'Off'},
	{label: 'auto', icon: faBorderNone, value: 'auto', tooltip: 'Auto'},
];

const AppSettingsModal: FunctionComponent = () => {

	const {closeModal} = useModalType();

	const user = useCurrentUser();

	const [activeDoNotMail, setActiveDoNotMail] = useState('auto');
	const [activeDoNotTrack, setActiveDoNotTrack] = useState('auto');

	const { register, formState, resetField, handleSubmit, reset } =
		useForm<UpdateAppSettingsFormValues>({
			defaultValues: {
				doNotMail: false,
				doNotTrack: false,
			},
		});

	// this is needed to update react-hook-form's default values with new
	// values from a successful PUT request.
	useEffect(() => {
		if (!!user && formState.isDirty) {
			reset(user);
			closeModal();
		}
	}, [user, reset]);

	const validatingRegister = useCallback(
		(inputName: keyof UpdateAppSettingsFormValues) =>
			register(inputName, {
				required: `${inputName} is required`,
			}),
		[register]
	);

	const [updateSettings, mutationResults] = useUpdateSettingsMutation();
	const [isDoNotMailChecked, setIsDoNotMailChecked] = useState<boolean>(false);
	const [isDoNotTrackChecked, setIsDoNotTrackChecked] = useState<boolean>(false);

	const onSubmit: SubmitHandler<UpdateAppSettingsFormValues> = (formVals) => {
		const { __tag, ...userProps } = user;

		updateSettings({
			userId: userProps._id,
			body: Object.assign({}, userProps, formVals),
		});
	};

	//All Facebook keyboard shortcuts
// Global
// To Do This	Use Command
// Show shortcuts f1
// Show shortcuts		⇧ + ?shift and Question mark

// Disabled
// To Do This	Use Command
// Search Facebook
// /Forward slash


// News Feed
// To Do This	Use Command

// See more 								↩enter

// Disabled
// To Do This	Use Command
// Leave a comment 							c
// Jump to the next post 					j
// Previous post 							k
// Like or unlike a post 					l
// Open attachment of post 					o
// Create a post 							p
// Search Messenger contacts 				q
// Share post 								s


// Photo albums

// Disabled

// Enter or exit fullscreen					f
// Previous photo							j
// Next photo								k
// Like photo								l

// Single-character shortcuts
// Use single-character shortcuts to perform common actions faster on Facebook.

// Pin keyboard shortcut help
// In the corner of your screen, you'll see shortcuts that relate to what you're doing. They'll change as you use Facebook.


	if (user) {
		return (
			<AppModal
				label={"Settings & privacy"}
				isOpen={true}
				isDirty={formState.isDirty}
				onClose={closeModal}
				onSubmit={handleSubmit(onSubmit)}
				canEdit={true}>
				<Heading
					style={{padding: 0, margin: 0}}
					component="h3">
					Privacy settings
				</Heading>
				<SlideToggle
					legend="Do not mail"
					key={'doNotMail'}
					activeValue={activeDoNotMail}
					onCheck={setActiveDoNotMail}
					onActiveValueMismatch={() => {}}
					checkboxes={toggleDefs}
					// onChange={() => setIsDoNotMailChecked((p) => !p)}
					// onReset={() => resetField('doNotMail')}
					// isDirty={Boolean(formState.dirtyFields.doNotMail)}
				/>
					{/*{...validatingRegister('doNotMail')}*/}
				<SlideToggle
					legend="Do not track"
					key={'doNotTrack'}
					activeValue={activeDoNotTrack}
					onCheck={setActiveDoNotTrack}
					onActiveValueMismatch={() => {}}
					checkboxes={toggleDefs}
					// onChange={() => setIsDoNotTrackChecked((p) => !p)}
					// onReset={() => resetField('doNotTrack')}
					// isDirty={Boolean(formState.dirtyFields.doNotTrack)}
				/>
					{/*{...validatingRegister('doNotTrack')}*/}
				<FormResults
					{...mutationResults}
					validationErrors={formState.errors}
					onSuccessDelay={2000}
				/>
			</AppModal>
		);
	} else {
		return null
	}
};

export default AppSettingsModal;
