import styled from 'styled-components';

export const StyledSVGLabelText = styled.text`
	text-anchor: middle;
	fill: white;
	font-size: 10px;
`;

export const StyledSVGContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const StyledAxisGroup = styled.g`
	color: ${(p) => p.theme.palette.cyan};
`;
