import {groupForAttrSelect} from './helpers';
import {faShareNodes} from '@fortawesome/free-solid-svg-icons';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useAppDispatch} from 'app/hooks';
import FlexContainer from 'common/FlexContainer';
import Heading from 'common/text/Heading';
import IconButton from 'common/buttons/IconButton';
import {List, ListItem, ListItemContent, ListItemControls} from 'common/List';
import Typography from 'common/text/Typography';
import theme from 'common/theme/theme';
import {pipe} from 'common/utils/functionUtils';
import {setModalType} from 'features/HUD/state/HUDSlice';
import {useGetAttributesQuery} from 'features/api';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useEntitySearchParams
    from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import {
    SortedAttrList,
    pluralizeType,
} from 'features/ontology/helpers/attributeHelpers';
import useActiveEntityData from 'features/ontology/hooks/useActiveEntityData';
import {BaseAttribute} from 'features/ontology/types/attributeTypes';
import {Fragment, FunctionComponent, useEffect} from 'react';
import styled from 'styled-components';
import AttributeMenu from "../../menus/AttributeMenu";

function capitalize(str: string) {
    return str.replace(str[0], str[0].toUpperCase());
}

const StyledAttributeListContainer = styled(FlexContainer)`
	height: ${(p) =>
    `calc(100vh - ${(p.theme.navHeight + p.theme.panelGutter * 2)}px)`};
	overflow-y: auto;
	margin-left: .5rem;
	margin-right: .25rem;
`;

const StyledHeading = styled(Heading)`
	padding: ${(p) => p.theme.spacing(2)} 0 ${(p) => p.theme.spacing(1)} 5px;
	margin-bottom: 5px;
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
	text-transform: capitalize;
	font-size: 16px !important;
`;

interface AttrSelectItemProps {
    activeAttrName: string | null;
    onClick: () => void;
    attribute: BaseAttribute;
}

const AttrSelectItem: FunctionComponent<AttrSelectItemProps> = (
    {
        attribute,
        onClick,
        activeAttrName,
    }
) => {
    const appDispatch = useAppDispatch();

    const isSelected = activeAttrName === attribute.name;

    return (
        <ListItem

            button
            background="default"
            onClick={onClick}
            aria-current={isSelected}
            style={{padding: '4px 0 4px 4px', justifyContent: 'space-between', width: 'auto'}}
        >
            <ListItemContent>
                <Typography
                    color={isSelected ? 'primary' : 'oldManGray'}
                    variant="h5">
                    {attribute.singular}
                </Typography>
            </ListItemContent>
            <AttributeMenu attribute={attribute} canEdit={true}/>
        </ListItem>
    );
};

const AttributeSelect: FunctionComponent = () => {
    const entitySearchParams = useEntitySearchParams();

    const {activeItem: activeEntity} = useActiveEntityData();

    const activeAttrName = entitySearchParams.getActiveAttributeName();

    const {data: attrData, ...attrLoadState} = useGetAttributesQuery(
        activeEntity ? {entityId: activeEntity._id} : skipToken
    );

    const primaryIdAttr = attrData
        ? attrData.find((a) => a.isPrimary === true) ?? null
        : null;

    const makeOnClick = (name: string) => () => {
        entitySearchParams.setActiveAttribute(name, true);
    };

    const grouped = attrData
        ? pipe(attrData, groupForAttrSelect)
        : ([] as SortedAttrList);

    useEffect(() => {
        if (primaryIdAttr && !activeAttrName) {
            entitySearchParams.setActiveAttribute(primaryIdAttr.name);
        }
    }, [entitySearchParams, activeAttrName, primaryIdAttr]);

    return (
        <StyledAttributeListContainer flexDirection="column">
            <DisplayOnLoad {...attrLoadState} >
                <List style={{color: theme.palette.darkBaby}}>
                    <ListItem
                        button
                        background="default"
                        onClick={() => {
                            if (primaryIdAttr) {
                                entitySearchParams.setActiveAttribute(
                                    primaryIdAttr.name
                                );
                            }
                        }}
                    >
                        <ListItemContent style={{marginTop: '.5rem'}}>
                            <Typography
                                variant="h5"
                                color={
                                    primaryIdAttr?.name === activeAttrName
                                        ? 'primary'
                                        : 'oldManGray'
                                }
                            >
                                View{' '}{activeEntity ? activeEntity.plural : 'individuals'}
                            </Typography>
                        </ListItemContent>
                    </ListItem>

                    {grouped.map(({type, attrs}) => (
                        <ListItemContent key={type} style={{marginTop: '1rem'}}>
                            <div style={{
                                marginTop: '.5rem',
                                borderBottom: `1px solid ${theme.palette.divider}`
                            }}>

                                <Typography color="primary" variant="h5">
                                    {capitalize(pluralizeType(type))}
                                </Typography>
                            </div>
                            {attrs.map((a) => (
                                <AttrSelectItem
                                    attribute={a}
                                    onClick={makeOnClick(a.name)}
                                    activeAttrName={activeAttrName}
                                    key={a._id}
                                />
                            ))}
                        </ListItemContent>
                    ))}
                </List>
            </DisplayOnLoad>
        </StyledAttributeListContainer>
    );
};

export default AttributeSelect;
