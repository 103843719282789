import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	ProfileIcon,
	GraphIcon,
	SpatialIcon,
	UsageIcon,
	QualityIcon,
	ForeignKeysIcon,
	LineageIcon,
	DataIcon,
} from 'common/icons';
import SlideToggle, { SlideToggleCheckDescriptor } from 'common/inputs/SlideToggle';
import useAvailableViews from 'features/HUD/hooks/useAvailableViews';
import { ViewMode } from 'features/compositeViews/types';
import useDispatchableErr from 'features/errorHandling/hooks/useDispatchableErr';
import useViewModeParams from 'features/navigation/hooks/useViewModeParams';
import { FunctionComponent, useMemo } from 'react';
import {canEdit as canEditDomain} from "../../../features/authentication/helpers";
import {useParams} from "react-router-dom";
import {ContextParams} from "../../../features/navigation/types/navigationContextTypes";
import useActiveOrgData from "../../../features/ontology/hooks/useActiveOrgData";

const iconMap: Record<ViewMode, IconProp> = {
	spatial: SpatialIcon,
	profile: ProfileIcon,
	graph: GraphIcon,
	data: DataIcon,
	form: DataIcon,
	lineage: LineageIcon,
	usage: UsageIcon,
	relations: ForeignKeysIcon,
	quality: QualityIcon
};

const makeViewCheck = (vm: ViewMode): SlideToggleCheckDescriptor<ViewMode> => ({
	label: vm,
	icon: iconMap[vm],
	value: vm,
	tooltip: `View ${vm}`,
});

export const ViewToggle: FunctionComponent = () => {
	const dispatchErr = useDispatchableErr();


	const { getViewMode, setViewMode } = useViewModeParams();

	const currentViewMode = getViewMode();
	const availableViews = useAvailableViews();
	const checks = useMemo(
		() => availableViews.map(makeViewCheck),
		[availableViews]
	);
	// const canEdit = activeResource ? canEditDomain(activeResource) : false;

	//  availableViews updates asynchronously in Redux store, so we need to account
	// for initial empty array here.
	return availableViews.length > 0 ? (
		<SlideToggle
			activeValue={currentViewMode ? currentViewMode : 'profile'}
			checkboxes={checks}
			legend="select view mode"
			onCheck={setViewMode}
			onActiveValueMismatch={dispatchErr}
		/>) : null;
};

const getDataContextLevel = (username?: string, source?: string, catalog?: string, dataset?: string) =>
	dataset ? 'dataset' : catalog ? 'catalog' : source ? 'source' : username ? 'username' : null;

export const DataViewToggle: FunctionComponent = () => {
	const dispatchErr = useDispatchableErr();
	const { username, source, catalog, dataset } = useParams<ContextParams>();
	const navContextLevel = getDataContextLevel(username, source, catalog, dataset);


	const {activeItem: activeResource} = useActiveOrgData();
		// useActiveResourceData = useActiveSourceData;
	// if (navContextLevel === 'source') {
	// }
	// if (navContextLevel === 'catalog') {
	// 	useActiveResourceData = useActiveCatalogData;
	// }
	// if (navContextLevel === 'dataset') {
	// 	useActiveResourceData = useActiveDatasetData;
	// }

	const { getViewMode, setViewMode } = useViewModeParams();
	const currentViewMode = getViewMode();
	const availableViews = useAvailableViews();
	const checks = useMemo(
		() => availableViews.map(makeViewCheck),
		[availableViews]

	);
	// const { activeItem: activeResource } = useActiveResourceData();
	const canEdit = activeResource ? canEditDomain(activeResource) : false;

	//  availableViews updates asynchronously in Redux store, so we need to account
	// for initial empty array here.
	return availableViews.length > 0 ? (
		<>
		<SlideToggle
			activeValue={currentViewMode ? currentViewMode : 'data'}
			checkboxes={checks}
			legend="select view mode"
			onCheck={getViewMode}
			onActiveValueMismatch={dispatchErr}
		/>
		</>
	) : null;
};

export default ViewToggle;
