import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";
import SubtleTextArea from "./SubtleTextArea";

const AboutInput: FunctionComponent<ActionFieldProps> = (
    {
        defaultValue,
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleTextArea
        {...validatingRegister('definition')}
        label="Bio"
        defaultValue={defaultValue ? defaultValue : 'Describe yourself...'}
        isDirty={Boolean(formState.dirtyFields.definition)}
        onReset={() => resetField('definition')}
        error={harvestErrors(
            'definition',
            formState,
            mutationResults
        )}
    />;
}

export default AboutInput;
