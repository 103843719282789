import useHUDactive from '../hooks/useHUDActive';
import useViewportDimensions from '../hooks/useViewportDimensions';
import {BasePaper} from 'common/Paper';
import { FunctionComponent, ReactNode, useRef } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import styled, { DefaultTheme } from 'styled-components';

const short = (theme: DefaultTheme) => theme.transitions.duration.standard;

const ease = (theme: DefaultTheme) => theme.transitions.easing.easeInOut;

//	left: ${(p) => p.theme.panelGutter * 2 + p.theme.sideControllerWidth}px;
const StyledBottomBarContainer = styled.div<{
	$viewportWidth: number;
	$viewportHeight: number;
	state: TransitionStatus;
}>`
	box-sizing: border-box;
	opacity: ${(p) =>
		p.state === 'entered' || p.state === 'entering' ? 1 : 0};
	transition: transform ${({ theme }) => `${short(theme)}ms ${ease(theme)}`},
		opacity ${({ theme }) => `${short(theme)}ms ${ease(theme)}`},
		width ${({ theme }) => `${short(theme)}ms ${ease(theme)}`};
	transform: ${(p) =>
		`translateY(${
			p.state === 'entered' || p.state === 'entering'
				? '0'
				: p.theme.navHeight + p.theme.panelGutter
		}px)`};
	position: absolute;
	bottom: ${(p) => `${p.theme.panelGutter}px`};
	left: ${(p) => `${p.theme.panelGutter}px`};
	height: ${(p) => `${p.theme.navHeight}px`};
	/* subtract panelGutter width twice b/c the width of sidebar changed to
    deal with padding discrepancy in header */
	width: calc(100vw - ${(p) => p.theme.panelGutter * 3 + p.theme.sideControllerWidth}px);
	z-index: ${(p) => p.theme.zIndex.controlSurface};
	/* display: grid; */
	/* grid-template-columns: ${(p) =>
		`${p.theme.drawerWidth}px ${
			p.$viewportWidth -
			p.theme.drawerWidth -
			p.theme.navHeight -
			p.theme.panelGutter * 2
		}px`}; */

	/* Animation for use if bottom bar ever opens/closes independent of side panel */
	/* width: ${(p) =>
		//@ts-ignore
		p.$sidebarOpen
			? p.$viewportWidth - p.theme.drawerWidth - p.theme.panelGutter
			: p.$viewportWidth - p.theme.panelGutter * 2}px; */
`;

const StyledPaper = styled(BasePaper)`
	margin: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-content: flex-start;
	justify-items: flex-start;
`;

interface BottomBarProps {
	children?: ReactNode;
}

const BottomBar: FunctionComponent<BottomBarProps> = ({ children }) => {
	// Need this ref to stop react-transition-group from trying to
	// use findDOMNode, which is deprecated and profoundly irritating.
	const transitionRef = useRef<any>();

	const { viewportWidth, viewportHeight } = useViewportDimensions();

	const HUDIsActive = useHUDactive();

	return (
		<Transition in={HUDIsActive} timeout={10} nodeRef={transitionRef}>
			{(state) => (
				<StyledBottomBarContainer
					ref={transitionRef}
					$viewportWidth={viewportWidth}
					$viewportHeight={viewportHeight}
					state={state}
				>
					{/* <SourcePanelButton /> */}
					<StyledPaper>{children}</StyledPaper>
				</StyledBottomBarContainer>
			)}
		</Transition>
	);
};

export default BottomBar;
