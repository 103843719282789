import styled from 'styled-components';

export interface ImageAvatarProps {
	src: string;
	width?: string;
	height?: string;
	backgroundSize?: string;
}

	// border: 3px solid ${(p) => p.theme.palette.drapeGray};
const ImageAvatar = styled.img<ImageAvatarProps>`
	border: 3px solid ${(p) => p.theme.palette.darkBaby};
	/* magic number alert: IconButtonBase sets button width/height to theme.spacing(4) when
 		button's size prop is 'sm'.  We want this button to be the same size. */
	height: ${(p) => p.height ? p.height : p.theme.spacing(4)};
	width: ${(p) => p.width ? p.width : p.theme.spacing(4)};
	min-width: 32px;
	min-height: 32px;
	&:hover {
		border: 3px solid ${(p) => p.theme.palette.darkBaby};
		cursor: pointer;
	}
	&:focus-visible {
		outline: 0px solid ${(p) => p.theme.palette.primary.main};
	}
	border-radius: 50%;
	background-position: center;
	background-color: ${(p) => p.src ? p.theme.palette.slickRick : p.theme.palette.primary.main};
	background-repeat: no-repeat;
	background-size: ${(p) => (p.backgroundSize ? p.backgroundSize : 'cover')};
	background-image: ${(p) => (p.src ? `url(${p.src})` : 'none')};
`;

export const PositionedImageAvatar = styled.img<ImageAvatarProps>`
	border: 0px solid transparent;
	height: ${(p) => p.height ? p.height : p.theme.spacing(4)};
	width: ${(p) => p.width ? p.width : p.theme.spacing(4)};
	min-width: 32px;
	min-height: 32px;
	border-radius: 50%;
	background-color: transparent;
	background-position: center;
	background-repeat: no-repeat;
	background-size: ${(p) => (p.backgroundSize ? p.backgroundSize : 'cover')};
	background-image: ${(p) => (p.src ? `url(${p.src})` : 'none')};
`;

export default ImageAvatar;
