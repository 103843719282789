import React, {FunctionComponent} from "react";
import Heading from "../text/Heading";
import Typography from "../text/Typography";
import FlexContainer from "../FlexContainer";
import {formFieldHasErrors, genErrorIdFromLabel} from "../Form";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";


interface ElseFieldParams extends ActionFieldProps {
    attrData: any;
    watchedStaticElse: boolean;
}

const ElseSelect: FunctionComponent<ElseFieldParams> = (
    {
        formState,
        attrData,
        validatingRegister,
        watchedStaticElse
    }
    ) => {
    return <>
        <Heading style={{padding: 0, margin: 0}} component="h3">
            Else
        </Heading>
        <Typography id="usingStaticElseValue">
            Select an attribute or provide a static value to furnish
            a value if no condition evaluates to true
        </Typography>
        <FlexContainer
            style={{width: '100%', padding: '0 16px'}}
            justifyContent="space-between"
            role="radiogroup"
            flexDirection={'column'}
            aria-labelledby="usingStaticElseValue"
            aria-errormessage={genErrorIdFromLabel(
                'usingStaticElseValue'
            )}
            aria-invalid={formFieldHasErrors(
                'usingStaticElseValue',
                formState
            )}
        >
            <div>
                <input
                    id={`usingStaticElseValue-false`}
                    type="radio"
                    value="false"
                    {...validatingRegister(`usingStaticElseValue`, {
                        required: {
                            value: true,
                            message:
                                'A value for usingStaticElse is required',
                        },
                    })}
                />
                <label htmlFor={`usingStaticElseValue-false`}>
                    <Typography>Use attribute</Typography>
                </label>
            </div>
            <div>
                <input
                    id={`usingStaticElseValue-true`}
                    type="radio"
                    value="true"
                    {...validatingRegister(`usingStaticElseValue`, {
                        required: {
                            value: true,
                            message:
                                'A value for usingStaticElse is required',
                        },
                    })}
                />
                <label htmlFor={`usingStaticElse-true`}>
                    <Typography>Use static value</Typography>
                </label>
            </div>
        </FlexContainer>
        {!watchedStaticElse ? (
            <>
                <label htmlFor="elseId">
                    <Typography>Select an attribute</Typography>
                </label>
                <select
                    {...validatingRegister('elseId', {
                        valueAsNumber: true,
                        validate: (v: string | number) => {
                            if (typeof v === 'string') {
                                const maybeInt = parseInt(v, 10);

                                return isNaN(maybeInt)
                                    ? 'Invalid value passed to otherId'
                                    : maybeInt > 0
                                        ? true
                                        : 'An else attribute ID must be selected if you are not using a static files value';
                            }

                            return v > 0
                                ? true
                                : 'An else attribute ID must be selected if you are not using a static files value';
                        },
                    })}
                    id="elseId"
                    aria-errormessage={genErrorIdFromLabel(
                        'elseId'
                    )}
                    aria-invalid={formFieldHasErrors(
                        'elseId',
                        formState
                    )}
                >
                    {attrData.map((attr: any) => (
                        <option
                            value={attr._id}
                            key={attr._id}
                        >{`${attr.entity.plural}: ${attr.plural}`}</option>
                    ))}
                </select>
            </>
        ) : (
            <>
                <label htmlFor="elseValue">
                    <Typography>Enter a static value</Typography>
                </label>
                <input
                    type="text"
                    {...validatingRegister('elseValue', {
                        required: {
                            value: true,
                            message: `Else value is a required field if you are not using an attribute id`,
                        },
                        maxLength: {
                            value: 200,
                            message: `A maximum of 200 characters is allowed for singular`,
                        },
                    })}
                    id="elseValue"
                    aria-errormessage={genErrorIdFromLabel(
                        'elseValue'
                    )}
                    aria-invalid={formFieldHasErrors(
                        'elseValue',
                        formState
                    )}
                />
            </>
        )}
    </>
}

export default ElseSelect;
