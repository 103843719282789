import styled from 'styled-components';

	// background-color: transparent;
export const StyledInput = styled.textarea`
	position: relative;
	line-height: ${(p) => p.theme.typography.input.lineHeight};
	letter-spacing: ${(p) => p.theme.typography.input.letterSpacing};
	font-family: ${(p) => p.theme.typography.input.fontFamily};
	font-size: ${(p) => p.theme.typography.input.fontSize};
	font-weight: ${(p) => p.theme.typography.input.fontWeight};
	color: ${(p) => p.theme.palette.oldManGray};
	outline: none;
	border: 1px solid transparent;
	border-radius: 4px;
	padding: ${(p) => p.theme.spacing(0.5)};
	padding-left: ${(p) => p.theme.spacing(1)};
	width: 100%;
	overflow: hidden;
	background-color: #3e4354;
	&:hover {
		border: 1px solid ${(p) => p.theme.palette.divider};
		background-color: #3e4354;
		cursor: pointer;
	}

	&:focus {
		border: 1px solid ${(p) => p.theme.palette.divider};
		cursor: pointer;
		overflow: auto;
				background-color: #3e4354;
		color: ${(p) => p.theme.palette.common.white};
		background-color: #3e4354;
	}

	&:focus,
	&.is-dirty {
		border: 1px solid ${(p) => p.theme.palette.divider};
		cursor: text;
		background-color: #3e4354;
	}
`;
		// background-color: ${(p) => p.theme.palette.background.paper};

export const StyledTextArea = styled.textarea<{ $error?: boolean }>`
	border: 2px solid ${(p) => p.theme.palette.drapeGray};
	color: ${(p) => p.theme.palette.common.white}BF;
	border-radius: ${(p) => p.theme.spacing(1)};
	font-size: ${(p) => p.theme.typography.input.fontSize};
	line-height: ${(p) => p.theme.typography.input.lineHeight};
	letter-spacing: ${(p) => p.theme.typography.input.letterSpacing};
	font-weight: ${(p) => p.theme.typography.input.fontWeight};
	font-family: ${(p) => p.theme.typography.input.fontFamily};
	padding: ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(2)};
	width: 100%;
	background-color: ${(p) => p.theme.palette.common.white}0A;
	&:-webkit-autofill {
		background-color: ${(p) => p.theme.palette.oldManGray};
		background-color: ${(p) => p.theme.palette.darkerBaby};
	}
	&::autofill {
		background-color: ${(p) => p.theme.palette.oldManGray};
	}
	&:focus {
		border: 1px solid ${(p) =>
			p.$error ? p.theme.palette.error.main : p.theme.palette.darkerBaby};
		outline: none;
	}

	&::placeholder {
		color: ${(p) => p.theme.palette.cyan};
		font-size: ${(p) => p.theme.typography.overline.fontSize};
		line-height: ${(p) => p.theme.typography.overline.lineHeight};
		letter-spacing: ${(p) => p.theme.typography.overline.letterSpacing};
		font-weight: ${(p) => p.theme.typography.overline.fontWeight};
		font-family: ${(p) => p.theme.typography.overline.fontFamily};
	}
	caret-color:  ${(p) => p.theme.palette.primary.main};
`;
