import { composeName } from '../../features/governance/helpers';
import useFilteredDomainMembers from '../../features/governance/hooks/useFilteredDomainMembers';
import { DomainAuthorityLevel } from '../../features/governance/types/GovernanceTypes';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'common/buttons/IconButton';
import { List, ListItem, ListItemContent } from 'common/List';
import PopoverBase from 'common/popovers/Popover/PopoverBase';
import Typography from 'common/text/Typography';
import { sortByString } from 'common/utils/functionUtils';
import {
	useCreateDomainMemberMutation,
	useCreateDomainStewardMutation,
} from 'features/api';
import { parseQueryError } from 'features/api/helpers';
import { FunctionComponent, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import useFilteredOrgMembers
	from "../../features/governance/hooks/useFilteredOrgMembers";

const selectMutation = (authorityLevel: DomainAuthorityLevel) => {
	switch (authorityLevel) {
		case 'steward':
			return useCreateDomainStewardMutation;
		case 'member':
			return useCreateDomainMemberMutation;
	}
};

interface DomainPromoteButtonProps {
	authorityLevel: DomainAuthorityLevel;
	domainId: number;
	orgId: number;
}

const DomainPromoteButton: FunctionComponent<DomainPromoteButtonProps> = ({
	authorityLevel,
	domainId,
	orgId,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const combinedMembers = sortByString(
		useFilteredDomainMembers(domainId, orgId, 'steward'),
		(usr) => usr.lastName ? usr.lastName : usr.firstName
	);

	const [menuOpen, setMenuOpen] = useState(false);

	const [promote, promotionResult] = selectMutation(authorityLevel)();

	const renderMemberList = () => {
		if (combinedMembers.length === 0) {
			return <ListItem>No available users</ListItem>;
		}

		return (
			<>
				{combinedMembers.map((usr) => (
					<ListItem
						button
						key={usr._id}
						onClick={() => {
							if (promotionResult.isLoading) {
								return;
							}

							promote({ domainId, body: { userId: usr._id } });
						}}
					>
						{composeName(usr)}
					</ListItem>
				))}
				{promotionResult.isLoading && (
					<ListItem>
						<ListItemContent>
							<Typography variant='h5' color="primary">
								Updating...
							</Typography>
						</ListItemContent>
					</ListItem>
				)}
				{promotionResult.isError && (
					<ListItem>
						<ListItemContent>
							<Typography color="error">
								{parseQueryError(promotionResult.error).message}
							</Typography>
						</ListItemContent>
					</ListItem>
				)}
			</>
		);
	};

	return (
		<>
			<IconButton
				ref={setAnchorEl}
				icon={faPlus}
				tooltip={`add domain ${authorityLevel}`}
				showTooltip={!menuOpen}
				aria-label={`add domain ${authorityLevel}`}
				onClick={() => setMenuOpen((p) => !p)}
			/>
			<PopoverBase
				placement="bottom-start"
				open={menuOpen}
				anchorEl={anchorEl}
			>
				<ClickAwayListener onClickAway={() => setMenuOpen(false)}>
					<List style={{ maxHeight: '300px', overflowY: 'auto' }}>
						{renderMemberList()}
					</List>
				</ClickAwayListener>
			</PopoverBase>
		</>
	);
};

export default DomainPromoteButton;
