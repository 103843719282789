import theme from 'common/theme/theme'
import { ColumnMeta } from '../types';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

const StyledIDCell = styled.th<ColumnMeta>`
	display: grid;
	grid-template-columns: 2ch ${(p) => p.naiveWidth + 2}ch;
	padding: ${(p) => p.theme.spacing(1, 2)};
`;

interface IDCellProps extends ColumnMeta {
	className: string;
	showIcon: boolean;
	onClick?: () => void;
	children?: ReactNode;
}

const IDCell: FunctionComponent<IDCellProps> = ({
	onClick,
	showIcon,
	children,
	className,
	...props
}) => {
	return (
		<StyledIDCell {...props} className={className}>
			<div>
				{showIcon && (
					<FontAwesomeIcon
						icon={faEye}
						size={'xs'}
						color={theme.palette.primary.main}
						style={{ cursor: 'pointer' }}
						onClick={onClick}
					/>
				)}
			</div>
			<div
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					textAlign: 'left',
					paddingLeft: '8px',
				}}
			>
				{children}
			</div>
		</StyledIDCell>
	);
};

export default IDCell;
