import useHookId from './useHookId';
import environment from 'common/environment';
import { brushX } from 'd3-brush';
import { select } from 'd3-selection';
import { useEffect, useMemo } from 'react';

type BrushEventName = 'Start' | 'End' | 'Brush';

type BrushEventHandlerKey = `on${BrushEventName}`;

type BrushEventHandlerArg = Record<
	BrushEventHandlerKey,
	((v: [string, string]) => void) | ((v: [number, number]) => void)
>;

const classBase = 'brush-x';

interface BrushConfig extends Partial<BrushEventHandlerArg> {
	width: number;
	height: number;
	extent?: [[number, number], [number, number]];
	top: number;
	left: number;
	right: number;
	bottom: number;
	invert: (v: any) => any;
	transformBrushMove?: (pos: [number, number]) => [number, number];
}

const useBrushX = ({
	transformBrushMove = (x: any) => x,
	left,
	top,
	right,
	bottom,
	extent,
	onBrush,
	onEnd,
	onStart,
	invert,
	width,
	height,
}: BrushConfig) => {
	const className = useHookId(classBase);

	const brush = useMemo(() => {
		const ext = extent ?? [
			[left, top],
			[width - right, height - bottom],
		];

		const brush = brushX().extent(ext);

		//   only attach brush if there's at least one handler listening
		if (onBrush || onStart || onEnd) {
			if (onEnd) {
				brush.on('end', ({ selection }) => {
					if (selection) {
						onEnd(selection.map(invert));

						const brushEl = select<SVGGElement, unknown>(
							`.${className}`
						);

						if (brushEl) {
							brushEl.call(brush.move, null);
						}
					}
				});
			}

			if (onStart) {
				brush.on('start', ({ selection }) => {
					onStart(selection.map(invert));
				});
			}

			if (onBrush) {
				brush.on('brush', ({ selection }) => {
					onBrush(selection.map(invert));
				});
			}

			brush.on('brush', (e) => {
				if (!e.sourceEvent) {
					return;
				}

				const brushEl = select<SVGGElement, unknown>(`.${className}`);

				if (brushEl) {
					brushEl.call(brush.move, transformBrushMove(e.selection));
				}
			});
		}

		return brush;
	}, [
		transformBrushMove,
		left,
		top,
		right,
		bottom,
		extent,
		onBrush,
		onEnd,
		onStart,
		invert,
		className,
		width,
		height,
	]);

	//   only attach brush if there's at least one handler listening.
	//   Brush breaks JSDOM in tests, so don't attach in in test environment.
	useEffect(() => {
		if ((onBrush || onStart || onEnd) && environment.NODE_ENV !== 'test') {
			select<SVGGElement, unknown>(`.${className}`).call(brush);
		}
	}, [onBrush, onStart, onEnd, brush, className]);

	return { brush: brushX, brushClass: className };
};

export default useBrushX;
