import React, {FunctionComponent} from "react";
import {timeResolutions} from "../../features/actions/createEvent/createEventTypes";
import Typography from "../text/Typography";
import {formFieldHasErrors, genErrorIdFromLabel} from "../Form";
import {GetEntityAttrsResponse} from "../../features/ontology/types/attributeTypes";
import AppModal from "../modals/AppModal";
import SubtleSelect from "./SubtleSelect";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {harvestErrors} from "./SubtleTextBox";


//https://doi.org/10.1007/978-0-387-35973-1_1376
//Here are the temporal resolutions for several common remote sensing products:
// • NOAA AVHRR: < 1 day
// • MODIS: 1–2 days
// • QuickBird: 1–3.5 days (off-nadir)
// • Ikonos: 16 days (1.5–3 days off-nadir)
// • Landsat ETM+ : 16 days
// • RADARSAT1: 24 days (1–6 days off-nadir)
// • SPOT5: 26 days (2–3 days off-nadir)
// • NAPP (USGS): 5 years

// Temporal resolution is defined as the amount of time need- ed to revisit
// and acquire data for the exact same loca- tion. When applied to remote
// sensing, this amount of time depends on the orbital characteristics of the
// sensor plat- form as well as sensor characteristics. The temporal
// resolution is high when the revisiting delay is low and vice- versa. Temporal resolution is usually expressed in days.
const TemporalResolutionSelect: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister
    }
    ) => {
    return <SubtleSelect
        inline={true}
        label={`Type`}
        alignItems={'center'}
        justifyContent={'space-between'}
        onReset={() => resetField('type')}
        isDirty={Boolean(formState.dirtyFields.type)}
        {...validatingRegister('type')}
    />
// {/*{timeResolutions.map((tr) => {*/}
// {/*    return (*/}
// {/*        <StyledFieldContainer justifyContent="space-between" key={tr}>*/}
// {/*            <label htmlFor={tr}>*/}
// {/*                <Typography style={{textTransform: 'capitalize'}}>*/}
// {/*                    {tr.slice(0, -2)}:*/}
// {/*                </Typography>*/}
// {/*            </label>*/}
// {/*            <div style={{flex: '0 1 70%'}}>*/}
// {/*                <select*/}
// {/*                    style={{width: '100%'}}*/}
// {/*                    {...register(tr, {valueAsNumber: true})}*/}
// {/*                    id={tr}*/}
// {/*                    aria-errormessage={genErrorIdFromLabel(tr)}*/}
// {/*                    aria-invalid={formFieldHasErrors(tr, formState)}*/}
// {/*                >*/}
// {/*                    <option value={0} key={0}>*/}
// {/*                        None*/}
// {/*                    </option>*/}
// {/*                    {(attrData as GetEntityAttrsResponse)*/}
// {/*                        .map((attr) => (*/}
// {/*                            <option value={attr._id} key={attr._id}>*/}
// {/*                                {`${attr.entity.plural}: ${attr.plural}`}*/}
// {/*                            </option>*/}
// {/*                        ))}*/}
// {/*                </select>*/}
// {/*            </div>*/}
// {/*        </StyledFieldContainer>*/}
// {/*    );*/}
// {/*})}*/}
}

export default TemporalResolutionSelect;
