import {
	DataSourceSummaries,
	GetLiveDataCatalogsResponse, LiveDataCatalogs, LiveDataSets
} from '../../types/dataTypes';
import {
	SelectedSourceData,
	SourceNavItemList,
} from '../../types/uiTypes';
import ContextualDisplay, {BrowserDisplay} from '../ContextualDisplay';
import SourceNav, {BrowserNav} from './SourceNav';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetSourceSummariesQuery} from 'features/api';
import { parseQueryError } from 'features/api/helpers';
import { canEdit as canEditOrg } from 'features/authentication/helpers';
import useActiveOrgData from 'features/ontology/hooks/useActiveOrgData';
import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from "common/modals/Modal";
import Typography from "common/text/Typography";
import {SourceBody, ModalHeader} from "../../../HUD/components/HUDModal/styledComponents";
import ReactModal from "react-modal";
import {ModalType} from "../../../HUD/types/HUDTypes";
import {DataNav, DataModalNav} from "../../../navigation/components/Navbar";
import useActiveSourceData from "../../../ontology/hooks/useActiveSourceData";
import useActiveSourceCatalogs
	from "../../../ontology/hooks/useActiveSourceCatalogs";
import {ViewMode} from "../../../compositeViews/types";

const StyledMain = styled.div<{grid?: string;}>`
	display: grid;
	grid-template-columns: ${(p) => p.grid ? p.grid : '1fr 1fr 11fr'};
	grid-template-rows: 1fr;
	flex: 1;
	overflow: auto;
	position: absolute;
	width: calc(100vw - ${(p) => `${p.theme.sideControllerWidth + p.theme.panelGutter * 3}`}px);
	height: calc(100vh - ${(p) => p.theme.navHeight * 2 + p.theme.panelGutter * 2}px);
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter}px;
	color: ${(p) => p.theme.palette.text.paper};
`;

const StyledModal = styled.div`
	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-template-rows: 1fr;
	flex: 1;
	overflow: auto;
	background: ${(p) => p.theme.palette.background.paper};
	color: ${(p) => p.theme.palette.text.paper};
`;

const StyledPanel = styled.div`
	display: grid;
	flex: 1;
	overflow: auto;
	background: ${(p) => p.theme.palette.background.paper};
	color: ${(p) => p.theme.palette.text.paper};
`;

interface SourceBrowserBaseProps {
	setOnDisplay: any;
	onDisplay: any;
	displayMode: any;
	setDisplayMode: any;
}

interface SourceBrowserModalProps extends ReactModal.Props {
	handleClose: () => void;
	modalType: ModalType;
	modalWidth?: number;
	modalHeight?: number;
	top?: string;
	borderRadius?: string;
}

const StyledTypography = styled(Typography)`
	display: inline;
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
`;

const SourceBrowser: FunctionComponent = () => {

	const {activeItem: activeOrg} = useActiveOrgData();
	const {activeItem: activeSource} = useActiveSourceData();
	const {
		isError,
		isLoading,
		data: sources,
		isFetching,
		error,
		isSuccess,
		isUninitialized,
	} = useGetSourceSummariesQuery(
		activeOrg ? { orgId: activeOrg._id } : skipToken
	);

	const canEdit = !!activeOrg && activeOrg.userContext.permissions.edit;

	const { catalogs, ...loadState } = useActiveSourceCatalogs();
	const [cardData, setCardData] = useState<SourceNavItemList | LiveDataCatalogs | LiveDataSets | null>(null)
	const [displayMode, setDisplayMode] = useState<null | 'form'>(null)

	// const {getViewMode, setViewMode} = useViewModeParams();
	const [onDisplay, setOnDisplay] = useState<SelectedSourceData>([]);

	useEffect(() => {
		if (loadState.isSuccess && catalogs) {
			// setOnDisplay(catalogs);
			setCardData(catalogs);
		}
	}, [catalogs, loadState.isSuccess]);

	// TODO: this causes flicker on cache invalidation.  Pass these props down
	// so children can render a spinner or something instead.
	if (loadState.isLoading || loadState.isFetching || loadState.isUninitialized) {
		return <SourceBody borderRadius={'0px'}>
			<Typography variant={'h2'} color={'oldManGray'}>
				Loading...
			</Typography>
		</SourceBody>;
	}

	if (loadState.isError && loadState.error) {
		const { message } = parseQueryError(loadState.error);
		return <SourceBody borderRadius={'0px'}>
			<Typography variant={'h2'} color={'oldManGray'}>
				{message}
			</Typography>
		</SourceBody>;
	}

	return (
		<>
			<SourceBody borderRadius={'0px'}>
				<StyledMain grid='1fr 1fr 7fr'>
					<StyledPanel>
						<BrowserNav
							sources={sources as DataSourceSummaries}
							setter={(toSelect: SelectedSourceData) => setOnDisplay(toSelect)}
							currentlySelected={onDisplay}
						/>
					</StyledPanel>
					<StyledPanel>
						<SourceNav
							catalogs={catalogs as GetLiveDataCatalogsResponse}
							setter={(toSelect: SelectedSourceData) => setOnDisplay(toSelect)}
							currentlySelected={onDisplay}
							sourceId={!!activeSource ? activeSource._id : skipToken}
						/>
					</StyledPanel>
					<StyledPanel>
						<BrowserDisplay
							activeItem={activeSource}
							onDisplay={onDisplay}
							setOnDisplay={setOnDisplay}
							cardData={cardData}
						/>
					</StyledPanel>
				</StyledMain>
			</SourceBody>
			</>
	);
}

// <ContextualDisplay
// 	displayMode={displayMode}
// 	closeForm={() => setDisplayMode('data')}
// 	activeItem={activeSource}
// 	setOnDisplay={setOnDisplay}
// 	onDisplay={onDisplay}
// 	cardData={cardData}
// />

export const SourceBrowserModal: FunctionComponent<SourceBrowserModalProps> = ({
		modalType,
		handleClose,
		modalWidth,
		modalHeight,
		top,
		borderRadius,
	}) => {
	const [onDisplay, setOnDisplay] = useState<SelectedSourceData>([]);
	const [displayMode, setDisplayMode] = useState<ViewMode>('form');
	return <Modal
			isOpen={!!modalType}
			modalType={modalType}
			shouldCloseOnOverlayClick={true}
			onRequestClose={handleClose}
			contentLabel={'Data Browser'}
			modalWidth={modalWidth}
			modalHeight={modalHeight}
			top={top}
			borderRadius={borderRadius}
		>
		<DataModalNav
			width={modalWidth}
			handleClose={handleClose}
			handleDisplay={setDisplayMode}
		/>
		<StyledModal>
			{/*<SourceBrowserBase*/}
			{/*	onDisplay={onDisplay}*/}
			{/*	setOnDisplay={setOnDisplay}*/}
			{/*	displayMode={displayMode}*/}
			{/*	setDisplayMode={setDisplayMode}*/}
			{/*/>*/}
		</StyledModal>
		</Modal>;
}

export default SourceBrowser;
