import React, {FunctionComponent, useCallback, useEffect} from "react";
import theme from "../theme/theme";
import Modal from "./Modal";
import ReactModal from "react-modal";
import StyledSquare from "../StyledSquare";
import Typography from "../text/Typography";
import IconButton, {IconButtonBase} from "../buttons/IconButton";
import {faInfo, faInfoCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import {BasePaper} from '../Paper';
import styled from 'styled-components';
import {harvestErrors, NonInputLine, SubtleTextbox} from "../inputs/SubtleTextBox";
import SubtleSelect from "../inputs/SubtleSelect";
import {FormResults} from "../Form";
import useCurrentUser from "../../features/authentication/hooks/useUser";
import {useCreateFeedbackMutation} from "../../features/api";
import {SubmitHandler, useForm} from "react-hook-form";
import Button from "../buttons/Button";
import FlexContainer from "../FlexContainer";
import {StyledInputContainer} from "../inputs/SubtleTextBox/styledComponents";
import Heading from "../text/Heading";
import SubtleTextArea from "../inputs/SubtleTextArea";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebook,
	faGithub, faInstagram,
	faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import ProfileAvatar from "../../features/navigation/components/Navbar/ProfileAvatar";
import useModalType from "../../features/HUD/hooks/useModalType";

export const SourceBody = styled(BasePaper)`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: ${(p) => p.theme.palette.background.paper};
`

interface HUDProps {
	translucent?: boolean;
	borderRadius?: string;
	width?: string | number;
	height?: string | number;
}


	// box-shadow: ${(p) => p.theme.shadows[4]};
export const HUDModalBody = styled.div<HUDProps>`
	background: ${(p) =>
		p.theme.palette.background[
			p.translucent ? 'transparentDark' : 'paper'
		]};
		
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	color: ${(p) => p.theme.palette.lightBaby};
	width: 100%;
	height: calc(100vh - ${(p) => p.theme.navHeight * 4 + p.theme.panelGutter * 8}px);
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter * 4}px;
	position: absolute;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
	${(p) => p.theme.translucent}
`;

interface StyledModalHeaderProps {
	justifyContent?: string;
	borderBottom?: string;
	padding?: string;
}

	// padding: ${(p) => p.padding ? p.padding : p.theme.spacing(1, 2)};
export const ModalHeader = styled.header<StyledModalHeaderProps>`
	padding: 1rem;
    border-bottom: ${(p) => p.borderBottom ? p.borderBottom : `2px solid ${p.theme.palette.divider}`};
	display: flex;
	background: ${(p) => p.theme.palette.background['paper']};
	justify-content: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	justify-items: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	align-items: flex-start;
	align-content: flex-start;
	height: ${(p) => p.theme.navHeight};
	z-index: 10000;
`;

const StyledGraphicContainer = styled.div`
	position: relative;
	height:100%;
	padding-right: 100%;
	width: 0;
	float: left;
`
const StyledGraphicSquare = styled.div`
	background: ${(p) => p.theme.palette.primary.main};
	width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
`;

const StyledGraphicCircle = styled(StyledGraphicSquare)`
	background: ${(p) => p.theme.palette.primary.main};
	width: 6rem;
    height: 6rem;
    border-radius: 50%;
    position: absolute;
    left: 0;
`;


	// font-size: 1rem;
export const HUDModalHeaderText = styled.h1`
	display: flex;
	align-content: center;
	align-items: center;
	gap: 1rem;
	justify-content: flex-start;
	justify-items: flex-start;
`;


interface ModalHeaderProps extends ReactModal.Props {
	handleClose: () => void;
	headingText: string;
}


interface SubmitFeedbackFormValues {
	feedback: string;
	category: string;
	// phoneNumber: string;
}


const HUDModalHeader: FunctionComponent<ModalHeaderProps> = ({
	headingText,
	handleClose
}) => {
	return (
		<ModalHeader>
			<HUDModalHeaderText>
				<StyledSquare width={'2rem'} height={'2rem'}/>
				<Typography color={'oldManGray'} variant={'h1'}>
					{headingText}
				</Typography>
			</HUDModalHeaderText>
			<IconButton
				onClick={handleClose}
				icon={faTimes}
				aria-label="close source browser"
			/>
		</ModalHeader>
	);
};

const StyledButton = styled(Button)`
	margin-top: ${(p) => p.theme.spacing(1)};
`;

const FeedbackModal: FunctionComponent = () => {

	const {closeModal} = useModalType();
	const user = useCurrentUser();

	const { register, formState, resetField, handleSubmit, reset } =
		useForm<SubmitFeedbackFormValues>({
			defaultValues: {feedback: ''},
		});

	// this is needed to update react-hook-form's default values with new
	// values from a successful PUT request.
	useEffect(() => {
		if (!!user && formState.isDirty) {
			closeModal();
		}
	}, [user, reset]);

	const validatingRegister = useCallback(
		(inputName: keyof SubmitFeedbackFormValues) =>
			register(inputName, {
				required: `${inputName} is required`,
			}),
		[register]
	);

	const [createFeedback, mutationResults] = useCreateFeedbackMutation();

	const onSubmit: SubmitHandler<SubmitFeedbackFormValues> = (formVals) => {
		createFeedback({
			body: Object.assign({userId: user._id}, formVals),
		});
	};

	if (user) {
		return (
			<Modal
				borderRadius={'8px'}
				isOpen={true}
				shouldCloseOnOverlayClick={true}
				onRequestClose={closeModal}
				contentLabel={"Give Feedback to Futuremodel"}
			>
				<FlexContainer
					flexDirection={'column'}
					style={{height: '100%', width: '100%', overflowY: 'hidden'}}>
					<HUDModalHeader
						handleClose={closeModal}
						headingText={"Edit profile"}
						isOpen={true}
					/>
					<form autoComplete={undefined} style={{height: '100%', width: '100%'}} onSubmit={handleSubmit(onSubmit)}>
						<HUDModalBody borderRadius={'8px'}>
							<FlexContainer flexDirection={'column'} gap={'1rem'} style={{padding: '2rem'}}>
								<Heading style={{padding: 0, margin: 0}} component="h3">
									Give feedback about your Futuremodel experience.
								</Heading>
								<Typography variant={'subtitle'} color={'oldManGray'}>
									Let us know about a broken feature.
								</Typography>
								<Heading style={{padding: 0, margin: 0}} component="h3">
									How can we improve?
								</Heading>
								<SubtleSelect
									label="Default organization"
									defaultValue={'Choose an area'}
									onReset={() => resetField('category')}
									isDirty={Boolean(formState.dirtyFields.category)}
									{...validatingRegister('category')}
								>
									{['Profile', 'Graph', 'Spatial'].map((category) => (
											<option key={category} value={category}>
												{category}
											</option>
										))}
								</SubtleSelect>
								<SubtleTextArea
									{...register('feedback')}
									label="Details"
									showLabel
									defaultValue={'Please include as much info as possible...'}
									isDirty={Boolean(formState.dirtyFields.feedback)}
									onReset={() => resetField('feedback')}
									error={harvestErrors(
										'feedback',
										formState,
										mutationResults
									)}
								/>
								<Heading style={{padding: 0, margin: 0}} component="h3">
									Add a Screenshot or Video (Recommended)
								</Heading>
								<Typography>
									Let us know if you have ideas that can help
									make our products better. If you need help
									solving a specific problem, please visit{" "}
									<a href='https://www.futuremodel.io/help'>
										the Help Center
									</a>.
								</Typography>
								<Heading style={{padding: 0, margin: 0}} component="h3">
									Something went wrong
								</Heading>
								<Typography variant={'subtitle'} color={'oldManGray'}>
									Let us know about a broken feature.
								</Typography>
															<SubtleSelect
									label="Default organization"
									defaultValue={'Choose an area'}
									onReset={() => resetField('category')}
									isDirty={Boolean(formState.dirtyFields.category)}
									{...validatingRegister('category')}
								>
									{['Profile', 'Graph', 'Spatial'].map((category) => (
											<option key={category} value={category}>
												{category}
											</option>
										))}
								</SubtleSelect>
								<SubtleTextArea
									{...register('feedback')}
									label="Details"
									showLabel
									defaultValue={'Please include as much info as possible...'}
									isDirty={Boolean(formState.dirtyFields.feedback)}
									onReset={() => resetField('feedback')}
									error={harvestErrors(
										'feedback',
										formState,
										mutationResults
									)}
								/>
								<Heading style={{padding: 0, margin: 0}} component="h3">
									Add a Screenshot or Video (Recommended)
								</Heading>
								<Typography>
									Let us know if you have ideas that can help
									make our products better. If you need help
									solving a specific problem, please visit{" "}
									<a href='https://www.futuremodel.io/help'>
										the Help Center
									</a>.
								</Typography>
								<FormResults
									{...mutationResults}
									validationErrors={formState.errors}
									onSuccessDelay={2000}
								/>
							</FlexContainer>
						</HUDModalBody>
						<StyledButton
							style={{
								position: 'absolute',
								bottom: '0',
								width: '100%',
								height: `${theme.navHeight}px`,
								borderRadius: '0 0 8px 8px'
							}}
							disabled={!formState.isDirty}>
							Submit
						</StyledButton>
					</form>
				</FlexContainer>
			</Modal>
		);
	} else {
		return null
	}
};

export default FeedbackModal;
