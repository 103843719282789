import styled from 'styled-components';
import {FunctionComponent} from 'react';

interface PaperProps {
	translucent?: boolean;
	borderRadius?: string;
	style?: any;
	overrides?: any;
	children?: any;
}

export const BottomPaper = styled.div<PaperProps>`
	padding: 0;
	margin: 0;
	background: ${(p) =>
		p.theme.palette.background[
			p.translucent ? 'transparentDark' : 'paper'
		]};
	${(p) => p.theme.translucent};
	background: transparent;
	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(500px);
		backdrop-filter: blur(500px);
	}
`

export const TopPaper = styled.div<PaperProps>`
	background: ${(p) =>
		p.theme.palette.background[
			p.translucent ? 'transparentDark' : 'paper'
		]};
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	box-shadow: ${(p) => p.theme.shadows[4]};
	color: ${(p) => p.theme.palette.lightBaby};
	opacity: 95%: 
	padding: 0;
	margin: 0;
`;


export const BasePaper = styled.div<PaperProps>`
	padding: 0;
	margin: 0;
	background: ${(p) =>
		p.theme.palette.background[
			p.translucent ? 'transparentDark' : 'paper'
		]};
	
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	box-shadow: ${(p) => p.theme.shadows[4]};
	color: ${(p) => p.theme.palette.lightBaby};

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
`;

export const Paper: FunctionComponent<{translucent?: boolean; style?: any; viewportHeight?: number; overrides?: any, children?: any}> = ({ viewportHeight, translucent=false, style, overrides, children }) => {
	return <TopPaper translucent={translucent}>
			<BottomPaper style={style}>
				{children}
			</BottomPaper>
		</TopPaper>;
};

export default Paper;
