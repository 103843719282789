import { StyledContentContainer, StyledHeading } from '../../../../features/spatial/components/styledComponents';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'common/buttons/IconButton';
import useElementSize from 'common/hooks/useSize';
import { default as theme } from 'common/theme/theme';
import { StyledLeftDrawerPanel } from 'features/HUD/components/styledComponents';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import { QueryStateTracker } from 'features/api/helpers';
import useEntitySearchParams from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import { Facts } from 'features/dataPreparation/dataPreparationTypes';
import HorizontalBarChart from 'common/viz/HorizontalBarChart/HorizontalBarChartCore';
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

const StyledHeadingContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
`;

interface HUDBarChartProps extends QueryStateTracker {
	facts: Facts<string>;
	attrName: string;
	attrPlural: string;
}

const HUDBarChartModule: FunctionComponent<HUDBarChartProps> = ({
	attrName,
	attrPlural,
	facts,
	...queryState
}) => {
	const { setCatRangeFilter } = useEntitySearchParams();

	const [activeCategories, setActiveCategories] = useState<string[]>([]);

	const [size, setSizeTarget] = useElementSize();

	const onBarClick = (cat: string) => {
		setActiveCategories((prev) =>
			prev.includes(cat) ? prev.filter((v) => v !== cat) : [...prev, cat]
		);
	};

	const onFilterSubmit = () => {
		setCatRangeFilter(attrName, activeCategories);
		setActiveCategories([]);
	};

	const hasSelections = activeCategories.length > 0;

	return (
		<StyledLeftDrawerPanel
			style={{
				padding: 0,
				display: 'flex',
				flexDirection: 'column',
				overflow: 'hidden',
			}}
		>
			<StyledHeadingContainer>
				<div></div>
				{hasSelections ? (
					<StyledHeading
					component="h6"
					className='unselectable'
					style={{
						color: theme.palette.lightBaby + 99,
						margin: '5px',
						whiteSpace: 'nowrap',
						fontSize: '9px',
						fontWeight: '700',
						height: '10px'
					}}
				>{attrPlural.slice(0,25)}
				</StyledHeading>
				) : (
					<StyledHeading
					component="h6"
					className='unselectable'
					style={{
						color: theme.palette.lightBaby + 99,
						margin: '5px',
						whiteSpace: 'nowrap',
						fontSize: '9px',
						fontWeight: '700',
						height: '10px'
					}}
				>
					{attrPlural}
				</StyledHeading>
				)}

				{hasSelections && (
					<IconButton
						size="xs"
						icon={faCheck}
						fillColor={hasSelections ? 'primary' : 'default'}
						disabled={!hasSelections}
						tooltip="submit filter"
						onClick={onFilterSubmit}
						overrides={{
							root: { justifySelf: 'end' },
						}}
						tooltipPlacement="right"
					/>
				)}
			</StyledHeadingContainer>
			<DisplayOnLoad {...queryState} spinnerDiameter={50}>
				<StyledContentContainer ref={setSizeTarget}>
					<HorizontalBarChart
						onBarClick={onBarClick}
						activeCategories={activeCategories}
						facts={facts.map((f) => f.values[0])}
						width={size.width}
						height={size.height}
						minBarThickness={24}
						left={4}
						top={4}
						right={16}
						fontSize={8.5}
						showLabels={false}
						showPercentage={true}
						bottom={4}
						xAxis={false}
						yAxis={false}
					/>
				</StyledContentContainer>
			</DisplayOnLoad>
		</StyledLeftDrawerPanel>
	);
};

export default HUDBarChartModule;
