import React, {FunctionComponent} from "react";
import MenuButton from "../buttons/MenuButton";
import MenuPanel from "./MenuPanel";
import {
    MANAGE_MEMBERS,
    SHARE_RESOURCE,
    VIEW_DOMAIN,
    VIEW_ORG,
    SHOW_CREDITS,
    FOLLOW_RESOURCE,
    BOOKMARK_RESOURCE,
    COPY_SHARE_URL, DESCRIBE_ENTITY, DESCRIBE_DOMAIN,
} from "../../features/graph/CONSTANTS";
import {BaseDomain} from "../../features/ontology/types/domainTypes";
import ShareResourceModal from "../modals/ShareResourceModal";

// <MenuAction onClick={() => {
// }} label={'Save to your tracked objects'}/>
const domainActions = [
    {
        actionTitle: VIEW_DOMAIN,
        kind: 'link',
        requiresEdit: false,
        location: '/:org/:domain'
	},
    {
        actionTitle: VIEW_ORG,
        kind: 'link',
        requiresEdit: false,
        location: '/:org'
	},
    {
        actionTitle: SHOW_CREDITS,
        modalType: 'domainCredits',
        kind: 'modal',
        requiresEdit: false,
        divider: true
	},
    {
        actionTitle: FOLLOW_RESOURCE,
        requiresEdit: false,
        kind: 'action',
        callback: 'follow',
	},
    {
        actionTitle: BOOKMARK_RESOURCE,
        requiresEdit: false,
        kind: 'action',
        divider: true,
	},
    {
        actionTitle: DESCRIBE_DOMAIN,
        modalType: 'domainSettings',
        kind: 'modal',
        requiresEdit: true,
    },
    {
        actionTitle: 'Share',
        requiresEdit: false,
        kind: 'subMenu',
        items: [
            {
                actionTitle: SHARE_RESOURCE,
                kind: 'modal',
                requiresEdit: false,
                modalType: 'shareResource',
                form: ShareResourceModal,
            },
            {
                actionTitle: COPY_SHARE_URL,
                requiresEdit: false,
                subMenu: 'share',
                kind: 'action',
                callback: 'copyLink',
                divider: false,
            },
        ]
    }
]

export const DomainMenu: FunctionComponent<{ domain: BaseDomain; canEdit: boolean; }> = (
    {domain, canEdit,}
) => {

    return <MenuButton placement={'right-start'}>
		<MenuPanel
			resource={domain}
			canEdit={canEdit}
			actions={domainActions}
        />
    </MenuButton>
}

export default DomainMenu;
