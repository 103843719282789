import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

export const DEFAULT_404_MESSAGE = 'Resource not found';

const NotFound: FunctionComponent = () => {
	return (
		<>
			<h1>{DEFAULT_404_MESSAGE}</h1>
			<Link to="/">Go Home</Link>
		</>
	);
};

export default NotFound;
