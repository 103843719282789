import React, {FunctionComponent, useEffect, useState} from "react";
// import timezones from "../../assets/tzs.json";
import SubtleSelect from "./SubtleSelect";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {harvestErrors} from "./SubtleTextBox";

const TimezoneSelect: FunctionComponent<ActionFieldProps> = (
    {
        defaultValue,
        mutationResults,
        formState,
        resetField,
        validatingRegister
    }
    ) => {
    // aria-errormessage={genErrorIdFromLabel('timezone')}
    // aria-invalid={formFieldHasErrors('timezone', formState)}

   	const [timezones, setTimezones] = useState([]);

   	// placeholder submission handlers
    // const onTZSubmit = (e: FormEvent) => {
    //     e.preventDefault();
    //     // @ts-ignore
    //     const fd = new FormData(e.target);
	//
    //     for (let [key, val] of fd) {
    //         console.log('key:', key, 'val:', val);
    //     }
    // };

     // fetch data to populate timezone select
    useEffect(() => {
        fetch('https://worldtimeapi.org/api/timezone')
            .then((res) => res.json())
            .then(setTimezones);
    }, []);

    return <SubtleSelect
        {...validatingRegister('timezone')}
        label="Timezone"
        defaultValue={defaultValue}
        inline={true}
                // alignItems={'center'}
                // justifyContent={'space-between'}
        isDirty={Boolean(formState.dirtyFields.timezone)}
        onReset={() => resetField('timezone')}
        error={harvestErrors(
            'timezone',
            formState,
            mutationResults
        )}
    >
        {timezones && timezones.map((tz) => (
            <option key={tz} value={tz}>{tz}</option>
        ))}
    </SubtleSelect>;
}

// <op/**/tion key="none" value="">None</option>
// {timezones.map((tz) => (
//     <option key={tz} value={tz}>
//         {tz}
//     </option>
// ))}

export default TimezoneSelect;
