import Button from 'common/buttons/Button';
import FlexContainer from 'common/FlexContainer';
import {FormResults} from 'common/Form';
import Textbox from 'common/inputs/TextBox';
import Typography from 'common/text/Typography';
import theme from 'common/theme/theme';
import {
    useReserveUserMutation
} from 'features/api';
import React, {
    FunctionComponent,
    MouseEventHandler,
    useRef, useState
} from 'react';
import {useForm} from 'react-hook-form';
import styled from 'styled-components';
import Success from "../../../common/Success";
import StyledForm from "../../../common/Form/FormBody";
import {isValidEmail} from "../../../common/utils/typeGuards";
import {CSSTransition} from "react-transition-group";
import SwipeInOut from "../../../common/transitions/SwipeInOut";


const StyledFormBody = styled.form`
	border-radius: ${(p) => p.theme.spacing(1)};
	background: ${(p) => p.theme.palette.darkerBaby};
	/* padding: ${(p) => p.theme.spacing(3, 3, 2)}; */
	max-width: 450px;
	min-width: 350px;
	width: 50%;
	margin: 0 0 15vh 0;
	justify-self: center;
	/* @media screen and (min-width: 1400px) {
		margin-right: 475px;
	} */

	@media (max-width: 750px) {
		justify-self: center;
		margin: 10vh 0 15vh 0;
	}
`;

const StyledLogo = styled.img`
	width: '75%';
	height: '75%';
	margin: ${(p) => p.theme.spacing(0)};
	padding: 0 0 0 50px;

	@media screen and (max-width: 600px) {
		width: 90%;
		padding: 0;
	}
`;

const StyledCitizen = styled.img`
	/* height: 100%;
	max-height: 30vh; */
	justify-self: center;
	margin: 0;
	min-width: 25%;
	min-height: 25%;
	max-width: 30vw;
	max-height: 30vh;

	@media (max-width: 750px) {
		max-height: 15vh;
		display: none;
	}
`;

const StyledMain = styled.div`
	background-color: ${(p) => p.theme.palette.darkBaby};
	width: 100%;
	height: 100vh;
`;

const StyledContainer = styled.div`
	display: grid;
	grid-gap: ${(p) => p.theme.spacing(1)};
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: min-content;
	grid-auto-columns: 1fr;
	align-items: middle;
	justify-items: center;
	margin: 15vh auto ${(p) => p.theme.spacing(0)};
	padding: 0;
	/* transform: translateY(-50%); */

	@media (max-width: 750px) {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(3, min-content);
		margin-top: ${(p) => p.theme.navHeight}px;
	}
`;

const StyledCopyright = styled.p`
	grid-column: span 2;
	color: #29323c;
	text-align: center;
	font-size: 12px;
	font-weight: 600;

	@media (max-width: 750px) {
		grid-column: span 1;
	}
`;

// const StyledMain = styled.div`
// 	background-color: ${(p) => p.theme.palette.darkBaby};
// 	width: 100%;
// 	height: 100vh;
// 	min-height: 600px;
// 	color: ${(p) => p.theme.palette.common.white};
// `;
//
// const StyledContainer = styled(FlexContainer)`
// 	margin: auto;
// 	padding-top: ${(p) => 2 * p.theme.navHeight}px;
// 	width: 40%;
// 	gap: ${(p) => p.theme.spacing(4)};
//
// 	@media screen and (max-width: 1200px) {
// 		width: 80%;
// 	}
// `;
//
// const StyledLogo = styled.img`
// 	width: '75%';
// 	height: '75%';
//
// 	margin-top: ${(p) => p.theme.spacing(10)};
//
// 	@media screen and (max-width: 600px) {
// 		width: 90%;
// 	}
// `;

export interface ReserveUserResponse {
}

export interface ReserveUserParams {
    email: string;
    username: string;
    firstName: string;
    lastName: string;
}

type TeaserFormValues = ReserveUserParams;

interface TGProps {
    shouldEnter: boolean;
    children: any;
}

const InputTransitionGroup: FunctionComponent<TGProps> = (
    {children, shouldEnter}
) => {
    const xitionRef = useRef<any>();
    return (
        <CSSTransition
            classNames="swipe-transition"
            timeout={300}
            in={shouldEnter}
            nodeRef={xitionRef}
            appear={true}
        >
            <SwipeInOut ref={xitionRef}>
                {children}
            </SwipeInOut>
        </CSSTransition>
    );
};
const CredentialsPage: FunctionComponent = () => {
    const {
        formState: {errors},
        register,
        handleSubmit,
        trigger,
    } = useForm<TeaserFormValues>({
        defaultValues: {
            email: '',
            username: '',
            firstName: '',
            lastName: '',
        },
    });

    const [reserve, reserveResult] = useReserveUserMutation();

    const [formPosition, setFormPosition] = useState(0);

    const advance = () => {
        setFormPosition((p) => (p + 1) % 3);
    };

    const handleClick: MouseEventHandler = async () => {
        const toValidate =
            formPosition === 0
                ? 'email'
                : formPosition === 1
                ? (['firstName', 'lastName'] as const)
                : 'username';

        const canContinue = await trigger(toValidate);

        const canSubmit = formPosition === 2;

        if (canContinue) {
            if (canSubmit) {
                return handleSubmit((vals) => reserve(vals))();
            }

            return advance();
        }

        return;
    };
    return (
        <StyledMain>
            <FlexContainer style={{
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '15vh 0 0',
                textAlign: 'center'
            }}>
                <StyledLogo
                    src="https://storage.googleapis.com/davis-associates.appspot.com/futuremodel-teaser.svg"
                    alt="Futuremodel logo"
                />
                <StyledContainer>
                    <StyledCitizen
                        src="https://storage.googleapis.com/davis-associates.appspot.com/fm-hero-figure.svg"
                        role="presentation"
                    />
                    <StyledFormBody
                        onSubmit={(e) => e.preventDefault()}
                        aria-label="request access"
                    >
                        <FlexContainer
                            flexDirection="column"
                            style={{
                                gap: '2px',
                                padding: `${theme.spacing(2, 2, 1)}`,
                                color: theme.palette.oldManGray
                            }}
                        >
                            {reserveResult.isSuccess ? (
                                <FlexContainer justifyContent="center">
                                    <div>
                                        <Success size="lg"/>
                                        <Typography
                                            color="primary"
                                            style={{fontSize: '2rem'}}>
                                            You're All Set!
                                        </Typography>
                                    </div>
                                </FlexContainer>
                            ) : (
                                <StyledForm aria-labelledby='name'
                                            onSubmit={(e) => e.preventDefault()}>
                                    <div style={{overflow: 'hidden'}}>
                                        <InputTransitionGroup
                                            shouldEnter={formPosition === 0}
                                        >
                                            <Textbox
                                                placeholder="Your email"
                                                type="text"
                                                style={{
                                                    height: '50px',
                                                    width: '100%',
                                                    marginBottom: '2px'
                                                }}
                                                {...register('email', {
                                                    required: 'Please enter a valid email address',
                                                    validate: isValidEmail,
                                                })}
                                                error={errors.email}
                                            />
                                        </InputTransitionGroup>
                                        <InputTransitionGroup
                                            shouldEnter={formPosition === 1}
                                        >
                                            <Textbox
                                                placeholder="First Name"
                                                {...register('firstName', {
                                                    required:
                                                        'Please enter your first name',
                                                })}
                                                style={{
                                                    height: '50px',
                                                    width: '100%',
                                                    marginBottom: '2px'
                                                }}
                                                error={errors.firstName}
                                                overrides={{
                                                    root: {
                                                        padding: '.6rem',
                                                        flex: 1,
                                                    },
                                                }}
                                            />
                                            <Textbox
                                                overrides={{
                                                    root: {
                                                        padding: '.6rem',
                                                        flex: 1,
                                                    },
                                                }}
                                                style={{
                                                    height: '50px',
                                                    width: '100%',
                                                    marginBottom: '2px'
                                                }}
                                                error={errors.lastName}
                                                placeholder="Last Name"
                                                {...register('lastName', {
                                                    required:
                                                        'Please enter your last name',
                                                })}
                                            />
                                        </InputTransitionGroup>
                                        <InputTransitionGroup
                                            shouldEnter={formPosition === 2}
                                        >
                                            <Textbox
                                                overrides={{root: {padding: '.6rem',},}}
                                                style={{
                                                    height: '50px',
                                                    width: '100%',
                                                    marginBottom: '2px'
                                                }}
                                                placeholder="Username"
                                                {...register('username', {
                                                    required: 'Please enter a username',
                                                })}
                                                error={errors.username}
                                            />
                                        </InputTransitionGroup>
                                    </div>
                                    <Button
                                        type="button"
                                        onClick={handleClick}
                                        // Limit button height to keep it from blowing up when the inputs have errs
                                        style={{maxHeight: '45px'}}
                                    >
                                        {formPosition === 0
                                            ? 'Join The List'
                                            : formPosition === 1
                                                ? 'Continue...'
                                                : 'Submit'}
                                    </Button>
                                    <FormResults {...reserveResult} />
                                </StyledForm>
                            )}
                        </FlexContainer>
                        <FlexContainer
                            justifyContent="center"
                            style={{
                                padding: '16px',
                                borderTop: `2px solid ${theme.palette.slickRick}`,
                            }}
                        >
                        </FlexContainer>
                    </StyledFormBody>
                </StyledContainer>
                <StyledCopyright>Futuremodel © 2022</StyledCopyright>
            </FlexContainer>
        </StyledMain>
    );
};

export default CredentialsPage;
