import React, {FunctionComponent, useEffect} from 'react';
import Heading from 'common/text/Heading';
import styled from "styled-components";
import theme from "../../../common/theme/theme";
import {BasePaper} from "../../../common/Paper";
import OrgCard from "../../../common/cards/OrgCard";
import {useAppDispatch} from "../../../app/hooks";
import {deriveUserViews} from "../../compositeViews/helpers";
import {pipe} from "../../../common/utils/functionUtils";
import {viewsAvailable} from "../../HUD/state/HUDSlice";
import {useGetOrgsQuery} from "../../api";
import {skipToken} from "@reduxjs/toolkit/query";
import useCurrentUser from "../../authentication/hooks/useUser";
import Typography from "../../../common/text/Typography";
import FlexCardContainer from "../../../common/FlexCardContainer";
import FlexContainer from "../../../common/FlexContainer";

	// height: calc(100vh - ${(p) => p.theme.navHeight + p.theme.panelGutter * 2}px);
	// top: ${(p) => p.theme.navHeight + p.theme.panelGutter}px;
	// width: calc(100vw - ${(p) => `${p.theme.sideControllerWidth + p.theme.panelGutter * 3}`}px);
	// left: ${(p) => p.theme.spacing(1)};
export const StyledFeedGridLayout = styled(BasePaper)`
	display: grid;
	grid-template-columns: 1fr 5fr 1fr;
	position: absolute;
	height: 100vh;
	width: 100vw;
	gap: 2rem;
	padding: 1rem;
	opacity: 1;
	z-index: ${(p) => p.theme.zIndex.controlSurface};
	overflow-y: auto;	
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		overflow-x: hidden;	
	}
`;


export const Stub: FunctionComponent<{ placeholder: string }> = ({ placeholder }) => {
	return (
		<StyledFeedGridLayout>
			<div/>
			<Heading style={{color: '#29323c'}} component="h5">
				{placeholder}
			</Heading>
		</StyledFeedGridLayout>
	);
};


export const StyledFeedPanel = styled.div`
	background-color: ${(p) => p.theme.palette.slickRick}
	padding: 5rem;
	display: flex;
	align-items: space-between;
	justify-content: center;
`;

export const FeedPage: FunctionComponent = () => {

	const currentUser = useCurrentUser();

	const { data: userOrgs, isSuccess } = useGetOrgsQuery(
		!!currentUser ? { userId: currentUser._id } : skipToken
	);

	const appDispatch = useAppDispatch();

	useEffect(() => {
		if (isSuccess && userOrgs) {
			const views = deriveUserViews();
			pipe(views, viewsAvailable, appDispatch);
		}

		return () => pipe([], viewsAvailable, appDispatch) as void;
	}, [currentUser, appDispatch]);

	return	<StyledFeedGridLayout>
		<StyledFeedPanel />
		<StyledFeedPanel>
			<FlexCardContainer justifyContent={'flex-start'}>
				{userOrgs && userOrgs
					.filter((org) => org.orgType !== 'experience')
					.sort((a, b) => a.label.charCodeAt(0) - b.label.charCodeAt(0))
					.map((org) => {
						return <OrgCard
							key={org._id}
							org={org}
							variant='slim'
						/>
					})}
			</FlexCardContainer>
		</StyledFeedPanel>
		<StyledFeedPanel />
	</StyledFeedGridLayout>;


};

export default FeedPage;
