import React, {FunctionComponent} from "react";
import useActiveOrgData from "../../features/ontology/hooks/useActiveOrgData";
import Typography from "../text/Typography";
import AppModal from "./AppModal";
import useModalType from "../../features/HUD/hooks/useModalType";
import useActiveDomainData
    from "../../features/ontology/hooks/useActiveDomainData";
import {canEdit as canEditOrg} from "../../features/authentication/helpers";
import {
    extractQueryErrMessage,
    mergeQueryStates
} from "../../features/api/helpers";
import MemberPanel from "../../features/governance/components/MemberPanel";
import {BaseOrg} from "../../features/ontology/types/orgTypes";
import {useParams} from "react-router-dom";

const DomainCreditsModal: FunctionComponent = () => {

    const params = useParams();
    const {modalType, modalProps, closeModal} = useModalType();
    const {resource} = modalProps;

    const {activeItem: activeOrg, ...orgQueryRest} = useActiveOrgData();

    const {activeItem: activeDomain, ...domainQueryRest} =
        useActiveDomainData(
            resource._object === 'Domain'
                ? resource.name
                : resource._object === 'Entity'
                ? resource.ontology.domain
                : resource.entity.ontology.domain
        );

    const canEdit = activeOrg ? canEditOrg(activeOrg) : false;

    const {isError, error, isLoading, isUninitialized} = mergeQueryStates(
        orgQueryRest,
        domainQueryRest
    );

    if (isError && error) {
        return (
            <Typography variant='h5' color="error">
                {extractQueryErrMessage(error)}
            </Typography>
        );
    }

    if (isLoading || isUninitialized) {
        return <Typography variant='h5'>
            Loading...
        </Typography>;
    }

    return (
        <AppModal
            label={"Credits"}
            isForm={false}
            isOpen={modalType === 'domainCredits'
            || modalType === 'entityCredits'
            || modalType === 'attributeCredits'}
            isDirty={false}
            onClose={closeModal}
            onSubmit={() => {}}
            canEdit={false}>
            {/*<Heading component={'h1'}*/}
            {/*         style={{justifySelf: 'center', alignSelf: 'center'}}>*/}
            {/*    {activeOrg.label}*/}
            {/*</Heading>*/}
            {!!activeDomain
                ? <>
                    <MemberPanel
                        canEdit={canEdit}
                        authorityLevel="admin"
                        contextLevel="username"
                        authorityLabel="Administered by"
                        orgId={(activeOrg as BaseOrg)._id}
                        domainId={activeDomain?._id}
                    />
                    <MemberPanel
                        canEdit={canEdit}
                        authorityLevel="governor"
                        contextLevel="username"
                        authorityLabel="Governed by"
                        orgId={(activeOrg as BaseOrg)._id}
                        domainId={activeDomain?._id}
                    />
                    <MemberPanel
                        canEdit={canEdit}
                        authorityLevel="steward"
                        contextLevel="domain"
                        authorityLabel="Stewarded by"
                        orgId={(activeOrg as BaseOrg)._id}
                        domainId={activeDomain?._id}
                    />
                </> : null
            }
        </AppModal>
    );
}

export default DomainCreditsModal;
