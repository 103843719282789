import useGraphDataLoader from '../../hooks/useGraphDataLoader';
import useGraphHandlers from '../../hooks/useGraphHandlers';
import {
    StyledGraphContainer,
    StyledHeading,
} from '../../../../common/viz/ForceGraph/styledComponents';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import theme from 'common/theme/theme';
import {useGetOrgGraphQuery} from 'features/api';
import GraphPopover from 'common/viz/ForceGraph/GraphPopover';
import {drawNode, drawLink} from 'features/graph/helpers';
import GraphContextProvider from 'features/graph/state/GraphContextProvider';
import useActiveOrgData from 'features/ontology/hooks/useActiveOrgData';
import React, {FunctionComponent} from 'react';
import {default as ForceGraph2D} from 'react-force-graph-2d';
import ContextMenu from "../../../../common/ContextMenu";

const OrgGraphBody: FunctionComponent = () => {
    const {activeItem} = useActiveOrgData();

    const canEdit = activeItem && activeItem.userContext.permissions.edit
        || activeItem && activeItem.userContext.permissions.admin;

    const queryRes = useGetOrgGraphQuery(
        activeItem ? {orgId: activeItem._id} : skipToken
    );

    useGraphDataLoader(queryRes);

    const {selectNode, width, height, setFgm, graphState} =
        useGraphHandlers();

    return (
        <>
            <StyledGraphContainer>
                <div>
                    {graphState.isLoadingData && (
                        <StyledHeading className='unselectable' component="h2"
                                       color={theme.palette.darkBaby}>
                            Preparing your data...
                        </StyledHeading>
                    )}
                    <ForceGraph2D
                        ref={setFgm as any}
                        backgroundColor={theme.palette.background.default}
                        width={width}
                        height={height}
                        graphData={graphState.graphData}
                        onNodeClick={selectNode as any}
                        linkCanvasObject={drawLink}
                        nodeId="_id"
                        nodeLabel={() => ''}
                        linkCanvasObjectMode={() => 'replace'}
                        nodeCanvasObject={drawNode()}
                    />
                </div>
                <GraphPopover/>
            </StyledGraphContainer>
            {canEdit ? <ContextMenu/> : null}
        </>
    );
};

const OrgGraph: FunctionComponent = () => {
    return (
        <GraphContextProvider>
            <OrgGraphBody/>
        </GraphContextProvider>
    );
};

export default OrgGraph;
