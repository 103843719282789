import { ActionDescriptor } from '../common/commonTypes';
import AggregateAttributeForm from './AggregateAttributeForm';

const aggregateAttribute: ActionDescriptor = {
	form: AggregateAttributeForm,
	actionTitle: 'Aggregate',
	requiresEdit: true,
	kind: 'modal',
	divider: false,
	modalType: 'aggregateAttribute'
};

export default aggregateAttribute;
