export const EVENT_LINE_CAT = 'eventLineCat';
export const SCATTERPLOT_CATEGORY = 'scatterplotCat';
export const SCATTERPLOT_Y = 'scatterplotY';
export const EVENT_LINE_Y = 'eventLineY';
export const ATTRIBUTE = 'attribute';
export const CAT_RANGE_FILTER_TYPE = 'catRange' as const;
export const QTY_RANGE_FILTER_TYPE = 'qtyRange' as const;
export const EVENT_RANGE_FILTER_TYPE = 'eventRange' as const;
export const IDENTITY_FILTER_TYPE = 'identity' as const;
export const QUERY_STRING_FILTER_PARSING_ERROR =
	'Query string filter validation failed';
export const HISTOGRAM_CAT = 'histogramCat';
