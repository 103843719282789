import IconButton from '../../buttons/IconButton';
import {
    StyledInputContainer,
    StyledLabel,
} from '../SubtleTextBox/styledComponents';
import Typography from '../../text/Typography';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import {forkRef} from 'common/utils/reactUtils';
import React, {
    forwardRef,
    MouseEventHandler,
    PropsWithChildren,
    useRef,
} from 'react';
import {UseFormRegisterReturn} from 'react-hook-form';
import styled from "styled-components";
import {Children} from "react";
import FlexContainer from "../../FlexContainer";

export interface SubtleSelectProps extends UseFormRegisterReturn {
    isDirty: boolean;
    label: string;
    onReset?: () => void;
    error?: string;
    defaultValue?: string;
    alignItems?: string;
    justifyContent?: string;
    inline?: boolean;
    id?: any;
}

const StyledOption = styled.option`
	color: ${(p) => p.theme.palette.primary.main};
	background-color: ${(p) => p.theme.palette.primary.main};

`;

// background-color: ${(p) => p.theme.palette.common.white}0A;
const StyledSelect = styled.select<{ $error?: boolean }>`
	background-color: ${(p) => p.theme.palette.darkerBaby};
	color: ${(p) => p.theme.palette.common.white}BF;
	background: transparent;
	border: 1px solid transparent;
	border-radius: ${(p) => p.theme.spacing(1)};
	font-size: ${(p) => p.theme.typography.input.fontSize};
	line-height: ${(p) => p.theme.typography.input.lineHeight};
	letter-spacing: ${(p) => p.theme.typography.input.letterSpacing};
	font-weight: ${(p) => p.theme.typography.input.fontWeight};
	font-family: ${(p) => p.theme.typography.input.fontFamily};
	padding: ${(p) => p.theme.spacing(0.5)} ${(p) => p.theme.spacing(1)};
	width: 100%;
	&:-webkit-autofill {
		background-color: ${(p) => p.theme.palette.darkerBaby};
		background-color: ${(p) => p.theme.palette.darkerBaby};
	}
	&::autofill {
		background-color: ${(p) => p.theme.palette.darkerBaby};
	}
	&:focus {
		border: 1px solid ${(p) =>
    p.$error ? p.theme.palette.error.main : p.theme.palette.darkerBaby}AA;
		outline: none;
	}
		&:active {
		outline: none;
	}

	&::placeholder {
		color: ${(p) => p.theme.palette.cyan};
		font-size: ${(p) => p.theme.typography.overline.fontSize};
		line-height: ${(p) => p.theme.typography.overline.lineHeight};
		letter-spacing: ${(p) => p.theme.typography.overline.letterSpacing};
		font-weight: ${(p) => p.theme.typography.overline.fontWeight};
		font-family: ${(p) => p.theme.typography.overline.fontFamily};
	}
	caret-color:  ${(p) => p.theme.palette.primary.main};
`;

const SubtleSelect = forwardRef<HTMLInputElement,
    PropsWithChildren<SubtleSelectProps>>((
    {
        alignItems,
        justifyContent,
        isDirty,
        inline,
        id,
        defaultValue,
        children,
        onReset,
        label,
        error,
        ...rest
    }, forwardedRef) => {
    const resetterRef = useRef<HTMLInputElement>();
    const setRefs = forkRef(forwardedRef, resetterRef);
    const onContainerClick: MouseEventHandler<HTMLDivElement> = () => {
        if (resetterRef.current) {
            resetterRef.current.focus();
        }
    };

    return <div id={id}>
        <StyledInputContainer
            justifyContent="space-between"
            alignItems="center"
            onClick={onContainerClick}
            data-testid="subtle-input-container"
        >
            {
                inline
                    ? <FlexContainer
                        alignItems='center'
                        justifyContent='flex-start'
                        style={{width: '100%'}}>
                        <StyledLabel
                            htmlFor={id ? id : `${label}-subtle-input`}
                            style={{marginRight: '.5rem'}}>
                            {label}
                        </StyledLabel>
                        <StyledSelect
                            id={id ? id : `${label}-subtle-input`}
                            defaultValue={defaultValue}
                            className={clsx(isDirty && 'is-dirty')}
                            aria-invalid={Boolean(error)}
                            aria-errormessage={`${label}-error-message`}
                            // @ts-ignore
                            ref={setRefs}
                            {...rest}
                        >
                            {children && Children.map(children, (child: any, i) => {
                                return <StyledOption
                                    key={child.props.value}
                                    value={child.props.value}>
                                    {child.props.children}
                                </StyledOption>
                            })}
                        </StyledSelect>
                    </FlexContainer>
                    : <FlexContainer
                        alignItems='flex-start'
                        flexDirection='column'
                        justifyContent='flex-start'
                        style={{width: '100%', gap: '1rem'}}>
                        <StyledLabel htmlFor={id ? id : `${label}-subtle-input`}>
                            {label}
                        </StyledLabel>
                        <StyledSelect
                            id={id ? id : `${label}-subtle-input`}
                            defaultValue={defaultValue}
                            className={clsx(isDirty && 'is-dirty')}
                            aria-invalid={Boolean(error)}
                            aria-errormessage={`${label}-error-message`}
                            // @ts-ignore
                            ref={setRefs}
                            {...rest}
                        >
                            {children && Children.map(children, (child: any, i) => {
                                return <StyledOption value={child.props.value}>
                                    {child.props.children}
                                </StyledOption>
                            })}
                        </StyledSelect>
                    </FlexContainer>
            }
            {isDirty && (
                <IconButton
                    size="xs"
                    icon={faTimes}
                    aria-label={`reset ${label} field`}
                    tooltipPlacement="top"
                    tooltip={`reset ${label} field`}
                    onClick={(e) => {
                        e.preventDefault();
                        onReset && onReset();
                    }}
                />
            )}
            <div>
                {error && (
                    <Typography color="error" id={`${label}-error-message`}>
                        {error}
                    </Typography>
                )}
            </div>
        </StyledInputContainer>
    </div>;
});

export default SubtleSelect;
