import Typography from 'common/text/Typography';
import { isAppError } from 'common/utils/typeGuards';
import useDispatchableError from 'features/errorHandling/hooks/useDispatchableErr';
import { AppError } from 'features/errorHandling/types/errorTypes';
import {
	contextNameFromContextIdx,
	URLParamsToContextArray,
} from 'features/navigation/helpers';
import {ContextLadder,} from 'features/navigation/types/navigationContextTypes';
import React, {FunctionComponent, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {
	OrgCommentsList,
	DomainCommentsList,
	EntityCommentsList,
	IndividualCommentsList
} from './CommentsList'
import FlexContainer from "../../FlexContainer";
import {ListItemContent} from "../../List";

const errUI = (message: string) => {
	return (
		<>
			<div>
				<Typography color="error">
					Unable to determine ontology depth from url. Parsing failed
					with the following error:
				</Typography>
			</div>
			<div>
				<Typography color="error">{message}</Typography>
			</div>
		</>
	);
};

const getUI = (contextLadder: AppError | ContextLadder) => {
	if (isAppError(contextLadder)) {
		return errUI(contextLadder.message);
	}

	const ctx = contextNameFromContextIdx(contextLadder.length - 1);
	// const dataCtx = dataContextNameFromContextIdx(dataContextLadder.length - 1);

	switch (ctx) {
		case 'username':
			return <OrgCommentsList />;
		case 'domain':
			return <DomainCommentsList />;
		case 'entity':
			return <EntityCommentsList />;
		case 'individual':
			return <IndividualCommentsList />;
		default:
			return <OrgCommentsList />;
	}
};

const CommentsTab: FunctionComponent<{container?: any; props?: any;}> = ({container, ...props}) => {
	const dispatchErr = useDispatchableError();

	const params = useParams();

	const contextLadder = URLParamsToContextArray(params);

	useEffect(() => {
		if (isAppError(contextLadder)) {
			dispatchErr(contextLadder);
		}
	}, [contextLadder, dispatchErr]);

	return (
		<FlexContainer
			style={{height: container ? container : '100%', gridArea: 'panel'}}
			alignContent='center'
			flexDirection='column'
			justifyItems='flex-start'
			justifyContent='flex-start'
			alignItems='center'
			gap={'.5rem'}>
			{getUI(contextLadder)}
		</FlexContainer>
	);
};

export default CommentsTab;
