import FlexContainer from '../../../common/FlexContainer';
import {FormResults} from '../../../common/Form';
import Heading from '../../../common/text/Heading';
import Typography from '../../../common/text/Typography';
import {usePersistEntityMutation} from '../../api';
import React, {FunctionComponent, useCallback} from 'react';
import {
    PersistEntityFormValues,
    PersistEntityFormParams
} from "./persistEntityTypes";
import {SubmitHandler, useForm} from "react-hook-form";
import AppModal from "../../../common/modals/AppModal";
import {useAppDispatch} from "../../../app/hooks";
import useModalType from "../../HUD/hooks/useModalType";
import {setModalType} from "../../HUD/state/HUDSlice";
import {PrependAttrFormValues} from "../prependAttribute/prependAttributeTypes";

export const persistEntityFormDefaults = {
    contentType: 'text/plain',
    frequency: '',
    cronString: '*****',
    firstLoadAt: '',
    catalogId: 0,
};

const PersistEntityForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
	const {objectId: _id, plural: label, canEdit} = modalProps;

    const {
        handleSubmit,
        register,
        formState
    } = useForm<PersistEntityFormValues>(
        {
            defaultValues: persistEntityFormDefaults,
        }
    );

    const [persist, mutationResults] = usePersistEntityMutation();

    const validatingRegister = useCallback(
        (inputName: keyof PersistEntityFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const onSubmit: SubmitHandler<PersistEntityFormValues> = (vals) => {
         persist({
             entityId: _id,
             body: vals
         });
    };

    const onSuccessfulPersist = () => closeModal();

    return (
        <AppModal
            label={"Persist Entity"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit ? canEdit : true}>
            <Heading component="h5">
                <Typography>
                    Permanently save {label} to FutureModel database?
                </Typography>
            </Heading>
            <FlexContainer
                style={{width: '50%'}}
                justifyContent="space-between"
            />
            <FormResults
                {...mutationResults}
                validationErrors={{}}
                onSuccess={onSuccessfulPersist}
                onSuccessDelay={1500}
            />
        </AppModal>
    );
};

export default PersistEntityForm;
