import {
    selectModalType,
    setModalType
} from '../state/HUDSlice';
import { useSelector } from 'react-redux';
import {useAppDispatch} from "../../../app/hooks";

export const useModalType = () => {

    const appDispatch = useAppDispatch();

    return {
        modalType: useSelector(selectModalType).type,
        modalProps: useSelector(selectModalType).props,
        closeModal: () => appDispatch(setModalType({type: null, props: {}}))
    };
}

// export const useModalProps = () => useSelector(selectModalProps);

export default useModalType;
