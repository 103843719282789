import React, {
    FunctionComponent,
    useCallback,
    useState
} from 'react';
import {useParams} from 'react-router-dom';
import {
    useGetUserProfileQuery,
} from 'features/api';
import useCurrentUser from "../../authentication/hooks/useUser";
import {
    OptimizedContainer,
    StyledProfileBase,
    StyledProfileGridLayout
} from "../common/styledComponents";
import ProfileHeader from "./ProfileHeader";
import Typography from "../../../common/text/Typography";
import styled from 'styled-components';
import FlexContainer from "../../../common/FlexContainer";
import FollowButton from "../../../common/buttons/FollowButton";
import FollowingButton from "../../../common/buttons/FollowingButton";
import FollowersButton from "../../../common/buttons/FollowersButton";
import clsx from "clsx";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import theme from "../../../common/theme/theme";
import GridContainer from "../../../common/GridContainer";
import {BaseUser, UserModalTypes} from "../../authentication/types/userTypes";
import UserMenu from "../../../common/menus/UserMenu";
import SelfMenu from "../../../common/menus/SelfMenu";
import Heading from "../../../common/text/Heading";

const StyledTabList = styled(TabList)`
	list-style-type: none;
	display: flex;
	width: 100%;	
	align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
    gap: .5rem;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
	    margin-top: 2rem;
		align-items: center;
        justify-content: center;
	}
`;
// margin: ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(2)};
// border-bottom: 1px solid ${(p) => p.theme.palette.divider};

const StyledTab = styled(Tab)`
	padding: ${(p) => p.theme.spacing(1)};
	&:hover {
		cursor: pointer;
		background-color: ${(p) => p.theme.palette.cyan};
	}

	&.selected {
		border-bottom: 1px solid ${(p) => p.theme.palette.background.paper};
	}
`;

const StyledProfileItem = styled(GridContainer)`
	padding: ${(p) => p.theme.spacing(2)};
	margin: 0;
	width: 100%;
	align-items: center;
	justify-content: space-between;
`;

const StyledPlaceholder = styled(StyledProfileItem)`
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
`;

// TODO: Depending on how db formats datetime, we may want this to read
// user's preferred timezone and format accordingly.  Docs are here:
// https://date-fns.org/docs/Getting-Started
// const formatDateString = (dateString: string) =>
// 	format(new Date(dateString), 'yyyy-MM-dd');

const tabLabels = [
    'Domains',
    'Forum',
    'Questions',
    // 'Activity',
    'Credits',
];


const UserProfileHeader: FunctionComponent<{ onRefChange: any, isSelfProfile: boolean, user: BaseUser, openModal: (type: UserModalTypes | null) => void; }> = (
    {
        onRefChange,
        isSelfProfile,
        user,
        openModal
    }
) => {

    const isFollowing = user.userContext.interactions.following || isSelfProfile;
    const canEdit = user.userContext.permissions.edit
        || user.userContext.permissions.admin
        || isSelfProfile;

    const realName = `${user.firstName} ${user.lastName}`;

    return <FlexContainer
        ref={onRefChange}
        justifyContent={'flex-end'}
        alignItems={'flex-start'}
        style={{gridArea: 'head'}}>
        {/*// 	gap: '1rem'*/}
        <FlexContainer style={{
            display: 'flex',
            placeContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            // gap: '1rem'
        }}>
            <OptimizedContainer>
                <ProfileHeader
                    avatarUrl={user?.avatar?.medium?.url}
                    headingText={realName}
                    isOnline={user?.isOnline}
                    location={user?.location}
                    subheadText={user.username}
                    user={user}
                >
                    <FlexContainer
                        style={{marginTop: '1rem'}}
                        gap={'1rem'}
                        justifyContent='center'
                        alignItems='flex-end'>
                        <FollowingButton resource={user}/>
                        <FollowersButton resource={user}/>
                    </FlexContainer>
                </ProfileHeader>
            </OptimizedContainer>
                <FlexContainer style={{gridArea: 'panel'}}
                   alignContent='center'
                   flexDirection='column'
                   justifyItems='flex-start'
                   justifyContent='flex-start'
                   alignItems='center' gap={'.5rem'}>
                    {!isSelfProfile ? <FollowButton
                        objectAlreadyFollowed={isFollowing}
                        resource={user}
                    /> : null
                    }
                </FlexContainer>
        </FlexContainer>
        {isSelfProfile
            ? <SelfMenu user={user} canEdit={canEdit}/>
            : <UserMenu user={user} canEdit={canEdit}/>}
    </FlexContainer>
}
const UserProfile: FunctionComponent = () => {
    const {username} = useParams();
    const currentUser = useCurrentUser();
    const isSelfProfile = username === currentUser.username;

    const {isLoading, isFetching, data: response} = useGetUserProfileQuery({username: username ? username : ''});
    const user = response?.response

    // const { data: orgsData } = useGetOrgsQuery(
    // 	!!user ? { userId: user._id } : skipToken
    // 	);

    const [modalType, setModalType] = useState<UserModalTypes | null>(null);

    const [containerHeight, setContainerHeight] = useState<number>(300);
    const [selectedIdx, setSelectedIdx] = useState<number>(0);

    const onRefChange = useCallback((node: any) => {
        if (node) {
            // console.log("ref changed to:", node);
            const bounding = node.getBoundingClientRect();
            setContainerHeight(bounding.height);
        }
    }, []);

     if (isLoading || isFetching) {
        return (
            <StyledProfileBase>
                <StyledProfileGridLayout>
                    <StyledPlaceholder key="title">
                        <Heading component="h5">
                            Loading...
                        </Heading>
                    </StyledPlaceholder>
                </StyledProfileGridLayout>
            </StyledProfileBase>
        );
    }

    if (!!user) {

        return (
            <>
                <StyledProfileBase>
                    <StyledProfileGridLayout style={{paddingTop: '2rem'}}>
                        <UserProfileHeader
                            onRefChange={onRefChange}
                            openModal={setModalType}
                            isSelfProfile={isSelfProfile}
                            user={user}
                        />
                         <Tabs
                            style={{
                                width: '100%',
                                height: '100%',
                                gridArea: 'main',
                            }}
                            selectedIndex={selectedIdx}
                            onSelect={(idx) => setSelectedIdx(idx)}>
                            {/*<FlexContainer alignItems={'center'}>*/}
                                <StyledTabList>
                                    {tabLabels.map((label, i) => (
                                        <StyledTab className={clsx(i === selectedIdx && 'selected')} key={label}>
                                            <Typography variant='h6' color='oldManGray'>{label}</Typography>
                                        </StyledTab>
                                    ))}
                                </StyledTabList>
                            {/*</FlexContainer>*/}
                             <div style={{
                                maxHeight: `calc(100vh - ${containerHeight + theme.navHeight * 3}px)`,
                                width: '100%',
                                overflowY: 'auto'
                            }}>
                                {/*  {[*/}
                                {/*    {*/}
                                {/*        Panel: DomainPanel,*/}
                                {/*        args: {org: org, canEdit: canEdit}*/}
                                {/*    },*/}
                                {/*    {*/}
                                {/*        Panel: DomainPanel,*/}
                                {/*        args: {org: org, canEdit: canEdit}*/}
                                {/*    },*/}
                                {/*    {*/}
                                {/*        Panel: CommentsTab,*/}
                                {/*        args: {container: '100%'}*/}
                                {/*    },*/}
                                {/*    {Panel: QuestionsTab, args: {}},*/}
                                {/*    {*/}
                                {/*        Panel: GovernancePanel,*/}
                                {/*        args: {container: '100%'}*/}
                                {/*    },*/}
                                {/*    {Panel: ActivitiesTab, args: {}},*/}
                                {/*].map((Panel, i) => {*/}
                                {/*    return i === selectedIdx*/}
                                {/*        ? <TabPanel key={i}>*/}
                                {/*            <Panel.Panel props={Panel.args}/>*/}
                                {/*        </TabPanel>*/}
                                {/*        : <TabPanel key={i}/>;*/}
                                {/*})}*/}
                                <TabPanel/><TabPanel/><TabPanel/><TabPanel/>
                            </div>
                        </Tabs>
                    </StyledProfileGridLayout>
                </StyledProfileBase>
            </>
        );
    } else {
        return null;
    }
};

export default UserProfile;

//{/*<StyledScrollContainer>*/}
// {/*	<StyledTabList>*/}
// {/*		{[*/}
// {/*				{*/}
// {/*					Panel: React.Component,*/}
// {/*					// DomainPanel,*/}
// {/*					args: {}*/}
// {/*					// args: {orgId: activeOrg._id}*/}
// {/*				},*/}
// {/*				// {*/}
// {/*				//     Panel: CommentsTab,*/}
// {/*				//     args: {container: '100%'}*/}
// {/*				// },*/}
// {/*				// {*/}
// {/*				//     Panel: QuestionsTab,*/}
// {/*				//     args: {}*/}
// {/*				// },*/}
// {/*				// // <ActivitiesTab/>,*/}
// {/*				// {*/}
// {/*				//     Panel: GovernancePanel,*/}
// {/*				//     args: {container: '100%'}*/}
// {/*				// }*/}
// {/*			].map((Panel, i) => {*/}
// {/*				 return i === selectedIdx*/}
// {/*						? <Panel.Panel key={i} {...Panel.args} />*/}
// {/*						: null;*/}
// {/*				// <StyledTab className={clsx(i === selectedIdx && 'selected')} key={label}>*/}
// {/*				//     <Typography variant='caption2' color='oldManGray'>{label}</Typography>*/}
// {/*				// </StyledTab>*/}
// {/*			})}*/}
// {/*	</StyledTabList>*/}
// {/*</StyledScrollContainer>*/}
