import styled from 'styled-components';
import FlexContainer from "./FlexContainer";

const StyledFlexContainer = styled(FlexContainer)`
	gap: ${(p) => p.theme.spacing(0)} ${(p) => p.theme.spacing(2)};
	margin-top: ${(p) => p.theme.navHeight + p.theme.panelGutter * 2}px;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	height: 45vh;
	padding: ${(p) => p.theme.spacing(1)};
`;

export default StyledFlexContainer;
