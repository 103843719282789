import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useState
} from "react";
import {BasePaper} from '../Paper';
import styled from 'styled-components';
import {harvestErrors, NonInputLine, SubtleTextbox} from "../inputs/SubtleTextBox";
// import {UserInviteData} from "../../features/authentication/types/userTypes";
import {FormResults} from "../Form";
import useCurrentUser from "../../features/authentication/hooks/useUser";
import {
	useInviteUserMutation,
	useGetUserInvitesQuery,
	useGetUsersWaitingQuery,
	useWithdrawInviteMutation,
	useResendInviteMutation,
	useGrantAccessMutation
} from "../../features/api";
import {SubmitHandler, useForm} from "react-hook-form";
import FlexContainer from "../FlexContainer";
import Heading from "../text/Heading";
import {isValidEmail} from "../utils/typeGuards";
import {nanoid} from "@reduxjs/toolkit";
import AppModal from "./AppModal";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {
	BaseUser,
	UserInviteData
} from "../../features/authentication/types/userTypes";
import ResourceItem from "../List/ResourceItem";
import Typography from "../text/Typography";
import useModalType from "../../features/HUD/hooks/useModalType";

export const SourceBody = styled(BasePaper)`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: ${(p) => p.theme.palette.background.paper};
`

interface HUDProps {
	translucent?: boolean;
	borderRadius?: string;
	width?: string | number;
	height?: string | number;
}


	// box-shadow: ${(p) => p.theme.shadows[4]};
export const HUDModalBody = styled.div<HUDProps>`
	background: ${(p) =>
		p.theme.palette.background[
			p.translucent ? 'transparentDark' : 'paper'
		]};
		
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	color: ${(p) => p.theme.palette.lightBaby};
	width: 100%;
	height: calc(100vh - ${(p) => p.theme.navHeight * 4 + p.theme.panelGutter * 8}px);
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter * 4}px;
	position: absolute;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
	${(p) => p.theme.translucent}
`;

interface StyledModalHeaderProps {
	justifyContent?: string;
	borderBottom?: string;
	padding?: string;
}

	// padding: ${(p) => p.padding ? p.padding : p.theme.spacing(1, 2)};
export const ModalHeader = styled.header<StyledModalHeaderProps>`
	padding: 1rem;
    border-bottom: ${(p) => p.borderBottom ? p.borderBottom : `2px solid ${p.theme.palette.divider}`};
	display: flex;
	background: ${(p) => p.theme.palette.background['paper']};
	justify-content: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	justify-items: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	align-items: flex-start;
	align-content: flex-start;
	height: ${(p) => p.theme.navHeight};
	z-index: 10000;
`;

	// font-size: 1rem;
export const HUDModalHeaderText = styled.h1`
	display: flex;
	align-content: center;
	align-items: center;
	gap: 1rem;
	justify-content: flex-start;
	justify-items: flex-start;
`;

interface InviteFormValues {
	email: string;
}

// PopoverBaseProps
interface MembershipModalProps {
	isOpen: boolean;
	onClose: () => void;
	objectType: string;
}


const MembershipModal: FunctionComponent = () => {

	const {modalProps, closeModal} = useModalType();
	const {objectType} = modalProps

	const user = useCurrentUser();

	const {
		register,
		handleSubmit,
		formState,
		resetField,
		watch,
		reset,
	} = useForm<InviteFormValues>({
		defaultValues: { email: '' },
	});

	const [activeInvites, setActiveInvites] = useState<UserInviteData[]>();
	const [activeWaiting, setActiveWaiting] = useState<BaseUser[]>();
	const [updateKey, setUpdateKey] = useState(nanoid());

	const email = watch('email');

	const {data: invites, isSuccess} = useGetUserInvitesQuery(
		!!user && user._id ? {userId: user._id, perPage: 1000, page: 1} : skipToken
	);

	const {data: waiting, isSuccess: isWaitingSuccess} = useGetUsersWaitingQuery({
		perPage: 1000,
		page: 1
	});

	const [sendInvite, mutationResults] = useInviteUserMutation();
	const [resendInvite, resendMutationResults] = useResendInviteMutation();
	const [withdrawInvite, withdrawMutationResults] = useWithdrawInviteMutation();
	const [grantAccess, grantMutationResults] = useGrantAccessMutation();

	useEffect(() => {
		if (invites && isSuccess) {
			setActiveInvites(invites.items);
		}
	}, [invites, isSuccess]);

	useEffect(() => {
		if (waiting && isWaitingSuccess) {
			setActiveWaiting(waiting.items);
		}
	}, [waiting, isWaitingSuccess]);

	useEffect(() => {
		if (mutationResults.isSuccess) {
			setUpdateKey(nanoid());
		}
	}, [mutationResults.isSuccess]);


	// this is needed to update react-hook-form's default values with new
	// values from a successful PUT request.
	useEffect(() => {
		reset();
	}, [user, reset]);


	const validatingRegister = useCallback(
		(inputName: keyof InviteFormValues) =>
			register(inputName, {
				required: `${inputName} is required`,
			}),
		[register]
	);

	const onSendSubmit: SubmitHandler<InviteFormValues> = (formVals) => {
		sendInvite(formVals);
	};

	const onResendSubmit: SubmitHandler<{inviteId: number}> = (formVals) => {
		resendInvite(formVals);
	};

	const onWithdrawSubmit: SubmitHandler<{inviteId: number}> = (formVals) => {
		withdrawInvite(formVals);
	};

	const onGrantSubmit: SubmitHandler<{userId: number}> = (formVals) => {
		grantAccess(formVals);
	};

	if (activeInvites && activeWaiting) {
		return (
			<AppModal
				label={"Manage invitations"}
				isOpen={true}
				isDirty={Boolean(formState.dirtyFields.email)}
				onClose={closeModal}
				isForm={false}
				onSubmit={handleSubmit(onSendSubmit)}
				canEdit={false}>
				<FlexContainer justifyContent={'space-between'}>
					<Heading
						style={{padding: 0, margin: 0}}
						component="h3">
						Invite User
					</Heading>
					<Typography variant={'h5'} color={'primary'}>
						{user.invitesRemaining} invites remaining
					</Typography>
				</FlexContainer>
				<FlexContainer flexDirection={'column'} gap={'1rem'} alignItems='center'>
					<SubtleTextbox
						{...register('email', {
							required: 'Please enter a valid email address',
							validate: isValidEmail,
						})}
						defaultValue="Email"
						// error={validationErrs.email}
						// autoComplete="off"
						label="Email Address"
						onReset={() => resetField('email')}
						isDirty={Boolean(formState.dirtyFields.email)}
						{...validatingRegister('email')}
					/>
				</FlexContainer>

				<Heading style={{padding: 0, margin: 0}} component="h3">
					Pending
				</Heading>
				{!!activeInvites && activeInvites.filter(
					(invite) => !invite.isAccepted && !invite.isWithdrawn
				).map((invite) => {
					console.log(invite)
					return !!invite ? <ResourceItem
						key={invite._id}
						resource={invite.invited}
						canEdit={true}
						onClick={() => {}}
						onResend={() => onResendSubmit({inviteId: invite._id})}
						onWithdraw={() => onWithdrawSubmit({inviteId: invite._id})}
					/> : null
				})}

				<Heading style={{padding: 0, margin: 0}} component="h3">
					Accepted
				</Heading>
				{!!activeInvites && activeInvites.filter(
					(invite) => invite.isAccepted
				).map((invite) => {
					console.log(invite)
					return <ResourceItem
						key={invite._id}
						resource={invite.invited}
						canEdit={true}
						onClick={() => {}}
					/>
				})}

				<Heading style={{padding: 0, margin: 0}} component="h3">
					Requested
				</Heading>
				{activeWaiting.map((user: BaseUser) => {
					return <ResourceItem
						key={user._id}
						resource={user}
						canEdit={true}
						isWaiting={true}
						onClick={() => {}}
						onGrant={(e: any) => {
							onGrantSubmit({userId: user._id});
						}}
					/>
				})}

				<FormResults
					{...mutationResults}
					successMessage={`An invitation has been sent to ${email}.`}
				/>

			</AppModal>
		);
	} else {
		return null
	}
};

export default MembershipModal;
