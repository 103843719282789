import React, {FunctionComponent} from "react";
import Button from "./Button";
import Typography from "../text/Typography";

const SeeMoreButton: FunctionComponent<{ onClick: () => void}> = () => {
    return <Button style={{padding: '0 0 0 .5rem', margin: 0}}>
        <Typography variant='caption2'>
            See More
        </Typography>
    </Button>
};

export default SeeMoreButton;
