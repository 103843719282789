import { getDataCrumbKinds } from '../../helpers';
import { DataContextParams } from '../../types/navigationContextTypes';
import DataCrumb from './DataCrumb';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const DataCrumbsContainer = styled.nav`
	display: flex;
	gap: 0px;
	flex-direction: row;
	justify-items: center;
	justify-content: center;
	align-items: center;
	align-content: center;
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		display: none;
	}
`;



const DataCrumbs: FunctionComponent = () => {
	const params = useParams();
	console.log(params)

	const toRender = getDataCrumbKinds(params);
	console.log(toRender)

	return (
		<DataCrumbsContainer>
			{toRender.map((kind, i) => (
				<DataCrumb
					key={kind}
					kind={kind}
					isTip={i === toRender.length - 1}
				/>
			))}
		</DataCrumbsContainer>
	);
};

export default DataCrumbs;
