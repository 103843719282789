import { IconButtonBase } from 'common/buttons/IconButton';
import ImageAvatar, {PositionedImageAvatar} from 'common/avatars/ImageAvatar';
import Typography from 'common/text/Typography';
import { isEmpty } from 'common/utils/typeGuards';
import { Empty } from 'common/utils/typeUtils';
import {forwardRef, useState} from 'react';
import styled from 'styled-components';
import useCurrentUser from "../../../authentication/hooks/useUser";
import {PopoverBase} from "../../../../common/popovers/Popover";
import UserPopover from "../../../../common/popovers/UserPopover";
import MemberItem from "../../../../common/List/MemberItem";
import {BaseUser} from "../../../authentication/types/userTypes";

interface AvatarProps {
	onClick?: (e?: any) => void;
	onHover?: (e?: any) => void;
	onExit?: (e?: any) => void;
	initial: string;
	profileImageURL: Empty | string | "" | undefined;
	isOnline?: boolean;
	isProfile?: boolean;
	onHold?: boolean;
	resource?: any;
	children?: any
	styles?: any;
	width?: string;
	height?: string;
	avatarScale?: number;
	avatarPosition?: number;
	borderRadius?: string;
}


const StyledAvatar = styled.div<{borderRadius?: string, width?: string, height?: string}>`
  	position:relative;
  	display:inline-block;
	height: ${(p) => p.height ? p.height : '32px'};
  	width: ${(p) => p.width ? p.width : '32px'};
`

const PositionedAvatar = styled.div<{borderRadius?: string, width?: string, height?: string}>`
  	position:relative;
  	display:flex;
  	text-align: center;
  	vertical-align: middle;
  	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '100%'};
  	border: 3px solid ${(p) => p.theme.palette.darkBaby};
  	/* magic number alert: IconButtonBase sets button width/height to theme.spacing(4) when
 		button's size prop is 'sm'.  We want this button to be the same size. */
	height: ${(p) => p.height ? p.height : p.theme.spacing(4)};
	width: ${(p) => p.width ? p.width : p.theme.spacing(4)};
	min-width: 32px;
	min-height: 32px;
	overflow: hidden;
	background-color: ${(p) => p.theme.palette.slickRick};
	&:hover {
		border: 3px solid ${(p) => p.theme.palette.darkBaby};
		cursor: pointer;
	}
	&:focus-visible {
		outline: 0px solid ${(p) => p.theme.palette.primary.main};
	}
`

const StyledAvatarStatus = styled.span`
  width: 10px;
  height: 10px;
  background-color: #60b3a1;
  border: 1px solid #3e4354;
  position: absolute;
  bottom: 2%;
  right: 2%;
  border-radius: 100%;
`

const StyledLargeAvatarStatus = styled.span`
  width: 22px;
  height: 22px;
  background-color: #60b3a1;
  border: 3px solid #3e4354;
  position: absolute;
  bottom: 8%;
  right: 8%;
  border-radius: 100%;
`
  // border: 3px solid #3e4354;

const ProfileAvatar = forwardRef<HTMLButtonElement, AvatarProps>(
	({ resource, borderRadius, avatarPosition, avatarScale, onHold, width, height, styles, onHover, onExit, onClick, profileImageURL, initial, isProfile, isOnline = false , children}, ref) => {

		const currentUser = useCurrentUser();

		const isSelf = resource
			&& currentUser
			&& resource._object === 'User'
			&& currentUser._id === resource._id;

		let onlineStyle = isOnline
			? "avatar-online"
			: "avatar-offline";

		const baseComponent = isEmpty(profileImageURL)
			? (
			<IconButtonBase
				size="sm"
				fillColor="default"
				baseOpacity="100%"
				variant='transparent'
				className={onlineStyle}
				ref={ref as any}
				style={{
					...styles,
					objectFit: 'cover',
					borderRadius: '100%',
					cursor: 'pointer',
				}}
				onMouseEnter={onHover}
				onMouseLeave={() => onHold && onExit && onExit(onHold)}
				width={width}
				height={height}
				onClick={onClick}
				aria-label="user profile menu"
			>
				<Typography variant='h5'>
					{initial}
				</Typography>
			</IconButtonBase>
		) : !!avatarPosition || !!resource?.avatarPosition ? (
			<PositionedImageAvatar
				as="button"
				aria-label="user profile menu"
				src={profileImageURL as string}
				className={onlineStyle}
				style={{
					...styles,
					objectFit: 'cover',
					// borderRadius: borderRadius,
					cursor: 'pointer',
					right: !!avatarPosition ? `${avatarPosition}px` : !!resource?.avatarScale ? `${resource.avatarScale}px` : '0',
					position: !!avatarPosition || !!resource?.avatarPosition ? 'relative' : undefined
				}}
				backgroundSize={
					!!avatarScale
						? `${avatarScale}%`
						: !!resource?.avatarScale
						? `${resource.avatarScale}%`
						: 'cover'
				}
				width={width}
				onMouseEnter={onHover}
				onMouseLeave={() => onHold && onExit && onExit(onHold)}
				height={height}
				ref={ref as any}
				data-testid="image"
			/>) : (
			<ImageAvatar
				as="button"
				aria-label="user profile menu"
				src={profileImageURL as string}
				className={onlineStyle}
				style={{
					...styles,
					objectFit: 'cover',
					borderRadius: borderRadius,
					cursor: 'pointer'
				}}
				backgroundSize={
					!!avatarScale
						? `${avatarScale}%`
						: !!resource?.avatarScale
						? `${resource.avatarScale}%`
						: 'cover'
				}
				width={width}
				onMouseEnter={onHover}
				onMouseLeave={() => onHold && onExit && onExit(onHold)}
				height={height}
				ref={ref as any}
				data-testid="image"
			/>);

		return <>
			{!!avatarPosition || !!resource?.avatarPosition ? <PositionedAvatar
				style={{cursor: 'pointer',}}
				width={width}
				borderRadius={borderRadius}
				height={height}
				onClick={onClick}>
				{baseComponent}
				{!isSelf ? isOnline ? isProfile
					? <StyledLargeAvatarStatus />
					: <StyledAvatarStatus/> : null : null}
			</PositionedAvatar> : <StyledAvatar
				style={{cursor: 'pointer',}}
				width={width}
				height={height}
				onClick={onClick}>
				{baseComponent}
				{!isSelf ? isOnline ? isProfile
					? <StyledLargeAvatarStatus />
					: <StyledAvatarStatus/> : null : null}
			</StyledAvatar>}
			{children}
		</>
	});

export default ProfileAvatar;
