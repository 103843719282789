/**
 * Adapted from Material UI Theme object
 * Docs: https://mui.com/customization/theming/
 * Github: https://github.com/mui-org/material-ui
 *
 * Colors from https://app.zeplin.io/project/60e3c8232b38181235af7323/styleguide
 *
 * Values that correspond to values from Zeplin are noted
 * in a comment by a triple-dash, like this:
 *  // ---someValue---
 */

const primary = '#60b3a1';
const secondary = '#6c8aec';
const black = '#121520';
const darkerBaby = '#29323c';
const darkBaby = '#4f566b';
const oldManGray = '#c1c9d2';
const lightBaby = '#f9fbfd';
const drapeGray = '#3a414c';
const slickRick = '#3e4354';
const magenta = '#CC3366';
const magenta2 = '#ff6d91';
const cyan = '#6a7485';
const danger = magenta;
const warning = '#fad776';

interface ThemeSpacing {
	(a: number, b?: number, c?: number, d?: number): string;
}

type SpacingUnit = 'px' | 'em' | 'rem' | 'vh' | 'vw';

const alphaHexMap = {
	'100': 'FF',
	'99': 'FC',
	'98': 'FA',
	'97': 'F7',
	'96': 'F5',
	'95': 'F2',
	'94': 'F0',
	'93': 'ED',
	'92': 'EB',
	'91': 'E8',
	'90': 'E6',
	'89': 'E3',
	'88': 'E0',
	'87': 'DE',
	'86': 'DB',
	'85': 'D9',
	'84': 'D6',
	'83': 'D4',
	'82': 'D1',
	'81': 'CF',
	'80': 'CC',
	'79': 'C9',
	'78': 'C7',
	'77': 'C4',
	'76': 'C2',
	'75': 'BF',
	'74': 'BD',
	'73': 'BA',
	'72': 'B8',
	'71': 'B5',
	'70': 'B3',
	'69': 'B0',
	'68': 'AD',
	'67': 'AB',
	'66': 'A8',
	'65': 'A6',
	'64': 'A3',
	'63': 'A1',
	'62': '9E',
	'61': '9C',
	'60': '99',
	'59': '96',
	'58': '94',
	'57': '91',
	'56': '8F',
	'55': '8C',
	'54': '8A',
	'53': '87',
	'52': '85',
	'51': '82',
	'50': '80',
	'49': '7D',
	'48': '7A',
	'47': '78',
	'46': '75',
	'45': '73',
	'44': '70',
	'43': '6E',
	'42': '6B',
	'41': '69',
	'40': '66',
	'39': '63',
	'38': '61',
	'37': '5E',
	'36': '5C',
	'35': '59',
	'34': '57',
	'33': '54',
	'32': '52',
	'31': '4F',
	'30': '4D',
	'29': '4A',
	'28': '47',
	'27': '45',
	'26': '42',
	'25': '40',
	'24': '3D',
	'23': '3B',
	'22': '38',
	'21': '36',
	'20': '33',
	'19': '30',
	'18': '2E',
	'17': '2B',
	'16': '29',
	'15': '26',
	'14': '24',
	'13': '21',
	'12': '1F',
	'11': '1C',
	'10': '1A',
	'9': '17',
	'8': '14',
	'7': '12',
	'6': '0F',
	'5': '0D',
	'4': '0A',
	'3': '08',
	'2': '05',
	'1': '03',
	'0': '00',
};

const toType = function(obj: any): string {
	if (obj === null || obj === undefined) {
		return 'unknown'
	} else {
	  	// @ts-ignore
		return Object.prototype.toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
	}
};

const getKeys = function(query: string, dictionary: object) {
	return Object.keys(dictionary).filter(function (key) {
	  return !!~key.indexOf(query);
  });
};

const getHexTransparent = (transparency: number | string): string | null => {
	return toType(transparency) === 'string' || toType(transparency) === 'number'
		? getKeys(transparency.toString(), alphaHexMap)[0]
		: null
};

export const makeThemeSpacingFunction =
	(factor: number, spacingUnit: SpacingUnit): ThemeSpacing =>
	(requiredSpacingValue, ...optionalSpacingValues) => {
		const singleSpace = ' ';

		const makeSingleSpacingComponent = (spacingValue: number) =>
			`${factor * spacingValue}` + spacingUnit;

		let spacingString = makeSingleSpacingComponent(requiredSpacingValue);

		optionalSpacingValues.forEach((space) => {
			if (typeof space === 'number') {
				spacingString +=
					singleSpace + makeSingleSpacingComponent(space);
			}
		});

		return spacingString;
	};

/**
 * MUI recommends a factor of 8px for calculating spacing
 * https://material.io/design/layout/understanding-layout.html
 */
const spacing = makeThemeSpacingFunction(8, 'px');

const Translucent = "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {-webkit-backdrop-filter: blur(1.5rem); backdrop-filter: blur(1.5rem);}";

const UnselectableMixin = `
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
`;

const TabularFamily = 'SFMonoPro,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';
const HeadingFamily = 'SFPro,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';
const ContentFamily = 'SFPro,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

const theme = {
	navHeight: 40,
	// Width of collapsible side panel
	drawerWidth: 200,
	sideControllerWidth: 32,
	// width of gutters between and around panels in the HUD
	panelGutter: 8,
	spacing,
	breakpoints: {
		keys: ['xs', 'sm', 'md', 'lg', 'xl'] as const,
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1250,
			xl: 1536,
		},
		unit: 'px',
	},
	direction: 'ltr',
	palette: {
		oldManGray: oldManGray,
		lightBaby: lightBaby,
		darkBaby: darkBaby,
		darkerBaby: darkerBaby,
		slickRick: slickRick,
		drapeGray: drapeGray,
		magenta: magenta,
		black: black,
		cyan: cyan,
		mode: 'light',
		primary: {
			// '50': '#F0F7FF',
			// '100': '#C2E0FF',
			// '200': '#A5D8FF',
			// '300': '#66B2FF',
			// '400': '#3399FF',
			// '500': '#007FFF',
			// '600': '#0072E5',
			// '700': '#0059B2',
			// '800': '#004C99',
			// '900': '#003A75',
			// ---Primary---
			main: primary,
			light: primary,
			dark: primary,
			// light: '#66B2FF',
			// dark: '#0059B2',
			// contrastText: '#fff',
		},
		// ---Dark Baby---
		divider: `${darkBaby}55`,
		primaryDark: {
			A50: '#E2EDF8',
			A100: '#CEE0F3',
			A200: '#91B9E3',
			A300: '#5090D3',
			A400: '#265D97',
			A500: '#1E4976',
			A600: '#173A5E',
			A700: '#132F4C',
			A800: '#bea5ff',
			A900: '#6c8aec',
			main: '#8cb4ff',
			fade: '#9e5265',
		},
		common: {
			// ---Black---
			black: black,
			// ---Slick Rick---
			white: lightBaby,
		},
		text: {
			// ---Black---
			primary: darkerBaby,
			paper: oldManGray,
			light: darkerBaby,
			dark: oldManGray,
			secondary: '#46505A',
			disabled: "#FFFFFF5C",
		},
		grey: {
			A50: '#F3F6F9',
			A100: '#EAEEF3',
			A200: '#E5E8EC',
			A300: '#D7DCE1',
			A400: '#BFC7CF',
			A500: '#AAB4BE',
			A600: '#7F8E9D',
			A700: '#46505A',
			A800: '#2F3A45',
			A900: '#20262D',
			// A100: '#f5f5f5',
			// A200: '#eeeeee',
			// A400: '#bdbdbd',
			// A700: '#616161',
		},
		error: {
			A50: '#FFF0F1',
			A100: '#FFDBDE',
			A200: '#FFBDC2',
			A300: '#FF99A2',
			A400: '#FF7A86',
			A500: '#FF505F',
			A600: '#EB0014',
			A700: '#C70011',
			A800: '#94000D',
			A900: '#570007',
			// ---Danger---
			lightest: '#9E5265',
			lighter: '#ff97a0',
			light: '#ff6d91',
			main: '#CC3366',
			dark: '#C70011',
			contrastText: '#fff',
		},
		success: {
			A50: '#E9FBF0',
			A100: '#C6F6D9',
			A200: '#9AEFBC',
			A300: '#6AE79C',
			A400: '#3EE07F',
			A500: '#21CC66',
			A600: '#1DB45A',
			A700: '#1AA251',
			A800: '#178D46',
			A900: '#0F5C2E',
			// ---Primary---
			main: primary,
			light: '#6AE79C',
			dark: '#1AA251',
			contrastText: '#fff',
		},
		warning: {
			A50: '#FFF9EB',
			A100: '#FFF4DB',
			A200: '#FFEDC2',
			A300: '#FFE4A3',
			A400: '#FFD980',
			A500: '#FCC419',
			A600: '#FAB005',
			A700: '#F1A204',
			A800: '#DB9A00',
			A900: '#8F6400',
			// ---Warning---
			main: warning,
			light: '#FFE4A3',
			dark: '#F1A204',
			contrastText: 'rgba(0, 0, 0, 0.87)',
		},
		secondary: {
			// ---Secondary---
			main: secondary,
			light: '#ba68c8',
			dark: '#7b1fa2',
			contrastText: '#fff',
		},
		app: {
			// ---Secondary---
			main: darkBaby,
			light: lightBaby,
			dark: darkBaby,
			contrastText: '#fff',
		},
		panel: {
			// ---Secondary---
			main: darkBaby,
			light: darkBaby,
			dark: slickRick,
			contrastText: '#fff',
		},
		info: {
			main: '#7C69EF',
			light: '#03a9f4',
			dark: '#01579b',
			contrastText: '#fff',
		},
		contrastThreshold: 3,
		tonalOffset: 0.2,
		background: {
			// ---Slick Rick---
			paper: slickRick,
			default: lightBaby,
			transparentLight: `${lightBaby}95`,
			transparentDark: slickRick + '99',
		},
		action: {
			active: 'rgba(0, 0, 0, 0.54)',
			hover: 'rgba(0, 0, 0, 0.04)',
			hoverOpacity: 0.04,
			selected: 'rgba(0, 0, 0, 0.08)',
			selectedOpacity: 0.08,
			disabled: 'rgba(0, 0, 0, 0.26)',
			disabledBackground: 'rgba(0, 0, 0, 0.12)',
			disabledOpacity: 0.38,
			focus: 'rgba(0, 0, 0, 0.12)',
			focusOpacity: 0.12,
			activatedOpacity: 0.12,
		},
	},
	shape: {
		borderRadius: 10,
	},
	unstable_strictMode: true,
	typography: {
		// X-height
		//
		// X-height refers to the height of the lowercase x for a typeface,
		// and it indicates how tall or short each glyph in a typeface will be.
		//
		// Typefaces with tall x-heights have better legibility at small font
		// sizes, as the white space within each letter is more legible.

		// Ascenders and descenders
		//
		// Ascenders are an upward vertical stroke found in certain lowercase
		// letters that extend beyond either the cap height or baseline.
		// Descenders are the downward vertical stroke in these letters. In
		// some cases, a collision between these strokes can occur when the
		// line height (the vertical distance between baselines) is too tight.

		// Weight
		//
		// Weight refers to the relative thickness of a font’s stroke. A
		// typeface can come in many weights; and four to six weights is a
		// typical number available for a typeface.

		// Serif
		// A serif is a small shape or projection that appears at the beginning
		// or end of a stroke on a letter. Typefaces with serifs are called
		// serif typefaces. Serif fonts are classified as one of the following:

		// Old-Style serifs resemble writing in ink, with:
		// Low contrast between thick and thin strokes
		// Diagonal stress in the strokes
		// Slanted serifs on lower-case ascenders

		// Transitional serifs have:
		// High contrast between thick and thin strokes
		// Medium-High x-height
		// Vertical stress in the strokes
		// Bracketed serifs

		// Didone or neoclassical serifs have:
		// Very high contrast between thick and thin strokes
		// Vertical stress in the strokes
		// “Ball” terminal strokes.

		// Slab serifs have:
		// Heavy serifs with imperceptible differences between the stroke weight
		// Minimal or no bracketing

		// 1. EB Garamond, old-style serif
		// 2. Libre Baskerville, transitional serif
		// 3. Libre Bodoni, didone / neoclassical serif
		// 4. Bitter, slab serif

		// Sans Serif
		// A typeface without serifs is called a sans serif typeface, from the
		// French word “sans” that means "without." Sans serifs can be
		// classified as one of the following:

		// Grotesque: Low contrast between thick and thin strokes, vertical or
		// no observable stress

		// Humanist: Medium contrast between thick and thin strokes, slanted
		// stress

		// Geometric: Low contrast between thick and thin strokes, with
		// vertical stress, and circular round forms

		// 1. Work Sans, grotesque sans serif
		// 2. Alegreya Sans, humanist sans serif
		// 3. Quicksand, geometric sans serif

		// Monospace
		// Monospace typefaces display all characters with the same width.

		// 1. Roboto Mono, monospace
		// 2. Space Mono, monospace
		// 3. VT323, monospace

		// Handwriting
		// Handwriting typefaces are unconventional with a natural, handwritten
		// feel. These typically are used as H1 - H6 in your type scale. They
		// come in the following forms:
		//
		// Black letter: High contrast, narrow, with straight lines and angular
		// curves
		// Script: Replication of calligraphic styles of writing (more formal)
		// Handwriting: Replication of handwriting (less formal)

		// 1. UnifrakturMaguntia, black letter
		// 2. Dancing Script, script
		// 3. Indie Flower, handwriting

		// Display
		// A miscellaneous category for all classification types that are only
		// suitable for use at large point sizes. Display fonts typically are
		// used as H1 - H6 in your type scale.

		// 1. Shrikhand, display
		// 2. Chewy, display
		// 3. Faster One, display

		// Readability
		// While legibility is determined by the characters in a typeface,
		// readability refers to how easy it is to read words or blocks of
		// text, which is affected by the style of a typeface.

		// Letter-spacing
		// Letter-spacing, also called tracking, refers to the uniform
		// adjustment of the space between letters in a piece of text.

		// Larger type sizes, such as headlines, use tighter letter-spacing to
		// improve readability and reduce space between letters.

		// For smaller type sizes, looser letter spacing can improve
		// readability as more space between letters increases contrast between
		// each letter shape. Text in all caps, even at small type sizes, has
		// improved readability because of its added letter spacing.

		// Tabular figures
		// Use tabular figures (also known as monospaced numbers), rather than
		// proportional digits, in tables or places where values may change
		// often.

		// Tabular figures keep values optically aligned for better scanning.

		// Line length
		// Line lengths for body text are usually between 40 to 60 characters.
		// In areas with wider line lengths, such as desktop, longer lines that
		// contain up to 120 characters will need an increased line height from
		// 20sp to 24sp.

		// The ideal line length is 40-60 characters per line for English
		// body text.

		// The ideal line length for short lines of English text is 20-40
		// characters per line.

		// Line height
		// Line height, also known as leading, controls the amount of space
		// between baselines in a block of text. A text’s line height is
		// proportional to its type size.

		// Paragraph spacing
		// Keep paragraph spacing in the range between .75x and 1.25x of the
		// type size.

		// Type size 20sp, line-height 30dp, paragraph spacing 28dp

		// Type alignment
		//
		// Type alignment controls how text aligns in the space it appears.
		// There are three type alignments: Left-aligned text is the most
		// common setting for left-to-right...
		//
		// Type alignment controls how text aligns in the space it appears.
		// There are three type alignments:
		//
		// Left-aligned: when text is aligned to the left margin
		// Right-aligned: when text is aligned to the right margin
		// Centered: when text is aligned to the center of the area it is set in

		// Left-aligned
		// Left-aligned text is the most common setting for left-to-right
		// languages such as English.

		// Left-aligned text applied to body copy

		// Right-aligned
		// Right-aligned text is the most common setting for right-to-left
		// languages, such as Arabic and Hebrew.
		//
		// Left-to-right languages can use right-aligned text, though it is
		// best for distinguishing short typographic elements within a layout
		// (such as side notes), and is not recommended for long copy.
		// Right-aligned text applied to a side note

		// Centered
		// Centered text is best used to distinguish short typographic elements
		// within a layout (such as pull quotes), and is not recommended for
		// long copy.

		// System fonts
		// System fonts come pre-installed on your computer or device. They
		// typically have wide language support and no licensing costs for
		// developers. Using the system default font in your app font unites
		// the consistency of the platform with your app’s look and feel.
		// However, because they appear in many places on devices, they may
		// not stand out.

		// Using system fonts
		//
		// Native system typefaces should be used for large blocks of text and
		// any text below 14sp. Roboto is the default system font for Android.
		// For...
		//
		// Native system typefaces should be used for large blocks of text and
		// any text below 14sp.
		//
		// Roboto is the default system font for Android. For platforms outside
		// of Android and web products, use a system typeface that is preferred
		// on that platform. For example, iOS applications should use Apple’s
		// San Francisco font.

		// Length
		//
		// Word length can vary greatly across languages, even those that use
		// similar glyphs, such as English and German.
		//
		// Word length can vary greatly across languages, even those that use
		// similar glyphs, such as English and German.

		// English is often shorter than other European languages. For
		// instance, German has many compound words that are longer, requiring
		// more lines or line spacing.

		fontFamily:
			'"IBM Plex Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
		fontFamilyCode:
			'Consolas,Menlo,Monaco,Andale Mono,Ubuntu Mono,monospace',
		fontFamilyTagline:
			'"PlusJakartaSans-ExtraBold",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
		fontFamilySystem:
			'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
		htmlFontSize: 16,
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightSemiBold: 600,
		fontWeightBold: 700,
		fontWeightExtraBold: 800,
		// Headlines
		//
		// In the type scale, headlines span from a range of 1 through 6.
		// Headlines are the largest text on the screen, reserved for short,
		// important text or numerals.
		//
		// For headlines, you can choose an expressive font, such as a display,
		// handwritten, or script style. These unconventional font designs have
		// details and intricacy that help attract the eye.

		// Serif or sans serif typefaces work well for headlines, especially
		// at smaller sizes.
		h1: {
			fontSize: '1.625rem',
			lineHeight: '2rem',
			letterSpacing: '0.01375rem',
			fontWeight: 400,
			fontFamily: HeadingFamily,
			textCase: 'sentence',
		},
		h2: {
			fontSize: '1.375rem',
			lineHeight: '1.625rem',
			letterSpacing: '-0.01625rem',
			fontWeight: 400,
			fontFamily: HeadingFamily,
			textCase: 'sentence',
		},
		h3: {
			fontSize: '1.0625rem',
			lineHeight: '1.375rem',
			letterSpacing: '-0.026875rem',
			fontWeight: 400,
			fontFamily: HeadingFamily,
			textCase: 'sentence',
		},
		h4: {
			fontSize: '0.9375rem',
			lineHeight: '1.25rem',
			letterSpacing: '-0.014375rem',
			fontWeight: 400,
			fontFamily: HeadingFamily,
			textCase: 'sentence',
		},
		h5: {
			fontSize: '0.8125rem',
			lineHeight: '1rem',
			letterSpacing: '-0.005rem',
			fontWeight: 700,
			fontFamily: HeadingFamily,
			textCase: 'sentence',
		},
		h6: {
			fontSize: '0.6875rem',
			lineHeight: '0.875rem',
			letterSpacing: '0.00375rem',
			fontWeight: 400,
			fontFamily: HeadingFamily,
			textCase: 'sentence',
		},
		subtitle: {
			// Subtitles are smaller than headlines. They are typically
			// reserved for medium-emphasis text that is shorter in length.
			// Serif or sans serif typefaces work well for subtitles.
			fontSize: '0.6875rem',
			lineHeight: '0.875rem',
			letterSpacing: '0.00375rem',
			fontWeight: 400,
			fontFamily: ContentFamily,
			textCase: 'sentence',
		},
		// Body text comes in ranges 1-2, and it’s typically used for long-form
		// writing as it works well for small text sizes. For longer sections
		// of text, a serif or sans serif typeface is recommended.
		body1: {
			fontSize: '0.8125rem',
			lineHeight: '1rem',
			letterSpacing: '-0.005rem',
			fontWeight: 400,
			fontFamily: ContentFamily,
			textCase: 'sentence',
		},
		body2: {
			fontSize: '0.8125rem',
			lineHeight: '1rem',
			letterSpacing: '-0.005rem',
			fontWeight: 400,
			fontFamily: ContentFamily,
			textCase: 'sentence',
		},
		// Button
		// Button text is a call to action used by different types of buttons
		// (such as text, outlined and contained buttons) and in tabs, dialogs,
		// and cards.

		// Button text can be sentence case, sans serif, or serif. Button text
		// is typically an all caps sans serif.

		// Caution
		// Use caution when having button text appear distinct from
		// non-interactive text, such as this upper lower, sans serif typeface
		// on a button.
		button: {
			fontSize: '0.625rem',
			lineHeight: '0.9375rem',
			letterSpacing: '0rem',
			fontWeight: 400,
			fontFamily: ContentFamily,
			textTransform: 'initial',
			textCase: 'allCaps',
		},
		input: {
			fontSize: '0.8125rem',
			lineHeight: '1rem',
			letterSpacing: '0.0075rem',
			fontWeight: 500,
			fontFamily: ContentFamily,
			// display: 'inline-block',
		},
		// Caption and overline
		// Caption and overline text (text with a line above it) are the
		// smallest font sizes. They are used sparingly to annotate imagery
		// or to introduce a headline.
		overline: {
			fontSize: '0.625rem',
			lineHeight: '0.9375rem',
			letterSpacing: '0rem',
			fontWeight: 400,
			fontFamily: ContentFamily,
			textTransform: 'uppercase',
			textCase: 'allCaps',
		},
		footnote: {
			fontSize: '0.625rem',
			lineHeight: '1rem',
			letterSpacing: '0.0075rem',
			fontWeight: 400,
			fontFamily: ContentFamily,
			display: 'inline-block',
			textCase: 'sentence',
		},
		caption1: {
			fontSize: '0.625rem',
			lineHeight: '1rem',
			letterSpacing: '0.0075rem',
			fontWeight: 400,
			fontFamily: ContentFamily,
			display: 'inline-block',
			textCase: 'sentence',
		},
		caption2: {
			fontSize: '0.625rem',
			lineHeight: '0.8125rem',
			letterSpacing: '0.0075rem',
			fontWeight: 500,
			fontFamily: ContentFamily,
			display: 'inline-block',
			textCase: 'sentence',
		},
		table: {
			fontSize: '0.625rem',
			lineHeight: '0.8125rem',
			letterSpacing: '0.0075rem',
			fontWeight: 500,
			fontFamily: TabularFamily,
			display: 'inline-block',
			textCase: 'sentence',
		}
	},
	nprogress: {
		color: '#007FFF',
	},
	props: {
		MuiBadge: {
			overlap: 'rectangular',
		},
	},
	translucent: Translucent,
	mixins: {
		unselectable: UnselectableMixin,
		toolbar: {
			minHeight: 56,
			'@media (min-width:0px) and (orientation: landscape)': {
				minHeight: 48,
			},
			'@media (min-width:600px)': {
				minHeight: 64,
			},
		},
	},
	shadows: [
		'none',
		'0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
		'0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
		'0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
		'0px 2px 3px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
		'0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
		'0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
		'0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
		'0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
		'0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
		'0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
		'0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
		'0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
		'0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
		'0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
		'0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
		'0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
		'0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
		'0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
		'0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
		'0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
		'0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
		'0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
		'0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
		'0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
	],
	shadowMap: {
		Paper: 4,
	},
	transitions: {
		easing: {
			easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
			easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
			easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
			sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
		},
		duration: {
			shortest: 150,
			shorter: 200,
			short: 250,
			standard: 300,
			complex: 375,
			enteringScreen: 225,
			leavingScreen: 195,
		},
	},
	zIndex: {
		graphScreen: 700,
		controlSurface: 800,
		navPanel: 900,
		modal: 1000,
		// appBar: 1100,
		drawer: 1200,
		// modal: 1300,
		snackbar: 1400,
		tooltip: 1500,
	},
};

export default theme;
