import { isNonNullObject } from './typeGuards';
import { DefaultTheme } from 'styled-components';

// type guards and convenience definitions
export const hasOwnProperty = <X extends {}, Y extends PropertyKey>(
	obj: X,
	prop: Y
): obj is X & Record<Y, unknown> => obj.hasOwnProperty(prop);

export const keyExists = <X extends {}, Y extends PropertyKey>(
	u: unknown,
	property: string
): u is X & Record<Y, unknown> =>
	isNonNullObject(u) ? (hasOwnProperty(u, property) ? true : false) : false;

/**
 * Assert that array of strings returned from Object.prototype.keys
 * contains only the keys specified in the object's type.
 */
export const typedKeys = <T extends {}>(o: T): Array<keyof T> =>
	Object.keys(o) as Array<keyof T>;

export interface NonEmptyStringBrand {
	readonly brand: unique symbol;
}

export type NonEmptyString = string & NonEmptyStringBrand;

export interface Tagged {
	readonly __tag: string;
}

export const EMPTY_TAG = '__EMPTY__';

export interface Empty extends Tagged {
	__tag: typeof EMPTY_TAG;
}

export const EMPTY: Empty = { __tag: EMPTY_TAG };

// Style-related types
export type FlexJustifyProperties =
	| 'space-between'
	| 'space-around'
	| 'flex-start'
	| 'flex-end'
	| 'center';

export type FlexDirectionProperties = 'column' | 'row';

export type FlexAlignmentProperties = 'flex-start' | 'flex-end' | 'center';

export type GridContainerProperties =
	| 'grid-template-columns'
	| 'grid-template-rows'
	| 'grid-template-areas'
	| 'grid-template'
	| 'column-gap'
	| 'row-gap'
	| 'grid-column-gap'
	| 'grid-row-gap'
	| 'gap'
	| 'grid-gap';

export type GridJustifyItemsProperties =
	| 'start'
	| 'end'
	| 'center'
	| 'stretch';

export type GridAlignItemsProperties =
	| 'start'
	| 'end'
	| 'center'
	| 'stretch';

export type GridJustifyContentProperties =
	//Sometimes the total size of your grid might be less than the size of its grid container. This could happen if all of your grid items are sized with non-flexible units like px. In this case you can set the alignment of the grid within the grid container. This property aligns the grid along the inline (row) axis (as opposed to align-content which aligns the grid along the block (column) axis).
	| 'start'
	// aligns the grid to be flush with the start edge of the grid container
	| 'end'
	// aligns the grid to be flush with the end edge of the grid container
	| 'center'
	// aligns the grid in the center of the grid container
	| 'stretch'
	// resizes the grid items to allow the grid to fill the full width of the
	// grid container
	| 'space-around'
	// places an even amount of space between each grid item, with half-sized
	// spaces on the far ends
	| 'space-between'
	// places an even amount of space between each grid item, with no space at
	// the far ends
	| 'space-evenly';
	// places an even amount of space between each grid item, including the
	// far ends


export type GridAlignContentProperties =
	// Sometimes the total size of your grid might be less than the size of
// its grid container. This could happen if all of your grid items are sized
// with non-flexible units like px. In this case you can set the alignment of
// the grid within the grid container. This property aligns the grid along
// the block (column) axis (as opposed to justify-content which aligns the
// grid along the inline (row) axis).
	| 'start'
	// aligns the grid to be flush with the start edge of the grid container
	| 'end'
	// aligns the grid to be flush with the end edge of the grid container
	| 'center'
	// aligns the grid in the center of the grid container
	| 'stretch'
	// resizes the grid items to allow the grid to fill the full height of the grid container
	| 'space-around'
	// places an even amount of space between each grid item, with half-sized spaces on the far ends
	| 'space-between'
	// places an even amount of space between each grid item, with no space at the far ends
	| 'space-evenly';
	// places an even amount of space between each grid item, including the far ends


export type ElementCSSState = 'hover';

export type StyledProps<T> = T & { theme: DefaultTheme };

export interface StyledPropsSelector<T> {
	(state?: ElementCSSState): (p: StyledProps<T>) => string;
}

export type RequireAtLeastOne<T, R extends keyof T = keyof T> = Omit<T, R> &
	{ [P in R]: Required<Pick<T, P>> & Partial<Omit<T, P>> }[R];

export type Nullable<T> = T | null | undefined;

// big thanks to https://stackoverflow.com/questions/41139763/how-to-declare-a-fixed-length-array-in-typescript
// for this one.
export type Tuple<
	T,
	N extends number,
	R extends readonly T[] = []
> = R['length'] extends N ? R : Tuple<T, N, readonly [T, ...R]>;

export const toStringIfNumber = (x: string | number) =>
	typeof x === 'number' ? x.toString() : x;
