import { AppDispatch } from '../../app/store';
import { pipe } from '../../common/utils/functionUtils';
import { resize, scroll } from './state/HUDSlice';
import { WindowResizePayload, WindowScrollPayload } from './types/HUDTypes';

/**
 * Debounce window callbacks to protect performance.
 */
export const makeWindowCallback = (updater: () => void, delay: number) => {
	let lastFire: number;
	let timerID: ReturnType<typeof setTimeout> | null = null;

	return () => {
		const currTime = Date.now();

		if (!lastFire) {
			lastFire = currTime;
		}

		const elapsed = currTime - lastFire;

		if (elapsed === 0 || elapsed >= delay) {
			if (timerID) {
				clearTimeout(timerID);
				timerID = null;
			}
			lastFire = Date.now();
			updater();
		} else {
			if (timerID) {
				clearTimeout(timerID);
				timerID = null;
			}
			timerID = setTimeout(() => {
				lastFire = Date.now();
				updater();
			}, delay - elapsed);
		}
	};
};

export const updateViewportDimensions = (appDispatch: AppDispatch) => () => {
	const nextVal: WindowResizePayload = {
		viewportHeight: window.innerHeight,
		viewportWidth: window.innerWidth,
	};

	pipe(nextVal, resize, appDispatch);
};

export const updateScrollPosition = (appDispatch: AppDispatch) => () => {
	const nextVal: WindowScrollPayload = {
		scrollX: window.scrollX,
		scrollY: window.scrollY,
	};

	pipe(nextVal, scroll, appDispatch);
};
