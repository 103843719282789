import {mergeQueryStates, parseQueryError} from '../../features/api/helpers';
import { QueryErrResult } from '../../features/api/types';
import Spinner from '../loading/Spinner';
import React, { FunctionComponent } from 'react';
import styled from "styled-components";
import Button from "./Button";
import Typography from "../text/Typography";
import TextButton from "./TextButton";
import {
	useFollowOrgMutation,
	useFollowResourceMutation, useUnfollowOrgMutation,
	useUnfollowResourceMutation
} from "../../features/api";
import {
	FollowId,
	FollowResourceParams
} from "../../features/authentication/types/userTypes";

export interface FollowButtonProps {
	resource: any;
	objectAlreadyFollowed: boolean;
	objectIdentifier?: string | null;
	error?: QueryErrResult;
}

//PaperButton
// width: 100%;
// height: 100%;
// padding: ${(p) => p.theme.spacing(1)};
const BaseFollowButton = styled(Button)`
	width: 8rem;
	height: 2rem;
	border: none;
	background-color: ${(p) => p.theme.palette.darkBaby};
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: .25rem;
	&:hover {
		background-color: ${(p) => p.theme.palette.darkBaby};
	}
`;

const FollowButton: FunctionComponent<FollowButtonProps> = ({
	resource,
	objectAlreadyFollowed,
	error,
}) => {


    const [follow, followResult] = useFollowResourceMutation();
    const [unfollow, unfollowResult] = useUnfollowResourceMutation();

    const objectType = !!resource.individual
		? 'Individual'
		: resource._object;

    const followPayload = !!resource.individual ? {
    	kind: 'newIndividualFollow',
		attributeId: resource.primaryAttrId,
		objectId: resource.primaryAttrId,
		primaryAttributeValue: resource.valueAtPrimaryAttr,
	} : {objectId: resource._id, kind: 'other'}

    const handleFollow = () => follow({
        objectIdentifier: followPayload as FollowId,
        objectType: objectType,
		body: {
        	objectIdentifier: followPayload as FollowId,
			objectType: objectType,
		}
    });

    const handleUnfollow = () => unfollow({
        objectIdentifier: followPayload as FollowId,
        objectType: objectType,
		body: {
        	objectIdentifier: followPayload as FollowId,
			objectType: objectType,
		}
    });

    const mergedMutationState = mergeQueryStates(followResult, unfollowResult);

	const onClick = () => {
		// Prevent user from doing anything while previous interaction is in-process
		if (mergedMutationState.isLoading) return null;
		if (objectAlreadyFollowed) return handleUnfollow();
		return handleFollow();
	};

	const renderContents = () => {
		// we presume loading/error will come from an RTK mutation; therefore
		// loading and error states are mutually exclusive.
		if (mergedMutationState.isLoading) return <Spinner />;
		if (error) return parseQueryError(error).message;

		if (objectAlreadyFollowed) {
			return <Typography variant={'h5'} color={'oldManGray'}>
				Unfollow
			</Typography>;
		}

		return <Typography variant={'h5'} color={'primary'}>
			Follow
		</Typography>;
	};

	return (
		<TextButton color='oldManGray' text='' onClick={onClick}>
			{renderContents()}
		</TextButton>
	);
};

export default FollowButton;
