import React, {FunctionComponent, useState} from "react";
import ProfileAvatar
    from "../../features/navigation/components/Navbar/ProfileAvatar";
import {ListItem, ListItemContent, ListItemControls} from "./index";
import Typography from "../text/Typography";
import Error from "../text/Error";
import styled from "styled-components";
import {
    BaseDomain,
    DomainModalTypes
} from "../../features/ontology/types/domainTypes";
import FlexContainer from "../FlexContainer";
import StyledSpan from "../StyledSpan";
import RouterLink from "../RouterLink";
import IconButton from "../buttons/IconButton";
import Button from "../buttons/Button";
import ViewsIcon from "../icons/ViewsIcon";
import MenuButton from "../buttons/MenuButton";
import ResourceLabel from "../text/ResourceLabel";
import {BaseEntity} from "../../features/ontology/types/entityTypes";
import DomainMenu from "../menus/DomainMenu";

export const StyledDomainListItem = styled(ListItem)`
	grid-template: [row1-start] "avatar domain entities views refreshed" 1fr [row1-end] 
	 / 35px max-content max-content auto auto;
	gap: 1rem;
	text-align: left;
	place-content: center;
	place-items: center;
	width: 100%;
	height: 4rem;
`;

//export const StyledDomainListItem = styled(ListItem)`
// 	grid-template:
// 	 [row1-start] "avatar domain entities views refreshed" 1fr [row1-end]
// 	 / auto 200px 200px 50px 50px;
// 	gap: 1rem;
// 	text-align: left;
// 	place-content:  space-between;
// 	place-items: space-between;
// 	width: 100%;
// `;

const Featuring: FunctionComponent<{ domain: BaseDomain, setPopover: (e: any, entity: BaseEntity) => void; }> = (
    {
        domain,
        setPopover
    }
) => {
    return <div style={{gridArea: 'entities', width: '100%'}}>
        <Typography variant='h6' id={`domain-${domain._id}-entities`}>
            {domain.entities && domain.entities
                .filter((o) => o.plural || o.singular || o.name)
                .map((entity, index) => {
                    return index === 0
                        ? <span
                            key={`entity-${entity._id}`}
                            style={{cursor: 'pointer'}}
                            onMouseEnter={(e: any) => setPopover(e, entity)}>
                             <RouterLink
                                 to={`/${entity.ontology?.org}/${entity.ontology?.domain}/${entity.name}`}>
                            <Typography variant='h6' color={'primary'}>
                                {entity.plural ? entity.plural : entity.name}
                            </Typography>
                             </RouterLink>
                        </span>
                        : <React.Fragment key={`entity-${entity._id}`}>
                            {', '}
                            <span
                                style={{cursor: 'pointer'}}
                                onMouseEnter={(e: any) => setPopover(e, entity)}>
                                 <RouterLink
                                     to={`/${entity.ontology?.org}/${entity.ontology?.domain}/${entity.name}`}>
                                <Typography variant='h6' color={'primary'}>
                                    {entity.plural ? entity.plural : entity.name}
                                </Typography>
                                 </RouterLink>
                            </span>
                        </React.Fragment>
                })}
            {domain.stats.entities > 2
                ? <span style={{cursor: 'pointer'}}>
                    <Typography variant='h6'>, and</Typography>
                    <Button>
                        <Typography variant='h6' color={'primary'}>
                            {`${domain.stats.entities - 2} ${domain.stats.entities - 2 === 1
                                ? 'other' : 'others'}`}
                        </Typography>
                    </Button>
            </span> : null}
        </Typography>
    </div>
}

const DomainItem: FunctionComponent<{ setPopover: (e: any, resource: any) => void, domain: BaseDomain, canEdit: boolean, onClick: any, handleError?: any, controls?: any, children?: any }> = (
    {
        domain,
        canEdit,
        onClick,
        handleError,
        controls,
        setPopover,
        children
    }) => {

    const [actionSubmenu, setActionSubmenu] = useState<string | null>(null);

    return <FlexContainer alignItems='center' justifyContent='space-between'>
        <StyledDomainListItem aria-labelledby={`domain-${domain._id}`}>
            <div style={{gridArea: 'avatar'}}
                 id={`domain-${domain._id}-avatar`}>
                <RouterLink to={`/${domain.ontology?.org}/${domain.name}`}>
                    <ProfileAvatar
                        onHover={onClick}
                        onClick={() => {
                        }}
                        initial={domain.label ? domain.label : domain.name}
                        profileImageURL={''}
                    />
                </RouterLink>
            </div>
            <div style={{gridArea: 'domain', width: '100%', cursor: 'pointer'}}
                 id={`domain-${domain._id}-name`}>
                <RouterLink to={`/${domain.ontology?.org}/${domain.name}`}>
                    <ResourceLabel onHover={onClick} resource={domain}/>
                </RouterLink>
            </div>
            <Featuring domain={domain} setPopover={setPopover}/>
            <div style={{gridArea: 'views', textAlign: 'right', width: '100%'}}>
                <Typography paragraph variant='h6'
                            id={`domain-${domain._id}-views`}>
                    {domain.stats.views}
                </Typography>
                {/*<IconButton*/}
                {/*    icon={ViewsIcon}*/}
                {/*    variant={'transparent'}*/}
                {/*    style={{position: 'relative', right: '.25rem'}}*/}
                {/*/>*/}
            </div>
            <div style={{
                gridArea: 'refreshed',
                textAlign: 'right',
                width: '100%'
            }}>
                <Typography paragraph variant='h6' color={'oldManGray'}>
                    {domain.lastModified}
                </Typography>
            </div>
            <Error resource={domain} handleError={handleError}/>
        </StyledDomainListItem>
        <DomainMenu domain={domain} canEdit={canEdit}/>
    </FlexContainer>;
}

export default DomainItem;
