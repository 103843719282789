import { FunctionComponent } from 'react';
import {
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading, AccordionItemPanel
} from "react-accessible-accordion";
import {ListItem, ListItemAvatar, ListItemContent} from "../../List";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQ, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import Typography from "../../text/Typography";
import AnswerList from "./AnswerList";

const Question: FunctionComponent<{question: any, expanded: any}> = ({question, expanded}) => {
    return (
        <AccordionItem uuid={question._id}>
            <AccordionItemHeading>
                <AccordionItemButton>
                    <ListItem button>
                        <ListItemAvatar>
                            <FontAwesomeIcon
                                icon={faQuestionCircle}
                            />
                        </ListItemAvatar>
                        <ListItemContent
                            truncate={expanded.every(
                                (v: number) => v !== question._id
                            )}
                        >
                            <Typography>
                                {question.content}
                            </Typography>
                        </ListItemContent>
                    </ListItem>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <AnswerList
                    answers={question.answers.items}
                    questionId={question._id}
                />
            </AccordionItemPanel>
        </AccordionItem>
    )
}

export default Question;
