import {FormResults} from '../../../common/Form';
import {useGetAttributesQuery, useUpdateEntityMutation} from '../../api';
import {emptyStringToNull} from '../common/helpers';
import {UpdateEntityFormValues} from './updateEntityTypes';
import React, {FunctionComponent, useCallback} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import SingularInput from "../../../common/inputs/SingularInput";
import PluralInput from "../../../common/inputs/PluralInput";
import DefinitionInput from "../../../common/inputs/DefinitionInput";
import useModalType from "../../HUD/hooks/useModalType";
import Heading from "../../../common/text/Heading";
import AboutInput from "../../../common/inputs/AboutInput";
import AttributeSelect from "../../../common/inputs/SelectAttribute";
import EntityTypeSelect from "../../../common/inputs/EntityTypeSelect";

const UpdateEntityForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
    const {resource, canEdit} = modalProps;

    const {register, handleSubmit, formState, resetField, reset} =
        useForm<UpdateEntityFormValues>({
            defaultValues: resource,
        });

    const validatingRegister = useCallback(
        (inputName: keyof UpdateEntityFormValues, options?: any) =>
            register(inputName, options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const {
		isLoading,
		isError,
		error,
		data: attrData,
		isUninitialized,
	} = useGetAttributesQuery({ entityId: resource._id });

    const [updateEntity, mutationResults] = useUpdateEntityMutation();

    const onSubmit: SubmitHandler<UpdateEntityFormValues> = (vals, e) => {
        e?.preventDefault();
        const body = {
            ...vals,
            definition: emptyStringToNull(vals.definition),
        };
        updateEntity({entityId: resource._id, body});
    };

    const onSuccessfulUpdate = () => closeModal();

    return (
        <AppModal
            label={"Describe Entity"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit}>
            <Heading style={{padding: 0, margin: 0}} component="h3">
                Profile Picture
            </Heading>
            <SingularInput
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            <PluralInput
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            <EntityTypeSelect
                formState={formState}
                resetField={resetField}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            <Heading style={{padding: 0, margin: 0}} component="h3">
                About
            </Heading>
            <AboutInput
                defaultValue={resource.definition !== undefined
                    ? resource.definition : null}
                validatingRegister={validatingRegister}
                formState={formState}
                resetField={resetField}
                mutationResults={mutationResults}
            />
            {!isLoading && !isUninitialized
                ? <>
                    <AttributeSelect selectId='primaryId' props={{
                    validatingRegister,
                    formState,
                    resetField,
                    mutationResults,
                    attrData,
                }} />
                <AttributeSelect selectId='startId' props={{
                    validatingRegister,
                    formState,
                    resetField,
                    mutationResults,
                    attrData,
                }} />

                <AttributeSelect selectId='endId' props={{
                    validatingRegister,
                    formState,
                    resetField,
                    mutationResults,
                    attrData,
                }} />
                <AttributeSelect selectId='generatedBy' props={{
                    validatingRegister,
                    formState,
                    resetField,
                    mutationResults,
                    attrData,
                }} />

                <AttributeSelect selectId='destroyedBy' props={{
                    validatingRegister,
                    formState,
                    resetField,
                    mutationResults,
                    attrData,
                }} />

                <AttributeSelect selectId='exampleId' props={{
                    validatingRegister,
                    formState,
                    resetField,
                    mutationResults,
                    attrData,
                }} />

                </>
                : null}
            <FormResults
                {...mutationResults}
                validationErrors={formState.errors}
                onSuccess={onSuccessfulUpdate}
                onSuccessDelay={1500}
            />
        </AppModal>
    );
};

export default UpdateEntityForm;
