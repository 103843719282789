import {
	StyledVizPaper,
	StyledSubmoduleContent,
	StyledSubmoduleHeader,
} from '../styledComponents';
import IDCell from './components/IDCell';
import {
	attrsToColumns,
	colsToGridCols,
	datumMatchesSearchTerm,
	attrToColumn,
} from './helpers';
import { CellMeta, ColumnMeta } from './types';
import { useAppDispatch } from 'app/hooks';
import RouterLink from 'common/RouterLink';
import { isNonEmptyString } from 'common/utils/typeGuards';
import { setDrawerIndividual } from 'features/HUD/state/HUDSlice';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useActiveIndividualsMeta from 'features/ontology/hooks/useActiveIndividualsMeta';
import { resolveAttrType } from 'features/ontology/typeGuards/attributeGuards';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';
import {
	ChangeEventHandler,
	FunctionComponent,
	useMemo,
	useState,
} from 'react';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';
import Typography from "../../../../../common/text/Typography";
import Textbox from "../../../../../common/inputs/TextBox";
import useViewModeParams from "../../../../navigation/hooks/useViewModeParams";
import {useParams} from "react-router-dom";

const StyledIDLink = styled(RouterLink)`
	&:hover {
		color: ${(p) => p.theme.palette.primary.main};
	}
`;

// 1. give every cell in header pos: sticky top: 0 z-Index: 1
// 2. Give 'id' header cell (only) pos: sticky, left: 0, and z-index: 2 (to be above other header cells)
// 3. Give left-column in body position: sticky, left: 0, z-index 1 (so that ID header cell floats above them)
const StyledCell = styled.td<CellMeta>`
	border-right: ${(p) =>
		p.showDivider
			? `1px solid ${p.theme.palette.divider}`
			: '0 none white'};
	padding: ${(p) => p.theme.spacing(1, 2)};
	text-align: ${(p) => (p.alignRight ? 'right' : 'left')};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	vertical-align: middle;
	&:hover {
		overflow: visible;
		white-space: normal;
		overflow-wrap: break-word;
	}
`;

const StyledHeaderCell = styled.th<CellMeta>`
	border-right: ${(p) =>
		p.showDivider
			? `1px solid ${p.theme.palette.divider}`
			: '0 none white'};
	padding: ${(p) => p.theme.spacing(1, 2)};
	text-align: ${(p) => (p.alignRight ? 'right' : 'left')};
	text-overflow: ellipsis;
	white-space: nowrap;
`;
	// max-width: 50px;

const StyledTableRow = styled.tr<{ cols: ColumnMeta[] }>`
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	display: grid;
	grid-template-columns: ${(p) => colsToGridCols(p.cols)};

	&:hover {
		background-color: ${(p) => p.theme.palette.darkerBaby};
		color: ${(p) => p.theme.palette.oldManGray};

		& .body-cell:first-child {
			background: ${(p) => p.theme.palette.darkerBaby};
			color: ${(p) => p.theme.palette.oldManGray};
		}
	}

	& .body-cell:first-child {
		position: sticky;
		left: 0;
		z-index: 1;
		background: ${(p) => p.theme.palette.background.paper};
	}
`;

const StyledTableHead = styled.thead`
	position: sticky;
	top: 0;
	z-index: 3;
`;

const StyledHeaderRow = styled.tr<{ cols: ColumnMeta[] }>`
	display: grid;
	grid-template-columns: ${(p) => colsToGridCols(p.cols)};
	background: ${(p) => p.theme.palette.background.paper};

	& .header-cell:first-child {
		position: sticky;
		left: 0;
		z-index: 2;
		background: ${(p) => p.theme.palette.background.paper};
	}
`;

const IndividualsModule: FunctionComponent = () => {
	const { preparedData, ...individualsLoadState } =
		useActiveIndividualsMeta();

	const appDispatch = useAppDispatch();

	const cols = useMemo(() => {
		if (preparedData) {
			const { attributes } = preparedData;

			const primaryId = attributes.find(
				(a) => resolveAttrType(a) === 'primaryIdentity'
			) as BaseAttribute;

			const hasSecondaryIds = attributes.some(
				(a) => resolveAttrType(a) === 'secondaryIdentity'
			);

			const colsMinusPrimaryId = attrsToColumns(preparedData.attributes);

			return [
				// if secondary Ids are present, don't treat primary id cell as last of its type
				attrToColumn(primaryId, !hasSecondaryIds),
				...colsMinusPrimaryId,
			];
		}

		return [] as ColumnMeta[];
	}, [preparedData]);

	const [filterTerm, setFilterTerm] = useState<string>('');

	const filtered = useMemo(() => {
		if (!preparedData) {
			return [];
		}

		if (isNonEmptyString(filterTerm)) {
			return preparedData.data.filter(datumMatchesSearchTerm(filterTerm));
		}

		return preparedData.data;
	}, [preparedData, filterTerm]);

	const debounced = useMemo(
		() =>
			debounce(
				1000,
				(term: string) => {
					setFilterTerm(term);
				},
				{ atBegin: false }
			),
		[]
	);

	const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		debounced(e.target.value);
	};

	const {username, domain, entity} = useParams();
	const {getViewMode} = useViewModeParams();
	const mode = getViewMode();

	return (
		<StyledVizPaper>
			<StyledSubmoduleHeader>
				<Textbox defaultValue='Search' name='individual-table-search' onChange={onChange} />
				<Typography variant='caption2'>
					{`${filtered.length} rows`}
				</Typography>
			</StyledSubmoduleHeader>
			<StyledSubmoduleContent style={{ overflow: 'auto' }}>
				<DisplayOnLoad {...individualsLoadState}>
					<table
						style={{
							display: 'inline-block',
							borderCollapse: 'collapse',
						}}
					>
						<StyledTableHead>
							<StyledHeaderRow cols={cols}>
								{/* NB: we assume first item of array will always be the primary ID item */}
								{cols.map((col, i) =>
									i === 0 ? (
										<IDCell
											{...col}
											showIcon={false}
											className="header-cell"
											key={col.name}
										>
											<Typography color='oldManGray' variant='h5'>
												{col.header}
											</Typography>
										</IDCell>
									) : (
										<StyledHeaderCell
											alignRight={col.alignRight}
											showDivider={col.showDivider}
											key={`header-${col.header}`}
											className="header-cell"
										>
											<Typography color='oldManGray' variant='h5'>
												{col.header}
											</Typography>
										</StyledHeaderCell>
									)
								)}
							</StyledHeaderRow>
						</StyledTableHead>

						<tbody>
							{filtered.map((datum, di) => (
								<StyledTableRow cols={cols} key={di}>
									{cols.map((col, ci) =>
										ci === 0 ? (
											<IDCell
												{...col}
												showIcon={true}
												onClick={() =>
													appDispatch(
														setDrawerIndividual({
															datum,
															attributes:
																preparedData
																	? preparedData.attributes
																	: [],
														})
													)
												}
												className="body-cell"
												key={`${di}-${ci}`}
											>
												<StyledIDLink
													preserveSearchParams={false}
													to={mode !== 'profile' ?
														`/${username}/${domain}/${entity}/${datum[col.name]}/${mode}`
														: `/${username}/${domain}/${entity}/${datum[col.name]}`
													}
												>
													<Typography color='oldManGray' variant='h6'>
														{datum[col.name]}
													</Typography>
												</StyledIDLink>
											</IDCell>
										) : (
											<StyledCell
												alignRight={col.alignRight}
												showDivider={col.showDivider}
												key={`${di}-${ci}`}
												className="body-cell"
											>
												<Typography color='oldManGray' variant='h6'>
													{datum[col.name]}
												</Typography>
											</StyledCell>
										)
									)}
								</StyledTableRow>
							))}
						</tbody>
					</table>
				</DisplayOnLoad>
			</StyledSubmoduleContent>
		</StyledVizPaper>
	);
};

export default IndividualsModule;
