import { StyledLabel, StyledIconContainer } from './styledComponents';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import TooltipBody from 'common/Tooltip';
import VisuallyHidden from 'common/VisuallyHidden';
import { forkRef } from 'common/utils/reactUtils';
import { forwardRef, HTMLAttributes } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import Typography from "../../text/Typography";
import theme from "../../theme/theme";

interface ToggleLabelProps extends HTMLAttributes<HTMLLabelElement> {
	label: string;
	isTouchingSlider: boolean;
	// isFirst: boolean;
	// isFinal: boolean;
	isCurrentlySelected: boolean;
	icon: IconProp;
	uniqueId: string;
	tooltip: string;
}

const ToggleLabel = forwardRef<HTMLLabelElement, ToggleLabelProps>(
	(
		{
			label,
			icon,
			uniqueId,
			isTouchingSlider,
			// isFirst,
			// isFinal,
			isCurrentlySelected,
			tooltip,
			...baseLabelProps
		},
		ref
	) => {
		const { setTriggerRef, getTooltipProps, setTooltipRef, visible } =
			usePopperTooltip({placement: 'right',});

		const combinedRef = forkRef(setTriggerRef, ref);

		return (
			<>
				<StyledLabel
					htmlFor={uniqueId}
					className={clsx(isCurrentlySelected && 'is-selected')}
					ref={combinedRef}
					{...baseLabelProps}
					isFinal={true}
					isFirst={true}
					data-testid={`slide-toggle-label-${label}`}
				>
					<VisuallyHidden>
						{label}
					</VisuallyHidden>
					<StyledIconContainer
						className={clsx('hide-divider')}
					>
						<FontAwesomeIcon {...{color: isCurrentlySelected
								? theme.palette.primary.main : ''}}
										 icon={icon} size="xs"/>
					</StyledIconContainer>
				</StyledLabel>
				{visible && (
					<TooltipBody {...getTooltipProps()} ref={setTooltipRef}>
						<Typography variant='caption2'>
							{tooltip}
						</Typography>
					</TooltipBody>
				)}
			</>
		);
	}
);

export default ToggleLabel;
