import React, {
	FunctionComponent,
	ReactNode,
	useCallback,
	useEffect
} from "react";
import theme from "../theme/theme";
import Modal from "./Modal";
import ReactModal from "react-modal";
import StyledSquare from "../StyledSquare";
import Typography from "../text/Typography";
import IconButton, {IconButtonBase} from "../buttons/IconButton";
import {faInfo, faInfoCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import {BasePaper} from '../Paper';
import styled from 'styled-components';
import Button from "../buttons/Button";
import FlexContainer from "../FlexContainer";
// import {harvestErrors, NonInputLine, SubtleTextbox} from "../inputs/SubtleTextbox";
// import SubtleSelect from "../inputs/SubtleSelect";
// import {FormResults} from "../Form";
// import useCurrentUser from "../../features/authentication/hooks/useUser";
// import {useGetOrgsQuery, useUpdateUserMutation} from "../../features/api";
// import {SubmitHandler, useForm} from "react-hook-form";
// import {StyledInputContainer} from "../inputs/SubtleTextbox/styledComponents";
// import Heading from "../text/Heading";
// import SubtleTextArea from "../inputs/SubtleTextArea";
// import {Link} from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
// 	faFacebook,
// 	faGithub, faInstagram,
// 	faLinkedin
// } from "@fortawesome/free-brands-svg-icons";
// import ProfileAvatar from "../../features/navigation/components/Navbar/ProfileAvatar";

export const SourceBody = styled(BasePaper)`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: ${(p) => p.theme.palette.background.paper};
`

interface HUDProps {
	translucent?: boolean;
	borderRadius?: string;
	width?: string | number;
	height?: string | number;
}


// box-shadow: ${(p) => p.theme.shadows[4]};
export const HUDModalBody = styled.div<HUDProps>`
	background: ${(p) =>
		p.theme.palette.background[
			p.translucent ? 'transparentDark' : 'paper'
		]};
		
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	color: ${(p) => p.theme.palette.lightBaby};
	width: 100%;
	height: calc(100vh - ${(p) => p.theme.navHeight * 4 + p.theme.panelGutter * 6}px);
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter * 2}px;
	min-height: 200px;
	position: absolute;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
	${(p) => p.theme.translucent}
`;

interface StyledModalHeaderProps {
	justifyContent?: string;
	borderBottom?: string;
	padding?: string;
}

	// padding: ${(p) => p.padding ? p.padding : p.theme.spacing(1, 2)};
export const ModalHeader = styled.header<StyledModalHeaderProps>`
	padding: 1rem;
    border-bottom: ${(p) => p.borderBottom ? p.borderBottom : `2px solid ${p.theme.palette.divider}`};
	display: flex;
	background: ${(p) => p.theme.palette.background['paper']};
	justify-content: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	justify-items: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	align-items: flex-start;
	align-content: flex-start;
	height: ${(p) => p.theme.navHeight};
	z-index: 10000;
`;

const StyledGraphicContainer = styled.div`
	position: relative;
	height:100%;
	padding-right: 100%;
	width: 0;
	float: left;
`
const StyledGraphicSquare = styled.div`
	background: ${(p) => p.theme.palette.primary.main};
	width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
`;

const StyledGraphicCircle = styled(StyledGraphicSquare)`
	background: ${(p) => p.theme.palette.primary.main};
	width: 6rem;
    height: 6rem;
    border-radius: 50%;
    position: absolute;
    left: 0;
`;


	// font-size: 1rem;
export const HUDModalHeaderText = styled.h1`
	display: flex;
	align-content: center;
	align-items: center;
	gap: 1rem;
	justify-content: flex-start;
	justify-items: flex-start;
`;


interface ModalHeaderProps extends ReactModal.Props {
	handleClose: () => void;
	headingText: string;
}

const HUDModalHeader: FunctionComponent<ModalHeaderProps> = ({
	headingText,
	handleClose
}) => {
	return (
		<ModalHeader>
			<HUDModalHeaderText>
				<StyledSquare width={'2rem'} height={'2rem'}/>
				<Typography color={'oldManGray'} variant={'h1'}>
					{headingText}
				</Typography>
			</HUDModalHeaderText>
			<IconButton
				onClick={handleClose}
				icon={faTimes}
				aria-label="close source browser"
			/>
		</ModalHeader>
	);
};

const StyledButton = styled(Button)`
	margin-top: ${(p) => p.theme.spacing(1)};
`;

const AppModal: FunctionComponent<{id?: string, label: string, isForm?: boolean, isOpen: boolean, isDirty: boolean, onClose: () => void, onSubmit: (e?: any) => void, canEdit: boolean, children?: ReactNode}> = (
	{id, label, isForm=true, isOpen=false, isDirty, onSubmit, onClose, canEdit, children}
	) => {

	console.log(label)

	return (
		<Modal
			id={id}
			borderRadius={'.5rem'}
			isOpen={isOpen}
			shouldCloseOnOverlayClick={true}
			onRequestClose={onClose}
			contentLabel={label}
		>
			<FlexContainer
				flexDirection={'column'}
				style={{width: '100%', height: '100%', overflowY: 'hidden'}}>
				<HUDModalHeader
					handleClose={onClose}
					headingText={label}
					isOpen={isOpen}
				/>
				{
					isForm ?
						<form autoComplete={undefined} style={{width: '100%'}} onSubmit={onSubmit}>
							<HUDModalBody borderRadius={'.5rem'} className={'invisible-scroll'}>
								<FlexContainer flexDirection={'column'} gap={'1rem'} style={{padding: '1rem'}}>
									{children}
								</FlexContainer>
							</HUDModalBody>
							<StyledButton
								style={{
									position: 'absolute',
									bottom: '0',
									width: '100%',
									height: `${theme.navHeight}px`,
									borderRadius: '0 0 .5rem .5rem'
								}}
								disabled={!isDirty}>
								Submit
							</StyledButton>
						</form>
						: <HUDModalBody borderRadius={'.5rem'} className={'invisible-scroll'}>
							<FlexContainer flexDirection={'column'} gap={'1rem'} style={{padding: `${theme.spacing(3)} ${theme.spacing(2)}`}}>
								{children}
							</FlexContainer>
						</HUDModalBody>
				}
			</FlexContainer>
		</Modal>
	);
};

export default AppModal;
