import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";
import {isValidURL} from "../utils/typeGuards";


interface URLInputParams extends ActionFieldProps {
    name: string;
    label: string;
    tooltip: string;
}

// 'remoteUrl'
// 'Remote Url'
// "Remote repo URL"

const URLInput: FunctionComponent<URLInputParams> = (
    {
        name,
        label,
        tooltip,
        defaultValue,
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleTextbox
        {...validatingRegister(name, {
            value: '',
            validate: (v: any) =>
                isValidURL(v)
                    ? true
                    : 'please enter a url of form "http[s]:<address>"',
        })}
        showLabel
        type='url'
        key={tooltip}
        defaultValue={defaultValue}
        label={label}
        isDirty={Boolean(formState.dirtyFields[name])}
        onReset={() => resetField(name)}
        error={harvestErrors(
            name,
            formState,
            mutationResults
        )}
    />;
}

export default URLInput;
