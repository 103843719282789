import Typography from "./Typography";
import {FunctionComponent} from "react";
import Button from "../buttons/Button";

const PostedAgo: FunctionComponent<{resource: any}> = ({resource}) => {
    return resource.postedAgo ?
        <Button style={{padding: 0, margin: 0}}>
            <Typography color='oldManGray' variant="caption1">
                {resource.postedAgo}
            </Typography>
        </Button> : null;
}

export default PostedAgo;
