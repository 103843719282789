import styled from "styled-components";

const StyledCitizen = styled.img`
	margin: 0;
	padding: 0;

	@media (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		display: none;
	}
	${(p) => p.theme.mixins.unselectable}
`;

	// @media (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
	// 	justify-self: center;
	// }
export default StyledCitizen;
