import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
	useGetAttributesQuery,
	useGetDomainAttributesQuery,
	useGetDomainsQuery
} from 'features/api';
import { mergeQueryStates } from 'features/api/helpers';
import useActiveEntityData from 'features/ontology/hooks/useActiveEntityData';
import {BaseAttribute} from "../types/attributeTypes";
import {createSelector} from "@reduxjs/toolkit";


type QueryResponse = Omit<ReturnType<typeof useGetDomainsQuery>, 'refetch'>;

const selectActiveEntityAttributes = createSelector(
	(res: QueryResponse) => res.data,
	(data: QueryResponse['data']) => data
);


const useActiveEntityAttributes = (domainName?: string, entityName?: string) => {
	const { activeItem: activeEntity, ...entityLoadState } = useActiveEntityData(domainName, entityName);

	const { data: attributes, ...attributeLoadState } = useGetAttributesQuery(
		activeEntity ? { entityId: activeEntity._id } : skipToken,
		{
			selectFromResult: (res) => ({
				...res,
				activeItem: selectActiveEntityAttributes(
					res,
				) as [] | BaseAttribute[],
			}),
		}
	);

	const combinedLoadState = mergeQueryStates(entityLoadState, attributeLoadState);

	return {
		...combinedLoadState,
		attributes,
	};
};

export default useActiveEntityAttributes;
