import {BaseMetric} from '../../ontology/types/metricTypes';
import { emptyStringToNull } from '../common/helpers';
import {
	DescribeMetricFormValues,
	DescribeMetricPayload,
} from './describeMetricTypes';

export const describeMetricFormDefaults = (
	metric: BaseMetric
): DescribeMetricFormValues => ({
	label: metric.label,
	definition: metric.definition ?? '',
	type: metric.type,
});

export const describeMetricFormToPayload = (
	formValues: DescribeMetricFormValues
): DescribeMetricPayload => ({
	label: formValues.label,
	definition: emptyStringToNull(formValues.definition),
	type: formValues.type,
});
