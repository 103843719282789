import Typography from "./Typography";
import {FunctionComponent} from "react";

const Error: FunctionComponent<{resource: any, handleError: any}> = ({resource, handleError}) => {
    if (!!handleError[resource._id]) {
        return <Typography color="error">
            {handleError[resource._id]}
        </Typography>;
    }
    return null;
}

export default Error;
