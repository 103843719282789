import Button from '../../buttons/Button';
import FlexContainer from '../../FlexContainer';
import {ListItem, ListItemContent, ListItemAvatar,} from '../../List';
import SubtleTextArea from '../../inputs/SubtleTextArea';
import Typography from '../../text/Typography';
import theme from '../../theme/theme';
import SlideDown from '../../transitions/SlideDown';
import { useCreateQuestionMutation } from '../../../features/api';
import { parseQueryError } from '../../../features/api/helpers';
import QuestionContext from '../state/QuestionContext';
import { CreateQuestionFormValues } from '../types/questionTypes';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, useContext, useRef, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';

const QUESTION_PLACEHOLDER = 'Your question...';

const CreateQuestionForm: FunctionComponent = () => {
	const { parentObjectId, parentObjectType } = useContext(QuestionContext);

	const questionInputRef = useRef<HTMLTextAreaElement>();

	const nodeRef = useRef<HTMLElement>();

	const [formOpen, setFormOpen] = useState(false);

	const { register, resetField, formState, handleSubmit, reset } =
		useForm<CreateQuestionFormValues>({
			defaultValues: {
				question: QUESTION_PLACEHOLDER,
			},
		});

	const [createQuestion, createQuestionResult] = useCreateQuestionMutation();

	const onSubmit: SubmitHandler<CreateQuestionFormValues> = (vals) => {
		reset();
		createQuestion({
			body: {
				objectIdentifier: parentObjectId,
				objectType: parentObjectType,
				question: vals.question,
			},
		});
	};

	//  If there's a network error with a message, display that.  Otherwise,
	// display any form validation error messages.
	const questionError = () => {
		if (createQuestionResult.isError) {
			return parseQueryError(createQuestionResult.error).message;
		}

		return formState.errors.question?.message;
	};

	//  calling register outside the component it targets gives us access
	// to the component ref it exposes.
	const { ref: hookFormRef, ...inputProps } = register('question', {
		required: 'Content is required to submit a question',
		validate: (v) =>
			v === QUESTION_PLACEHOLDER
				? 'Content is required to submit a question'
				: true,
	});

	return (
		<div>
			<ListItem button onClick={() => setFormOpen((p) => !p)}>
				<ListItemAvatar>
					<FontAwesomeIcon
						icon={formOpen ? faMinus : faPlus}
					/>
				</ListItemAvatar>
				<ListItemContent>
					<Typography variant={'h5'} color={'oldManGray'}>
						Ask a new question
					</Typography>
				</ListItemContent>
			</ListItem>
			<CSSTransition
				in={formOpen}
				classNames="slide-down-transition"
				timeout={theme.transitions.duration.shortest}
				unmountOnExit
				nodeRef={nodeRef}
				onEntered={() => {
					if (questionInputRef.current) {
						questionInputRef.current.focus();
					}
				}}
			>
				<SlideDown ref={nodeRef as any}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<SubtleTextArea
							onReset={() => resetField('question')}
							isDirty={formState.isDirty}
							{...inputProps}
							ref={(el) => {
								hookFormRef(el);
								questionInputRef.current =
									el as HTMLTextAreaElement;
							}}
							style={{ resize: 'vertical' }}
							error={questionError()}
						/>

						<FlexContainer justifyContent="flex-end">
							<Button variant="outline" disabled={!formState.isDirty}>
								Submit
							</Button>
						</FlexContainer>
					</form>
				</SlideDown>
			</CSSTransition>
		</div>
	);
};

export default CreateQuestionForm;
