import { ColumnMeta } from './types';
import { flow } from 'common/utils/functionUtils';
import { isNumber, isString } from 'common/utils/typeGuards';
import { hasOwnProperty } from 'common/utils/typeUtils';
import {
	getSelectedSubtypes,
	groupAttrs,
	sortGroupedAttrs,
} from 'features/ontology/helpers/attributeHelpers';
import { isQuantity, isEvent } from 'features/ontology/typeGuards/attributeGuards';
import {
	CategoryAttribute,
	QuantityAttribute,
} from 'features/ontology/types/attributeTypes';
import { BaseAttribute } from 'features/ontology/types/attributeTypes';
import { Individual } from 'features/ontology/types/individualTypes';

export const coerceToString = (v: unknown) =>
	isString(v) ? v : isNumber(v) ? v.toString() : null;

export const datumMatchesSearchTerm = (term: string) => {
	const tester = new RegExp(term, 'i');

	return (datum: Individual) =>
		Object.values(datum).some((v) => {
			const coerced = coerceToString(v);

			if (coerced === null) {
				return false;
			}

			return tester.test(v);
		});
};

export const getMaxCharLength = (
	attribute: any,
	// lengthData: CategoryAttribute | QuantityAttribute
) => {
	if (attribute.type === 'category' || attribute.type === 'quantity' || attribute.type === 'identity') {
		if (attribute.profile && attribute.profile.results) {
			if (hasOwnProperty(attribute.profile.results, 'maxLength')) {
				const maybeMaxLength = attribute.profile.results.maxLength;
				return maybeMaxLength === null ? 1 : parseInt(maybeMaxLength, 10);
			}

			if (hasOwnProperty(attribute.profile.results, 'max')) {
				const maybeMax = String(attribute.profile.results.max);
				return maybeMax === null ? 1 : maybeMax.length;
			}
		}
	}
	return 16;
};

export const colToColString = (col: ColumnMeta, isPrimaryIdCol: boolean) =>
	isPrimaryIdCol
		? `${col.naiveWidth}px` : `clamp(100px, ${col.naiveWidth}px, 24ch)`;
		// : col.type === 'quantity'
		// ? `${col.naiveWidth}px`
		// ? `${col.singular.length}ch`
		// : `clamp(100px, ${col.naiveWidth}px, 24ch)`;

export const colsToGridCols = (cols: ColumnMeta[]) =>
	cols.map((col, i) => colToColString(col, i === 0)).join(' ');

// NB: intentionally EXCLUDE primay identity from column descriptors:
// we manually extract primary ID in component body to make sure it is always
// first in the list.
export const attrsToColumns = flow(
	groupAttrs('base'),
	getSelectedSubtypes([
		'ISODateString',
		'category',
		'quantity',
		'secondaryIdentity',
	]),
	sortGroupedAttrs,
	(sorted) =>
		sorted.reduce((acc, next) => {
			return acc.concat(
				next.attrs.map((a, i, arr) =>
					attrToColumn(a, i === arr.length - 1)
				)
			);
		}, [] as ColumnMeta[])
);

export const attrToColumn = (attr: BaseAttribute, isFinalOfType: boolean) => ({
	//  TODO: figure out best way to handle type discrimination for
	// attribute 'profile' property
	naiveWidth: Math.max((getMaxCharLength(attr as any) * 16 * 0.6875 + getMaxCharLength(attr as any) * 16 * 0.00375), (attr.singular.length * 16 * 0.8125 + attr.singular.length * 16 * -0.005)),
	alignRight: isQuantity(attr) || isEvent(attr),
	header: attr.singular,
	name: attr.name,
	showDivider: isFinalOfType,
	type: attr.type,
	singular: attr.singular
});
