import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetLiveDataCatalogsQuery, useGetLiveDataSetsQuery } from 'features/api';
import { mergeQueryStates } from 'features/api/helpers';
import useActiveSourceData from 'features/ontology/hooks/useActiveSourceData';
import useActiveCatalogData from 'features/ontology/hooks/useActiveCatalogData';

const useActiveCatalogDatasets = () => {
	const { activeItem: activeSource, ...sourceLoadState } = useActiveSourceData();
	 const {activeItem: activeCatalog } = useActiveCatalogData();

	const { data: catalogs, ...catalogLoadState } = useGetLiveDataCatalogsQuery(
		activeSource ? { sourceId: activeSource._id } : skipToken
	);

	const { data: datasets, ...datasetLoadState } = useGetLiveDataSetsQuery(
		!!activeSource && !!activeCatalog && activeSource._id !== undefined ?
			{
				catalogName: activeCatalog.name,
				sourceId: activeSource._id
			} : skipToken
	);

	const combinedLoadState = mergeQueryStates(catalogLoadState, datasetLoadState);

	return {
		catalogs,
		datasets,
		...combinedLoadState,
	};
};

export default useActiveCatalogDatasets;
