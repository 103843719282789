import IconButton from './IconButton';
import { List, ListItemContent, ListItem } from '../List';
import PopoverBase from '../popovers/Popover/PopoverBase';
import Typography from '../text/Typography';
import {
	useCreateOrgAdminMutation,
	useCreateOrgGovernorMutation,
	useCreateOrgEngineerMutation,
	useCreateOrgPublisherMutation,
	useCreateOrgMaintainerMutation,
	useCreateOrgReporterMutation,
	useCreateOrgContactMutation,
	useCreateOrgFunderMutation,
	useCreateOrgRightsHolderMutation,
	useCreateOrgMemberMutation
} from '../../features/api';
import { parseQueryError } from '../../features/api/helpers';
import { composeName } from '../../features/governance/helpers';
import useFilteredOrgMembers from '../../features/governance/hooks/useFilteredOrgMembers';
import { OrgAuthorityLevel } from '../../features/governance/types/GovernanceTypes';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { sortByString } from 'common/utils/functionUtils';
import { FunctionComponent, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';

const selectMutation = (authorityLevel: 'member' | 'admin' | 'governor' | 'engineer' | 'publisher' | 'maintainer' | 'contact' | 'reporter' | 'funder' | 'rightsHolder') => {
	switch (authorityLevel) {
		case 'admin':
			return useCreateOrgAdminMutation
		case 'governor':
			return useCreateOrgGovernorMutation
		case 'engineer':
			return useCreateOrgEngineerMutation
		case 'publisher':
			return useCreateOrgPublisherMutation
		case 'maintainer':
			return useCreateOrgMaintainerMutation
		case 'reporter':
			return useCreateOrgReporterMutation
		case 'contact':
			return useCreateOrgContactMutation
		case 'funder':
			return useCreateOrgFunderMutation
		case 'rightsHolder':
			return useCreateOrgRightsHolderMutation
		case 'member':
			return useCreateOrgMemberMutation
		default:
			return useCreateOrgMemberMutation;
	}
};

interface OrgPromoteButtonProps {
	authorityLevel: OrgAuthorityLevel;
	orgId: number;
}

const OrgPromoteButton: FunctionComponent<OrgPromoteButtonProps> = ({
	authorityLevel,
	orgId,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const combinedMembers = sortByString(
		useFilteredOrgMembers(orgId, authorityLevel),
		(usr) => usr.lastName ? usr.lastName : usr.firstName
	);

	const [menuOpen, setMenuOpen] = useState(false);

	const [promote, promotionResult] = selectMutation(
		authorityLevel as
			| 'member'
			| 'admin'
			| 'governor'
			| 'engineer'
			| 'publisher'
			| 'maintainer'
			| 'reporter'
			| 'contact'
			| 'funder'
			| 'rightsHolder'
	)();

	const renderMemberList = () => {
		if (combinedMembers.length === 0) {
			return <ListItem>
				No available users
			</ListItem>;
		}

		return (
			<>
				{combinedMembers.map((usr) => (
					<ListItem
						button
						key={usr._id}
						onClick={() => {
							if (promotionResult.isLoading) {
								return;
							}

							promote({ orgId, body: { userId: usr._id } });
						}}
					>
						{composeName(usr)}
					</ListItem>
				))}
				{promotionResult.isLoading && (
					<ListItem>
						<ListItemContent>
							<Typography color="primary">Updating...</Typography>
						</ListItemContent>
					</ListItem>
				)}
				{promotionResult.isError && (
					<ListItem>
						<ListItemContent>
							<Typography color="error">
								{parseQueryError(promotionResult.error).message}
							</Typography>
						</ListItemContent>
					</ListItem>
				)}
			</>
		);
	};

	return (
		<>
			<IconButton
				ref={setAnchorEl}
				icon={faPlus}
				tooltip={`add org ${authorityLevel}`}
				showTooltip={!menuOpen}
				aria-label={`add org ${authorityLevel}`}
				onClick={() => setMenuOpen((p) => !p)}
			/>
			<PopoverBase
				open={menuOpen}
				anchorEl={anchorEl}
				placement="bottom-start"
			>
				<ClickAwayListener onClickAway={() => setMenuOpen(false)}>
					<List style={{ maxHeight: '300px', overflowY: 'auto' }}>
						{renderMemberList()}
					</List>
				</ClickAwayListener>
			</PopoverBase>
		</>
	);
};

export default OrgPromoteButton;
