import { StyledPropsSelector } from '../utils/typeUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, IconName } from '@fortawesome/free-solid-svg-icons';
import { Placement } from '@popperjs/core';
import clsx from 'clsx';
import TooltipBody from 'common/Tooltip';
import Typography from 'common/text/Typography';
import { ColorProp } from 'common/text/Typography';
import { forkRef } from 'common/utils/reactUtils';
import { ButtonHTMLAttributes, CSSProperties, forwardRef } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import styled from 'styled-components';
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";

type SizeProp =
	| 'none'
	| 'xs'
	| 'sm'
	| 'lg';
	// | '1x'
	// | '2x'
	// | '3x'
	// | '4x'
	// | '5x'
	// | '6x'
	// | '7x'
	// | '8x'
	// | '9x'
	// | '10x';

type IconSizeProp =
	| 'xs'
	| 'sm'
	| 'lg'
	| '1x'
	| '2x'
	| '3x'
	| '4x'
	| '5x'
	| '6x'
	| '7x'
	| '8x'
	| '9x'
	| '10x';

const mapSizePropToWidthSpacing = (size: SizeProp) => {
	switch (size) {

		case 'none':
			return 0;

		case 'xs':
			return 2;

		case 'sm':
			return 4;

		case 'lg':
			return 4;

		default:
			return 4.5;
	}
};

const mapSizePropToHeightSpacing = (size: SizeProp) => {
	switch (size) {

		case 'none':
			return 2;

		case 'xs':
			return 2;

		case 'sm':
			return 4;

		case 'lg':
			return 4;

		default:
			return 4.5;
	}
};
// case '1x':
// 	return 9
//
// case '2x':
// 	return 13.5

type IconButtonBaseProps = Omit<IconButtonProps, 'icon' | 'overrides'>;

const buttonBackground: StyledPropsSelector<IconButtonBaseProps> =
	(state?) => (props) => {
		const {
			variant = 'transparent',
			theme: { palette },
		} = props;

		const baseColor = palette.darkerBaby;

		// May want some other color to represent transparency at
		// some point.
		const transparentColor = 'transparent';

		// On hover, add an alpha value to background to give it
		// some transparency.
		const colorOnHover = baseColor + '95';

		return state === 'hover'
			? colorOnHover
			: variant === 'base'
			? baseColor
			: transparentColor;
	};

const buttonShape: StyledPropsSelector<IconButtonBaseProps> = () => (props) => {
	const { shape = 'base', theme } = props;

	return shape === 'base' ? `${theme.spacing(0.5)}` : '50%';
};

const buttonBorder: StyledPropsSelector<IconButtonBaseProps> =
	() => (props) => {
		const { variant = 'base', shape = 'base' } = props;

		return variant === 'transparent' || shape === 'round' ? 'none' : 'none';
	};

const StyledIconContainer = styled.div`
	width: ${(p) => p.theme.spacing(1)};
	display: grid;
	align-content: center;
	margin: 0 ${(p) => p.theme.spacing(0.5)};
`;

export const IconButtonBase = styled.button<
	IconButtonBaseProps & {
		size: SizeProp;
		fillColor: ColorProp;
		baseOpacity: string;
		usingLabel?: boolean;
		width?: string;
		height?: string;
	}
>`
	border-radius: ${buttonShape()};
	background: ${buttonBackground()};
	border: ${buttonBorder()};
	padding: 0;
	width: ${(p) => p.width ? p.width : p.usingLabel 
	? p.theme.spacing(mapSizePropToWidthSpacing(p.size) + 0.5)
	: p.theme.spacing(mapSizePropToWidthSpacing(p.size) + 0.5)};
	height: ${(p) => p.height ? p.height : p.usingLabel
	? p.theme.spacing(mapSizePropToHeightSpacing(p.size)) 
	: p.theme.spacing(mapSizePropToHeightSpacing(p.size))};
	cursor: pointer;
	box-shadow: none;
	opacity: ${(p) => p.baseOpacity};
	color: ${(p) => {
		if (p.fillColor) {
			switch (p.fillColor) {
				case 'error':
					return p.theme.palette.error.main

				case 'warn':
					return p.theme.palette.warning.main

				case 'primary':
					return p.theme.palette.primary.main

				case 'darkBaby':
					return p.theme.palette.darkBaby

				case 'oldManGray':
					return p.theme.palette.oldManGray

				default:
					return p.theme.palette.oldManGray
			}
		} else {
			return p.theme.palette.oldManGray
		}
	}};

	transition: color ${(p) => p.theme.transitions.duration.shorter}ms linear;
	transition: opacity ${(p) => p.theme.transitions.duration.short}ms linear;
	transition: background ${(p) => p.theme.transitions.duration.short}ms linear;

	&:hover {
		opacity: 100%;
	}
	
	&.disabled {
		color: ${(p) => p.theme.palette.cyan};
		box-shadow: none;
	}

	&:hover.disabled {
		opacity: 80%;
		padding: .25rem;
		background-color: ${(p) => p.theme.palette.divider};
		cursor: default;
	}
`;
// background-color: ${(p) => p.theme.palette.divider};

interface Overrides {
	root?: CSSProperties;
	icon?: CSSProperties;
}

export interface IconButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {
	tooltip?: string;
	tooltipPlacement?: Placement;
	icon?: typeof faChevronRight;
	iconName?: IconName;
	fillColor?: ColorProp;
	baseOpacity?: string;
	variant?: 'base' | 'transparent';
	shape?: 'base' | 'round';
	overrides?: Overrides;
	size?: SizeProp;
	iconSize?: IconSizeProp;
	showTooltip?: boolean;
	content?: any;
	usingLabel?: boolean;
}

/**
 * Forwards any ref to button element.
 */
const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
	(props, ref) => {
		const {
			icon,
			iconName,
			fillColor = 'default',
			baseOpacity = '100%',
			overrides,
			size = 'sm',
			iconSize,
			tooltip,
			tooltipPlacement,
			children,
			disabled,
			content,
			usingLabel=false,
			...rest
		} = props;

		const showTooltip = !!(props.showTooltip ?? tooltip);

		const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
			usePopperTooltip({ placement: tooltipPlacement ?? 'auto' });

		const combinedRefs = forkRef(setTriggerRef, ref);

		const renderButtonContent = () =>
			icon ? <FontAwesomeIcon
				icon={icon}
				className={'unselectable'}
				size={iconSize ? iconSize : size === 'none' ? 'xs' : size}
				transform={{y: content !== undefined && usingLabel ? 2.5 : 1}}
				style={overrides?.icon}
			/> : children;

		return (
			<>
				<IconButtonBase
					className={clsx(disabled && 'disabled unselectable')}
					style={{ ...overrides?.root }}
					ref={combinedRefs as any}
					{...rest}
					size={size}
					usingLabel={content !== undefined && usingLabel}
					fillColor={fillColor}
					baseOpacity={baseOpacity}
					disabled={!!disabled}
				>
					{renderButtonContent()}
				</IconButtonBase>
				{tooltip && visible && showTooltip && (
					<TooltipBody{...getTooltipProps()} ref={setTooltipRef as any}>
						<Typography
							variant='caption2'
							style={{ whiteSpace: 'nowrap' }}>
							{tooltip}
						</Typography>
					</TooltipBody>
				)}
			</>
		);
	}
);

export default IconButton;
