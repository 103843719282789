import React, {FunctionComponent} from "react";
import {ListItem, ListItemContent, ListItemControls} from "./index";
import Error from "../text/Error";
import FullName from "../text/FullName";
import LastActive from "../text/LastActive";
import {BaseEntity} from "../../features/ontology/types/entityTypes";
import ResourceItem from "./ResourceItem";
import ProfileAvatar
    from "../../features/navigation/components/Navbar/ProfileAvatar";
import ViewsIcon from "../icons/ViewsIcon";
import ResourceLabel from "../text/ResourceLabel";
import {StyledMemberDataListItem} from "../../features/governance/components/styledComponents";
import MenuButton from "../buttons/MenuButton";
import EntityMenu from "../menus/EntityMenu";
import RouterLink from "../RouterLink";
import FlexContainer from "../FlexContainer";
import styled from "styled-components";
import {StyledDomainListItem} from "./DomainItem";
import Typography from "../text/Typography";
import IconButton from "../buttons/IconButton";

export const StyledEntityListItem = styled(ListItem)`
	grid-template: [row1-start] "avatar entity entities views refreshed" 1fr [row1-end] 
	 / 35px max-content max-content auto auto;
	gap: 1rem;
	text-align: left;
	place-content: center;
	place-items: center;
	width: 100%;
	height: 4rem;
`;


const EntityItem: FunctionComponent<{entity: any, canEdit: boolean, onClick: any, openModal: any, handleError?: any, controls?: any, children?: any}> = ({entity, canEdit, onClick, handleError, openModal, controls, children}) => {

    return <FlexContainer aria-labelledby={`entity-${entity._id}`} alignItems='center' justifyContent='space-between'>
        <StyledEntityListItem>
            <div style={{gridArea: 'avatar'}} id={`entity-${entity._id}-avatar`}>
                <RouterLink to={`/${entity.ontology.org}/${entity.ontology.domain}/${entity.name}`}>
                    <ProfileAvatar
                        onHover={onClick}
                        initial={entity.singular !== undefined && entity.singular !== null
                            ? entity.singular[0]
                            : 'u'}
                        profileImageURL={entity.profileImageURL}
                    />
                </RouterLink>
            </div>
            <div style={{gridArea: 'entity', width: '100%', cursor: 'pointer'}} id={`entity-${entity._id}-name`}>
                 <RouterLink to={`/${entity.ontology.org}/${entity.ontology.domain}/${entity.name}`}>
                    <ResourceLabel onHover={onClick} resource={entity} />
                </RouterLink>
            </div>

            {/*<Featuring domain={entity} setPopover={setPopover}/>*/}
            <div style={{gridArea: 'entities', width: '100%'}} />
            <div style={{gridArea: 'views', textAlign: 'right', width: '100%'}}>
                <Typography
                    paragraph
                    tooltip="views"
                    variant='h5'
                    color={'oldManGray'}
                    id={`entity-${entity._id}-views`}>
                    <span style={{cursor: 'pointer'}} >
                        {entity.stats.pageViews.views}
                    </span>
                    <IconButton
                        size={'sm'}
                        iconSize={'sm'}
                        fillColor={'primary'}
                        icon={ViewsIcon}
                        variant={'transparent'}
                        style={{
                            position: 'relative',
                            marginLeft: '-.25rem'
                        }}
                    />
                </Typography>
            </div>
            <div style={{
                gridArea: 'refreshed',
                textAlign: 'right',
                width: '100%'
            }}>
                <Typography paragraph variant='h6' color={'oldManGray'}>
                    <span style={{cursor: 'pointer'}} >
                        {entity.lastModified}
                    </span>
                </Typography>
            </div>
            {/*<Error resource={entity} handleError={handleError}/>*/}
        </StyledEntityListItem>
        <EntityMenu
            resource={entity}
            canEdit={canEdit}
        />
    </FlexContainer>
}

export default EntityItem;

        // {/*        <ListItemContent style={{*/}
        // {/*    alignItems: 'center',*/}
        // {/*    justifyContent: 'center',*/}
        // {/*    margin: '2px 0 2px 2px',*/}
        // {/*    paddingLeft: '8px'*/}
        // {/*}}>*/}
        // {/*    /!*<FullName user={user} styles={{ padding: '1px' }}/>*!/*/}
        // {/*    <LastActive user={entity} position='flex-start' />*/}
        // {/*    /!*<Username user={user} />*!/*/}
        // {/*    <Error resource={entity} handleError={handleError} />*/}
        // {/*</ListItemContent>*/}
