import {useModalType} from 'features/HUD/hooks/useModalType';
import {SourceBrowserModal} from 'features/browser/components/SourceBrowser/SourceBrowser';
import AttributeLineageGraph from 'common/modals/LineageGraph';
import ImportEntityGraph from 'common/modals/ImportEntityGraph';
import MetricCreationForm from 'common/modals/MetricCreationForm';
import OrgCreationForm from 'common/modals/OrgCreationForm';
import DomainCreationForm from 'common/modals/DomainCreationForm';
import EntityCreationForm from 'common/modals/EntityCreationForm';
import React, { FunctionComponent } from 'react';
import AppModal from "../../../../common/modals/AppModal";
import UserSettingsModal from "../../../../common/modals/UserSettingsModal";
import CreateConnectionModal
	from "../../../../common/modals/CreateConnectionModal";
import DeleteOrgModal from "../../../../common/modals/DeleteOrgModal";
import DeleteEntityForm from "../../../actions/deleteEntity/DeleteEntityForm";
import OrgSettingsModal from "../../../../common/modals/OrgSettingsModal";
import ImportEntityForm from "../../../actions/importEntity/ImportEntityForm";
import CreateLocationModal
	from "../../../actions/createLocation/CreateLocationModal";
import CreateAttributeForm
	from "../../../actions/createAttribute/CreateAttributeForm";
import AddComponentForm from "../../../actions/addComponent/AddComponentForm";
import DomainSettingsModal from "../../../../common/modals/DomainSettingsModal";
import EntitySettingsModal from "../../../../common/modals/EntitySettingsModal";
import TranslateValueForm
	from "../../../actions/translateValue/TranslateValueForm";
import RestrictEntityForm
	from "../../../actions/restrictEntity/RestrictEntityForm";
import RelateAttributeForm
	from "../../../actions/relateAttribute/RelateAttributeForm";
import PivotEntityForm from "../../../actions/pivotEntity/PivotEntityForm";
import MeltEntityForm from "../../../actions/meltEntity/MeltEntityForm";
import CreateQuestionForm
	from "../../../actions/createQuestion/CreateQuestionForm";
import PersistEntityForm
	from "../../../actions/persistEntity/PersistEntityForm";
import DescribeAttributeForm
	from "../../../actions/describeAttribute/DescribeAttributeForm";
import DescribeMetricForm
	from "../../../actions/describeMetric/DescribeMetricForm";
import SplitAttributeForm
	from "../../../actions/splitAttribute/SplitAttributeForm";
import MergeAttributeForm
	from "../../../actions/mergeAttribute/MergeAttributeForm";
import MoveAttributeForm
	from "../../../actions/moveAttribute/MoveAttributeForm";
import LoadAttributeForm
	from "../../../actions/loadAttribute/LoadAttributeForm";
import AggregateAttributeForm
	from "../../../actions/aggregateAttribute/AggregateAttributeForm";
import PrependAttributeForm
	from "../../../actions/prependAttribute/PrependAttributeForm";
import SubtractAttributeForm
	from "../../../actions/subtractAttribute/SubtractAttributeForm";
import MultiplyAttributeForm
	from "../../../actions/multiplyAttribute/MultiplyAttributeForm";
import DivideAttributeForm
	from "../../../actions/divideAttribute/DivideAttributeForm";
import AddAttributeForm from "../../../actions/addAttribute/AddAttributeForm";
import AppendAttributeForm
	from "../../../actions/appendAttribute/AppendAttributeForm";
import CreateConditionalForm
	from "../../../actions/createConditional/CreateConditionalForm";
import CreateActivityForm
	from "../../../actions/createActivity/CreateActivityForm";
import CreateEventForm from "../../../actions/createEvent/CreateEventForm";
import CreateMetricModal from "../../../actions/createMetric/CreateMetricModal";
import CreateIndividualModal from "../../../actions/createIndividual/CreateIndividualModal";
import UpdateDomainForm from "../../../../common/modals/DomainCreationForm";
import DeleteDomainForm from "../../../actions/deleteDomain/DeleteDomainForm";
import UpdateEntityForm from "../../../actions/updateEntity/UpdateEntityForm";
import SettingsModal from "../../../../common/modals/SettingsModal";
import FeedbackModal from "../../../../common/modals/FeedbackModal";
import FollowersModal from "../../../../common/modals/FollowersModal";
import FollowingModal from "../../../../common/modals/FollowingModal";
import HelpModal from "../../../../common/modals/HelpModal";
import InvitationModal from "../../../../common/modals/InvitationModal";
import KeyboardShortcutsModal
	from "../../../../common/modals/KeyboardShortcutsModal";
import MembershipModal from "../../../../common/modals/MembershipModal";
import MetricSettingsModal from "../../../../common/modals/MetricSettingsModal";
import ReactionsModal from "../../../../common/modals/SharesModal";
import RestrictionsModal from "../../../../common/modals/RestrictionsModal";
import SharesModal from "../../../../common/modals/SharesModal";
import ValuesModal from "../../../../common/modals/ValuesModal";
import AccessibilityModal from "../../../../common/modals/AccessibilityModal";
import DeleteAttributeForm
	from "../../../actions/deleteAttribute/DeleteAttributeForm";
import ShareResourceModal from "../../../../common/modals/ShareResourceModal";
import OrgCreditsModal from "../../../../common/modals/OrgCreditsModal";
import DomainCreditsModal from "../../../../common/modals/DomainCreditsModal";
import DatasetSettingsModal
	from "../../../../common/modals/DatasetSettingsModal";




const MODAL_COMPONENTS = {
	orgCreationForm: OrgCreationForm,
	domainCreationForm: DomainCreationForm,
	entityCreationForm: EntityCreationForm,
	metricCreationForm: MetricCreationForm,
	sourceCreationForm: CreateConnectionModal,
	individualCreationForm: MetricCreationForm,
	// individualCreationForm: IndividualCreationForm,
	importEntity: ImportEntityForm,
	attributeLineage: AttributeLineageGraph,
	deleteOrg: DeleteOrgModal,
	deleteDomain: DeleteDomainForm,
	deleteAttribute: DeleteAttributeForm,
	deleteEntity: DeleteEntityForm,
	createLocation: CreateLocationModal,
	createAttribute: CreateAttributeForm,
	addComponent: AddComponentForm,
	userSettings: UserSettingsModal,
	orgSettings: OrgSettingsModal,
	orgCredits: OrgCreditsModal,
	domainCredits: DomainCreditsModal,
	entityCredits: DomainCreditsModal,
	individualCredits: DomainCreditsModal,
	datasetSettings: DatasetSettingsModal,
	attributeCredits: DomainCreditsModal,
	domainSettings: DomainSettingsModal,
	entitySettings: UpdateEntityForm,
	attributeSettings: DescribeAttributeForm,
	metricSettings: MetricSettingsModal,
	translateValue: TranslateValueForm,
	acceptedValues: ValuesModal,
	specializeEntity: RestrictEntityForm,
	restrictEntity: RestrictEntityForm,
	relateAttribute: RelateAttributeForm,
	pivotEntity: PivotEntityForm,
	meltEntity: MeltEntityForm,
	askQuestion: CreateQuestionForm,
	persistEntity: PersistEntityForm,
	describeAttribute: DescribeAttributeForm,
	describeEntity: EntitySettingsModal,
	describeDomain: DomainSettingsModal,
	describeMetric: DescribeMetricForm,
	splitAttribute: SplitAttributeForm,
	mergeAttribute: MergeAttributeForm,
	moveAttribute: MoveAttributeForm,
	loadAttribute: LoadAttributeForm,
	aggregateAttribute: AggregateAttributeForm,
	prependAttribute: PrependAttributeForm,
	subtractAttribute: SubtractAttributeForm,
	multiplyAttribute: MultiplyAttributeForm,
	divideAttribute: DivideAttributeForm,
	addAttribute: AddAttributeForm,
	appendAttribute: AppendAttributeForm,
	createCondition: CreateConditionalForm,
	createActivity: CreateActivityForm,
	createEvent: CreateEventForm,
	createMetric: CreateMetricModal,
	createIndividual: CreateIndividualModal,
	restrictions: RestrictionsModal,
	specializations: RestrictionsModal,
	shareResource: ShareResourceModal,
	followers: FollowersModal,
	following: FollowingModal,
	help: HelpModal,
	reactions: ReactionsModal,
	// activity: ActivityModal,
	shares: SharesModal,
	accessibility: AccessibilityModal,
	invitations: InvitationModal,
	settings: SettingsModal,
	feedback: FeedbackModal,
	shortcuts: KeyboardShortcutsModal,
	membership: MembershipModal,
	// sourceBrowser: SourceBrowserModal,
	// individualCreationForm: Individ,
	userProfile: UserSettingsModal,
	viewAs: UserSettingsModal,
	activityLog: UserSettingsModal,
	archive: UserSettingsModal,
	accountStatus: UserSettingsModal,
	audioCall: UserSettingsModal,
	videoCall: UserSettingsModal,
	friendship: UserSettingsModal,
	none: AppModal,
}

const HUDModal: FunctionComponent = () => {

	const {modalType, modalProps} = useModalType() || {modalType: 'orgSettings', modalProps: {}};
	console.log(modalType)
	console.log(modalProps)

	// const isOpen = modalType === 'prependAttribute';

	// const {activeItem} = useActiveOrgData();
	const ModalComponent = MODAL_COMPONENTS[modalType || 'none' ] as FunctionComponent;
	return <ModalComponent {...modalProps} />

	// 	onClose: () => {},
	// 	isOpen: true,
	// 	children: null
	// }) : null;

	// if (activeItem) {
		// if (modalType === 'sourceCreationForm') {
		// 	return <CreateConnectionModal
		// 		isOpen={!!modalType && modalType === 'sourceCreationForm'}
		// 		resourceId={activeItem._id}
		// 	/>
		// } else if (modalType === 'sourceBrowser') {
		// 	const params = {
		// 		borderRadius: '4px'
		// 	}
		//
		// 	return <SourceBrowserModal
		// 		borderRadius={params.borderRadius}
		// 		isOpen={!!modalType}
		// 	/>;
		// } else if (modalType === 'userProfile') {
		// 	return <UserSettingsModal
		// 		canEdit={true}
		// 		isOpen={true}
		// 	/>;
		// } else {

		// }
	// } else {
	// 	return null
	// }
};
				// , !!modalType, closeModal)

export default HUDModal;
