import FlexContainer from '../../../common/FlexContainer';
import {FormResults} from '../../../common/Form';
import Spinner from '../../../common/loading/Spinner';
import Typography from '../../../common/text/Typography';
import {
    useGetAttributesQuery,
    useAggregateAttributeMutation,
} from '../../api';
import {mergeErrorStates, extractQueryErrMessage} from '../../api/helpers';
import {isRelation} from '../../ontology/typeGuards/attributeGuards';
import {GetEntityAttrsResponse} from '../../ontology/types/attributeTypes';
// import {AttrActionFormProps} from '../common/commonTypes';
import {
    renderDerivationSubfields
} from '../common/jsxHelpers';
import {
    aggregateAttrFormDefaults,
    aggregateAttrFormToPayload,
    getAvailableAggregationTypes,
} from './aggregateAttributeHelpers';
import {AggregateAttrFormValues} from './aggregateAttributeTypes';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import AggregationTypeSelect from "../../../common/inputs/AggregationTypeSelect";
import useModalType from "../../HUD/hooks/useModalType";
import Uninitialized from "../../../common/loading/Uninitialized";
import Loading from "../../../common/loading/Loading";
import {PrependAttrFormValues} from "../prependAttribute/prependAttributeTypes";
import OtherIdSelect from "../../../common/inputs/OtherIdSelect";

const AggregateAttributeForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
	const {objectId: _id, resource, canEdit} = modalProps;

    const objectEntityId = isRelation(resource) ? resource.objectEntityId : null;

    const formDefaults = aggregateAttrFormDefaults();

    const {handleSubmit, register, formState, reset, watch, resetField} =
        useForm<AggregateAttrFormValues>({
            defaultValues: formDefaults,
        });

    const [aggTypeRef, setAggTypeRef] = useState<HTMLSelectElement | null>(
        null
    );

    const validatingRegister = useCallback(
        (inputName: keyof AggregateAttrFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    // we register aggregationType OUTSIDE of an element so that we can
    // control ref assignment inside aggregationType <select/>. We
    // need to set two refs for this element: one for react-hook-form,
    // and another (aggTypeRef)
    const {ref: registerRef, ...aggTypeRegisterProps} = register(
        'aggregationType',
        {
            required: 'AggregationType is a required field',
            validate: (v) =>
                v === 'none' ? 'An aggregation must be selected' : true,
        }
    );

    const queryRes = useGetAttributesQuery(
        !!objectEntityId && typeof objectEntityId !== 'number'
            ? {entityId: objectEntityId?._id} : skipToken
    );

    const watchedAggregatingId = watch('aggregatingId');

    //  only re-find/filter when user's attr selection changes
    const availableAggregations = useMemo(() => {
        if (watchedAggregatingId === 0) {
            return [];
        }

        if (!queryRes.data) {
            return [];
        }

        const selectedAttr = queryRes.data.find(
            (attr) => attr._id === watchedAggregatingId
        );

        if (!selectedAttr) {
            return [];
        }

        return getAvailableAggregationTypes(selectedAttr);
    }, [queryRes.data, watchedAggregatingId]);

    // When user's attr selection changes, reset form value
    // and form display value to 'none' to avoid conflicts
    // between internal formstate and the display value of
    // uncontrolled input
    useEffect(() => {
        if (!!availableAggregations && formState.isDirty) {
            resetField('aggregationType');

            if (aggTypeRef) {
                aggTypeRef.value = 'none';
            }
        }
    }, [aggTypeRef, availableAggregations, resetField]);

    // useEffect(() => {
    //     if (queryRes.isSuccess && closeModal) {
    //         closeModal();
    //     }
    // }, [queryRes.isSuccess, closeModal]);

    const [aggregateAttr, mutationResults] = useAggregateAttributeMutation();

    const mergedErrs = mergeErrorStates(queryRes, mutationResults);

    const onSubmit: SubmitHandler<AggregateAttrFormValues> = (vals, e) => {
        e?.preventDefault();
        aggregateAttr({
            attributeId: _id,
            body: aggregateAttrFormToPayload(vals),
        });
    };

    if (queryRes.isError) {
        return (
            <FlexContainer justifyContent="center">
                <Typography color="error" paragraph>
                    {extractQueryErrMessage(queryRes.error)}
                </Typography>
            </FlexContainer>
        );
    }

    return (
        <AppModal
            label={"Create Aggregation"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit}>
            {renderDerivationSubfields({
                isDerivation: true,
                formState,
                validatingRegister,
                resetField,
                mutationResults
            })}
            {!queryRes.isUninitialized && !queryRes.isLoading ?
                <OtherIdSelect
                    fieldName={'aggregatingId'}
                    validatingRegister={validatingRegister}
                    formState={formState}
                    selectableAttrs={ queryRes.data as GetEntityAttrsResponse}
                /> : queryRes.isUninitialized
                    ? <Uninitialized /> : <Loading />
                //   Ok to cast--if it's not loading and not errored, files is present
            }
            <AggregationTypeSelect
                availableAggregations={availableAggregations}
                resetField={resetField}
                formState={formState}
                validatingRegister={validatingRegister}
                mutationResults={mutationResults}
            />
            <FormResults
                onSuccess={closeModal}
                onSuccessDelay={600}
                isError={!!mergedErrs}
                error={mergedErrs}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default AggregateAttributeForm;
