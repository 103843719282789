import React, {FunctionComponent} from "react";
import TextButton from "./TextButton";


const SettingsButton: FunctionComponent<{canEdit: boolean, onClick: () => void}> = ({canEdit, onClick}) => {
	return canEdit
		? <TextButton
			text='Edit profile'
			color='primary'
			fillColor='transparent'
			onClick={onClick}
		/>
		: null;
}

export default SettingsButton;
