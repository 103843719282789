import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch } from 'app/hooks';
import Heading from 'common/text/Heading';
import { pipe } from 'common/utils/functionUtils';
import { viewsAvailable } from 'features/HUD/state/HUDSlice';
import {useGetDomainsQuery, useGetSourceSummariesQuery} from 'features/api';
import { deriveDataViews } from 'features/compositeViews/helpers';
import useRenderView, {
	RenderViewConfig,
} from 'features/navigation/hooks/useRenderView';
import { StyledProfileGridLayout } from 'features/profile/common/styledComponents';
import { FunctionComponent, useEffect } from 'react';
import FlexContainer from "../../../common/FlexContainer";
import SourceBrowser from "../../browser/components/SourceBrowser/SourceBrowser";
import useActiveOrgData from "../../ontology/hooks/useActiveOrgData";
import CatalogBrowser from "../../browser/components/CatalogBrowser/CatalogBrowser";

const Stub: FunctionComponent<{ placeholder: string }> = ({ placeholder }) => {
	return (
		<StyledProfileGridLayout>
			<div/>
			<Heading style={{color: '#29323c'}} component="h5">
				{placeholder}
			</Heading>
		</StyledProfileGridLayout>
	);
};


const configs: RenderViewConfig = {
	viewDefault: 'data',
	views: [
		{ match: 'data', element: <CatalogBrowser /> },
		{ match: 'quality', element: <CatalogBrowser /> },
		{ match: 'lineage', element: <CatalogBrowser /> },
		{ match: 'usage', element: <CatalogBrowser /> },
		{ match: 'relations', element: <CatalogBrowser /> },
		{ match: 'form', element: <CatalogBrowser /> },
	],
};


const CatalogViews: FunctionComponent = () => {
	const { activeItem: activeOrg } = useActiveOrgData();

	const {
		data: orgSources,
		isSuccess,
	} = useGetSourceSummariesQuery(
		activeOrg ? { orgId: activeOrg._id } : skipToken
	);

	const appDispatch = useAppDispatch();

	const render = useRenderView(configs);

	useEffect(() => {
		if (orgSources && isSuccess) {
			const views = deriveDataViews();
			pipe(views, viewsAvailable, appDispatch);
		}

		return () => pipe([], viewsAvailable, appDispatch) as void;
	}, [orgSources, isSuccess, appDispatch]);

	return render();
};

export default CatalogViews;
