import {
	HUDState,
	WindowResizePayload,
	WindowScrollPayload,
	ModalType,
	HUDIndividual,
} from '../types/HUDTypes';
import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ViewMode } from 'features/compositeViews/types';
import {OrgViewMode} from "../../compositeViews/helpers";

export const initialState: HUDState = {
	activeIndividual: null,
	recenterGraphKey: nanoid(),
	viewportWidth: 0,
	viewportHeight: 0,
	scrollX: 0,
	scrollY: 0,
	HUDActive: true,
	tabDrawerOpen: false,
	HUDAvailable: true,
	availableViews: [],
	buttons: [],
	modalType: {type: null, props: {}},
};

const HUDSlice = createSlice({
	name: 'HUD',
	initialState,
	reducers: {
		viewsAvailable: (state, action: PayloadAction<ViewMode[]>) => ({
			...state,
			availableViews: action.payload,
		}),

		resize: (state, action: PayloadAction<WindowResizePayload>) => {
			return { ...state, ...action.payload };
		},

		HUDActive: (state, action: PayloadAction<boolean>) => {
			return { ...state, HUDActive: action.payload };
		},

		toggleHUDActive: (state) => {
			return { ...state, HUDActive: !state.HUDActive };
		},

		setTabDrawerOpen: (state, action: PayloadAction<boolean>) => {
			return {
				...state,
				tabDrawerOpen: action.payload,
			};
		},

		HUDAvailable: (state, action: PayloadAction<boolean>) => {
			return { ...state, HUDAvailable: action.payload };
		},

		scroll: (state, action: PayloadAction<WindowScrollPayload>) => {
			return { ...state, ...action.payload };
		},

		recenterGraph: (state) => {
			return { ...state, recenterGraphKey: nanoid() };
		},

		setModalType: (state, action: PayloadAction<{type: ModalType, props?: any}>) => {
			return {
				...state,
				modalType: {
					type: action.payload.type,
					props: action.payload.props || {}
				}
			};
		},

		setDrawerIndividual: (state, action: PayloadAction<HUDIndividual>) => ({
			...state,
			activeIndividual: action.payload,
		}),

		clearDrawerIndividual: (state) => ({
			...state,
			activeIndividual: null,
		}),
	},
});

export const selectViewportDimensions = ({ HUD }: RootState) => ({
	viewportWidth: HUD.viewportWidth,
	viewportHeight: HUD.viewportHeight,
});

export const selectHUDState = ({ HUD }: RootState) => HUD.HUDActive;

export const selectHUDAvailable = ({ HUD }: RootState) => HUD.HUDAvailable;

export const selectAvailableViews = ({ HUD }: RootState) => HUD.availableViews;

export const selectHUDButtons = ({ HUD }: RootState) => HUD.buttons;

export const selectModalType = ({ HUD }: RootState) => HUD.modalType;

// export const selectModalProps = ({ HUD }: RootState) => HUD.modalProps;

export const selectRecenterGraphKey = ({ HUD }: RootState) =>
	HUD.recenterGraphKey;

export const selectTabDrawerOpen = ({ HUD }: RootState) => HUD.tabDrawerOpen;

export const selectActiveIndividual = ({ HUD }: RootState) =>
	HUD.activeIndividual;

export const {
	clearDrawerIndividual,
	setDrawerIndividual,
	viewsAvailable,
	setTabDrawerOpen,
	resize,
	HUDActive,
	scroll,
	HUDAvailable,
	toggleHUDActive,
	setModalType,
	recenterGraph,
} = HUDSlice.actions;

export default HUDSlice.reducer;
