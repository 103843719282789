import FlexContainer from '../../../common/FlexContainer';
import {FormResults} from '../../../common/Form';
import Spinner from '../../../common/loading/Spinner';
import Typography from '../../../common/text/Typography';
import {useAddComponentMutation, useGetAttributesQuery} from '../../api';
import {extractQueryErrMessage} from '../../api/helpers';
import {EntityActionFormProps} from '../common/commonTypes';
import {renderDerivationSubfields} from '../common/jsxHelpers';
import {
    addComponentFormDefaults,
    addComponentFormToPayload,
} from './addComponentHelpers';
import {AddComponentFormValues} from './addComponentTypes';
import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import {useAppDispatch} from "../../../app/hooks";
import useModalType from "../../HUD/hooks/useModalType";
import {setModalType} from "../../HUD/state/HUDSlice";
import {PrependAttrFormValues} from "../prependAttribute/prependAttributeTypes";

const AddComponentForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
	const {objectId: _id, canEdit} = modalProps;

    const formDefaults = addComponentFormDefaults();

    const {handleSubmit, register, resetField, formState, watch} =
        useForm<AddComponentFormValues>({
            defaultValues: formDefaults,
        });

    const watchedLatitudeId = watch('latitudeId');

    const validatingRegister = useCallback(
        (inputName: keyof AddComponentFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const [addComponent, mutationResults] = useAddComponentMutation();

    const onSubmit: SubmitHandler<AddComponentFormValues> = (vals) => {
        addComponent({
            entityId: _id,
            body: addComponentFormToPayload(vals),
        });
    };

    const {
        isLoading,
        isError,
        error,
        data: attrData,
        isUninitialized,
    } = useGetAttributesQuery({entityId: _id});

    //  lat and long can't be the same attribute
    const longitudeCandidates = useMemo(() => {
        if (attrData) {
            return attrData.filter((attr) => attr._id !== watchedLatitudeId);
        }

        return [];
    }, [watchedLatitudeId, attrData]);

    if (isLoading || isUninitialized) {
        return (
            <FlexContainer justifyContent="center">
                <Typography paragraph variant='h5'>Loading
                    attributes...</Typography>
                <Spinner/>
            </FlexContainer>
        );
    }

    if (isError) {
        return (
            <FlexContainer justifyContent="center">
                <Typography color="error" paragraph>
                    {extractQueryErrMessage(error)}
                </Typography>
            </FlexContainer>
        );
    }

    return (
        <AppModal
            label={"Add Component"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit ? canEdit : true}>
            {renderDerivationSubfields({
                isDerivation: true,
                formState,
                validatingRegister,
                resetField,
                mutationResults
            })}
            <FormResults
                isError={mutationResults.isError}
                error={mutationResults.error}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default AddComponentForm;
