import {PopoverContentColumns} from './styledComponents';
import { FunctionComponent, ReactNode } from 'react';

interface PopoverMainProps {
	children?: ReactNode;
}

const PopoverMain: FunctionComponent<PopoverMainProps> = ({
	children,
}) => {
	return (
		<PopoverContentColumns style={{padding: '1rem 0', margin: '0'}}>
			{children}
		</PopoverContentColumns>
	);
};

export default PopoverMain;
