import { BaseAttribute } from '../../ontology/types/attributeTypes';
import {
	boolStringToBool,
	emptyStringToNull,
	withArticle,
} from '../common/helpers';
import {
	TranslateValueFormValues,
	TranslateValuePayload,
} from './translateValueTypes';

export const genTranslateValueFieldLabels = (
	sourceAttr: BaseAttribute,
	targetAttr: BaseAttribute | 'deriveNew' | null
): { [key in keyof TranslateValueFormValues]: string } => {
	const otherId = 'Select target identity:';

	if (targetAttr === null) {
		return {
			otherId,
			singular: 'No target attr selected...',
			plural: 'No target attr selected...',
			definition: 'No target attr selected...',
			isDependent: 'No target attr selected...',
			isExclusive: 'No target attr selected...',
		};
	}

	const targetLabel =
		targetAttr === 'deriveNew'
			? 'Derived Entity'
			: targetAttr.entity.singular;

	const sourceEntity = sourceAttr.entity;

	const singular = "What is the singular form of this relationship's name?";

	const plural = "What is the plural form of this relationship's name?";

	const definition = `Describe the relationship between ${withArticle(
		sourceEntity.singular
	)} and ${withArticle(targetLabel)}:`;

	const isDependent = `Can ${withArticle(
		sourceEntity.singular
	)} exist without ${withArticle(targetLabel)}, or is every ${
		sourceEntity.singular
	} required to have ${withArticle(targetLabel)}?`;

	const isExclusive = `Is ${withArticle(
		sourceEntity.singular
	)} exclusive to ${withArticle(targetLabel)}, or can ${withArticle(
		sourceEntity.singular
	)} be shared by many ${targetLabel}?`;

	const type = 'What is the nature of this particular relationship?';

	return {
		otherId,
		singular,
		plural,
		definition,
		isDependent,
		isExclusive,
	};
};

export const translateValueFormDefaults = (
	attr: BaseAttribute
): TranslateValueFormValues => ({
	otherId: 'null',
	singular: attr.singular,
	plural: attr.plural,
	definition: attr.definition ?? '',
	isDependent: 'true',
	isExclusive: 'true',
});

const parseTargetAttribute = (attrIdString: string): number | 'deriveNew' =>
	attrIdString === 'deriveNew' ? 'deriveNew' : parseInt(attrIdString, 10);

export const translateValueFormToPayload = (
	formValues: TranslateValueFormValues
): TranslateValuePayload => ({
	otherId: parseTargetAttribute(formValues.otherId),
	singular: formValues.singular,
	plural: formValues.plural,
	definition: emptyStringToNull(formValues.definition),
	isDependent: boolStringToBool(formValues.isDependent),
	isExclusive: boolStringToBool(formValues.isExclusive),
});
