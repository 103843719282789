import { DataSourceSummaries } from '../types/dataTypes';
import {SelectedSourceData, SourceNavItemList} from '../types/uiTypes';
import {BrowserDisplay} from './ContextualDisplay';
import {BrowserNav} from './SourceBrowser/SourceNav';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetSourceSummariesQuery} from 'features/api';
import { parseQueryError } from 'features/api/helpers';
import useActiveOrgData from 'features/ontology/hooks/useActiveOrgData';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import {SourceBody} from "../../HUD/components/HUDModal/styledComponents";
import Typography from "../../../common/text/Typography";
import CreateConnectionModal from "../../../common/modals/CreateConnectionModal";
import ContextMenu from "../../../common/ContextMenu";

const StyledMain = styled.div<{grid?: string;}>`
	display: grid;
	grid-template-columns: ${(p) => p.grid ? p.grid : '1fr 8fr'};
	grid-template-rows: 1fr;
	flex: 1;
	overflow: auto;
	position: absolute;
	width: calc(100vw - ${(p) => `${p.theme.sideControllerWidth + p.theme.panelGutter * 3}`}px);
	height: calc(100vh - ${(p) => p.theme.navHeight * 2 + p.theme.panelGutter * 2}px);
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter}px;
	color: ${(p) => p.theme.palette.text.paper};
`;

const StyledPanel = styled.div`
	display: grid;
	flex: 1;
	overflow: auto;
	background: ${(p) => p.theme.palette.background.paper};
	color: ${(p) => p.theme.palette.text.paper};
`;

const OrgData: FunctionComponent = () => {

	const {activeItem: activeOrg} = useActiveOrgData();
	const { data: sources, isLoading, isFetching, isUninitialized, isError, error } = useGetSourceSummariesQuery(
		activeOrg && activeOrg._id ? {orgId: activeOrg._id} : skipToken
	);

	const canEdit = !!activeOrg && activeOrg.userContext.permissions.edit;

	const [cardData, setCardData] = useState<SourceNavItemList | null>(null)
	const [displayMode, setDisplayMode] = useState<null | 'form'>(null)
	const [onDisplay, setOnDisplay] = useState<SelectedSourceData>([]);

	// TODO: this causes flicker on cache invalidation.  Pass these props down
	// so children can render a spinner or something instead.
	if (isLoading || isFetching || isUninitialized) {
		return <SourceBody borderRadius={'0px'}>
			<Typography variant={'h2'} color={'oldManGray'}>
				Loading...
			</Typography>
		</SourceBody>;
	}

	if (isError && error) {
		const { message } = parseQueryError(error);

		return <SourceBody borderRadius={'0px'}>
			<Typography variant={'h2'} color={'oldManGray'}>
				{message}
			</Typography>
		</SourceBody>;
	}

	return <>
			<SourceBody borderRadius={'0px'}>
			<StyledMain>
				<StyledPanel>
					<BrowserNav
						sources={sources as DataSourceSummaries}
						setter={(toSelect: SelectedSourceData) => setOnDisplay(toSelect)}
						currentlySelected={onDisplay}
					/>
				</StyledPanel>
			</StyledMain>
		</SourceBody>
		<ContextMenu />
	</>;
}

export default OrgData;
