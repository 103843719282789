import FlexContainer from '../../../common/FlexContainer';
import {FormResults} from '../../../common/Form';
import Spinner from '../../../common/loading/Spinner';
import Typography from '../../../common/text/Typography';
import {useGetAttributesQuery, useLoadAttributeMutation} from '../../api';
import {mergeErrorStates, extractQueryErrMessage} from '../../api/helpers';
import {isRelation} from '../../ontology/typeGuards/attributeGuards';
import {GetEntityAttrsResponse} from '../../ontology/types/attributeTypes';
import {AttrActionFormProps} from '../common/commonTypes';
import {
    loadAttrFormDefaults,
    loadAttrFormToPayload,
} from './loadAttributeHelpers';
import {LoadAttrFormValues} from './loadAttributeTypes';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import {useAppDispatch} from "../../../app/hooks";
import useModalType from "../../HUD/hooks/useModalType";
import {setModalType} from "../../HUD/state/HUDSlice";
import {PrependAttrFormValues} from "../prependAttribute/prependAttributeTypes";
import OtherIdSelect from "../../../common/inputs/OtherIdSelect";
import Uninitialized from "../../../common/loading/Uninitialized";
import Loading from "../../../common/loading/Loading";

const LoadAttributeForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
    const {objectId: sourceAttrId, resource, entity, canEdit} = modalProps;

    const objectEntityId = isRelation(resource) ? resource.objectEntityId : null;

    const formDefaults = loadAttrFormDefaults();

    const {handleSubmit, register, formState} = useForm<LoadAttrFormValues>({
        defaultValues: formDefaults,
    });

    const queryRes = useGetAttributesQuery(
        !!objectEntityId && typeof objectEntityId !== 'number'
            ? {entityId: objectEntityId._id} : skipToken
    );

    // useEffect(() => {
    //     if (queryRes.isSuccess && closeModal) {
    //         closeModal();
    //     }
    // }, [queryRes.isSuccess, closeModal]);

    const [loadAttr, loadAttrResult] = useLoadAttributeMutation();

    const mergedErrs = mergeErrorStates(queryRes, loadAttrResult);

    const validatingRegister = useCallback(
        (inputName: keyof LoadAttrFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const onSubmit: SubmitHandler<LoadAttrFormValues> = (vals, e) => {
        e?.preventDefault();
        loadAttr({
            sourceAttrId,
            body: loadAttrFormToPayload(vals),
        });
    };

    return (
        <AppModal
            label={"Load Attribute"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit ? canEdit : true}>

            {queryRes.isUninitialized ? (
                <FlexContainer justifyContent="center">
                    <Typography color="warn" paragraph>
                        Waiting to receive target entity Id... If this message
                        does not clear, it is likely that no entity id is
                        available for the target entity of this relation.
                    </Typography>
                    <Spinner/>
                </FlexContainer>
            ) : null}

            {queryRes.isLoading ? (
                <Loading />
            ) : null}

            {queryRes.isError ? (
                <FlexContainer justifyContent="center">
                    <Typography color="error" paragraph>
                        {extractQueryErrMessage(queryRes.error)}
                    </Typography>
                </FlexContainer>
            ) : null}
            {!queryRes.isUninitialized && !queryRes.isLoading && !queryRes.isError ?
                <OtherIdSelect
                    fieldName={'attributeId'}
                    validatingRegister={validatingRegister}
                    formState={formState}
                    selectableAttrs={ queryRes.data as GetEntityAttrsResponse}
                /> : queryRes.isUninitialized ? <Uninitialized /> : <Loading />
                //   Ok to cast--if it's not loading and not errored, files is present
            }
            <FormResults
                isError={!!mergedErrs}
                error={mergedErrs}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default LoadAttributeForm;
