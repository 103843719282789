import Typography from 'common/text/Typography';
import { isAppError } from 'common/utils/typeGuards';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useDispatchableError from 'features/errorHandling/hooks/useDispatchableErr';
import { AppError } from 'features/errorHandling/types/errorTypes';
import {
	contextNameFromContextIdx,
	URLParamsToContextArray,
} from 'features/navigation/helpers';
import { ContextLadder } from 'features/navigation/types/navigationContextTypes';
import useActiveOrgData from 'features/ontology/hooks/useActiveOrgData';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import useActiveEntityData from 'features/ontology/hooks/useActiveEntityData';
import useActiveIndividualData from 'features/ontology/hooks/useActiveIndividualData';
import ActivityList from 'common/activities/components/ActivityList';
import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProfileHeader from "../../../features/profile/common/ProfileHeader";
import styled from "styled-components";
import GridContainer from "../../GridContainer";

const StyledProfileItem = styled(GridContainer)`
	padding: ${(p) => p.theme.spacing(2, 1)};
	align-items: start;
	justify-content: space-between;
`;

const StyledPlaceholder = styled(StyledProfileItem)`
	border-bottom: 0px solid ${(p) => p.theme.palette.divider};
	margin: auto auto 0 auto;
	padding: auto auto 0 auto;
`;

const errUI = (message: string) => {
	return (
		<>
			<div>
				<Typography color="error">
					Unable to determine ontology depth from url. Parsing failed
					with the following error:
				</Typography>
			</div>
			<div>
				<Typography color="error">{message}</Typography>
			</div>
		</>
	);
};

const IndividualActivityList: FunctionComponent = () => {
	const { activeItem, ...individualLoadState } = useActiveIndividualData();

	const identifier: number = activeItem
		? activeItem.individual._id
		: null;

	return (
		<DisplayOnLoad {...individualLoadState}>
			{activeItem && (
				<ActivityList
					objectId={identifier}
					objectType={'Individual'}
					overrides={{
						list: {
							maxHeight: '100%',
						},
					}}
				/>
			)}
		</DisplayOnLoad>
	);
};

const EntityActivityList: FunctionComponent = () => {
	const { activeItem: activeEntity, ...entityLoadState } =
		useActiveEntityData();

	const identifier: number | null = activeEntity
		? activeEntity._id
		: null;

	return (
		<DisplayOnLoad {...entityLoadState}>
			{activeEntity && identifier && (
				<ActivityList
					objectId={identifier}
					objectType={'Entity'}
					overrides={{
						list: {
							maxHeight: 'auto',
						},
					}}
				/>
			)}
		</DisplayOnLoad>
	);
};

const DomainActivityList: FunctionComponent = () => {
	const { activeItem: activeDomain, ...domainLoadState } =
		useActiveDomainData();

	const identifier: number | null = activeDomain
		? activeDomain._id
		: null;

	return (
		<DisplayOnLoad {...domainLoadState}>
			{activeDomain && identifier && (
				<ActivityList
					objectId={identifier}
					objectType={'Domain'}
					overrides={{
						list: {
							maxHeight: 'auto',
						},
					}}
				/>
			)}
		</DisplayOnLoad>
	);
};

const OrgActivityList: FunctionComponent = () => {
	const { activeItem: activeOrg, ...orgLoadState } =
		useActiveOrgData();

	const identifier: number | null = activeOrg
		? activeOrg._id
		: null;

	return (
		<DisplayOnLoad {...orgLoadState}>
			{activeOrg && identifier && (
				<ActivityList
					objectId={identifier}
					objectType={'Organization'}
					overrides={{
						list: {
							maxHeight: 'auto',
						},
					}}
				/>
			)}
		</DisplayOnLoad>
	);
};

const getUI = (contextLadder: AppError | ContextLadder) => {
	if (isAppError(contextLadder)) {
		return errUI(contextLadder.message);
	}

	const ctx = contextNameFromContextIdx(contextLadder.length - 1);

	switch (ctx) {
		case 'username':
			return <OrgActivityList />
		case 'domain':
			return <DomainActivityList />
		case 'entity':
			return <EntityActivityList />
		case 'individual':
			return <IndividualActivityList />
			default:
			return <OrgActivityList />
	}
};

const ActivitiesTab: FunctionComponent = () => {
	const dispatchErr = useDispatchableError();

	const params = useParams();

	const contextLadder = URLParamsToContextArray(params);

	useEffect(() => {
		if (isAppError(contextLadder)) {
			dispatchErr(contextLadder);
		}
	}, [contextLadder, dispatchErr]);

	return getUI(contextLadder);
};

export default ActivitiesTab;
