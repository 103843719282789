import React, {FunctionComponent, ReactNode, useState} from "react";
import {List, ListItem} from "../List";
import IconButton from "../buttons/IconButton";
import {faChevronRight, faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import Popover from "../popovers/Popover/PopoverBase";
import ClickAwayListener from "react-click-away-listener";
import styled from "styled-components";
import Button from "./Button";
import TextButton from "./TextButton";
import {setModalType} from "../../features/HUD/state/HUDSlice";
import Typography from "../text/Typography";
import FlexContainer from "../FlexContainer";
import clsx from "clsx";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import theme from "../theme/theme";


interface ActionListProps {
    // _id: number,
    label: string,
    children?: ReactNode,
    style?: any;
    // isOpen: boolean
}

// const StyledListItem = styled.li`
// 	background: transparent;
// 	cursor: pointer';
// 	&:hover {
// 		background: ${(p) => p.theme.palette.darkerBaby + '90'};
// 	}
// 	padding: ${(p) => p.theme.spacing(0.5)}  ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(.5)};
// 	display:  flex;
// 	align-items: flex-start;
// 	justify-content: space-between;
// 	color: ${(p) => p.theme.palette.oldManGray};
//
// 	&.highlight-list-item-hover:hover {
// 		color: ${(p) => p.theme.palette.primary.main};
// 	}
// `;


const SubMenuButton: FunctionComponent<ActionListProps> = (
    {
        label,
        children,
        style
    }
) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const closeMenu = () => setMenuOpen(false);

    return <>
        <ListItem
            button
            style={{
                margin: '0',
                height: '2.25rem',
                padding: `${theme.spacing(0.5)}  ${theme.spacing(1)} ${theme.spacing(.5)}`}}
            ref={setAnchorEl}
            onClick={() => setMenuOpen((p) => !p)}>
            <FlexContainer alignItems='center' justifyContent={'space-between'} style={{width: '100%'}}>
                <Typography variant='subtitle'>
                    {label}
                </Typography>
                <FontAwesomeIcon size='xs' icon={faChevronRight}/>
            </FlexContainer>
        </ListItem>
        <Popover
            anchorEl={anchorEl}
            open={menuOpen}
            placement={'right-start'}
            handleClickAway={closeMenu}
        >
            <ClickAwayListener onClickAway={closeMenu}>
                <List style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column'
                }}>
                    {children}
                </List>
            </ClickAwayListener>
        </Popover>
    </>;
};

export default SubMenuButton;
