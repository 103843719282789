import clsx from 'clsx';
import {
	forwardRef,
	HTMLAttributes,
	CSSProperties,
	FunctionComponent
} from 'react';
import styled from 'styled-components';
import ProfileAvatar
	from "../../../features/navigation/components/Navbar/ProfileAvatar";
import {composeName} from "../../../features/governance/helpers";
import Typography from "../../text/Typography";
import {ListItem} from "../../List";
import ReactRouterLink from "../../RouterLink";
import useViewModeParams
	from "../../../features/navigation/hooks/useViewModeParams";
import {useNavigate} from "react-router";
// import UserItem from "../List/UserItem";

export const BasicItem: FunctionComponent<{resource: any, onClick: any}> = ({resource, onClick}) => {
	const {getViewMode} = useViewModeParams();

	return <ListItem onClick={onClick} style={{ cursor: 'pointer' }}		>
			<ListItemContent>
				<Typography color='primary' variant='caption2'>
					{resource.label === undefined
						? resource.singular
						: resource.label}
				</Typography>
			</ListItemContent>
		</ListItem>

}

const LinkItem: FunctionComponent<{resource: any, onClick: any}> = ({resource, onClick}) => {
	const {getViewMode} = useViewModeParams();
	const navigate = useNavigate();
	const mode = getViewMode();

	return <ListItem onClick={() => {
				if (resource.kind !== 'username') {
						navigate(
							mode !== 'profile'
							? `${resource.url}/${mode}`
							: resource.url
						)
					// navigate(resource.name)
				} else {
					navigate(
						mode !== 'profile' && mode !== 'feed'
							? `/${resource.name}/${mode}`
							: `/${resource.name}`
					)
				}
				onClick()
			}}
			style={{ cursor: 'pointer' }}
		>
			<ListItemContent>
				<Typography color='primary' variant='caption2'>
					{resource.label === undefined
						? resource.singular
						: resource.label}
				</Typography>
			</ListItemContent>
		</ListItem>

}
// / <ReactRouterLink
	// 	to={resource.url}
	// 	key={resource.url}
	// 	preserveSearchParams={false}
	// >
	// </ReactRouterLink>
const UserItem: FunctionComponent<{resource: any, onClick: any}> = ({resource, onClick}) => {
	return <ListItem
		style={{display: 'flex', padding: '8px'}}
		button
		key={resource._id}
		onClick={onClick}
	>
		<ProfileAvatar
			onClick={() => {}}
			initial={composeName(resource)[0]}
			profileImageURL={resource.avatar?.thumb.url as string}
			isOnline={resource.lastActive === 'online'}
		/>
		<ListItemContent style={{
			display: 'flex',
			flexDirection: 'column',
			marginLeft: '8px'
		}}>
			<Typography variant='h5'>
				{composeName(resource)}
			</Typography>
			<Typography variant='h6'>
				{resource.username}
			</Typography>
		</ListItemContent>
	</ListItem>
}

interface Props {
	$button?: boolean;
	$highlightColor?: string;
	$background?: 'default' | 'paper';
	position?: string;
}

const getResourceItem = (type: any) => {
	switch (type) {
		case 'user':
			return UserItem
		case 'link':
			return LinkItem
		default:
			return BasicItem
	}
}

const StyledOption = styled.option<Props>`
	background: transparent;
	cursor: ${(p) => (p.$button ? 'pointer' : 'inherit')};
	&:hover {
		background: ${(p) =>
			p.$background === 'paper'
				? p.theme.palette.darkerBaby + '90'
				: p.theme.palette.darkBaby + 50};
	}
	padding: ${(p) => p.theme.spacing(0.2)};
	display:  flex;
	align-items:  ${(p) => p.position ? p.position : 'center'};
	align-content: center;
	color: ${(p) => p.theme.palette.oldManGray};

	&.highlight-list-item-hover:hover {
		color: ${(p) => p.$highlightColor || p.theme.palette.primary.main};
	}
`;

export const ListItemControls = styled.div<{
	justifyContent?: CSSProperties['justifyContent'];
}>`
	display: flex;
	flex-basis: 20%;
	align-items: center;
	align-content: center;
	justify-content: ${(p) => p.justifyContent ?? 'flex-end'};
`;

export const ListItemContent = styled.div<{ truncate?: boolean }>`
	text-overflow: ${(p) => (p.truncate ? 'ellipsis' : 'default')};
	white-space: ${(p) => (p.truncate ? 'nowrap' : 'default')};
	overflow: ${(p) => (p.truncate ? 'hidden' : 'default')};
	flex-grow: 1;
`;

ListItemContent.defaultProps = { truncate: false };

export const ListItemAvatar = styled.div`
	flex: 0 0 10%;
`;

export interface SelectBoxItemProps extends HTMLAttributes<HTMLLIElement> {
	resource: any;
	onClick: any;
	button?: boolean;
	highlightOnHover?: boolean;
	highlightColor?: string;
	background?: 'default' | 'paper';
	position?: string;
	type?: string;
}

//	<List style={{maxHeight: '50vh', overflowY: 'auto'}}>
// 		{sortedLinks.map(({url, label, singular}) => (
export const SelectItem = forwardRef<HTMLElement, SelectBoxItemProps>((props, ref) => {
	return	<ReactRouterLink
				to={props.resource.url}
				preserveSearchParams={false}
			>
				<ListItem
					onClick={props.onClick}
					style={{cursor: 'pointer'}}
				>
					<ListItemContent>
						<Typography variant='caption2'>
							{props.resource.label}
						</Typography>
					</ListItemContent>
				</ListItem>
			</ReactRouterLink>
})

export const SelectBoxItem = forwardRef<HTMLElement, SelectBoxItemProps>(
	(
		{
			resource,
			type,
			background = 'paper',
			children,
			button,
			highlightOnHover,
			className,
			highlightColor,
			onClick,
			...props
		},
		ref
	) => {
		const Element = getResourceItem(type ? type : resource?._object)
		return Element ? (<Element onClick={onClick} resource={resource} />) : null;
	}
);


const StyledList = styled.ul`
	list-style-type: none;
`;

interface ListProps extends HTMLAttributes<HTMLUListElement> {}

export const List = forwardRef<HTMLElement, ListProps>(
	({ children, ...props }, ref) => {
		return (
			<StyledList ref={ref as any} {...props}>
				{children}
			</StyledList>
		);
	}
);

export default SelectBoxItem;
