import clsx from 'clsx';
import React, { forwardRef, HTMLAttributes, CSSProperties } from 'react';
import styled from 'styled-components';

interface Props {
	$button?: boolean;
	$highlightColor?: string;
	$background?: 'default' | 'paper';
	position?: string;
}

const StyledListItem = styled.li<Props>`
	background: transparent;
	cursor: ${(p) => (p.$button ? 'pointer' : 'inherit')};
	&:hover {
		background: ${(p) =>
			p.$background === 'paper'
				? p.theme.palette.darkerBaby + '90'
				: p.theme.palette.darkBaby + 50};
	}
	padding: ${(p) => p.theme.spacing(0.5)}  ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(.5)};
	display:  flex;
	align-items:  ${(p) => p.position ? p.position : 'center'};
	align-content: center;
	color: ${(p) => p.theme.palette.oldManGray};

	&.highlight-list-item-hover:hover {
		color: ${(p) => p.$highlightColor || p.theme.palette.primary.main};
	}
`;

export const StyledMenuItem = styled.li<Props>`
	background: transparent;
	cursor: ${(p) => (p.$button ? 'pointer' : 'inherit')};
	&:hover {
		background: ${(p) =>
			p.$background === 'paper'
				? p.theme.palette.darkerBaby + '90'
				: p.theme.palette.darkBaby + 50};
	}
	padding: ${(p) => p.theme.spacing(0.1)} 0;
	margin: 0;
	display:  flex;
	justify-content: flex-start;
	align-items:  ${(p) => p.position ? p.position : 'center'};
	color: ${(p) => p.theme.palette.oldManGray};

	&.highlight-list-item-hover:hover {
		color: ${(p) => p.$highlightColor || p.theme.palette.primary.main};
	}
`;

export const StyledProfileItem = styled.li<Props>`
	background: transparent;
	cursor: ${(p) => (p.$button ? 'pointer' : 'inherit')};
	&:hover {
		background: ${(p) =>
			p.$background === 'paper'
				? p.theme.palette.darkerBaby + '90'
				: p.theme.palette.darkBaby + 50};
	}
	padding: ${(p) => p.theme.spacing(0.2)};
	display:  grid;
	grid-template: 
	[row1-start] "attribute fact" 1fr [row1-end]  
	/ 200px 1fr;
	color: ${(p) => p.theme.palette.oldManGray};

	&.highlight-list-item-hover:hover {
		color: ${(p) => p.$highlightColor || p.theme.palette.primary.main};
	}
`;
	// align-items:  ${(p) => p.position ? p.position : 'center'};
	// align-content: center;

export const ListItemControls = styled.div<{
	justifyContent?: CSSProperties['justifyContent'];
}>`
	display: flex;
	flex-basis: 20%;
	gap: 1rem;
	flex-direction: column;
	align-items: flex-start;
	align-content: center;
	justify-content: ${(p) => p.justifyContent ?? 'flex-start'};
`;
	// align-items: center;

export const ListItemContent = styled.div<{ truncate?: boolean }>`
	text-overflow: ${(p) => (p.truncate ? 'ellipsis' : 'default')};
	white-space: ${(p) => (p.truncate ? 'nowrap' : 'default')};
	overflow: ${(p) => (p.truncate ? 'hidden' : 'default')};
	flex-grow: 1;
	padding: 0;
	margin: 0;
`;

ListItemContent.defaultProps = { truncate: false };

export const ListItemAvatar = styled.div`
	flex: 0 0 10%;
`;

export interface ListItemProps extends HTMLAttributes<HTMLLIElement> {
	button?: boolean;
	highlightOnHover?: boolean;
	highlightColor?: string;
	background?: 'default' | 'paper';
	position?: string;
}

export const ListItem = forwardRef<HTMLElement, ListItemProps>(
	(
		{
			background = 'paper',
			children,
			button,
			highlightOnHover,
			className,
			highlightColor,
			...props
		},
		ref
	) => {
		const classes = clsx(
			highlightOnHover && 'highlight-list-item-hover',
			className
		);

		return (
			<StyledListItem
				ref={ref as any}
				$background={background}
				$button={button}
				$highlightColor={highlightColor}
				role={button ? 'button' : undefined}
				className={classes}
				{...props}
			>
				{children}
			</StyledListItem>
		);
	}
);

export const ProfileListItem = forwardRef<HTMLElement, ListItemProps>(
	(
		{
			background = 'paper',
			children,
			button,
			highlightOnHover,
			className,
			highlightColor,
			...props
		},
		ref
	) => {
		const classes = clsx(
			highlightOnHover && 'highlight-list-item-hover',
			className
		);

		return (
			<StyledProfileItem
				ref={ref as any}
				$background={background}
				$button={button}
				$highlightColor={highlightColor}
				role={button ? 'button' : undefined}
				className={classes}
				{...props}
			>
				{children}
			</StyledProfileItem>
		);
	}
);

const StyledList = styled.ul`
	list-style-type: none;
`;

interface ListProps extends HTMLAttributes<HTMLUListElement> {}

export const List = forwardRef<HTMLElement, ListProps>(
	({ children, ...props }, ref) => {
		return (
			<StyledList ref={ref as any} {...props}>
				{children}
			</StyledList>
		);
	}
);
