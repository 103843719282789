import { ViewMode } from 'features/compositeViews/types';
import { useEffect, useRef } from 'react';
import {useMatch, useNavigate, useSearchParams} from 'react-router-dom';
import useViewModeParams from "./useViewModeParams";
import {DataViewMode} from "../../browser/types/uiTypes";

export interface View {
	match: ViewMode | DataViewMode;
	element: JSX.Element;
}

export interface RenderViewConfig {
	viewDefault: string;
	views: View[];
}

const useRenderView = ({ views, viewDefault }: RenderViewConfig) => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const {getViewMode} = useViewModeParams();
	//  convert possible undefined to an empty string to avoid any comparison
	// shenanigans

	const toMatch = getViewMode() ?? viewDefault;
	// console.log(toMatch)
	// searchParams.get(VIEW_MODE_SEARCH_PARAM) ?? '';

	const explicitMatch = views.find((v) => v.match === toMatch);

	const defaultTracker = useRef({ hasDefaulted: false });

	useEffect(() => {
		if (explicitMatch) {
			defaultTracker.current.hasDefaulted = false;
			return;
		}

		if (toMatch === viewDefault || toMatch === 'data' || !explicitMatch) {
			if (defaultTracker.current.hasDefaulted) {
				// if we get here, the default specified by passed-in config doesn't match any of the
				// passed-in components, so something has gone badly wrong.
				// NB: this logic depends on this hook unmounting as a result of the 404 navigation.
				// If that doesn't happen, 'hasDefaulted' will remain in a dirty state, which could create
				// very odd behavior.
				return navigate('/404');
			}
			// either there was no view param, or its value doesn't match any of the options passed into
			// the hook.
			defaultTracker.current.hasDefaulted = true;
			// searchParams.set(VIEW_MODE_SEARCH_PARAM, viewDefault);
			// return setViewMode(viewDefault as ViewMode);
		}
	}, [
		toMatch,
		setSearchParams,
		viewDefault,
		explicitMatch,
		navigate,
		searchParams,
	]);

	if (explicitMatch) {
		return () => explicitMatch.element;
	}

	return () => null;
};

export default useRenderView;
