import {FormResults} from '../../../common/Form';
import Typography from '../../../common/text/Typography';
import {useCreateQuestionMutation} from '../../api';
import {CreateQuestionFormValues} from '../../../common/questions/types/questionTypes';
import {EntityActionFormProps} from '../common/commonTypes';
import {StyledTextArea} from '../common/styledComponents';
import React, {FunctionComponent, useCallback} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import {useAppDispatch} from "../../../app/hooks";
import useModalType from "../../HUD/hooks/useModalType";
import {setModalType} from "../../HUD/state/HUDSlice";

const CreateQuestionForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
	const {_id, canEdit} = modalProps;

    const {handleSubmit, register, formState} =
        useForm<CreateQuestionFormValues>({
            defaultValues: {
                question: '',
            },
        });

    const validatingRegister = useCallback(
        (inputName: keyof CreateQuestionFormValues, options?: any) =>
            register(inputName, options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const [ask, mutationResults] = useCreateQuestionMutation();

    const onSubmit: SubmitHandler<CreateQuestionFormValues> = (vals) => {
        // ask({ entityId: _id, body: createQuestionFormToPayload(vals) });
    };

    const onAskSuccess = () => closeModal();

    return (
        <AppModal
            label={"Create Location"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit ? canEdit : true}>
            <label htmlFor="question">
                <Typography>
                    What is your question?
                </Typography>
            </label>
            <StyledTextArea
                {...validatingRegister('question')}
                id="question"
            />

            <FormResults
                {...mutationResults}
                validationErrors={formState.errors}
                onSuccess={onAskSuccess}
                onSuccessDelay={1500}
            />
        </AppModal>
    );
};

export default CreateQuestionForm;
