import React, {FunctionComponent} from "react";
import MenuButton from "../buttons/MenuButton";
import MenuPanel from "./MenuPanel";
import SubMenuButton from "../buttons/SubMenuButton";
import TextButton from "../buttons/TextButton";
import {
    VIEW_AS,
    ACCOUNT_STATUS,
    ARCHIVE,
    ACTIVITY_LOG,
    PROFILE_SETTINGS,
} from "../../features/graph/CONSTANTS";
import {BaseUser} from "../../features/authentication/types/userTypes";
import UserSettingsModal from "../modals/UserSettingsModal";

const userActions = [
    {
        actionTitle: VIEW_AS,
        modalType: 'viewAs',
        divider: false,
        requiresEdit: true,
        form: React.Fragment,
    },
    {
        actionTitle: ACCOUNT_STATUS,
        modalType: 'accountStatus',
        divider: true,
        requiresEdit: true,
        form: React.Fragment,
    },
    {
        actionTitle: ARCHIVE,
        modalType: 'archive',
        divider: true,
        kind: 'modal',
        requiresEdit: true,
        form: React.Fragment,
    },
    {
        actionTitle: ACTIVITY_LOG,
        modalType: 'activityLog',
        kind: 'modal',
        requiresEdit: true,
        form: React.Fragment,
    },
    {
        actionTitle: PROFILE_SETTINGS,
        modalType: 'userSettings',
        kind: 'modal',
        requiresEdit: true,
        form: UserSettingsModal,
    },
]

export const ShareButton: FunctionComponent = () => {
    return <SubMenuButton label={'Share'}>
        <TextButton
            color='primary'
            fillColor='transparent'
            text={'Copy Object Link'}
        />
    </SubMenuButton>
}

export const UserMenu: FunctionComponent<{ user: BaseUser; canEdit: boolean; }> = (
    {user, canEdit}
) => {
    return <MenuButton placement={'right-start'}>
        {user && canEdit ? <>
                <MenuPanel
                    resource={user}
                    canEdit={canEdit}
                    actions={userActions}
                />
            </>
            : null}
    </MenuButton>;
};

export default UserMenu;
