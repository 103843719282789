import Button from '../../../common/buttons/Button';
import FlexContainer from '../../../common/FlexContainer';
import { FormResults } from '../../../common/Form';
import Heading from '../../../common/text/Heading';
import Typography from '../../../common/text/Typography';
import { useDeleteAttributeMutation } from '../../api';
import { AttrActionFormProps } from '../common/commonTypes';
import {
	StyledFlexContainer,
	StyledPaper,
	StyledDeleteButton,
} from '../common/styledComponents';
import { FunctionComponent, MouseEventHandler } from 'react';
import useModalType from "../../HUD/hooks/useModalType";
import AppModal from "../../../common/modals/AppModal";

const DeleteAttributeForm: FunctionComponent = () => {

	 const {modalProps, closeModal} = useModalType();
	const {objectId: _id, resource, canEdit} = modalProps;

	const [deleteAttr, deletionResult] = useDeleteAttributeMutation();

	const onClick: MouseEventHandler = () => {
		deleteAttr({ attrId: _id });
	};

	const onSuccessfulDelete = () => closeModal();

	return (
		<AppModal isForm={false} label={'Delete Attribute'} isOpen={true} isDirty={false} onClose={closeModal} onSubmit={() => {}} canEdit={true} >
			<StyledPaper>
				<StyledFlexContainer flexDirection="column" alignItems="center">
					<Heading component="h2">
						<Typography>
							Are you sure you want to delete {resource.singular}?
						</Typography>
					</Heading>
					<FlexContainer
						style={{ width: '50%' }}
						justifyContent="space-between"
					>
						<Button onClick={closeModal}>
							Go Back
						</Button>
						<StyledDeleteButton onClick={onClick}>
							Delete
						</StyledDeleteButton>
					</FlexContainer>
					<FormResults
						{...deletionResult}
						validationErrors={{}}
						onSuccess={onSuccessfulDelete}
						onSuccessDelay={1500}
					/>
				</StyledFlexContainer>
			</StyledPaper>
		</AppModal>
	);
};

export default DeleteAttributeForm;
