import React, {FunctionComponent} from "react";
import FlexContainer from "../FlexContainer";
import styled from "styled-components";
import {BaseUser} from "../../features/authentication/types/userTypes";
import {BaseOrg} from "../../features/ontology/types/orgTypes";
import Typography from "../text/Typography";
import useCurrentUser from "../../features/authentication/hooks/useUser";
import ProfileAvatar
    from "../../features/navigation/components/Navbar/ProfileAvatar";
import {IconButtonBase} from "../buttons/IconButton";
import {useNavigate} from "react-router";

const StyledAvatarContainer = styled.div`
     width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid ${(p) => p.theme.palette.cyan};
    background-color: ${(p) => p.theme.palette.darkBaby};
    overflow: hidden;
    position: relative;
`
const StyledAvatar = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;    
    margin-top: -6px;
    cursor: pointer;
    transition: transform 0.3s ease;
    position: relative;
    &:hover {
        transform: translateY(-10px);
    }
`

const HiddenAvatar = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${(p) => p.theme.palette.darkerBaby};
    border: 2px solid ${(p) => p.theme.palette.primary.main};
    color: ${(p) => p.theme.palette.primary.main};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 3px;
`

// const StyledAvatar = styled.div<{width?: string, height?: string}>`
//   position:relative;
//   display:inline-block;
//   height: ${(p) => p.height ? p.height : '32px'};
//   width: ${(p) => p.width ? p.width : '32px'};
// `

// const StyledAvatarImage = styled.img`
//   /*width:100px;*/
//   /*height:100px;*/
//   object-fit:cover;
//   border-radius:100%;
// `

const StyledAvatarStatus = styled.span`
  width: 8px;
  height: 8px;
  background-color: ${(p) => p.theme.palette.primary.main};
  border: 1px solid ${(p) => p.theme.palette.cyan};
  position: absolute;
  bottom: 1%;
  right: 1%;
  border-radius: 100%;
`

const StyledLargeAvatarStatus = styled.span`
  width: 22px;
  height: 22px;
  background-color: #60b3a1;
  border: 3px solid #3e4354;
  position: absolute;
  bottom: 2%;
  right: 2%;
  border-radius: 100%;
`

const Avatar: FunctionComponent<{ resource: any,  onHold?: boolean; onHover?: () => void; onClick?: () => void; onExit?: (onHold: boolean) => void; }> = ({onHold, onHover, onExit, onClick, resource}) => {

    const currentUser = useCurrentUser();

    const isOnline = resource.lastActive === 'online';

    const isSelf = resource
        && currentUser
        && resource._object === 'User'
        && currentUser._id === resource._id;

    let onlineStyle = isOnline
        ? "avatar-online"
        : "avatar-offline";

    const navigator = useNavigate();


    return <StyledAvatar
        onClick={() => navigator(`/${resource.username}`, { replace: false })}
        onMouseEnter={onHover}
        onMouseLeave={() => onHold && onExit && onExit(onHold)}
        className={`unselectable avatar ${onlineStyle}`}>
        <StyledAvatarContainer>

        <img
            style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
            }}
            src={resource._object === 'User'
                ? resource.avatar.medium.url
                : resource.avatar.medium}
        />
                    </StyledAvatarContainer>
            {isOnline ? <StyledAvatarStatus/> : null}
    </StyledAvatar>

}


const AvatarGroup: FunctionComponent<{ items: BaseUser[] | BaseOrg[] }> = ({items}) => {
    return <FlexContainer
        flexDirection='column'
        className='unselectable avatar-group'>
        {items.slice(0, 3).map((item, index: number) => {
            return <Avatar
                key={`${item.username}${index}`}
                resource={item}
            />
        })}
        <HiddenAvatar className='hidden-avatars'>
            <Typography variant={'h5'} color={'primary'}>
                +{items.slice(3).length}
            </Typography>
        </HiddenAvatar>
    </FlexContainer>;
}  // style={{direction: 'rtl'}}

export default AvatarGroup;
