import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";
import Textbox from "./TextBox";

const PluralInput: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <Textbox
        style={{
            height: '50px',
            width: '100%',
            marginBottom: '2px'
        }}
        autoComplete="username"
        labelText="Email or username"
        error={formState.errors.username}
        {...validatingRegister('username', {
            required: {
                value: true,
                message: 'Username is required',
            },
        })}
    />;
}

export default PluralInput;
