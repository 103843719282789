import {
	AttributeSubtype,
	BaseAttribute,
} from '../../ontology/types/attributeTypes';
import { boolStringToBool, emptyStringToNull } from '../common/helpers';
import {
	DescribeAttrFormValues,
	DescribeAttrPayload,
} from './describeAttributeTypes';

export const describeAttrFormDefaults = (
	attr: BaseAttribute
): DescribeAttrFormValues => ({
	singular: attr.singular,
	plural: attr.plural,
	definition: attr.definition ?? '',
	type: attr.type,
	otherType: attr.subtype ?? '',
	defaultValue: attr.defaultValue ?? '',
	isRequired: attr.isRequired === undefined || attr.isRequired === null
		? false : attr.isRequired,
	isDisplay: attr.isDisplay === undefined || attr.isDisplay === null
		? false : attr.isDisplay
});

export const describeAttrFormToPayload = (
	formValues: DescribeAttrFormValues
): DescribeAttrPayload => ({
	singular: formValues.singular,
	plural: formValues.plural,
	definition: emptyStringToNull(formValues.definition),
	type: formValues.type,
	otherType: emptyStringToNull(
		formValues.otherType
	) as AttributeSubtype | null,
	defaultValue: emptyStringToNull(formValues.defaultValue),
	isRequired: formValues.isRequired,
	isDisplay: formValues.isDisplay,
});
