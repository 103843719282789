import { DataCrumbLink } from '../components/DataCrumbs/DataCrumbBase';
import useDataParams from './useDataBrowserParams';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isAppError, isEmpty } from 'common/utils/typeGuards';
import {
	useGetOrgsQuery,
	useGetSourceSummariesQuery,
	useGetLiveDataCatalogsQuery,
	useGetLiveDataSetsQuery,
} from 'features/api';
import { mergeQueryStates, QueryStateTracker } from 'features/api/helpers';
import useUserID from 'features/authentication/hooks/useUserID';
import {DataContextLevels} from 'features/navigation/types/navigationContextTypes';
import useActiveOrgData from 'features/ontology/hooks/useActiveOrgData';
import { useMemo } from 'react';
import useActiveSourceData from "../../ontology/hooks/useActiveSourceData";
import useActiveCatalogData from "../../ontology/hooks/useActiveCatalogData";
import {useParams} from "react-router-dom";
import useOrgID from "../../authentication/hooks/useOrgID";
import {hasOwnProperty} from "../../../common/utils/typeUtils";

const useDataCrumbQuery = (kind: DataContextLevels) => {
	const params = useParams();
	const { linkBuilder, ...urlParams } = useDataParams(kind);

	const userId = useUserID();
	const orgId = useOrgID();
	const shouldQueryOrgs = kind === 'username' && !isEmpty(userId);

	//  NB: These queries look 'heavier' than they are. Because the 'useActive*'
	// hooks above query for all relevant objects then filter (instead of hitting an endpoint
	// for a single object), the data for the logic below is going to end up in the cache one way
	// or another.
	const orgs = useGetOrgsQuery(
		shouldQueryOrgs ? { userId } : skipToken
	);

	// TODO: there is a case maybe for making these all a single hook.
	const { activeItem: activeOrg } = useActiveOrgData();
	const { activeItem: activeSource } = useActiveSourceData();

	const shouldQuerySources = kind === 'source' && !!activeOrg;

	const sources = useGetSourceSummariesQuery(
		shouldQuerySources ? { orgId: activeOrg._id } : skipToken
	);


	const shouldQueryCatalogs = kind === 'catalog' && !!activeSource;
	const catalogs = useGetLiveDataCatalogsQuery(
		shouldQueryCatalogs ? { sourceId: activeSource._id } : skipToken
	);
	// const { activeItem: activeCatalog } = useActiveCatalogData();

	// const { activeItem: activeCatalog } = useActiveCatalogData();
	const shouldQueryDatasets = kind === 'dataset' && !!activeSource;
	const datasets = useGetLiveDataSetsQuery(
		!shouldQueryDatasets || params.catalog === undefined
			? skipToken
			: { sourceId: activeSource._id, catalogName: params.catalog}
	);

	const { data, ...loadingState } =
		kind === 'username'
			? orgs
			: kind === 'source'
			? sources
			: kind === 'catalog'
				? catalogs
				: datasets;

	const linkData: DataCrumbLink[] = useMemo(() => {
		if (data) {
			const builder = isAppError(linkBuilder) ? () => '/' : linkBuilder;

			return data.map((d) => ({
				label: d.label,
				url: builder(d.name),
				name: d.name,
				kind: kind,
			}));
		}

		return [];
	}, [data, linkBuilder]);

	//  by expressing errors generated outside of RTK Query in the same format, we can programatically
	// combine outside error/success states with query errors.
	const synchronousLoadingState: QueryStateTracker = {
		isLoading: false,
		isError: isAppError(linkBuilder),
		error: isAppError(linkBuilder)
			? { message: linkBuilder.message }
			: undefined,
		isUninitialized: false,
		isSuccess: !isAppError(linkBuilder),
	};

	return {
		...mergeQueryStates(loadingState, synchronousLoadingState),
		links: linkData,
		// cast here b/c if there's a problem with URLparams, linkBuilder will be an error anyway
		activeName: urlParams[kind] as string,
	};
};

export default useDataCrumbQuery;
