import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
	FontAwesomeIcon,
	FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import FlexContainer from "./FlexContainer";
import {LOAD_SPINNER_TEST_ID} from "./loading/Spinner";

const StyledSuccessContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(p) => p.theme.palette.success.main};
`;

const StyledIcon = styled(FontAwesomeIcon)`
	color: ${(p) => p.theme.palette.success.main};
`;

interface SuccessIconProps extends Omit<FontAwesomeIconProps, 'icon'> {
	children?: ReactNode;
}

const Success: FunctionComponent<SuccessIconProps> = ({
	children,
	...restProps
}) => {
	return (
		<FlexContainer
			data-testid="form-success"
			style={{
				width: '100vw',
				height: '100vh',
				position: 'fixed',
				top: 0,
				left: 0,
				pointerEvents: 'none'
			}}
			alignItems='center'
			justifyContent="center">
			{children}
			<StyledIcon
				icon={faCheckCircle}
				{...restProps}
			/>
		</FlexContainer>
	);
};

export default Success;
