import {faEdit, faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import GridContainer from 'common/GridContainer';
import Heading from 'common/text/Heading';
import {BasePaper} from 'common/Paper';
import Typography from 'common/text/Typography';
import theme from 'common/theme/theme';
// import { format } from 'date-fns';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import React, {
    FunctionComponent,
    useEffect,
    useState,
    FormEvent,
    ReactNode, useCallback,
} from 'react';
import {usePopperTooltip} from 'react-popper-tooltip';
import styled from 'styled-components';
// import {ListItemAvatar} from "../../../common/List";
// import ImageAvatar from "../../../common/ImageAvatar";
// import {StyledMemberDataListItem} from "../../governance/components/styledComponents";
import NavStats from "../../navigation/components/Navbar/NavStats";
import {
    StyledProfileGridLayout,
    OptimizedContainer,
    StyledProfilePanelGrid,
    StyledProfileBase,
    StyledProfileBodyLayout
} from "../common/styledComponents";
// import AttributeSelect from "../EntityProfile/components/AttributeSelect";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import clsx from "clsx";
import GovernancePanel from "../../governance/components/GovernancePanel";
import EntityPanel from "../../../common/lists/EntityPanel";
import DomainMenu from "../../../common/menus/DomainMenu";
import AddMemberButton from "common/buttons/AddMemberButton";
import CommentsTab from "../../../common/comments/components/CommentsTab";
import QuestionsTab from "../../../common/questions/components/QuestionsTab";
import ActivitiesTab from "../../../common/activities/components/ActivitiesTab";
import FollowersModal from "../../../common/modals/FollowersModal";
import DomainSettingsModal from "../../../common/modals/DomainSettingsModal";
import IconButton from "../../../common/buttons/IconButton";
import useCurrentUser from "../../authentication/hooks/useUser";
import {ListItem} from "../../../common/List";
import FlexContainer from "../../../common/FlexContainer";
import {StyledGraphicContainer} from "../../../common/StyledGraphicContainer";
import {StyledGraphicCircle} from "../../../common/StyledCircle";
import FollowButton from "../../../common/buttons/FollowButton";
import FollowersButton from "../../../common/buttons/FollowersButton";
import SettingsButton from "../../../common/buttons/SettingsButton";
import {mergeQueryStates} from "../../api/helpers";
import {useFollowDomainMutation, useUnfollowDomainMutation} from "../../api";
import Button from "../../../common/buttons/Button";
import useActiveOrgData from "../../ontology/hooks/useActiveOrgData";
import ProfileAvatar from "../../navigation/components/Navbar/ProfileAvatar";
import RouterLink from "../../../common/RouterLink";
import LinkedInButton from "../../../common/buttons/LinkedInButton";
import FacebookButton from "../../../common/buttons/FacebookButton";
import InstagramButton from "../../../common/buttons/InstagramButton";
import GithubButton from "../../../common/buttons/GithubButton";
import {
    StyledHeaderSection,
    StyledLabelSection,
    StyledSection
} from "../../../common/StyledSection";
import MenuButton from "../../../common/buttons/MenuButton";
import DomainPanel from "../../../common/lists/DomainPanel";
import ProfileHeader from "../OrgProfile/ProfileHeader";
import MessageButton from "../../../common/buttons/MessageButton";
import SubMenuButton from "../../../common/buttons/SubMenuButton";
import TextButton from "../../../common/buttons/TextButton";
import DestroyButton from "../../../common/buttons/DestroyButton";
import {BaseDomain, DomainModalTypes} from "../../ontology/types/domainTypes";

// grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr;

const StyledTabList = styled(TabList)`
	list-style-type: none;
	display: flex;
	width: 100%;
	height: 100%;
	gap: 2rem;
	align-items: flex-start;
	justify-content: flex-start;
`;
// margin: ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(2)};
// border-bottom: 1px solid ${(p) => p.theme.palette.divider};

const StyledTab = styled(Tab)`
	padding: ${(p) => p.theme.spacing(1)};
	&:hover {
		cursor: pointer;
		background-color: ${(p) => p.theme.palette.cyan};
	}

	&.selected {
		border-bottom: 1px solid ${(p) => p.theme.palette.background.paper};
	}
`;

const StyledProfileItem = styled(GridContainer)`
	padding: ${(p) => p.theme.spacing(2)};
	margin: 0;
		width: 100%;
	align-items: center;
	justify-content: space-between;
`;

const StyledList = styled.ul`
	width: 100%;
	list-style-type: none;
`;

const StyledPlaceholder = styled(StyledProfileItem)`
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
`;

// TODO: Depending on how db formats datetime, we may want this to read
// user's preferred timezone and format accordingly.  Docs are here:
// https://date-fns.org/docs/Getting-Started
// const formatDateString = (dateString: string) =>
// 	format(new Date(dateString), 'yyyy-MM-dd');

const StyledPaper = styled(BasePaper)`
	/* padding: ${(p) => p.theme.spacing(1)}; */
`;

interface TimezoneTooltipProps {
    children?: ReactNode;
}

const TimezoneTooltip: FunctionComponent<TimezoneTooltipProps> = ({
                                                                      children,
                                                                  }) => {
    // TODO: pry don't want this dependency--use Popper component instead,
    // since we have to have it for other things anyway.
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip();

    return (
        <>
            <FontAwesomeIcon
                style={{
                    marginLeft: '8px',
                    cursor: 'pointer',
                }}
                icon={faQuestionCircle}
                forwardedRef={setTriggerRef}
            />
            {visible && (
                <StyledPaper
                    ref={setTooltipRef as any}
                    {...getTooltipProps({className: 'tooltip-container'})}
                >
                    <div {...getArrowProps({className: 'tooltip-arrow'})} />
                    <Typography>{children}</Typography>
                </StyledPaper>
            )}
        </>
    );
};

const tabLabels = [
    'About',
    'Entities',
    // 'Forum',
    // 'Questions',
    // 'Activity',
    // 'Credits',
];

export const DomainProfile: FunctionComponent = () => {
    // TODO: make this robust
    // const { org } = useParams() as { org: string };
    const [selectedIdx, setSelectedIdx] = useState<number>(0);
    const [containerHeight, setContainerHeight] = useState<number>(300);

    const {activeItem: activeOrg} = useActiveOrgData();
    // Don't run the query if we're not navigated to right page
    const {
        isLoading,
        isFetching,
        activeItem: activeDomain
    } = useActiveDomainData();

    const onRefChange = useCallback((node: any) => {
        if (node) {
            const bounding = node.getBoundingClientRect();
            setContainerHeight(bounding.height);
        }
    }, []);

    if (isLoading || isFetching) {
        return (
            <StyledProfileBase>
                <StyledProfileGridLayout>
                    <StyledPlaceholder key="title">
                        <Heading component="h5">
                            Loading...
                        </Heading>
                    </StyledPlaceholder>
                </StyledProfileGridLayout>
            </StyledProfileBase>
        );
    }

    if (!!activeDomain) {

        const isFollowing = activeDomain.userContext.interactions.following;
        const canEdit = activeDomain.userContext.permissions.edit
            || activeDomain.userContext.permissions.admin;

        return (
            <>
                <StyledProfileBase>
                    <StyledProfileGridLayout>
                        <FlexContainer
                            ref={onRefChange}
                            style={{gridArea: 'head'}}
                            justifyContent={'flex-end'}
                            alignItems={'flex-start'}>
                            <FlexContainer style={{
                                display: 'flex',
                                placeContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                                // gap: '1rem'
                            }}>
                                <OptimizedContainer>
                                    <StyledHeaderSection>
                                        {activeOrg.avatar.medium
                                            ? <ProfileAvatar
                                                profileImageURL={activeOrg.avatar.medium}
                                                initial={activeDomain.name[0]}
                                                isProfile={true}
                                                onClick={() => {
                                                }}
                                                styles={{gridArea: 'avatar'}}
                                                isOnline={false}
                                                height={'6rem'}
                                                width={'6rem'}
                                            />
                                            : <StyledGraphicContainer style={{
                                                gridArea: 'avatar',
                                                height: '6rem',
                                                width: '6rem'
                                            }}>
                                                <StyledGraphicCircle style={{
                                                    height: '6rem',
                                                    width: '6rem'
                                                }}/>
                                            </StyledGraphicContainer>
                                        }
                                        <StyledLabelSection style={{
                                            gridArea: 'header',
                                            width: '100%'
                                        }}>
                                            <RouterLink
                                                to={`/${activeDomain && activeDomain.ontology ? activeDomain.ontology.org : ''}`}
                                                preserveSearchParams={false}>
                                                <Typography
                                                    style={{gridArea: 'overline'}}
                                                    variant="caption1">
                                                    {activeDomain.orgName}
                                                </Typography>
                                            </RouterLink>
                                            <Heading
                                                style={{gridArea: 'title',}}
                                                component="h1">
                                                {activeDomain.label}
                                            </Heading>
                                            <FollowersButton
                                                resource={activeDomain}/>
                                            <div style={{gridArea: 'url'}}/>
                                        </StyledLabelSection>
                                        <OptimizedContainer
                                            style={{
                                                gridArea: 'social',
                                                marginBottom: '2rem',
                                                marginTop: '1rem'
                                            }}
                                            gap='1rem'/>
                                    </StyledHeaderSection>
                                    <FlexContainer justifyContent={'center'}
                                                   alignItems={'center'}>
                                        <FollowButton
                                            objectAlreadyFollowed={isFollowing}
                                            resource={activeDomain}
                                        />
                                    </FlexContainer>
                                </OptimizedContainer>
                            </FlexContainer>
                            <DomainMenu
                                domain={activeDomain}
                                canEdit={canEdit}
                            />
                        </FlexContainer>
                        <Tabs
                            style={{
                                width: '100%',
                                height: '100%',
                                gridArea: 'main'
                            }}
                            selectedIndex={selectedIdx}
                            onSelect={(idx) => setSelectedIdx(idx)}>
                            <FlexContainer alignItems={'center'}>
                                <StyledTabList style={{
                                    alignItems: 'flex-start',
                                    alignContent: 'flex-start',
                                    justifyContent: 'flex-start',
                                    justifyItems: 'flex-start',
                                    gap: '.5rem',
                                    borderBottom: `1px solid ${theme.palette.divider}`
                                }}>
                                    {tabLabels.map((label, i) => (
                                        <StyledTab
                                            className={clsx(i === selectedIdx && 'selected')}
                                            key={label}>
                                            <Typography variant='h6'
                                                        color='oldManGray'>
                                                {label}
                                            </Typography>
                                        </StyledTab>
                                    ))}
                                </StyledTabList>
                            </FlexContainer>
                            <div style={{
                                maxHeight: `calc(100vh - ${containerHeight + theme.panelGutter * 4 + theme.navHeight * 3}px)`,
                                width: '100%',
                                overflowY: 'auto'
                            }}>
                                {!!activeDomain && [
                                    {
                                        Panel: EntityPanel,
                                        args: {domainId: activeDomain._id}
                                    },
                                    {
                                        Panel: EntityPanel,
                                        args: {domainId: activeDomain._id}
                                    }
                                ].map(({Panel, args}, i) => {
                                    return i === selectedIdx
                                        ? <TabPanel
                                            key={i}><Panel {...args}/></TabPanel>
                                        : <TabPanel key={i}/>;
                                })}
                            </div>
                        </Tabs>
                    </StyledProfileGridLayout>
                </StyledProfileBase>
            </>
        );
    }

    return null;
};

export default DomainProfile;
