import { AttrTypeCounts, EntityVizMapper } from '../types/entityTypes';
import { flatten2D, pipe } from 'common/utils/functionUtils';
import { isNumber } from 'common/utils/typeGuards';
import { resolveAttrType } from 'features/ontology/typeGuards/attributeGuards';
import {
	AttributeTypeData,
	BaseAttribute,
	ResolvedAttributeType,
} from 'features/ontology/types/attributeTypes';
import {
	GetRestrictionsParams,
	RestrictionSubject
} from 'features/ontology/types/entityTypes';
import {
	CATEGORY_BAR_CHART,
	RELATION_BAR_CHART,
	EVENT_LINE_CHART,
	QTY_HISTO,
	QTY_SCATTERPLOT,
	INDIVIDUALS_TABLE,
} from 'common/viz/CONSTANTS';
import {getURLStumpFromObjectType} from "../../../common/comments/helpers";

const reduceResolvedType = (
	acc: AttrTypeCounts,
	resolvedType: ResolvedAttributeType
) => {
	const existingCount = acc[resolvedType];

	acc[resolvedType] = existingCount ? existingCount + 1 : 1;

	return acc;
};

export const makeDeriveEntityViz =
	(mappers: EntityVizMapper[]) =>
	(as: AttributeTypeData[], activeAttribute: BaseAttribute) => {
		return pipe(
			as
				.map(resolveAttrType)
				.reduce(reduceResolvedType, {} as AttrTypeCounts),
			(count) => mappers.map((m) => m(count, activeAttribute)),
			flatten2D
		);
	};

// const makeVizMapper =
// 	(
// 		key: AttributeBaseType | AttributeSubtype | null,
// 		tester: (keyResult: number | undefined) => boolean,
// 		onTrue: EntityVizKind[]
// 	): EntityVizMapper =>
// 	(counts: AttrTypeCounts) =>
// 		key === null
// 			? tester(undefined)
// 				? onTrue
// 				: []
// 			: tester(counts[key])
// 			? onTrue
// 			: [];

// const lineage = makeVizMapper(null, () => true, [LINEAGE_GRAPH]);

const qtyHisto: EntityVizMapper = (counts, activeAttr) =>
	resolveAttrType(activeAttr) === 'quantity' ? [QTY_HISTO] : [];

const qtyScatterplot: EntityVizMapper = (counts, activeAttr) => {
	const ct = counts['quantity'];

	//  drawing a quantity scatterplot requires at least 2 attributes of type 'quantity'
	if (
		typeof ct === 'number' &&
		ct >= 2 &&
		resolveAttrType(activeAttr) === 'quantity'
	) {
		return [QTY_SCATTERPLOT];
	}

	return [];
};

const eventLineChart: EntityVizMapper = (counts, activeAttr) => {
	// drawing an event-based line chart requires at least one attribute of resolved type 'ISODateString',
	// and at least one attribute of resolved type 'quantity'.
	const eventCt = counts['ISODateString'];

	if (
		isNumber(eventCt) &&
		eventCt > 0 &&
		resolveAttrType(activeAttr) === 'ISODateString'
	) {
		return [EVENT_LINE_CHART];
	}

	return [];
};

const categoryBarChart: EntityVizMapper = (counts, activeAttr) =>
	resolveAttrType(activeAttr) === 'category' ? [CATEGORY_BAR_CHART] : [];

const relationBarChart: EntityVizMapper = (counts, activeAttr) =>
	resolveAttrType(activeAttr) === 'functional' ? [RELATION_BAR_CHART] : [];

const individualsTable: EntityVizMapper = (counts, activeAttr) =>
	activeAttr.isPrimary ? [INDIVIDUALS_TABLE] : [];

const entityProfileViz = [
	individualsTable,
	qtyHisto,
	qtyScatterplot,
	categoryBarChart,
	relationBarChart,
	eventLineChart,
];

const spatialHUDViz = [qtyHisto, categoryBarChart];

export const deriveEntityProfileViz = makeDeriveEntityViz(entityProfileViz);

export const deriveSpatialHUDViz = makeDeriveEntityViz(spatialHUDViz);

export const buildRestrictionsQueryURL = ({
	objectId,
	objectType,
}: GetRestrictionsParams) =>
	`/${getURLStumpFromObjectType(objectType)}/${objectId}/restrictions`;

export const buildParametrizedRestrictionsQueryURL = (
	objectType: RestrictionSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/restrictions`;
