import Button from 'common/buttons/Button';
import FlexContainer from 'common/FlexContainer';
import { FormResults } from 'common/Form';
import Heading from 'common/text/Heading';
import Typography from 'common/text/Typography';
import { useAcceptTOSMutation } from 'features/api';
import useTOSAccepted from 'features/authentication/hooks/useTOSAccepted';
import { FunctionComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import theme from "../../../common/theme/theme";
import { Navigate } from 'react-router-dom';

const StyledForm = styled.form`
	padding: ${(p) => p.theme.spacing(2)};
	padding-bottom: 0;
	max-width: 80%;
	display: flex;
`;

const StyledMain = styled.div`
	background-color: ${(p) => p.theme.palette.darkBaby};
	width: 100%;
	height: 100vh;
	color: ${(p) => p.theme.palette.lightBaby};
`;

const StyledContainer = styled(FlexContainer)`
	margin: auto;
	position: fixed;
	padding: 2rem;
	left: 0;
	right: 0;
	width: 60%;
	gap: ${(p) => p.theme.spacing(0)};

	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		width: 80%;
	}
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.lg}px) {
		width: 70%;
	}

	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		width: 100%;
	}
`;

const StyledCheckbox = styled.span`
	background-color: #29323c !important;
	color: #29323c !important;

	&:hover input ~ .checkmark {
		background-color: ${(p) => p.theme.palette.oldManGray} !important;
	}
	&:checked ~ .checkmark {
		background-color: #29323c;
	}
	&:after {
		border-color: ${(p) => p.theme.palette.primary.main} !important;
	}
`;

// const StyledContainer = styled.div`
// 	padding: ${(p) => p.theme.spacing(2)};
// 	width: 50%;
// 	border-radius: ${(p) => p.theme.spacing(0.5)};
// 	border: 1px solid ${(p) => p.theme.palette.divider};

interface AcceptanceFormValues {
	acceptTerms: boolean;
}

const AcceptanceForm: FunctionComponent = () => {
	const navigate = useNavigate();

	const { register, watch, handleSubmit } = useForm<AcceptanceFormValues>();

	const acceptanceState = watch('acceptTerms');

	const [acceptTOS, acceptanceResult] = useAcceptTOSMutation();

	const onSubmit: SubmitHandler<AcceptanceFormValues> = () => {
		//   TODO: figure out why I can't call this w/out an argument according to TS
		acceptTOS(null);
	};

	const onSuccess = () => navigate('/onboarding');

	//  Shenanigans here: IF successfully updating TOS busts the 'getUser' cache and the query
	// reruns before the timeout
	//  of the 'onSuccess' callback completes, we need to keep rendering FormResults even though 'tosAccepted' on the user
	//  object is true and there's nothing to see--if we unmount FormResults, the useEffect cleanup function in
	// FormResults runs and onSuccess never gets executed

	return (
		<div style={{margin: '0 auto', width: '200px'}}>
		<StyledForm
			aria-label="accept terms of service"
			onSubmit={handleSubmit(onSubmit)}
		>
			<FlexContainer justifyContent="space-between" alignItems="center">
				<label
					htmlFor="accept-terms-box"
					style={{
						paddingRight: '8px',
						position: 'relative',
						fontSize: '14px',
						top: '2px',
						width: '100px'
					}}
					className="container"
				>
					<span style={{
						display: 'inline-block',
						position: 'relative',
						top: '4px',
						color: '#c1c9d2',
						fontWeight: '500'
					}}>
					I Accept
					</span>
					<input
						id="accept-terms-box"
						{...register('acceptTerms', {
							required: 'Please accept terms to continue',
						})}
						type="checkbox"
					/>
					<StyledCheckbox className="checkmark" />
				</label>
				<Button
					style={{fontSize: '12px', width: '75px', color: '#29323c'}}
					disabled={!acceptanceState}>
					Next
				</Button>
			</FlexContainer>
			<FormResults
				{...acceptanceResult}
				onSuccess={onSuccess}
				onSuccessDelay={1000}
			/>
		</StyledForm>
			</div>
	);
};

const StyledTOSBox = styled.section`
	overflow-y: auto;
	height: 50vh;
	display: flex;
	flex-direction: column;
	max-height: 600px;
	gap: 1rem;
	margin: 2rem;
	padding: ${(p) => p.theme.spacing(2)};
	background-color: ${(p) => p.theme.palette.lightBaby};
	border-radius: ${(p) => p.theme.spacing(0.5)};
	color: ${(p) => p.theme.palette.darkBaby};
	font-weight: 700 !important;
`;

const TOSPage: FunctionComponent = () => {

	return (
		<StyledMain>
			<StyledContainer flexDirection="column">
				<Heading
					style={{
						textAlign: 'left',
						color: theme.palette.darkerBaby,
						paddingLeft: theme.spacing(4),
						marginBottom: theme.spacing(2),
					}}
					component="h1"
				>
					Terms of Service
				</Heading>
				<StyledTOSBox>
					<Typography paragraph variant={'body1'}>
						Futuremodel builds data technologies and services that
						enable people to connect with each other, build
						communities, and grow businesses. These Terms govern
						your use of Futuremodel, Witchcraft, and the other products,
						features, apps, services, technologies, and software we
						offer (the Futuremodel Products or Products), except where we
						expressly state that separate terms (and not these)
						apply. These Products are provided to you by
						Futuremodel Inc.
					</Typography>

					<Typography paragraph variant={'body1'}>
						We don’t charge you to use Futuremodel or the other
						products and services covered by these Terms, unless
						we state otherwise. Instead, businesses and
						organizations, and other persons pay us to manage their
						data for their products and services and make their data
						available to you. We use your personal data to help
						determine which datasets to show you.
					</Typography>

					<Typography paragraph variant={'body1'}>
						We don’t sell your personal data to advertisers, and
						we don’t share information that directly identifies you
						(such as your name, email address or other contact
						information) with advertisers unless you give us
						specific permission. Instead, advertisers can tell us
						things like the kind of audience they want to see their
						ads, and we show those ads to people who may be
						interested. We provide advertisers with reports about
						the performance of their ads that help them understand
						how people are interacting with their content. See
						Section 2 below to learn more about how personalized
						advertising under these terms works on the Futuremodel
						Products.
					</Typography>

					<Typography paragraph>
						Our Privacy Policy explains how we collect and use your
						personal data to determine some of the ads you see and
						provide all of the other services described below. You
						can also go to your settings pages of the relevant Futuremodel
						Product at any time to review the privacy choices you
						have about how we use your data.
					</Typography>
					<Typography variant={'h4'} color={'darkBaby'}>
						1. The services we provide
					</Typography>
					<Typography paragraph>
						Our mission is to give people the power to build
						community and bring the world closer together. To help
						advance this mission, we provide the Products and
						services described below to you:
					</Typography>
					<Typography variant={'h5'}>
						Provide a personalized experience for you:
					</Typography>
					<Typography paragraph>
						Your experience on Facebook is unlike anyone else's:
						from the posts, stories, events, ads, and other content
						you see in Facebook News Feed or our video platform to
						the Facebook Pages you follow and other features you
						might use, such as Facebook Marketplace, and search.
						For example, we use data about the connections you make,
						the choices and settings you select, and what you share
						and do on and off our Products - to personalize your
						experience.
					</Typography>
					<Typography variant={'h5'}>
						Connect you with people and organizations you care about:
					</Typography>
					<Typography paragraph>
						We help you find and connect with people, groups,
						businesses, organizations, and others that matter to
						you across the Meta Products you use. We use data to
						make suggestions for you and others - for example,
						groups to join, events to attend, Facebook Pages to
						follow or send a message to, shows to watch, and people
						you may want to become friends with. Stronger ties make
						for better communities, and we believe our services are
						most useful when people are connected to people, groups,
						and organizations they care about.
					</Typography>
					<Typography variant={'h5'}>
						Empower you to express yourself and communicate about
						what matters to you:
					</Typography>
					<Typography paragraph>
						There are many ways to express yourself on Facebook to communicate with friends, family, and others about what matters to you - for example, sharing status updates, photos, videos, and stories across the Meta Products (consistent with your settings), sending messages or making voice or video calls to a friend or several people, creating events or groups, or adding content to your profile as well as showing you insights on how others engage with your content. We have also developed, and continue to explore, new ways for people to use technology, such as augmented reality and 360 video to create and share more expressive and engaging content on Meta Products.
					</Typography>
					<Typography variant={'h5'}>
						Help you discover content, products, and services that may interest you:
					</Typography>
					<Typography paragraph>
						We show you personalized ads, offers, and other sponsored or commercial content to help you discover content, products, and services that are offered by the many businesses and organizations that use Facebook and other Meta Products. Section 2 below explains this in more detail.
					</Typography>
					<Typography variant={'h5'}>
						Promote the safety, security, and integrity of our services, combat harmful conduct and keep our community of users safe:
					</Typography>
					<Typography paragraph>
						People will only build community on Meta Products if they feel safe and secure. We work hard to maintain the security (including the availability, authenticity, integrity, and confidentiality) of our Products and services. We employ dedicated teams around the world, work with external service providers, partners and other relevant entities and develop advanced technical systems to detect potential misuse of our Products, harmful conduct towards others, and situations where we may be able to help support or protect our community, including to respond to user reports of potentially violating content. If we learn of content or conduct like this, we may take appropriate action based on our assessment that may include - notifying you, offering help, removing content, removing or restricting access to certain features, disabling an account, or contacting law enforcement. We share data across Meta Companies when we detect misuse or harmful conduct by someone using one of our Products or to help keep Meta Products, users and the community safe. For example, we share information with Meta Companies that provide financial products and services to help them promote safety, security and integrity and comply with applicable law. Meta may access, preserve, use and share any information it collects about you where it has a good faith belief it is required or permitted by law to do so. For more information, please review our Privacy Policy.
						<br />
						<br />
						In some cases, the Oversight Board may review our decisions, subject to its terms and bylaws. Learn more here.
					</Typography>
					<Typography variant={'h5'}>
						Use and develop advanced technologies to provide safe and functional services for everyone:
					</Typography>
					<Typography paragraph>
						We use and develop advanced technologies - such as artificial intelligence, machine learning systems, and augmented reality - so that people can use our Products safely regardless of physical ability or geographic location. For example, technology like this helps people who have visual impairments understand what or who is in photos or videos shared on Facebook or Instagram. We also build sophisticated network and communication technology to help more people connect to the internet in areas with limited access. And we develop automated systems to improve our ability to detect and remove abusive and dangerous activity that may harm our community and the integrity of our Products.
					</Typography>
					<Typography variant={'h5'}>
						Research ways to make our services better:
					</Typography>
					<Typography paragraph>
						We engage in research to develop, test, and improve our Products. This includes analyzing data we have about our users and understanding how people use our Products, for example by conducting surveys and testing and troubleshooting new features. Our Privacy Policy explains how we use data to support this research for the purposes of developing and improving our services.
						Provide consistent and seamless experiences across the Meta Company Products:
						Our Products help you find and connect with people, groups, businesses, organizations, and others that are important to you. We design our systems so that your experience is consistent and seamless across the different Meta Company Products that you use. For example, we use data about the people you engage with on Facebook to make it easier for you to connect with them on Instagram or Messenger, and we enable you to communicate with a business you follow on Facebook through Messenger.
					</Typography>
					<Typography variant={'h5'}>
						Ensuring access to our services:
					</Typography>
					<Typography paragraph>
						To operate our global services and enable you to connect with people around the world, we need to transfer, store and distribute content and data to our data centers, partners, service providers, vendors and systems around the world, including outside your country of residence. The use of this global infrastructure is necessary and essential to provide our services. This infrastructure may be owned, operated, or controlled by Meta Platforms, Inc., Meta Platforms Ireland Limited, or its affiliates.
					</Typography>
					<Typography paragraph>
						Lorem ipsum dolor sit, amet consectetur adipisicing
						elit. Beatae nisi molestias assumenda neque ut?
						Repudiandae amet placeat itaque esse modi autem
						assumenda doloribus exercitationem quasi, quidem labore,
						delectus voluptatibus ad officiis nam. Non odit
						excepturi blanditiis ipsa provident omnis reprehenderit,
						porro rerum suscipit unde sunt, veniam enim natus velit
						aut adipisci soluta ut quae. Iste, voluptatem ducimus
						corporis repudiandae asperiores provident, iure deserunt
						ullam magnam sequi fugit quisquam illo labore placeat
						dignissimos veniam modi doloribus quis. Blanditiis,
						alias officia dolore repellendus magni est earum
						pariatur consequuntur aperiam perferendis tenetur
						cupiditate quia similique unde voluptates id rerum odio
						suscipit quaerat harum, excepturi eligendi cum!
						Assumenda, ipsa distinctio.
						<br />
						<br />
						Accusamus illum quaerat aspernatur eveniet, voluptate
						alias suscipit veniam nostrum unde debitis aperiam
						tempore excepturi minus quasi nisi, aut nesciunt sunt
						aliquid dicta magni vitae aliquam vero. Aut fuga
						delectus, ut eveniet laborum eum ipsa provident, totam
						quibusdam facilis voluptates! Esse dignissimos nihil
						culpa dolores laudantium libero minus tempore? Mollitia,
						molestias cumque itaque obcaecati magni omnis!
						Distinctio dicta ea voluptatem quidem repellat
						perspiciatis unde, sunt, iusto perferendis, iste porro
						enim facere totam quo accusamus nobis debitis minus
						natus officiis voluptatibus aliquid architecto quibusdam
						non? Ullam iste ratione porro numquam nobis non
						incidunt, distinctio provident.
						<br />
						<br />
						Lorem ipsum dolor sit, amet consectetur adipisicing
						elit. Beatae nisi molestias assumenda neque ut?
						Repudiandae amet placeat itaque esse modi autem
						assumenda doloribus exercitationem quasi, quidem labore,
						delectus voluptatibus ad officiis nam. Non odit
						excepturi blanditiis ipsa provident omnis reprehenderit,
						porro rerum suscipit unde sunt, veniam enim natus velit
						aut adipisci soluta ut quae. Iste, voluptatem ducimus
						corporis repudiandae asperiores provident, iure deserunt
						ullam magnam sequi fugit quisquam illo labore placeat
						dignissimos veniam modi doloribus quis. Blanditiis,
						alias officia dolore repellendus magni est earum
						pariatur consequuntur aperiam perferendis tenetur
						cupiditate quia similique unde voluptates id rerum odio
						suscipit quaerat harum, excepturi eligendi cum!
						Assumenda, ipsa distinctio.
						<br />
						<br />
						Accusamus illum quaerat aspernatur eveniet, voluptate
						alias suscipit veniam nostrum unde debitis aperiam
						tempore excepturi minus quasi nisi, aut nesciunt sunt
						aliquid dicta magni vitae aliquam vero. Aut fuga
						delectus, ut eveniet laborum eum ipsa provident, totam
						quibusdam facilis voluptates! Esse dignissimos nihil
						culpa dolores laudantium libero minus tempore? Mollitia,
						molestias cumque itaque obcaecati magni omnis!
						Distinctio dicta ea voluptatem quidem repellat
						perspiciatis unde, sunt, iusto perferendis, iste porro
						enim facere totam quo accusamus nobis debitis minus
						natus officiis voluptatibus aliquid architecto quibusdam
						non? Ullam iste ratione porro numquam nobis non
						incidunt, distinctio provident.
					</Typography>
				</StyledTOSBox>
				<AcceptanceForm />
			</StyledContainer>
		</StyledMain>
	);
};


// Return to top
// 2. How our services are funded
// Instead of paying to use Facebook and the other products and services we offer, by using the Facebook Products covered by these Terms, you agree that we can show you ads that businesses and organizations pay us to promote on and off the Facebook Company Products. We use your personal data, such as information about your activity and interests, to show you ads that are more relevant to you.
// Protecting people's privacy is central to how we've designed our ad system. This means that we can show you relevant and useful ads without telling advertisers who you are. We don't sell your personal data. We allow advertisers to tell us things like their business goal, and the kind of audience they want to see their ads (for example, people between the age of 18-35 who like cycling). We then show their ad to people who might be interested.
// We also provide advertisers with reports about the performance of their ads to help them understand how people are interacting with their content on and off Facebook. For example, we provide general demographic and interest information to advertisers (for example, that an ad was seen by a woman between the ages of 25 and 34 who lives in Madrid and likes software engineering) to help them better understand their audience. We don’t share information that directly identifies you (information such as your name or email address that by itself can be used to contact you or identifies who you are) unless you give us specific permission. Learn more about how Facebook ads work here.
// We collect and use your personal data in order to provide the services described above to you. You can learn about how we collect and use your data in our Data Policy. You have controls over the types of ads and advertisers you see, and the types of information we use to determine which ads we show you. Learn more.
// Return to top
// 3. Your commitments to Facebook and our community
// We provide these services to you and others to help advance our mission. In exchange, we need you to make the following commitments:
// 1. Who can use Facebook
// When people stand behind their opinions and actions, our community is safer and more accountable. For this reason, you must:
// Provide for your account the same name that you use in everyday life.
// Provide accurate information about yourself.
// Create only one account (your own) and use it for personal purposes.
// Not share your password, give access to your Facebook account to others, or transfer your account to anyone else (without our permission).
// We try to make Facebook broadly available to everyone, but you cannot use Facebook if:
// You are under 13 years old.
// You are a convicted sex offender.
// We've previously disabled your account for violations of our Terms or the Community Standards, or other terms and policies that apply to your use of Facebook. If we disable your account for a violation of our Terms, the Community Standards, or other terms and policies, you agree not to create another account without our permission. Receiving permission to create a new account is provided at our sole discretion, and does not mean or imply that the disciplinary action was wrong or without cause.
// You are prohibited from receiving our products, services, or software under applicable laws.
// 2. What you can share and do on Meta Products
// We want people to use Meta Products to express themselves and to share content that is important to them, but not at the expense of the safety and well-being of others or the integrity of our community. You therefore agree not to engage in the conduct described below (or to facilitate or support others in doing so):
// You may not use our Products to do or share anything:
// That violates these Terms, the Community Standards, or other terms and policies that apply to your use of our Products.
// That is unlawful, misleading, discriminatory or fraudulent (or assists someone else in using our Products in such a way).
// That you do not own or have the necessary rights to share.
// That infringes or violates someone else's rights, including their intellectual property rights (such as by infringing another’s copyright or trademark, or distributing or selling counterfeit or pirated goods), unless an exception or limitation applies under applicable law.
// You may not upload viruses or malicious code, use the services to send spam, or do anything else that could disable, overburden, interfere with, or impair the proper working, integrity, operation, or appearance of our services, systemes, or Products.
// You may not access or collect data from our Products using automated means (without our prior permission) or attempt to access data you do not have permission to access.
// You may not proxy, request, or collect Product usernames or passwords, or misappropriate access tokens.
// You may not sell, license, or purchase any data obtained from us or our services, except as provided in the Platform Terms.
// You may not misuse any reporting, flagging, dispute, or appeals channel, such as by making fraudulent, duplicative, or groundless reports or appeals.
// We can remove or restrict access to content that is in violation of these provisions. We can also suspend or disable your account for conduct that violates these provisions, as provided in Section 4.B.
// If we remove content that you have shared in violation of the Community Standards, we’ll let you know and explain any options you have to request another review, unless you seriously or repeatedly violate these Terms or if doing so may expose us or others to legal liability; harm our community of users; compromise or interfere with the integrity or operation of any of our services, systems or Products; where we are restricted due to technical limitations; or where we are prohibited from doing so for legal reasons. For information on account suspension or termination, see Section 4.B below.
// To help support our community, we encourage you to report content or conduct that you believe violates your rights (including intellectual property rights) or our terms and policies, if this feature exists in your jurisdiction.
// We also can remove or restrict access to content features, services, or information if we determine that doing so is reasonably necessary to avoid or mitigate misuse of our services or adverse legal or regulatory impacts to Meta.
// 3. The permissions you give us
// We need certain permissions from you to provide our services:
// Permission to use content you create and share: Some content that you share or upload, such as photos or videos, may be protected by intellectual property laws.
// You retain ownership of the intellectual property rights (things like copyright or trademarks) in any such content that you create and share on Facebook and other Meta Company Products you use. Nothing in these Terms takes away the rights you have to your own content. You are free to share your content with anyone else, wherever you want.
// However, to provide our services we need you to give us some legal permissions (known as a "license") to use this content. This is solely for the purposes of providing and improving our Products and services as described in Section 1 above.
// Specifically, when you share, post, or upload content that is covered by intellectual property rights on or in connection with our Products, you grant us a non-exclusive, transferable, sub-licensable, royalty-free, and worldwide license to host, use, distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of your content (consistent with your privacy and application settings). This means, for example, that if you share a photo on Facebook, you give us permission to store, copy, and share it with others (again, consistent with your settings) such as Meta Products or service providers that support those products and services. This license will end when your content is deleted from our systems.
// You can delete individual content you share, post, and upload at any time. In addition, all content posted to your personal account will be deleted if you delete your account. Learn more about how to delete your account. Account deletion does not automatically delete content that you post as an admin of a page or content that you create collectively with other users, such as photos in Shared Albums which may continue to be visible to other album members.
// It may take up to 90 days to delete content after we begin the account deletion process or receive a content deletion request. If you send content to trash, the deletion process will automatically begin in 30 days unless you chose to delete the content sooner. While the deletion process for such content is being undertaken, the content is no longer visible to other users. After the content is deleted, it may take us up to another 90 days to remove it from backups and disaster recovery systems.
// Content will not be deleted within 90 days of the account deletion or content deletion process beginning in the following situations:
// where your content has been used by others in accordance with this license and they have not deleted it (in which case this license will continue to apply until that content is deleted);
// where deletion within 90 days is not possible due to technical limitations of our systems, in which case, we will complete the deletion as soon as technically feasible; or
// where immediate deletion would restrict our ability to:
// investigate or identify illegal activity or violations of our terms and policies (for example, to identify or investigate misuse of our Products or systems);
// protect the safety, integrity, and security of our Products, systems, services, our employees, and users, and to defend ourselves;
// comply with legal obligations for the preservation of evidence, including data Meta Companies providing financial products and services preserve to comply with any record keeping obligations required by law; or
// comply with a request of a judicial or administrative authority, law enforcement or a government agency;
// in which case, the content will be retained for no longer than is necessary for the purposes for which it has been retained (the exact duration will vary on a case-by-case basis).
// In each of the above cases, this license will continue until the content has been fully deleted.
// Permission to use your name, profile picture, and information about your actions with ads and sponsored or commercial content: You give us permission to use your name and profile picture and information about actions you have taken on Facebook next to or in connection with ads, offers, and other sponsored or commercial content that we display across our Products, without any compensation to you. For example, we may show your friends that you are interested in an advertised event or have liked a Facebook Page created by a brand that has paid us to display its ads on Facebook. Ads and content like this can be seen only by people who have your permission to see the actions you've taken on Meta Products. You can learn more about your ad settings and preferences.
// Permission to update software you use or download: If you download or use our software, you give us permission to download and install updates to the software where available.
// 4. Limits on using our intellectual property
// If you use content covered by intellectual property rights that we have and make available in our Products (for example, images, designs, videos, or sounds we provide that you add to content you create or share on Facebook), we retain all rights to that content (but not yours). You can only use our copyrights or trademarks (or any similar marks) as expressly permitted by our Brand Usage Guidelines or with our prior written permission. You must obtain our written permission (or permission under an open source license) to modify, translate, create derivative works of, decompile, or reverse engineer our products or their components, or otherwise attempt to extract source code from us, unless an exception or limitation applies under applicable law or your conduct relates to the Meta Bug Bounty Program.
// Return to top
// 4. Additional provisions
// 1. Updating our Terms
// We work constantly to improve our services and develop new features to make our Products better for you and our community. As a result, we may need to update these Terms from time to time to accurately reflect our services and practices, to promote a safe and secure experience on our Products and services, and/or to comply with applicable law. Unless otherwise required by law, we will notify you before we make changes to these Terms and give you an opportunity to review them before they go into effect. Once any updated Terms are in effect, you will be bound by them if you continue to use our Products.
// We hope that you will continue using our Products, but if you do not agree to our updated Terms and no longer want to be a part of the Facebook community, you can delete your account at any time.
// 2. Account suspension or termination
// We want Facebook to be a place where people feel welcome and safe to express themselves and share their thoughts and ideas.
// If we determine, in our discretion, that you have clearly, seriously or repeatedly breached our Terms or Policies, including in particular the Community Standards, we may suspend or permanently disable your access to Meta Company Products, and we may permanently disable or delete your account. We may also disable or delete your account if you repeatedly infringe other people’s intellectual property rights or where we are required to do so for legal reasons.
// We may disable or delete your account if after registration your account is not confirmed, your account is unused and remains inactive for an extended period of time, or if we detect someone may have used it without your permission and we are unable to confirm your ownership of the account. Learn more about how we disable and delete accounts.
// Where we take such action we’ll let you know and explain any options you have to request a review, unless doing so may expose us or others to legal liability; harm our community of users; compromise or interfere with the integrity or operation of any of our services, systems or Products; where we are restricted due to technical limitations; or where we are prohibited from doing so for legal reasons.
// You can learn more about what you can do if your account has been disabled and how to contact us if you think we have disabled your account by mistake.
// If you delete or we disable or delete your account, these Terms shall terminate as an agreement between you and us, but the following provisions will remain in place: 3, 4.2-4.5.
// 3. Limits on liability
// We work hard to provide the best Products we can and to specify clear guidelines for everyone who uses them. Our Products, however, are provided "as is," and we make no guarantees that they always will be safe, secure, or error-free, or that they will function without disruptions, delays, or imperfections. To the extent permitted by law, we also DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. We do not control or direct what people and others do or say, and we are not responsible for their actions or conduct (whether online or offline) or any content they share (including offensive, inappropriate, obscene, unlawful, and other objectionable content).
// We cannot predict when issues might arise with our Products. Accordingly, our liability shall be limited to the fullest extent permitted by applicable law, and under no circumstance will we be liable to you for any lost profits, revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or incidental damages arising out of or related to these Terms or the Meta Products (however caused and on any theory of liability, including negligence), even if we have been advised of the possibility of such damages. Our aggregate liability arising out of or relating to these Terms or the Meta Products will not exceed the greater of $100 or the amount you have paid us in the past twelve months.
// 4. Disputes
// We try to provide clear rules so that we can limit or hopefully avoid disputes between you and us. If a dispute does arise, however, it's useful to know up front where it can be resolved and what laws will apply.
// You and Meta each agree that any claim, cause of action, or dispute between us that arises out of or relates to these Terms or your access or use of the Meta Products shall be resolved exclusively in the U.S. District Court for the Northern District of California or a state court located in San Mateo County. You also agree to submit to the personal jurisdiction of either of these courts for the purpose of litigating any such claim, and that the laws of the State of California will govern these Terms and any claim, cause of action, or dispute without regard to conflict of law provisions. Without prejudice to the foregoing, you agree that, in its sole discretion, Meta may bring any claim, cause of action, or dispute we have against you in any competent court in the country in which you reside that has jurisdiction over the claim.
// 5. Other
// These Terms (formerly known as the Statement of Rights and Responsibilities) make up the entire agreement between you and Meta Platforms, Inc. regarding your use of our Products. They supersede any prior agreements.
// Some of the Products we offer are also governed by supplemental terms. If you use any of those Products, supplemental terms will be made available and will become part of our agreement with you. For instance, if you access or use our Products for commercial or business purposes, such as buying ads, selling products, developing apps, managing a group or Page for your business, or using our measurement services, you must agree to our Commercial Terms. If you post or share content containing music, you must comply with our Music Guidelines. To the extent any supplemental terms conflict with these Terms, the supplemental terms shall govern to the extent of the conflict.
// If any portion of these Terms is found to be unenforceable, the unenforceable portion will be deemed amended to the minimum extent necessary to make it enforceable, and if it can’t be made enforceable, then it will be severed and the remaining portion will remain in full force and effect. If we fail to enforce any of these Terms, it will not be considered a waiver. Any amendment to or waiver of these Terms must be made in writing and signed by us.
// You will not transfer any of your rights or obligations under these Terms to anyone else without our consent.
// You may designate a person (called a legacy contact) to manage your account if it is memorialized. If you enable it in your settings, only your legacy contact or a person who you have identified in a valid will or similar legal document expressing clear consent to disclose your content to that person upon death or incapacity will be able to seek limited disclosure of information from your account after it is memorialized.
// These Terms do not confer any third-party beneficiary rights. All of our rights and obligations under these Terms are freely assignable by us in connection with a merger, acquisition, or sale of assets, or by operation of law or otherwise.
// We may need to change the username for your account in certain circumstances (for example, if someone else claims the username and it appears unrelated to the name you use in everyday life).
// We always appreciate your feedback and other suggestions about our products and services. But we may use feedback and other suggestions without any restriction or obligation to compensate you, and we are under no obligation to keep them confidential.
// We reserve all rights not expressly granted to you.
// Return to top
// 5. Other terms and policies that may apply to you
// Community Standards: These guidelines outline our standards regarding the content you post to Facebook and your activity on Facebook and other Meta Products.
// Commercial Terms: These terms apply if you also access or use our Products for any commercial or business purpose, including advertising, operating an app on our Platform, using our measurement services, managing a group or a Page for a business, or selling goods or services.
// Community Payment Terms: These terms apply to payments made on or through Meta Products.
// Commerce Policies: These guidelines outline the policies that apply when you offer products or services for sale on Facebook, Instagram, and WhatsApp.
// Music Guidelines: These guidelines outline the policies that apply if you post or share content containing music on any Meta Products.
// Advertising Policies: These policies apply to partners who advertise across the Meta Products and specify what types of ad content are allowed by partners who advertise across the Meta Products.
// Self-Serve Ad Terms: These terms apply when you use self-serve advertising interfaces to create, submit, or deliver advertising or other commercial or sponsored activity or content.
// Facebook Pages, Groups and Events Policy: These guidelines apply if you create or administer a Facebook Page, group, or event, or if you use Facebook to communicate or administer a promotion.
// Meta Platform Policy: These terms apply to the use of the set of APIs, SDKs, tools, plugins, code, technology, content, and services that enables others to develop functionality, retrieve data from MetaProducts, or provide data to us.
// Developer Payment Terms: These terms apply to developers of applications that use Facebook Payments.
// Meta Brand Resources: These guidelines outline the policies that apply to use of Meta trademarks, logos, and screenshots.
// Recommendations Guidelines: The Facebook Recommendations Guidelines and Instagram Recommendations Guidelines outline our standards for recommending and not recommending content.
// Live Policies: These policies apply to all content broadcast to Facebook Live.
//
// Date of Last Revision: July 26, 2022

export default TOSPage;

