import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import SubtleRadio from "./SubtleRadio";
import {harvestErrors} from "./SubtleTextBox";

const MissingValuesInput: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
    ) => {
    return <SubtleRadio
        {...validatingRegister('replaceMissing')}
        showLabel
        validatingRegister={validatingRegister}
        name='replaceMissing'
        options={[
            {name: 'true', label: 'Yes'},
            {name: 'false', label: 'No'},
        ]}
        alignItems={'center'}
        justifyContent={'space-between'}
        inline={true}
        label="Replace missing values with 0 (does not affect NULLS)?"
        isDirty={Boolean(formState.dirtyFields.replaceMissing)}
        onReset={() => resetField('replaceMissing')}
        error={harvestErrors('replaceMissing', formState, mutationResults)}
    />;
}

export default MissingValuesInput;
