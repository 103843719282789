import Button from '../../../common/buttons/Button';
import {FormResults} from '../../../common/Form';
import Typography from '../../../common/text/Typography';
import {useTranslateValueMutation} from '../../api';
import {mergeErrorStates} from '../../api/helpers';
import {BaseAttribute} from '../../ontology/types/attributeTypes';
import {AttrActionFormProps} from '../common/commonTypes';
import {createBasicValidation} from '../common/helpers';
import {StyledTextArea,} from '../common/styledComponents';
import useAttrActionData from '../hooks/useAttrActionData';
import {
    translateValueFormDefaults,
    translateValueFormToPayload,
    genTranslateValueFieldLabels,
} from './translateValueHelpers';
import {TranslateValueFormValues} from './translateValueTypes';
import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import {useAppDispatch} from "../../../app/hooks";
import useModalType from "../../HUD/hooks/useModalType";
import {setModalType} from "../../HUD/state/HUDSlice";
import {PrependAttrFormValues} from "../prependAttribute/prependAttributeTypes";

const TranslateValueForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
	const {_id, entity: sourceEntity, canEdit} = modalProps;

	const {
        handleSubmit,
        register,
        formState
    } = useForm<TranslateValueFormValues>(
        {
            defaultValues: translateValueFormDefaults(modalProps),
        }
    );


    const {isLoading, domainAttrs, domainAttrQueryResult} =
        useAttrActionData();

    // User is required to select a target attribute as the first step
    const [targetAttr, setTargetAttr] = useState<BaseAttribute | 'deriveNew' | null>(null);

    const validatingRegister = useCallback(
        (inputName: keyof TranslateValueFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const fieldLabels = genTranslateValueFieldLabels(modalProps, targetAttr);

    // When a target attr is selected, the form gets much bigger, so we need
    // to update the popover position
    useEffect(() => {
        if (targetAttr && closeModal) {
            closeModal();
        }
    }, [targetAttr, closeModal]);

    const selectableAttrs = useMemo(() => {
        if (!domainAttrs) {
            return [];
        }

        // exclude attr if it is 1) same as source attr, 2) belongs to the same entity as source attr,
        // 3) is not of type 'identity'
        return domainAttrs.filter(
            (attr) => attr.type === 'identity' && attr._id !== _id
        );
    }, [domainAttrs, _id]);

    const [translateValue, translateValueResults] = useTranslateValueMutation();

    const mergedErrResults = mergeErrorStates(
        translateValueResults,
        domainAttrQueryResult
    );

    const onSubmit: SubmitHandler<TranslateValueFormValues> = (vals, e) => {
        e?.preventDefault();
        translateValue({
            sourceAttrId: _id,
            body: translateValueFormToPayload(vals),
        });
    };

    return (
        <AppModal
            label={"Create Location"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit ? canEdit : true}>
            {/*<TargetAttrSelect*/}
            {/*	setTargetAttr={setTargetAttr}*/}
            {/*	loading={isLoading}*/}
            {/*	register={register}*/}
            {/*	selectableAttrs={selectableAttrs}*/}
            {/*	labelText={fieldLabels.otherId}*/}
            {/*/>*/}
            {targetAttr && sourceEntity && (
                <>
                    <label htmlFor="singular">
                        <Typography>{fieldLabels.singular}</Typography>
                    </label>
                    <input
                        type="text"
                        {...validatingRegister(
                            'singular',
                            createBasicValidation('singular')
                        )}
                        id="singular"
                    />

                    <label htmlFor="plural">
                        <Typography>{fieldLabels.plural}</Typography>
                    </label>
                    <input
                        type="text"
                        {...validatingRegister(
                            'plural',
                            createBasicValidation('plural')
                        )}
                        id="plural"
                    />

                    <label htmlFor="definition">
                        <Typography>
                            {fieldLabels.definition}
                        </Typography>
                    </label>
                    <StyledTextArea
                        {...validatingRegister('definition')}
                        id="definition"
                    />

                    <Typography>{fieldLabels.isDependent}</Typography>
                    <div>
                        <input
                            id="isDependent-true"
                            type="radio"
                            value="true"
                            {...validatingRegister('isDependent', {
                                required: {
                                    value: true,
                                    message:
                                        'A value for isDependent is required',
                                },
                            })}
                        />
                        <label htmlFor="isDependent-true">
                            <Typography>Dependent</Typography>
                        </label>
                    </div>
                    <div>
                        <input
                            id="isDependent-false"
                            type="radio"
                            value="false"
                            {...validatingRegister('isDependent', {
                                required: {
                                    value: true,
                                    message:
                                        'A value for isDependent is required',
                                },
                            })}
                        />
                        <label htmlFor="isDependent-false">
                            <Typography>
                                Independent
                            </Typography>
                        </label>
                    </div>

                    <Typography>{fieldLabels.isExclusive}</Typography>
                    <div>
                        <input
                            id="isExclusive-true"
                            type="radio"
                            value="true"
                            {...validatingRegister('isExclusive', {
                                required: {
                                    value: true,
                                    message:
                                        'A value for isExclusive is required',
                                },
                            })}
                        />
                        <label htmlFor="isExclusive-true">
                            <Typography>Exclusive</Typography>
                        </label>
                    </div>
                    <div>
                        <input
                            id="isExclusive-false"
                            type="radio"
                            value="false"
                            {...validatingRegister(
                                'isExclusive',
                                createBasicValidation('isExclusive')
                            )}
                        />
                        <label htmlFor="isExclusive-false">
                            <Typography>
                                Non-Exclusive
                            </Typography>
                        </label>
                    </div>
                    {/*<label htmlFor="type">*/}
                    {/*	<Typography>{fieldLabels.type}</Typography>*/}
                    {/*</label>*/}
                    {/*<select*/}
                    {/*	{...register(*/}
                    {/*		'type',*/}
                    {/*		createBasicValidation('type')*/}
                    {/*	)}*/}
                    {/*>*/}
                    {/*	<option value="relationOf">related to</option>*/}
                    {/*	<option value="sameAs">same as</option>*/}
                    {/*	<option value="oppositeOf">opposite of</option>*/}
                    {/*	<option value="alternateOf">*/}
                    {/*		alternate of*/}
                    {/*	</option>*/}
                    {/*	<option value="specializationOf">*/}
                    {/*		specialization of*/}
                    {/*	</option>*/}
                    {/*	<option value="equivalentTo">*/}
                    {/*		equivalent to*/}
                    {/*	</option>*/}
                    {/*	<option value="partOf">part of</option>*/}
                    {/*	<option value="componentOf">*/}
                    {/*		component of*/}
                    {/*	</option>*/}
                    {/*	<option value="portionOf">portion of</option>*/}
                    {/*	<option value="featureOf">feature of</option>*/}
                    {/*	<option value="memberOf">member of</option>*/}
                    {/*	<option value="attachedTo">attached to</option>*/}
                    {/*	<option value="possessedBy">*/}
                    {/*		possessed by*/}
                    {/*	</option>*/}
                    {/*	<option value="influencedBy">*/}
                    {/*		influenced by*/}
                    {/*	</option>*/}
                    {/*</select>*/}
                </>
            )}
            {targetAttr && <Button type="submit">Submit</Button>}
            <FormResults
                isError={
                    domainAttrQueryResult.isError ||
                    translateValueResults.isError
                }
                error={mergedErrResults}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default TranslateValueForm;
