export const DESCRIBE_ENTITY = 'Describe entity';
export const CREATE_ATTRIBUTE = 'Create Attribute';
export const MELT_ENTITY = 'Melt Entity';
export const PERSIST_ENTITY = 'Persist Entity';
export const ASK_QUESTION = 'Ask Question';
export const REMOVE_IMPORT = 'Remove Import';
export const CREATE_CONDITION = 'Create Condition';
export const CREATE_LOCATION = 'Create Location';
export const CREATE_EVENT = 'Create Event';
export const CREATE_RESTRICTION = 'Create Restriction';
export const CREATE_SPECIALIZATION = 'Create Specialization';
export const CREATE_ACTIVITY = 'Create Activity';
export const DESCRIBE_DOMAIN = 'Describe domain';
export const DESCRIBE_ORG = 'Edit profile';
export const DESTROY_ENTITY = 'Destroy entity';
export const DESTROY_METRIC = 'Destroy metric';
export const DESTROY_ORG = 'Destroy org';
export const DELETE_DOMAIN = 'Destroy Domain';
export const DELETE_COMMENT = 'Delete Comment';
export const DESTROY_ATTRIBUTE = 'Destroy Attribute';
export const DESCRIBE_ATTRIBUTE = 'Describe Attribute';
export const MANAGE_MEMBERS = 'Manage Members';
export const SHARE_RESOURCE = 'Share Resource';
export const AUDIO_CALL = 'Audio call';
export const VIDEO_CALL = 'Video call';
export const SEE_FRIENDSHIP = 'See friendship';
export const SUPPORT_REPORT = 'Find support or report';
export const BLOCK_USER = 'Block';
export const VIEW_AS = 'View As';
export const ACCOUNT_STATUS = 'Account Status';
export const ARCHIVE = 'Archive';
export const ACTIVITY_LOG = 'Activity Log';
export const PROFILE_SETTINGS = 'Profile Settings';
export const REMOVE_STEWARD = 'Remove steward';
export const REMOVE_GOVERNOR = 'Remove governor';
export const REMOVE_MEMBER = 'Remove member';
export const ADD_COMPONENT = 'Add Component';
export const VIEW_DOMAIN = 'Go to domain';
export const VIEW_ORG = 'Go to org';
export const VIEW_ENTITY = 'Go to entity';
export const VIEW_ATTRIBUTE = 'Go to attribute';
export const VIEW_LINEAGE = 'Go to lineage';
export const VIEW_ENTITY_PROFILE = 'Go to profile';
export const VIEW_ENTITY_SPATIAL = 'Go to spatial';
export const VIEW_ENTITY_GRAPH = 'Go to graph';
export const SHOW_CREDITS = 'Show credits';
export const FOLLOW_RESOURCE = 'Follow domain';
export const BOOKMARK_RESOURCE = 'Add to Deck';
export const COPY_SHARE_URL = 'Copy Domain Link';
export const COPY_EMBED_CODE = 'Copy Embed Code';
export const COPY_FUTUREMODEL_URI = 'Copy Futuremodel URI';
export const CREATE_METRIC = 'Create metric';
export const CREATE_INDIVIDUAL = 'Create individual';
export const SAME_AS = 'Same as';
export const ALTERNATE_OF = 'Alternate of';
export const SPECIALIZATION_OF = 'Specialization of';
export const GENERALIZATION_OF = 'Generalization of';
export const ENTITY_AUDIENCE = 'Audience';
export const SHOW_QUESTIONS = 'Show questions';
export const SHOW_COMMENTS = 'Show comments';
export const RENAME_RESOURCE = 'Rename';
export const REFRESH_QUERY = 'Refresh';




