import { formFieldHasErrors, genErrorIdFromLabel } from '../../../common/Form';
import Typography from '../../../common/text/Typography';
import { pipe } from '../../../common/utils/functionUtils';
import {
	resetRelationCount,
	resetRestrictionReference,
	stripFormOnlyProps,
} from '../common/helpers';
import { resetIdentifyingFields } from '../common/helpers';
import {
	RestrictEntityFormValues,
	RestrictEntityPayload,
	RestrictEntityActionParams,
	SpecializeEntityPayload,
	RestrictionKind,
} from './restrictEntityTypes';
import { FormState, UseFormRegister } from 'react-hook-form';

export const restrictEntityFormDefaults = (): RestrictEntityFormValues => ({
	restrictedId: 0,
	restrictionType: 'is',
	// form validation prevents restrictionId from being 0 if the field is active
	restrictionId: 0,
	restrictionValue: '',
	singular: '',
	plural: '',
	definition: '',
	// form validation prevents nRelationCount from being 0 if the field is active
	nRelationCount: 0,
	usingStaticReference: 'true',
});

export const restrictEntityFormToPayload = (
	restrictionKind: RestrictionKind,
	formValues: RestrictEntityFormValues
): RestrictEntityPayload | SpecializeEntityPayload => {
	const requiresRelationCount =
		formValues.restrictionType === 'hasAtLeastNRelationsWith' ||
		formValues.restrictionType === 'hasAtMostNRelationsWith';

	const requiresIdentifiers = restrictionKind === 'specialization';

	const requiresReferenceValue = formValues.usingStaticReference === 'true';

	const baseValues = {
		...formValues,
	};

	return pipe(
		baseValues,
		stripFormOnlyProps,
		resetRelationCount(requiresRelationCount),
		resetIdentifyingFields(requiresIdentifiers),
		resetRestrictionReference(requiresReferenceValue)
	);
};

// Need an extra type-conversion step b/c 'restrictionKind' is needed to
// form the correct URL for the mutation request, but is NOT needed in the
// actual payload
export const restrictEntityFormToParams = (
	entityId: number,
	restrictionKind: RestrictionKind,
	formValues: RestrictEntityFormValues
): RestrictEntityActionParams => ({
	restrictionKind,
	body: restrictEntityFormToPayload(restrictionKind, formValues),
	entityId,
});

export const renderRelationCountSubfield = (
	relationCountNeeded: boolean,
	register: UseFormRegister<RestrictEntityFormValues>,
	formState: FormState<RestrictEntityFormValues>
) => {
	if (relationCountNeeded) {
		return (
			<>
				<label htmlFor="nRelationCount">
					<Typography>What value should be used for 'N'?</Typography>
				</label>
				<input
					type="number"
					{...register('nRelationCount', {
						min: {
							value: 1,
							message:
								'If an "N relations" comparison is to be used, a value greater than 0 must be selected for "N"',
						},
					})}
					id="nRelationCount"
					aria-errormessage={genErrorIdFromLabel('nRelationCount')}
					aria-invalid={formFieldHasErrors(
						'nRelationCount',
						formState
					)}
				/>
			</>
		);
	}

	return null;
};
