import Button from '../../../../common/buttons/Button';
import { List, ListItem, ListItemContent } from '../../../../common/List';
import Popover from '../../../../common/popovers/Popover/PopoverBase';
import ReactRouterLink from '../../../../common/RouterLink';
import {
	// getPluralKind,
	getPluralLabel } from '../../helpers';
import { ContextLevels } from '../../types/navigationContextTypes';
import {faChevronRight, faPlus} from '@fortawesome/free-solid-svg-icons';
import Spinner from 'common/loading/Spinner';
import Typography from 'common/text/Typography';
import { sortByString } from 'common/utils/functionUtils';
import { FunctionComponent, useMemo, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import styled from 'styled-components';
import IconButton from "../../../../common/buttons/IconButton";
import SelectBox from "../../../../common/inputs/SelectBox";
import SelectBoxItem from "../../../../common/inputs/SelectBox/SelectBoxItem";
import useViewModeParams from "../../hooks/useViewModeParams";
import {useNavigate} from "react-router";

	// border: 1px solid transparent;
	// color: ${(p) => p.theme.palette.darkBaby};
const BreadcrumbChevron = styled.div`
	margin-right: 2px;
	border-radius: .25rem;
	position: relative;

	&:hover {
		left: -1px;
		margin-right: 0px;
		border: 1px solid ${(p) => p.theme.palette.darkBaby};
		color: ${(p) => p.theme.palette.darkBaby};
	}
`

const BreadcrumbButton = styled(Button)`
	background: transparent;
	margin: 0;
	border-radius: .25rem;
	padding: .25rem .25rem .25rem .25rem;
	text-transform: capitalize;
	border: 1px solid transparent;

	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	
	
	&:hover {
		border: 1px solid ${(p) => p.theme.palette.darkBaby};
		color: ${(p) => p.theme.palette.darkBaby};
	}
`;

export interface BreadcrumbLink {
	url: string;
	name: string;
	kind?: string;
	label?: string;
	singular?: string;
}

interface BaseBreadcrumbProps {
	kind: ContextLevels;
	links: BreadcrumbLink[];
	status: 'loading' | 'success' | 'error';
	errorMessage?: string | null;
	isTip: boolean;
	activeName: string | null;
}

const Breadcrumb: FunctionComponent<BaseBreadcrumbProps> = ({
	kind,
	links,
	isTip,
	status,
	activeName,
	errorMessage,
}) => {

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const [initial, setInitial] = useState(true);
	const [open, setOpen] = useState(false);
	const {getViewMode} = useViewModeParams();
	const navigate = useNavigate();

	const handleClick = () => {
		if (initial) {
			setInitial(false);
		}
		return setOpen((p) => !p);
	};

	const onClickAway = () => {
		if (open) {
			return setOpen(false);
		}

		return null;
	};

	const activeLink = links.find((l) => l.name === activeName);

	const sortedLinks = useMemo(
		() => sortByString(links, (l) =>
			l.label ? l.label : l.singular ? l.singular : ' '
		),
		[links]
	);

	const getTipBtnText = () => {
		switch (status) {
			case 'loading':
				return 'Loading...';

			case 'error':
				return 'Error';

			case 'success':
				const displayText = kind === 'entity' && typeof activeName === 'string'
					? activeLink?.label !== undefined
						? activeLink?.label
						: activeLink?.singular
					: getPluralLabel(kind);
				return <Typography
						variant='h5'
						color={'cyan'}
						className='unselectable'
						style={{
							fontWeight: 700,
							fontSize: '.875rem'
						}}>
						{displayText}
					</Typography>;
		}
	};

	const renderButton = () => {
		if (isTip) {
			return <ClickAwayListener onClickAway={onClickAway}>
					<BreadcrumbButton
						variant='text'
						color={'cyan'}
						className='unselectable'
						onClick={handleClick}
						ref={setAnchorEl as any}
					>
					<Typography
						variant='h5'
						color={'cyan'}
						className='unselectable'
						style={{fontWeight: 700, fontSize: '.875rem'}}>
							{getTipBtnText()}
						</Typography>
					</BreadcrumbButton>
				</ClickAwayListener>;
		}

		return (
			<>
				<BreadcrumbButton variant='text' onClick={() => {
					const mode = getViewMode();
					if (kind !== 'username') {
						activeLink && navigate(
							mode !== 'profile'
								? `./${activeLink.name}/${mode}`
								: `./${activeLink.name}`,
							{ replace: false }
						)
					} else {
						activeLink && navigate(
							mode !== 'profile' && mode !== 'feed'
								? `/${activeLink.name}/${mode}`
								: `/${activeLink.name}`,
							{ replace: false }
						)
					}

				}}>
					<Typography
						variant='h5'
						className='unselectable'
						color={'cyan'}
						style={{
							fontWeight: 700,
							fontSize: '.875rem'
						}}>
						{activeLink?.label !== undefined
							? activeLink?.label
							: getPluralLabel(kind)}
					</Typography>
				</BreadcrumbButton>
				<ClickAwayListener onClickAway={onClickAway}>
					<BreadcrumbChevron data-testid="breadcrumb-chevron">
						<IconButton
							size="xs"
							iconSize="lg"
							onClick={handleClick}
							color={'oldManGray'}
							ref={setAnchorEl as any}
							usingLabel={true}
							variant={'transparent'}
							overrides={{icon: {verticalAlign: 0}}}
							style={initial
								? {
								transition: 'transform 0.5s',
									position: 'relative',
							}
								: open
									? {
										transform: 'rotate(90deg)',
										transition: 'transform 0.3s',
										position: 'relative',
									}
									: {
										transform: 'rotate(0deg)',
										transition: 'transform 0.3s',
										position: 'relative',

									}
							}
							icon={faChevronRight}
							fillColor='darkBaby'
						>
							<Typography className='unselectable' color={'oldManGray'} variant='h5'>
								{getTipBtnText()}
							</Typography>
						</IconButton>
					</BreadcrumbChevron>
				</ClickAwayListener>
			</>
		);
	};

	const renderDropdown = () => {
		switch (status) {
			case 'loading':
				return <Spinner data-testid="breadcrumb-dropdown-loading" />;
			case 'error':
				return (
					<Typography paragraph color="error">
						{errorMessage || 'Something went wrong.'}
					</Typography>
				);
			case 'success':
				return (
					<List style={{ maxHeight: '50vh', overflowY: 'auto' }}>
						{sortedLinks.map((link) => (
							<SelectBoxItem
								key={link.name}
								type='link'
								resource={link}
								onClick={() => setAnchorEl(null)}
							/>
						))}
					</List>
				);
		}
	};

	return (
		<>
			{renderButton()}
			<Popover anchorEl={anchorEl} open={open} placement="bottom">
				{renderDropdown()}
			</Popover>
		</>
	);
};

export default Breadcrumb;
