import React, {FunctionComponent} from "react";
import SubtleSelect from "./SubtleSelect";
import {harvestErrors} from "./SubtleTextBox";
import {GetEntityAttrsResponse} from "../../features/ontology/types/attributeTypes";

const AttributeSelect: FunctionComponent<{selectId: string; required?: boolean; props: any;}> = ({selectId, required=false, props}) => {
	const {
		validatingRegister,
		formState,
		resetField,
		attrData,
		mutationResults,
		label
	} = props;

	return <SubtleSelect
		{...validatingRegister(selectId, required ? {
			valueAsNumber: true,
			validate: (v: string | number) => {
				if (typeof v === 'string') {
					const maybeInt = parseInt(v, 10);

					return isNaN(maybeInt)
						? 'Invalid value passed to otherId'
						: maybeInt > 0
							? true
							: `An attribute must be selected to provide ${selectId}`;
				}

				return v > 0 ? true
					: 'A reference attribute must be selected if you are not using a static data value';
				},
		} : {})}
		label={label ? label : selectId[0].toUpperCase() + selectId.slice(1, -2)}
		inline={false}
		isDirty={Boolean(formState.dirtyFields[selectId])}
		onReset={() => resetField(selectId)}
		error={harvestErrors(
			selectId,
			formState,
			mutationResults
		)}
	>
		{(attrData as GetEntityAttrsResponse).map((attr) => (
			<option value={attr._id} key={attr._id}>
				{`${attr.entity.plural}: ${attr.plural}`}
			</option>
	))}
	</SubtleSelect>
}

export default AttributeSelect;
