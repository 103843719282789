import FlexContainer from '../FlexContainer';
import IconButton from '../buttons/IconButton';
import {
    PopoverDrawer,
    PopoverSubhead,
    PopoverCardBody,
    PopoverHead,
} from './Popover';
import SubtleTextArea from '../inputs/SubtleTextArea';
import {SubtleTextbox} from '../inputs/SubtleTextBox';
import {warnInDev} from '../utils/reactUtils';
import {useUpdateOrgMutation} from '../../features/api';
import DeleteOrgButton from '../buttons/DeleteOrgButton';
import {faCheck, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {isNonEmptyString} from 'common/utils/typeGuards';
import {
    DEFINITION_PLACEHOLDER,
} from 'features/graph/helpers';
import {BaseOrg, OrgModalTypes} from 'features/ontology/types/orgTypes';
import React, {
    FormEventHandler,
    FunctionComponent,
    MouseEventHandler,
    useEffect,
    useState,
} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useLocation} from 'react-router-dom';

import PaperButton from '../buttons/PaperButton';
import {
    PopoverControlsGrid,
    DropdownIcon,
} from './Popover/styledComponents';
import RouterLink from '../RouterLink';
import {UserContext} from '../../features/authentication/types/userTypes';
import FollowButton from '../buttons/FollowButton';
import {faEllipsisH} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import Typography from "../text/Typography";
import theme from 'common/theme/theme'
import {List, ListItem} from 'common/List';
import {ListItemContent} from 'common/List';
import {TinySquare} from '../viz/ForceGraph/styledComponents';
import Spinner from 'common/loading/Spinner';
import {useGetDomainsQuery} from 'features/api';
import {parseQueryError} from 'features/api/helpers';
import {BaseDomain} from 'features/ontology/types/domainTypes';
import styled from 'styled-components';
import QuestionButton from "../buttons/QuestionButton";
import CommentButton from "../buttons/CommentButton";
import OrgMenu from "../menus/OrgMenu";
import {useAppDispatch} from "../../app/hooks";
import useModalType from "../../features/HUD/hooks/useModalType";
import {setModalType} from "../../features/HUD/state/HUDSlice";

interface OrgDropdownProps {
    orgId: number;
    orgLabel: string;
    actionSubmenu: any;
    setActionSubmenu: any;
}

export const ListItemAvatar = styled.div`
	flex: 0 0 5%;
`;


const OrgPopoverDropdown: FunctionComponent<OrgDropdownProps> = ({
                                                                     orgId,
                                                                 }) => {
    const {
        isLoading,
        isError,
        error,
        data: domains,
    } = useGetDomainsQuery({orgId});

    if (isLoading) {
        return (
            <FlexContainer justifyContent="center">
                <Spinner/>
            </FlexContainer>
        );
    }

    if (isError && error) {
        return (
            <Typography variant='h5' color="error">
                {parseQueryError(error).message}
            </Typography>
        );
    }

    // cast type of 'domains' since compiler doesn't know about mutually-exclusive
    // query states.
    return (
        <div style={{
            overflow: 'auto',
            maxHeight: '300px',
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`
        }}>
            <List style={{
                flexDirection: 'column',
                alignContent: 'center',
                paddingLeft: '24px',
                alignItems: 'center'
            }}>
                {(domains as BaseDomain[]).map((domain) => (
                    <ListItem key={domain._id} style={{height: '48px'}}>
                        <ListItemAvatar>
                            <TinySquare/>
                        </ListItemAvatar>
                        <ListItemContent>
                            <Typography variant="h5">
                                {domain.label}
                            </Typography>
                        </ListItemContent>
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

interface ControlsProps {
    toggleDropdown: MouseEventHandler;
    pathToProfile: string;
    menuOpen: boolean;
    canEdit: boolean;
    org: BaseOrg;
    orgLabel: string | null;
    userContext: UserContext | null;
}


const OrgPopoverControls: FunctionComponent<ControlsProps> = (
    {
        toggleDropdown,
        pathToProfile,
        menuOpen,
        org,
        canEdit,
        orgLabel,
        userContext,
    }
) => {
    return (
        <FlexContainer
            justifyContent="space-around"
            style={{
                padding: '.5rem',
                gap: '.5rem'
            }}
        >
            <FollowButton
                resource={org}
                objectAlreadyFollowed={org && org.userContext
                    ? org.userContext.interactions.following
                    : false}
            />
            <QuestionButton
                objectId={org._id}
                objectType="Organization"
                canEdit={canEdit}
            />
            <CommentButton
                objectId={org._id}
                objectType="Organization"
                canEdit={canEdit}
            />
            <PaperButton width='38px' height='32px' onClick={toggleDropdown}
                         tooltip="toggle drawer">
                <DropdownIcon
                    className={clsx(menuOpen && 'popover-menu-open')}
                    icon={faChevronDown}
                />
            </PaperButton>
            {/*<FollowButton*/}
            {/*	loading={false}*/}
            {/*	handleFollow={() => {}}*/}
            {/*	handleUnfollow={() => {}}*/}
            {/*	objectAlreadyFollowed={userContext ? userContext.interactions.following : false}*/}
            {/*	error={{}}*/}
            {/*	// userContext={userContext}*/}
            {/*	// orgLabel={orgLabel}*/}
            {/*	// orgId={orgId}*/}
            {/*/>*/}
            {/*<RouterLink to={pathToProfile}>*/}
            {/*	<PaperButton>*/}
            {/*		<Typography variant='button'>*/}
            {/*			Explore*/}
            {/*		</Typography>*/}
            {/*	</PaperButton>*/}
            {/*</RouterLink>*/}
            {/*<PaperButton onClick={toggleDropdown}>*/}
            {/*	<DropdownIcon*/}
            {/*		className={clsx(menuOpen && 'popover-menu-open')}*/}
            {/*		icon={faEllipsisH}*/}
            {/*	/>*/}
            {/*</PaperButton>*/}
        </FlexContainer>
    );
};

export interface OrgPopoverBodyProps {
    org: BaseOrg;
}

interface UpdateOrgFormValues {
    username: string;
    label: string;
    legalName: string;
    domainName: string;
    orgType: string;
    location: string;
    industry: string;
    language: string;
    currency: string;
    timezone: string;
    linkedin: string;
    facebook: string;
    instagram: string;
    github: string;
    avatarUrl: string;
    definition?: string;
    isOnboarded?: boolean;
    isManaged?: boolean;
    isVerified?: boolean;
}


export const OrgPopover: FunctionComponent<OrgPopoverBodyProps> = (props) => {

    const {org} = props;
    const {_id, name, label, userContext, definition} = org;

    const appDispatch = useAppDispatch();
	const {modalType, closeModal} = useModalType();

    const {
        register,
        handleSubmit,
        formState,
        resetField
    } = useForm<UpdateOrgFormValues>({
        defaultValues: {
            label,
            definition: definition ?? DEFINITION_PLACEHOLDER,
        },
    });

    const {isDirty: formIsDirty, dirtyFields} = formState;

    const {label: labelIsDirty, definition: definitionIsDirty} = dirtyFields;

    const [updateOrg, mutationResult] = useUpdateOrgMutation();

    useEffect(() => {
        if (mutationResult.error) {
            warnInDev(JSON.stringify(mutationResult.error), 'error');
        }
    }, [mutationResult.error]);

    const onSubmit: SubmitHandler<UpdateOrgFormValues> = (vals) => {
        updateOrg({
            orgId: _id,
            body: vals,
            // definition: nullifyDefinitionPlaceholder(vals.definition),
            // },
        });
    };

    const conditionalSubmit: FormEventHandler = (e) => {
        e.preventDefault();

        if (formIsDirty) {
            handleSubmit(onSubmit)();
        }

        return null;
    };

    const [menuOpen, setMenuOpen] = useState(false);

    // Handle open popover menu state here in parent to prevent multiple
    // submenus from being open at once
    const [actionSubmenu, setActionSubmenu] = useState<string | null>(null);

    const {pathname} = useLocation();

    const canEdit = org.userContext.permissions.edit || org.userContext.permissions.admin;

    // useEffect(() => {
    // 	if (isSuccess && currentOrg) {
    // 		const { definition, label } = currentOrg;
    // 		reset(
    // 			{ label, definition: definition ?? DEFINITION_PLACEHOLDER },
    // 			{ keepDefaultValues: false }
    // 		);
    // 	}
    // }, [reset, isSuccess, currentOrg]);

    const toggleDropdown: MouseEventHandler = () => {
        setMenuOpen((p) => !p);
    };

    const pathToProfile = `../${name}`

    return (
        <PopoverCardBody>
            <FlexContainer
                justifyContent='space-between'
                style={{
                    width: '100%',
                    borderBottom: menuOpen
                        ? `1px solid ${theme.palette.divider}`
                        : 'none'
                }}
            >
                <PopoverHead>
                    <form onSubmit={conditionalSubmit}>
                        <SubtleTextbox
                            {...register('label', {
                                required: "A value for 'label' is required",
                            })}
                            isDirty={Boolean(labelIsDirty)}
                            label="label"
                            onReset={() => resetField('label')}
                            overrides={{
                                root: {
                                    fontSize: '1.2rem',
                                    letterSpacing: '0.1em',
                                    fontWeight: '600',
                                    display: 'block',
                                    margin: 0,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                },
                                container: {
                                    maxWidth: '250px',
                                },
                            }}
                            disabled={!canEdit}
                        />
                        {/*<PopoverSubhead>*/}
                        {/*    {(canEdit || isNonEmptyString(definition)) &&*/}
                        {/*    (canEdit ? (*/}
                        {/*        <SubtleTextArea*/}
                        {/*            {...register('definition')}*/}
                        {/*            isDirty={Boolean(definitionIsDirty)}*/}
                        {/*            label="definition"*/}
                        {/*            onReset={() => resetField('definition')}*/}
                        {/*            rows={2}*/}
                        {/*            overrides={{*/}
                        {/*                root: {*/}
                        {/*                    resize: 'vertical',*/}
                        {/*                },*/}
                        {/*            }}*/}
                        {/*            disabled={!canEdit}*/}
                        {/*        />*/}
                        {/*    ) : (*/}
                        {/*        <p>{definition}</p>*/}
                        {/*    ))}*/}
                        {/*</PopoverSubhead>*/}
                    </form>
                </PopoverHead>
                <OrgMenu
                    org={org}
                    canEdit={canEdit}
                />
            </FlexContainer>
            <PopoverDrawer open={menuOpen}>
                <OrgPopoverDropdown
                    orgId={_id}
                    orgLabel={label}
                    actionSubmenu={actionSubmenu}
                    setActionSubmenu={setActionSubmenu}
                />
            </PopoverDrawer>
            <OrgPopoverControls
                userContext={userContext}
                orgLabel={label}
                org={org}
                canEdit={canEdit}
                toggleDropdown={toggleDropdown}
                menuOpen={menuOpen}
                pathToProfile={pathToProfile}
            />
        </PopoverCardBody>
    );
};

export default OrgPopover;
