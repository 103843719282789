import React, {FunctionComponent} from "react";
import IconButton from "./IconButton";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";

export const FacebookButton: FunctionComponent<{resource: any}> = ({resource}) => {
	return resource && resource.social.facebook
			? resource._object === 'Organization'
				? <a href={`https://facebook.com/${resource.social.facebook}`} target='_blank' rel="noreferrer">
					<IconButton
						size={'xs'}
						iconSize={'lg'}
						fillColor={'cyan'}
						icon={faFacebook as any}
					/>
				</a>
				: <a href={`https://facebook.com/${resource.social.facebook.username}`} target='_blank' rel="noreferrer">
					<IconButton
						size={'xs'}
						iconSize={'lg'}
						fillColor={'cyan'}
						icon={faFacebook as any}
					/>
				</a> : null;
}

export default FacebookButton;
