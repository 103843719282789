import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch } from 'app/hooks';
import Heading from 'common/text/Heading';
import { pipe } from 'common/utils/functionUtils';
import { viewsAvailable } from 'features/HUD/state/HUDSlice';
import {useGetOrgsQuery, useGetUserProfileQuery} from 'features/api';
import { deriveUserViews } from 'features/compositeViews/helpers';
import UserProfile from 'features/profile/UserProfile';
import useRenderView, {
	RenderViewConfig,
} from 'features/navigation/hooks/useRenderView';
// import useActiveUserData from 'features/ontology/hooks/useActiveUserData';
import { StyledProfileGridLayout } from 'features/profile/common/styledComponents';
import { FunctionComponent, useEffect } from 'react';
import FlexContainer from "../../../common/FlexContainer";
import {useParams} from "react-router-dom";
import UserMeta from "../../../common/meta/UserMeta";

const Stub: FunctionComponent<{ placeholder: string }> = ({ placeholder }) => {
	return (
		<StyledProfileGridLayout>
			<div/>
			<Heading style={{color: '#29323c'}} component="h5">
				{placeholder}
			</Heading>
		</StyledProfileGridLayout>
	);
};

const configs: RenderViewConfig = {
	viewDefault: 'profile',
	views: [
		{
			match: 'profile',
			element: <UserProfile />,
		}
	],
};

const UserViews: FunctionComponent = () => {
	// const { username } = useParams();
	// const 	{ data: response} = useGetUserProfileQuery({
	// 	username: username ? username : ''
	// });
	// const activeUser = response?.response

	// const { data: userOrgs, isSuccess } = useGetOrgsQuery(
	// 	activeUser ? { userId: activeUser._id } : skipToken
	// );

	// const appDispatch = useAppDispatch();

	// const render = useRenderView(configs);
	//
	// useEffect(() => {
	// 	if (userOrgs) {
	// 		const views = deriveUserViews();
	// 		pipe(views, viewsAvailable, appDispatch);
	// 	}
	//
	// 	return () => pipe([], viewsAvailable, appDispatch) as void;
	// }, [activeUser, appDispatch]);

	return <>
		<UserMeta />
		<FlexContainer
		alignItems='center'
		justifyContent='center'
		alignContent='center'>
		<UserProfile />
	</FlexContainer>
	</>;
};

export default UserViews;
