import { WithDrawCoords } from '../../types';
import { ReactElement } from 'react';

// points of an event line often represent an aggregation of several
// underlying data--that's why originalIndices is plural/an array rather
// than a single index position.
export interface EventPoint {
	x: Date;
	y: number;
	originalIndices: number[];
	lineIdx: number;
	yAttr: string;
	xAttr: string;
	// generally pointId is constructed from the names of the x and y attributes it represents,
	// plus original index position, since this combination will be unique for any given point.
	// For convenience, this id is constructed and placed directly on the type rather than leaving it
	// for the caller to construct.
	pointId: string;
}

export type DrawnEventPoint = WithDrawCoords<EventPoint>;

export interface Line {
	line: EventPoint[];
	xMax: Date;
	xMin: Date;
	yMax: number;
	// if multiple lines are passed to chart, line drawing fn
	// needs to know which line is currently being drawn in order to set
	// color/position/event handlers/whatever. See comments for EventPoint.pointId
	// for notes on how lineIdx is usually constructed.
	lineId: string;
	lineIdx: number;
	xAttr: string;
	yAttr: string;
}

export type Lines = Array<Line>;

export interface DrawnLine extends Omit<Line, 'line'> {
	width: number;
	height: number;
	line: DrawnEventPoint[];
	// 'path' is the string of 'move' instructions that can be passed to an
	// SVG <path> element to create a continuous line.
	path: string;
}

export interface EventPointDrawFn {
	(point: DrawnEventPoint): ReactElement<any, any>;
}

export interface EventLineDrawFn {
	(drawnLine: DrawnLine): ReactElement<any, any>;
}

export interface EventCardArgs {
	x: number;
	y: number;
	xValue: string;
	yValue: string;
	visible: boolean;
	drawWidth: number;
	drawHeight: number;
}

export interface EventCardDrawFn {
	(cardArgs: EventCardArgs): ReactElement<any, any> | null;
}

export const eventGroupIntervals = [
	'FullYear',
	'Month',
	'Date',
	'Hours',
	'Minutes',
	'Seconds',
	'Milliseconds',
] as const;

export type TimeInterval = typeof eventGroupIntervals[number];

export type TimeIntervals = TimeInterval[];

// TODO: types could pry be improved here.
export interface AttributeTransformer {
	map?: (a: any) => any;
	aggregate?: (a: any[]) => any;
}

export type Transformers = Record<string, AttributeTransformer>;

export type BrushExtent =
	| [[number, number], [number, number]]
	| ((dims: {
			width: number;
			height: number;
			top: number;
			left: number;
			bottom: number;
			right: number;
	  }) => [[number, number], [number, number]]);
