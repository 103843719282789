import AttributeMeta from 'features/dataPreparation/AttributeMeta';
import CategoryMeta from 'features/dataPreparation/CategoryMeta';
import DataPreparer from 'features/dataPreparation/DataPreparer';
import { Individual } from 'features/ontology/types/individualTypes';
import { DataPoint, LeafArray } from 'common/viz/types';

type CategoryTree = Record<string, any>;

export type LeafRecoveryGuide = Array<string[]>;

// export class ExtentTracker {
// 	xMin: number = Number.POSITIVE_INFINITY;
// 	xMax: number = Number.NEGATIVE_INFINITY;
// 	yMax: number = Number.NEGATIVE_INFINITY;

// 	concat({ x, y }: { x: number; y: number }) {
// 		if (x > this.xMax) {
// 			this.xMax = x;
// 		}

// 		if (x < this.xMin) {
// 			this.xMin = x;
// 		}

// 		if (y > this.yMax) {
// 			this.yMax = y;
// 		}
// 	}
// }

const transformLeaves = (
	node: CategoryTree,
	transform: (leaf: LeafArray) => void
) => {
	// base condition
	if (Array.isArray(node)) {
		transform(node);
		return;
	}

	Object.values(node).forEach((subnode) =>
		transformLeaves(subnode, transform)
	);
};

export const genPointId = (xattr: string, yattr: string, origIdx: number) =>
	`${xattr}-${yattr}-${origIdx}`;

const buildCategoryTree = (yAttrs: string[], categories: CategoryMeta[]) => {
	let depth = -1;

	const dive = () => {
		depth++;

		const nextCat = categories[depth];

		// if we hit this case, the input array was empty
		if (!nextCat) {
			return yAttrs.map(() => []);
		}

		if (depth === categories.length - 1) {
			const res = Array.from(nextCat.uniqueValues.values()).reduce(
				(acc, k) => {
					const leafArray: LeafArray = [];

					// add subarray to hold the points for each different y-attribute for
					// which we'll generate points
					yAttrs.forEach(() => leafArray.push([] as DataPoint[]));

					acc[k] = leafArray;

					return acc;
				},
				{} as Record<string, any>
			);
			depth--;
			return res;
		}

		const res = Array.from(nextCat.uniqueValues.values()).reduce(
			(acc, k) => {
				acc[k] = dive();
				return acc;
			},
			{} as Record<string, any>
		);

		depth--;

		return res;
	};

	return dive();
};

const getLeafArray = (tree: CategoryTree, path: string[]) => {
	if (Array.isArray(tree)) {
		return tree;
	}

	let destination = tree;

	//  follow key names down through tree to the LeafArray
	//  that will store the points for the given combination of y-attribute and
	// category filters.
	path.forEach((key) => {
		destination = destination[key];
	});

	return destination as LeafArray;
};

const assignPointToLeaf = (
	xAttrName: string,
	yAttrNames: string[],
	datum: Individual,
	originalIdx: number,
	leafArray: LeafArray
) => {
	yAttrNames.forEach((yAttrName, i) => {
		const destinationArray = leafArray[i];

		if (Array.isArray(destinationArray)) {
			const dataPoint: DataPoint = {
				datum,
				originalIdx,
				xAttr: xAttrName,
				yAttr: yAttrName,
				x: datum[xAttrName],
				y: datum[yAttrName],
				pointId: genPointId(xAttrName, yAttrName, originalIdx),
			};

			destinationArray.push(dataPoint);

			return;
		}

		throw new Error(
			'recovered a destination for a chart datum that was not an array'
		);
	});
};

const isValidIndividual = (idx: number, ...metas: AttributeMeta[]) =>
	metas.every((meta) => meta && !meta.missingAt.has(idx) && !meta.nullAt.has(idx));

export const distributeByCategory = <A = any, B = any>(
	preparedData: DataPreparer,
	xAttr: string,
	yAttrs: string[],
	categories: string[],
	recoveryGuide: LeafRecoveryGuide,
	compareFn?: (a: DataPoint, b: DataPoint) => number
) => {
	if (categories.length !== recoveryGuide.length) {
		throw new Error(
			'number of categories did not match number of recovery instructions'
		);
	}

	if (yAttrs.length === 0) {
		return { charts: [] };
	}

	const data = preparedData.data;

	const categoryMetas = categories.map(
		(attrName) => preparedData.attributeFields[attrName]
	) as CategoryMeta[];

	const allMetas = [xAttr, ...yAttrs]
		.map((attrName) => preparedData.attributeFields[attrName])
		.concat(categoryMetas);

	const categoryTree = buildCategoryTree(yAttrs, categoryMetas);

	data.forEach((datum, originalIdx) => {
		// All fields that this operation will read must be populated
		// for each given datum.  If any datum has a needed field whose value
		// is null or undefined, disregard that datum.
		if (!isValidIndividual(originalIdx, ...allMetas)) {
			return;
		}

		const datumKeyPath = categories.map((c) => datum[c]);

		const leafArray = getLeafArray(categoryTree, datumKeyPath);

		assignPointToLeaf(xAttr, yAttrs, datum, originalIdx, leafArray);
	});

	if (compareFn) {
		transformLeaves(categoryTree, (leafArray) =>
			leafArray.forEach((subArray) => subArray.sort(compareFn))
		);
	}

	const charts = recoverLeavesInOrder<A, B>(categoryTree, recoveryGuide);

	return {
		charts,
	};
};

const recursivelyBuildPaths = (pathParts: Array<string[]>) => {
	const result: Array<string[]> = [];

	const walk = (
		result: Array<string[]>,
		pathSoFar: string[],
		currentPosition: number,
		pathParts: Array<string[]>
	) => {
		if (currentPosition === pathParts.length - 1) {
			pathParts[currentPosition].forEach((suffix) => {
				result.push([...pathSoFar, suffix]);
			});

			return;
		}

		pathParts[currentPosition].forEach((part) =>
			walk(result, [...pathSoFar, part], currentPosition + 1, pathParts)
		);
	};

	walk(result, [], 0, pathParts);
	return result;
};

// Returns a result array of LeafArrays in 'typewriter' order based on the order of the
// strings
const recoverLeavesInOrder = <A = any, B = any>(
	tree: CategoryTree,
	recoveryGuide: LeafRecoveryGuide
) => {
	const result: LeafArray<A, B>[] = [];

	// case: no categories.  Tree is just an array.
	if (recoveryGuide.length === 0) {
		if (Array.isArray(tree)) {
			result.push(tree);
			return result;
		}

		throw new Error(
			'table recovery received a tree data structure but no layer information'
		);
	}

	const pathsInOrder = recursivelyBuildPaths(recoveryGuide);

	pathsInOrder.forEach((path) => result.push(getLeafArray(tree, path)));

	return result;
};
