import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";
import Textbox from "./TextBox";

const NameInput: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleTextbox
        {...validatingRegister('name')}
        showLabel
        label="Name"
        isDirty={Boolean(formState.dirtyFields.name)}
        onReset={() => resetField('name')}
        error={harvestErrors(
            'name',
            formState,
            mutationResults
        )}
    />;
}

export default NameInput;
