import {
    renderDerivationSubfields
} from '../common/jsxHelpers';
import {
    deriveDurationFormDefaults,
    deriveDurationFormToPayload,
    temporalUnits,
} from './createActivityHelpers';
import {CreateActivityFormValues} from './createActivityTypes';
import Button from 'common/buttons/Button';
import FlexContainer from 'common/FlexContainer';
import {
    formFieldHasErrors,
    FormResults,
    genErrorIdFromLabel,
} from 'common/Form';
import Spinner from 'common/loading/Spinner';
import Typography from 'common/text/Typography';
import {useGetAttributesQuery, useCreateActivityMutation} from 'features/api';
import {mergeErrorStates, extractQueryErrMessage} from 'features/api/helpers';
import {EntityActionFormProps} from 'features/actions/common/commonTypes';
import {
    StyledFlexContainer,
    StyledPaper,
} from 'features/actions/common/styledComponents';
import useAttrNeighbors from 'features/actions/hooks/useAttrNeighbors';
import {
    BaseAttribute,
    GetEntityAttrsResponse
} from 'features/ontology/types/attributeTypes';
import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import TemporalUnitSelect from "../../../common/inputs/TemporalUnitSelect";
import StartEventSelect from "../../../common/inputs/StartEventSelect";
import IsRequiredInput from "../../../common/inputs/IsRequiredInput";
import useModalType from "../../HUD/hooks/useModalType";
import {PrependAttrFormValues} from "../prependAttribute/prependAttributeTypes";
import OtherIdSelect from "../../../common/inputs/OtherIdSelect";
import Uninitialized from "../../../common/loading/Uninitialized";
import Loading from "../../../common/loading/Loading";

const CreateActivityForm: FunctionComponent = () => {

    const {modalProps, closeModal} = useModalType();
	const {objectId: parentEntityId, canEdit} = modalProps;

    const formDefaults = deriveDurationFormDefaults();

    const {
        handleSubmit,
        register,
        reset,
        resetField,
        formState,
        watch,
        setError
    } =
        useForm<CreateActivityFormValues>({
            defaultValues: formDefaults,
        });

    const watchedAttrId = watch('attrId');

    const validatingRegister = useCallback(
        (inputName: keyof CreateActivityFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const {data: attributes, isLoading, isError, error, isSuccess} = useGetAttributesQuery(
        {entityId: parentEntityId,},
        {
            selectFromResult: (res) => {
                if (res.data) {
                    return {
                        ...res,
                        data: res.data.filter((a) => a.type === 'event'),
                    };
                }

                return res;
            },
        }
    );

    //  prevent filtering from running every render
    const filter = useMemo(() => {
        return (a: BaseAttribute) => a.type === 'event';
    }, []);

    const queryRes = useAttrNeighbors(
        watchedAttrId === 0 ? null : watchedAttrId,
        filter
    );

    const firstAttribute = useMemo(() => {
        if (attributes) {
            return (
                attributes.find((attr) => attr._id === watchedAttrId) ?? null
            );
        }

        return null;
    }, [watchedAttrId, attributes]);

    useEffect(() => {
        if (queryRes.isSuccess && closeModal) {
            closeModal();
        }
    }, [queryRes.isSuccess, closeModal]);

    const [createActivity, mutationResults] = useCreateActivityMutation();

    const mergedErrs = mergeErrorStates(queryRes, mutationResults);

    const onSubmit: SubmitHandler<CreateActivityFormValues> = (vals, e) => {
        e?.preventDefault();

        if (firstAttribute !== null) {
            if (vals.otherId === 0) {
                setError('otherId', {
                    message:
                        'An attribute to provide the end of activity must be selected',
                });
                return;
            }
            createActivity({
                entityId: parentEntityId,
                // entityId: firstAttribute._id,
                body: deriveDurationFormToPayload(vals),
            });
        }
    };

    if (queryRes.isLoading) {
        return (
            <FlexContainer justifyContent="center">
                <Typography paragraph variant='h5'>
                    Loading attributes...
                </Typography>
                <br/>
                <Spinner/>
            </FlexContainer>
        );
    }

    if (queryRes.isError) {
        return (
            <FlexContainer justifyContent="center">
                <Typography color="error" paragraph>
                    {extractQueryErrMessage(queryRes.error)}
                </Typography>
            </FlexContainer>
        );
    }

   if (!!attributes) {
        return (
            <AppModal
                label={"Create Activity"}
                isOpen={true}
                isDirty={formState.isDirty}
                onClose={closeModal}
                onSubmit={handleSubmit(onSubmit)}
                canEdit={canEdit}>
                <StartEventSelect
                    attrData={attributes}
                    resetField={resetField}
                    formState={formState}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                />
                {renderDerivationSubfields({
                    isDerivation: true,
                    formState,
                    resetField,
                    validatingRegister,
                    mutationResults
                })}
                <OtherIdSelect
                    fieldName={'otherId'}
                    validatingRegister={validatingRegister}
                    formState={formState}
                    selectableAttrs={ queryRes.attrNeighbors}
                />
                <TemporalUnitSelect
                    resetField={resetField}
                    formState={formState}
                    validatingRegister={validatingRegister}
                    mutationResults={mutationResults}
                />
                <FormResults
                    isError={!!mergedErrs}
                    error={mergedErrs}
                    validationErrors={formState.errors}
                />
            </AppModal>
        );
   } else {
       return null;
   }
};

export default CreateActivityForm;
