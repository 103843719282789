import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List } from 'common/List';
import { PopoverBase } from 'common/popovers/Popover';
import Typography from 'common/text/Typography';
import {
	ChangeEventHandler,
	FunctionComponent,
	ReactElement,
	useState,
} from 'react';
import styled from 'styled-components';

const StyledInputContainer = styled.div`
	position: relative;
	height: 2rem;
	align-self: center;
	width: 250px;
		
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		width: 2.5rem;
	}
`;

	// padding-left: ${(p) => p.theme.spacing(3)};
const StyledInput = styled.input`
	border: 1px solid ${(p) => p.theme.palette.darkBaby};
	background: ${(p) => p.theme.palette.slickRick};
	opacity: 90%;
	border-radius: ${(p) => p.theme.spacing(0.5)};
	height: 100%;
	width: 100%;
	padding-left: 30px;
	padding-bottom: 1px;
	font-weight: 500;
	font-size: 12px;
	color: ${(p) => p.theme.palette.oldManGray};
	&:focus {
		border-radius: 4px 4px 1px 1px ;
		outline: none;
		border: 1px solid ${(p) => p.theme.palette.slickRick};
	}
	&:active {
		outline: none;
		border: 1px solid ${(p) => p.theme.palette.darkBaby};
	}
`;

const StyledSearchIcon = styled(FontAwesomeIcon)`
	position: absolute;
	top: 50%;
	opacity: 100%;
	color: ${(p) => p.theme.palette.oldManGray};
	transform: translateY(-50%);
	left: ${(p) => p.theme.spacing(1)};
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		left: ${(p) => p.theme.spacing(1.5)};
	}
`;

interface SearchBarProps {
	onChange: (value: string) => void;
	results: ReactElement[];
	label: string;
	value: string;
}

const SearchBar: FunctionComponent<SearchBarProps> = ({
	label,
	results,
	onChange,
	value,
}) => {
	const [anchor, setAnchor] = useState<HTMLElement | null>(null);
	const [menuOpen, setMenuOpen] = useState(false);

	const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		// if input is transitioning from empty to populated, open the menu
		if (e.target.value.length === 1 && !menuOpen) {
			setMenuOpen(true);
		}

		// if input is transitioning from populated to empty, close the menu
		if (e.target.value.length === 0 && menuOpen) {
			setMenuOpen(false);
		}

		onChange(e.target.value);
	};
	return (
		<StyledInputContainer>
			<StyledInput
				aria-label={label}
				type="text"
				onChange={handleChange}
				ref={setAnchor}
				onFocus={() => {
					if (value.length === 0) {
						return;
					}
					return setMenuOpen(true);
				}}
				onBlur={() => setMenuOpen(false)}
				value={value}
				onClick={(e) => e.stopPropagation()}
			/>
			<StyledSearchIcon color='oldManGray' icon={faSearch} size="sm" />
			<PopoverBase
				handleClickAway={() => setMenuOpen(false)}
				open={menuOpen}
				anchorEl={anchor}
				placement="bottom"
				offset={0}
				overrides={{
					paper: {
						maxHeight: '400px',
						overflowY: 'auto',
						// width: 'max-content',
						fontWeight: 500,
						// fontSize: '12px',
						paddingLeft: '30px',
						width: '250px',
						borderRadius: '1px 1px 4px 4px',
					},
				}}
			>
				{
					results.length === 0 ? (
						<ul>
							<Typography color={'oldManGray'} variant={'body2'}>
								No results
							</Typography>
						</ul>
					) : results.length > 0
						? <ul>
						{results.map((item, i: number) => {
							return (<li key={i}>
								<Typography color='oldManGray' variant='body2'>
									{item}
								</Typography>
							</li>)
						})}
						</ul>
						: null
				}
			</PopoverBase>
		</StyledInputContainer>
	);
};

export default SearchBar;
