import {FunctionComponent} from "react";
import useActiveLandingData from "../ontology/hooks/useActiveLandingData";
import useCurrentUser from "../authentication/hooks/useUser";
import UserViews from "./UserViews";
import OrgViews from "./OrgViews";

const AgentViews: FunctionComponent = () => {
	const { activeItem: activeResource } = useActiveLandingData();

    return activeResource._object == 'User'
        ? <UserViews />
        : <OrgViews />;
}

export default AgentViews;
