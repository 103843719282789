import { ActionDescriptor } from '../common/commonTypes';
import MultiplyAttributeForm from './MultiplyAttributeForm';
import {faMultiply} from "@fortawesome/free-solid-svg-icons";

const multiplyAttribute: ActionDescriptor = {
	form: MultiplyAttributeForm,
	actionTitle: 'Multiply',
	requiresEdit: true,
	actionIcon: faMultiply,
	kind: 'modal',
	divider: false,
	modalType: 'multiplyAttribute'
};

export default multiplyAttribute;
