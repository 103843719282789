import { useGetOrgMembersQuery, useGetDomainStewardsQuery } from '../../api';
import { BaseUser } from '../../authentication/types/userTypes';
import { filterDomainMembers } from '../helpers';
import { DomainAuthorityLevel } from '../types/GovernanceTypes';
import useCombinedOrgMembers from './useCombinedOrgMembers';
import { useMemo } from 'react';

const emptyMembers: BaseUser[] = [];
const emptyStewards: BaseUser[] = [];

const useFilteredDomainMembers = (
	domainId: number,
	orgId: number,
	authorityLevel: DomainAuthorityLevel
) => {
	const combinedOrgMembers = useCombinedOrgMembers(orgId);

	const { members } = useGetOrgMembersQuery(
		{ orgId },
		{ selectFromResult: ({ data }) => ({ members: data ?? emptyMembers }) }
	);

	const { stewards } = useGetDomainStewardsQuery(
		{ domainId },
		{
			selectFromResult: ({ data }) => ({
				stewards: data ?? emptyStewards,
			}),
		}
	);

	return useMemo(
		() =>
			filterDomainMembers(
				authorityLevel,
				{ members, stewards },
				combinedOrgMembers
			),
		[members, stewards, authorityLevel, combinedOrgMembers]
	);
};

export default useFilteredDomainMembers;
