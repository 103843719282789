import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useState
} from "react";
import theme from "../theme/theme";
import Modal from "./Modal";
import ReactModal from "react-modal";
import StyledSquare from "../StyledSquare";
import Typography from "../text/Typography";
import IconButton, {IconButtonBase} from "../buttons/IconButton";
import {
	faInfo,
	faInfoCircle,
	faTimes,
	faX
} from "@fortawesome/free-solid-svg-icons";
import {BasePaper} from '../Paper';
import styled from 'styled-components';
import {harvestErrors, NonInputLine, SubtleTextbox} from "../inputs/SubtleTextBox";
import SubtleSelect from "../inputs/SubtleSelect";
import {FormResults} from "../Form";
import useCurrentUser from "../../features/authentication/hooks/useUser";
import {
	useCreateFeedbackMutation,
	useCreateShareMutation,
	useCreateLinkedInShareMutation
} from "../../features/api";
import {SubmitHandler, useForm} from "react-hook-form";
import Button from "../buttons/Button";
import FlexContainer from "../FlexContainer";
import {StyledInputContainer} from "../inputs/SubtleTextBox/styledComponents";
import Heading from "../text/Heading";
import SubtleTextArea from "../inputs/SubtleTextArea";
import {Link, useLocation} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebook,
	faGithub, faInstagram,
	faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import ProfileAvatar from "../../features/navigation/components/Navbar/ProfileAvatar";
import useModalType from "../../features/HUD/hooks/useModalType";
import Textbox from "../inputs/TextBox";
import {
	initQueryStateTracker, mergeQueryStates,
	QueryStateTracker
} from "../../features/api/helpers";
import useLinkedInCodeRequest from "../sharing/useLinkedInCodeRequest";
import useOAuthTokenIsValid
	from "../../features/authentication/hooks/useOAuthTokenIsValid";
import html2canvas from "html2canvas";
import {StyledSubmoduleHeader} from "../../features/profile/EntityProfile/components/styledComponents";
import AppModal from "./AppModal";

export const SourceBody = styled(BasePaper)`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: ${(p) => p.theme.palette.background.paper};
`

interface HUDProps {
	translucent?: boolean;
	borderRadius?: string;
	width?: string | number;
	height?: string | number;
}


	// box-shadow: ${(p) => p.theme.shadows[4]};
export const HUDModalBody = styled.div<HUDProps>`
	background: ${(p) =>
		p.theme.palette.background[
			p.translucent ? 'transparentDark' : 'paper'
		]};
		
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	color: ${(p) => p.theme.palette.lightBaby};
	width: 100%;
	height: calc(100vh - ${(p) => p.theme.navHeight * 4 + p.theme.panelGutter * 8}px);
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter * 4}px;
	position: absolute;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
	${(p) => p.theme.translucent}
`;

interface StyledModalHeaderProps {
	justifyContent?: string;
	borderBottom?: string;
	padding?: string;
}

	// padding: ${(p) => p.padding ? p.padding : p.theme.spacing(1, 2)};
export const ModalHeader = styled.header<StyledModalHeaderProps>`
	padding: 1rem;
    border-bottom: ${(p) => p.borderBottom ? p.borderBottom : `2px solid ${p.theme.palette.divider}`};
	display: flex;
	background: ${(p) => p.theme.palette.background['paper']};
	justify-content: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	justify-items: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	align-items: flex-start;
	align-content: flex-start;
	height: ${(p) => p.theme.navHeight};
	z-index: 10000;
`;

const StyledGraphicContainer = styled.div`
	position: relative;
	height:100%;
	padding-right: 100%;
	width: 0;
	float: left;
`
const StyledGraphicSquare = styled.div`
	background: ${(p) => p.theme.palette.primary.main};
	width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
`;

const StyledGraphicCircle = styled(StyledGraphicSquare)`
	background: ${(p) => p.theme.palette.primary.main};
	width: 6rem;
    height: 6rem;
    border-radius: 50%;
    position: absolute;
    left: 0;
`;


	// font-size: 1rem;
export const HUDModalHeaderText = styled.h1`
	display: flex;
	align-content: center;
	align-items: center;
	gap: 1rem;
	justify-content: flex-start;
	justify-items: flex-start;
`;


interface ModalHeaderProps extends ReactModal.Props {
	handleClose: () => void;
	headingText: string;
}


interface SubmitFeedbackFormValues {
	feedback: string;
	category: string;
	// phoneNumber: string;
}


const HUDModalHeader: FunctionComponent<ModalHeaderProps> = ({
	headingText,
	handleClose
}) => {
	return (
		<ModalHeader>
			<HUDModalHeaderText>
				<StyledSquare width={'2rem'} height={'2rem'}/>
				<Typography color={'oldManGray'} variant={'h1'}>
					{headingText}
				</Typography>
			</HUDModalHeaderText>
			<IconButton
				onClick={handleClose}
				icon={faTimes}
				aria-label="close source browser"
			/>
		</ModalHeader>
	);
};

const StyledButton = styled(Button)`
	margin-top: ${(p) => p.theme.spacing(1)};
`;

const capture = (elementId: string) => {

	const node = document.getElementById(elementId);

	if (!node) {
		return Promise.reject('Unable to find element requested for share');
	}

	return html2canvas(node).then((c) => {
		return new Promise<Blob>((res, rej) => {
			c.toBlob(
				(maybeBlob) => {
					if (maybeBlob === null) {
						return rej('Unable to convert canvas to blob');
					}
					return res(maybeBlob);
				}, 'image/jpg', 1
			);
		});
	});
};

const StyledForm = styled.form`
	padding: ${(p) => p.theme.spacing(1)};
`;

const StyledFormContainer = styled.div`
	min-width: 240px;
`;

const StyledFormHeader = styled(StyledSubmoduleHeader)`
	display: flex;
	justify-content: space-between;
`;


interface ShareFormValues {
	comment?: string;
	description?: string;
}

interface ShareFormProps {
	elementId: string;
	handleClose: () => void;
}


const ShareResourceModal: FunctionComponent = () => {

	const {modalProps, closeModal} = useModalType();
	const {elementId, canEdit} = modalProps;

	const user = useCurrentUser();

	const { register, formState, resetField, handleSubmit, reset, watch } = useForm<ShareFormValues>({
		defaultValues: {
			comment: '',
			description: '',
		},
	});


	// this is needed to update react-hook-form's default values with new
	// values from a successful PUT request.
	useEffect(() => {
		if (!!user && formState.isDirty) {
			closeModal();
		}
	}, [user, reset]);

	const validatingRegister = useCallback(
		(inputName: keyof ShareFormValues) =>
			register(inputName, {
				required: `${inputName} is required`,
			}),
		[register]
	);

	const [createShare, mutationResults] = useCreateShareMutation();

	const [wComment, wDescription] = watch(['comment', 'description']);

	const location = useLocation();

	const [localFormState, setLocalFormState] = useState<QueryStateTracker>(
		initQueryStateTracker()
	);

	const { login, ...loginStatus } = useLinkedInCodeRequest();

	const [launchShare, shareStatus] = useCreateLinkedInShareMutation();

	const linkedInTokenValid = useOAuthTokenIsValid('linkedin');

	const doShare = useCallback(
		async (
			{comment, description, code,}: ShareFormValues & { code?: string }
			) => {
			try {
				const blob = await capture(elementId);
				const fd = new FormData();
				fd.append('imageBlob', blob);
				fd.append('link', location.pathname + location.search);
				if (comment && comment.length > 1) fd.append('comment', comment);
				if (description && description.length > 1) fd.append('description', description);
				if (code) fd.append('code', code);
				launchShare({ body: fd });
			} catch (e) {
				return setLocalFormState((p) => ({
					...p,
					isError: true,
					isLoading: false,
					error: {
						status: 400,
						data: { message: e as string },
					},
				}));
			}
		},
		[elementId, location.pathname, location.search, launchShare]
	);

	const onSubmit: SubmitHandler<ShareFormValues> = async (vals) => {
		setLocalFormState((p) => ({
			...p, isLoading: true, isUninitialized: false,
		}));

		if (linkedInTokenValid) return doShare(vals);

		return login();

		// createShare({
			// body: Object.assign({userId: user._id}, formVals),
		// });
	};

	useEffect(() => {
		if (!linkedInTokenValid && loginStatus.code && loginStatus.isSuccess) {
			// if token was expired, the only way loginStatus could be in success state is
			// if user initiated 'share' submission and completed the login flow.
			// If that's the case, submit the share to api server with code.
			doShare({
				comment: wComment,
				description: wDescription,
				code: loginStatus.code,
			});
		}
		//  NB: we do NOT want this to re-run on form field changes.  wComment and wDescription
		// are intentionally omitted from dependency array.
	}, [linkedInTokenValid, loginStatus.code, loginStatus.isSuccess, doShare]);

	useEffect(() => {
		if (shareStatus.isSuccess) {
			// finally, if the 'share' request to api server is successful,
			// all loading is done and form should be in a success state.
			setLocalFormState((p) => ({
				...p,
				isSuccess: true,
				isError: false,
				isLoading: false,
				error: undefined,
			}));
		}
	}, [shareStatus.isSuccess]);

	const mergedQueryStates = mergeQueryStates(shareStatus, localFormState);

	if (user) {
		return <AppModal
				label={"Share resource"}
				isOpen={true}
				isDirty={formState.isDirty}
				onClose={closeModal}
				onSubmit={handleSubmit(onSubmit)}
				canEdit={canEdit}>
						<Heading style={{padding: 0, margin: 0}} component="h3">
					Comment
				</Heading>
			<SubtleTextbox
					{...register('comment')}
					defaultValue={'Describe your comment'}
					label="Comment"  // "post comment
					isDirty={Boolean(formState.dirtyFields.comment)}
					onReset={() => resetField('comment')}
					error={harvestErrors(
						'comment',
						formState,
						mutationResults
					)}
				/>
				<Heading style={{padding: 0, margin: 0}} component="h3">
					Description
				</Heading>
				<SubtleTextArea
					{...register('description')}
					label="Post"
					style={{minHeight: '300px'}}
					defaultValue={'Describe your share'}
					isDirty={Boolean(formState.dirtyFields.description)}
					onReset={() => resetField('description')}
					error={harvestErrors(
						'description',
						formState,
						mutationResults
					)}
				/>
				{/*<FlexContainer justifyContent="center">*/}
				{/*	<Button*/}
				{/*		disabled={*/}
				{/*			mergedQueryStates.isLoading ||*/}
				{/*			mergedQueryStates.isError ||*/}
				{/*			mergedQueryStates.isSuccess*/}
				{/*		}*/}
				{/*	>*/}
				{/*		<Typography variant='button'>*/}
				{/*			Submit*/}
				{/*		</Typography>*/}
				{/*	</Button>*/}
				{/*</FlexContainer>*/}
				<FormResults
					{...mergedQueryStates}
					onSuccess={closeModal}
					onSuccessDelay={1500}
				/>
		</AppModal>
	} else {
		return null
	}
};

export default ShareResourceModal;
