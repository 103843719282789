import { BaseOrg } from '../../ontology/types/orgTypes';
import {
	LiveDataCatalogs,
	DataSourceSummaries,
	LiveDataSets,
	DataSourceSummary,
	LiveDataCatalog,
	LiveDataSet,
	LiveDataColumn,
} from '../types/dataTypes';
import {
	isLiveDataSet,
	SelectedSourceData,
} from '../types/uiTypes';
import CardDisplay from './CardDisplay';
import DataPreview from './DatasetBrowser/DataPreview';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import {ViewMode} from "../../compositeViews/types";

interface ContextualDisplayProps {
	setOnDisplay: (selectable: SelectedSourceData) => void;
	onDisplay: SelectedSourceData;
	displayMode: ViewMode | 'browser';
	activeItem:
		| BaseOrg
		| DataSourceSummary
		| LiveDataCatalog
		| LiveDataSet
		| LiveDataColumn
		| null;
	closeForm: () => void;
	cardData: DataSourceSummaries | LiveDataCatalogs | LiveDataSets | null;
}

interface DataDisplayProps {
	dataset: LiveDataSet;
}

interface BrowserDisplayProps {
	setOnDisplay: (selectable: SelectedSourceData) => void;
	onDisplay: SelectedSourceData;
	activeItem:
		| BaseOrg
		| DataSourceSummary
		| LiveDataCatalog
		| LiveDataSet
		| LiveDataColumn
		| null;
	cardData: DataSourceSummaries | LiveDataCatalogs | LiveDataSets | null;
}


const StyledContextualDisplay = styled.section`
	padding: ${(p) => p.theme.spacing(1)};
	position: relative;
`;

const StyledListContainer = styled.section`
	overflow-y: auto;
	position: relative;
	overflow: auto;
	/* min-width: 300px; */
	/* max-width: 450px; */
`;

const ContextualDisplay: FunctionComponent<ContextualDisplayProps> = ({
	setOnDisplay,
	onDisplay,
	displayMode,
	closeForm,
	activeItem,
	cardData,
}) => {
	const renderDisplay = () => {
		if (displayMode === 'form') {
			// return (
			// 	<CreateConnectionModal
			// 		onClose={closeForm}
			// 		canEdit={true}
			// 		isOpen={true}
			// 		resourceId={activeItem && activeItem._id ? activeItem._id : null}
			// 	/>
			// );
		}


		return (
			<StyledListContainer>
				<ul>
					{/*<CardDisplay*/}
					{/*// 	setter={(selectable: SelectedSourceData) =>*/}
					{/*// 		setOnDisplay(selectable)*/}
					{/*// 	}*/}
					{/*// 	cardData={onDisplay}*/}
					{/*// />*/}
				</ul>
			</StyledListContainer>
		);
	};

	return <StyledContextualDisplay>
		{renderDisplay()}
	</StyledContextualDisplay>;
};


export const DataDisplay: FunctionComponent<DataDisplayProps> = ({dataset}) => {
	return <DataPreview dataset={dataset} />;
}

export const BrowserDisplay: FunctionComponent<BrowserDisplayProps> = ({cardData, setOnDisplay}) => {
	const renderDisplay = () => {
		return (
			cardData && <StyledListContainer>
				<ul>
					<CardDisplay
						setter={(selectable: SelectedSourceData) => setOnDisplay(selectable)}
						cardData={cardData}
					/>
				</ul>
			</StyledListContainer>
		);
	};

	return <StyledContextualDisplay>
		{renderDisplay()}
	</StyledContextualDisplay>;
};

export default ContextualDisplay;
