import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";

const PhoneNumberInput: FunctionComponent<ActionFieldProps> = (
    {
        defaultValue,
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleTextbox
        {...validatingRegister('phoneNumber')}
        label="Phone number"
        type='tel'
        defaultValue={defaultValue}
        isDirty={Boolean(formState.dirtyFields.phoneNumber)}
        onReset={() => resetField('phoneNumber')}
        error={harvestErrors(
            'phoneNumber',
            formState,
            mutationResults
        )}
        showLabel
    />;
}

//<form>
//   <label for="phone">Enter a phone number:</label>
//   <input type="tel" id="phone" name="phone"
//   placeholder="123-45-678"
//   pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}">
// </form>

export default PhoneNumberInput;
