import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import GridContainer from 'common/GridContainer';
import Heading from 'common/text/Heading';
import {BasePaper} from 'common/Paper';
import Typography from 'common/text/Typography';
import theme from 'common/theme/theme';
// import { format } from 'date-fns';
import useActiveOrgData from 'features/ontology/hooks/useActiveOrgData';
import React, {
    FunctionComponent,
    useState,
    ReactNode, useCallback,
} from 'react';
import {usePopperTooltip} from 'react-popper-tooltip';
import styled from 'styled-components';
import ProfileHeader from "./ProfileHeader";
import {
    StyledProfileGridLayout,
    OptimizedContainer,
    StyledProfileBase
} from "../common/styledComponents";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import clsx from "clsx";
import GovernancePanel from "../../governance/components/GovernancePanel";
import OrgMenu from "../../../common/menus/OrgMenu";
import DomainPanel from "../../../common/lists/DomainPanel";
// import MessageButton from "common/buttons/MessageButton";
import CommentsTab from "../../../common/comments/components/CommentsTab";
import QuestionsTab from "../../../common/questions/components/QuestionsTab";
// import ActivitiesTab from "../../HUD/components/ActivitiesTab";
import ActivitiesTab from "common/activities/components/ActivitiesTab";
import FlexContainer from "../../../common/FlexContainer";
import FollowButton from "../../../common/buttons/FollowButton";
import {BaseOrg} from "../../ontology/types/orgTypes";
import OrgMeta from "../../../common/meta/OrgMeta";

const StyledTabList = styled(TabList)`
	list-style-type: none;
	display: flex;
	width: 100%;	
	align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
    gap: .5rem;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid ${(p) => p.theme.palette.divider};
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
	    margin-top: 2rem;
		align-items: center;
        justify-content: center;
	}
`;
// margin: ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(2)};
// border-bottom: 1px solid ${(p) => p.theme.palette.divider};

const StyledTab = styled(Tab)`
	padding: ${(p) => p.theme.spacing(1)};
	&:hover {
		cursor: pointer;
		background-color: ${(p) => p.theme.palette.cyan};
	}

	&.selected {
		border-bottom: 1px solid ${(p) => p.theme.palette.background.paper};
	}
`;

const StyledProfileItem = styled(GridContainer)`
	padding: ${(p) => p.theme.spacing(2)};
	margin: 0;
	width: 100%;
	align-items: center;
	justify-content: space-between;
`;

const StyledPlaceholder = styled(StyledProfileItem)`
	border-bottom: 1px solid ${(p) => p.theme.palette.divider};
`;

// TODO: Depending on how db formats datetime, we may want this to read
// user's preferred timezone and format accordingly.  Docs are here:
// https://date-fns.org/docs/Getting-Started
// const formatDateString = (dateString: string) =>
// 	format(new Date(dateString), 'yyyy-MM-dd');

const StyledPaper = styled(BasePaper)`
	/* padding: ${(p) => p.theme.spacing(1)}; */
`;

interface TimezoneTooltipProps {
    children?: ReactNode;
}

const TimezoneTooltip: FunctionComponent<TimezoneTooltipProps> = (
    {children,}) => {
    // TODO: pry don't want this dependency--use Popper component instead,
    // since we have to have it for other things anyway.
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip();

    return (
        <>
            <FontAwesomeIcon
                style={{
                    marginLeft: '.5rem',
                    cursor: 'pointer',
                }}
                icon={faQuestionCircle}
                forwardedRef={setTriggerRef}
            />
            {visible && (
                <StyledPaper
                    ref={setTooltipRef as any}
                    {...getTooltipProps({className: 'tooltip-container'})}
                >
                    <div {...getArrowProps({className: 'tooltip-arrow'})} />
                    <Typography>{children}</Typography>
                </StyledPaper>
            )}
        </>
    );
};

const tabLabels = [
    // 'Insights',
    'About',
    'Domains',
    'Forum',
    'Questions',
    'Credits',
    'Activity',
];


export const OrgProfileControls: FunctionComponent<{ org: BaseOrg; }> = ({org}) => {

    const isFollowing = org.userContext.interactions.following;

    return <FlexContainer justifyContent={'center'} alignItems={'flex-end'}>
        <FollowButton objectAlreadyFollowed={isFollowing} resource={org}/>
    </FlexContainer>;
};

export const OrgHeader: FunctionComponent<{ onRefChange: any; org: BaseOrg; canEdit: boolean; }> = (
    {onRefChange, org, canEdit}
) => {

    return <FlexContainer
        ref={onRefChange}
        style={{gridArea: 'head'}}
        justifyContent={'flex-end'}
        alignItems={'flex-start'}>
        <FlexContainer style={{
            display: 'flex',
            placeContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            // gap: '1rem'
        }}>
            <OptimizedContainer>
                <ProfileHeader
                    headingText={org.label}
                    subheadText={org.orgType}
                    resource={org}
                    avatarUrl={org.avatar?.medium}
                    location={org.locale?.location}
                />
                <OrgProfileControls org={org}/>
            </OptimizedContainer>
        </FlexContainer>
        <OrgMenu
            org={org}
            canEdit={canEdit}
        />
    </FlexContainer>;
};

export const OrgContent: FunctionComponent<{ org: BaseOrg; canEdit: boolean; containerHeight: number; }> = (
    {org, containerHeight, canEdit}
) => {

    const [selectedIdx, setSelectedIdx] = useState<number>(0);

    return <Tabs
        style={{width: '100%', height: '100%', gridArea: 'main',}}
        selectedIndex={selectedIdx}
        onSelect={(idx) => setSelectedIdx(idx)}>
        <StyledTabList>
            {tabLabels.map((label, i) => (
                <StyledTab
                    className={clsx(i === selectedIdx && 'selected')}
                    key={label}>
                    <Typography variant='h6' color='oldManGray'>
                        {label}
                    </Typography>
                </StyledTab>
            ))}
        </StyledTabList>
        <div style={{
            maxHeight: `calc(100vh - ${containerHeight + theme.navHeight * 3}px)`,
            width: '100%',
            overflowY: 'auto'
        }}>
            {[
                {
                    Panel: DomainPanel,
                    args: {org: org, canEdit: canEdit}
                },
                {
                    Panel: DomainPanel,
                    args: {org: org, canEdit: canEdit}
                },
                {
                    Panel: CommentsTab,
                    args: {container: '100%'}
                },
                {Panel: QuestionsTab, args: {}},
                {
                    Panel: GovernancePanel,
                    args: {container: '100%'}
                },
                {Panel: ActivitiesTab, args: {}},
            ].map((Panel, i) => {
                return i === selectedIdx
                    ? <TabPanel key={i}>
                        <Panel.Panel props={Panel.args}/>
                    </TabPanel>
                    : <TabPanel key={i}/>;
            })}
        </div>
    </Tabs>;

}

export const OrgProfile: FunctionComponent = () => {
    // TODO: make this robust

    // Don't run the query if we're not navigated to right page
    const {isLoading, isFetching, activeItem: activeOrg} = useActiveOrgData();
    const [containerHeight, setContainerHeight] = useState<number>(300);

    // const useEffect(() => {
    //     function handleResize() {
    //         if (node) {
    //             const bounding = node.getBoundingClientRect();
    //             console.log(bounding);
    //             setContainerHeight(bounding.height);
    //         }
    //     }
    //
    //     window.addEventListener('resize', handleResize)
    //
    //     return () => {
    //         window.removeEventListener('resize', handleResize)
    //     }
    //
    // }, [])
    const onRefChange = useCallback((node: any) => {
        if (node) {
            const bounding = node.getBoundingClientRect();
            console.log(bounding);
            setContainerHeight(bounding.height);
        }
    }, []);

    if (isLoading || isFetching) {
        return (
            <>
                <StyledProfileBase>
                    <StyledProfileGridLayout>
                        <StyledPlaceholder key="title">
                            <Heading component="h5">
                                Loading...
                            </Heading>
                        </StyledPlaceholder>
                    </StyledProfileGridLayout>
                </StyledProfileBase>
            </>
        );
    }

    if (!!activeOrg) {
        //  (type: ModalType | null) => appDispatch(setModalType({type: type}))
        // const {currency, timezone, language} = locale;

        const canEdit = activeOrg.userContext.permissions.edit
            || activeOrg.userContext.permissions.admin;

        return (
            <>
                <StyledProfileBase>
                    <StyledProfileGridLayout>
                        <OrgHeader
                            onRefChange={onRefChange}
                            org={activeOrg}
                            canEdit={canEdit}

                        />
                        <OrgContent
                            org={activeOrg}
                            canEdit={canEdit}
                            containerHeight={containerHeight}
                        />
                    </StyledProfileGridLayout>
                </StyledProfileBase>
            </>
        );
    }

    return null;
};


// export const OrgPanel: FunctionComponent = () => {
//     // TODO: make this robust
//     // const { org } = useParams() as { org: string };
//
//     const [tzs, setTzs] = useState([]);
//
//
//     // placeholder submission handlers
//     const onTZSubmit = (e: FormEvent) => {
//         e.preventDefault();
//         // @ts-ignore
//         const fd = new FormData(e.target);
//
//         for (let [key, val] of fd) {
//             console.log('key:', key, 'val:', val);
//         }
//     };
//
//     const onCurrencySubmit = (e: FormEvent) => {
//         e.preventDefault();
//         // @ts-ignore
//         const fd = new FormData(e.target);
//
//         for (let [key, val] of fd) {
//             console.log('key:', key, 'val:', val);
//         }
//     };
//
//     // fetch data to populate timezone select
//     useEffect(() => {
//         fetch('https://worldtimeapi.org/api/timezone')
//             .then((res) => res.json())
//             .then(setTzs);
//     }, []);
//
//     // fetch data to populate currency select
//     useEffect(() => {
//         fetch('https://worldtimeapi.org/api/timezone')
//             .then((res) => res.json())
//             .then(setTzs);
//     }, []);
//
//     // Don't run the query if we're not navigated to right page
//     const {isLoading, isFetching, activeItem: activeOrg} = useActiveOrgData();
//
//
//     if (activeOrg) {
//         const {
//             label,
//             orgType,
//         } = activeOrg;
//
//         return <StyledProfilePanelGrid>
//             <ProfileHeader
//                 headingText={label}
//                 subheadText={orgType}
//                 resource={activeOrg}
//             />
//         </StyledProfilePanelGrid>;
//     }
//
//     if (isLoading || isFetching) {
//         return (
//             <StyledList>
//                 <li>
//                     <StyledPlaceholder key="title">
//                         <Heading component="h5">
//                             Loading...
//                         </Heading>
//                     </StyledPlaceholder>
//                 </li>
//             </StyledList>
//         );
//     }
//
//     return null;
// };

export default OrgProfile;
