import Heading from '../../../common/text/Heading';
import React, {FunctionComponent, ReactNode} from 'react';
import styled from 'styled-components';
import ProfileAvatar from "../../navigation/components/Navbar/ProfileAvatar";
import Typography from "../../../common/text/Typography";
import RouterLink from "../../../common/RouterLink";
import NavStats from "../../navigation/components/Navbar/NavStats";
import {UserGravatarData} from '../../authentication/types/userTypes';
import FlexContainer from "../../../common/FlexContainer";
import LinkedInButton from "../../../common/buttons/LinkedInButton";
import FacebookButton from "../../../common/buttons/FacebookButton";
import InstagramButton from "../../../common/buttons/InstagramButton";
import GithubButton from "../../../common/buttons/GithubButton";
import {ListItem} from "../../../common/List";
import AddMemberButton from "../../../common/buttons/AddMemberButton";
import {NonInputLine} from "../../../common/inputs/SubtleTextBox";
import {StyledGraphicContainer} from "common/StyledGraphicContainer"
import {StyledGraphicCircle} from "common/StyledCircle"
import {
    DrawerHeader,
    DrawerHeading,
    DrawerMain,
    DrawerSubhead
} from "../../../common/Drawer";
import useLinkTruncater from "../../navigation/hooks/useLinkTruncater";
import {isPrimaryIdentity} from "../../ontology/typeGuards/attributeGuards";
import {BaseAttribute} from "../../ontology/types/attributeTypes";
import FollowersButton from "../../../common/buttons/FollowersButton";

const StyledHeaderSection = styled.div`
	display: grid;
	grid-template:
	 [row1-start] "avatar header" 1fr [row1-end] 
	 [row2-start] ". social" auto [row2-end] 
	 / auto 1fr;
	justify-items: start;
	place-content: start start;
	align-items: center;
	gap: 2rem;
	width: 100%;
	height: 100%;
	text-align: left;
		
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		grid-template:
		 [row1-start] "avatar" 1fr [row1-end] 
		 [row2-start] "header" 1fr [row2-end] 
		 [row3-start] "social" auto [row3-end] 
		 / 1fr;
		gap: 1rem;
		place-items: center center;
		place-content: center center;
		text-align: center;
	}
`;
// justify-items: flex-start;

//    [row6-start] "location" 1fr [row6-end]
const StyledLabelSection = styled.div`
	display: grid;
	grid-template:
    [row1-start] "overline" auto [row1-end]
    [row2-start] "title" 1fr [row2-end]
    [row3-start] "url" 1fr [row3-end]
    / 1fr;
	justify-content: start;
	align-content: start;
	justify-items: start;
	align-items: start;
	gap: .5rem;
	
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		place-items: center;
		gap: .25rem;
		place-content: start;
		text-align: center;
	}
`;

const OptimizedContainer = styled(FlexContainer)`

    padding: 0;
    margin: 0;
    justify-content: start;
    justify-items: start;
    justify-self: start;
    align-self: start;
    align-items: center;
    align-content: center;
    width: 100%;
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.md}px) {
		justify-items: center;
		justify-content: center;
		align-content: center;
		align-items: center;
		text-align: center;
	}
`


interface ProfileHeaderProps {
    headingText: string;
    subheadText?: string;
    resource?: any;
    subheadUrl?: string | null;
    includeAvatar?: boolean;
    children?: ReactNode;
    openSettings?: () => void;
    openFollowers?: () => void;
    // canEdit?: boolean;
    // setIsSettingsOpen?: () => void;
    webUrl?: string | null;
    avatarUrl?: string | null;
    location?: string | null;
    isOnline?: boolean;
}

interface IndividualHeaderProps {
    datum: Record<string, any>;
    attributes: BaseAttribute[];
}

const ProfileOverline: FunctionComponent<{ text: string; url?: string }> = (
    {text, url}
) => {
    return url
        ? <RouterLink to={url} preserveSearchParams={false}>
            <Typography style={{gridArea: 'overline',}} variant="subtitle">
                {text}
            </Typography>
        </RouterLink>
        : <Typography style={{gridArea: 'overline',}} variant="subtitle">
            {text}
        </Typography>;
}


const IndividualHeading: FunctionComponent<IndividualHeaderProps> = (
    {
        datum,
        attributes
    }
) => {

    const truncate = useLinkTruncater();
    const parentEntityLabel = attributes[0].entity.singular;
    const primaryIdentity = attributes.find(isPrimaryIdentity) ?? null;

    return (
        <StyledHeaderSection>
            {/*{resource.avatar.thumb*/}
            {/*    ? <ProfileAvatar*/}
            {/*        profileImageURL={resource.avatar.medium || avatarUrl}*/}
            {/*        initial={resource.name[0]}*/}
            {/*        isProfile={true}*/}
            {/*        onClick={() => {*/}
            {/*        }}*/}
            {/*        avatarScale={resource?.avatarScale}*/}
            {/*        avatarPosition={resource?.avatarPosition}*/}
            {/*        styles={{gridArea: 'avatar'}}*/}
            {/*        isOnline={isOnline}*/}
            {/*        height={'12rem'}*/}
            {/*        width={'12rem'}*/}
            {/*    />*/}
            {/*    : includeAvatar*/}
            {/*        ? */}
            {/*        : null}*/}
            <StyledGraphicContainer style={{
                gridArea: 'avatar',
                height: '12rem',
                width: '12rem'
            }}>
                <StyledGraphicCircle style={{
                    height: '12rem',
                    width: '12rem'
                }}/>
            </StyledGraphicContainer>
            <StyledLabelSection style={{gridArea: 'header'}}>
                <ProfileOverline text={parentEntityLabel ?? ''}
                                 url={truncate('entity', primaryIdentity ? primaryIdentity.entity.name : '')}/>
                <Heading style={{gridArea: 'title',}} component="h1">
                    {primaryIdentity && `${datum[primaryIdentity.name]}`}
                </Heading>
                <FollowersButton resource={datum}/>
                {/*{resource.domainName && (*/}
                {/*    <a href={resource.domainName} style={{gridArea: 'url'}}>*/}
                {/*        <Typography variant='h4'>*/}
                {/*            {resource.domainName}*/}
                {/*        </Typography>*/}
                {/*    </a>*/}
                {/*)}*/}
            </StyledLabelSection>
            {/*<SocialHeader resource={datum}/>*/}
        </StyledHeaderSection>
    );
};

export default IndividualHeading;
