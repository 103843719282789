import {
	AttrFilterType,
	FilterValidator,
	CatRangeFilter,
	QsParam,
	QtyRangeFilter,
	EventRangeFilter,
	IdentityFilter,
} from './types';
import {
	isAppError,
	isNonEmptyString,
	isNumber,
} from 'common/utils/typeGuards';
import { isNonNullObject } from 'common/utils/typeGuards';
import { hasOwnProperty } from 'common/utils/typeUtils';
import {
	QUERY_STRING_FILTER_PARSING_ERROR,
	CAT_RANGE_FILTER_TYPE,
	QTY_RANGE_FILTER_TYPE,
	EVENT_RANGE_FILTER_TYPE,
	IDENTITY_FILTER_TYPE,
} from 'features/compositeViews/EntityViews/CONSTANTS';
import { AppError } from 'features/errorHandling/types/errorTypes';

const validateMinMax = (s: unknown) =>
	Array.isArray(s) && s.length === 2 && s.every(isNumber) && s[0] <= s[1];

const validateIncludedValues = (as: unknown) =>
	Array.isArray(as) && as.length > 0 && as.every(isNonEmptyString);

const validate = <T extends Record<string, any>>(
	toValidate: { [K in keyof T]: unknown },
	validator: FilterValidator<T>
) =>
	Object.entries(toValidate).reduce((acc, next) => {
		if (isAppError(acc)) return acc;

		const [k, v] = next;

		if (validator[k](v)) {
			// TODO: fix this type
			//    @ts-ignore
			acc[k] = v;
			return acc;
		}

		return new AppError(QUERY_STRING_FILTER_PARSING_ERROR);
	}, {} as T | AppError);

const qtyRangeValidator: FilterValidator<QtyRangeFilter> = {
	attributeName: isNonEmptyString,
	filterType: (s) => s === QTY_RANGE_FILTER_TYPE,
	min: isNumber,
	max: isNumber,
	searchParamKey: isNonEmptyString,
	searchParamValue: isNonEmptyString,
};

const eventRangeValidator: FilterValidator<EventRangeFilter> = {
	attributeName: isNonEmptyString,
	filterType: (s) => s === EVENT_RANGE_FILTER_TYPE,
	min: isNumber,
	max: isNumber,
	searchParamKey: isNonEmptyString,
	searchParamValue: isNonEmptyString,
};

const identityValidator: FilterValidator<IdentityFilter> = {
	attributeName: isNonEmptyString,
	filterType: (s) => s === IDENTITY_FILTER_TYPE,
	searchParamKey: isNonEmptyString,
	searchParamValue: isNonEmptyString,
	includedIdentities: (ids) =>
		Array.isArray(ids) && ids.every(isNonEmptyString),
};

const catRangeValidator: FilterValidator<CatRangeFilter> = {
	attributeName: isNonEmptyString,
	filterType: (s) => s === CAT_RANGE_FILTER_TYPE,
	includedValues: (as) => Array.isArray(as) && as.every(isNonEmptyString),
	searchParamKey: isNonEmptyString,
	searchParamValue: isNonEmptyString,
};

export const parseParam = (
	param: QsParam
): {
	attributeName: string;
	filterType: AttrFilterType;
} => {
	const [attributeName, filterType] = param[0].split(':');
	return { filterType: filterType as AttrFilterType, attributeName };
};

export const parseQtyRangeFilter = (param: QsParam) => {
	const { attributeName, filterType } = parseParam(param);

	const [min, max] = param[1].split(',').map(Number);

	const result = {
		attributeName,
		filterType,
		min,
		max,
		searchParamKey: param[0],
		searchParamValue: param[1],
	};

	return validate<QtyRangeFilter>(result, qtyRangeValidator);
};

export const createQtyRangeFilter =
	(attributeName: string) => (minMax: [number, number]) => {
		const prelims = validate(
			{ attributeName, minMax },
			{ attributeName: isNonEmptyString, minMax: validateMinMax }
		);

		if (isAppError(prelims)) {
			return prelims;
		}

		const [min, max] = minMax;

		const result = {
			searchParamKey: qtyRangeKey(attributeName),
			searchParamValue: `${min},${max}`,
			filterType: QTY_RANGE_FILTER_TYPE,
			attributeName,
			min,
			max,
		};

		return result;
	};

export const parseCatRangeFilter = (param: QsParam) => {
	const { attributeName, filterType } = parseParam(param);

	// NB: we encodeURIComponent() category names BEFORE we add them
	// to query string filter, so there shouldn't be any commas except those
	// we add as delineators AFTER encoding.
	// TODO: decodeURIComponent CAN throw. It is unlikely in this setting,
	// AFAIK.
	const includedValues = param[1].split(',').map(decodeURIComponent);

	const result = {
		attributeName,
		filterType,
		includedValues,
		searchParamValue: param[1],
		searchParamKey: param[0],
	};

	return validate<CatRangeFilter>(result, catRangeValidator);
};

export const createCatRangeFilter =
	(attributeName: string) => (includedValues: string[]) => {
		const prelims = validate(
			{ attributeName, includedValues },
			{
				attributeName: isNonEmptyString,
				includedValues: validateIncludedValues,
			}
		);

		if (isAppError(prelims)) return prelims;

		try {
			const escapedValues = includedValues.map(encodeURIComponent);

			return {
				searchParamKey: catRangeKey(attributeName),
				searchParamValue: escapedValues.join(','),
				includedValues: escapedValues,
				attributeName,
				filterType: CAT_RANGE_FILTER_TYPE,
			};
		} catch (e) {
			return new AppError(
				isNonNullObject(e) && hasOwnProperty(e, 'message')
					? (e.message as string)
					: 'failure when attempting to URI-encode encode category range filter values'
			);
		}
	};

export const createEventRangeFilter =
	//  NB: minMax numbers represent MS since Unix epoch, i.e. Date primitives


		(attributeName: string) =>
		(minMax: [number, number]): EventRangeFilter | AppError => {
			const prelims = validate(
				{ attributeName, minMax },
				{ attributeName: isNonEmptyString, minMax: validateMinMax }
			);

			if (isAppError(prelims)) {
				return prelims;
			}

			const [min, max] = minMax;

			const result = {
				searchParamKey: eventRangeKey(attributeName),
				searchParamValue: `${min},${max}`,
				filterType: EVENT_RANGE_FILTER_TYPE,
				attributeName,
				min,
				max,
			};

			return result;
		};

export const parseEventRangeFilter = (param: QsParam) => {
	const { attributeName, filterType } = parseParam(param);

	const [min, max] = param[1].split(',').map(Number);

	const result = {
		attributeName,
		filterType,
		min,
		max,
		searchParamKey: param[0],
		searchParamValue: param[1],
	};

	return validate<EventRangeFilter>(result, eventRangeValidator);
};

export const createIdentityFilter =
	(attributeName: string) =>
	(includedIdentities: string[]): IdentityFilter | AppError => {
		const prelims = validate(
			{ attributeName, includedIdentities },
			{
				attributeName: isNonEmptyString,
				includedIdentities: (ids: unknown) =>
					Array.isArray(ids) &&
					ids.length > 0 &&
					ids.every(isNonEmptyString),
			}
		);

		if (isAppError(prelims)) {
			return prelims;
		}

		try {
			const escapedIdentities =
				includedIdentities.map(encodeURIComponent);

			return {
				includedIdentities,
				searchParamKey: identityKey(attributeName),
				searchParamValue: escapedIdentities.join(','),
				filterType: IDENTITY_FILTER_TYPE,
				attributeName,
			};
		} catch (e) {
			return new AppError(
				isNonNullObject(e) && hasOwnProperty(e, 'message')
					? (e.message as string)
					: 'failure when attempting to URI-encode encode identity range filter values'
			);
		}
	};

export const parseIdentityFilter = (
	param: QsParam
): IdentityFilter | AppError => {
	const { attributeName, filterType } = parseParam(param);

	const identities = param[1].split(',').map(decodeURIComponent);

	const result = {
		searchParamKey: param[0],
		searchParamValue: param[1],
		includedIdentities: identities,
		attributeName,
		filterType,
	};

	return validate<IdentityFilter>(result, identityValidator);
};

export const catRangeKey = (attrName: string) =>
	`${attrName}:${CAT_RANGE_FILTER_TYPE}`;

export const qtyRangeKey = (attrName: string) =>
	`${attrName}:${QTY_RANGE_FILTER_TYPE}`;

export const eventRangeKey = (attrName: string) =>
	`${attrName}:${EVENT_RANGE_FILTER_TYPE}`;

export const identityKey = (attrName: string) =>
	`${attrName}:${IDENTITY_FILTER_TYPE}`;

export const parserMap = {
	[CAT_RANGE_FILTER_TYPE]: parseCatRangeFilter,
	[EVENT_RANGE_FILTER_TYPE]: parseEventRangeFilter,
	[QTY_RANGE_FILTER_TYPE]: parseQtyRangeFilter,
	[IDENTITY_FILTER_TYPE]: parseIdentityFilter,
};
