import React, {FunctionComponent} from "react";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {SubtleTextbox, harvestErrors} from "./SubtleTextBox";
import Textbox from "./TextBox";

const UserNameInput: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    return <SubtleTextbox
        {...validatingRegister('username')}
        showLabel
        label="Username"
        isDirty={Boolean(formState.dirtyFields.username)}
        onReset={() => resetField('username')}
        error={harvestErrors(
            'username',
            formState,
            mutationResults
        )}
    />;
}

export default UserNameInput;
