import React, {FunctionComponent} from "react";
import useActiveOrgData from "../../features/ontology/hooks/useActiveOrgData";
import Typography from "../text/Typography";
import {BasePaper} from '../Paper';
import styled from 'styled-components';
import AppModal from "./AppModal";
import useModalType from "../../features/HUD/hooks/useModalType";
import useActiveDomainData
    from "../../features/ontology/hooks/useActiveDomainData";
import {canEdit as canEditOrg} from "../../features/authentication/helpers";
import {
    extractQueryErrMessage,
    mergeQueryStates
} from "../../features/api/helpers";
import MemberPanel, {UsersPanel} from "../../features/governance/components/MemberPanel";
import {BaseOrg} from "../../features/ontology/types/orgTypes";

export const SourceBody = styled(BasePaper)`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: ${(p) => p.theme.palette.background.paper};
`

interface HUDProps {
    translucent?: boolean;
    borderRadius?: string;
    width?: string | number;
    height?: string | number;
}

// box-shadow: ${(p) => p.theme.shadows[4]};

export const HUDModalBody = styled.div<HUDProps>`
	background: ${(p) =>
    p.theme.palette.background[
        p.translucent ? 'transparentDark' : 'paper'
        ]};
		
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	color: ${(p) => p.theme.palette.lightBaby};
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
	${(p) => p.theme.translucent}
`;

interface StyledModalHeaderProps {
    justifyContent?: string;
    borderBottom?: string;
    padding?: string;
}

// padding: ${(p) => p.padding ? p.padding : p.theme.spacing(1, 2)};
export const ModalHeader = styled.header<StyledModalHeaderProps>`
	padding: 1rem;
    border-bottom: ${(p) => p.borderBottom ? p.borderBottom : `2px solid ${p.theme.palette.divider}`};
	display: flex;
	background: ${(p) => p.theme.palette.background['paper']};
	justify-content: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	justify-items: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	align-items: flex-start;
	align-content: flex-start;
	height: 4rem;
	z-index: 10000;
`;

// font-size: 1rem;
export const HUDModalHeaderText = styled.h1`
	display: flex;
	align-content: center;
	align-items: center;
	gap: 1rem;
	justify-content: flex-start;
	justify-items: flex-start;
`;


const OrgCreditsModal: FunctionComponent = () => {

    const {modalType, closeModal} = useModalType();
    // const {resource} = modalProps;

    const {activeItem: activeOrg, ...orgQueryRest} = useActiveOrgData();

    const {activeItem: activeDomain, ...domainQueryRest} =
        useActiveDomainData();

    const canEdit = activeOrg ? canEditOrg(activeOrg) : false;

    const {isError, error, isLoading, isUninitialized} = mergeQueryStates(
        orgQueryRest,
        domainQueryRest
    );

    if (isError && error) {
        return (
            <Typography variant='h5' color="error">
                {extractQueryErrMessage(error)}
            </Typography>
        );
    }

    if (isLoading || isUninitialized) {
        return <Typography variant='h5'>Loading...</Typography>;
    }

    if (activeOrg) {
        return (
            <AppModal
                label={"Credits"}
                isForm={false}
                isOpen={modalType === 'orgCredits'}
                isDirty={false}
                onClose={() => closeModal()}
                onSubmit={() => {
                }}
                canEdit={canEdit}>
                <MemberPanel
                    canEdit={canEdit}
                    authorityLevel="admin"
                    contextLevel="username"
                    authorityLabel="Administered By"
                    orgId={(activeOrg as BaseOrg)._id}
                    domainId={activeDomain?._id}
                />
                <MemberPanel
                    canEdit={canEdit}
                    authorityLevel="governor"
                    contextLevel="username"
                    authorityLabel="Governed By"
                    orgId={(activeOrg as BaseOrg)._id}
                    domainId={activeDomain?._id}
                />
                <MemberPanel
                    canEdit={canEdit}
                    authorityLevel="engineer"
                    contextLevel="username"
                    authorityLabel="Engineered By"
                    orgId={(activeOrg as BaseOrg)._id}
                    domainId={activeDomain?._id}
                />
                <MemberPanel
                    canEdit={canEdit}
                    authorityLevel="publisher"
                    contextLevel="username"
                    authorityLabel="Published By"
                    orgId={(activeOrg as BaseOrg)._id}
                    domainId={activeDomain?._id}
                />
                <MemberPanel
                    canEdit={canEdit}
                    authorityLevel="maintainer"
                    contextLevel="username"
                    authorityLabel="Maintained By"
                    orgId={(activeOrg as BaseOrg)._id}
                    domainId={activeDomain?._id}
                />
                <MemberPanel
                    canEdit={canEdit}
                    authorityLevel="rightsHolder"
                    contextLevel="username"
                    authorityLabel="Rights Held By"
                    orgId={(activeOrg as BaseOrg)._id}
                    domainId={activeDomain?._id}
                />
                <MemberPanel
                    canEdit={canEdit}
                    authorityLevel="funder"
                    contextLevel="username"
                    authorityLabel="Funded By"
                    orgId={(activeOrg as BaseOrg)._id}
                    domainId={activeDomain?._id}
                />
                <MemberPanel
                    canEdit={canEdit}
                    authorityLevel="reporter"
                    contextLevel="username"
                    authorityLabel="Reported By"
                    orgId={(activeOrg as BaseOrg)._id}
                    domainId={activeDomain?._id}
                />
                <MemberPanel
                    canEdit={canEdit}
                    authorityLevel="member"
                    contextLevel="username"
                    authorityLabel="Members"
                    orgId={(activeOrg as BaseOrg)._id}
                    domainId={activeDomain?._id}
                />
            </AppModal>
        );
    } else {
        return null
    }
}

export default OrgCreditsModal;
