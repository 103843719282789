import {
	CreateCommentFormValues,
	CreateCommentPayload,
	GeneralCommentCreationId,
	GetCommentsParams,
	NewIndividualCommentCreationId,
	CommentCreationId,
	CommentSubject,
} from './types/commentTypes';

export const isNewIndividualIdentifier = (
	v: CommentCreationId
): v is NewIndividualCommentCreationId => {
	return v.kind === 'newIndividualComment';
};

interface CreateGeneralCommentFormParams {
	formValues: CreateCommentFormValues;
	objectIdentifier: GeneralCommentCreationId;
	objectType: Exclude<CommentSubject, 'Individual'>;
}

interface CreateNewCommentFormParams {
	formValues: CreateCommentFormValues;
	objectIdentifier: NewIndividualCommentCreationId;
	objectType: 'Individual';
}

type CreateCommentFormParams =
	| CreateNewCommentFormParams
	| CreateGeneralCommentFormParams;

export const createCommentFormToPayload = ({
	formValues,
	objectIdentifier,
	objectType,
}: CreateCommentFormParams): CreateCommentPayload => {
	return {
		comment: formValues.comment,
		objectIdentifier,
		objectType,
	};
};

export const getURLStumpFromObjectType = (objectType: CommentSubject) => {
	switch (objectType) {
		case 'Organization':
			return 'orgs';
		case 'User':
			return 'users';
		case 'DataSource':
			return 'sources';
		case 'DataCatalog':
			return 'catalogs';
		case 'Dataset':
			return 'datasets';
		case 'DataColumn':
			return 'columns';
		case 'DataRow':
			return 'rows';
		case 'DataValue':
			return 'values';
		case 'DataType':
			return 'types';
			case 'DataTest':
			return 'tests';
			case 'DataProfile':
			return 'profiles';
			case 'DataStatistic':
			return 'statistics';
			case 'DataError':
			return 'errors';
			case 'DataLoad':
			return 'loads';
		case 'Metric':
			return 'metrics';
		case 'Fact':
			return 'facts';
		case 'Insight':
			return 'insights';
		case 'Domain':
			return 'domains';
		case 'Entity':
			return 'entities';
		case 'Attribute':
			return 'attributes';
		case 'Individual':
			return 'individuals';
					case 'Comment':
			return 'comments';
	}
};

export const buildCommentsQueryURL = ({
	objectId,
	objectType,
	perPage = 10,
	page = 1
}: GetCommentsParams) =>
	`/${getURLStumpFromObjectType(objectType)}/${objectId}/comments?page=${page}&per_page=${perPage}`;

export const buildParametrizedCommentsQueryURL = (
	objectType: CommentSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/comments?page=:page&per_page=:perPage`;
