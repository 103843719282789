import React, {FunctionComponent, useState, MouseEventHandler} from "react";
import MenuButton from "../buttons/MenuButton";
import MenuPanel from "./MenuPanel";
import TextButton from "../buttons/TextButton";
import {BaseDomain} from "../../features/ontology/types/domainTypes";
import {DataCatalog, DataSet} from "../../features/browser/types/dataTypes";
// import ImportButton from "../buttons/ImportButton";
// import RecacheButton from "../buttons/RecacheButton";
// import DropButton from "../buttons/DropButton";
// import MoveButton from "../buttons/MoveButton";
// import DescribeButton from "../buttons/DescribeButton";
// import IconButton from './IconButton';
import { warnInDev } from '../utils/reactUtils';
import {
	useGenDomainFromCatalogMutation,
	useGenDomainFromDatasetMutation,
	useImportCatalogToDomainMutation,
	useImportDatasetToDomainMutation,
} from '../../features/api';
import { ContextParams } from '../../features/navigation/types/navigationContextTypes';
import useActiveOrgData from '../../features/ontology/hooks/useActiveOrgData';
import useActiveDomainData from '../../features/ontology/hooks/useActiveDomainData';
import { LiveDataCatalog, LiveDataSet } from '../../features/browser/types/dataTypes';
import { canEdit as canEditOrg } from 'features/authentication/helpers';
import { useParams } from 'react-router-dom';

// <ImportButton importable={dataset}/>
//  <RecacheButton cacheable={dataset}/>
//  <DropButton droppable={dataset}/>
//  <MoveButton movable={dataset}/>
//  <DescribeButton describable={dataset}/>


const datasetActions = [
    {
        actionTitle: 'Generate',
        kind: 'action',
        callback: 'generateOntology'
	},
    {
        actionTitle: 'Recache',
        kind: 'action',
        location: '/:org'
	},
    {
        actionTitle: 'Drop',
        kind: 'action',
        // modalType: 'showCredits',
        divider: true,
	},
    {
        actionTitle: 'Move',
        kind: 'action',
        // callback: 'follow',
	},
    {
        actionTitle: 'Describe',
        kind: 'modal',
        modalType: 'datasetSettings',
        // divider: true,
	},
    // {
    //     actionTitle: COPY_SHARE_URL,
    //     subMenu: 'share',
    //     kind: 'action',
    //     callback: 'copyLink',
    //     divider: false,
	// },
    // {
    //     actionTitle: MANAGE_MEMBERS,
    //     modalType: 'membership',
    //     divider: true,
    //     form: AddMemberButton,
    // },
    // {
    //     actionTitle: SHARE_RESOURCE,
    //     modalType: 'share',
    //     divider: true,
    //     form: AddMemberButton,
    // },
    // {
	// 	actionTitle: DELETE_DOMAIN,
    //     kind: 'modal',
	// 	modalType: 'deleteDomain',
	// 	// restrictionKind: 'specialization'
	// },
    // {
    //     actionTitle: DESTROY_ORG,
    //     modalType: 'destroy',
    //     form: DestroyButton,
    // },
]

export const CatalogMenu: FunctionComponent<{ catalog: DataCatalog; canEdit: boolean; }> = (
    {
        catalog,
        canEdit,
    }
) => {

    return <MenuButton>
        {canEdit ? <MenuPanel
			resource={catalog}
			canEdit={canEdit}
			actions={datasetActions}
            />
			: null}
    </MenuButton>

    // <MenuButton placement={'right-start'}>

        // {/*    <TextButton*/}
        // {/*        color='primary'*/}
        // {/*        fillColor='transparent'*/}
        // {/*        text={'Copy Object Link'}/>*/}
        // {/*<SubMenuButton label={'Share'}>*/}
        // {/*    <TextButton text={'Copy Object Link'}/>*/}
        // {/*</SubMenuButton>*/}
        // {/*<DestroyButton*/}
        // {/*    objectType={'Domain'}*/}
        // {/*    objectId={!!domain && domain._id}*/}
        // {/*    label={domain.label}*/}
        // {/*    openActionSubmenu={actionSubmenu}*/}
        // {/*    setOpenActionSubmenu={setActionSubmenu}*/}
        // {/*/>*/}
}

export default CatalogMenu;
