import React, {FunctionComponent} from "react";
import SubtleSelect from "./SubtleSelect";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {harvestErrors} from "./SubtleTextBox";

const OrgTypeSelect: FunctionComponent<ActionFieldProps> = (
    {
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
) => {
    // aria-errormessage={genErrorIdFromLabel('timezone')}
    // aria-invalid={formFieldHasErrors('timezone', formState)}
    return <SubtleSelect
        {...validatingRegister('orgType')}
        label="Type"
        inline={true}
        isDirty={Boolean(formState.dirtyFields.orgType)}
        onReset={() => resetField('orgType')}
        error={harvestErrors(
            'orgType',
            formState,
            mutationResults
        )}
    >{[
        {label: 'Enterprise', name: 'enterprise'},
        {label: 'Media', name: 'media'},
        {label: 'Government', name: 'government'},
        {label: 'Non-Profit', name: 'nonProfit'}
    ].map((type) => {
        return <option key={type.name} value={type.name}>
            {type.label}
        </option>
    })}
    </SubtleSelect>;
}

export default OrgTypeSelect;
