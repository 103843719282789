import { ListItemContent, List, ListItemControls } from '../../../../common/List';
import Typography from '../../../../common/text/Typography';
import { useGetLiveDataSetsQuery } from '../../../api';
import { renderDropdown } from '../../helpers';
import useSourceListItem from '../../hooks/useSourceListItem';
import { LiveDataCatalog } from '../../types/dataTypes';
import {
	SourceListItemProps,
	SourceSetter
} from '../../types/uiTypes';
import { SourceListItem } from '../styledComponents';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import clsx from 'clsx';
import { FunctionComponent, useEffect } from 'react';

interface DataCatalogListItemProps
	extends SourceListItemProps,
		LiveDataCatalog {
	onDisplay: LiveDataCatalog;
	setter: any;
	// cardSetter: CatalogCardSetter;
	sourceId: number | symbol;
}

const CatalogSummaryListItem: FunctionComponent<DataCatalogListItemProps> = ({
	onDisplay,
	setter,
	currentlySelected,
	// cardSetter,
	sourceId,
	...catalogProps
}) => {
	const { queryShouldRun, open, active, onClick, itemName } =
		useSourceListItem({
			onDisplay,
			kind: 'catalog',
			currentlySelected,
			setter,
		});

	const queryResults = useGetLiveDataSetsQuery(
		queryShouldRun && typeof sourceId === 'number'
			? { sourceId: sourceId, catalogName: onDisplay.name }
			: skipToken
	);

	// pass list back up to the card display component
	useEffect(() => {
		const { isSuccess, data } = queryResults;
		if (isSuccess && data && active) {
			setter(data);
		}
	}, [queryResults, setter, active]);

	return (
		<List>
			<SourceListItem
				button
				className={clsx(active && 'browser-item-is-open')}
				onClick={onClick}
			>
				<ListItemContent>
					<Typography variant={'h5'} color={'oldManGray'}>
						{itemName}
					</Typography>
				</ListItemContent>
			</SourceListItem>
			{/*{open &&*/}
			{/*	renderDropdown({*/}
			{/*		...queryResults,*/}
			{/*		// setter,*/}
			{/*		cardSetter,*/}
			{/*		// currentlySelected,*/}
			{/*		sourceId,*/}
			{/*	})}*/}
		</List>
	);
};

export default CatalogSummaryListItem;
