import {
	TimeInterval,
	eventGroupIntervals,
	EventLineDrawFn,
	Transformers,
	EventCardDrawFn,
	Line,
	Lines,
} from './types';
import { isoParse } from 'd3-time-format';
import { LeafArray, OldBadPointDrawFn } from 'common/viz/types';
import styled from 'styled-components';

const StyledPoint = styled.circle`
	fill: ${(p) => p.theme.palette.primary.main};
`;

const StyledLine = styled.path`
	fill: none;
	stroke: ${(p) => p.theme.palette.primary.main};
`;

const getDateMethod = (suffix: Exclude<TimeInterval, 'none'>) =>
	`getUTC${suffix}` as const;

export const getZeroDateArgs = (
	isoString: string,
	resolution: TimeInterval | 'none'
) => {
	const baseDate = isoParse(isoString) as Date;

	//  if no group is to be used, fully resolve the passed-in data
	const sliceTo =
		resolution === 'none'
			? eventGroupIntervals.length
			: eventGroupIntervals.findIndex((el) => el === resolution) + 1;

	return eventGroupIntervals
		.slice(0, sliceTo)
		.map((interval) => baseDate[getDateMethod(interval)]());
};

export const genZeroDate = (args: any[]): number => {
	//  TODO: clean up these types
	//  set UTC Date up to the finest resolution provided in the arguments
	//  @ts-ignore
	return Date.UTC(...args);
};

export const getOldest = (a: Date, b: Date) => {
	if (a < b) {
		return a;
	}

	if (a > b) {
		return b;
	}

	return a;
};

export const getNewest = (a: Date, b: Date) => {
	if (a > b) {
		return a;
	}

	if (a < b) {
		return b;
	}

	return a;
};

const getZeroedLineObject = (): Pick<
	Line,
	'line' | 'xMin' | 'xMax' | 'yMax'
> => ({
	line: [],
	//    use smallest/largest date that native JS Date object can construct
	xMin: new Date(8640000000000000),
	xMax: new Date(-8640000000000000),
	yMax: Number.NEGATIVE_INFINITY,
});

// Because line chart needs to draw multiple lines, this aggregation function accepts an array of arrays.
export const groupDataToEventLines = (
	rawEventData: LeafArray<string, number>,
	interval: TimeInterval | 'none',
	// use a transformer to map y-values BEFORE aggregation, or to pass a custom aggregation function
	transformers?: Transformers
): Lines => {
	if (interval === 'none') {
		//  don't perform any grouping
		return rawEventData.map((dataArray, yAttrIdx) => {
			const lineBase = dataArray.reduce((line, datum) => {
				const { yAttr, x, y: rawY, xAttr, originalIdx } = datum;

				const mapY = transformers ? transformers[yAttr]?.map : null;

				const xDate = isoParse(x) as Date;

				const y = mapY ? mapY(rawY) : rawY;

				line.xMin = getOldest(line.xMin, xDate);
				line.xMax = getNewest(line.xMax, xDate);
				line.yMax = Math.max(line.yMax, y);

				line.line.push({
					pointId: `${xAttr}-${yAttr}-${originalIdx}-point`,
					lineIdx: yAttrIdx,
					x: xDate,
					y,
					originalIndices: [originalIdx],
					yAttr,
					xAttr,
				});

				return line;
			}, getZeroedLineObject());

			const { xAttr, yAttr } = lineBase.line[0] ?? {
				xAttr: 'empty',
				yAttr: 'empty',
			};

			return {
				...lineBase,
				xAttr,
				yAttr,
				lineIdx: yAttrIdx,
				lineId: `${xAttr}-${yAttr}-line`,
			};
		});
	}

	const aggregatedDateMaps = rawEventData.map((dataArray) =>
		dataArray.reduce((aggMap, datum) => {
			const { yAttr, xAttr, x, y: rawY, originalIdx } = datum;

			const roundedTimeStamp = genZeroDate(getZeroDateArgs(x, interval));

			// apply mapping to individual y value, if one has been provided
			const mapY = transformers ? transformers[yAttr]?.map : null;

			const y = mapY ? mapY(rawY) : rawY;

			const existing = aggMap.get(roundedTimeStamp);

			if (!existing) {
				aggMap.set(roundedTimeStamp, {
					originalIndices: [originalIdx],
					ys: [y],
					yAttr: yAttr,
					xAttr: xAttr,
				});
				return aggMap;
			}

			existing.originalIndices.push(originalIdx);

			existing.ys.push(y);

			return aggMap;
		}, new Map<number, { originalIndices: number[]; ys: any[]; yAttr: string; xAttr: string }>())
	);

	return aggregatedDateMaps.map((aggMap, lineIdx) => {
		const lineBase = Array.from(aggMap.entries()).reduce(
			(line, [dateInMS, { originalIndices, ys, yAttr, xAttr }]) => {
				const aggregator = transformers
					? transformers[yAttr]?.aggregate
					: null;

				// use provided aggregator if available, otherwise just count the number of y values
				const yVal = aggregator ? aggregator(ys) : ys.length;

				const xDate = new Date(dateInMS);

				line.xMax = getNewest(line.xMax, xDate);
				line.xMin = getOldest(line.xMin, xDate);
				line.yMax = Math.max(yVal, line.yMax);

				line.line.push({
					pointId: `${xAttr}-${yAttr}-${originalIndices[0]}`,
					lineIdx,
					x: xDate,
					y: yVal,
					originalIndices,
					xAttr,
					yAttr,
				});

				return line;
			},
			getZeroedLineObject()
		);

		const { xAttr, yAttr } = lineBase.line[0] ?? {
			xAttr: 'empty',
			yAttr: 'empty',
		};

		return {
			...lineBase,
			xAttr,
			yAttr,
			lineIdx,
			lineId: `${xAttr}-${yAttr}-line`,
		};
	});
};

// export const groupLine = (
// 	line: Line,
// 	interval: TimeInterval,
// 	aggregator: (as: number[]) => number
// ): Line =>
// 	Array.from(
// 		line
// 			.reduce((acc, nextPoint) => {
// 				const { x, y } = nextPoint;

// 				const key = genZeroDate(
// 					getZeroDateArgs(x, interval)
// 				).toISOString();

// 				const existing = acc.get(key);

// 				if (existing) {
// 					existing.push(y);
// 					return acc;
// 				}

// 				acc.set(key, [y]);
// 				return acc;
// 			}, new Map<string, number[]>())
// 			.entries()
// 	).map(([key, vals]) => ({ x: key, y: aggregator(vals) }));

// Because line chart needs to draw multiple discrete lines, it accepts slightly
// different fact structure than scatterplot.  Notice return type is an array of arrays--
// each inner array contains the points that belong to a single discrete line.
// export const createEventLines = <
// 	T extends OldBadPointEnhancer<string, number, any> | undefined
// >(
// 	keys: string[],
// 	facts: Facts<any>,
// 	preparedData: DataPreparer,
// 	enhancer?: T
// ): T extends OldBadPointEnhancer<string, number, infer R>
// 	? R[][]
// 	: Point<string, number>[][] => {
// 	const [xAttrName, ...yAttrNames] = keys;

// 	const result: any = [];

// 	// create an inner array for each y attr, since each y-attribute to be displayed
// 	// belongs to a different line.
// 	for (let i = 0; i < yAttrNames.length; i++) {
// 		result.push([]);
// 	}

// 	// now, distribute pairs of [xvalue, yvalue] to the inner result arrays.
// 	// Call enhancement function (if provided) on each point before adding it.
// 	facts.forEach(({ values, originalIdx }) => {
// 		const [xVal, ...yVals] = values;

// 		yVals.forEach((yVal, i) => {
// 			const point: Point<string, number> = { x: xVal, y: yVal };
// 			result[i].push(
// 				enhancer
// 					? enhancer(
// 							point,
// 							preparedData,
// 							xAttrName,
// 							yAttrNames,
// 							yAttrNames[i],
// 							originalIdx
// 					  )
// 					: point
// 			);
// 		});
// 	});

// 	return result;
// };

// Gather basic metadata and convert strings to dates in a single pass.
// NB: curry this to easily swap out d3 date parser if needed
// const _processEventPoints =
// 	(dateParser: (isoString: string) => Date) => (lines: Lines) => {
// 		let xMin: Date;
// 		let xMax: Date;
// 		let yMax: number;

// 		const output = lines.reduce((acc, nextArr) => {
// 			const nested: ProcessedLine = [];

// 			nextArr.forEach((pt) => {
// 				const date = dateParser(pt.x);

// 				if (xMin === undefined || date < xMin) {
// 					xMin = date;
// 				}

// 				if (xMax === undefined || date > xMax) {
// 					xMax = date;
// 				}

// 				if (yMax === undefined || pt.y > yMax) {
// 					yMax = pt.y;
// 				}

// 				// IMPORTANT: need to pass any extra props through to the output array--these props
// 				// need to make their way to the drawing function!!
// 				nested.push({ ...pt, x: date, y: pt.y });
// 			});

// 			acc.push(nested);

// 			return acc;
// 		}, [] as ProcessedLines);

// 		//   TODO: figure out how to deal with compiler here
// 		//   @ts-ignore
// 		return { xMax, xMin, yMax, processedLines: output };
// 	};

// export const processEventPoints = _processEventPoints(
// 	isoParse as (s: string) => Date
// );

export const drawDefaultPoint: OldBadPointDrawFn<Date, number> = ({
	drawX,
	drawY,
	pointId,
}) => (
	<StyledPoint
		cx={drawX}
		cy={drawY}
		r={5}
		opacity={1}
		//   TODO: using nanoid here is not bueno at all.
		key={pointId}
		data-testid="default-point"
	/>
);

export const drawDefaultLine: EventLineDrawFn = ({ path, lineId }) => (
	<StyledLine
		d={path}
		opacity={0.5}
		key={lineId}
		data-testid="default-line"
	/>
);

const shouldBreakLeft = (width: number, position: number) =>
	//  if cursor is more than 2/3 width of drawing surface to the left,
	// break hover tooltip left to keep all of it within drawing area
	position >= width * 0.66;

const shouldBreakUp = (height: number, position: number) =>
	//  if cursor is more than 2/3 width of drawing surface to the left,
	// break hover tooltip left to keep all of it within drawing area
	position >= height * 0.85;

export const drawDefaultCard: EventCardDrawFn = ({
	x,
	y,
	xValue,
	yValue,
	visible,
	drawHeight,
	drawWidth,
}) => {
	if (visible) {
		const offsetX = shouldBreakLeft(drawWidth, x) ? -60 : 40;
		const finalX = x + offsetX;
		const offsetY = shouldBreakUp(drawHeight, y) ? -40 : 40;
		const finalY = y + offsetY;

		return (
			<g transform={`translate(${finalX}, ${finalY})`}>
				<text fill="white">X: {xValue}</text>
				<text y={16} fill="white">
					Y: {yValue}
				</text>
			</g>
		);
	}

	return null;
};
