import theme from "../theme/theme";
import Typography from "../text/Typography";
import Button from "./Button";
import React from "react";

const LogInButton = () => {

    return <Button
        style={{
            backgroundColor: theme.palette.darkBaby,
            borderColor: theme.palette.drapeGray,
            height: '50px',
            width: '100%',
            borderRadius: theme.spacing(1),
        }}
        type="submit"
        data-testid="submit-credentials"
    >
        <Typography color={'primary'} variant={'button'}>
            Log In
        </Typography>
    </Button>
};

export default LogInButton;
