import React, {FunctionComponent} from 'react';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import StyledCopyright from "../../common/StyledCopyright";
import LogInLinkButton from "../../common/buttons/LogInLinkButton";
import Typography from "../../common/text/Typography";

const StyledLanding = styled.div`
	height: 100vh; 
	width: 100vw;
	
	@media screen and (max-width: ${(p) => p.theme.breakpoints.values.sm}px) {
		overflow-y: auto;	
		overflow-x: hidden;	
	}
`
const StyledMain = styled.div`
	display: flex;
	flex-direction: column;
	place-content: center;
	place-items: center;
	min-height: 600px;
	height: 100vh;
	width: 100vw;
	gap: 6rem;
	overflow: hidden;
	padding: 0;	
	margin: 0;	
	background-color: ${(p) => p.theme.palette.darkBaby};
`;

const StyledLogo = styled.img`
	margin: 0;
	${(p) => p.theme.mixins.unselectable}
`;

const StyledCitizen = styled.img`
	margin: 0;
	padding: 0;
	${(p) => p.theme.mixins.unselectable}
`;

const StyledSigninNav = styled.nav<{ useTranslucency: boolean; }>`
	background: transparent;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-between;
	flex: 1;
	justify-content: flex-end;
	align-content: flex-end;
	z-index: ${(p) => p.theme.zIndex.navPanel};
	background-color: ${(p) => p.theme.palette.darkBaby};
`;

const SigninButtons: FunctionComponent = () => {
    const {pathname} = useLocation();
    const atLanding = !pathname.match(/login/i);
    if (atLanding) {
        return <LogInLinkButton />
    }
    return null;
};


const TeaserPage: FunctionComponent = () => {
    return (
        <StyledLanding>
            <StyledSigninNav useTranslucency={false}>
                <SigninButtons/>
            </StyledSigninNav>
            <StyledMain>
                <StyledLogo
                    width={'400px'}
                    height={'47px'}
                    alt="Futuremodel Inc."
                    src="https://storage.googleapis.com/davis-associates.appspot.com/futuremodel-teaser.svg"
                />
                <StyledCitizen
                    width={'275px'}
                    height={'315px'}
                    src="https://storage.googleapis.com/davis-associates.appspot.com/fm-hero-figure.svg"
                    role="presentation"
                />
                <StyledCopyright/>
            </StyledMain>
        </StyledLanding>
    );
};

export default TeaserPage;
