import React, {FunctionComponent, ReactNode, useState} from "react";
import Username from "common/text/Username";
import {StyledMemberDataListItem} from "../../features/governance/components/styledComponents";
import ProfileAvatar
    from "../../features/navigation/components/Navbar/ProfileAvatar";
import {List, ListItemContent, ListItemControls} from "./index";
import Typography from "../text/Typography";
import {
    BaseUser
} from "../../features/authentication/types/userTypes";
import Error from "../text/Error";
import FullName from "../text/FullName";
import LastActive from "../text/LastActive";
import MenuButton from "../buttons/MenuButton";
import UserItem from "./UserItem";
import FlexContainer from "../FlexContainer";
import UserMenu from "../menus/SelfMenu";
import SelfMenu from "../menus/SelfMenu";
import RoleMenu from "../menus/RoleMenu";


interface ActionListProps {
    _id: number,
    _object: string,
    children?: ReactNode,
    // isOpen: boolean
}

type RoleTypes =
    | 'member'
    | 'steward'
    | 'governor';



const MemberItem: FunctionComponent<{role?: RoleTypes; user: BaseUser, canEdit: boolean, onClick: any, handleError?: any, children?: any}> = ({role, user, canEdit, onClick, handleError, children}) => {

    return <UserItem user={user} canEdit={canEdit} onClick={onClick}>
        <FlexContainer justifyContent='space-between'>
            <div>
                <FullName user={user} styles={{ padding: '1px' }}/>
                <LastActive user={user} position='flex-start' />
                <Error resource={user} handleError={handleError} />
            </div>
            {canEdit ?
                <>
                <RoleMenu
                    role={role ? role : 'member'}
                    user={user}
                    canEdit={canEdit}
                    openModal={() => {}}
                />
                </>
                : <RoleMenu
                    role={role ? role : 'member'}
                    user={user}
                    canEdit={canEdit}
                    openModal={() => {}}
                />}
            </FlexContainer>
    </UserItem>
}

export default MemberItem;
