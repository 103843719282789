import environment from 'common/environment';

export interface LinkedInLoginMessage {
	state: string;
	source: typeof OAUTH_ACCESS_CODE_REQUEST_MESSAGE;
	payload: AuthCodeStatus;
}

export interface CreateLinkedInShareParams {
	// form fields: {
	// 	imageBlob: Blob;
	//  link: string;
	// 	code?: string;
	//  comment?: string;
	// description?: string
	// };
	body: FormData;
}

export interface CreateLinkedInShareResponse {
	postId: string;
	linkedInTokenExpiration: number;
}

export interface CreateShareResponse {}

export interface CreateShareParams {
	userId: number;
	body: {
		imageBlob: Blob;
		link: string;
		code?: string;
		comment?: string;
		description?: string
	};
}

export type OAuthProvider = 'linkedin';

export interface ProviderURIData {
	endpoint: string;
	redirectURI: string;
}

export interface AuthCodeStatus {
	status: 'uninitialized' | 'loading' | 'success' | 'error';
	code: string | null;
	error: string | null;
}

export interface UploadStatus {
	status: 'uninitialized' | 'loading' | 'success' | 'error';
	error: string | null;
	provider: OAuthProvider;
	postId: string | null;
	enqueuedUpload: string | null;
}

export const PKCE_MISMATCH =
	'Auth response state did not match original request state';

export const OAUTH_ACCESS_CODE_REQUEST_MESSAGE = 'oauth_access_code_request';

export interface RegisterLinkedinUploadResponse {
	value: {
		uploadMechanism: {
			'com.linkedin.digitalmedia.uploading.MediaUploadHttpRequest': {
				headers: {};
				uploadUrl: string;
			};
		};

		mediaArtifact: string;
		asset: string;
	};
}

export const oAuthTokenServerURLs: Record<OAuthProvider, string> = {
	linkedin: `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${environment.LINKED_IN_ID}&scope=w_member_social%20r_liteprofile&redirect_uri=${environment.LINKED_IN_REDIRECT_URI}`,
};
