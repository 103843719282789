import React, {FunctionComponent} from "react";
import Button from "./Button";
import Typography from "../text/Typography";

const ReplyButton: FunctionComponent<{ onClick: () => void}> = ({onClick}) => {

    return <Button onClick={onClick} style={{padding: 0, margin: 0}}>
        <Typography variant='button'>
            Reply
        </Typography>
    </Button>
};

export default ReplyButton;
