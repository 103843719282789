import Typography from "./Typography";
import {FunctionComponent} from "react";
import {composeName} from "../../features/governance/helpers";

const FullName: FunctionComponent<{user: any, styles?: any, onHold?: boolean, onHover?: (e?: any) => void; onClick?: () => void; onExit?: (onHold: boolean) => void;}> = ({user, styles, onHover, onClick, onHold, onExit}) => {
    return <Typography
        onClick={onClick}
        onMouseEnter={onHover}
        onMouseLeave={() => onHold && onExit && onExit(onHold)}
        variant='h6'
        id={`member-${user._id}`}
        color={'oldManGray'}
        style={{
            cursor: 'pointer',
            ...styles
        }}>
        {composeName(user)}
    </Typography>;
}

export default FullName;
