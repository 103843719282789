import {composeName} from '../helpers';
import useMemberQueryHook from '../hooks/useMemberQueryHook';
import {
    OrgAuthorityLevel,
    DomainAuthorityLevel,
} from '../types/GovernanceTypes';
import OrgPromoteButton from '../../../common/buttons/OrgPromoteButton';
import DomainPromoteButton from '../../../common/buttons/DomainPromoteButton';
import RevokeRoleButton from '../../../common/buttons/RevokeRoleButton';
import UserPopover from '../../../common/popovers/UserPopover';
import {
    StyledMemberMgmtHeading,
    StyledHeadingContainer,
} from './styledComponents';
import {List,} from 'common/List';
import {PopoverBase} from 'common/popovers/Popover';
import Spinner from 'common/loading/Spinner';
import Typography from 'common/text/Typography';
import {useGetUsersQuery} from 'features/api';
import {parseQueryError} from 'features/api/helpers';
import {BaseUser} from 'features/authentication/types/userTypes';
import {FunctionComponent, useCallback, useState} from 'react';
import MemberItem from "common/List/MemberItem";
import ClickAwayListener from "react-click-away-listener";

type AdminPanelProps = {
    domainId?: number;
    orgId: number;
    canEdit: boolean;
    authorityLabel?: string;
} & (
    | { contextLevel: 'username'; authorityLevel: OrgAuthorityLevel }
    | { contextLevel: 'domain'; authorityLevel: DomainAuthorityLevel }
    );

const MemberPanel: FunctionComponent<AdminPanelProps> = (
    {
        domainId,
        orgId,
        contextLevel,
        authorityLevel,
        authorityLabel,
        canEdit,
    }
) => {
    const [popoverUser, setPopoverUser] = useState<BaseUser | null>(null);
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    const objectId = contextLevel === 'username' ? orgId : domainId;

    const {
        data: memberData,
        isLoading,
        isError,
        isUninitialized,
        error,
    } = useMemberQueryHook(contextLevel, authorityLevel, objectId);

    const [memberErr, setMemberErr] = useState<Record<string, string>>({});

    const updateMemberErr = useCallback(
        (msg: string, id: number) => setMemberErr((p) => ({...p, [id]: msg})),
        []
    );

    const renderContent = () => {
        if (isError) {
            return (
                <Typography paragraph color="error">
                    {parseQueryError(error).message}
                </Typography>
            );
        }

        if (isLoading || isUninitialized) {
            return <Spinner/>;
        }

        return (
            <div>
                <PopoverBase
                    anchorEl={anchor}
                    handleClickAway={() => setPopoverUser(null)}
                    open={!!popoverUser}>
                    {popoverUser && <UserPopover user={popoverUser}/>}
                </PopoverBase>
                <List aria-labelledby={`${contextLevel}-${authorityLevel}`}
                      style={{padding: '4px 8px'}}>
                    {(memberData as BaseUser[]).map((member) => {
                        return <MemberItem
                            key={member._id}
                            user={member}
                            canEdit={canEdit}
                            handleError={memberErr}
                            onClick={(e: any) => {
                                setAnchor(e.currentTarget);
                                setPopoverUser(member);
                            }}>
                            <RevokeRoleButton
                                setMemberErr={updateMemberErr}
                                role={authorityLevel}
                                objectId={objectId as number}
                                objectType={contextLevel}
                                userId={member._id}/>
                        </MemberItem>
                    })}
                </List>
            </div>
        );
    };

    //  Org "member" is the lowest level of privilege--it makes no sense to 'promote' someone
    // to that level.  So don't show the 'promote' button.
    const renderPromoteButton = () => {
        if (contextLevel === 'username') {
            // if (authorityLevel === 'member') {
            // 	return null;
            // }

            return (
                <OrgPromoteButton
                    orgId={orgId}
                    authorityLevel={authorityLevel}
                />
            );
        }

        if (!domainId) {
            return null;
        }

        return (
            <DomainPromoteButton
                domainId={domainId}
                orgId={orgId}
                authorityLevel={authorityLevel}/>
        );
    };

    return (
        <>
            <StyledHeadingContainer
                justifyContent="space-between"
                alignItems="center">
                <StyledMemberMgmtHeading
                    component="h5"
                    id={`${contextLevel}-${authorityLevel}`}>
                    {authorityLabel ? authorityLabel : authorityLevel}
                </StyledMemberMgmtHeading>
                {canEdit && renderPromoteButton()}
            </StyledHeadingContainer>
            {!!memberData && memberData.length > 0 ? renderContent() : null}
        </>
    );

};


export const UsersPanel: FunctionComponent<AdminPanelProps> = (
    {
        domainId,
        orgId,
        contextLevel,
        authorityLevel,
        authorityLabel,
        canEdit,
    }
) => {
    const [popoverUser, setPopoverUser] = useState<BaseUser | null>(null);
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    const objectId = contextLevel === 'username' ? orgId : domainId;

    const {
        data: memberData,
        isLoading,
        isError,
        isUninitialized,
        error,
    } = useGetUsersQuery();

    const [memberErr, setMemberErr] = useState<Record<string, string>>({});

    const updateMemberErr = useCallback(
        (msg: string, id: number) => setMemberErr((p) => ({...p, [id]: msg})),
        []
    );

    const renderContent = () => {
        if (isError) {
            return (
                <Typography paragraph color="error">
                    {parseQueryError(error).message}
                </Typography>
            );
        }

        if (isLoading || isUninitialized) {
            return <Spinner/>;
        }

        return (
            <div>
                <PopoverBase
                    anchorEl={anchor}
                    handleClickAway={() => setPopoverUser(null)}
                    open={!!popoverUser}>
                    {popoverUser && <UserPopover user={popoverUser}/>}
                </PopoverBase>
                <List aria-labelledby={`${contextLevel}-${authorityLevel}`}
                      style={{padding: '4px 8px'}}>
                    {(memberData as BaseUser[]).map((member) => {
                        return <MemberItem
                            key={member._id}
                            user={member}
                            canEdit={canEdit}
                            handleError={memberErr}
                            onClick={(e: any) => {
                                setAnchor(e.currentTarget);
                                setPopoverUser(member);
                            }}>
                            <RevokeRoleButton
                                setMemberErr={updateMemberErr}
                                role={authorityLevel}
                                objectId={objectId as number}
                                objectType={contextLevel}
                                userId={member._id}/>
                        </MemberItem>
                    })}
                </List>
            </div>
        );
    };

    //  Org "member" is the lowest level of privilege--it makes no sense to 'promote' someone
    // to that level.  So don't show the 'promote' button.
    const renderPromoteButton = () => {
        if (contextLevel === 'username') {
            // if (authorityLevel === 'member') {
            // 	return null;
            // }

            return (
                <OrgPromoteButton
                    orgId={orgId}
                    authorityLevel={authorityLevel}
                />
            );
        }

        if (!domainId) {
            return null;
        }

        return (
            <DomainPromoteButton
                domainId={domainId}
                orgId={orgId}
                authorityLevel={authorityLevel}/>
        );
    };

    return (
        <>
            <StyledHeadingContainer
                justifyContent="space-between"
                alignItems="center">
                <StyledMemberMgmtHeading
                    component="h5"
                    id={`${contextLevel}-${authorityLevel}`}>
                    {authorityLabel ? authorityLabel : authorityLevel}
                </StyledMemberMgmtHeading>
                {canEdit && renderPromoteButton()}
            </StyledHeadingContainer>
            {!!memberData && memberData.length > 0 ? renderContent() : null}
        </>
    );

};

export default MemberPanel;
