import { useGetLandingQuery } from '../../api';
import {
	ContextParams,
	DataContextParams
} from '../../navigation/types/navigationContextTypes';
import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';
import { BaseOrg } from '../types/orgTypes';
import { BaseUser } from "../../authentication/types/userTypes";
import NotFound from "../../routing/components/NotFound";
import useActiveOrgData from "./useActiveOrgData";
import {BaseAttribute} from "../types/attributeTypes";

// TODO: figure out why this doesn't type correctly in selector fn below
type QueryResponse = Omit<ReturnType<typeof useGetLandingQuery>, 'refetch'>;

type ActiveResourceName = string | undefined;

const selectActiveResource = createSelector(
	(res: QueryResponse) => res.data,
	(res: QueryResponse, activeResourceName: ActiveResourceName) =>
		activeResourceName,
	(data: QueryResponse['data'], activeResourceName: ActiveResourceName) =>
		data && data.response && data?.response.username === activeResourceName
			? data?.response : {_object: '404'}
		);
// data?.find((acct: BaseOrg | BaseUser) => acct.username === activeResourceName) ??
// null
/**
 * @returns RTK Query files related to fetching all available orgs for a user, as well
 * as an activeOrg property that corresponds to the files for the user specified
 * in the application URL.
 */
const useActiveLandingData = () => {
	const params = useParams() as ContextParams | DataContextParams;

	// won't run as long as skipToken is its argument
	const fetchData = useGetLandingQuery(
		params.username ? { username: params.username } : skipToken,
		{
			selectFromResult: (res) => ({
				...res,
				// important to use a memoizing selector here: selectFromResult
				// uses shallow-equal comparison to determine whether or not a re-render
				// should occur. The only way to get a stable reference for the value of
				// activeItem is to memoize based on inputs.  RTK query takes care of
				// memoizing the value of res.data for us.
				activeItem: selectActiveResource(
					res,
					params.username
				) as BaseOrg | BaseUser,
				// activeItem:
				// 	? res.data.response
				// 	: {_object: '404'},
				// selectActiveResource(
				// 	res
				// )
			}),
		}
		// {
		// 	selectFromResult: (res) => ({
		// 		...res,
		//
		// 			res,
		// 			params.attribute
		// 		) as null | BaseAttribute,
		// 	}),
		// }
	);

	return fetchData;
};

export default useActiveLandingData;
