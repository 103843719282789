import React, {FunctionComponent} from "react";
import Typography from "../text/Typography";
import {ListItem} from "../List";
import {setModalType} from "../../features/HUD/state/HUDSlice";
import {useAppDispatch} from "../../app/hooks";

const FollowersButton: FunctionComponent<{resource: any}> = ({resource}) => {

	const appDispatch = useAppDispatch();

	if (resource && resource._object === 'Individual') {
		return null
	} else {
		const followerCount = resource && resource._object === 'User'
		? resource.stats.connections.followers ? resource.stats.connections.followers : 0
		: resource.stats.followers ? resource.stats.followers : 0;

		return <ListItem button onClick={() => appDispatch(setModalType({
				type: 'followers', props: {objectType: resource._object, objectId: resource._id}}
			))} style={{gap: '.25rem', margin: '0 0 2rem', padding: 0}}>
			<Typography color={'oldManGray'} variant={'h6'}>
				{followerCount} {followerCount === 1 ? 'Follower' : 'Followers'}
			</Typography>
		</ListItem>;
	}
}

export default FollowersButton;
