import {
	GetActivitiesParams,
	ActivitySubject,
	GetReactionsParams,
	ReactionSubject,
	GetViewersParams,
	ViewerSubject,
	GetTasksParams,
	TaskSubject
} from './types/activityTypes';


export const getURLStumpFromObjectType = (objectType: ActivitySubject) => {
	switch (objectType) {
		case 'Organization':
			return 'orgs';
		case 'User':
			return 'users';
		case 'DataSource':
			return 'sources';
		case 'DataCatalog':
			return 'catalogs';
		case 'Dataset':
			return 'datasets';
		case 'DataColumn':
			return 'columns';
		case 'DataRow':
			return 'rows';
		case 'DataValue':
			return 'values';
		case 'DataType':
			return 'types';
		case 'Metric':
			return 'metrics';
		case 'Fact':
			return 'facts';
		case 'Insight':
			return 'insights';
		case 'Domain':
			return 'domains';
		case 'Entity':
			return 'entities';
		case 'Attribute':
			return 'attributes';
		case 'Individual':
			return 'individuals';
					case 'Comment':
			return 'comments';
	}
};

export const buildActivitiesQueryURL = ({
	objectId,
	objectType,
	perPage = 10,
	page = 1
}: GetActivitiesParams) =>
	`/${getURLStumpFromObjectType(objectType)}/${objectId}/activity?page=${page}&per_page=${perPage}`;

export const buildParametrizedActivitiesQueryURL = (
	objectType: ActivitySubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/activity?page=:page&per_page=:perPage`;

export const buildReactionsQueryURL = ({
	objectId,
	objectType,
	perPage = 10,
	page = 1
}: GetReactionsParams) =>
	`/${getURLStumpFromObjectType(objectType)}/${objectId}/reactions?page=${page}&per_page=${perPage}`;

export const buildParametrizedReactionsQueryURL = (
	objectType: ReactionSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/reactions?page=:page&per_page=:perPage`;

export const buildViewersQueryURL = ({
	objectId,
	objectType,
	perPage = 10,
	page = 1
}: GetViewersParams) =>
	`/${getURLStumpFromObjectType(objectType)}/${objectId}/views?page=${page}&per_page=${perPage}`;

export const buildParametrizedViewersQueryURL = (
	objectType: ViewerSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/views?page=:page&per_page=:perPage`;

export const buildTasksQueryURL = ({
	objectId,
	objectType,
	perPage = 10,
	page = 1
}: GetTasksParams) =>
	`/${getURLStumpFromObjectType(objectType)}/${objectId}/tasks?page=${page}&per_page=${perPage}`;

export const buildParametrizedTasksQueryURL = (
	objectType: TaskSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/tasks?page=:page&per_page=:perPage`;


