import {StyledTextbox, StyledInputContainer, StyledLabel} from './styledComponents';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import IconButton from 'common/buttons/IconButton';
import Typography from 'common/text/Typography';
import { forkRef } from 'common/utils/reactUtils';
import React, { CSSProperties, forwardRef, MouseEventHandler, useRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import FlexContainer from "../../FlexContainer";

interface TextboxOverrides {
	root?: CSSProperties;
	label?: CSSProperties;
	button?: CSSProperties;
	container?: CSSProperties;
}
export interface SubtleTextboxProps extends UseFormRegisterReturn {
	isDirty: boolean;
	label: string;
	id?: string;
	inline?: boolean;
	showLabel?: boolean;
	onReset?: () => void;
	defaultValue?: any;
	error?: string;
	justifyContent?: string;
	alignItems?: string;
	overrides?: TextboxOverrides;
}

const SubtleTextbox = forwardRef<HTMLInputElement, SubtleTextboxProps>(
	(
		{
			overrides,
			isDirty,
			onReset,
			label,
			inline,
			id,
			defaultValue,
			justifyContent,
			alignItems,
			showLabel = false,
			error,
			...rest
		},
		forwardedRef
	) => {
		const resetterRef = useRef<HTMLInputElement>();

		const setRefs = forkRef(forwardedRef, resetterRef);

		const onContainerClick: MouseEventHandler<HTMLDivElement> = () => {
			if (resetterRef.current) {
				resetterRef.current.focus();
			}
		};

		return (
			<div>
				<StyledInputContainer
					justifyContent={justifyContent ? justifyContent : "space-between"}
					alignItems={alignItems ? alignItems : "flex-start"}
					flexDirection={!inline ? 'column' : 'row'}
					onClick={onContainerClick}
					gap={'1rem'}
					data-testid="subtle-input-container"
					style={{ ...overrides?.container, position: 'relative' }}
				>
					{showLabel && (
						<StyledLabel
							htmlFor={`${label}-subtle-input`}
							style={{...overrides?.label}}
						>
							{label}
						</StyledLabel>
					)}
					<FlexContainer
						style={{width: '100%'}}
						alignItems='flex-start'
						justifyContent='flex-start'
					>
						<StyledTextbox
							aria-label={label}
							spellCheck={false}
							style={overrides?.root}
							defaultValue={defaultValue}
							id={id ? id : `${label}-subtle-input`}
							className={clsx(isDirty && 'is-dirty')}
							aria-invalid={Boolean(error)}
							aria-errormessage={`${label}-error-message`}
							// @ts-ignore
							ref={setRefs}
							{...rest}
						/>
						{isDirty && (
							<IconButton
								style={{
									...overrides?.button,
								}}
								fillColor="error"
								size="xs"
								iconSize='sm'
								icon={faTimes}
								aria-label={`reset ${label} field`}
								onClick={(e) => {
									e.preventDefault();
									onReset && onReset();
								}}
							/>
						)}
					</FlexContainer>
				</StyledInputContainer>
				<div>
					{error && (
						<Typography id={`${label}-error-message`} color="error">
							{error}
						</Typography>
					)}
				</div>
			</div>
		);
	}
);

export default SubtleTextbox;
