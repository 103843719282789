import { ActionDescriptor } from '../common/commonTypes';
import AddAttributeForm from './AddAttributeForm';
import {faAdd} from "@fortawesome/free-solid-svg-icons";

const addAttribute: ActionDescriptor = {
	form: AddAttributeForm,
	actionTitle: 'Add',
	requiresEdit: true,
	kind: 'modal',
	divider: false,
	actionIcon: faAdd,
	modalType: 'addAttribute'
};

export default addAttribute;
