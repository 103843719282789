import React, {FunctionComponent} from "react";
import Typography from "../text/Typography";
import {ListItem} from "../List";
import {setModalType} from "../../features/HUD/state/HUDSlice";
import {useAppDispatch} from "../../app/hooks";

const FollowingButton: FunctionComponent<{resource: any;}> = ({resource}) => {

	const appDispatch = useAppDispatch();

	return <ListItem button onClick={() => appDispatch(setModalType({
			type: 'following', props: {objectType: resource._object, objectId: resource._id}}
		))} style={{gap: '.25rem', margin: 0, padding: 0}}>
		{resource && resource.stats.connections.following
			? <Typography color={'oldManGray'} variant={'h6'}>
				{resource.stats.connections.following}
			</Typography>
			: <Typography color={'oldManGray'} variant={'h6'}>0</Typography>
		}
		<Typography color={'oldManGray'} variant={'h6'}>
			Following
		</Typography>
	</ListItem>;
}

export default FollowingButton;
