import FlexContainer from '../../FlexContainer';
import Typography from '../../text/Typography';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledNonInputContainer = styled(FlexContainer)`
	padding: ${(p) => p.theme.spacing(0.25)};
	border: 1px solid transparent;
	overflow: auto;
	&:hover {
		border: 1px solid ${(p) => p.theme.palette.divider};
	}
`;

const StyledInput = styled.input`
	background-color: transparent;
	font-family: ${(p) => p.theme.typography.body1.fontFamily};
	font-size: ${(p) => p.theme.typography.body1.fontSize};
	font-weight: ${(p) => p.theme.typography.fontWeightRegular};
	outline: none;
	border: none;
	padding: ${(p) => p.theme.spacing(0.5)};

	&:focus,
	&.is-dirty {
		background-color: ${(p) => p.theme.palette.background.paper};
		color: ${(p) => p.theme.palette.common.white};
	}
`;

const StyledSpan = styled.span`
	padding-right: ${(p) => p.theme.spacing(1)};
	font-family: ${(p) => p.theme.typography.body1.fontFamily};
	font-size: ${(p) => p.theme.typography.body1.fontSize};
	font-weight: ${(p) => p.theme.typography.fontWeightBold};
`;

export interface NonInputLineProps {
	label: string;
	value: string;
}

const NonInputLine: FunctionComponent<NonInputLineProps> = ({
	label,
	value,
}) => {
	return (
		<StyledNonInputContainer>
			<div>
				<StyledSpan>{label}:</StyledSpan>
				<Typography style={{ display: 'inline-block' }}>
					{value}
				</Typography>
			</div>
		</StyledNonInputContainer>
	);
};

export default NonInputLine;
