import { StyledPropsSelector } from '../utils/typeUtils';
import clsx from 'clsx';
import React, { MouseEventHandler, forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';

interface Props extends HTMLAttributes<HTMLButtonElement> {
	onClick?: MouseEventHandler<HTMLButtonElement>;
	variant?: 'filled' | 'outlined' | 'text';
	color?: string;
}

const buttonBorder: StyledPropsSelector<Props> = (state?) => (props) => {
	const { variant = 'text', theme } = props;

	if (state === 'hover') {
		return variant === 'text'
			? 'none'
			: `2px solid ${theme.palette.primary.main}`;
	}
	return variant === 'text' ? 'none' : '2px solid transparent';
};

const buttonBackground: StyledPropsSelector<Props> = (state?) => (props) => {
	const { variant = 'text', theme } = props;

	if (state === 'hover') {
		return variant === 'outlined' || variant === 'text'
			? 'transparent'
			: theme.palette.primary.main + '30';
	}
	return variant === 'filled'
		? theme.palette.primary.main
		: variant === 'text'
		? 'transparent'
		: 'transparent';
};

const buttonTextColor: StyledPropsSelector<Props> = (state?) => (props) => {
	const { variant = 'text', theme } = props;

	if (state === 'hover') {
		return variant === 'filled'
			? theme.palette.primary.main
			: theme.palette.darkerBaby;
	}

	return variant === 'filled'
		? theme.palette.oldManGray
		: theme.palette.primary.main;
};

	// border-radius: ${(p) => p.theme.spacing(1)};
const StyledButtonBase = styled.button<{fillColor: string; color: string;}>`
	color:  ${(p) => p.color ? p.color : buttonTextColor()};
	background-color: ${(p) => p.fillColor ? p.fillColor : buttonBackground()};
	padding: ${(p) => p.theme.spacing(0.5)};
	border-radius: .25rem;
	border: ${buttonBorder()};
	font-size: ${(p) => p.theme.typography.button.fontSize};
	line-height: ${(p) => p.theme.typography.button.lineHeight};
	letter-spacing: ${(p) => p.theme.typography.button.letterSpacing};
	font-weight: ${(p) => p.theme.typography.button.fontWeight};
	text-transform: ${(p) => p.theme.typography.button.textTransform};
	font-family: ${(p) => p.theme.typography.button.fontFamily};
	transition: background 0.075s linear;

	&:hover {
		color: ${buttonTextColor('hover')};
		background-color: ${buttonBackground('hover')};
		border: ${buttonBorder('hover')};
		cursor: pointer;
	}

	&.button-disabled {
		color: ${(p) => p.theme.palette.divider};
		border: 2px solid ${(p) => p.theme.palette.divider};
		background-color: transparent;
		cursor: revert;
	}
`;

const Button = forwardRef<Props, any>(
	({ variant, disabled, className, ...props }, ref) => {
		return (
			<StyledButtonBase
				className={clsx(className, disabled && 'button-disabled')}
				{...props}
				variant={variant}
				disabled={disabled}
				ref={ref}
			/>
		);
	}
);

export default Button;
