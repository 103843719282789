import {
	PopoverContentColumns,
	PopoverHeader,
	PopoverImage
} from './styledComponents';
import { FunctionComponent, ReactNode } from 'react';
import RouterLink from "../../RouterLink";

interface PopoverHeadContentProps {
	children?: ReactNode;
	imgSrc?: string | null;
	name?: string;
}

interface PopoverMainProps {
	children?: ReactNode;
}

const PopoverMain: FunctionComponent<PopoverMainProps> = ({
	children,
}) => {
	return (
		<PopoverContentColumns>
			{children}
		</PopoverContentColumns>
	);
};

const PopoverHead: FunctionComponent<PopoverHeadContentProps> = (
	{
		name,
		imgSrc = null,
		children,
	}) => {
	return (
		<PopoverContentColumns>
			{
				name
					? <RouterLink to={`/${name}`} preserveSearchParams={false}>
						<PopoverImage src={imgSrc} />
					</RouterLink>
					: <PopoverImage src={imgSrc} />
			}
			<PopoverHeader flexDirection="column">
				{children}
			</PopoverHeader>
		</PopoverContentColumns>
	);
};

export default PopoverHead;
