import Button from '../../../common/buttons/Button';
import {FormResults} from '../../../common/Form';
import Typography from '../../../common/text/Typography';
import {useCreateAttributeMutation} from '../../api';
import {EntityActionFormProps} from '../common/commonTypes';
import {renderDerivationSubfields} from '../common/jsxHelpers';
import {StyledFlexContainer, StyledPaper} from '../common/styledComponents';
import {
    createAttrFormDefaults,
    createAttrFormToPayload,
} from './createAttributeHelpers';
import {CreateAttrFormValues} from './createAttributeTypes';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import AppModal from "../../../common/modals/AppModal";
import Heading from "../../../common/text/Heading";
import {
    harvestErrors,
    SubtleTextbox
} from "../../../common/inputs/SubtleTextBox";
import SubtleSelect from "../../../common/inputs/SubtleSelect";
import DefaultValueInput from "../../../common/inputs/DefaultValueInput";
import useModalType from "../../HUD/hooks/useModalType";
import {PrependAttrFormValues} from "../prependAttribute/prependAttributeTypes";

const identitySubtypeOptions = [
    {displayValue: 'None', value: ''},
];

const relationSubtypeOptions = [
    {displayValue: 'None', value: ''},
];

const categorySubtypeOptions = [
    {displayValue: 'None', value: ''},
];

const quantitySubtypeOptions = [
    {displayValue: 'None', value: ''},
    {displayValue: 'Distance', value: 'distance'},
    {displayValue: 'Duration', value: 'duration'},
    {displayValue: 'Currency', value: 'currency'},
    {displayValue: 'Ratio', value: 'ratio'},
    {displayValue: 'Rate', value: 'rate'},
];

const eventSubtypeOptions = [
    {displayValue: 'None', value: ''},
];

const locationSubtypeOptions = [
    {displayValue: 'Geography', value: 'geography'},
    {displayValue: 'None', value: ''},
];

const mediaSubtypeOptions = [
    {displayValue: 'Document', value: 'document'},
    {displayValue: 'Image', value: 'image'},
    {displayValue: 'Video', value: 'video'},
];

const subtypeMap = {
    identity: identitySubtypeOptions,
    relation: relationSubtypeOptions,
    category: categorySubtypeOptions,
    quantity: quantitySubtypeOptions,
    event: eventSubtypeOptions,
    location: locationSubtypeOptions,
    media: mediaSubtypeOptions,
};

const CreateAttributeForm: FunctionComponent = () => {

    const {modalType, modalProps, closeModal} = useModalType();
	const {resource} = modalProps;


    const {
        _id: parentEntityId,
        canEdit,
    } = resource;

    const formDefaults = createAttrFormDefaults();

    const {handleSubmit, register, reset, formState, watch, resetField} =
        useForm<CreateAttrFormValues>({
            defaultValues: formDefaults,
        });

    // watch type field to determine what to display in
    // subtype menu
    const watchedAttrType = watch('type', formDefaults.type);

    // when type changes we reset the subtype to make sure we don't get a mismatch between
    // type and subtype values.
    useEffect(() => {
        resetField('otherType');
    }, [watchedAttrType, resetField]);

    const [createAttr, mutationResults] = useCreateAttributeMutation();

    const validatingRegister = useCallback(
        (inputName: keyof CreateAttrFormValues, options?: any) =>
            register(inputName,  options ? options : {
                required: `${inputName} is required`,
            }),
        [register]
    );

    const onSubmit: SubmitHandler<CreateAttrFormValues> = (vals, e) => {
        e?.preventDefault();
        createAttr({
            body: createAttrFormToPayload(vals, parentEntityId),
        });
    };

    // only present option to choose a subtype if main type is appropriate
    const renderSubtypeSelect = useCallback(() => {
        const shouldRender =
            watchedAttrType === 'quantity' || watchedAttrType === 'location';

        if (!shouldRender) {
            return null;
        }

        return (
            <SubtleSelect
                inline={true}
                label="Subtype"
                alignItems={'center'}
                justifyContent={'space-between'}
                onReset={() => resetField('otherType')}
                isDirty={Boolean(formState.dirtyFields.otherType)}
                {...validatingRegister('otherType')}
            >
                {subtypeMap[watchedAttrType].map(({value, displayValue}) => (
                    <option value={value} key={value}>
                        {displayValue}
                    </option>
                ))}
            </SubtleSelect>
        );
    }, [watchedAttrType, register]);

    return (
        <AppModal
            label={"Create Attribute"}
            isOpen={true}
            isDirty={formState.isDirty}
            onClose={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            canEdit={canEdit}>
            {renderDerivationSubfields({
                isDerivation: true,
                formState,
                resetField,
                validatingRegister,
                mutationResults
            })}

            {renderSubtypeSelect()}
            <DefaultValueInput
                formState={formState}
                validatingRegister={validatingRegister}
                resetField={resetField}
                mutationResults={mutationResults}
            />

            <FormResults
                {...mutationResults}
                validationErrors={formState.errors}
            />
        </AppModal>
    );
};

export default CreateAttributeForm;
