import React, {FunctionComponent, useState} from "react";
import {BaseOrg, OrgModalTypes} from "../../features/ontology/types/orgTypes";
import MenuButton from "../buttons/MenuButton";
import MenuPanel from "./MenuPanel";
import AddMemberButton from "../buttons/AddMemberButton";
import SubMenuButton from "../buttons/SubMenuButton";
import TextButton from "../buttons/TextButton";
import DestroyButton from "../buttons/DestroyButton";
import {
    REMOVE_STEWARD,
    REMOVE_GOVERNOR,
    REMOVE_MEMBER,
} from "../../features/graph/CONSTANTS";
import SettingsButton from "../buttons/SettingsButton";
import {BaseUser, UserModalTypes} from "../../features/authentication/types/userTypes";
import RevokeRoleButton from "../buttons/RevokeRoleButton";


const roleMap = {
    governor: REMOVE_GOVERNOR,
    member: REMOVE_MEMBER,
    steward: REMOVE_STEWARD
}

export const ShareButton: FunctionComponent = () => {
    return <SubMenuButton label={'Share'}>
            <TextButton color='primary' fillColor='transparent' text={'Copy Object Link'}/>
        </SubMenuButton>
}

type RoleTypes =
    | 'member'
    | 'steward'
    | 'governor';


export const RoleMenu: FunctionComponent<{ role: RoleTypes; user: BaseUser; canEdit: boolean; openModal: (type: UserModalTypes | null) => void;}> = (
    {user, role, canEdit, openModal}
) => {

    const [actionSubmenu, setActionSubmenu] = useState<string | null>(null);

    const actionTitle = roleMap[role];
    if (user && canEdit) {
         return <MenuButton placement={'right-start'}>
            <MenuPanel
                resource={user}
                canEdit={canEdit}
                actions={[{
                    actionTitle: actionTitle,
                    modalType: 'membership',
                    divider: false,
                    form: RevokeRoleButton,
                }]}/>
        </MenuButton>;
    } else {
        return null;
    }
};

export default RoleMenu;
