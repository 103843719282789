import { SplitAttrFormValues, SplitAttrPayload } from './splitAttributeTypes';

export const splitAttrFormDefaults = (): SplitAttrFormValues => ({
	attributeId: 0,
});

export const splitAttrFormToPayload = (
	formValues: SplitAttrFormValues
): SplitAttrPayload => {
	return formValues;
};
