import useAuthStatus from 'features/authentication/hooks/useAuthStatus';
import useCurrentUser from 'features/authentication/hooks/useUser';
import { FunctionComponent, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface RequireAuthProps {
	to: string;
	TOSRequired?: boolean;
	children?: ReactNode;
}

const RequireAuth: FunctionComponent<RequireAuthProps> = ({
	children,
	to,
	TOSRequired,
}) => {
	const { tosAccepted, isOnboarded } = useCurrentUser();
	const isUnauthorized = !useAuthStatus();
	const location = useLocation();

	if (isUnauthorized) {
		return (
			<Navigate to={to} state={{ returnWhenAuthenticated: location }} />
		);
	}

	if (TOSRequired) {
		// if (tosAccepted && isOnboarded) {
		// 	return <>{children}</>;
		// }
		if (!tosAccepted) {
			return <Navigate to="/terms-of-service" />;
		}
		return <>{children}</>;
	}

	return <>{children}</>;
};

export default RequireAuth;
