import React, {FunctionComponent} from "react";
import timezones from "../../assets/tzs.json";
import SubtleSelect from "./SubtleSelect";
import {ActionFieldProps} from "../../features/actions/common/commonTypes";
import {harvestErrors, SubtleTextbox} from "./SubtleTextBox";

const LanguageSelect: FunctionComponent<ActionFieldProps> = (
    {
        defaultValue,
        formState,
        resetField,
        validatingRegister,
        mutationResults
    }
    ) => {
    // aria-errormessage={genErrorIdFromLabel('timezone')}
    // aria-invalid={formFieldHasErrors('timezone', formState)}
    return <SubtleTextbox
        {...validatingRegister('language')}
        showLabel
        label="Language"
        defaultValue={defaultValue}
        isDirty={Boolean(formState.dirtyFields.language)}
        onReset={() => resetField('language')}
        error={harvestErrors(
            'language',
            formState,
            mutationResults
        )}
    />;
}

export default LanguageSelect;
