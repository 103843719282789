import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import Typography from "../text/Typography";
import {
	faBorderNone,
	faInfo,
	faInfoCircle,
	faTimes,
	faToggleOff,
	faToggleOn
} from "@fortawesome/free-solid-svg-icons";
import {BasePaper} from '../Paper';
import styled from 'styled-components';
// import SubtleRadio from "../inputs/SubtleRadio";
import {FormResults} from "../Form";
import useCurrentUser from "../../features/authentication/hooks/useUser";
import {useUpdateAccessibilityMutation} from "../../features/api";
import {SubmitHandler, useForm} from "react-hook-form";
import Heading from "../text/Heading";
import AppModal from "./AppModal";
import OnOffToggle, {SlideToggleCheckDescriptor} from "../inputs/OnOffToggle";
import {MonthIcon, YearIcon} from "../icons";
import FlexContainer from "../FlexContainer";
import useModalType from "../../features/HUD/hooks/useModalType";

export const SourceBody = styled(BasePaper)`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background: ${(p) => p.theme.palette.background.paper};
`

interface HUDProps {
	translucent?: boolean;
	borderRadius?: string;
	width?: string | number;
	height?: string | number;
}


	// box-shadow: ${(p) => p.theme.shadows[4]};
export const HUDModalBody = styled.div<HUDProps>`
	background: ${(p) =>
		p.theme.palette.background[
			p.translucent ? 'transparentDark' : 'paper'
		]};
		
	border-radius: ${(p) => p.borderRadius ? p.borderRadius : '4px'};
	color: ${(p) => p.theme.palette.lightBaby};
	width: 100%;
	height: calc(100vh - ${(p) => p.theme.navHeight * 4 + p.theme.panelGutter * 8}px);
	top: ${(p) => p.theme.navHeight + p.theme.panelGutter * 4}px;
	position: absolute;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		-webkit-backdrop-filter: blur(1px);
		backdrop-filter: blur(1px);
	}
	${(p) => p.theme.translucent}
`;

interface StyledModalHeaderProps {
	justifyContent?: string;
	borderBottom?: string;
	padding?: string;
}

	// padding: ${(p) => p.padding ? p.padding : p.theme.spacing(1, 2)};
export const ModalHeader = styled.header<StyledModalHeaderProps>`
	padding: 1rem;
    border-bottom: ${(p) => p.borderBottom ? p.borderBottom : `2px solid ${p.theme.palette.divider}`};
	display: flex;
	background: ${(p) => p.theme.palette.background['paper']};
	justify-content: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	justify-items: ${(p) => p.justifyContent ? p.justifyContent : 'space-between'};
	align-items: flex-start;
	align-content: flex-start;
	height: ${(p) => p.theme.navHeight};
	z-index: 10000;
`;

	// font-size: 1rem;
export const HUDModalHeaderText = styled.h1`
	display: flex;
	align-content: center;
	align-items: center;
	gap: 1rem;
	justify-content: flex-start;
	justify-items: flex-start;
`;


interface UpdateAccessibilityFormValues {
	darkModeOn: boolean;
	// phoneNumber: string;
}


const toggleDefs: SlideToggleCheckDescriptor<string | boolean>[]  = [
	{label: 'off', icon: faToggleOff, value: false, tooltip: 'Off'},
	{label: 'on', icon: faToggleOn, value: true, tooltip: 'On'},
	// {label: 'auto', icon: faBorderNone, value: 'auto', tooltip: 'Auto'},
];



const AccessibilityModal: FunctionComponent = () => {

	const {closeModal} = useModalType();
	const user = useCurrentUser();

	const [activeValue, setActiveValue] = useState<boolean | undefined>(false);

	const { register, formState, resetField, handleSubmit, reset } =
		useForm<UpdateAccessibilityFormValues>({
			defaultValues: user,
		});

	// this is needed to update react-hook-form's default values with new
	// values from a successful PUT request.
	useEffect(() => {
		if (!!user) {
			setActiveValue(user.darkModeOn)
		}
	}, [user]);

	useEffect(() => {
		if (!!user && formState.isDirty) {
			reset(user);
			closeModal();
		}
	}, [user, reset]);

	const validatingRegister = useCallback(
		(inputName: keyof UpdateAccessibilityFormValues) =>
			register(inputName, {
				required: `${inputName} is required`,
			}),
		[register]
	);

	const [updateAccessibility, mutationResults] = useUpdateAccessibilityMutation();

	const onSubmit: SubmitHandler<UpdateAccessibilityFormValues> = (formVals) => {
		const { __tag, ...userProps } = user;

		updateAccessibility({
			userId: userProps._id,
			body: Object.assign({darkMode: userProps.darkModeOn}, formVals),
		});
	};

	if (!!user) {
		return (
			<AppModal
				label={"Display & accessibility"}
				isOpen={true}
				isDirty={activeValue !== user.darkModeOn}
				onClose={closeModal}
				onSubmit={handleSubmit(onSubmit)}
				canEdit={true}>
				<FlexContainer alignItems={'center'} justifyContent={'space-between'}>
					<FlexContainer flexDirection={'column'} gap={'1rem'}>
						<Heading style={{padding: 0, margin: 0}} component="h3">
							Dark Mode
						</Heading>
						<Typography paragraph variant={'body1'}>
							Adjust the appearance of Futuremodel to reduce glare
							and give your eyes a break.
						</Typography>
						<OnOffToggle
							// label="Dark Mode"
							activeValue={activeValue}
							legend="Dark Mode"
							onCheck={() => setActiveValue((p) => !p)}
							onActiveValueMismatch={() => {}}
							checkboxes={toggleDefs}
							// // onReset={() => resetField('darkMode')}
							// // isDirty={Boolean(formState.dirtyFields.darkMode)}
							// {/*{...validatingRegister('darkMode')}*/}
						/>
					</FlexContainer>
				</FlexContainer>
				{/*<Heading style={{padding: 0, margin: 0}} component="h3">*/}
				{/*	Compact Mode*/}
				{/*</Heading>*/}
				{/*<Typography>*/}
				{/*	Make your font size smaller so more content can fit on the*/}
				{/*	screen.*/}
				{/*</Typography>*/}
				{/*<SlideToggle*/}
				{/*	activeValue={activeValue}*/}
				{/*	legend="Compact Mode"*/}
				{/*	onCheck={setActiveValue}*/}
				{/*	onActiveValueMismatch={() => {}}*/}
				{/*	checkboxes={toggleDefs}*/}
				{/*	// // onReset={() => resetField('darkMode')}*/}
				{/*	// // isDirty={Boolean(formState.dirtyFields.darkMode)}*/}
				{/*	// /!*{...validatingRegister('darkMode')}*!/*/}
				{/*/>*/}
				{/*<Heading style={{padding: 0, margin: 0}} component="h3">*/}
				{/*	Use single-character keyboard shortcuts*/}
				{/*</Heading>*/}
				{/*<Typography>*/}
				{/*	Use single-character shortcuts to perform common actions*/}
				{/*	faster on Futuremodel.*/}
				{/*</Typography>*/}
				{/*<SlideToggle*/}
				{/*	activeValue={activeValue}*/}
				{/*	legend="Single-Character Keyboard Shortcuts"*/}
				{/*	onCheck={setActiveValue}*/}
				{/*	onActiveValueMismatch={() => {}}*/}
				{/*	checkboxes={toggleDefs}*/}
				{/*	// // onReset={() => resetField('darkMode')}*/}
				{/*	// // isDirty={Boolean(formState.dirtyFields.darkMode)}*/}
				{/*	// /!*{...validatingRegister('darkMode')}*!/*/}
				{/*/>*/}
			{/*	<Heading style={{padding: 0, margin: 0}} component="h3">*/}
			{/*		Pin keyboard shortcut help*/}
			{/*	</Heading>*/}
			{/*	<Typography>*/}
			{/*		In the corner of your screen, you'll see shortcuts that*/}
			{/*		relate to what you're doing. They'll change as you use*/}
			{/*		Futuremodel.*/}
			{/*	</Typography>*/}
			{/*	<SlideToggle*/}
			{/*		activeValue={activeValue}*/}
			{/*		legend="Pin Keyboard Shortcut Suggestions"*/}
			{/*		onCheck={setActiveValue}*/}
			{/*		onActiveValueMismatch={() => {}}*/}
			{/*		checkboxes={toggleDefs}*/}
			{/*		// // onReset={() => resetField('darkMode')}*/}
			{/*		// // isDirty={Boolean(formState.dirtyFields.darkMode)}*/}
			{/*		// /!*{...validatingRegister('darkMode')}*!/*/}
			{/*	/>*/}
			{/*	<Heading style={{padding: 0, margin: 0}} component="h3">*/}
			{/*		See all keyboard shortcuts*/}
			{/*	</Heading>*/}
			{/*	/!*{['On', 'Off'].map((o) => (*!/*/}
			{/*	/!*	<option key={o} value={o}>*!/*/}
			{/*	/!*		{o}*!/*/}
			{/*	/!*	</option>*!/*/}
			{/*	/!*))}*!/*/}
			{/*/!*</SubtleRadio>*!/*/}
		<FormResults
				{...mutationResults}
				validationErrors={formState.errors}
				onSuccessDelay={2000}
			/>
		</AppModal>
		);
	} else {
		return null
	}
};

// {/*<FlexContainer flexDirection={'column'} gap={'1rem'} alignItems='center'>*/}
// {/*{!!user.avatar?.medium.url || !!user.avatarUrl*/}
// {/*	? <ProfileAvatar*/}
// {/*		initial={!!user ? user.firstName : ''}*/}
// {/*		profileImageURL={user.avatar?.medium.url ? user.avatar?.medium.url : user.avatarUrl}*/}
// {/*		isProfile={true}*/}
// {/*		// onClick={() => setMenuOpen((p) => !p)}*/}
// {/*		onClick={() => {}}*/}
// {/*		// ref={setReferenceEl}*/}
// {/*		isOnline={false}*/}
// {/*		styles={{*/}
// {/*			alignSelf: 'center',*/}
// {/*			justifySelf: 'center'*/}
// {/*		}}*/}
// {/*		height={'10rem'}*/}
// {/*		width={'10rem'}*/}
// {/*	/>*/}
// {/*	: <StyledGraphicContainer style={{gridArea: 'avatar'}}>*/}
// {/*		<StyledGraphicCircle />*/}
// {/*	</StyledGraphicContainer>}*/}
// {/*	<a style={{alignSelf: 'center', verticalAlign: 'middle'}} href='https://en.gravatar.com/' target='_blank'>*/}
// {/*	<Typography>Manage Gravatar</Typography>*/}
// {/*	<IconButton*/}
// {/*		icon={faInfoCircle as any}*/}
// {/*		tooltip=""*/}
// {/*		size="xs"*/}
// {/*		iconSize='sm'*/}
// {/*		style={{marginLeft: '4px'}}*/}
// {/*		fillColor={'primary'}*/}
// {/*		onClick={() => {}}*/}
// {/*		showTooltip={true}*/}
// {/*	/>*/}
// {/*</a>*/}
// {/*</FlexContainer>*/}
export default AccessibilityModal;
