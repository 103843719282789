import { BaseAttribute } from '../ontology/types/attributeTypes';
import { Individual } from '../ontology/types/individualTypes';
import AttributeMeta from './AttributeMeta';
import {
	AttributeFields,
	Facts,
	IAttributeMeta,
	IDataPreparer,
	IGetMetaClass,
	UnrecognizedField,
} from './dataPreparationTypes';
import {
	isNonEmptyString,
	isNonNullable,
	isNumber,
} from 'common/utils/typeGuards';
import { Nullable, toStringIfNumber } from 'common/utils/typeUtils';
import { isoParse } from 'd3-time-format';
import {
	AttributeFilter,
	AttributeFilters,
	isCatRangeFilter,
	isEventRangeFilter,
	isIdentityFilter,
	isQtyRangeFilter,
} from 'features/compositeViews/EntityViews/types';
import { AppError } from 'features/errorHandling/types/errorTypes';

class DataPreparer implements IDataPreparer {
	individualCount: number = 0;
	data: Individual[] = [];
	attributes: BaseAttribute[] = [];
	attributeFields: AttributeFields = {};
	unrecognizedFields: UnrecognizedField[] = [];
	isInitialized: boolean = false;
	isProcessed: boolean = false;

	private individuals: Individual[] = [];
	private getMetaClass: IGetMetaClass;
	private aliasMap: Record<string, string> = {};

	facts<T>(...as: string[]) {
		const meta = as.map((n) => this.getAttributeData(n));

		if (meta.every(isNonNullable)) {
			const result: Facts<T> = [];

			this.data.reduce((acc, datum, i) => {
				if (meta.every((m) => this.metaIndexValid(i, m))) {
					const values = meta.map((m) => datum[m.futuremodelName]);
					result.push({ values, originalIdx: i });
				}
				return acc;
			}, result);

			return result;
		}

		return new AppError(
			`Attempting to read facts for ${as.join(
				','
			)}, but not all are known attribute names. Valid attribute names are ${this.attributes
				.map((a) => a.name)
				.join(',')}`
		);
	}

	getAttributeData<T extends AttributeMeta = AttributeMeta>(
		v: string
	): Nullable<T>;

	getAttributeData<T extends AttributeMeta = AttributeMeta>(
		v: (a: IAttributeMeta) => boolean
	): T[];

	getAttributeData(v: string | ((attr: IAttributeMeta) => boolean)) {
		if (typeof v === 'string') {
			const queried = this.attributeFields[v];
			return queried ?? null;
		}

		const fields = this.attributeFields;

		return Object.keys(fields)
			.map((fieldName) => fields[fieldName])
			.filter(v);
	}

	process(filters?: AttributeFilters) {
		// disallow processing more than once
		if (this.isProcessed) {
			return this;
		}

		const process = this.processIndividual.bind(this);
		const satisfiesCurrentFilters = this.satisfiesCurrentFilters.bind(this);

		// keep count of individuals excluded by filter
		// so that we can calculate the idx of a valid
		// entry in the filtered array
		let discarded = 0;

		this.individuals.length > 0 && this.individuals.forEach((ind, i) => {
			if (satisfiesCurrentFilters(ind, filters)) {
				this.data.push(process(ind, i - discarded));
				return;
			}

			discarded++;
			return;
		});

		this.isProcessed = true;
		this.attributes.forEach((a) => this.attributeFields[a.name].finalize());
		return this;
	}

	private metaIndexValid(idx: number, meta: IAttributeMeta) {
		return meta && !meta.missingAt.has(idx) && !meta.nullAt.has(idx);
	}

	private satisfiesCurrentFilters(
		record: Individual,
		filters?: AttributeFilters
	) {
		if (!filters || filters.length === 0) {
			return true;
		}

		const strats = filters.map(this.getFilterStrat);

		return strats.every((strat, i) => {
			return strat(record[this.aliasMap[filters[i].attributeName]]);
		});
	}

	private getFilterStrat(f: AttributeFilter) {
		return isQtyRangeFilter(f)
			? (v: unknown) => isNumber(v) && f.min <= v && v <= f.max
			: isCatRangeFilter(f)
			? (v: unknown) =>
					isNonEmptyString(v) && f.includedValues.includes(v)
			: isEventRangeFilter(f)
			? (v: unknown) => {
					if (!isNonEmptyString(v)) {
						return false;
					}

					const parsed = isoParse(v);

					if (!(parsed instanceof Date)) {
						return false;
					}

					const ts = parsed.getTime();

					return f.min <= ts && ts <= f.max;
			  }
			: isIdentityFilter(f)
			? (v: unknown) =>
					(typeof v === 'string' || typeof v === 'number') &&
					f.includedIdentities.includes(toStringIfNumber(v))
			: () => false;
	}

	private processIndividual(ind: Individual, individualIdx: number) {
		const individualKeys = new Set(Object.keys(ind));
		const expectedKeys = this.attributes.map((a) => a.alias);

		const dealiased = expectedKeys.reduce((acc, key) => {
			const processor = this.attributeFields[key];
			processor.ingest(ind[key], individualIdx);
			individualKeys.delete(key);
			const dealiasedKey = this.aliasMap[key];
			acc[dealiasedKey] = ind[key];
			return acc;
		}, {} as any);

		// if there are any keys left in the individual not covered by the expected attributes,
		// record them.
		if (individualKeys.size > 0) {
			// Take care to use the array index of the *individual* being processed,
			// not the index of the object key we're currently looking at!
			individualKeys.forEach((key) => {
				this.unrecognizedFields.push({
					idx: individualIdx,
					keyName: key,
					value: ind[key],
				});
			});
		}

		dealiased['_object'] = 'Individual';

		return dealiased;
	}

	private assignProcessors() {
		this.attributes.forEach((a) => {
			const MetaClass = this.getMetaClass(a);
			this.attributeFields[a.alias] = MetaClass;

			//    create a getter for the attribute in 'attributeFields' so that
			// metadata object can be accessed by attribute's name OR its alias.
			Object.defineProperty(this.attributeFields, a.name, {
				get: () => this.attributeFields[a.alias],
			});
		});
	}

	_init(attrs: BaseAttribute[], individuals: Individual[]) {
		if (this.isInitialized) {
			throw new Error(
				'attempting to initialize individual data processor more than once!'
			);
		}
		this.attributes = attrs;
		this.individuals = individuals;
		this.assignProcessors();
		this.isInitialized = true;
		this.individualCount = individuals.length;

		attrs.forEach((a) => {
			this.aliasMap[a.alias] = a.name;
			this.aliasMap[a.name] = a.alias;
		});

		return this;
	}

	constructor(getMetaClass: IGetMetaClass) {
		this.getMetaClass = getMetaClass;
	}
}

export default DataPreparer;
