import { useAppDispatch } from '../../app/hooks';
import IconButton from './IconButton';
import {CreateIcon} from 'common/icons';
import {List, ListItem} from '../List';
import Popover from '../popovers/Popover/PopoverBase';
import { pipe } from '../utils/functionUtils';
import { setModalType } from '../../features/HUD/state/HUDSlice';
import { ControlButtonWrapper } from '../../features/HUD/components/styledComponents';
import { canEdit as canEditObject } from 'features/authentication/helpers';
import useActiveOrgData from 'features/ontology/hooks/useActiveOrgData';
import useActiveDomainData from 'features/ontology/hooks/useActiveDomainData';
import { FunctionComponent, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {TinySquare} from "../viz/ForceGraph/styledComponents";
import Typography from "../text/Typography";
import useModalType from "../../features/HUD/hooks/useModalType";


// const StyledListItem = styled.li<Props>`
// 	background: transparent;
// 	cursor: ${(p) => (p.$button ? 'pointer' : 'inherit')};
// 	&:hover {
// 		background: ${(p) =>
// 			p.$background === 'paper'
// 				? p.theme.palette.darkerBaby + '90'
// 				: p.theme.palette.darkBaby + 50};
// 	}
// 	padding: ${(p) => p.theme.spacing(0.5)}  ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(.5)};
// 	display:  flex;
// 	align-items:  ${(p) => p.position ? p.position : 'center'};
// 	align-content: center;
// 	color: ${(p) => p.theme.palette.oldManGray};
//
// 	&.highlight-list-item-hover:hover {
// 		color: ${(p) => p.$highlightColor || p.theme.palette.primary.main};
// 	}
// `;

const StyledList = styled(List)`
	background-color: ${(p) => p.theme.palette.darkBaby};
`;

const StyledListItem = styled(ListItem)`
	white-space: nowrap;
`;

export const ListItemAvatar = styled.div`
	flex: 0 0 10%;
`;

export const MenuAction: FunctionComponent<{onClick: () => void, label: string}> = ({onClick, label}) => {
	return 	<StyledListItem button onClick={onClick} style={{alignContent: 'center'}}>
		<ListItemAvatar>
			<TinySquare />
		</ListItemAvatar>
		<Typography variant={'h6'} style={{paddingLeft: '4px'}}>
			{label}
		</Typography>
	</StyledListItem>
}

const mergeClickHandlers = (close: () => void, ...fns: ((x?: any) => void)[]) =>
	fns.map((fn) => () => {
		close();
		fn();
	});

// TODO: These lists could be their own components/files
interface OrgGraphListProps {
	closeMenu: () => void;
}


const OrgGraphList: FunctionComponent<OrgGraphListProps> = ({
	closeMenu,
}) => {
	const {closeModal} = useModalType();
	const appDispatch = useAppDispatch();
	const openDomainCreationForm = () =>
		pipe(setModalType({type: 'domainCreationForm', props: []}), appDispatch);

	const openOrgCreationForm = () =>
		pipe(setModalType({type: 'orgCreationForm', props: []}), appDispatch);

	const openSourceCreationForm = () =>
		pipe(setModalType({type: 'sourceCreationForm', props: []}), appDispatch);

	const [openDomainCF, openOrgCF, openSourceCF] = mergeClickHandlers(
		closeMenu,
		openDomainCreationForm,
		openOrgCreationForm,
		openSourceCreationForm,
	);

	return (
		<StyledList>
			<MenuAction onClick={openSourceCF} label={'Create Data Source'}/>
			<MenuAction onClick={openDomainCF} label={'Create Domain'}/>
			<MenuAction onClick={openOrgCF} label={'Create Organization'}/>
		</StyledList>
	);
};

interface DomainGraphListProps {
	closeMenu: () => void;
}

const DomainGraphList: FunctionComponent<DomainGraphListProps> = ({
	closeMenu,
}) => {
	const appDispatch = useAppDispatch();

	// const openSourceBrowser = () =>
	// 	pipe(setModalType({type: 'sourceBrowser'}), appDispatch);

	const openEntityCreationForm = () =>
		pipe(setModalType({type: 'entityCreationForm'}), appDispatch);

	const openMetricCreationForm = () =>
		pipe(setModalType({type: 'metricCreationForm'}), appDispatch);

	// const openIndividualCreationForm = () =>
	// 	pipe(setModalType({type: 'individualCreationForm'}), appDispatch);

	const openEntityImport = () =>
		pipe(setModalType({type: 'importEntity'}), appDispatch);

	// openICF
	const [openEI, openECF, openMCF] = mergeClickHandlers(
		closeMenu,
		// openSourceBrowser,
		openEntityImport,
		openEntityCreationForm,
		openMetricCreationForm,
		// openIndividualCreationForm
	);
	return (
		<StyledList style={{padding: '4px'}}>
			{/*<MenuAction onClick={openSB} label={'Import Dataset'}/>*/}
			{/*<MenuAction onClick={openSB} label={'Import Catalog'}/>*/}
			<MenuAction onClick={openEI} label={'Import Entity'}/>
			<MenuAction onClick={openECF} label={'Create Entity'}/>
			<MenuAction onClick={openMCF} label={'Create Metric'}/>
			{/*<MenuAction onClick={openICF} label={'Create Individual'}/>*/}
		</StyledList>
	);
};

const CreateButton: FunctionComponent = () => {
	const { activeItem: activeOrg } = useActiveOrgData();
	const { activeItem: activeDomain } = useActiveDomainData();

	const [menuOpen, setMenuOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { domain, username } = useParams();

	const closeMenu = () => setMenuOpen(false);

	//  if there's not at least a route parameter for 'org', don't show anything
	const orgOrDomainInPath = !!(username || domain);
	const creatable = domain ? 'entity' : username ? 'domain' : '';
	const relevantPermission = creatable === 'entity' ? activeDomain : activeOrg;

	const canEdit = !!relevantPermission
		? canEditObject(relevantPermission)
		: false;

	const displaying = canEdit && orgOrDomainInPath;

	const renderList = () =>
		creatable === 'entity' ? (
			<DomainGraphList closeMenu={closeMenu} />
		) : (
			<OrgGraphList closeMenu={closeMenu} />
		);

	return (
		<ControlButtonWrapper displaying={displaying}>
			<IconButton
				ref={setAnchorEl}
				icon={CreateIcon}

				onClick={() => setMenuOpen((p) => !p)}
				aria-label={`Create a new ${creatable}`}
				key="creation-form"
				size="lg"
				color={'darkerBaby'}
				fillColor='darkerBaby'
				tooltipPlacement="left"
			/>

			<Popover anchorEl={anchorEl} open={menuOpen} placement="left-start">
				<ClickAwayListener onClickAway={() => setMenuOpen(false)}>
					<div>{renderList()}</div>
				</ClickAwayListener>
			</Popover>
		</ControlButtonWrapper>
	);
};


export const CreateMenu: FunctionComponent = () => {
	const { activeItem: activeOrg } = useActiveOrgData();
	const { activeItem: activeDomain } = useActiveDomainData();

	const [menuOpen, setMenuOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { domain, username } = useParams();

	const closeMenu = () => setMenuOpen(false);

	//  if there's not at least a route parameter for 'org', don't show anything
	const orgOrDomainInPath = !!(username || domain);
	const creatable = domain ? 'entity' : username ? 'domain' : '';
	const relevantPermission = creatable === 'entity' ? activeDomain : activeOrg;

	const canEdit = !!relevantPermission
		? canEditObject(relevantPermission)
		: false;

	const displaying = canEdit && orgOrDomainInPath;

	const renderList = () =>
		creatable === 'entity' ? (
			<DomainGraphList closeMenu={closeMenu} />
		) : (
			<OrgGraphList closeMenu={closeMenu} />
		);

	return <Popover anchorEl={anchorEl} open={true} placement="right-start">
		{renderList()}
	</Popover>;
};

export default CreateButton;
