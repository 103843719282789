import {Tagged} from '../../../common/utils/typeUtils';
import {AppError} from '../../errorHandling/types/errorTypes';
import {DateTime} from '../../ontology/types/commonTypes';
import {JWT} from './tokenTypes';
import {BaseOrg} from "../../ontology/types/orgTypes";
import {CreationId} from "../../../common/questions/helpers";
import {QuestionSubject} from "../../../common/questions/types/questionTypes";
import {
    GeneralCommentCreationId,
    NewIndividualCommentCreationId
} from "../../../common/comments/types/commentTypes";


export type UserModalTypes =
    | 'userSettings'
    | 'share'
    | 'viewAs'
    | 'activityLog'
    | 'archive'
    | 'accountStatus'
    | 'audioCall'
    | 'videoCall'
    | 'friendship'
    | 'support'
    | 'block'
    | 'destroy'
    | 'following'
    | 'followers';


export interface UserInviteData {
    _object: 'UserInvite',
    _id: number,
    summary: string;
    invitedBy: BaseUser;
    invited: BaseUser;
    createdAt: DateTime;
    acceptedAt: null | DateTime;
    withdrawnAt: null | DateTime;
    isAccepted: boolean;
    isWithdrawn: boolean;
}

/**
 * Base type for a single user's profile files
 */
export interface BaseUser {
    _links: {
        url: string,
        followed: string;
        followers: string;
        self: string;
        apiPath: string;
    };
    avatar: {
        medium: UserGravatarData;
        small: UserGravatarData;
        thumb: UserGravatarData;
        tiny: UserGravatarData;
    } | null;
    department: null | string;
    defaultOrg: string;
    email?: string;
    phoneNumber?: string;
    avatarUrl?: string;
    doNotMail: boolean;
    doNotTrack?: boolean;
    darkModeOn?: boolean;
    definition?: string;
    _id: number;
    isWaiting?: boolean;
    isInternal: boolean;
    isOnline: boolean;
    isPresumedCompetitor?: boolean;
    isVerifiedCompetitor?: boolean;
    jobTitle: null | string;
    firstName: string;
    lastName: string;
    lastSeen: null | DateTime;
    lastActive: null | string;
    locale: null | string;
    location: null | string;
    social: {
        github: {
            username: string | null | undefined;
        };
        facebook: {
            username: string | null | undefined;
        };
        instagram: {
            username: string | null | undefined;
        };
        linkedin: {
            username: string | null | undefined;
            oauthTokenExp: number | null;
        };
    };
    tosAccepted: boolean;
    isOnboarded: boolean;
    memberSince: null | DateTime;
    invitesRemaining: number;
    invitedBy: null | UserInviteData;
    stats: {
        // profile: {};
        activity: {
            logins: number;
            logouts: number;
        };
        connections: {
            followers: number;
            following: number;
            invitesSent: number;
        };
        modeling: {
            sources: number;
            catalogs: number;
            datasets: number;
            attributes: number;
            domains: number;
            entities: number;
            imports: number;
            metrics: number;
        };
        interactions: {
            tasks: number;
            messagesSent: number;
            questions: number;
            answers: number;
            comments: number;
            reactions: number;
            shares: number;
        };
        mentions: {};
        community: {
            organizations: number;
            domains: number;
        };
    };
    _object: 'User';
    username: string;
    userContext: {
        excludedPermissions: [];
        interactions: {
            following: boolean;
        };
        iqByAction: {
            editMetadata: {
                primary: {
                    applicable: boolean;
                    base: number;
                    multiplier: number;
                };
            };
        };
        permissions: {
            self: boolean;
            admin: boolean;
            edit: boolean;
            view: boolean;
        };
        relationships: {};
    };
    emailConfirmed: boolean;
}

export interface UserGravatarData {
    bounding_box: {
        height: number;
        width: number;
    };
    url: string;
}

export const AUTHENTICATED_USER_TAG = '__authenticatedUser__';

/**
 * Shape of user in redux store when user is logged-in
 */
export interface AuthenticatedUser extends Tagged, BaseUser {
    __tag: typeof AUTHENTICATED_USER_TAG;
}

export const UNAUTHENTICATED_USER_TAG = '__unauthenticatedUser__';


export const ORG_TAG = '__Org__';

export interface Org extends BaseOrg {
    __tag: typeof ORG_TAG;
}

/**
 * Shape of redux user slice when no user is logged in
 */
export interface UnauthenticatedUser extends Tagged {
    __tag: typeof UNAUTHENTICATED_USER_TAG;
}

export interface DisplayPropSettings<T> {
    label: string;
    value: T;
    editable: boolean;
}

export interface DisplayableUserProps {
    firstName: AuthenticatedUser['firstName'];
    lastName: AuthenticatedUser['lastName'];
    memberSince: AuthenticatedUser['memberSince'];
    username: AuthenticatedUser['username'];
    jobTitle: AuthenticatedUser['jobTitle'];
    department: AuthenticatedUser['department'];
    doNotMail: AuthenticatedUser['doNotMail'];
    defaultOrg: AuthenticatedUser['defaultOrg'];
}

export type EditableUserProps = Omit<DisplayableUserProps, 'memberSince'>;

/**
 * Represents every possible state of the 'user' slice in Redux store:
 * either authenticated or unauthenticated.
 */

export type CurrentOrg = Org;
export type CurrentUser = AuthenticatedUser | UnauthenticatedUser;
export type User = AuthenticatedUser | UnauthenticatedUser | BaseUser;

export const isAuthenticatedUser = (u: CurrentUser): u is AuthenticatedUser =>
    u.__tag === AUTHENTICATED_USER_TAG;

/**
 * JSON payload sent to server on a login or signup attempts
 */
export interface UserAuthCredentials {
    username: string;
    password: string;
}

/**
 * Payload passed to mutation query to retrieve a token on signup or login
 */
export interface AuthenticateUserParams {
    kind: 'signup' | 'login';
    credentials: UserAuthCredentials;
}

/**
 * Payload to request user's profile information
 */
export interface GetUserProfileParams {
    username: string;
}

/**
 * Payload to request user's profile information
 */
export interface GetUserParams {
    userId: number;
}

/**
 * JSON payload of successful query for user profile files
 */
export interface UserDataResponse {
    meta: {
        status: number;
    };

    response: BaseUser;
}

export interface GetLandingResponse {
    meta: {
        status: number;
    };

    response: BaseUser | BaseOrg;
}

export interface GetLandingParams {
    username: string;
}

/**Raw API response shape.  RTK Query endpoing takes care of extracting 'items' */
export interface GetUsersFullResponse {
    items: BaseUser[];
}

/**API response that code will actually receive from RTK Query, due to 'transformResponse'
 * setup for endpoing.
 */
export type GetUsersResponse = BaseUser[];

/**
 * When application receives user data as a response to a successful authentication request,
 * convert user data from type received from server
 * to type used in client application.
 */
export const userResponseToAuthenticatedUser = (
    res: UserDataResponse
): AuthenticatedUser | AppError => {
    // TODO: might be a good idea to do some validation here, eventually.
    return {
        __tag: AUTHENTICATED_USER_TAG,
        ...res.response,
    };
};

// export type UpdateUserParams = Omit<AuthenticatedUser, '__tag'>;
export interface UpdateUserParams {
    userId: number;
    body: Omit<AuthenticatedUser, '__tag'>;
}

export interface GrantAccessParams {
    userId: number;
}

export interface GrantAccessResponse {
}

export type UpdateUserDataResponse = UserDataResponse;

/**
 * Grouping of properties that define the relationship between the
 * current user and a given object.
 */
export interface UserContext {
    interactions: {
        following: boolean;
    };

    permissions: { admin: boolean; edit: boolean; view: boolean };
    relationships: {};
}

export interface Contextualized {
    userContext: UserContext;
}

export const userIsFollowing = (object: Contextualized) =>
    object.userContext.interactions.following;

export interface RequestPWResetParams {
    email: string;
}

export interface RequestPWResetResponse {
}

export interface ResetPasswordResponse {
}

export interface ResetPasswordParams {
    password: string;
    resetToken: string;
}

export interface RegisterUserResponse extends BaseUser {
    token: JWT;
}

export interface RegisterUserParams {
    firstName: string;
    lastName: string;
    username: string;
    password: string;
    registrationToken: string;
}

export interface InviteUserResponse {
}

export interface InviteUserParams {
    email: string;
}

export interface GetUsersWaitingResponse {
    _meta: {
        page: number;
        perPage: number;
        totalItems: number;
        totalPages: number;
    },
    items: BaseUser[];
}

export interface GetUsersWaitingParams {
    perPage: number;
    page: number;
}

export interface WithdrawInviteResponse {
}

export interface WithdrawInviteParams {
    inviteId: number;
}

export interface ResendInviteResponse {
}

export interface ResendInviteParams {
    inviteId: number;
}

export const followable: string[] = [
    // TODO: this api route doesn't actually exist in the backend
    'Organization',
    'Domain',
    'Entity',
    'Attribute',
    'Individual',
    'User',
    'DataSource',
    'DataCatalog',
    'Dataset',
    'DataColumn',
    'DataRow',
    'DataValue',
    'DataType',
    'Metric',
    'Fact',
    'Comment',
    'Insight',
];

export type FollowersSubject = typeof followable[number];

export interface GetFollowersParams {
    objectId: number;
    objectType: string;
    perPage?: number;
    page?: number;
}

export interface GetUserInvitesParams {
    userId: number;
    perPage?: number;
    page?: number;
}

export const buildInvitationsQueryURL = ({
                                             userId,
                                             perPage = 10,
                                             page = 1
                                         }: GetUserInvitesParams) =>
    `/users/${userId}/invitations?page=${page}&per_page=${perPage}`;

export const buildParametrizedInvitationsQueryURL = () =>
    `/users/:userId/invitations?page=:page&per_page=:perPage`;


export interface GetFollowingParams {
    objectId: number;
    objectType: 'users';
    perPage?: number;
    page?: number;
}

export type GetFollowersResponse = {
    _meta: {
        page: number;
        perPage: number;
        totalItems: number;
        totalPages: number;
    },
    items: any[];
};

export type GetFollowingResponse = {
    _meta: {
        page: number;
        perPage: number;
        totalItems: number;
        totalPages: number;
    },
    items: any[];
};

export type GetUserInvitesResponse = {
    _meta: {
        page: number;
        perPage: number;
        totalItems: number;
        totalPages: number;
    },
    items: any[];
};


export interface GeneralFollowId {
    kind: 'other';
    objectId: number;
}

export interface NewIndividualFollowId {
    kind: 'newIndividualFollow';
    attributeId: number;
    primaryAttributeValue: string | number;
    objectId: string | number;
}

export type FollowId =
    | NewIndividualFollowId
    | GeneralFollowId;


export interface FollowResourceParams {
    objectType: string;
    objectIdentifier: FollowId;
    body: {
        objectType: string;
        objectIdentifier: FollowId;
    };
}

export interface FollowResourceResponse {
}

export interface UnfollowResourceParams {
    objectType: string;
    objectIdentifier: FollowId;
    body: {
        objectType: string;
        objectIdentifier: FollowId;
    }
}

export interface UnfollowResourceResponse {
}

export const getURLStumpFromObjectType = (objectType: FollowersSubject) => {
    switch (objectType) {
        case 'Organization':
            return 'orgs';
        case 'User':
            return 'users';
        case 'DataSource':
            return 'sources';
        case 'DataCatalog':
            return 'catalogs';
        case 'Dataset':
            return 'datasets';
        case 'DataColumn':
            return 'columns';
        case 'DataRow':
            return 'rows';
        case 'DataValue':
            return 'values';
        case 'DataType':
            return 'types';
        case 'Metric':
            return 'metrics';
        case 'Fact':
            return 'facts';
        case 'Insight':
            return 'insights';
        case 'Domain':
            return 'domains';
        case 'Entity':
            return 'entities';
        case 'Attribute':
            return 'attributes';
        case 'Individual':
            return 'individuals';
        case 'Comment':
            return 'comments';
        default:
            return 'orgs';
    }
};


export const buildFollowQueryURL = (
    {
        objectIdentifier,
        objectType
    }: FollowResourceParams) =>
    `/${getURLStumpFromObjectType(objectType)}/${objectIdentifier.objectId}/follow`;

export const buildParametrizedFollowQueryURL = (
    objectType: FollowersSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/follow`;


export const buildUnfollowQueryURL = (
    {
        objectIdentifier,
        objectType,
    }: UnfollowResourceParams) =>
    `/${getURLStumpFromObjectType(objectType)}/${objectIdentifier.objectId}/unfollow`;

export const buildParametrizedUnfollowQueryURL = (
    objectType: FollowersSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/unfollow`;

export const stringify = (arg: string | null | boolean | number | {}): string =>
    typeof arg === 'string'
        ? arg
        : typeof arg === 'boolean'
        ? String(arg)
        : arg === null
            ? 'null'
            : typeof arg === 'number'
                ? String(arg)
                : typeof arg === 'object'
                    ? 'empty'
                    : 'unknown';

export const buildFollowingQueryURL = (
    {
        objectId,
        perPage = 10,
        page = 1
    }: GetFollowingParams) =>
    `/users/${objectId}/following?page=${page}&per_page=${perPage}`;

export const buildParametrizedFollowingQueryURL = () => `/users/:objectId/following?page=:page&per_page=:perPage`;

export const buildFollowersQueryURL = (
    {
        objectId,
        objectType,
        perPage = 10,
        page = 1
    }: GetFollowersParams) =>
    `/${getURLStumpFromObjectType(objectType)}/${objectId}/followers?page=${page}&per_page=${perPage}`;

export const buildParametrizedFollowersQueryURL = (
    objectType: FollowersSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId/followers?page=:page&per_page=:perPage`;

export const buildUsersWaitingQueryURL = (
    {
        perPage = 10,
        page = 1
    }: GetUsersWaitingParams) => `/waiting?page=${page}&per_page=${perPage}`;

export const buildParametrizedUsersWaitingQueryURL = () =>
    `/waiting?page=:page&per_page=:perPage`;

export interface DeleteResourceParams {
    objectId: number;
    objectType: string;
}

export interface DeleteResourceResponse {
}

export const buildDeleteResourceQueryURL = (
    {
        objectId,
        objectType,
    }: DeleteResourceParams) => `/${getURLStumpFromObjectType(objectType)}/${objectId}`;

export const buildParametrizedDeleteResourceQueryURL = (
    objectType: FollowersSubject
) => `/${getURLStumpFromObjectType(objectType)}/:objectId`;


