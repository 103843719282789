import FlexContainer from '../FlexContainer';
import Spinner from '../loading/Spinner';
import Typography from '../text/Typography';
import {BaseAttribute} from '../../features/ontology/types/attributeTypes';
import {RelateAttrFormValues} from '../../features/actions/relateAttribute/relateAttributeTypes';
import React, {
    ChangeEventHandler,
    FunctionComponent,
    Fragment,
    MouseEventHandler, ChangeEvent
} from 'react';
import {UseFormRegister} from 'react-hook-form';
import Loading from "../loading/Loading";
import SubtleSelect from "./SubtleSelect";

interface TargetAttrSelectProps {
    selectableAttrs: BaseAttribute[];
    setTargetAttr: (attr: BaseAttribute | 'deriveNew' | null) => void;
    loading: boolean;
    validatingRegister: any;
    resetField: any;
    formState: any;
    labelText: string;
}

const TargetAttrSelect: FunctionComponent<TargetAttrSelectProps> = (
    {
        loading,
        selectableAttrs,
        setTargetAttr,
        resetField,
        formState,
        validatingRegister,
        labelText,
    }
) => {
    if (loading) {
        return <Loading/>;
    }

    const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
        const {value} = e.target;

        console.log(e)
        console.log(e.target)
        if (value === 'deriveNew') {
            return setTargetAttr(value);
        }

        if (value !== 'null') {
            return setTargetAttr(
                // call setter with complete attribute object for the
                // selected attribute.
                // ok to cast the 'find' result b/c we know the input array
                // has been pre-filtered
                selectableAttrs.find(
                    (attr) => attr._id === parseInt(value, 10)
                ) as BaseAttribute
            );
        }

        return setTargetAttr(null);
    };

    return <SubtleSelect
        inline={true}
        label={labelText}
        id={'target-attr-select'}
        alignItems={'center'}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => handleChange(e)}
        justifyContent={'space-between'}
        onReset={() => resetField('otherId')}
        isDirty={Boolean(formState.dirtyFields.otherId)}
        {...validatingRegister('otherId', {
            onChange: handleChange,
            validate: (value: any) =>
                value === 'null'
                    ? 'A target attribute selection is required'
                    : true,
        })}
    >
        <option value="null" key="initial">
            Select target attribute:
        </option>
        <option value="deriveNew" key="deriveNew">
            Derive a new entity
        </option>
        {selectableAttrs.map((attr) => (
            <option value={attr._id} key={attr._id}>
                {`${attr.entity.plural}: ${attr.plural}`}
            </option>
        ))}
    </SubtleSelect>;
}

export default TargetAttrSelect;
