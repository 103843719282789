import { scaleLinear } from 'd3-scale';

const useLinearXScale = (config: {
	xMinRatio?: number;
	xMaxRatio?: number;
	xMax: number;
	xMin: number;
	left: number;
	right: number;
	width: number;
}) => {
	const {
		left,
		right,
		width,
		xMax,
		xMin,
		xMinRatio = 1,
		xMaxRatio = 1,
	} = config;

	const xScale = scaleLinear()
		.domain([xMin * xMinRatio, xMax * xMaxRatio])
		.range([left, width - right]);

	return xScale;
};

export default useLinearXScale;
