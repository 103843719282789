import LinkedInShareButton from '../../../buttons/LinkedInShareButton';
import {
    StyledVizPaper,
    StyledSubmoduleHeader,
    StyledChartGrid,
    StyledSubmoduleContent, StyledAttributeList, StyledVizWithControls,
} from '../../../../features/profile/EntityProfile/components/styledComponents';
import {
    faGear,
    faHashtag,
    faPercentage
} from '@fortawesome/free-solid-svg-icons';
import FlexContainer from 'common/FlexContainer';
import SlideToggle, {SlideToggleCheckDescriptor} from 'common/inputs/SlideToggle';
import Typography from 'common/text/Typography';
import useElementSize from 'common/hooks/useSize';
import {isAppError} from 'common/utils/typeGuards';
import DisplayOnLoad from 'features/api/DisplayOnLoad';
import useEntitySearchParams
    from 'features/compositeViews/EntityViews/hooks/useEntitySearchParams';
import useDispatchableErr
    from 'features/errorHandling/hooks/useDispatchableErr';
import useActiveIndividualsMeta
    from 'features/ontology/hooks/useActiveIndividualsMeta';
import HorizontalBarChart
    from 'common/viz/HorizontalBarChart/HorizontalBarChartCore';
import {FunctionComponent, useMemo, useState} from 'react';
import {List, ListItem} from "../../../List";
import IconButton from "../../../buttons/IconButton";
import {BaseAttribute} from "../../../../features/ontology/types/attributeTypes";
import theme from "../../../theme/theme";

const toggleDefs: SlideToggleCheckDescriptor<boolean>[] = [
    {
        label: 'percentage',
        icon: faPercentage,
        value: true,
        tooltip: 'Percentage',
    },
    {
        label: 'value',
        icon: faHashtag,
        value: false,
        tooltip: 'Count'
    },
];

const svgId = 'horizontal-bar-chart-for-capture';

const BarChartModule: FunctionComponent = () => {
    const dispatchError = useDispatchableErr();

    const [size, setSizeTarget] = useElementSize();
    const [settingsOpen, setSettingsOpen] = useState(false);

    const {getActiveAttributeName} = useEntitySearchParams();

    const [showPercentage, setShowPercentage] = useState(true);

    const attributeName = getActiveAttributeName();

    const {preparedData, ...individualsLoadState} =
        useActiveIndividualsMeta();

    // const categoryAttrNames = getAllHistogramCat();

    const activeAttribute = !!preparedData && !!preparedData.attributes ?
        preparedData.attributes.filter((a) => a.name === attributeName)[0]
        : {singular: attributeName} as BaseAttribute;

    const facts = useMemo(() => {
        if (preparedData && attributeName) {
            const facts = preparedData.facts<string>(attributeName);

            if (isAppError(facts)) {
                dispatchError(facts);
                return [] as string[];
            }

            return facts.reduce(
                (acc, f) => acc.concat(f.values),
                [] as string[]
            );
        }

        return [] as string[];
    }, [attributeName, preparedData, dispatchError]);

    return (
        <StyledVizPaper>
            <StyledSubmoduleHeader>
                <Typography variant='h6' color="cyan">
                    Distribution
                    of {activeAttribute ? activeAttribute.singular : attributeName}
                </Typography>
                <IconButton icon={faGear}
                            onClick={() => setSettingsOpen((p) => !p)}/>
            </StyledSubmoduleHeader>
            <StyledVizWithControls settingsOpen={settingsOpen}>
                <DisplayOnLoad {...individualsLoadState}>
                    {/*<StyledSubmoduleHeader>*/}
                    {/*	<FlexContainer justifyContent="flex-start">*/}
                    {/*		<Typography>*/}
                    {/*			Count by {attributeName}*/}
                    {/*		</Typography>*/}
                    {/*	</FlexContainer>*/}
                    {/*</StyledSubmoduleHeader>*/}
                    <StyledSubmoduleContent ref={setSizeTarget} id={svgId}>
                        {/*rows={rows} cols={cols}*/}
                        {/*<StyledChartGrid>*/}
                        <HorizontalBarChart
                            facts={facts}
                            // width={size.width}
                            // height={size.height}
                            showPercentage={showPercentage}
                        />
                        {/*</StyledChartGrid>*/}
                    </StyledSubmoduleContent>
                </DisplayOnLoad>
                {
                    settingsOpen ?
                        <div
                            style={{borderLeft: `1px solid ${theme.palette.divider}`}}>
                            <List
                                style={{
                                    overflowY: 'auto',
                                    // paddingTop: '.05rem',
                                    // paddingBottom: '.05rem',
                                    paddingLeft: '1rem',
                                }}
                            >
                                <Typography
                                    variant="subtitle"
                                    color={'cyan'}>
                                    Share
                                </Typography>
                                <ListItem>
                                    <LinkedInShareButton elementId={svgId}/>
                                </ListItem>

                                <ListItem>
                                    <SlideToggle
                                        onActiveValueMismatch={dispatchError}
                                        activeValue={showPercentage}
                                        legend="select bar chart mode"
                                        onCheck={setShowPercentage}
                                        checkboxes={toggleDefs}
                                    />
                                </ListItem>
                            </List>
                            <List
                                style={{
                                    overflowY: 'auto',
                                    paddingTop: '.05rem',
                                    paddingBottom: '.05rem',
                                    paddingLeft: '1rem',
                                    marginTop: '1rem',
                                }}
                            >
                                <Typography
                                    variant="subtitle"
                                    color={'cyan'}>
                                </Typography>
                            </List>
                        </div>
                        : null}
            </StyledVizWithControls>
        </StyledVizPaper>
    );
};

export default BarChartModule;

//{/*{primaryAttribute && (*/}
//                 {/*	<ListItem*/}
//                 {/*		button*/}
//                 {/*		aria-current={*/}
//                 {/*			yAttrNames.includes(primaryAttribute.name)*/}
//                 {/*		}*/}
//                 {/*		onClick={makeMenuHandler(*/}
//                 {/*			primaryAttribute.name,*/}
//                 {/*			yAttrNames,*/}
//                 {/*			appendLineChartY,*/}
//                 {/*			removeLineChartY*/}
//                 {/*		)}>*/}
//                 {/*		/!*{yAttrNames.includes(primaryAttribute.name) && (*!/*/}
//                 {/*		/!*	<ListItemAvatar>*!/*/}
//                 {/*		/!*		<FontAwesomeIcon*!/*/}
//                 {/*		/!*			size='xs'*!/*/}
//                 {/*		/!*			icon={faCheck}*!/*/}
//                 {/*		/!*			color={colorMap[primaryAttribute.name]}*!/*/}
//                 {/*		/!*		/>*!/*/}
//                 {/*		/!*	</ListItemAvatar>*!/*/}
//                 {/*		/!*)}*!/*/}
//                 {/*		<ListItemContent>*/}
//                 {/*			<Typography variant={'caption2'} color={'primary'}>*/}
//                 {/*				{`Number of ${primaryAttribute.entity.plural}`}*/}
//                 {/*			</Typography>*/}
//                 {/*		</ListItemContent>*/}
//                 {/*	</ListItem>*/}
//                 {/*)}*/}
//                 {/*{preparedData &&*/}
//                 {/*	xAttrName &&*/}
//                 {/*	filterAttrsForYMenu(*/}
//                 {/*		xAttrName,*/}
//                 {/*		preparedData.attributes,*/}
//                 {/*		['quantity']*/}
//                 {/*	).map((a) => {*/}
//                 {/*		const current = yAttrNames.includes(a.name);*/}
//
//                 {/*		return (*/}
//                 {/*			<ListItem*/}
//                 {/*				button*/}
//                 {/*				key={a.name}*/}
//                 {/*				aria-current={!!current}*/}
//                 {/*				onClick={makeMenuHandler(*/}
//                 {/*					a.name,*/}
//                 {/*					yAttrNames,*/}
//                 {/*					appendLineChartY,*/}
//                 {/*					removeLineChartY*/}
//                 {/*				)}*/}
//                 {/*				style={{*/}
//                 {/*					color:*/}
//                 {/*						colorMap[a.name] ?? 'inherit',*/}
//                 {/*				}}*/}
//                 {/*			>*/}
//                 {/*				/!*{current && (*!/*/}
//                 {/*				/!*	<ListItemAvatar>*!/*/}
//                 {/*				/!*		<FontAwesomeIcon*!/*/}
//                 {/*				/!*			size='xs'*!/*/}
//                 {/*				/!*			icon={faCheck}*!/*/}
//                 {/*				/!*		/>*!/*/}
//                 {/*				/!*	</ListItemAvatar>*!/*/}
//                 {/*				/!*)}*!/*/}
//                 {/*				<ListItemContent>*/}
//                 {/*					<Typography variant={'caption2'}>*/}
//                 {/*						{a.singular}*/}
//                 {/*					</Typography>*/}
//                 {/*				</ListItemContent>*/}
//                 {/*			</ListItem>*/}
//                 {/*		);*/}
//                 {/*	})}*/}
//                 {/*<List*/}
//                 {/*	style={{*/}
//                 {/*		overflowY: 'auto',*/}
//                 {/*		paddingTop: '.05rem',*/}
//                 {/*		paddingBottom: '.05rem',*/}
//                 {/*		paddingLeft: '1rem',*/}
//                 {/*		marginTop: '1rem',*/}
//                 {/*	}}*/}
//                 {/*>*/}
//                 {/*	<ListItem>*/}
//                 {/*		<SlideToggle*/}
//                 {/*			onActiveValueMismatch={dispatchError}*/}
//                 {/*			activeValue={timeGrouping}*/}
//                 {/*			legend="group events by time period"*/}
//                 {/*			onCheck={setTimeGrouping}*/}
//                 {/*			checkboxes={toggleDefs}*/}
//                 {/*		/>*/}
//                 {/*	</ListItem>*/}
//                 {/*</List>*/}
//                 {/*// </StyledAttributeList>*/}
