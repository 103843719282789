import { isNonEmptyString } from 'common/utils/typeGuards';
import { Tagged } from 'common/utils/typeUtils';

/**Type for javascript errors our application generates.
 * Depend on this rather than plain Error for future extensibility
 * without needing to rework types.
 */
export const APP_ERROR_TAG = '__appError__';

export interface AppError extends Tagged {
	__tag: typeof APP_ERROR_TAG;
}

export class AppError extends Error implements AppError {
	constructor(public readonly message: string) {
		super(message);
		this.__tag = APP_ERROR_TAG;
	}
}

/**Serializable error value that can be dispatched to redux store.
 * Must always be derivable from AppError.
 */
export interface DispatchableError {
	message: string;
}

/**All possible states of redux 'err' slice. */
export type ErrorState = null | DispatchableError;

/**Type of redux 'err' slice when there is an error present. */
export type ActiveErrorState = NonNullable<ErrorState>;

/**Type of redux 'err' slice when no error is present. */
export type EmptyErrorState = null;

/**Narrow type of redux 'err' slice to the state that indicates
 * that an error is present.
 */
export const isActiveErrorState = (
	errState: ErrorState
): errState is ActiveErrorState =>
	errState === null
		? false
		: !!isNonEmptyString(errState.message);

/**Derive a serializable error object from javascript error of type AppError
 * that can be dispatched to redux store
 */
export const toDispatchableErr = (appError: AppError): DispatchableError => ({
	message: appError.message,
});
